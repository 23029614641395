<template>
    <div class="lg:flex flex-row lg:-mx-2">
        <ShortcutTool v-if="canUseShortcuts && member" :context-member-id="parseInt(member.memberID, 10)" />
        <div class="mx-2 w-full lg:w-1/4">
            <SearchPanel v-if="canSearch()" />
        </div>

        <div class="mx-2 w-full lg:w-3/4 mb-4">
            <DashboardCard
                v-if="member"
                :member="member"
                :hints="hints"
                context-type="CONTEXT_TYPE_MEMBER_VIEW"
                :member-hash-id="member.hashMemberID"
                @refresh="loadProfile()"
            />
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import SearchPanel from './SearchPanel';
    import { dashboardAxios, socialModerationAxios } from '../../../axios.config';
    import ShortcutTool from '@/components/Shortcuts/ShortcutTool';
    import { User } from '@/classes/User';

    export default {
        name: 'Show',

        components: {
            ShortcutTool,
            SearchPanel,
        },

        data() {
            return {
                member: null,
                hints: {},
                memberHash: null,
            };
        },

        computed: {
            ...mapGetters({
                staff: 'staff/getStaff',
            }),

            canUseShortcuts() {
                return User.make(this.staff).can('shortcut-tool:admin');
            },
        },
        watch: {
            $route: {
                handler() {
                    this.memberHash = this.$route.params.memberHash;

                    this.loadProfile();
                },
                deep: true,
            },
        },
        mounted() {
            let memberHash = this.$route.params.memberHash;

            this.memberHash = memberHash.toString().split('.').pop();

            if (!this.memberHash) {
                this.alert({ message: 'Malformed user identifier.', type: 'danger' });

                return;
            }

            this.loadProfile();
        },
        methods: {
            ...mapActions({
                handleError: 'handleError',
                alert: 'alert',
            }),

            canSearch() {
                return User.make(this.staff).can('members:admin');
            },

            async loadProfile() {
                if (!this.memberHash) {
                    return;
                }

                let member = null;

                try {
                    let response = await dashboardAxios.get('/v1/members/' + this.memberHash);

                    member = response.data.result.data;

                    this.hints = response.data.result.hints;
                } catch (e) {
                    this.handleError({ error: e });
                }

                if (User.make(this.staff).can('account:social-view')) {
                    try {
                        let socialResponse = await socialModerationAxios.post(
                            '/internal/community/social/view/get-social-profile',
                            {
                                account_id: member.accountID,
                            },
                        );
                        member.social = socialResponse.data.profile;
                    } catch (e) {
                        this.alert({ message: 'Unauthorized social', type: 'danger' });
                    }
                } else {
                    member.social = null;
                }

                this.member = member;
            },
        },
    };
</script>
