<template>
    <div class="container mx-auto border-1">
        <div class="flex items-center p-4 pb-5">
            <div class="w-full flex space-x-4 rounded">
                <div class="w-9/12">
                    <div class="md:flex sm:flex-wrap">
                        <input
                            :value="filters.title"
                            type="text"
                            class="w-full bg-white p-2 py-4 rounded h-2 border-1 border-grey-200"
                            placeholder="Search Campaigns"
                            @blur="(event) => (filters.title = event.target.value)"
                        />
                    </div>
                </div>
                <div class="w-1/12">
                    <button
                        class="text-xs border rounded-xs rounded text-center p-2 justify-self-end w-full border-grey-200"
                        :class="{ 'cursor-not-allowed': filterBadgeCount === 0, 'text-grey': filterBadgeCount === 0 }"
                        @click="clearFilters"
                    >
                        Clear Filters
                    </button>
                </div>
                <div ref="filterSection" class="w-1/12">
                    <button
                        class="text-xs border rounded-xs rounded text-center p-2 justify-self-end w-full border-grey-200"
                        @click="showFilters = !showFilters"
                    >
                        Filter
                        <span
                            v-if="filterBadgeCount > 0"
                            class="inline-flex items-center justify-center px-2 py-1 ml-1 text-xs font-bold leading-none text-white bg-pink-muzmatch rounded-full"
                        >
                            {{ filterBadgeCount }}
                        </span>
                    </button>
                    <div v-show="showFilters" class="shadow-lg bg-white p-2 rounded w-1/6 mt-2 absolute z-30">
                        <div>
                            <div class="font-bold text-xs">Statuses</div>
                            <div class="md:flex sm:flex-wrap mt-2">
                                <tab-item
                                    v-for="item in filters.statuses"
                                    :key="item.title"
                                    class="w-1/4"
                                    :active="filters.status"
                                    :name="item.title"
                                    @clicked="(active) => (filters.status = active)"
                                />
                            </div>
                        </div>
                        <div class="mt-6">
                            <div class="font-bold text-xs">Channels</div>
                            <div class="md:flex sm:flex-wrap mt-2">
                                <tab-item
                                    v-for="item in filters.channels"
                                    :key="item.title"
                                    class="w-1/4"
                                    :active="filters.channel"
                                    :name="item.title"
                                    @clicked="(active) => (filters.channel = active)"
                                />
                            </div>
                        </div>
                        <div class="mt-6">
                            <div class="font-bold text-xs">Types</div>
                            <div class="md:flex sm:flex-wrap mt-2">
                                <tab-item
                                    v-for="item in filters.types"
                                    :key="item.title"
                                    class="w-1/3"
                                    :active="filters.type"
                                    :name="item.title"
                                    @clicked="(active) => (filters.type = active)"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-1/12">
                    <button
                        class="text-xs border border-pink-dark rounded-xs rounded bg-pink-dark text-center text-white p-2 justify-self-end w-full"
                        :class="{ 'cursor-not-allowed': isDisabled, 'bg-grey-muzmatch': isDisabled }"
                        :disabled="isDisabled"
                        @click="showCreateCampaign = true"
                    >
                        New
                    </button>
                </div>
            </div>
        </div>

        <div class="overflow-auto">
            <table v-if="!isLoading" class="table-auto w-full">
                <thead>
                    <tr class="border-b border-grey-400">
                        <th
                            v-for="(field, index) in tableFields"
                            :key="index"
                            class="text-xs text-left py-4 font-bold"
                            :class="{ 'pl-4': index === 0, 'cursor-pointer': field.sortable }"
                            :title="field.tip"
                            @click="changeSorting(field)"
                        >
                            {{ field.label }}
                            <icon
                                v-if="sorting.current === field.name"
                                :icon="sorting.direction === 'asc' ? 'chevron-up' : 'chevron-down'"
                                class="ml-1 text-pink-muzmatch"
                            />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <table-row
                        v-for="(campaign, index) in filteredCampaigns"
                        :key="campaign.id"
                        :campaign="campaign"
                        :index="index"
                        :goal-list="goalList"
                    />
                    <tr v-if="noCampaigns">
                        <td class="text-base py-4 pl-4">No campaigns found</td>
                    </tr>
                </tbody>
            </table>
            <div v-else class="flex flex-col items-center justify-center my-4">
                <div><icon icon="list" class="loading-animation text-4xl mb-2 text-pink-muzmatch mr-4"></icon></div>
                <div><span class="text-lg mt-2 font-bold">Loading...</span></div>
            </div>
            <ShowMore
                :is-loading-more="isLoadingMore"
                :current-items-total="this.campaigns.length"
                :total-items="totalItems"
                :filtered-campaigns-length="this.filteredCampaigns.length"
                :amount-per-page="this.pagination.amountPerPage"
                @show-more="showMore"
                @amount-per-page-changed="amountPerPageChanged"
            />
        </div>

        <create-new-campaign-modal :show-modal="showCreateCampaign" @hide="showCreateCampaign = false" />
    </div>
</template>

<script>
    import TabItem from './TabItem.vue';
    import TableRow from './TableRow.vue';
    import CreateNewCampaignModal from './CreateNewCampaignModal.vue';
    import ShowMore from './../../components/Tables/ShowMore';
    import { messengerAxios } from '../../../axios.config';
    import { mapActions } from 'vuex';
    import moment from 'moment';

    export default {
        name: 'MessengerList',
        components: {
            TabItem,
            TableRow,
            CreateNewCampaignModal,
            ShowMore,
        },
        data() {
            return {
                filtersSet: false,
                showFilters: false,
                filters: {
                    title: '',
                    status: 'All',
                    statuses: [{ title: 'All' }, { title: 'Draft' }, { title: 'Paused' }, { title: 'Published' }],
                    type: 'All',
                    types: [
                        { title: 'All' },
                        { title: 'Scheduled' },
                        { title: 'API Event' },
                        { title: 'Reengagement' },
                    ],
                    channel: 'All',
                    channels: [
                        { title: 'All' },
                        { title: 'Email' },
                        { title: 'Push' },
                        { title: 'In App' },
                        { title: 'In App No Push' },
                        { title: 'No Message' },
                    ],
                },
                tableFields: [
                    { label: 'Campaign', name: 'title', type: 'string', sortable: true },
                    { label: 'Status', name: 'state', type: 'string', sortable: true },
                    { label: 'Type', name: 'type', type: 'string', sortable: true },
                    { label: 'Audience Size', name: 'audienceSize', type: 'int', sortable: true },
                    { label: 'Engagement', sortable: false, tip: 'Most recent step' },
                    { label: 'Scheduled At', name: 'scheduleSendAt', type: 'date', sortable: true },
                    { label: 'Processing', name: 'processing', type: 'int', sortable: true },
                    { label: 'Last Processed', name: 'lastProcessed', type: 'date', sortable: true },
                    { label: 'Updated', name: 'updatedAt', type: 'date', sortable: true },
                    { label: 'Completed', name: 'completedAt', type: 'date', sortable: true },
                ],
                sorting: {
                    current: 'updatedAt',
                    direction: 'desc',
                },
                pagination: {
                    pageNumber: 1,
                    amountPerPage: 10,
                },
                campaigns: [],
                filteredCampaigns: [],
                isLoading: false,
                isDisabled: false,
                showCreateCampaign: false,
                goalList: [],
                isLoadingMore: false,
                totalItems: 0,
            };
        },
        computed: {
            noCampaigns() {
                return this.filteredCampaigns.length === 0;
            },
            filterBadgeCount() {
                const filters = [this.filters.status, this.filters.type, this.filters.channel];

                return filters.filter((filter) => filter !== 'All').length;
            },
        },
        watch: {
            'filters.title'() {
                if (this.filtersSet) {
                    this.pagination.pageNumber = 1;
                    this.fetchCampaigns();
                }
            },
            'filters.status'() {
                if (this.filtersSet) {
                    this.pagination.pageNumber = 1;
                    this.fetchCampaigns();
                }
            },
            'filters.type'() {
                if (this.filtersSet) {
                    this.pagination.pageNumber = 1;
                    this.fetchCampaigns();
                }
            },
            'filters.channel'() {
                if (this.filtersSet) {
                    this.pagination.pageNumber = 1;
                    this.fetchCampaigns();
                }
            },
            'sorting.current'() {
                this.parseCampaignByFilters();
            },
            'sorting.direction'() {
                this.parseCampaignByFilters();
            },
        },
        created() {
            const path = sessionStorage.getItem('messengerListFullPath');
            if (path && path !== this.$router.currentRoute.fullPath) {
                this.$router.push(path);
            }

            this.loadQueryParams();
            this.fetchCampaigns(true);
            window.addEventListener('click', this.documentClick);
        },
        destroyed() {
            document.removeEventListener('click', this.documentClick);
        },
        methods: {
            ...mapActions({
                handleError: 'handleError',
            }),
            documentClick(e) {
                const filterSection = this.$refs.filterSection;
                const target = e.target;

                if (filterSection && !filterSection.contains(target)) {
                    this.showFilters = false;
                }
            },
            fetchCampaigns: _.debounce(async function (initial = false) {
                this.isLoading = true;
                try {
                    const [campaigns, goals] = await Promise.all([
                        messengerAxios.get('/campaigns', {
                            params: {
                                pageNumber: initial ? 1 : this.pagination.pageNumber,
                                amountPerPage: initial
                                    ? this.pagination.pageNumber * this.pagination.amountPerPage
                                    : this.pagination.amountPerPage,
                                title: this.filters.title,
                                state: this.filters.status,
                                type: this.filters.type,
                                channel: this.filters.channel,
                            },
                        }),
                        messengerAxios.get('/goals'),
                    ]);
                    this.filtersSet = true;
                    this.goalList = goals.data.result;
                    this.campaigns = campaigns.data.result.campaigns;
                    this.totalItems = campaigns.data.result.count;
                    this.parseCampaignByFilters();
                } catch (err) {
                    await this.handleError({ error: err });
                }

                this.isLoading = false;
            }, 500),

            loadQueryParams() {
                this.pagination.pageNumber = this.$route.query.pageNumber ? parseInt(this.$route.query.pageNumber) : 1;
                this.pagination.amountPerPage = this.$route.query.amountPerPage
                    ? parseInt(this.$route.query.amountPerPage)
                    : 20;
                this.filters.title = this.$route.query.title ? this.$route.query.title : '';
                this.filters.status = this.$route.query.status ? this.$route.query.status : 'All';
                this.filters.type = this.$route.query.type ? this.$route.query.type : 'Scheduled';
                this.filters.channel = this.$route.query.channel ? this.$route.query.channel : 'All';
            },

            async showMore() {
                this.pagination.pageNumber += 1;
                this.isLoadingMore = true;
                try {
                    const newCampaigns = await messengerAxios.get('/campaigns', {
                        params: {
                            pageNumber: this.pagination.pageNumber,
                            amountPerPage: this.pagination.amountPerPage,
                            title: this.filters.title,
                            state: this.filters.status,
                            type: this.filters.type,
                            channel: this.filters.channel,
                        },
                    });
                    this.campaigns = this.campaigns.concat(newCampaigns.data.result.campaigns);
                    this.parseCampaignByFilters();
                } catch (err) {
                    await this.handleError({ error: err });
                }
                this.isLoadingMore = false;
            },

            async amountPerPageChanged(event) {
                this.pagination.amountPerPage = Number(event.target.value);
                this.pagination.pageNumber = 1;
                try {
                    const newCampaigns = await messengerAxios.get('/campaigns', {
                        params: {
                            pageNumber: this.pagination.pageNumber,
                            amountPerPage: this.pagination.amountPerPage,
                            title: this.filters.title,
                            state: this.filters.status,
                            type: this.filters.type,
                            channel: this.filters.channel,
                        },
                    });
                    this.campaigns = newCampaigns.data.result.campaigns;
                    this.parseCampaignByFilters();
                } catch (err) {
                    await this.handleError({ error: err });
                }
            },

            clearFilters() {
                if (this.filterBadgeCount === 0) return;
                this.filters.status = 'All';
                this.filters.type = 'All';
                this.filters.channel = 'All';
            },

            changeSorting(field) {
                if (!field.sortable) return;

                // Change direction if same field
                if (field.name === this.sorting.current) {
                    this.sorting.direction = this.sorting.direction === 'asc' ? 'desc' : 'asc';
                    return;
                }

                this.sorting.current = field.name;
            },

            parseCampaignByFilters() {
                let filteredCampaigns = JSON.parse(JSON.stringify(this.campaigns));

                // Title filtering
                const wordList = this.filters.title.trim().split(' ');
                filteredCampaigns = filteredCampaigns.filter((campaign) => {
                    let campaignTitleFlag = true;

                    wordList.forEach((word) => {
                        if (!campaign.title.toLowerCase().includes(word.toLowerCase())) {
                            campaignTitleFlag = false;
                        }
                    });

                    return campaignTitleFlag;
                });

                switch (this.filters.status) {
                    case 'Draft':
                        filteredCampaigns = filteredCampaigns.filter((campaign) => campaign.state === 'DRAFT');
                        break;
                    case 'Paused':
                        filteredCampaigns = filteredCampaigns.filter((campaign) => campaign.state === 'PAUSED');
                        break;
                    case 'Published':
                        filteredCampaigns = filteredCampaigns.filter((campaign) => campaign.state === 'PUBLISHED');
                        break;
                }

                switch (this.filters.type) {
                    case 'Scheduled':
                        filteredCampaigns = filteredCampaigns.filter((campaign) => campaign.type === 'SCHEDULE');
                        break;
                    case 'API Event':
                        filteredCampaigns = filteredCampaigns.filter((campaign) => campaign.type === 'API_EVENT');
                        break;
                    case 'Reengagement':
                        filteredCampaigns = filteredCampaigns.filter((campaign) => campaign.type === 'REENGAGEMENT');
                        break;
                }

                switch (this.filters.channel) {
                    case 'Email':
                        filteredCampaigns = filteredCampaigns.filter((campaign) =>
                            campaign.channelList.includes('EMAIL'),
                        );
                        break;
                    case 'Push':
                        filteredCampaigns = filteredCampaigns.filter((campaign) =>
                            campaign.channelList.includes('PUSH'),
                        );
                        break;
                    case 'In App':
                        filteredCampaigns = filteredCampaigns.filter((campaign) =>
                            campaign.channelList.includes('IN_APP'),
                        );
                        break;
                }

                // Table column sorting
                const momentFormat = 'YYYY-MM-DD HH:mm:ss.SSSSSS';
                const currentField = this.tableFields.find((tableField) => tableField.name === this.sorting.current);
                filteredCampaigns.sort((a, b) => {
                    if (this.sorting.direction === 'desc') {
                        [a, b] = [b, a];
                    }

                    if (a[currentField.name] === null) return -1;
                    if (b[currentField.name] === null) return 1;

                    switch (currentField.type) {
                        case 'date':
                            return (
                                moment(a[currentField.name], momentFormat) - moment(b[currentField.name], momentFormat)
                            );
                        case 'string':
                            return a[currentField.name].localeCompare(b[currentField.name]);
                        case 'int':
                            return parseInt(a[currentField.name]) - parseInt(b[currentField.name]);
                    }
                });

                try {
                    this.$router
                        .replace({
                            query: {
                                pageNumber: this.pagination.pageNumber,
                                amountPerPage: this.pagination.amountPerPage,
                                title: this.filters.title.trim(),
                                status: this.filters.status,
                                type: this.filters.type,
                                channel: this.filters.channel,
                            },
                        })
                        .catch((error) => {});
                } catch (error) {
                    // Duplicate query errors, do nothing
                }

                sessionStorage.setItem('messengerListFullPath', this.$router.currentRoute.fullPath);

                this.filteredCampaigns = filteredCampaigns;
            },
        },
    };
</script>

<style scoped>
    .border-1 {
        border-width: 1px;
    }
</style>
