<template>
    <div class="py-5">
        <div class="md:flex sm:flex-wrap bg-gray-200 mb-2 rounded-t">
            <div class="overflow-x-auto flex w-5/6">
                <div
                    v-for="(variation, index) in variations"
                    :key="variation"
                    class="py-4 px-2 border-b-4 flex-auto text-center cursor-pointer"
                    :class="{
                        'border-pink-muzmatch': variation === selectedVariation,
                        'text-pink-muzmatch': variation === selectedVariation,
                        'font-bold': variation === selectedVariation,
                    }"
                    @click="selectedVariation = variation"
                >
                    <div class="cursor-pointer">
                        {{ variation }}
                        <div
                            v-if="index > 0"
                            class="float-right h-full w-6 rounded hover:bg-grey-300"
                            @click.stop="removeVariation(variation)"
                        >
                            x
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="variations[variations.length - 1] !== 'Z'"
                class="py-4 px-2 border-b-4 flex-auto text-center cursor-pointer"
                @click="addVariation(nextLetterInAlphabet(variations[variations.length - 1]))"
            >
                <icon icon="plus" />
            </div>
        </div>
        <div v-for="(messageList, messageGroupIndex) in steps" :key="messageList.id">
            <div v-if="messageGroupIndex !== 0" :key="'interval_' + messageGroupIndex" class="my-10">
                <div class="interval-line-through">
                    <div class="interval-info bg-white">
                        <select v-model="steps[messageGroupIndex].intervalAmount" @change="canSave = true">
                            <option v-for="i in 30" :key="i" :value="i">{{ i }}</option>
                        </select>
                        <select v-model="steps[messageGroupIndex].intervalType" @change="canSave = true">
                            <option
                                v-for="typeItem in intervalTypeList"
                                :key="typeItem.constant"
                                :value="typeItem.constant"
                            >
                                {{ typeItem.label }}
                            </option>
                        </select>
                        interval
                    </div>
                </div>
            </div>
            <Panel :title="`Step ${messageGroupIndex + 1}`">
                <div slot="header" class="float-right">
                    <button
                        v-if="!campaign.completedAt"
                        type="button"
                        class="delete-btn float-right mr-2 mt-2 p-2 pt-1 text-grey bg-white"
                        @click="messageGroupDeleted(messageGroupIndex, messageList)"
                    >
                        <span>x</span>
                    </button>
                </div>
                <div class="p-2">
                    <campaign-step-elements
                        v-for="(message, index) in getTemplates(messageList.templates)"
                        :key="index"
                        :index="index"
                        :step-pill-type="message.messageType"
                        :active="message.active"
                        :is-disabled="campaign.completedAt !== null"
                        @deleted="messageDeleted(messageGroupIndex, $event, message)"
                        @selected="
                            $emit('template-clicked', {
                                groupIdx: messageGroupIndex,
                                stepIdx: index,
                                variation: selectedVariation,
                            })
                        "
                        @toggled="
                            message.active = $event;
                            canSave = true;
                        "
                    >
                        {{ templateTitle(message) }}
                    </campaign-step-elements>
                    <div v-if="!campaign.completedAt" class="mt-1 pl-2">
                        <button
                            type="button"
                            class="add-template-btn p-2 pl-0"
                            @click="addTemplateModal(messageGroupIndex)"
                        >
                            <span class="text-grey-dark font-bold bg-grey-200 plus-btn mr-2">+</span>
                            <span>Add Template</span>
                        </button>
                    </div>
                </div>
            </Panel>
        </div>

        <div v-if="!campaign.completedAt" class="text-center mt-5">
            <button type="button" class="add-step-btn p-2" @click="addStep">
                <span class="text-grey-dark font-bold bg-grey-200 plus-btn mr-2">+</span>
                <span>Add Step</span>
            </button>
        </div>

        <campaign-add-template-modal
            v-if="showModal"
            :message-group-index="modalMessageGroupIndex"
            @hide="showModal = false"
            @submit-template="submitTemplate"
        />

        <save-button
            v-if="!campaign.completedAt"
            :can-save="parentCanSave"
            :is-saving="isSaving"
            :wobble="saveButtonWobble"
            @save="saveCampaign"
        />
        <disabled-button v-else @clicked="$emit('final-disabled-click')"></disabled-button>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import Panel from '../../components/Panels/Panel';
    import CampaignStepElements from './CampaignStepElements';
    import CampaignAddTemplateModal from './CampaignAddTemplateModal';
    import SaveButton from './SaveButton.vue';
    import DisabledButton from './DisabledButton.vue';
    import { messengerAxios } from '../../../axios.config';
    import { User } from '../../classes/User';

    export default {
        name: 'CampaignMessage',

        components: {
            Panel,
            CampaignStepElements,
            CampaignAddTemplateModal,
            SaveButton,
            DisabledButton,
        },

        props: {
            campaign: {
                type: Object,
                required: true,
            },
            parentCanSave: {
                type: Boolean,
                default: false,
            },
            saveButtonWobble: {
                type: Boolean,
                required: true,
            },
        },

        data() {
            return {
                selectedVariation: 'A',
                canSave: false,
                isSaving: false,
                steps: [],
                deletedTemplates: [],
                deletedTemplateSteps: [],
                showModal: false,
                modalMessageGroupIndex: null,
                templateModal: {
                    type: null,
                    message: null,
                },
                inAppModel: {
                    messageType: 'IN_APP',
                    active: true,
                    messageBody_en: {
                        pushTitle: 'title',
                        pushBody: 'body',
                        messages: [
                            {
                                type: 'TEXT',
                                text: 'sample msg',
                            },
                        ],
                    },
                },
                inAppNoPushModel: {
                    messageType: 'IN_APP_NO_PUSH',
                    active: true,
                    messageBody_en: {
                        pushTitle: null,
                        pushBody: null,
                        messages: [
                            {
                                type: 'TEXT',
                                text: 'sample msg',
                            },
                        ],
                    },
                },
                noMessageModel: {
                    messageType: 'NO_MESSAGE',
                    active: true,
                    messageBody_en: {
                        pushTitle: null,
                        pushBody: null,
                        messages: [],
                    },
                },
                pushModel: {
                    messageType: 'PUSH',
                    active: true,
                    messageBody_en: {
                        pushTitle: 'title',
                        pushBody: 'body',
                    },
                },
                emailModel: {
                    messageType: 'EMAIL',
                    active: true,
                    messageBody_en: {
                        subject: 'test subject',
                        from: 'noreply@marketing.muzmatch.com',
                        template: {
                            tagName: 'div',
                            class: 'newsletter',
                            children: [],
                        },
                        body: '<div data-v-e27e3baa="" class="newsletter"></div>',
                    },
                },
                intervalTypeList: [
                    { label: 'day', constant: 'DAY' },
                    { label: 'hour', constant: 'HOUR' },
                ],
            };
        },

        computed: {
            ...mapGetters({
                staff: 'staff/getStaff',
            }),
            variations() {
                const variations = [
                    ...new Set(this.steps.flatMap((step) => step?.templates.map((template) => template.variation))),
                ].sort();

                return variations.length > 0 ? variations : ['A'];
            },
            isVariationsEnabled() {
                return true;
            },
        },

        watch: {
            campaign() {
                this.loadMessageList();
            },

            canSave() {
                this.$emit('update-parent-can-save', this.canSave);
            },

            parentCanSave() {
                this.canSave = this.parentCanSave;
            },

            selectedVariation() {
                this.$emit('template-clicked', {
                    groupIdx: 0,
                    stepIdx: 0,
                    variation: this.selectedVariation,
                });
            },
        },

        created() {
            this.loadMessageList();
        },

        methods: {
            ...mapActions(['confirmDelete', 'handleError', 'alert']),

            addStep() {
                this.steps.push({
                    campaignID: this.campaign.id,
                    intervalAmount: 1,
                    intervalType: 'DAY',
                    templates: [],
                });
            },

            nextLetterInAlphabet(letter) {
                return String.fromCharCode(letter.charCodeAt(0) + 1);
            },

            previousLetterInAlphabet(letter) {
                return String.fromCharCode(letter.charCodeAt(0) - 1);
            },

            addVariation(variation) {
                if (!this.isVariationsEnabled) {
                    this.alert({ message: 'Not quite ready for that yet!', type: 'danger' });
                    return;
                }

                if (this.steps.length === 0) {
                    this.alert({ message: 'Please add a step first', type: 'success' });
                    return;
                }

                this.steps.forEach((step) => {
                    step.templates
                        .filter((t) => t.variation === 'A')
                        .forEach((template) => {
                            step.templates.push({
                                ..._.cloneDeep(template),
                                id: null,
                                variation,
                            });
                        });
                });
            },

            removeVariation(letter) {
                if (!this.isVariationsEnabled) {
                    this.alert({ message: 'Not quite ready for that yet!', type: 'danger' });
                    return;
                }
                const charCodeRemoved = letter.charCodeAt(0);
                this.steps.forEach((step) => {
                    const deletedSteps = step.templates.filter((t) => t.variation === letter);
                    deletedSteps.forEach((template) => {
                        if ('id' in template) {
                            this.deletedTemplates.push(template.id);
                        }
                    });
                    step.templates = step.templates.filter((t) => t.variation !== letter);
                    step.templates.forEach((template) => {
                        const tempCharCode = template.variation.charCodeAt(0);
                        if (tempCharCode > charCodeRemoved) {
                            template.variation = String.fromCharCode(tempCharCode - 1);
                        }
                    });
                });
                this.selectedVariation = String.fromCharCode(charCodeRemoved - 1);
                this.canSave = true;
            },

            addTemplateModal(messageGroupIndex) {
                this.modalMessageGroupIndex = messageGroupIndex;
                this.showModal = true;
            },

            submitTemplate(form) {
                this.variations.forEach((variation) => {
                    const template = form.template
                        ? form.template
                        : _.cloneDeep(this[_.camelCase(form.messageType) + 'Model']);

                    this.steps[form.messageGroupIndex].templates.push({
                        ...template,
                        variation,
                    });
                });

                this.showModal = false;
                this.canSave = true;
            },

            loadMessageList() {
                if (this.campaign.steps) {
                    this.steps = this.campaign.steps;
                    return;
                }
                this.steps = [];
            },

            async messageGroupDeleted(parentIndex, steps) {
                if (!(await this.confirmDelete({ vm: this }))) return;

                if ('id' in steps) {
                    this.deletedTemplateSteps.push(steps.id);

                    this.steps[parentIndex].templates.forEach((template) => {
                        if ('id' in template) {
                            this.deletedTemplates.push(template.id);
                        }
                    });
                }

                this.$delete(this.steps, parentIndex);
                this.$emit('clear-preview', { type: 'GROUP', parentIndex: parentIndex, index: null });
                this.canSave = true;
            },

            async messageDeleted(parentIndex, index, template) {
                if (!(await this.confirmDelete({ vm: this }))) return;

                let templates = [];

                this.variations.forEach((variation) => {
                    const tmplts = this.getTemplates(this.steps[parentIndex].templates, variation);

                    templates.push(
                        ...(this.selectedVariation !== variation ? tmplts : tmplts.filter((_, idx) => idx !== index)),
                    );

                    if (this.selectedVariation === variation) {
                        tmplts
                            .filter((_, idx) => idx === index)
                            .forEach((tmplt) => {
                                if ('id' in tmplt) {
                                    this.deletedTemplates.push(tmplt.id);
                                }
                            });
                    }
                });

                this.steps[parentIndex].templates = templates;

                let allEmpty = true;

                for (let i = 0; i < this.steps.length; i++) {
                    const tmplts = this.getTemplates(this.steps[i].templates, template.variation);
                    if (tmplts.length > 0) {
                        allEmpty = false;
                    }
                }

                if (allEmpty) {
                    this.removeVariation(template.variation);
                }

                this.$emit('clear-preview', { type: 'SINGLE', parentIndex: parentIndex, index: index });
                this.canSave = true;

                this.$nextTick(() => {
                    if (this.variations.length === 1) {
                        this.selectedVariation = 'A';
                    }
                });
            },

            templateTitle(message) {
                switch (message.messageType) {
                    case 'IN_APP':
                        return message.messageBody_en.pushTitle || message.messageBody_en.messages[0].text || '--';
                    case 'IN_APP_NO_PUSH':
                        return message.messageBody_en.messages[0].text || '--';
                    case 'PUSH':
                        return message.messageBody_en.pushTitle || '--';
                    case 'EMAIL':
                        return message.messageBody_en.subject || '--';
                    case 'NO_MESSAGE':
                        return message.messageBody_en.subject || '--';
                }
            },

            async saveCampaign() {
                if (!this.valid()) {
                    this.alert({ message: 'Please make sure day interval is filled out', type: 'danger' });
                    return;
                }

                this.isSaving = true;

                try {
                    const response = await messengerAxios.post(`/campaign/${this.campaign.id}/steps`, {
                        steps: this.steps,
                        deletedTemplates: this.deletedTemplates,
                        deletedTemplateSteps: this.deletedTemplateSteps,
                    });

                    await this.alert({ message: 'The messages was successfully saved', type: 'success' });
                    this.deletedTemplates = [];
                    this.deletedTemplateSteps = [];
                    this.canSave = false;
                    this.$emit('updated-campaign', response.data.result);
                } catch (error) {
                    await this.handleError({ error });
                }

                this.isSaving = false;
            },

            valid() {
                if (this.steps.length === 1) {
                    return true;
                }

                return this.steps.filter((s, i) => s.intervalAmount === null && i >= 1).length === 0;
            },

            camelCase(str) {
                return str
                    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
                        return index === 0 ? word.toLowerCase() : word.toUpperCase();
                    })
                    .replace(/\s+/g, '');
            },

            getTemplates(templates, variation = null) {
                return templates.filter((template) => template.variation === (variation || this.selectedVariation));
            },
        },
    };
</script>

<style scoped>
    .interval-line-through {
        text-align: center;
        border-top: 1px solid #cccccc;
        height: 1px;
    }

    .interval-info {
        height: 30px;
        width: 150px;
        top: -17px;
        position: relative;
        margin: auto;
        padding-top: 4px;
        font-size: 0.8rem;
        border-radius: 0.2rem;
        border: 1px solid #dddddd;
    }

    .plus-btn {
        display: inline-block;
        width: 70px;
        padding: 5px 25px 5px 25px;
        border-radius: 0.25rem;
        font-weight: bolder;
    }

    .delete-btn {
        border-radius: 100%;
        height: 2rem;
        width: 2rem;
        font-weight: bolder;
    }
</style>
