<template>
    <div
        class="border-0.25 bg-white w-100 my-3 p-3 h-full rounded items-center justify-center m-1 border-solid border-grey-light"
        @click="activateInEditMode"
    >
        <div class="w-full flex items-center pb-1">
            <div class="w-full flex">
                <icon icon="align-justify" class="handle mr-2 mb-2 text-grey-muzmatch cursor-pointer"></icon>
                <label class="block text-grey-muzmatch font-bold rounded mb-1 text-sm">{{ element.label }}</label>
            </div>
            <div
                class="flex justify-center items-center bg-grey-300 rounded-full w-6 h-6 cursor-pointer justify-self-end"
                @click="removeElement"
            >
                <icon icon="times" class="text-white"></icon>
            </div>
        </div>

        <textarea
            v-if="showTextArea"
            v-model="vModel"
            class="p-2 border border-grey-300 block w-full rounded"
            :rows="element.rows"
        />

        <input
            v-if="isButton"
            v-model="element.children[0].attrs.href"
            type="text"
            class="mt-2 p-2 border border-grey-300 block w-full rounded"
            placeholder="url"
        />

        <ImageUpload v-if="isImageUpload" @image-uploaded="updateImageView" />

        <PhotoVideoElement v-if="isNested" :element="element" />

        <CampaignListBuilder v-if="isList" :list="element" />

        <ColumnSection v-if="isSection" :element="element" />

        <CampaignElementMenu v-if="!isNested" :element="element" />
    </div>
</template>

<script>
    import PhotoVideoElement from './PhotoVideoElement';
    import CampaignElementMenu from './CampaignElementMenu';
    import CampaignListBuilder from './CampaignListBuilder';
    import ColumnSection from './ColumnSection';
    import ImageUpload from './ImageUpload.vue';

    export default {
        name: 'CampaignElement',

        components: { PhotoVideoElement, CampaignElementMenu, CampaignListBuilder, ColumnSection, ImageUpload },

        props: {
            element: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                isEditing: true,
                nonTextElements: [
                    'Photo-Video',
                    'List',
                    'Image/Photo Section',
                    'Image',
                    'App Store Links',
                    'Divider Bold',
                    'Divider Thin',
                    'List',
                    'Profile Image',
                ],
            };
        },
        computed: {
            vModel: {
                get() {
                    if (this.element.label === 'Button') {
                        return this.element.children[0].textNode;
                    }

                    if (this.element.label === 'Image') {
                        return this.element.attrs.src;
                    }

                    return this.element.textNode;
                },
                set(value) {
                    if (this.element.label === 'Button') {
                        this.element.children[0].textNode = value;
                    }

                    if (this.element.label === 'Image') {
                        this.element.attrs.src = value;
                    }

                    this.element.textNode = value;
                },
            },
            isNested() {
                return this.element.label === 'Photo-Video';
            },
            isList() {
                return this.element.label === 'List';
            },
            isSection() {
                return this.element.label === 'Image/Photo Section';
            },
            isImageUpload() {
                return this.element.tagName === 'img';
            },
            isButton() {
                return this.element.label === 'Button';
            },
            showTextArea() {
                return !this.nonTextElements.includes(this.element.label);
            },
        },

        methods: {
            activateInEditMode() {
                this.isEditing = true;
            },
            removeElement() {
                this.$emit('remove');
            },
            updateImageView(resp) {
                this.element.attrs.src = resp.url;
            },
        },
    };
</script>
