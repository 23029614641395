<template>
    <div ref="messageBox" class="flex flex-col rounded">
        <div>
            <div class="flex flex-col justify-between rounded-t" :class="[messageColour]">
                <div class="flex flex-row justify-between">
                    <span class="text-base block font-bold px-4 pt-2 pb-1">
                        {{ userParse }}
                        <icon v-show="isEmailVerified" icon="check-circle"></icon>
                        <router-link
                            v-if="memberLink"
                            class="no-underline text-white text-sm ml-2 hover:text-grey-muzmatch"
                            :to="memberLink"
                            target="_blank"
                        >
                            <icon icon="link"></icon>
                        </router-link>
                    </span>

                    <div class="flex flex-row items-center mx-3 pb-1 text-xs thread-forward-icon">
                        <icon title="Forward Email" icon="share-square" @click="forwardEmail" />
                    </div>
                </div>

                <span class="text-sm block px-4 pb-2">
                    {{ dateFormat(email['created']) }} · {{ timeAgo(email['created']) }}
                </span>
            </div>
        </div>

        <div class="flex flex-col border-b-2 border-l-2 border-r-2 border-grey-100 pb-4">
            <div class="line-height">
                <div ref="messageContent">
                    <slot />
                    <div
                        v-if="fragmentsToggleVisible && !fragmentsVisible"
                        class="mx-4 mb-4 cursor-pointer font-bold"
                        @click="toggleFragments"
                    >
                        Show more
                    </div>
                </div>
            </div>
            <div v-if="fragmentsToggleVisible && fragmentsVisible">
                <div v-for="(fragment, idx) in remainingFragments" :key="idx">
                    <vue-markdown
                        :breaks="false"
                        class="mb-4 mx-4 pt-4 px-4 whitespace-pre-line border-l-2 border-grey-500"
                        :source="fragment"
                    />
                </div>
                <div class="m-4 cursor-pointer font-bold" @click="toggleFragments">Show less</div>
            </div>

            <AttachmentSection :email="email" :attachments="attachments" :thread-id="threadId" />
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import AttachmentSection from '../Attachments/AttachmentSection';
    import VueMarkdown from 'vue-markdown';
    import { dashboardAxios } from '../../../axios.config';

    export default {
        name: 'EmailCard',

        components: {
            AttachmentSection,
            VueMarkdown,
        },

        props: {
            email: {
                type: Object,
                required: true,
            },
            memberEmailAddresses: {
                type: Array,
                default: null,
            },
            threadEmailAddresses: {
                type: Array,
                default: null,
            },
            threadId: {
                type: Number,
                required: true,
            },
            type: {
                type: String,
                required: true,
            },
            attachments: {
                type: Array,
                required: true,
            },
            parentRefs: {
                type: Object,
                required: true,
            },
            index: {
                type: Number,
                required: true,
            },
        },

        data() {
            return {
                communityEmails: ['support@muzmatch.com', 'support@muzz.com', 'muzmatch.test@gmail.com'],
                fragmentsToggleVisible: false,
                fragmentsVisible: false,
                canCompress: false,
                canExpand: false,
            };
        },

        computed: {
            messageColour() {
                if (this.isCommunity(this.email['senderEmail'])) {
                    return 'bg-grey-100';
                }

                if (this.isEmailVerified) {
                    return 'bg-pink-muzmatch text-white';
                }

                return 'bg-purple-muzmatch text-white';
            },
            remainingFragments() {
                let fragments = this.email.fragments.slice();
                fragments.shift();
                return fragments;
            },
            userParse() {
                if (this.isCommunity(this.email.senderEmail)) {
                    return 'Muzz';
                }

                if (this.type.includes('FORWARDED')) {
                    return this.email.replyEmail;
                }

                return this.email.senderEmail;
            },
            isEmailVerified() {
                if (!this.memberEmailAddresses) return false;

                return this.memberEmailAddresses.some((emailAddress) => emailAddress.email_address === this.userParse);
            },
            isFirstEmailAddressInThread() {
                return this.index === 0;
            },
            currentEmailAttachments() {
                return this.attachments.filter((attachment) => {
                    return attachment.emailId === this.email.emailId;
                });
            },
            memberLink() {
                const email = this.memberEmailAddresses
                    ? this.memberEmailAddresses.find((email) => email.email_address === this.userParse)
                    : null;

                return email
                    ? {
                          name: 'members',
                          query: { emailAddress: email.email_address },
                      }
                    : null;
            },
        },

        mounted: function () {
            this.fragmentsToggleVisible = this.remainingFragments.length > 0;
        },

        updated: function () {
            this.fragmentsToggleVisible = this.remainingFragments.length > 0;
            this.checkStatus();
            this.$emit('checkStatus');
        },

        methods: {
            checkStatus() {
                if (this.fragmentsToggleVisible) {
                    if (this.fragmentsVisible) {
                        this.canCompress = true;
                        this.canExpand = false;
                        return;
                    }

                    this.canCompress = false;
                    this.canExpand = true;
                    return;
                }

                this.canCompress = false;
                this.canExpand = false;
            },
            isCommunity(senderEmail) {
                return this.communityEmails.includes(senderEmail);
            },
            toggleCollapse() {
                let oldTop = this.parentRefs.scroll.scrollTop;

                this.fragmentsVisible = false;

                Vue.nextTick(() => {
                    this.parentRefs.scroll.scrollTop = oldTop;
                    this.$emit('handleScroll');
                });
            },
            collapseAll() {
                this.fragmentsVisible = false;
            },
            expandAll() {
                this.fragmentsVisible = true;
            },
            toggleFragments() {
                this.fragmentsVisible = !this.fragmentsVisible;
                this.$emit('handleScroll');
            },
            dateFormat: function (value) {
                return moment.utc(value).local().format('LLLL');
            },
            timeAgo: function (value) {
                return moment.utc(value).local().fromNow();
            },
            async forwardEmail() {
                const response = await this.$swal({
                    html: `
                        <div class="flex flex-row items-center justify-center">
                            <div class="">
                                <input
                                    id="emailAddress"
                                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    type="text"
                                />
                            </div>
                            <div class="ml-2">
                                @muzz.com
                            </div>
                        </div>
                    `,
                    preConfirm: () => {
                        return [document.getElementById('emailAddress').value];
                    },
                    showCancelButton: true,
                    confirmButtonText: 'Forward email',
                });

                if (response.dismiss) {
                    return;
                }

                if (response.value[0].includes('@')) {
                    this.alert({
                        message: "Please do not include the '@' symbol in the email address field",
                        type: 'danger',
                    });
                    return;
                }

                let body = {
                    emailAddress: `${response.value[0]}@muzz.com`,
                    attachments: this.currentEmailAttachments,
                };

                await dashboardAxios.post(`/v1/tasks/email/${this.email.emailId}/forward`, body);
            },
        },
    };
</script>

<style scoped>
    .thread-visible {
        display: block;
        word-wrap: break-word;
        overflow: hidden;
        max-height: 8em;
        min-height: 2em;
    }

    .line-height {
        line-height: 1.4em;
    }

    .w-90 {
        width: 90%;
    }

    .thread-forward-icon {
        margin-bottom: -5px;
    }
</style>
