<template>
    <Panel v-if="permission" title="Edit Permission">
        <div class="p-4">
            <div class="flex flex-row -mx-2">
                <div class="w-1/2 mx-2">
                    <label>Product</label>

                    <input
                        v-model="permission.productName"
                        type="text"
                        class="p-2 bg-white border border-grey-400 rounded w-full mt-1"
                    />
                </div>

                <div class="w-1/2 mx-2">
                    <label>Name</label>

                    <input
                        v-model="permission.roleName"
                        type="text"
                        class="p-2 bg-white border border-grey-400 rounded w-full mt-1"
                    />
                </div>
            </div>

            <div class="flex justify-end mt-4">
                <button
                    class="py-2 px-4 text-grey-800 rounded focus:outline-none bg-grey-100 mr-2"
                    @click="$router.push('/permissions')"
                >
                    Back to listing
                </button>

                <button
                    class="py-2 px-4 text-white rounded focus:outline-none"
                    :class="loading ? 'bg-pink-lighter' : 'bg-pink-muzmatch'"
                    @click="saveRole()"
                >
                    Save
                </button>
            </div>
        </div>
    </Panel>
</template>

<script>
    import Panel from '../../components/Panels/Panel';
    import { dashboardAxios } from '../../../axios.config';
    import { mapActions } from 'vuex';

    export default {
        name: 'Show',

        components: {
            Panel,
        },

        data() {
            return {
                permission: null,
                loading: false,
            };
        },
        mounted() {
            let identifier = this.$route.params.id;

            let split = identifier.toString().split('.');

            if (split.length !== 2) {
                this.alert({ message: 'Malformed permission identifier.', type: 'danger' });

                return;
            }

            try {
                this.loadRole(parseInt(split[1], 10));
            } catch (e) {
                this.handleError({ error: e });
            }
        },
        methods: {
            ...mapActions(['alert', 'handleError']),

            async loadRole(id) {
                try {
                    let response = await dashboardAxios.get('/v1/walipermission/' + id);

                    this.permission = response.data.result;
                } catch (e) {
                    this.handleError({ error: e });
                }
            },

            async saveRole() {
                if (this.loading) {
                    return;
                }

                if (!this.permission.productName || !this.permission.roleName) {
                    this.alert({ message: 'Product and name are required', type: 'danger' });

                    return;
                }

                this.loading = true;

                try {
                    let response = await dashboardAxios.put('/v1/walipermission/' + this.permission.id, {
                        name: this.permission.roleName,
                        product: this.permission.productName,
                    });

                    this.permission = response.data.result;

                    this.alert({ message: 'Permission successfully updated', type: 'success' });

                    this.loading = false;
                } catch (e) {
                    this.handleError({
                        error: e,
                        callback: () => (this.loading = false),
                    });
                }
            },
        },
    };
</script>
