<template>
    <div class="border rounded shadow p-4 border-grey-400">
        <div v-for="(query, index) in queries" :key="index">
            <div v-if="index !== 0" class="w-full text-center my-4">Or</div>
            <query-row ref="queryRows" :options="options" :index="index" :row="query" @remove-row="removeRow" />
        </div>
        <div class="flex justify-center">
            <button
                class="border border-pink-dark rounded bg-pink-dark text-center text-white text-xs p-2 mt-3"
                @click="addRow"
            >
                Add OR constraint
            </button>
        </div>
    </div>
</template>

<script>
    import QueryRow from './QueryRow';

    export default {
        components: { QueryRow },
        props: {
            options: {
                type: Array,
                required: true,
            },
            box: {
                type: Array,
                default: null,
            },
        },
        data() {
            return {
                baseQuery: {
                    type: null,
                    comparison: null,
                    value: null,
                },
                queries: [],
            };
        },
        watch: {
            queries() {
                this.$emit('update-audience-amount');
            },
        },
        created() {
            this.addRow();

            if (!this.box) return;

            this.queries = this.box;
        },
        methods: {
            addRow() {
                this.queries.push(this.baseQuery);
            },
            removeRow(index) {
                this.queries = this.queries.filter((e, i) => i !== index);
            },
        },
    };
</script>
