<template>
    <div>
        <div>
            <label>MarriageID</label>

            <input
                v-model="search.memberId"
                type="text"
                class="p-2 bg-white border border-grey-400 rounded w-full mt-1"
                @keyup.enter="searchMembers()"
            />
        </div>

        <div class="mt-4">
            <label>AccountID</label>

            <input
                v-model="search.accountID"
                type="text"
                class="p-2 bg-white border border-grey-400 rounded w-full mt-1"
                @keyup.enter="searchMembers()"
            />
        </div>

        <div v-if="isDashboardAdmin" class="mt-4">
            <label>Nickname</label>

            <input
                v-model="search.nickname"
                type="text"
                class="p-2 bg-white border border-grey-400 rounded w-full mt-1"
                @keyup.enter="searchMembers()"
            />

            <div class="mt-4">
                <label>Phone Number</label>

                <input
                    v-model="search.phoneNumber"
                    type="text"
                    class="p-2 bg-white border border-grey-400 rounded w-full mt-1"
                    @keyup.enter="searchMembers()"
                />
            </div>
        </div>

        <div class="mt-4">
            <label>Email Address</label>

            <input
                v-model="search.emailAddress"
                type="text"
                class="p-2 bg-white border border-grey-400 rounded w-full mt-1"
                @keyup.enter="searchMembers()"
            />
        </div>

        <div class="mt-4">
            <label>Member Hash</label>

            <input
                v-model="search.memberHash"
                type="text"
                class="p-2 bg-white border border-grey-400 rounded w-full mt-1"
                @keyup.enter="searchMembers()"
            />
        </div>

        <div v-if="cities.length > 0" class="mt-4">
            <label>City</label>

            <multiselect
                v-model="city"
                class="w-full"
                :options="cities"
                track-by="id"
                label="name"
                :multiple="true"
                :limit="5"
                :options-limit="8000"
                placeholder="Select city"
            ></multiselect>
        </div>

        <div v-if="ethnicities.length > 0" class="mt-4">
            <label>Ethnicity</label>

            <multiselect
                v-model="ethnicity"
                class="w-full"
                :options="ethnicities"
                track-by="id"
                label="ethnicGroupingName"
                :multiple="true"
                :limit="5"
                placeholder="Select ethnicity"
            ></multiselect>
        </div>

        <div v-if="countries.length > 0" class="mt-4">
            <label>Country</label>
            <multiselect
                v-model="country"
                class="w-full"
                :options="countries"
                track-by="id"
                label="countryName"
                :multiple="true"
                :limit="5"
                placeholder="Select country"
            ></multiselect>
        </div>

        <div class="mt-4">
            <label>Profession</label>

            <select v-model="search.profession" class="p-2 bg-white border border-grey-400 rounded w-full mt-1">
                <option :value="''" selected>Select Profession</option>
                <option v-for="profession in professions" :key="profession.id" :value="profession.id">
                    {{ profession.professionName }}
                </option>
            </select>
        </div>

        <div class="mt-4">
            <label>Gender</label>

            <select v-model="search.gender" class="p-2 bg-white border border-grey-400 rounded w-full mt-1">
                <option value="" selected>Select Gender</option>
                <option value="M">Male</option>
                <option value="F">Female</option>
            </select>
        </div>

        <div class="mt-4">
            <label>Age</label>

            <div class="flex">
                <input
                    v-model.number="search.minAge"
                    class="mr-2 p-2 bg-white border border-grey-400 rounded w-2/5 mt-1"
                    type="number"
                    required
                />
                <div class="w-1/5"></div>
                <input
                    v-model.number="search.maxAge"
                    class="ml-2 p-2 bg-white border border-grey-400 rounded w-2/5 mt-1"
                    type="number"
                    required
                />
            </div>

            <vue-slider
                v-model="ageModel"
                class="mx-2"
                :interval="1"
                tooltip-placement="bottom"
                :min-range="0"
                :max-range="100"
            />
        </div>

        <div class="mt-4">
            <label>Approved</label>

            <select v-model="search.approved" class="p-2 bg-white border border-grey-400 rounded w-full mt-1">
                <option value="" selected>Select value</option>
                <option value="y">Yes</option>
                <option value="n">No</option>
            </select>
        </div>

        <div class="mt-4">
            <label>Share Link</label>

            <input
                v-model="search.shareLink"
                type="text"
                class="p-2 bg-white border border-grey-400 rounded w-full mt-1"
                @keyup.enter="searchMembers()"
            />
        </div>

        <div class="w-full mt-8 text-right">
            <button
                class="py-2 px-4 text-white rounded focus:outline-none hover:shadow-md"
                :class="loading ? 'bg-pink-lighter' : 'bg-pink-muzmatch'"
                @mousedown="loading = true"
                @mouseup="loading = false"
                @mouseleave="loading = false"
                @click="searchMembers()"
            >
                Search
            </button>
        </div>
    </div>
</template>

<script>
    import VueSlider from 'vue-slider-component';
    import { mapGetters, mapActions } from 'vuex';
    import { User } from '../../classes/User';
    import EventBus from '../../helpers/eventBus';
    import Multiselect from 'vue-multiselect';
    import { consoleSandbox } from '@sentry/utils';

    export default {
        name: 'Search',
        components: {
            VueSlider,
            Multiselect,
        },
        data() {
            return {
                loading: false,

                search: {
                    memberHash: '',
                    memberId: '',
                    accountID: '',
                    phoneNumber: '',
                    emailAddress: '',
                    nickname: '',
                    profession: '',
                    gender: '',
                    minAge: 1,
                    maxAge: 99,
                    approved: '',
                    shareLink: '',
                },
                city: [],
                ethnicity: [],
                country: [],
            };
        },
        computed: {
            ...mapGetters({
                staff: 'staff/getStaff',
                cities: 'search/getCities',
                ethnicities: 'search/getEthnicities',
                professions: 'search/getProfessions',
                countries: 'search/getCountries',
            }),
            isDashboardAdmin() {
                return User.make(this.staff).can('dashboard:admin');
            },
            ageModel: {
                get() {
                    return [this.search.minAge, this.search.maxAge];
                },
                set(value) {
                    console.log(value);
                },
            },
        },
        watch: {
            cities() {
                this.fillMultiSelect('city', this.cities);
            },
            countries() {
                this.fillMultiSelect('country', this.countries);
            },
            ethnicities() {
                this.fillMultiSelect('ethnicity', this.ethnicities);
            },
        },
        created() {
            this.loadCities();
            this.loadEthnicities();
            this.loadProfessions();
            this.loadCountries();
        },

        mounted() {
            let query = _.clone(this.$route.query);

            if (Object.keys(query).length > 0) {
                this.search = query;
                if (Array.isArray(query.city)) {
                    this.city = query.city;
                } else if (typeof query.city !== 'undefined' && query.city != '') {
                    this.city = [query.city];
                }
                if (Array.isArray(query.country)) {
                    this.country = query.country;
                } else if (typeof query.country !== 'undefined' && query.country != '') {
                    this.country = [query.country];
                }
                if (Array.isArray(query.ethnicity)) {
                    this.ethnicity = query.ethnicity;
                } else if (typeof query.ethnicity !== 'undefined' && query.ethnicity != '') {
                    this.ethnicity = [query.ethnicity];
                }
            }
            this.fillMultiSelect('city', this.cities);
            this.fillMultiSelect('country', this.countries);
            this.fillMultiSelect('ethnicity', this.ethnicities);
        },
        methods: {
            ...mapActions({
                loadEthnicities: 'search/loadEthnicities',
                loadProfessions: 'search/loadProfessions',
                loadCountries: 'search/loadCountries',
                loadCities: 'search/loadCities',
            }),
            async searchMembers() {
                let query = _.clone(this.search);
                query.city = this.city;
                query.country = this.country;
                query.ethnicity = this.ethnicity;

                if (Array.isArray(query.city) && query.city.length > 0) {
                    let cityIds = query.city.map((localCity) => localCity.id);
                    query.city = cityIds;
                }

                if (Array.isArray(query.country) && query.country.length > 0) {
                    let countryIds = query.country.map((country) => country.id);
                    query.country = countryIds;
                }

                if (Array.isArray(query.ethnicity) && query.ethnicity.length > 0) {
                    let ethnicityIds = query.ethnicity.map((ethnicity) => ethnicity.id);
                    query.ethnicity = ethnicityIds;
                }

                if (!this.isDashboardAdmin) {
                    let { memberHash, memberId, emailAddress, shareLink, accountID } = query;

                    query = { memberHash, memberId, emailAddress, shareLink, accountID };
                }

                query.emailAddress =
                    query.emailAddress && !query.emailAddress.includes('@')
                        ? `${query.emailAddress}@dev.muzz.com`
                        : query.emailAddress;

                query.age = this.ageModel;

                try {
                    await this.$router.push({ name: 'members', query });

                    this.$emit('searched');
                    EventBus.$emit('searched');
                } catch (e) {
                    // Ignore duplicate pushes.
                }
            },
            fillMultiSelect(type, options) {
                if (
                    Array.isArray(this[type]) &&
                    this[type].length > 0 &&
                    typeof this[type][0] === 'string' &&
                    options.length > 0
                ) {
                    let tempArray = [];
                    for (let i = 0; i < this[type].length; i++) {
                        let searchedItem = options.find((item) => item.id == Number(this[type][i]));
                        if (typeof searchedItem !== 'undefined') {
                            tempArray.push(searchedItem);
                        }
                    }
                    this[type] = tempArray;
                }
            },
        },
    };
</script>

<style src="vue-slider-component/theme/default.css"></style>
