<template>
    <modal size="sm" :click-overlay="closeModal" :no-padding="true">
        <div slot="body">
            <panel title="Create Campaign" :has-margin="false" :custom-classes="['bg-pink-muzmatch', 'text-white']">
                <div class="p-4">
                    <div class="flex flex-wrap">
                        <div class="w-full">
                            <label>Goal Type*</label>
                            <dropdown
                                class="mt-2"
                                placeholder="Select Type Filter"
                                :options="goalOptions"
                                @updated="form.goalID = $event"
                            />
                        </div>
                    </div>
                    <div class="mt-4 flex space-x-12">
                        <div class="w-1/2"></div>
                        <div class="w-1/2">
                            <m2-button :custom-classes="['text-xs', 'normal-case', 'rounded']" @clicked="submitGoal">
                                Add goal
                            </m2-button>
                        </div>
                    </div>
                </div>
            </panel>
        </div>
    </modal>
</template>

<script>
    import Modal from '../../components/Modal/Modal.vue';
    import Panel from '../../components/Panels/Panel';
    import Dropdown from '../../components/Dropdown/Dropdown';
    import M2Button from '../../components/Buttons/M2Button';
    import { mapActions } from 'vuex';

    export default {
        name: 'CampaignAddGoalModal',
        components: {
            Modal,
            Panel,
            Dropdown,
            M2Button,
        },

        props: {
            goals: {
                type: Array,
                required: true,
            },
        },

        data() {
            return {
                form: {
                    goalID: null,
                },
            };
        },

        computed: {
            goalOptions() {
                return this.goals.map((g) => ({
                    value: g.id,
                    label: g.title,
                }));
            },
        },

        methods: {
            ...mapActions(['alert']),

            submitGoal() {
                if (this.form.goalID !== null && this.form.threshold !== null) {
                    this.$emit('submit-goal', this.form);
                    return;
                }
                this.alert({ message: 'Missing required fields', type: 'danger' });
            },

            closeModal() {
                this.$emit('hide');
            },
        },
    };
</script>

<style scoped></style>
