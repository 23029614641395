<template>
    <div>
        <div class="inline-flex items-center cursor-pointer" @click="toggleShowDetails">
            <span class="my-2 text-grey-darkest">{{ name }}</span>
            <icon :icon="showDetails ? 'chevron-up' : 'chevron-down'" class="grey-light ml-3"></icon>
        </div>
        <div v-if="showDetails">
            <div v-if="typeof value === 'object'" class="block">
                <span v-for="(val, idx) in value" :key="idx" class="block my-2 mx-5">{{ val }}</span>
            </div>
            <span v-else class="block my-2 mx-5">{{ value }}</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'DeletedKey',
        props: {
            value: {
                type: [String, Array],
                required: true,
            },
            name: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                showDetails: false,
            };
        },
        methods: {
            toggleShowDetails() {
                this.showDetails = !this.showDetails;
            },
        },
    };
</script>
