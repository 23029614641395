<template>
    <div
        class="py-1 px-4 rounded-lg text-white text-sm font-semibold flex items-center"
        :class="colour"
        @click="$emit('clicked')"
    >
        <slot />
    </div>
</template>

<script>
    export default {
        props: {
            colour: {
                type: String,
                default: 'bg-pink-muzmatch',
            },
        },
    };
</script>

<style lang="scss" scoped></style>
