<template>
    <Modal size="m" :click-overlay="() => $emit('close-modal')" :no-padding="true">
        <div slot="body">
            <Panel title="Add Config Override" :has-margin="false" :custom-classes="['bg-pink-muzmatch', 'text-white']">
                <div class="p-4">
                    <div class="flex flex-wrap mb-5">
                        <div class="w-full">
                            <label>Config*</label>
                            <Dropdown
                                class="w-full"
                                :options="filteredConfigDropdown"
                                placeholder="Select config type"
                                :white-background="true"
                                :is-capitalize="false"
                                @updated="form.config = $event"
                            />
                        </div>
                    </div>

                    <template v-if="form.config !== null">
                        <div class="mb-5">
                            <label for="enabled">Enabled</label>
                            <input id="enabled" v-model="form.enabled" class="ml-2" type="checkbox" name="enabled" />
                        </div>

                        <div class="mb-5">
                            <label class="mr-2">Type</label>
                            <div v-for="val in typeOptionList" :key="val.value">
                                <input
                                    :id="val.value"
                                    v-model="form.type"
                                    :value="val.value"
                                    class="cursor-pointer"
                                    :disabled="true"
                                    type="radio"
                                />
                                <label class="pl-2 cursor-pointer" :for="val.value">
                                    {{ val.label }}
                                </label>
                            </div>
                        </div>

                        <div v-if="form.type !== 'BOOLEAN'" class="mb-5">
                            <label class="my-1">Suggestion</label>
                            <Dropdown
                                :key="resetDropdown"
                                :options="settingValueList"
                                :white-background="true"
                                placeholder="Select suggestion to copy"
                                :is-capitalize="false"
                                @updated="selectedSuggestion"
                            />
                        </div>

                        <div class="mb-5">
                            <label class="my-1">Value*</label>
                            <template v-if="['TEXT', 'NUMBER'].includes(form.type)">
                                <input
                                    v-model="form.value"
                                    class="sm:w-full block appearance-none border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight bg-white"
                                    :type="form.type"
                                />
                            </template>
                            <template v-else>
                                <Dropdown
                                    :options="[
                                        { value: true, label: 'True' },
                                        { value: false, label: 'False' },
                                    ]"
                                    :white-background="true"
                                    :default="true"
                                    placeholder="Select value"
                                    :is-capitalize="false"
                                    @updated="form.value = $event"
                                />
                            </template>
                        </div>

                        <M2Button
                            :is-disabled="isDisabled"
                            :custom-classes="['text-xs', 'normal-case', 'rounded']"
                            @clicked="addRow"
                        >
                            <span>Confirm</span>
                        </M2Button>
                    </template>
                </div>
            </Panel>
        </div>
    </Modal>
</template>
<script>
    import Modal from '@/components/Modal/Modal';
    import Panel from '../../../components/Panels/Panel';
    import M2Button from '../../../components/Buttons/M2Button';
    import Dropdown from '@/components/Dropdown/Dropdown';

    export default {
        name: 'DebugOptionRemoteConfigAddModal',
        components: {
            Modal,
            Panel,
            M2Button,
            Dropdown,
        },

        props: {
            configDropdown: {
                type: Array,
                required: true,
            },
            currentOverrides: {
                type: Array,
                required: true,
            },
            mobileSettings: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                resetDropdown: 0,
                form: {
                    id: null,
                    config: null,
                    enabled: true,
                    type: null,
                    suggestion: null,
                    value: null,
                },
                typeOptionList: [
                    { value: 'TEXT', label: 'Text' },
                    { value: 'NUMBER', label: 'Number' },
                    { value: 'BOOLEAN', label: 'Boolean' },
                ],
                filteredConfigDropdown: [],
            };
        },

        computed: {
            isDisabled() {
                return (
                    this.form.id === null ||
                    this.form.config === null ||
                    this.form.enabled === null ||
                    this.form.type === null ||
                    this.form.value === null
                );
            },

            settingValueList() {
                if (this.mobileSettings === null || this.form.config === null) {
                    return [];
                }

                const parentConfig = this.mobileSettings[this.form.config];
                const parentConfigValueList = [{ value: parentConfig.value, label: parentConfig.value }];
                const childConfigValueList = parentConfig.children.map((config) => ({
                    value: config.value,
                    label: config.value,
                }));

                if (parentConfig.children.length === 0) {
                    return parentConfigValueList;
                }

                if (parentConfig.childMode === 'MULTI_VARIATE') {
                    return childConfigValueList;
                }

                return [...parentConfigValueList, ...childConfigValueList];
            },
        },

        watch: {
            'form.config'(value) {
                this.form.id = this.mobileSettings[value].id;
                this.form.enabled = true;
                this.form.type = this.mobileSettings[value].type;
                this.form.suggestion = null;
                this.form.value = null;

                if (this.form.type === 'BOOLEAN') {
                    this.form.value = true;
                }
            },
        },

        mounted() {
            // Remove options from dropdown if already added
            const currentConfigOverrides = this.currentOverrides.map((override) => override.config);
            this.filteredConfigDropdown = this.configDropdown.filter(
                (dropdown) => !currentConfigOverrides.includes(dropdown.value),
            );
        },

        methods: {
            selectedSuggestion(value) {
                this.form.value = value;
                this.form.suggestion = null;
                this.resetDropdown++;
            },

            addRow() {
                this.$emit('add-row', this.form);
                this.$emit('close-modal');
            },
        },
    };
</script>
