<template>
    <table class="w-full bg-white overflow-hidden">
        <thead v-if="header" class="border-b bg-grey-lighter">
            <tr>
                <th
                    v-for="key in columns"
                    :key="key"
                    :class="{ 'bg-grey': sortKey == key }"
                    class="capitalize px-6 py-4 cursor-pointer"
                    @click="sortBy(key)"
                >
                    {{ key }}
                    <span class="arrow" :class="sortOrders[key] > 0 ? 'asc' : 'dsc'"></span>
                </th>
                <th v-if="action"></th>
            </tr>
        </thead>
        <tbody>
            <tr
                v-for="row in filteredData"
                :key="row.id"
                class="border-b group cursor-pointer"
                @click="handleRowClick(row)"
            >
                <td v-for="key in columns" :key="key" class="py-6 text-grey-darkest items-center">
                    {{ row[key] }}
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
    export default {
        props: {
            data: Array,
            columns: Array,
            header: {
                required: false,
                type: Boolean,
                default: false,
            },
            action: {
                required: false,
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                searchQuery: '',
                sortKey: '',
                sortOrders: {},
            };
        },
        computed: {
            filteredData: function () {
                let sortKey = this.sortKey;
                let filterKey = this.searchQuery && this.searchQuery.toLowerCase();
                let order = this.sortOrders[sortKey] || 1;
                let data = this.data;
                if (filterKey) {
                    data = data.filter(function (row) {
                        return Object.keys(row).some(function (key) {
                            return String(row[key]).toLowerCase().indexOf(filterKey) > -1;
                        });
                    });
                }
                if (sortKey) {
                    data = data.slice().sort(function (a, b) {
                        a = a[sortKey];
                        b = b[sortKey];
                        return (a === b ? 0 : a > b ? 1 : -1) * order;
                    });
                }
                return data;
            },
        },
        methods: {
            sortBy: function (key) {
                this.sortKey = key;
                this.sortOrders[key] = this.sortOrders[key] * -1;
            },
            handleRowClick: function (row) {
                this.$router.push(`/team/${row.name}`);
            },
        },
    };
</script>
