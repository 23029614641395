<template>
    <div>
        <div class="block mb-2">
            <input
                v-if="displayLink"
                v-model="element.children[0].attrs.href"
                type="text"
                class="p-2 border border-grey-300 rounded block w-full rounded"
                placeholder="Video url"
            />
        </div>
        <div class="block">
            <button
                class="w-full font-normal dashboard-button rounded-full p-2 disabled text-white bg-grey-500 bg-pink-muzmatch hover:shadow-md focus:outline-none cursor-pointer"
                @click="showLinkInput"
            >
                Add Link
            </button>
            <ImageUpload class="mr-3" @image-uploaded="updateImageView" />
        </div>
    </div>
</template>

<script>
    import ImageUpload from './ImageUpload';

    export default {
        name: 'PhotoVideoElement',

        components: {
            ImageUpload,
        },
        props: {
            element: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                displayLink: false,
            };
        },
        methods: {
            updateImageView(resp) {
                this.element.children[0].children[0].attrs.src = resp.url;
            },

            showLinkInput() {
                this.displayLink = true;
            },
        },
    };
</script>
