<template>
    <div class="pt-5 pb-5">
        <Panel title="Analytics">
            <div id="analytics-table-wrapper" class="p-4">
                <div v-if="steps.length && audience">
                    <template v-if="stats.length">
                        <div v-for="(stat, idx) in stats.slice().reverse()" :key="idx">
                            <div class="font-bold text-lg">Run {{ stats.length - idx }}</div>
                            <table class="table">
                                <tr>
                                    <th>Messages</th>
                                    <th>Open Rate</th>
                                    <th>Click Rate</th>
                                </tr>
                                <template v-for="(messageList, messageGroupIndex) in steps">
                                    <tr
                                        v-for="(message, index) in messageList.templates"
                                        :key="`${index}-${messageList.id}`"
                                        :class="messageGroupIndex !== 0 && index === 0 ? 'step-group' : ''"
                                    >
                                        <td>
                                            {{ templateTitle(message) }}
                                            <span class="text-sm text-grey-muzmatch">
                                                (Variation - {{ message.variation }})
                                            </span>
                                        </td>
                                        <td>{{ getOpenedPercentage(stat, message.id) }}</td>
                                        <td>{{ getOpenPercentage(stat, message.id) }}</td>
                                    </tr>
                                </template>
                            </table>
                        </div>
                    </template>
                    <template v-else-if="type == 'REENGAGEMENT' && Object.keys(reengagementStats).length > 0">
                        <div>
                            <table class="table">
                                <tr>
                                    <th>Messages</th>
                                    <th>Opens</th>
                                    <th>Clicks</th>
                                </tr>
                                <tr v-for="(stat, statKey) in reengagementStats" :key="statKey">
                                    <td>{{ templateTitle(stat.template) }} (Variation - {{ stat.variation }})</td>
                                    <td>{{ stat.opened ? stat.opened : '' }}</td>
                                    <td>{{ stat.clicked ? stat.clicked : '' }}</td>
                                </tr>
                            </table>
                        </div>
                    </template>
                    <p v-else>There are currently no stats</p>
                </div>
                <p v-else>
                    Please create a step first in the
                    <b>Messages</b>
                    tab
                </p>
            </div>
        </Panel>
    </div>
</template>

<script>
    import Panel from '../../components/Panels/Panel';
    export default {
        name: 'CampaignAnalytics',
        components: {
            Panel,
        },
        props: {
            steps: {
                type: Array,
                required: true,
            },
            stats: {
                type: Array,
                required: true,
            },
            audience: {
                type: Object,
                default: null,
            },
            type: {
                type: String,
                default: '',
            },
            reengagementStats: {
                type: Object,
            },
        },
        methods: {
            templateTitle(message) {
                switch (message.messageType) {
                    case 'IN_APP':
                        return message.messageBody_en.pushTitle || message.messageBody_en.messages[0] || '--';
                    case 'PUSH':
                        return message.messageBody_en.pushTitle || '--';
                    case 'EMAIL':
                        return message.messageBody_en.subject || '--';
                }
            },
            getOpenPercentage(stat, id) {
                if (!stat.items[`${id}.open`]) {
                    return `0%`;
                }

                const num = (Number(stat.items[`${id}.open`]) / Number(this.audience.estimatedAudienceSize)) * 100;

                return num.toFixed(2) + `% (${stat.items[`${id}.open`]}/${this.audience.estimatedAudienceSize})`;
            },
            getOpenedPercentage(stat, id) {
                if (!stat.items[`${id}.opened`]) {
                    return `0%`;
                }

                const num = (Number(stat.items[`${id}.opened`]) / Number(this.audience.estimatedAudienceSize)) * 100;

                return num.toFixed(2) + `% (${stat.items[`${id}.opened`]}/${this.audience.estimatedAudienceSize})`;
            },
        },
    };
</script>

<style scoped>
    #analytics-table-wrapper {
        overflow: scroll;
    }

    .step-group {
        border-top: 4px solid #b0b0b0;
    }

    table {
        border-collapse: collapse;
    }

    th {
        min-width: 300px;
    }

    th:first-child {
        min-width: 150px;
    }

    td,
    th {
        border: 2px solid #dddddd;
        text-align: left;
        padding: 1.2rem;
    }

    tr:first-child th {
        border-top: 0;
    }

    tr th:first-child {
        border-left: 0;
    }

    tr th:last-child {
        border-right: 0;
    }

    tr:first-child td {
        border-top: 0;
    }

    tr td:first-child {
        border-left: 0;
    }

    tr:last-child td {
        border-bottom: 0;
    }

    tr td:last-child {
        border-right: 0;
    }
</style>
