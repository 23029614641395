<template>
    <div class="h-full">
        <div class="fade sm:flex flex-row sm:-mx-2 h-full">
            <div class="mx-2 sm:w-1/4 flex flex-col flex-shrink-0">
                <div>
                    <input
                        v-model="emailFilter"
                        class="rounded p-2 border-grey-300 border w-full"
                        placeholder="Search..."
                    />
                </div>

                <div>
                    <EmailAddressSelector
                        v-for="emailAddress in emailAddresses"
                        :key="emailAddress.emailAddress"
                        :email-address="emailAddress"
                        :selected-email-address="selectedEmailAddress"
                        @thread-selected="setCurrentThread"
                        @email-address-selected="setCurrentEmailAddress"
                    />
                </div>
            </div>

            <EmailPanel
                v-if="thread"
                :thread="thread"
                :is-tab="true"
                :show-refresh-icon="false"
                :member-email-addresses="thread.memberEmailAddresses"
                :thread-email-addresses="thread.threadEmailAddresses"
                :is-task-manager="false"
                :context-type="contextType"
                context-action="CONTEXT_ACTION_MEMBER_EMAIL_PANEL"
                :context-detail="'CONTEXT_DETAIL_THREAD_' + thread.id"
                @set-thread="setCurrentThread"
            />
        </div>
    </div>
</template>

<script>
    import EmailPanel from '../../Panels/EmailPanel';
    import EmailAddressSelector from './EmailTab/EmailAddressSelector';
    import { dashboardAxios } from '../../../../axios.config';
    import { mapActions } from 'vuex';
    import checkGoogleAuth from '../../../helpers/checkGoogleAuth';
    import Vue from 'vue';

    export default {
        name: 'EmailsTab',

        components: {
            EmailPanel,
            EmailAddressSelector,
        },

        props: {
            profile: {
                type: Object,
                required: true,
            },
            contextType: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                emailFilter: '',
                emails: [],
                threads: null,
                thread: null,
                activeEmail: null,
                selectedEmailAddress: null,
                sortOrder: ['PRIMARY', 'OLD', 'OTHER'],
            };
        },

        computed: {
            filteredEmails() {
                if (!this.emailFilter) {
                    return this.emails;
                }

                return _.filter(this.emails, this.filterEmail);
            },
            emailAddresses() {
                if (!this.threads) return [];
                return this.sortByPriority(this.threads);
            },
        },

        async created() {
            try {
                await checkGoogleAuth(this);
                this.loadEmails();
            } catch (error) {
                this.handleError({ error });
                return;
            }
        },

        methods: {
            ...mapActions(['handleError']),
            filterEmail(email) {
                if (!this.profile) {
                    return true;
                }

                return email.toLowerCase().includes(this.emailFilter.toLowerCase());
            },

            sortByPriority(emailAddresses) {
                return _.sortBy(emailAddresses, (obj) => {
                    return _.indexOf(this.sortOrder, obj.type);
                });
            },

            setCurrentEmailContent(email) {
                this.activeEmail = email;
            },

            setCurrentThread(thread) {
                this.thread = thread;
            },

            setCurrentEmailAddress(selectedEmailAddress) {
                this.selectedEmailAddress = selectedEmailAddress;
            },

            async loadEmails() {
                try {
                    let response = await dashboardAxios.get(`/v1/tasks/email/member/${this.profile.memberID}/threads`);

                    this.threads = response.data.result;
                } catch (error) {
                    console.log(error);
                }
            },
        },
    };
</script>

<style scoped></style>
