<template>
    <Panel>
        <div slot="header" class="flex p-4 justify-between w-full">
            <div class="text-sm flex items-center">
                <div
                    v-if="!social"
                    class="pr-2"
                    :class="isChatView ? 'font-bold' : 'cursor-pointer'"
                    @click="setChatView()"
                >
                    Chat
                </div>
                <div class="px-2 border-black" :class="reportsClass" @click="setReportsView()">
                    {{ social ? 'Historical Social Reports' : 'Reports Received' }}
                </div>
            </div>

            <icon v-if="isChatView" icon="redo" class="cursor-pointer grey-light" @click="loadChat(false, true)"></icon>
            <icon v-if="isReportsView" icon="redo" class="cursor-pointer grey-light" @click="loadReports()"></icon>
        </div>

        <ChatPanelContents
            v-if="isChatView"
            :sender="sender"
            :receiver="receiver"
            :match-id="matchId"
            :custom-style="customStyle"
            :context-type="contextType"
            :context-action="contextAction"
            :loading="loading"
            :messages="messages"
            :current-page="currentPage"
            :total-pages="totalPages"
            @loadChat="loadChat"
            @nextPage="nextPage"
        />

        <ReportPanelContents
            v-if="isReportsView && !social"
            :custom-style="customStyle"
            :member="member"
            :member-loading="memberLoading"
            :reports-loading="reportsLoading"
            :reports="reports"
            @loadMember="loadMember"
            @loadReports="loadReports"
        />
    </Panel>
</template>

<script>
    import Vue from 'vue';
    import Panel from './Panel';
    import { mapActions } from 'vuex';
    import { dashboardAxios, socialModerationAxios } from '../../../axios.config';
    import ChatPanelContents from './Contents/ChatPanelContents';
    import ReportPanelContents from './Contents/ReportPanelContents';
    import EventBus from '@/helpers/eventBus';
    import moment from 'moment';
    export default {
        name: 'MultiChatPanel',

        components: {
            Panel,
            ChatPanelContents,
            ReportPanelContents,
        },

        props: {
            sender: {
                type: String,
                default: null,
            },

            receiver: {
                type: String,
                default: null,
            },

            matchId: {
                type: String,
                default: null,
            },

            customStyle: {
                type: String,
                default: '',
            },

            contextType: {
                type: String,
                required: true,
            },

            contextAction: {
                type: String,
                required: true,
            },

            isReportTask: {
                type: Boolean,
                required: true,
            },

            member: {
                type: Object,
                default: null,
            },

            memberLoading: {
                type: Boolean,
                required: true,
            },

            social: {
                type: Boolean,
            },

            queue: {
                type: Object,
            },

            accountUid: {
                type: String,
            },
        },

        data() {
            return {
                loading: true,
                messages: [],
                isChatView: false,
                isReportsView: true,
                reportsLoading: false,
                reports: [],
                currentPage: 1,
                totalPages: 1,
                socialReports: [],
            };
        },

        computed: {
            reportsClass() {
                let reportsClass = this.isReportsView ? 'font-bold' : 'cursor-pointer';
                if (!this.social) {
                    reportsClass += ' border-l-2';
                }
                return reportsClass;
            },
        },
        watch: {
            member() {
                this.updatePanel();
            },
            accountUid() {
                this.updatePanel();
            },
        },

        mounted() {
            this.setDefaultView();
        },
        methods: {
            ...mapActions({
                handleError: 'handleError',
            }),

            async nextPage() {
                this.currentPage++;
                this.loadChat(true);
            },

            async loadChat(silent = false, newMessages = false) {
                if (newMessages) {
                    this.currentPage = 1;
                    this.totalPages = 1;
                }
                if (!silent) {
                    this.loading = true;
                }

                if (!this.sender || !this.receiver) {
                    return;
                }

                let match = this.sender + '-' + this.receiver;

                try {
                    let response = await dashboardAxios.get('/v1/matches/' + match + '/messages/' + this.currentPage);

                    if (this.currentPage == 1) {
                        this.messages = response.data.result.messages;
                    } else {
                        this.messages = this.messages.concat(response.data.result.messages);
                    }

                    this.totalPages = response.data.result.total_pages;

                    this.loading = false;

                    let elements = document.getElementsByClassName('chat-messages');

                    let originalScrollHeight = elements.length > 0 ? elements[0].scrollHeight : 0;

                    Vue.nextTick(() => {
                        if (this.currentPage == 1) {
                            let elements = document.getElementsByClassName('chat-messages');

                            _.each(elements, (element) => {
                                element.scrollTop = element.scrollHeight;
                            });
                        } else {
                            let elements = document.getElementsByClassName('chat-messages');
                            if (elements.length > 0) {
                                elements[0].scrollTop = elements[0].scrollHeight - originalScrollHeight;
                            }
                        }
                    });
                } catch (e) {
                    this.handleError({ error: e });
                }
            },

            async loadReports() {
                if (this.reportsLoading) {
                    return;
                }

                if (!this.member) {
                    return;
                }

                this.reportsLoading = true;

                try {
                    let response = await dashboardAxios.get(
                        '/v1/members/' + this.member.hashMemberID + '/reports-received',
                    );

                    this.reports = response.data.result;
                } catch (error) {
                    console.error(error);

                    this.handleError({ error });
                }

                this.reportsLoading = false;
            },

            loadMember() {
                if (this.memberLoading) {
                    return;
                }

                EventBus.$emit('loadMember');
            },

            setReportsView() {
                this.isChatView = false;
                this.isReportsView = true;
            },

            setChatView() {
                this.isChatView = true;
                this.isReportsView = false;
            },

            setDefaultView() {
                if (this.isReportTask) {
                    this.setReportsView();
                    return;
                }

                this.setChatView();
            },

            updatePanel() {
                this.setDefaultView();
                this.loadReports();
            },

            time(time, format = 'DD/MM/YYYY HH:mm') {
                return moment(time).format(format);
            },
        },
    };
</script>
