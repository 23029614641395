<template>
    <div>
        <Panel title="Queue">
            <template #header>
                <div class="py-4 pl-2 pr-4">
                    <icon
                        :icon="!collapsed ? 'chevron-up' : 'chevron-down'"
                        class="cursor-pointer grey-light mr-4"
                        @click="collapsed = !collapsed"
                    ></icon>
                </div>

                <div class="ml-auto p-4">
                    <icon icon="redo" class="cursor-pointer grey-light" @click="loadCounts()" />
                </div>
            </template>

            <QueueList
                v-if="queues.length"
                :queues="queues"
                :selected="selectedQueue"
                :loading="countLoading"
                :collapsed="collapsed"
                @selected="selectQueue"
                @open="collapsed = false"
            />

            <div v-else class="flex justify-center items-center w-full h-48">
                <p class="text-5xl text-pink-muzmatch">
                    <icon icon="list"></icon>
                </p>
            </div>
        </Panel>
    </div>
</template>

<script>
    import QueueList from './QueueList';
    import { User } from '../../classes/User';
    import { mapActions, mapGetters } from 'vuex';
    import EventBus from '../../helpers/eventBus';
    import Panel from '../../components/Panels/Panel';
    import { dashboardAxios } from '../../../axios.config';
    import checkGoogleAuth from '../../helpers/checkGoogleAuth';

    export default {
        name: 'QueuedTasks',
        components: {
            QueueList,
            Panel,
        },
        props: {
            canUseReviewsPanel: {
                type: Boolean,
                default: false,
            },
            canUseIdentityVerification: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                queues: [],
                adminQueues: [],
                loading: false,
                collapsed: false,
                countLoading: false,

                counts: null,
                selectedQueue: null,
            };
        },
        computed: {
            ...mapGetters({
                staff: 'staff/getStaff',
            }),
            isAdmin() {
                return User.make(this.staff).can('task-manager:admin');
            },
            canUsePendingBlock() {
                return User.make(this.staff).can('task-manager:pending-block-admin');
            },
        },
        mounted() {
            this.loadCounts();

            EventBus.$on('refreshCount', () => {
                this.loadCounts();
            });
        },
        methods: {
            ...mapActions(['handleError', 'alert']),

            async loadCounts() {
                if (this.countLoading) {
                    return;
                }

                this.countLoading = true;

                let response = await dashboardAxios.get('/v1/task-manager-counts');

                this.counts = response.data.result;

                this.loadQueues();

                this.countLoading = false;
            },
            loadQueues() {
                this.queues = this.makeQueues(this.counts.unflagged);

                if (!this.isAdmin) {
                    return;
                }

                this.queues = [...this.queues];
            },
            makeQueues(counts, flagged = false) {
                let flagString = flagged ? '-flagged' : '';
                return [
                    {
                        url: 'approve' + flagString,
                        text: 'Approve',
                        count: counts['approve'],
                        loading: false,
                        escalated: false,
                        type: '',
                    },
                    {
                        url: 'inappropriate-profiles' + flagString,
                        text: 'Inappropriate Profile',
                        count: counts['inappropriate_profile'],
                        loading: false,
                        escalated: false,
                        type: '',
                    },
                    {
                        url: 'inappropriate-messages' + flagString,
                        text: 'Inappropriate Message',
                        count: counts['inappropriate_messages'],
                        loading: false,
                        escalated: false,
                        type: '',
                    },
                    {
                        url: 'spam-scam' + flagString,
                        text: 'Spam/Scam',
                        count: counts['spam_scam'],
                        loading: false,
                        escalated: false,
                        type: '',
                    },
                    {
                        url: 'other' + flagString,
                        text: 'Other Report',
                        count: counts['other'],
                        loading: false,
                        escalated: false,
                        type: '',
                    },
                    {
                        url: 'issue' + flagString,
                        text: 'Issue',
                        count: counts['issue'],
                        loading: false,
                        escalated: false,
                        type: '',
                    },
                    {
                        url: 'issue' + flagString + '/type/payments',
                        text: 'Payment',
                        count: counts['payment'],
                        loading: false,
                        escalated: false,
                        type: '',
                    },
                    {
                        url: 'email' + flagString,
                        text: 'Email',
                        count: counts['email'],
                        loading: false,
                        escalated: false,
                        type: '',
                    },
                    ...(this.canUseReviewsPanel
                        ? [
                              {
                                  url: 'app-reviews' + flagString,
                                  text: 'App Reviews',
                                  count: counts['app_reviews'],
                                  loading: false,
                                  escalated: false,
                              },
                          ]
                        : []),
                    ...(this.canUseIdentityVerification
                        ? [
                              {
                                  url: 'identity-verification' + flagString,
                                  text: 'Identity Verification',
                                  count: counts['identity_verification'],
                                  loading: false,
                                  escalated: false,
                              },
                          ]
                        : []),
                    ...(this.canUsePendingBlock
                        ? [
                              {
                                  url: 'pending-block-profiles' + flagString,
                                  text: 'Pending Block',
                                  count: counts['pending_block'],
                                  loading: false,
                                  escalated: false,
                              },
                          ]
                        : []),
                ];
            },
            async selectQueue(queue) {
                if (this.loading) {
                    return;
                }

                this.collapsed = true;
                this.loading = true;
                queue.loading = true;

                this.selectedQueue = queue;

                await this.loadTask();

                this.$emit('selected', this.selectedQueue);

                queue.loading = false;
                this.loading = false;
            },
            async loadTask() {
                if (!this.selectedQueue) {
                    return;
                }

                let response;

                if (this.selectedQueue.url === 'email') {
                    try {
                        await checkGoogleAuth(this);
                    } catch (error) {
                        this.handleError({ error });
                        return;
                    }
                }

                try {
                    response = await dashboardAxios.get('/v1/tasks/' + this.selectedQueue.url);

                    if (Array.isArray(response.data.result) && response.data.result.length == 0) {
                        this.selectedQueue.task = null;
                        this.alert({ message: 'No more tasks.', type: 'danger' });
                    } else {
                        this.selectedQueue.task = response.data.result;
                    }
                } catch (error) {
                    if (!error.response) {
                        this.alert({ message: 'An unknown error occurred.', type: 'danger' });

                        return callback();
                    }

                    let response = error.response;

                    if (response.status === 404) {
                        this.selectedQueue.task = null;

                        this.alert({ message: 'No more tasks.', type: 'danger' });
                        return;
                    }

                    this.handleError({ error });
                }
            },
        },
    };
</script>
