import { dashboardAxios } from '../../axios.config';
import { RedirectToLogin } from '../helpers/RedirectToLogin';

function handleError(error) {
    if (error.response.data.name !== 'TokenExpiredError') {
        throw err;
    }

    RedirectToLogin();
}

export default {
    store(staff) {
        return dashboardAxios.post(`v1/staff`, staff).catch(handleError);
    },

    update(staff) {
        return dashboardAxios.put(`v1/staff/${staff.id}`, staff).catch(handleError);
    },

    collection() {
        return dashboardAxios.get(`v1/staff`).catch(handleError);
    },

    search(token) {
        let config = {
            headers: { Authorization: 'Bearer ' + token },
        };

        return dashboardAxios.get('v1/auth/me', config).catch(handleError);
    },
};
