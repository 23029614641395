<script>
    /* eslint-disable */    
    export default {
        name: 'CampaignViewer',
        props: {
            nodes: {
                type: Object,
                required: true,
            },
        },
        updated() {
            this.html();
        },
        methods: {
            createComponent(dNode, h) {
                //   Handle empty elements and return empty array in case the dNode passed in is empty
                if (_.isEmpty(dNode)) {
                    return [];
                }
                // if the el is array call createComponent for all nodes
                if (_.isArray(dNode)) {
                    return dNode.map((child) => this.createComponent(child, h));
                }

                let children = [];

                if (dNode.children && dNode.children.length > 0) {
                    dNode.children.forEach((c) => {
                        if (_.isString(c)) {
                            children.push(c);
                        } else {
                            children.push(this.createComponent(c, h));
                        }
                    });
                }

                // Need to clone
                let properties = _.cloneDeep(dNode.properties),
                    style = _.cloneDeep(dNode.style),
                    attrs = _.cloneDeep(dNode.attrs),
                    cssClasses = _.cloneDeep(dNode.class),
                    domProps = _.cloneDeep(dNode.domProps),
                    scopedSlots = _.cloneDeep(dNode.scopedSlots),
                    nodeChildren = _.cloneDeep(dNode.children),
                    attributes = {
                        properties: properties,
                        attrs: attrs,
                        class: cssClasses,
                        style: style,
                        // children: nodeChildren,
                        scopedSlots: {
                            default: function (props) {
                                return this.createComponent(props, h);
                            },
                        },
                        slot: 'name-of-slot',
                        // domProps: domProps
                    };

                if (!dNode.children) {
                    attributes.domProps = domProps;
                }

                // console.log(dNode);
                let a = h(dNode.tagName, attributes, children.length > 0 ? children : dNode.textNode);

                if (a.attrs) {
                    a.attrs = dNode.attrs;
                    a.attrs.onclick = () => {
                        this.$emit('html-event', this.$el && this.$el.outerHTML);
                    };
                    this.$emit('html-event', this.$el && this.$el.outerHTML);
                }

                return a;
            },

            html() {
                this.$emit('html-event', this.$el && this.$el.outerHTML);
                return this.$el && this.$el.outerHTML;
            },
        },
        render: function (h) {
            return this.createComponent(this.nodes, h);
        },
    };
</script>

<style src="./email.css" scoped></style>
