<template>
    <transition name="modal">
        <div
            v-if="show"
            class="modal flex justify-center items-center w-full h-full fixed z-50"
            @click.self="$emit('closed')"
        >
            <div
                class="bg-white p-6 shadow rounded mx-4 w-full md:w-1/2 lg:w-1/3 xl:w-1/4 max-h-90 relative overflow-y-scroll"
            >
                <p class="font-bold text-lg mb-4">Search</p>

                <Search @searched="$emit('closed')"></Search>

                <div
                    class="absolute top-0 right-0 -mt-1 -mr-1 flex justify-center items-center bg-pink-muzmatch rounded-full w-8 h-8 cursor-pointer shadow"
                    @click="$emit('closed')"
                >
                    <icon icon="times" class="text-white"></icon>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import EventBus from '../../../helpers/eventBus';
    import Search from '../../../pages/Members/Search';

    export default {
        name: 'SearchModal',

        components: {
            Search,
        },

        props: {
            show: {
                type: Boolean,
                default: false,
            },
        },

        mounted() {
            EventBus.$on('globalKeyup', this.handleKeyup);
        },

        destroyed() {
            EventBus.$off('globalKeyup', this.handleKeyup);
        },

        methods: {
            handleKeyup(event) {
                if (event.key !== 'Escape') {
                    return;
                }

                this.$emit('closed');
            },
        },
    };
</script>

<style scoped>
    .modal {
        background-color: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0;
        right: 0;
        transition: opacity 100ms ease-in-out;
    }

    .top-0 {
        top: 0;
    }

    .right-0 {
        right: 0;
    }

    .modal-enter {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

    .max-h-90 {
        max-height: 90%;
    }
</style>
