<template>
    <div class="hover:bg-grey-100 p-2 rounded w-32 dropdown-item cursor-pointer" @click="buttonClick()">
        <slot />
    </div>
</template>

<script>
    export default {
        name: 'DropdownItem',

        props: {
            handleClick: {
                type: Function,
                default: async () => {},
            },

            handleError: {
                type: Function,
                default: async (error) => {},
            },

            confirmed: {
                type: Boolean,
                default: false,
            },

            confirmMessage: {
                type: String,
                default: 'This action is dangerous.',
            },
        },

        data() {
            return {
                loading: false,
            };
        },

        methods: {
            async buttonClick() {
                let response = { value: true };

                if (this.confirmed) {
                    response = await this.$swal({
                        title: 'Are you sure?',
                        icon: 'warning',
                        text: this.confirmMessage,
                        showCancelButton: true,
                        confirmButtonText: 'Continue',
                    });
                }

                if (!response.value) {
                    return;
                }

                if (this.loading) {
                    return;
                }

                this.loading = true;

                try {
                    await this.handleClick();
                } catch (error) {
                    await this.handleError(error);
                }

                this.loading = false;
            },
        },
    };
</script>
