<template>
    <div>
        <img
            v-if="!thumb.includes('.mp4')"
            class="cursor-pointer"
            :class="thumbClasses"
            :style="customStyle"
            :src="thumb"
            @click="showAttachmentModal = true"
        />

        <button
            v-if="thumb.includes('.mp4')"
            class="uppercase text-pink-muzmatch font-bold text-sm cursor-pointer"
            @click="showAttachmentModal = !showAttachmentModal"
        >
            Play Video
        </button>

        <video v-if="thumb.includes('.mp4') && showAttachmentModal" controls>
            <source :src="thumb" type="video/mp4" />
        </video>

        <vue-easy-lightbox
            move-disabled
            :visible="showAttachmentModal && !thumb.includes('.mp4')"
            :imgs="urls"
            :index="0"
            @hide="showAttachmentModal = false"
        />
    </div>
</template>

<script>
    import VueEasyLightbox from 'vue-easy-lightbox';

    export default {
        components: {
            VueEasyLightbox,
        },
        props: {
            thumbClasses: {
                type: String,
                default: '',
            },
            customStyle: {
                type: String,
                default: '',
            },
            thumb: {
                type: String,
                required: true,
            },
            urls: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                showAttachmentModal: false,
            };
        },
    };
</script>
