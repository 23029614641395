<template>
    <router-link class="no-underline" :to="memberRoute">
        <div class="w-full rounded shadow mb-4">
            <div class="p-4 cursor-pointer">
                <div class="flex justify-between items-center">
                    <div class="flex items-center">
                        <div>
                            <img v-if="member.premium === '1'" src="@/assets/logo-gold@3x.png" class="w-8" />
                            <img v-else src="@/assets/logo-mark@3x.png" class="w-8" />
                        </div>

                        <span class="font-bold text-pink-muzmatch ml-3">
                            {{ member.nickname }} ({{ member.age }}/{{ member.gender }})
                        </span>
                    </div>

                    <span
                        class="font-bold p-2 px-3 rounded-full"
                        :class="
                            member.approved === '1'
                                ? 'text-green-dark bg-green-lightest'
                                : 'text-red-dark bg-red-lightest'
                        "
                    >
                        {{ statusPill }}
                    </span>
                </div>
            </div>

            <div class="p-4 border-t border-grey-400 text-grey-800 break-words">
                {{ member.memberID }} | {{ timeStampRegistered }} | {{ lastActive }} | {{ member.emailAddress }} |
                {{ member.hashMemberID }} | {{ member.deviceOS }} ({{ member.appVersion }})
            </div>

            <div class="p-2 border-t border-grey-400 flex flex-wrap">
                <div v-for="photo in photos" :key="photo.url" class="xl:w-1/6 w-1/3 p-2">
                    <div class="flex">
                        <div class="font-bold text-pink-muzmatch">
                            <span>{{ photo.text }}</span>

                            <img
                                :src="
                                    photo.signedUrl
                                        ? photo.signedUrl
                                        : photo.text === 'Verification'
                                        ? resolveVerificationPhoto(photo.url, member.hashMemberID)
                                        : resolvePhoto(photo.url, member.hashMemberID)
                                "
                                class="rounded-lg w-full mt-2 h-80 photo"
                            />
                        </div>
                    </div>
                </div>
                <div class="flow-root"></div>
            </div>
        </div>
    </router-link>
</template>

<script>
    import { resolvePhotoUrl, resolveVerificationPhotoUrl } from '../../helpers/DashboardUtils';
    import moment from 'moment';
    import { getMemberRoute } from '../../helpers/RedirectToMember';

    export default {
        name: 'MemberSlimCard',

        props: {
            member: {
                type: Object,
                required: true,
            },
        },

        computed: {
            photos() {
                const mediaState = JSON.parse(this.member.mediaState);

                return [
                    {
                        url: mediaState?.complete?.image?.verification?.visible,
                        text: 'Verification',
                        signedUrl: this.member?.mediaSigned?.verification?.url,
                    },
                    {
                        url: mediaState?.complete?.image?.profile?.[1]?.visible,
                        text: 'Main Photo',
                        signedUrl: this.member?.mediaSigned?.profile?.[1]?.url,
                    },
                    {
                        url: mediaState?.complete?.image?.profile?.[2]?.visible,
                        text: 'Photo 2',
                        signedUrl: this.member?.mediaSigned?.profile?.[2]?.url,
                    },
                    {
                        url: mediaState?.complete?.image?.profile?.[3]?.visible,
                        text: 'Photo 3',
                        signedUrl: this.member?.mediaSigned?.profile?.[3]?.url,
                    },
                    {
                        url: mediaState?.complete?.image?.profile?.[4]?.visible,
                        text: 'Photo 4',
                        signedUrl: this.member?.mediaSigned?.profile?.[4]?.url,
                    },
                    {
                        url: mediaState?.complete?.image?.profile?.[5]?.visible,
                        text: 'Photo 5',
                        signedUrl: this.member?.mediaSigned?.profile?.[5]?.url,
                    },
                ];
            },

            timeStampRegistered() {
                return `Signed up ${moment(this.member.timeStampRegistered).fromNow()}.`;
            },

            lastActive() {
                return `Last active ${moment(this.member.lastActiveTimeStamp).fromNow()}.`;
            },

            memberRoute() {
                return getMemberRoute(this.member);
            },

            statusPill() {
                return this.member.statusPill.split(' : ')[0];
            },
        },

        methods: {
            resolvePhoto: resolvePhotoUrl,
            resolveVerificationPhoto: resolveVerificationPhotoUrl,
        },
    };
</script>

<style scoped>
    .photo {
        object-fit: cover;
    }

    @media (max-width: 767px) {
        .aspect-ratio {
            width: calc((100vw - 8rem) / 3);
            height: calc((100vw - 8rem) * 1.33 / 3);
        }
    }

    @media (min-width: 768px) {
        .aspect-ratio {
            width: calc((75vw - 8rem) / 3);
            height: calc((75vw - 8rem) * 1.33 / 3);
        }
    }

    @media (min-width: 1200px) {
        .aspect-ratio {
            width: calc((75vw - 8rem) / 6);
            height: calc((75vw - 8rem) * 1.33 / 6);
        }
    }
</style>
