<template>
    <div v-if="staff" id="app" class="font-avenir">
        <div id="nav">
            <component :is="layout">
                <router-view v-if="isTokenReady" :key="$route.path" />
            </component>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters, mapMutations } from 'vuex';
    import { User } from './classes/User';
    import * as Sentry from '@sentry/vue';
    import router from './router';

    const defaultLayout = 'default';

    export default {
        data: function () {
            return {
                prevRoute: null,
            };
        },

        computed: {
            ...mapGetters({
                staff: 'staff/getStaff',
                token: 'auth/getToken',
                isAppReady: 'getIsAppReady',
            }),
            layout() {
                return (this.$route.meta.layout || defaultLayout) + '-layout';
            },
            isTokenReady() {
                if (!this.$route.meta.needToken) {
                    return true;
                } else {
                    return this.token !== null && this.isAppReady;
                }
            },
        },

        mounted() {
            if (this.$route.query?.testError === 'true') {
                throw new Error('test');
            }

            switch (window.location.origin) {
                case 'https://wali.muzz.com':
                case 'https://wali.muzmatch.com':
                    document.title = 'Wali';
                    break;
                case 'https://wali.muzz.local':
                case 'http://localhost:8080':
                case 'http://localhost:8081':
                    document.title = '[LOCAL] Wali';
                    break;
                default:
                    document.title = '[DEV] Wali';
            }
        },

        beforeMount() {
            let token = this.$cookie.get('token');

            if (token !== null) {
                this.handleToken(token);

                return;
            }

            let referrer = window.parent.location.href;

            if (!referrer.includes('/login')) {
                return this.$router.push({
                    path: '/login',
                    query: { redirectTo: referrer },
                });
            }

            this.$router.push({ path: '/login' });
        },

        methods: {
            ...mapActions({
                requestCheckTokenExists: 'auth/requestCheckTokenExists',
                requestStaff: 'staff/requestStaff',
                requestSetToken: 'auth/requestSetToken',
                requestClearAuth: 'auth/requestClearAuth',
                alert: 'alert',
            }),

            ...mapMutations({
                setIsAppReady: 'setIsAppReady',
            }),

            hasPermissionForCurrentPage() {
                let permission = _.get(this.$route, 'meta.permission');

                if (!permission) {
                    return false;
                }

                return User.make(this.staff).can(permission);
            },

            async redirectToFirstPermission() {
                if (!this.staff.roles[0].productName) {
                    this.alert({
                        message: 'You done have any permission yet. Please contact admin to give you permission',
                        type: 'danger',
                    });

                    return;
                }

                if (this.staff.defaultHome) {
                    return this.$router.push({ path: `/${this.staff.defaultHome}` });
                }

                let permission = this.staff.roles[0].productName;

                await this.$router.push({ path: `/${permission}` });
            },

            async handleToken(token) {
                try {
                    await this.requestSetToken(token);
                    await this.requestStaff(token);

                    // If the current user doesn't have permission for this page.
                    if (!this.hasPermissionForCurrentPage()) {
                        // Redirect them to a page they have permission for.
                        await this.redirectToFirstPermission();
                    }

                    Sentry.setUser({ id: this.staff.id, email: this.staff.email });

                    await this.setIsAppReady(true);
                } catch {
                    let referrer = window.parent.location.href;

                    if (!referrer.includes('/login')) {
                        return this.$router.push({
                            path: '/login',
                            query: { redirectTo: referrer },
                        });
                    }

                    await this.$router.push({ path: '/login' });
                }
            },
        },
    };
</script>
