<template>
    <Panel v-if="shortcut" title="Shortcut">
        <div class="p-4">
            <div class="flex flex-row -mx-2">
                <div class="w-1/2 mx-2">
                    <label>Name</label>

                    <input
                        v-model="shortcut.name"
                        type="text"
                        class="p-2 bg-white border border-grey-400 rounded w-full mt-1"
                    />
                </div>

                <div class="w-1/2 mx-2">
                    <label>Shortcut</label>

                    <input
                        v-model="shortcut.keys"
                        type="text"
                        class="p-2 bg-white border border-grey-400 rounded w-full mt-1"
                    />
                </div>
            </div>

            <div class="mt-4">
                <label>Content</label>

                <textarea
                    v-model="shortcut.content"
                    class="p-2 bg-white border border-grey-400 rounded w-full mt-1 h-32"
                />
            </div>

            <div class="w-1/2 mt-4">
                <label>Type</label>
                <v-select v-model="shortcut.type" class="mt-1 style-chooser" taggable :options="types" />
            </div>

            <div class="flex flex-row justify-between mt-4">
                <SecondaryButton
                    text="Delete"
                    :confirmed="true"
                    confirm-message="This will delete the shortcut."
                    :handle-click="deleteShortcut"
                />

                <div class="flex justify-end">
                    <SecondaryButton class="mx-2" text="Cancel" :handle-click="() => $router.push('/shortcuts')" />

                    <PrimaryButton class="mx-2" text="Save" :handle-click="saveShortcut" />
                </div>
            </div>
        </div>
    </Panel>
</template>

<script>
    import Panel from '../../components/Panels/Panel';
    import { dashboardAxios } from '../../../axios.config';
    import { mapActions } from 'vuex';
    import PrimaryButton from '../../components/Buttons/PrimaryButton';
    import SecondaryButton from '../../components/Buttons/SecondaryButton';
    import vSelect from 'vue-select';
    import 'vue-select/dist/vue-select.css';

    export default {
        name: 'Show',

        components: {
            PrimaryButton,
            SecondaryButton,
            Panel,
            vSelect,
        },

        data() {
            return {
                loading: false,

                shortcut: {
                    name: '',
                    keys: '',
                    content: '',
                    type: 'OTHER',
                },

                types: [],
            };
        },
        watch: {
            'shortcut.type'() {
                if (this.shortcut.type) {
                    this.shortcut.type = this.shortcut.type.toUpperCase();
                } else {
                    this.shortcut.type = '';
                }
            },
        },
        created() {
            this.getTypes();
        },
        mounted() {
            let identifier = this.$route.params.id;

            let split = identifier.toString().split('.');

            if (split.length !== 2) {
                this.alert({ message: 'Malformed shortcut identifier.', type: 'danger' });

                return;
            }

            try {
                this.loadShortcut(Number.parseInt(split[1]));
            } catch (e) {
                this.handleError({ error: e });
            }
        },
        methods: {
            ...mapActions(['alert', 'handleError']),

            async loadShortcut(id) {
                try {
                    let response = await dashboardAxios.get('/v1/shortcuts/' + id);

                    this.shortcut = response.data.result;
                } catch (e) {
                    this.handleError({ error: e });
                }
            },

            async saveShortcut() {
                if (this.loading) {
                    return;
                }

                if (!(this.shortcut.name || this.shortcut.keys || this.shortcut.content)) {
                    this.alert({ message: 'Name, shortcut and content is required.', type: 'danger' });

                    return;
                }

                this.loading = true;

                try {
                    let response = await dashboardAxios.put('/v1/shortcuts/' + this.shortcut.id, this.shortcut);

                    this.shortcut = response.data.result;

                    this.alert({ message: 'Shortcut saved.', type: 'success' });

                    this.loading = false;

                    await this.$router.push('/shortcuts');
                } catch (e) {
                    this.handleError({
                        error: e,
                        callback: () => (this.loading = false),
                    });
                }
            },

            async deleteShortcut() {
                if (this.loading) {
                    return;
                }

                this.loading = true;

                try {
                    await dashboardAxios.delete('/v1/shortcuts/' + this.shortcut.id);

                    this.loading = false;

                    await this.$router.push('/shortcuts');
                } catch (e) {
                    this.handleError({
                        error: e,
                        callback: () => (this.loading = false),
                    });
                }
            },

            async getTypes() {
                try {
                    let response = await dashboardAxios.get('/v1/shortcuts-type');

                    this.types = response.data.result;
                } catch (e) {
                    this.handleError({
                        error: e,
                        callback: () => (this.loading = false),
                    });
                }
            },
        },
    };
</script>

<style>
    .style-chooser .vs__search,
    .style-chooser .vs__dropdown-toggle,
    .style-chooser .vs__dropdown-menu {
        text-transform: uppercase;
    }
</style>
