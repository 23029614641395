<template>
    <div>
        <div class="flex mb-4">
            <div class="w-1/5">
                <label><h4>MIN</h4></label>
                <p>Score: {{ boundaries.faceScoreRangeMin }}</p>
                <p>Area: {{ boundaries.faceAreaRangeMin }}</p>
                <p>Count: {{ boundaries.faceCountRangeMin }}</p>
                <p>Rotation: {{ boundaries.faceRotationRangeMin }}</p>
            </div>
            <div class="w-1/5">
                <label><h4>Boundary A</h4></label>
                <FaceForm
                    name="borderA"
                    :boundaries="boundaries"
                    :search="search.borderA"
                    @updateSearchParams="updateSearchParams"
                />
            </div>
            <div class="w-1/5">
                <label><h4>Boundary B</h4></label>
                <FaceForm
                    name="borderB"
                    :boundaries="boundaries"
                    :search="search.borderB"
                    @updateSearchParams="updateSearchParams"
                />
            </div>
            <div class="w-1/5">
                <label><h4>MAX</h4></label>
                <p>Score: {{ boundaries.faceScoreRangeMax }}</p>
                <p>Area: {{ boundaries.faceAreaRangeMax }}</p>
                <p>Count: {{ boundaries.faceCountRangeMax }}</p>
                <p>Rotation: {{ boundaries.faceRotationRangeMax }}</p>
            </div>
            <div class="w-1/5">
                <label><h4>Order</h4></label>
                <div class="flex">
                    <select
                        v-model.number="search.faceCountSortColumn"
                        class="ml-2 p-2 bg-white border border-grey-400 rounded w-2/5 mt-1"
                        required
                    >
                        <option>id</option>
                        <option>face_count</option>
                        <option>face_score</option>
                        <option>face_area</option>
                        <option>face_rotation</option>
                    </select>
                    <select
                        v-model="search.faceCountSortOrder"
                        class="ml-2 p-2 bg-white border border-grey-400 rounded w-2/5 mt-1"
                        required
                    >
                        <option>ASC</option>
                        <option>DESC</option>
                    </select>
                </div>
                <label><h4>Days</h4></label>
                <div class="flex">
                    <input
                        v-model="search.interval"
                        class="mr-2 p-2 bg-white border border-grey-400 rounded w-2/5 mt-1"
                        type="number"
                        step="1"
                        min="1"
                        max="365"
                    />
                </div>
                <div class="w-full mt-8 text-right">
                    <p>
                        <input id="approved" v-model="search.approved" type="checkbox" />
                        <label for="approved">Approved</label>
                    </p>
                    <p>
                        <input id="rotated" v-model="search.rotated" type="checkbox" />
                        <label for="rotated">Rotated</label>
                    </p>
                </div>
                <div class="w-full mt-8 text-right">
                    <button
                        class="py-2 px-4 text-white rounded focus:outline-none hover:shadow-md"
                        :class="loading ? 'bg-pink-lighter' : 'bg-pink-muzmatch'"
                        @mousedown="loading = true"
                        @mouseup="loading = false"
                        @mouseleave="loading = false"
                        @click="searchFaces()"
                    >
                        Search
                    </button>
                </div>
                <div>Total results: {{ totalResults }}</div>
            </div>
        </div>
        <div class="flex mb-4">
            <div class="w-1/3 ml-auto text-center">
                <FacePager
                    ref="cat1"
                    :category="1"
                    :search="search"
                    :boundaries="boundaries"
                    :total-results="totalResults"
                    @updateTotals="updateTotals"
                />
            </div>
            <div class="w-1/3 ml-auto text-center">
                <FacePager
                    ref="cat2"
                    :category="2"
                    :search="search"
                    :boundaries="boundaries"
                    :total-results="totalResults"
                    @updateTotals="updateTotals"
                />
            </div>
            <div class="w-1/3 ml-auto text-center">
                <FacePager
                    ref="cat3"
                    :category="3"
                    :search="search"
                    :boundaries="boundaries"
                    :total-results="totalResults"
                    @updateTotals="updateTotals"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import { dashboardAxios } from '../../../axios.config';
    import FaceForm from './FaceForm';
    import FacePager from './FacePager';

    export default {
        name: 'Face',

        components: {
            FaceForm,
            FacePager,
        },

        data() {
            return {
                loading: false,
                boundaries: {
                    faceScoreRangeMin: 0,
                    faceScoreRangeMax: 0,
                    faceAreaRangeMin: 0,
                    faceAreaRangeMax: 0,
                    faceCountRangeMin: 0,
                    faceCountRangeMax: 0,
                    faceRotationRangeMin: 0,
                    faceRotationRangeMax: 0,
                },
                search: {
                    faceCountSortColumn: 'id',
                    faceCountSortOrder: 'DESC',
                    borderA: {
                        faceScore: 0,
                        faceArea: 20,
                        faceCount: 1,
                    },
                    borderB: {
                        faceScore: 1,
                        faceArea: 40,
                        faceCount: 1,
                    },
                    approved: true,
                    approvedPhotos: true,
                    interval: 7,
                    rotated: false,
                },
                totals: {
                    category1: 0,
                    category2: 0,
                    category3: 0,
                },
            };
        },

        computed: {
            totalResults() {
                return this.totals.category1 + this.totals.category2 + this.totals.category3;
            },
        },

        mounted() {
            this.getBoundaries();
        },

        methods: {
            async getBoundaries() {
                try {
                    let path = this.generateBoundariesUrl();
                    let response = await dashboardAxios.post(path, this.search);
                    this.boundaries.faceScoreRangeMin = response.data.result[0].face_score_min;
                    this.boundaries.faceScoreRangeMax = response.data.result[0].face_score_max;
                    this.boundaries.faceAreaRangeMin = parseFloat(response.data.result[0].face_area_min) * 100;
                    this.boundaries.faceAreaRangeMax = parseFloat(response.data.result[0].face_area_max) * 100;
                    this.boundaries.faceCountRangeMin = response.data.result[0].face_count_min;
                    this.boundaries.faceCountRangeMax = response.data.result[0].face_count_max;
                    this.boundaries.faceRotationRangeMin = response.data.result[0].face_rotation_min;
                    this.boundaries.faceRotationRangeMax = response.data.result[0].face_rotation_max;

                    this.searchFaces();
                } catch (error) {
                    console.log(error);
                }
            },

            generateBoundariesUrl(url = '/v1/media/face-photos/boundaries') {
                return url;
            },

            searchFaces() {
                this.$refs.cat1.getMedia();
                this.$refs.cat2.getMedia();
                this.$refs.cat3.getMedia();
            },

            updateSearchParams(object) {
                this.search[object.name] = object.search;
            },

            updateTotals(object) {
                this.totals[object.category] = object.total;
            },
        },
    };
</script>
