<template>
    <div
        ref="thread-single-container"
        class="mt-1 ml-2 break-words rounded cursor-pointer hover:bg-grey-lightest p-2 flow-root"
        :class="{ 'bg-grey-lightest': isSelected }"
        @click="clicked"
    >
        <icon icon="chevron-right" class="text-xs mr-1" />
        <span>{{ thread.subject | threadSubject }}</span>
        <br v-if="width < 200" />
        <span class="text-grey-500" :class="{ 'float-right': width > 200 }">{{ thread.created | formatDate }}</span>
    </div>
</template>

<script>
    import moment from 'moment';
    export default {
        filters: {
            threadSubject: function (subject) {
                return subject ? subject : 'No subject found';
            },
            formatDate(date) {
                return moment.utc(date).format('DD/MM/YYYY');
            },
        },
        props: {
            selectedThreadId: {
                type: String,
                default: null,
            },
            thread: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                isSelected: false,
                width: 0,
            };
        },
        watch: {
            selectedThreadId(id) {
                if (this.thread.id !== id) {
                    this.isSelected = false;
                }
            },
        },
        mounted() {
            this.width = this.$refs['thread-single-container'] ? this.$refs['thread-single-container'].offsetWidth : 0;
        },
        methods: {
            clicked() {
                // if (this.isSelected) {
                //     this.isSelected = false;
                //     return;
                // }

                this.isSelected = true;
                this.$emit('fetch-thread', this.thread.id);
            },
        },
    };
</script>

<style lang="scss" scoped></style>
