import { captureException } from '@sentry/vue';
import axios from 'axios';
import { dashboardAxios } from '../../../axios.config';

export default {
    namespaced: true,

    state: {
        config: {
            Bucket: process.env.VUE_APP_CDN_BUCKET,
            region: process.env.VUE_APP_CDN_REGION,
            s3Url: process.env.VUE_APP_CDN_URL_PREFIX,
        },
        S3Client: {},
        signedURL: {},
    },

    actions: {
        async uploadFile({ commit, state, dispatch }, data) {
            let file = data.file,
                path = data.path,
                type = file.type;

            await dispatch('getSignedURL', {
                key: `email_campaigns/${path}/${file.name}`,
                type: type,
            });

            try {
                await axios({
                    method: 'put',
                    url: state.signedURL,
                    data: file,
                    transformRequest: [
                        function (data, headers) {
                            delete headers.common['Authorization'];
                            return data;
                        },
                    ],
                });
            } catch (error) {
                captureException(error);
            }
        },

        async getSignedURL({ commit }, file) {
            let response = await dashboardAxios.post('/v1/get-campaign-upload-url', file);

            commit('setSignedUrl', response.data.result);
        },
    },

    getters: {
        getConfig(state) {
            return state.config;
        },
    },

    mutations: {
        setSignedUrl(state, signedUrl) {
            state.signedURL = signedUrl;
        },
    },
};
