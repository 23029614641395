<template>
    <Modal v-if="showModal" :click-overlay="() => $emit('hide')" size="responsive" :no-padding="true">
        <div slot="body">
            <Panel title="Create Campaign" :has-margin="false" :custom-classes="['bg-pink-muzmatch', 'text-white']">
                <div class="p-4">
                    <div class="flex flex-wrap">
                        <div v-if="!importJson" class="w-full">
                            <label for="name-input">Title*</label>
                            <input
                                v-model="campaign.title"
                                class="focus:border-pink rounded-xs border border-grey w-full px-3 py-4 text-base mb-2"
                            />
                        </div>
                        <div v-if="importJson" class="w-full">
                            <label for="name-input">JSON*</label>
                            <textarea
                                v-model="campaign.json"
                                class="focus:border-pink rounded-xs border border-grey w-full px-3 py-4 text-base mb-2"
                            />
                            <span v-if="!validJson" class="text-red">Invalid JSON</span>
                        </div>
                    </div>
                    <div class="flex space-x-12">
                        <div class="w-1/2">
                            <m-2-button
                                v-if="importCampaignPermission"
                                :is-disabled="isLoading"
                                :custom-classes="['text-xs', 'normal-case', 'rounded']"
                                @clicked="importJson = !importJson"
                            >
                                <span v-if="!isLoading">Import Campaign</span>
                                <icon v-else class="animate-spin" icon="spinner" />
                            </m-2-button>
                        </div>
                        <div class="w-1/2">
                            <m-2-button
                                :is-disabled="isLoading || (importJson && !validJson)"
                                :custom-classes="['text-xs', 'normal-case', 'rounded']"
                                @clicked="createCampaign"
                            >
                                <span v-if="!isLoading">Confirm</span>
                                <icon v-else class="animate-spin" icon="spinner" />
                            </m-2-button>
                        </div>
                    </div>
                </div>
            </Panel>
        </div>
    </Modal>
</template>

<script>
    import M2Button from '../../components/Buttons/M2Button';
    import Modal from '../../components/Modal/Modal';
    import Panel from '../../components/Panels/Panel';
    import { messengerAxios } from '../../../axios.config';
    import { mapActions } from 'vuex';
    import { User } from '../../classes/User';
    import { mapGetters } from 'vuex';

    export default {
        components: {
            Modal,
            Panel,
            M2Button,
        },
        props: {
            showModal: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                isLoading: false,
                campaign: {
                    title: '',
                    json: '',
                },
                campaignJson: '',
                importJson: false,
            };
        },
        computed: {
            ...mapGetters({
                staff: 'staff/getStaff',
            }),
            importCampaignPermission() {
                return User.make(this.staff).can('messenger:import-campaign');
            },
            validJson() {
                if (this.importJson) {
                    try {
                        JSON.parse(this.campaign.json);
                    } catch (e) {
                        return false;
                    }
                    return true;
                }
                return true;
            },
        },
        methods: {
            ...mapActions(['alert']),

            handleInputChange(name, value) {
                this.campaign[name] = value;
            },

            async createCampaign() {
                let ajaxCampaign = Object.assign({}, this.campaign);
                if (!this.importJson && ajaxCampaign.title === '') {
                    return this.alert({
                        message: 'Title is required',
                        type: 'danger',
                    });
                }

                if (this.importJson) {
                    try {
                        JSON.parse(ajaxCampaign.json);
                    } catch (e) {
                        return this.alert({
                            message: 'Invalid JSON',
                            type: 'danger',
                        });
                    }
                } else {
                    delete ajaxCampaign.json;
                }

                this.isLoading = true;
                const response = await messengerAxios.post('/campaigns', ajaxCampaign);
                this.$router.push(`/messenger/campaign.${response.data.result.id}`);
                this.isLoading = false;
            },
        },
    };
</script>
