<template>
    <div class="ml-4">
        <panel title="Preview">
            <div slot="header" class="ml-auto p-4">
                <p v-if="isEmail" class="cursor-pointer text-sm" @click="showEditEmailModal = true">Edit</p>
            </div>
            <div class="bg-grey-100">
                <div class="font-bold flex items-center justify-center py-2 border-t-2 border-white">
                    <icon
                        class="text-grey-800 mr-4"
                        :class="{ 'text-grey-600': !canGoBack, 'cursor-pointer': canGoBack }"
                        icon="chevron-left"
                        @click="!canGoBack ? null : $emit('prev-template')"
                    />
                    <div class="flex flex-col items-center">
                        <div>{{ currentStep }}/{{ totalSteps }}</div>
                        <div class="text-xs bg-pink-muzmatch py-1 px-2 text-white rounded capitalize">{{ title }}</div>
                    </div>
                    <icon
                        class="text-grey-800 ml-4"
                        icon="chevron-right"
                        :class="{ 'text-grey-600': !canGoFwd, 'cursor-pointer': canGoFwd }"
                        @click="!canGoFwd ? null : $emit('next-template')"
                    />
                </div>
            </div>
            <div v-if="step.messageType != 'NO_MESSAGE'" class="flex">
                <div :key="previewKeyRefresh" class="w-10/12 border-r">
                    <div class="p-2">
                        <div v-if="hasNotification">
                            <div v-if="template.pushTitle || template.pushBody" class="flex space-x-2 items-center">
                                <div class="w-full">
                                    <push-notification
                                        ref="notification"
                                        :is-editing="isEditing"
                                        :notification="template"
                                        :language="selectedLanguage"
                                        :rtl="getSelectedLanguageRTL"
                                        @editing="isEditing = true"
                                        @blurred="updateParent"
                                    />
                                </div>
                                <div>
                                    <icon
                                        v-if="step.messageType === 'PUSH'"
                                        class="transform text-xs cursor-pointer"
                                        icon="edit"
                                        @click="showUrlModal = true"
                                    />
                                    <icon
                                        v-if="step.messageType === 'IN_APP'"
                                        class="transform rotate-45 text-xs cursor-pointer"
                                        icon="plus"
                                        @click="removePush"
                                    />
                                </div>
                            </div>

                            <div v-else class="w-full flex justify-center my-2">
                                <icon-button
                                    title="Add Push Notification"
                                    size="xs"
                                    icon="plus"
                                    rounded
                                    @click="addPush"
                                />
                            </div>
                        </div>

                        <div v-if="hasMessages">
                            <div>
                                <div
                                    v-for="(message, index) in template.messages"
                                    :key="index"
                                    class="flex space-x-2 items-center"
                                >
                                    <div class="w-11/12">
                                        <text-message-box
                                            ref="message"
                                            :message="message.text"
                                            :type="message.type"
                                            :is-editing="isEditing"
                                            :language="selectedLanguage"
                                            :rtl="getSelectedLanguageRTL"
                                            @editing="isEditing = true"
                                            @blurred="updateParent"
                                        />
                                    </div>
                                    <div class="w-1/12">
                                        <icon
                                            class="transform rotate-45 text-xs"
                                            :class="{ 'text-white': index === 0, 'cursor-pointer': index !== 0 }"
                                            icon="plus"
                                            @click="index === 0 ? null : removeMsg(index)"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="flex justify-center mt-4">
                                <icon-button rounded size="xs" icon="plus" @click="addMsg" />
                            </div>
                        </div>
                        <div v-if="isEmail" class="text-center">
                            <div class="w-11/12">
                                <dropdown
                                    ref="emailFrom"
                                    class="mb-3"
                                    placeholder="From Address"
                                    :is-capitalize="false"
                                    :default="template.from"
                                    :options="[
                                        {
                                            value: 'noreply@marketing.muzz.com',
                                            label: 'noreply@marketing.muzz.com',
                                        },
                                        {
                                            value: 'marketing@marketing.muzz.com',
                                            label: 'marketing@marketing.muzz.com',
                                        },
                                        {
                                            value: 'hello@marketing.muzz.com',
                                            label: 'hello@marketing.muzz.com',
                                        },
                                        { value: 'noreply@muzz.com', label: 'noreply@muzz.com' },
                                        { value: 'marketing@muzz.com', label: 'marketing@muzz.com' },
                                        { value: 'hello@muzz.com', label: 'hello@muzz.com' },
                                        { value: 'met@muzz.com', label: 'met@muzz.com' },
                                    ]"
                                    @updated="updateFromAddress"
                                />
                                <dropdown
                                    ref="emailFromName"
                                    class="mb-3"
                                    placeholder="From Name"
                                    :is-capitalize="false"
                                    :default="template.fromName || 'Muzz'"
                                    :options="[
                                        {
                                            value: 'Muzz',
                                            label: 'Muzz',
                                        },
                                        {
                                            value: 'Shahzad Younas',
                                            label: 'Shahzad Younas',
                                        },
                                        {
                                            value: 'Shahzad from Muzz',
                                            label: 'Shahzad from Muzz',
                                        },
                                        {
                                            value: 'Shahzad de chez Muzz',
                                            label: 'Shahzad de chez Muzz',
                                        },
                                        {
                                            value: 'Sim from Muzz',
                                            label: 'Sim from Muzz',
                                        },
                                        {
                                            value: 'Kareem from Muzz',
                                            label: 'Kareem from Muzz',
                                        },
                                        {
                                            value: 'Danish from Muzz',
                                            label: 'Danish from Muzz',
                                        },
                                        {
                                            value: 'Anisa from Muzz',
                                            label: 'Anisa from Muzz',
                                        },
                                        {
                                            value: 'Zohra from Muzz',
                                            label: 'Zohra from Muzz',
                                        },
                                        {
                                            value: 'Yasmina from Muzz',
                                            label: 'Yasmina from Muzz',
                                        },
                                        {
                                            value: 'Yasmina de chez Muzz',
                                            label: 'Yasmina de chez Muzz',
                                        },
                                        {
                                            value: 'Sarah de chez Muzz',
                                            label: 'Sarah de chez Muzz',
                                        },
                                        {
                                            value: 'Ayesha from Muzz',
                                            label: 'Ayesha from Muzz',
                                        },
                                        {
                                            value: 'Jemma from Muzz',
                                            label: 'Jemma from Muzz',
                                        },
                                        {
                                            value: 'Ria from Muzz',
                                            label: 'Ria from Muzz',
                                        },
                                        {
                                            value: 'Mariam from Muzz',
                                            label: 'Mariam from Muzz',
                                        },
                                        {
                                            value: 'Saqib from Muzz',
                                            label: 'Saqib from Muzz',
                                        },
                                        {
                                            value: 'Zeeshan from Muzz',
                                            label: 'Zeeshan from Muzz',
                                        },
                                        {
                                            value: 'Hasan from Muzz',
                                            label: 'Hasan from Muzzry',
                                        },
                                        {
                                            value: 'Nadeem from Muzz',
                                            label: 'Nadeem from Muzz',
                                        },
                                        {
                                            value: 'Nadia from Muzz',
                                            label: 'Nadia from Muzz',
                                        },
                                        {
                                            value: 'Fatima from Muzz',
                                            label: 'Fatima from Muzz',
                                        },
                                        {
                                            value: 'Sameera from Muzz',
                                            label: 'Sameera from Muzz',
                                        },
                                        {
                                            value: 'Sana from Muzz',
                                            label: 'Sana from Muzz',
                                        },
                                    ]"
                                    @updated="updateFromName"
                                />
                                <text-message-box
                                    ref="emailSubject"
                                    :message="template.subject"
                                    :is-editing="isEditing"
                                    :language="selectedLanguage"
                                    :rtl="getSelectedLanguageRTL"
                                    @editing="isEditing = true"
                                    @blurred="updateParent"
                                />
                            </div>
                            <div class="email">
                                <preview
                                    v-if="isEmail"
                                    :template="template.template"
                                    :show-panel="false"
                                    :lang="selectedLanguage"
                                    :rtl="getSelectedLanguageRTL"
                                    @save="updateParent"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-2/12">
                    <div v-for="lang in previewLanguages" :key="lang.name">
                        <div
                            v-if="langEnabled(lang.name)"
                            class="py-2 cursor-pointer"
                            :class="{
                                'bg-pink-muzmatch': selectedLanguage === lang.name,
                                'text-white': selectedLanguage === lang.name,
                                shadow: selectedLanguage === lang.name,
                                'border-b': selectedLanguage !== lang.name,
                            }"
                            @click="selectedLanguage = lang.name"
                        >
                            <div class="flex flex-col items-center justify-center">
                                <div class="text-xs mb-1">{{ lang.name }}</div>
                                <div>
                                    <img class="w-4" :src="lang.path" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="py-2 cursor-pointer text-center" @click="showLanguageModal = true">+</div>
                </div>
            </div>
            <div v-if="step.messageType != 'NO_MESSAGE'" class="flex space-x-1">
                <m2-button @clicked="showSendTestModal = true">Send Test</m2-button>
            </div>
        </panel>
        <select-language-modal
            v-if="showLanguageModal"
            :step="step"
            :preview-languages="previewLanguages"
            @close="showLanguageModal = false"
            @add-language="addLanguage"
        />
        <send-test-modal
            v-if="showSendTestModal"
            :selected-step="step"
            :selected-language="selectedLanguage"
            @close="showSendTestModal = false"
        />
        <edit-email-modal
            v-if="showEditEmailModal"
            :campaign="template"
            :lang="selectedLanguage"
            @close="showEditEmailModal = false"
            @html-event="$emit('html-event', { payload: $event, lang: selectedLanguage })"
            @save="
                $emit('update-step', $event);
                $emit('update-parent-can-save', true);
            "
        />
        <preview-add-url-target-modal
            v-if="showUrlModal"
            :selected-url-target="selectedUrlTarget"
            :url-target-list="modalUrlTargetList"
            @hide="showUrlModal = false"
            @submit="submitUrlTarget"
        />
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import M2Button from '../../components/Buttons/M2Button.vue';
    import Panel from '../../components/Panels/Panel.vue';
    import EditEmailModal from './EditEmailModal.vue';
    import PushNotification from './PushNotification.vue';
    import SendTestModal from './SendTestModal.vue';
    import TextMessageBox from './TextMessageBox.vue';
    import SelectLanguageModal from './SelectLanguageModal.vue';
    import PreviewAddUrlTargetModal from './PreviewAddUrlTargetModal';
    import Preview from '../../components/EmailBuilder/Preview.vue';
    import Dropdown from '../../components/Dropdown/Dropdown.vue';
    import { consoleSandbox } from '@sentry/utils';

    export default {
        components: {
            Panel,
            M2Button,
            TextMessageBox,
            SendTestModal,
            PushNotification,
            EditEmailModal,
            PreviewAddUrlTargetModal,
            SelectLanguageModal,
            Preview,
            Dropdown,
        },
        props: {
            step: {
                type: Object,
                required: true,
            },
            currentStep: {
                type: Number,
                required: true,
            },
            totalSteps: {
                type: Number,
                required: true,
            },
            currentGroupIndex: {
                type: Number,
                required: true,
            },
            urlTargetList: {
                type: Array,
                default: () => [],
            },
            previewUpdated: {
                type: Number,
                required: true,
            },
            language: {
                type: String,
                required: true,
            },
            previewLanguages: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                isEditing: false,
                showSendTestModal: false,
                showLanguageModal: false,
                showUrlModal: false,
                contentUpdated: false,
                originalNotification: null,
                originalMessages: null,
                nodes: null,
                showEditEmailModal: false,
                selectedUrlTarget: null,
                previewKeyRefresh: 0,
            };
        },
        computed: {
            selectedLanguage: {
                get() {
                    return this.language;
                },
                set(value) {
                    this.$emit('selected-language', value);
                },
            },
            template() {
                return this.step[`messageBody_${this.selectedLanguage.toLowerCase()}`];
            },
            title() {
                return this.step.messageType?.toLowerCase().replaceAll('_', ' ') ?? '';
            },
            modalUrlTargetList() {
                if (this.urlTargetList) {
                    let modalUrlTargetList = [];
                    this.urlTargetList.forEach((value) => {
                        modalUrlTargetList.push({ label: value.title, value: value.id });
                    });

                    return modalUrlTargetList;
                }

                return [];
            },
            canGoBack() {
                return this.currentStep > 1;
            },
            canGoFwd() {
                return this.currentStep < this.totalSteps;
            },
            enabledLanguages() {
                return Object.keys(this.step)
                    .filter((a) => a.includes('messageBody'))
                    .map((a) => a.replace('messageBody_', '').toUpperCase());
            },
            languageTemplate() {
                return this.enabledLanguages.reduce((a, v) => ({ ...a, [v]: '' }), {});
            },
            getSelectedLanguageRTL() {
                const foundLanguage = this.previewLanguages.find((language) => language.name === this.selectedLanguage);

                if (foundLanguage.rtl) {
                    return true;
                }

                return false;
            },
            hasNotification() {
                return ['IN_APP', 'PUSH'].includes(this.step.messageType);
            },
            hasMessages() {
                return ['IN_APP', 'IN_APP_NO_PUSH'].includes(this.step.messageType);
            },
            isEmail() {
                const value = ['EMAIL'].includes(this.step.messageType);
                this.$emit('update-is-email', value);
                return value;
            },
            renderMarkdown() {
                if (!this.nodes) return {};

                return {
                    ...this.nodes,
                    children: this.nodes.children.map((node) => {
                        let newChild = {
                            ...node,
                            domProps: {
                                innerHTML: node.textNode
                                    .replace(/(\*(.+)\*)/, (word) => `<b> ${word.split('*')[1]} </b>`)
                                    .replace(
                                        /(~(.+)~)/,
                                        (word) => `<span style="color: #fb406c"> ${word.split('~')[1]} </span>`,
                                    )
                                    .replace(
                                        /(\[(.+)\]\((.+)\))/,
                                        (word) =>
                                            `<a href="${word.split(/\((.+)\)/)[1]}"> ${word.split(/\[(.+)\]/)[1]} </a>`,
                                    ),
                            },
                        };

                        return newChild;
                    }),
                };
            },
        },
        watch: {
            currentStep() {
                // If user is switching step from preview screen, then clear edit changes
                this.updateStepDefaults();
            },
            currentGroupIndex() {
                this.updateStepDefaults();
            },
            previewUpdated() {
                this.previewKeyRefresh++;
            },
        },
        mounted() {
            this.updateStepDefaults();
        },
        methods: {
            ...mapActions(['confirmDelete', 'alert']),
            updateStepDefaults() {
                this.selectedUrlTarget = null;
                if (this.step.messageType === 'PUSH' && this.step.urlTargetID) {
                    this.selectedUrlTarget = this.step.urlTargetID;
                }
            },
            langEnabled(name) {
                return this.enabledLanguages.includes(name);
            },
            getKey(lang) {
                return `messageBody_${lang.toLowerCase()}`;
            },
            updateParent() {
                this.$nextTick(() => this.updateParentModel());
            },
            updateParentModel() {
                this.$emit('update-parent-can-save', true);
                this.isEditing = false;

                if (this.$refs.message) {
                    this.$refs.message.forEach((msg, i) => {
                        if (!msg.update) return;

                        this.$emit('update-msg', {
                            index: i,
                            text: msg.update,
                            type: msg.type,
                        });
                    });
                }

                if (this.$refs.notification) {
                    for (const item in this.$refs.notification.update) {
                        const update = this.$refs.notification.update[item];

                        if (!update) continue;

                        this.$emit('update-notif', {
                            item: item,
                            update: update,
                        });
                    }
                }

                if (this.$refs.emailSubject) {
                    const update = this.$refs.emailSubject.update;

                    if (!update) return;

                    this.$emit('update-email-subject', {
                        update,
                    });
                }
            },
            async removeMsg(index) {
                if (!(await this.confirmDelete({ vm: this }))) return;
                this.$emit('remove-msg', index);
                this.updateParent();
            },
            async addMsg() {
                const lastMessage = _.last(this.step[this.getKey(this.selectedLanguage)].messages);
                if (lastMessage && lastMessage.text.trim() === '') {
                    await this.alert({ message: "Previous message can't be empty", type: 'danger' });
                    return;
                }

                this.$emit('add-msg', { lang: this.selectedLanguage });
                this.updateParent();
            },
            submitUrlTarget(form) {
                this.selectedUrlTarget = form.urlTarget;
                this.$emit('update-url-target', form.urlTarget);
                this.$emit('update-parent-can-save', true);
                this.showUrlModal = false;
            },
            async removePush() {
                if (!(await this.confirmDelete({ vm: this }))) return;
                this.$emit('remove-push');
                this.updateParent();
            },
            addPush() {
                this.$emit('add-push');
                this.previewKeyRefresh++;
                this.updateParent();
            },
            updateFromAddress(email) {
                if (!email) return;

                this.$emit('update-email-from', {
                    email,
                });

                this.$emit('update-parent-can-save', true);
            },
            updateFromName(name) {
                if (!name) return;
                this.$emit('update-email-from-name', { name });
                this.$emit('update-parent-can-save', true);
            },
            addLanguage(name) {
                this.showLanguageModal = false;
                this.$emit('add-language-step', name);
                this.updateParent();
            },
        },
    };
</script>
