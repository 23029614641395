<template>
    <Panel title="Staff">
        <template #header>
            <div class="flex justify-end w-full">
                <div class="flex flex-row">
                    <div class="flex items-center">
                        <input
                            v-model="filter"
                            type="text"
                            placeholder="Search..."
                            class="p-2 rounded focus:outline-none w-full"
                        />
                    </div>

                    <div class="p-4 cursor-pointer" @click="$router.push('/team/add')">
                        <icon icon="plus"></icon>
                    </div>
                </div>
            </div>
        </template>

        <table class="w-full text-left">
            <thead>
                <tr class="bg-grey-200">
                    <th class="px-4 py-2">Name</th>
                </tr>
            </thead>
            <tbody>
                <template v-for="department in departmentList">
                    <tr :key="department.label" class="border bg-grey-400">
                        <td class="px-4 py-2">{{ department.label }}</td>
                    </tr>

                    <tr
                        v-for="staff in filteredTeam"
                        v-show="staff.wali_department_id == department.id"
                        :key="`${department.label}-${staff.id}`"
                        class="border hover:bg-grey-200 cursor-pointer"
                        @click="$router.push('/team/' + staff.name)"
                    >
                        <td class="px-4 py-2">{{ staff.name }}</td>
                    </tr>
                </template>
            </tbody>
        </table>
    </Panel>
</template>

<script>
    import Panel from '../../components/Panels/Panel';
    import { dashboardAxios } from '../../../axios.config';
    import { mapActions } from 'vuex';

    export default {
        components: {
            Panel,
        },
        data() {
            return {
                departmentList: [],
                team: [],
                filter: '',
            };
        },
        computed: {
            filteredTeam() {
                let team = this.team;

                team = _.sortBy(team, 'name');

                if (!this.filter) {
                    return team;
                }

                team = _.filter(team, (staff) => {
                    return JSON.stringify(staff).toLowerCase().includes(this.filter.toLowerCase());
                });

                return team;
            },
        },
        created() {
            this.loadDepartment();
            this.loadTeam();
        },
        methods: {
            ...mapActions(['handleError']),

            async loadDepartment() {
                try {
                    let response = await dashboardAxios.get('/v1/walidepartment');
                    this.departmentList = response.data.result;
                } catch (e) {
                    this.handleError({ error: e });
                }
            },

            async loadTeam() {
                try {
                    let response = await dashboardAxios.get('/v1/staff');
                    this.team = response.data.result;
                } catch (e) {
                    this.handleError({ error: e });
                }
            },
        },
    };
</script>
