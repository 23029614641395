function cleanNickname(nickname) {
    if (!nickname) {
        nickname = 'N/A';
    }

    nickname = nickname.replace(/\./g, '%2E');

    return encodeURIComponent(nickname);
}

function getMemberRoute(member) {
    if (!member) {
        throw `Member is not valid.`;
    }

    if (!member.hashMemberID) {
        throw `Member does not have hash.`;
    }

    let hash = member.hashMemberID;
    let nickname = cleanNickname(member.nickname);

    return {
        name: 'members-show',
        params: {
            memberHash: nickname + '.' + hash,
        },
    };
}

export { getMemberRoute };
