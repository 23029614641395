import { dashboardAxios } from '../../../axios.config';

export default {
    namespaced: true,

    state: {
        countries: [],
    },

    mutations: {
        setCountries(state, countries) {
            state.countries = countries;
        },
    },

    getters: {
        getCountries(state) {
            return state.countries;
        },
    },

    actions: {
        async loadCountries({ commit, state, dispatch }) {
            if (state.countries.length !== 0) {
                return;
            }

            try {
                let response = await dashboardAxios.get('/v1/meta/countries');

                commit('setCountries', response.data.result);
            } catch (e) {
                dispatch('handleError', { error: e }, { root: true });
            }
        },
    },
};
