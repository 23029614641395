import VueCookie from 'vue-cookie';

export const setToken = (state, token) => {
    if (token === null) {
        VueCookie.delete('token', { domain: process.env.VUE_APP_COOKIES_DOMAIN });

        state.token = null;

        return;
    }

    state.token = token;

    VueCookie.set('token', token, { domain: process.env.VUE_APP_COOKIES_DOMAIN, expires: '3M' });
};
