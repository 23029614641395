<template>
    <div>
        <div v-if="!obscenityOutput" class="text-center py-1 mt-1">No obscenity detection data.</div>

        <ul v-if="!showMore" class="text-center font-normal">
            <li
                v-if="output"
                :key="output.classifier"
                class="rounded mt-1 py-1"
                :class="obsceneClass(output.classifier, output.score)"
            >
                <div class="uppercase text-xs" v-text="output.classifier"></div>

                <span>Confidence: {{ output.score }}%</span>
            </li>

            <li
                v-if="obscenityOutput && results.length > 1"
                class="mt-1 py-1 hover:bg-pink-darker rounded"
                @click="showMore = true"
            >
                Show more...
            </li>
        </ul>

        <ul v-if="showMore" class="text-center font-normal">
            <li
                v-for="outputItem in results"
                :key="outputItem.classifier"
                class="rounded mt-1 py-1"
                :class="obsceneClass(outputItem.classifier, outputItem.score)"
            >
                <div class="uppercase text-xs" v-text="outputItem.classifier"></div>

                <span>Confidence: {{ outputItem.score }}%</span>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: 'ObsceneOutput',

        props: {
            obscenityOutput: {
                type: Object,
                default: null,
            },

            obscenityLimit: {
                type: Number,
                default: 50,
            },
        },

        data() {
            return {
                obsceneClassifiers: ['Porn', 'Sexy', 'Hentai', 'Flagged'],

                neutralClassifiers: ['Neutral', 'Drawing'],

                showMore: false,
            };
        },

        computed: {
            results() {
                return _.map(this.obscenityOutput, (score, classifier) => ({
                    classifier,
                    score: Math.round(score * 100),
                }));
            },

            output() {
                let data = _.maxBy(this.results, 'score');

                // If there is nothing don't show anything.
                if (!data) {
                    return null;
                }

                // If the score is okay, don't show it.
                if (data.score < this.obscenityLimit) {
                    return null;
                }

                // If the classifier is neutral, don't show it.
                if (this.neutralClassifiers.includes(data.classifier)) {
                    return null;
                }

                // Show it: score > obscenityLimit and classifier not in neutral classifiers.
                return data;
            },
        },

        methods: {
            obsceneClass(classifier, score) {
                if (this.obsceneClassifiers.includes(classifier) && score > this.obscenityLimit) {
                    return 'bg-black';
                }

                return 'bg-blue';
            },
        },
    };
</script>
