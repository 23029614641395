import Vue from 'vue';
import Vuex from 'vuex';
import * as Sentry from '@sentry/vue';
import staff from './staff';
import auth from './auth';
import member from './member';
import city from './city';
import country from './country';
import blast from './blast';
import search from './search';
import storage from './storage';
import router from '../router';
import { RedirectToLogin } from '../helpers/RedirectToLogin';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        staff,
        auth,
        member,
        city,
        country,
        blast,
        search,
        storage,
    },

    state: {
        alerts: [],
        isAppReady: false,
    },

    mutations: {
        alert(state, alert) {
            state.alerts.push(alert);
        },

        setAlerts(state, alerts) {
            state.alerts = alerts;
        },

        setIsAppReady(state, isReady) {
            state.isAppReady = isReady;
        },
    },

    getters: {
        getIsAppReady(state) {
            return state.isAppReady;
        },

        getAlerts(state) {
            return state.alerts;
        },
    },

    actions: {
        /**
         * @param commit
         * @param dispatch
         * @param message
         * @param type
         * @param time
         *
         * @returns {Promise<void>}
         */
        async alert({ commit, dispatch }, { message, type, time }) {
            if (!message) {
                throw `Message is required for an alert.`;
            }

            if (!type) {
                type = 'default';
            }

            if (!time) {
                time = 3000;
            }

            let alert = { message, type };

            commit('alert', alert);

            setTimeout(() => dispatch('deleteAlert', alert), time);
        },

        async confirmDelete(_, { vm, text, body = '' }) {
            if (!vm) {
                type = 'default';
            }

            if (!text) {
                text = 'Are you sure?';
            }

            let response = await vm.$swal.fire({
                title: text,
                text: body,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            });

            return response.value;
        },

        /**
         * @param state
         * @param commit
         * @param alert
         *
         * @returns {Promise<void>}
         */
        async deleteAlert({ state, commit }, alert) {
            let alerts = state.alerts;
            let index = alerts.indexOf(alert);

            state.alerts.splice(index, 1);

            commit('setAlerts', alerts);
        },

        /**
         * Handle errors from axios requests.
         *
         * @param dispatch
         * @param error
         * @param callback
         *
         * @returns {Promise<*>}
         */
        async handleError({ dispatch }, { error, callback }) {
            if (!callback) {
                callback = () => {};
            }

            if (!error.response) {
                dispatch('alert', { message: 'An unknown error occurred.', type: 'danger' });

                return callback();
            }

            let response = error.response;

            let errors = {
                401: () => {
                    RedirectToLogin();

                    return 'Unauthenticated.';
                },
                403: 'Unauthorised.',
                404: 'Unable to find that resource.',
                418: "I'm a teapot.",
                422: (response) => response.data.error.message,
                500: 'An unknown server error occurred.',
                503: 'Server currently unavailable, please try again later.',
            };

            let message = _.has(errors, response.status) ? errors[response.status] : 'An unknown error occurred.';

            if (message instanceof Function) {
                message = message(response);
            }

            dispatch('alert', { message, type: 'danger' });

            Sentry.captureException(error);

            return callback();
        },
    },
});
