<template>
    <div class="w-full mb-2 p-6 rounded-lg card border" :class="colour">
        <reported-issues v-if="showReportedIssues" :release="release" @hide="showReportedIssues = false" />
        <div class="flex items-center">
            <div>
                <platform-icon :platform="release.platform" custom-classes="h-4 text-color" />
                <span class="text-xl font-semibold text-color">{{ release.version }}</span>
            </div>
            <div v-show="!isUnreleased" class="flex-grow text-right items-center text-colour">
                <icon size="lg" icon="user" />
                <span class="text-xl ml-2">{{ users }}</span>
            </div>
        </div>
        <div class="flex mt-2">
            <pill :colour="statusColour">
                {{ status }}
            </pill>
            <pill v-show="!isUnreleased" class="ml-2" colour="bg-dau">{{ release.dau }}% DAU</pill>
            <pill v-show="isVersionWithMostUsers" class="ml-2" colour="bg-green">Most Active Version</pill>
            <pill v-show="isMinVersion" class="ml-2" colour="bg-min-version">Minimum Version</pill>
            <div v-if="canDelete" v-show="isUnreleased" class="flex-grow">
                <button class="py-1 text-sm px-4 text-pink-muzmatch rounded-lg float-right" @click="deleteRelease">
                    <svg class="h-4 w-4" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M19 24H5a2 2 0 01-2-2V6h18v16a2 2 0 01-2 2m-9-14a1 1 0 00-2 0v9a1 1 0 002 0v-9zm6 0a1 1 0 00-2 0v9a1 1 0 002 0v-9zm6-5H2V3h6V1.5C8 .673 8.673 0 9.5 0h5c.825 0 1.5.671 1.5 1.5V3h6v2zM10 3h4V2h-4v1z"
                            fill="#FB406C"
                        />
                    </svg>
                </button>
            </div>
        </div>
        <div class="mt-2">
            <div class="textarea rounded-lg bg-white border">
                <div class="md:flex sm:flex-wrap">
                    <div
                        class="py-4 px-2 border-b flex-auto text-center cursor-pointer"
                        :class="isActive === 'in_app_notes' ? 'border-pink-muzmatch' : 'text-'"
                        @click="makeActive('in_app_notes')"
                    >
                        <span
                            class="text-sm cursor-pointer"
                            :class="isActive === 'in_app_notes' ? 'text-pink-muzmatch font-semibold' : 'text-colour'"
                        >
                            In-App Notes
                        </span>
                    </div>
                    <div
                        class="py-4 px-2 border-b flex-auto text-center cursor-pointer"
                        :class="isActive === 'features_notes' ? 'border-pink-muzmatch' : ''"
                        @click="makeActive('features_notes')"
                    >
                        <span
                            class="text-sm cursor-pointer"
                            :class="isActive === 'features_notes' ? 'text-pink-muzmatch font-semibold' : 'text-colour'"
                        >
                            Features
                        </span>
                    </div>
                    <div
                        class="py-4 px-2 border-b flex-auto text-center cursor-pointer"
                        :class="isActive === 'bugfix_notes' ? 'border-pink-muzmatch' : ''"
                        @click="makeActive('bugfix_notes')"
                    >
                        <span
                            class="text-sm cursor-pointer"
                            :class="isActive === 'bugfix_notes' ? 'text-pink-muzmatch font-semibold' : 'text-colour'"
                        >
                            Bug Fixes
                        </span>
                    </div>
                    <div
                        class="py-4 px-2 border-b flex-auto text-center cursor-pointer"
                        :class="isActive === 'other_notes' ? 'border-pink-muzmatch' : ''"
                        @click="makeActive('other_notes')"
                    >
                        <span
                            class="text-sm cursor-pointer"
                            :class="isActive === 'other_notes' ? 'text-pink-muzmatch font-semibold' : 'text-colour'"
                        >
                            Other
                        </span>
                    </div>
                    <div class="py-4 flex-auto text-center border-b">
                        <icon
                            class="notes-arrow text-colour-grey"
                            :class="{ 'notes-open': isOpen }"
                            icon="chevron-down"
                            @click="isOpen = !isOpen"
                        />
                    </div>
                </div>
                <ul
                    v-if="showEditAble"
                    v-show="isOpen"
                    ref="editableContent"
                    class="outline-none text-color my-4 mx-8 list-disc"
                    :contenteditable="isEditing"
                    @click="editNotes"
                >
                    <li v-for="(text, idx) in model" :key="idx" class="my-2 mr-4">
                        <span v-if="isEditing">{{ text }}</span>
                        <span v-else v-html="renderMarkdown(text)"></span>
                    </li>
                </ul>
                <div v-show="isEditing" class="flex justify-between mb-3 mx-4">
                    <div class="text-xs text-gray-600">
                        <p>Supported markdown:</p>
                        <p>*text* - bold</p>
                        <p>_text_ - italic</p>
                        <p v-show="isActive !== 'in_app_notes'">[text](link) - link</p>
                    </div>
                    <div>
                        <button
                            class="py-1 text-sm px-4 bg-white text-pink-muzmatch rounded-lg border-2 border-pink-muzmatch mr-2"
                            @click="cancelText"
                        >
                            Cancel
                        </button>
                        <button class="py-1 text-sm px-4 bg-pink-muzmatch text-white rounded-lg" @click="saveText">
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex mt-4">
            <div v-if="canReleaseForPlatform" v-show="isUnreleased" class="flex justify-end flex-grow">
                <button
                    class="py-1 px-4 bg-white text-purple-muzmatch mr-2 text-sm font-semibold border-2 rounded-lg border-purple-muzmatch"
                    @click="releaseVersion"
                >
                    Release Version {{ release.version }}
                </button>
            </div>
            <div v-show="!isUnreleased" class="flex items-center text-color">
                <icon size="md" class="mr-2" icon="clock" />
                <span class="text-sm font-semibold">Released {{ release.released_date | formatDate }}</span>
            </div>
            <div v-show="!isUnreleased" class="flex justify-end flex-grow">
                <button
                    v-if="isMinAppVersionAdmin"
                    v-show="canShowMakeSuggested"
                    class="py-1 px-4 bg-white text-purple-muzmatch mr-2 text-sm font-semibold border-2 rounded-lg border-purple-muzmatch"
                    @click="updateSuggestedAppVersion"
                >
                    Make Suggested
                </button>
                <button
                    v-if="isMinAppVersionAdmin"
                    v-show="!isMinVersion && !isUnreleased"
                    class="py-1 px-4 bg-white text-purple-muzmatch mr-2 text-sm font-semibold border-2 rounded-lg border-purple-muzmatch"
                    @click="updateMinAppVersion"
                >
                    Make Minimum
                </button>
                <pill :class="{ 'cursor-pointer': issues !== '0' }" colour="issues-colour" @clicked="openIssuesModal">
                    <icon class="mr-2" icon="exclamation-triangle" />
                    {{ issues }} Issues
                </pill>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import PlatformIcon from './PlatformIcon';
    import ReportedIssues from './ReportedIssues';
    import Pill from './Pill';
    import { mapActions, mapGetters } from 'vuex';
    import { dashboardAxios } from '../../../axios.config';
    import { User } from '../../classes/User';

    export default {
        components: {
            PlatformIcon,
            Pill,
            ReportedIssues,
        },
        filters: {
            formatDate(date) {
                return date ? moment(date).format('DD/MM/YYYY') : '--';
            },
        },
        props: {
            release: {
                type: Object,
                required: true,
            },
            index: {
                type: Number,
                default: 0,
            },
            isBelowMinVersion: {
                type: Boolean,
                default: false,
            },
            isVersionWithMostUsers: {
                type: Boolean,
                default: false,
            },
            canSetSuggested: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                showReportedIssues: false,
                tempMessage: '',
                showAllNotes: false,
                isEditing: false,
                isActive: null,
                isOpen: false,
                showEditAble: true,
                isLoading: false,
            };
        },
        computed: {
            ...mapGetters({
                staff: 'staff/getStaff',
            }),
            isMinAppVersionAdmin() {
                return User.make(this.staff).can('app-versions:min-app-version');
            },
            isAdmin() {
                return User.make(this.staff).can('app-versions:admin');
            },
            canDelete() {
                return User.make(this.staff).can('app-versions:delete-app-version');
            },
            isUnreleased() {
                return this.release.released_date === null;
            },
            colour() {
                if (this.isUnreleased)
                    return {
                        'pending-release': true,
                    };

                if (!this.isBelowMinVersion) return { 'active-release': true };

                return { 'bg-white': true };
            },
            model() {
                if (this.release[this.isActive]) {
                    return this.release[this.isActive].split('\n');
                }

                this.resetField();
                return [''];
            },
            issues() {
                return this.release.reportedIssues;
            },
            hasIssues() {
                return this.release.reportedIssues !== '0';
            },
            users() {
                return this.release.userCount.toLocaleString();
            },
            status() {
                if (this.isBelowMinVersion) {
                    return 'Stale Release';
                }

                if (this.isUnreleased) {
                    return 'Pending Release';
                }

                return 'Live';
            },
            statusColour() {
                if (this.isBelowMinVersion) {
                    return 'bg-stale-release';
                }

                if (this.isUnreleased) {
                    return 'bg-pending-release';
                }

                return 'bg-pink-muzmatch';
            },
            isMinVersion() {
                return this.release.force_minimum_version === '1';
            },
            canShowMakeSuggested() {
                return (
                    this.release.suggested_minimum_version !== '1' &&
                    this.release.released_date !== null &&
                    this.canSetSuggested
                );
            },
            releasedDate() {
                if (this.isUnreleased) {
                    return '--';
                }

                return this.release.released_date;
            },
            canReleaseForPlatform() {
                return User.make(this.staff).can(`app-versions:release-${this.release.platform}`);
            },
        },
        created() {
            this.openCorrectNotesTab();
        },
        methods: {
            ...mapActions(['alert', 'handleError']),
            renderMarkdown(text) {
                if (/^(\*.+\*)/.test(text)) {
                    return `<strong>${text.replaceAll('*', '')}</strong>`;
                }

                if (/^(\_.+\_)/.test(text)) {
                    return `<em>${text.replaceAll('_', '')}</em>`;
                }

                if (this.isActive === 'in_app_notes') return text;

                const regexMdLinks = /\[([^\[]+)\](\([^)]+\))/gm;

                const matches = text.match(regexMdLinks);
                const singleMatch = /\[([^\[]+)\](\([^)]+\))/;
                if (matches !== null) {
                    for (var i = 0; i < matches.length; i++) {
                        const matchText = singleMatch.exec(matches[i]);
                        text = text.replaceAll(
                            matchText[0],
                            `<a class="text-pink-muzmatch underline" target="_blank" href='${matchText[2].slice(
                                1,
                                -1,
                            )}'>${matchText[1]}</a>`,
                        );
                    }
                }

                return text;
            },
            openCorrectNotesTab() {
                for (const note of ['in_app_notes', 'features_notes', 'bugfix_notes', 'other_notes']) {
                    if (!this.release[note] || this.release[note] === '') {
                        continue;
                    }

                    this.isActive = note;
                    if (!this.isBelowMinVersion) this.isOpen = true;
                    break;
                }

                if (!this.isActive) this.isActive = 'in_app_notes';
            },
            editNotes(e) {
                // if user clicks a link open the link instead of enable edit mode
                if (e.target.tagName === 'A') return;

                if (!this.isAdmin) return;

                this.isEditing = true;
                this.message = [];
            },
            updateText(e) {
                this.tempMessage = e.target.innerText;
            },
            resetField() {
                this.showEditAble = false;
                this.$nextTick(() => {
                    this.showEditAble = true;
                });
            },
            async makeActive(type) {
                if (await this.checkIfEditing()) {
                    return;
                }

                this.isOpen = true;
                this.isActive = type;
            },
            openIssuesModal() {
                if (!this.hasIssues) {
                    return;
                }

                this.showReportedIssues = true;
            },
            cancelText() {
                this.resetField();
                this.isEditing = false;
            },
            saveText() {
                // eslint-disable-next-line vue/no-mutating-props
                this.release[this.isActive] = this.$refs.editableContent.innerText
                    .split('\n')
                    .filter(Boolean)
                    .join('\n');
                this.resetField();
                this.saveReleaseNote();
            },
            async deleteRelease(version) {
                if (!(await this.confirmDelete())) {
                    return;
                }

                try {
                    const resp = await dashboardAxios.delete(`/v1/release-notes/${this.release.id}`, this.release);

                    this.$emit('releases-updates', resp.data.result);

                    this.alert({
                        message: `Successfully deleted ${this.release.version}`,
                        type: 'success',
                    });
                } catch {
                    this.alert({
                        message: `Something went wrong trying to delete ${this.release.version}`,
                        type: 'danger',
                    });
                }
            },
            async releaseVersion() {
                if (!(await this.confirmRelease())) {
                    return;
                }

                try {
                    const resp = await dashboardAxios.put(`/v1/release-notes/${this.release.id}/release`);

                    this.$emit('releases-updates', resp.data.result);

                    this.alert({
                        message: `Successfully released ${this.release.version}`,
                        type: 'success',
                    });
                } catch {
                    this.alert({
                        message: `Something went wrong trying to release ${this.release.version}`,
                        type: 'danger',
                    });
                }
            },
            async saveReleaseNote() {
                if (this.isLoading) {
                    return;
                }

                if (!(this.release.platform || this.release.released_date || this.release.version)) {
                    this.alert({
                        message: 'Version, Platform and Released Date is required.',
                        type: 'danger',
                    });

                    return;
                }

                this.isLoading = true;

                try {
                    const resp = await dashboardAxios.put(`/v1/release-notes/${this.release.id}`, this.release);

                    this.isLoading = false;
                    this.alert({
                        message: `Succesfully updated notes for version ${this.release.version}`,
                        type: 'success',
                    });
                } catch (e) {
                    console.log(e);
                    this.handleError({
                        error: e,
                        callback: () => (this.isLoading = false),
                    });
                }
                this.isEditing = false;
            },
            async checkIfEditing() {
                if (this.isEditing) {
                    const response = await this.$swal({
                        title: 'Are you sure?',
                        icon: 'warning',
                        text: 'All of your changes will be lost unless you save the notes before changing tabs',
                        showCancelButton: true,
                        cancelButtonText: 'Go back',
                        confirmButtonText: "I'm okay with losing my changes",
                    });

                    if (response.value) {
                        return false;
                    }

                    return true;
                }

                return false;
            },
            async confirmForceMinVersion() {
                const alert = await this.$swal({
                    title: 'Are you sure?',
                    icon: 'warning',
                    text: `This will make this app version (${this.release.version}) the required minimum version!`,
                    showCancelButton: true,
                    confirmButtonText: 'Understood',
                });
                return alert.value || false;
            },
            async confirmMakeSuggestedVersion() {
                const alert = await this.$swal({
                    title: 'Are you sure?',
                    icon: 'warning',
                    text: `This will make this app version (${this.release.version}) the suggested version!`,
                    showCancelButton: true,
                    confirmButtonText: 'Understood',
                });
                return alert.value || false;
            },
            async confirmDelete() {
                const alert = await this.$swal({
                    title: 'Are you sure?',
                    icon: 'warning',
                    text: `This will delete this app version (${this.release.version})!`,
                    showCancelButton: true,
                    confirmButtonText: 'Understood',
                });
                return alert.value || false;
            },
            async confirmRelease() {
                const alert = await this.$swal({
                    title: 'Are you sure?',
                    icon: 'warning',
                    text: `This will release this app version (${this.release.version})!`,
                    showCancelButton: true,
                    confirmButtonText: 'Understood',
                });
                return alert.value || false;
            },
            async updateSuggestedAppVersion() {
                try {
                    if (!(await this.confirmMakeSuggestedVersion())) {
                        return;
                    }

                    const resp = await dashboardAxios.put(
                        `/v1/release-notes/${this.release.id}/make-suggested-version`,
                        this.release,
                    );
                    this.$emit('releases-updates', resp.data.result);

                    this.alert({
                        message: `Successfully made ${this.release.version} the suggested app version`,
                        type: 'success',
                    });
                } catch {
                    this.alert({
                        message: 'Something went wrong trying to make this release the suggested app version.',
                        type: 'danger',
                    });
                }
            },
            async updateMinAppVersion() {
                try {
                    if (!(await this.confirmForceMinVersion())) {
                        return;
                    }

                    const resp = await dashboardAxios.put(
                        `/v1/release-notes/${this.release.id}/force-minimum-version`,
                        this.release,
                    );

                    this.$emit('releases-updates', resp.data.result);

                    this.alert({
                        message: `Successfully made ${this.release.version} the minimum required app version`,
                        type: 'success',
                    });
                } catch {
                    this.alert({
                        message: 'Something went wrong trying to make this release the minimum app version.',
                        type: 'danger',
                    });
                }
            },
        },
    };
</script>

<style scoped>
    :empty {
        display: block;
        height: 1em;
    }

    .card * {
        font-family: 'Spezia';
    }

    .active-release {
        background: #f0f4fc;
    }

    .text-color {
        color: #2e3b4b;
    }

    .text-colour-grey {
        color: #c3c9d0;
    }

    .issues-colour {
        background: #ff971d;
    }

    .most-popular-release {
        background: #a3e6c0;
    }
    .pending-release {
        background: #e4e6e9;
    }

    .bg-pending-release {
        background: #516375;
    }

    .bg-stale-release {
        background: #a8b1ba;
    }

    .bg-dau {
        background: #2b3c4d;
    }

    .bg-min-version {
        background: #65355f;
    }

    .text-pending-release {
        color: #9a9b9c;
    }
    .text-muzmatch-pink {
        color: #fb406c;
    }

    .notes-arrow {
        transition: all 0.4s ease;
    }

    .notes-open {
        transform: rotate(180deg);
    }
</style>
