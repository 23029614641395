<template>
    <div>
        <TabsList :current="activeItem" @setActive="setActive" />

        <!-- TABS -->
        <div class="relative">
            <div class="sm:p-4 py-4 tabs-height overflow-y-scroll">
                <!-- Member Details -->
                <DetailsTab v-if="isActive('member-details') && profile" :profile="profile" />

                <!-- Filters -->
                <FiltersTab v-if="isActive('search-filters') && profile" :profile="profile" />

                <!-- Reports Received -->
                <ReportsReceivedTab v-if="isActive('reports-received') && profile" :profile="profile" />

                <!-- Reports Made -->
                <ReportsMadeTab v-if="isActive('reports-made') && profile" :profile="profile" />

                <!-- Issues -->
                <IssuesTab v-if="isActive('issues') && profile" :profile="profile" />

                <!-- Marriage Chats -->
                <MessagesTab
                    v-if="isActive('message-history') && profile"
                    id="message-history"
                    :profile="profile"
                    :matches="matches"
                    :active-chat="activeChat"
                    :context-type="contextType"
                    @send-message-history="sendMessageHistory"
                    @set-current-chat-content="setCurrentChatContent"
                    @reload-matches="loadMatches"
                />

                <!-- Social Chats -->
                <SocialChatsTab
                    v-if="isActive('social-chats') && profile"
                    id="social-chats"
                    :profile="profile"
                    :conversations="socialChats"
                    :active-chat="activeSocialChat"
                    :context-type="contextType"
                    @set-current-chat-content="setCurrentSocialChatContent"
                    @reload-matches="loadSocialChats"
                />

                <!-- Email History -->
                <EmailsTab
                    v-if="isActive('emails-history') && profile"
                    :profile="profile"
                    :context-type="contextType"
                />

                <!-- Transactions -->
                <ProfileContentTab v-if="isActive('photo-profile-history') && profile" :profile="profile" />

                <!-- Auto approve decisions -->
                <ApprovalHistoryTab
                    v-if="isActive('approval-history') && profile"
                    :endpoint="'/v1/approvalHistory/' + profile.hashMemberID"
                    :show-member-i-d="false"
                />

                <!-- Photo History -->
                <TransactionsTab v-if="isActive('transactions') && profile" :profile="profile" />

                <!-- Logs -->
                <LogsTab v-if="isActive('logs') && profile" :profile="profile" />

                <!-- Staff Logs -->
                <StaffLogsTab v-if="isActive('staff-logs') && profile" :profile="profile" />

                <!-- Boosts -->
                <BoostsTab v-if="isActive('member-boosts') && profile" :profile="profile" />

                <!-- Discover -->
                <DiscoverTab v-if="isActive('member-discover') && profile" :profile="profile" />

                <!-- IC -->
                <ICTab v-if="isActive('member-ic') && profile" :profile="profile" />

                <IcebreakersTab
                    v-if="isActive('icebreakers') && profile"
                    :profile="profile"
                    @refresh="$emit('refresh')"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import TabsList from './TabsList';

    import DetailsTab from './Tabs/DetailsTab';
    import FiltersTab from './Tabs/FiltersTab';
    import ReportsReceivedTab from './Tabs/ReportsReceivedTab';
    import ReportsMadeTab from './Tabs/ReportsMadeTab';
    import IssuesTab from './Tabs/IssuesTab';
    import MessagesTab from './Tabs/MessagesTab';
    import SocialChatsTab from './Tabs/SocialChatsTab.vue';
    import EmailsTab from './Tabs/EmailsTab';
    import TransactionsTab from './Tabs/TransactionsTab';
    import LogsTab from './Tabs/LogsTab';
    import StaffLogsTab from './Tabs/StaffLogsTab';
    import BoostsTab from './Tabs/BoostsTab';
    import DiscoverTab from './Tabs/DiscoverTab';
    import ICTab from './Tabs/ICTab';
    import IcebreakersTab from './Tabs/IcebreakersTab';

    import { time } from '../../helpers/DashboardUtils';
    import { dashboardAxios } from '../../../axios.config';
    import Vue from 'vue';
    import EventBus from '../../helpers/eventBus';
    import ProfileContentTab from '@/components/Dashboard/Tabs/ProfileContent.vue';
    import ApprovalHistoryTab from '@/components/Dashboard/Tabs/ApprovalHistoryTab.vue';

    export default {
        name: 'DashboardTabs',

        components: {
            ApprovalHistoryTab,
            ProfileContentTab,
            TabsList,
            DetailsTab,
            FiltersTab,
            ReportsReceivedTab,
            ReportsMadeTab,
            IssuesTab,
            MessagesTab,
            SocialChatsTab,
            EmailsTab,
            TransactionsTab,
            LogsTab,
            StaffLogsTab,
            BoostsTab,
            DiscoverTab,
            ICTab,
            IcebreakersTab,
        },

        props: {
            profile: {
                type: Object,
                required: true,
            },

            chatHashId: {
                type: String,
                default: null,
            },

            contextType: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                activeItem: 'reports-received',
                matches: [],
                socialChats: [],
                activeChat: null,
                activeSocialChat: null,
                matchId: '',
                muzmatchMemberHash: '69d6354927d12aabab64c15843f5e786cfabe1fba511e6190622eb392856dffb',
            };
        },

        async mounted() {
            await this.loadMatches();
            await this.loadSocialChats();

            Vue.nextTick(() => {
                if (!this.$route.params.matchId) {
                    return;
                }

                let matchId = this.$route.params.matchId;

                this.openChat(this.chatHashId, matchId);
            });

            EventBus.$on('loadChat', this.openChat);
        },

        methods: {
            isActive(menuItem) {
                return this.activeItem === menuItem;
            },

            setActive(menuItem) {
                this.activeItem = menuItem;
            },

            openChat(memberHash, matchId) {
                this.activeItem = 'message-history';

                this.scrollToDashboardPanel();

                if (!memberHash && !matchId) {
                    return;
                }

                let matches = matchId
                    ? _.filter(this.matches, { id: matchId })
                    : _.filter(this.matches, this.filterMatchesByMemberHash(memberHash));

                this.scrollToMessages();

                if (matches.length === 1) {
                    this.activeChat = matches[0];
                    return;
                }

                if (memberHash !== this.muzmatchMemberHash) {
                    return this.openChat(this.muzmatchMemberHash);
                }

                this.activeChat = null;
            },

            filterMatchesByMemberHash(memberHash) {
                return (match) => match.member1.id === memberHash || match.member2.id === memberHash;
            },

            scrollToDashboardPanel() {
                let dashboardElement = document.getElementById('dashboard-panel');

                if (dashboardElement) {
                    dashboardElement.scrollTop = dashboardElement.scrollHeight;
                }
            },

            scrollToMessages() {
                let messageElement = document.getElementById('message-history');

                if (messageElement) {
                    let messageElementTop = messageElement.getBoundingClientRect().top;
                    window.scrollTo(0, messageElementTop);
                }
            },

            async loadMatches() {
                try {
                    let response = await dashboardAxios.get(`/v1/matches/${this.profile.hashMemberID}`);

                    this.matches = response.data.result;

                    Vue.nextTick(() => {
                        if (!this.chatHashId && !this.$route.params.matchId) {
                            return;
                        }

                        let matchId;

                        if (!this.chatHashId) {
                            matchId = this.$route.params.matchId;
                        }

                        this.openChat(this.chatHashId, matchId);
                    });
                } catch (error) {
                    console.log(error);
                }
            },

            async loadSocialChats() {
                try {
                    let response = await dashboardAxios.get(`/v1/social-chats/${this.profile.socialProfileUID}`);

                    this.socialChats = response.data.result;
                } catch (error) {
                    console.log(error);
                }
            },

            sendMessageHistory(receiver) {
                this.$emit('send-message-history', receiver);
            },

            setCurrentChatContent(match) {
                this.activeChat = match;
            },

            setCurrentSocialChatContent(conversation) {
                this.activeSocialChat = conversation;
            },

            time: time,
        },
    };
</script>

<style scoped>
    textarea,
    input[type='text'] {
        border: 1px solid #bdbdbd;
        border-radius: 5px;
    }

    .tabs-height {
        height: calc(100vh - 70px);
    }
</style>

<style>
    .tab-chat-height {
        height: calc(100% - 50px);
    }
    .tab-email-height {
        height: calc(100% - 80px);
    }
</style>
