<template>
    <div class="border-b">
        <div class="flex justify-between items-center px-4 py-3 flex-wrap">
            <div class="flex items-center my-1">
                <div>
                    <img v-if="profile.premium === '1'" src="@/assets/logo-gold@3x.png" class="w-8" />
                    <img v-else src="@/assets/logo-mark@3x.png" class="w-8" />
                </div>

                <span
                    class="font-bold ml-3 notranslate"
                    :class="profile.approved === '1' ? 'text-green-dark' : 'text-pink-muzmatch'"
                >
                    {{ profile.nickname }} ({{ profile.age }}/{{ profile.gender }})
                </span>

                <icon v-if="profile.verifiedStatus === 'approved'" class="ml-2 text-blue" icon="check-circle" />

                <router-link
                    :to="getMemberRoute(profile)"
                    class="hover:text-grey-600 text-grey-800 cursor-pointer p-2"
                    target="_blank"
                    title="Member Page"
                >
                    <icon icon="link"></icon>
                </router-link>
                <a
                    v-if="purchaseLoggerUrl"
                    :href="purchaseLoggerUrl"
                    class="hover:text-grey-600 text-grey-800 cursor-pointer p-2 pl-0"
                    target="_blank"
                    title="Purchase Logger"
                >
                    <icon icon="list"></icon>
                </a>
                <a
                    v-if="stripeUrl"
                    :href="stripeUrl"
                    class="hover:text-grey-600 text-grey-800 cursor-pointer p-2 pl-0"
                    target="_blank"
                    title="Stripe"
                >
                    <icon icon="dollar-sign"></icon>
                </a>
            </div>

            <span
                class="font-bold p-2 px-3 rounded-full cursor-pointer my-1"
                :class="profileStateClass"
                @click="showReason = !showReason"
            >
                {{ profileState }}

                <span v-if="profileStateReason">
                    <icon :icon="showReason ? 'chevron-up' : 'chevron-down'" class="ml-1"></icon>
                </span>
            </span>
        </div>

        <div v-if="showReason && profileStateReason" class="px-4 pb-4">
            {{ profileStateReason }}
        </div>
    </div>
</template>

<script>
    import { getMemberRoute } from '../../helpers/RedirectToMember';
    import { User } from '@/classes/User';
    import { mapGetters } from 'vuex';

    export default {
        name: 'DashboardMemberName',

        props: {
            profile: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                showReason: false,
            };
        },

        computed: {
            ...mapGetters({
                staff: 'staff/getStaff',
            }),

            profileState() {
                if (!this.profile) {
                    return 'Unknown';
                }

                if (!this.profile.profileState) {
                    return 'Unknown';
                }

                return this.profile.profileState.split(':')[0];
            },

            profileStateReason() {
                if (!this.profile) {
                    return null;
                }

                if (!this.profile.profileState) {
                    return null;
                }

                let reason = this.profile.profileState.split(':');

                reason.shift();

                return reason.join(':');
            },

            profileStateClass() {
                if (this.profileState === 'Unknown') {
                    return 'text-grey-900 bg-grey-100';
                }

                if (this.profileState.includes('NOT APPROVED')) {
                    return 'text-red-dark bg-red-lightest';
                }

                if (this.profileState.includes('APPROVED')) {
                    return 'text-green-dark bg-green-lightest';
                }

                return 'text-red-dark bg-red-lightest';
            },

            purchaseLoggerUrl() {
                if (!User.make(this.staff).can('purchase-logger:admin')) {
                    return null;
                }

                return `${process.env.VUE_APP_BETA_URL}/purchase-logger/table/view?field=accountID&value=${this.profile.accountID}`;
            },

            stripeUrl() {
                if (this.profile.stripeCustomerID === null) {
                    return null;
                }

                if (process.env.VUE_APP_ENV === 'production') {
                    return `https://dashboard.stripe.com/customers/${this.profile.stripeCustomerID}`;
                }

                return `https://dashboard.stripe.com/test/customers/${this.profile.stripeCustomerID}`;
            },
        },

        methods: {
            getMemberRoute,
        },
    };
</script>

<style scoped></style>
