var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{attrs:{"click-overlay":_vm.toggleModal},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"hidden md:block"},[_c('IconButton',{staticClass:"float-right right-0 top-0 absolute mt-2 mr-2",attrs:{"rounded":""},on:{"click":_vm.toggleModal}},[_vm._v(" Close ")])],1)]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"flex w-full max-h-90"},[_c('div',{staticClass:"w-1/2 m-3 max-h-full text-center"},[_c('DashboardLabel',{staticClass:"mb-5 block"},[_vm._v(_vm._s(_vm.photos[_vm.currentImgIndex].label))]),(_vm.photos[_vm.currentImgIndex].is === 'image')?_c('img',{staticClass:"profile-photo rounded-lg m-auto h-full block aspect-ratio",attrs:{"src":_vm.photos[_vm.currentImgIndex].signedUrl
                            ? _vm.photos[_vm.currentImgIndex].signedUrl
                            : _vm.photos[_vm.currentImgIndex].type === 'photoVerification'
                            ? _vm.resolveVerificationPhotoUrl(_vm.photos[_vm.currentImgIndex].key, _vm.hashMemberId, 'portrait')
                            : _vm.resolvePhotoUrl(_vm.photos[_vm.currentImgIndex].key, _vm.hashMemberId, 'portrait')},on:{"error":function($event){$event.target.src = require('../../assets/mm2.png')}}}):(_vm.photos[_vm.currentImgIndex].key)?_c('video',{staticClass:"focus:outline-none m-auto h-full block aspect-ratio",attrs:{"id":"profile_video","controls":""}},[_c('source',{attrs:{"src":_vm.photos[_vm.currentImgIndex].signedUrl
                                ? _vm.photos[_vm.currentImgIndex].signedUrl
                                : _vm.resolveVideoUrl(_vm.photos[_vm.currentImgIndex].key, _vm.hashMemberId)}})]):_c('img',{staticClass:"profile-photo rounded-lg m-auto h-full block aspect-ratio",attrs:{"src":_vm.resolvePhotoUrl(null, null, 'portrait', false)},on:{"error":function($event){$event.target.src = require('../../assets/mm2.png')}}}),_c('div',{staticClass:"w-full text-center my-5"},[_c('icon',{staticClass:"cursor-pointer ml-1 text-3xl",class:_vm.isFirstImage ? 'text-grey-light cursor-not-allowed' : 'text-pink-muzmatch',attrs:{"icon":"chevron-left"},on:{"click":_vm.getPreviousImage}})],1)],1),_c('div',{staticClass:"w-1/2 m-3 max-h-full text-center"},[_c('DashboardLabel',{staticClass:"mb-5 block"},[_vm._v(_vm._s(_vm.photos[_vm.nextImgIndex].label))]),(_vm.photos[_vm.nextImgIndex].is === 'image')?_c('img',{staticClass:"profile-photo rounded-lg h-full block m-auto aspect-ratio",attrs:{"src":_vm.photos[_vm.nextImgIndex].signedUrl
                            ? _vm.photos[_vm.nextImgIndex].signedUrl
                            : _vm.photos[_vm.nextImgIndex].type === 'photoVerification'
                            ? _vm.resolveVerificationPhotoUrl(_vm.photos[_vm.nextImgIndex].key, _vm.hashMemberId, 'portrait')
                            : _vm.resolvePhotoUrl(_vm.photos[_vm.nextImgIndex].key, _vm.hashMemberId, 'portrait')},on:{"error":function($event){$event.target.src = require('../../assets/mm2.png')}}}):(_vm.photos[_vm.nextImgIndex].key)?_c('video',{staticClass:"focus:outline-none m-auto h-full block aspect-ratio",attrs:{"controls":""}},[_c('source',{attrs:{"src":_vm.photos[_vm.nextImgIndex].signedUrl
                                ? _vm.photos[_vm.nextImgIndex].signedUrl
                                : _vm.resolveVideoUrl(_vm.photos[_vm.nextImgIndex].key, _vm.hashMemberId)}})]):_c('img',{staticClass:"profile-photo rounded-lg m-auto h-full block aspect-ratio",attrs:{"src":_vm.resolvePhotoUrl(null, null, 'portrait', false)},on:{"error":function($event){$event.target.src = require('../../assets/mm2.png')}}}),_c('div',{staticClass:"w-full text-center my-5"},[_c('icon',{staticClass:"cursor-pointer ml-1 text-3xl",class:_vm.isLastImage ? 'text-grey-light cursor-not-allowed' : 'text-pink-muzmatch',attrs:{"icon":"chevron-right"},on:{"click":_vm.getNextImage}})],1)],1)])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }