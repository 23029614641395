<template>
    <div class="mt-2 w-full">
        <div v-for="(frame, index) in frameList" :key="index" class="pr-2 float-left w-1/5">
            <div class="text-left">
                <h1 class="whitespace-nowrap font-semibold text-grey-700">
                    <template v-if="type === 'liveness'">
                        <template v-if="frame.category === 'selfie'">Signup Selfie</template>
                        <template v-else>Frame {{ index }}</template>
                    </template>
                    <template v-else>Photo {{ index + 1 }}</template>
                </h1>

                <div class="relative">
                    <img
                        class="profile-photo w-full rounded-lg cursor-pointer h-64"
                        :alt="frame.id"
                        :src="frame.base64Content"
                        @click="toggleModal(index)"
                    />
                </div>
            </div>
        </div>

        <IdentityVerificationLivenessModal
            v-if="showLivenessModal"
            :frame-list="frameList"
            :current-img-index="currentImgIndex"
            :next-img-index="nextImgIndex"
            @toggle-modal="toggleModal"
            @increment-index="incrementIndex"
            @decrement-index="decrementIndex"
        />

        <IdentityVerificationDocumentModal
            v-if="showDocumentModal"
            :frame-list="frameList"
            :current-img-index="currentImgIndex"
            :next-img-index="nextImgIndex"
            @toggle-modal="toggleModal"
            @increment-index="incrementIndex"
            @decrement-index="decrementIndex"
        />
    </div>
</template>

<script>
    import DashboardLabel from '@/components/Labels/DashboardLabel';
    import IdentityVerificationLivenessModal from '@/pages/TaskManager/IdentityVerification/IdentityVerificationLivenessModal';
    import IdentityVerificationDocumentModal from '@/pages/TaskManager/IdentityVerification/IdentityVerificationDocumentModal';
    export default {
        name: 'IdentityVerificationPhoto',
        components: {
            DashboardLabel,
            IdentityVerificationLivenessModal,
            IdentityVerificationDocumentModal,
        },
        props: {
            type: {
                type: String,
                required: true,
            },
            livenessIndex: {
                type: Number,
                required: true,
            },
            frameList: {
                type: Array,
                required: true,
            },
        },

        data() {
            return {
                showLivenessModal: false,
                showDocumentModal: false,
                currentImgIndex: 0,
                nextImgIndex: 1,
            };
        },

        methods: {
            decrementIndex() {
                this.currentImgIndex--;
                this.nextImgIndex--;
            },

            incrementIndex() {
                this.currentImgIndex++;
                this.nextImgIndex++;
            },

            toggleModal(index) {
                this.currentImgIndex = 0;
                this.nextImgIndex = 1;

                if (this.type === 'liveness') {
                    if (typeof index === 'number') {
                        const isLastImage = index === this.frameList.length - 1;

                        if (isLastImage) {
                            this.currentImgIndex = index - 1;
                            this.nextImgIndex = index;
                        } else {
                            this.currentImgIndex = index;
                            this.nextImgIndex = index + 1;
                        }
                    }

                    this.showLivenessModal = !this.showLivenessModal;
                    return;
                }

                if (typeof index === 'number') {
                    this.currentImgIndex = index;
                    this.nextImgIndex = index + 1;
                }

                this.showDocumentModal = !this.showDocumentModal;
            },
        },
    };
</script>

<style scoped>
    .profile-photo {
        object-fit: cover;
        object-position: 50%;
    }
</style>
