<template>
    <div class="my-2 sm:my-0 h-full w-full rounded shadow-md bg-white">
        <StaffLogsModal v-if="isModalOpen" :thread-id="thread.id" @hide="isModalOpen = false" />
        <Panel class="h-full w-full shadow-none" title="Email" :has-margin="false" :email="true">
            <div v-if="loading" class="w-full h-full bg-grey-lightest rounded flex justify-center items-center">
                <p class="text-5xl text-pink-muzmatch">
                    <icon icon="envelope" class="mx-1"></icon>
                </p>
            </div>
            <template v-if="!loading" #header>
                <div class="flex flex-row items-center w-full mx-3 pb-1 text-xs thread-forward-icon">
                    <icon title="Forward Email Chain" icon="share-square" @click="forwardThread" />
                </div>
                <div class="flex flex-row justify-center items-center mx-2">
                    <div class="mx-2">
                        <icon
                            title="Collapse all emails"
                            icon="compress-alt"
                            :class="canCompress ? 'cursor-pointer' : 'text-grey'"
                            @click="compress()"
                        ></icon>
                    </div>
                    <div class="mx-2">
                        <icon
                            title="Expand all emails"
                            icon="expand-alt"
                            :class="canExpand ? 'cursor-pointer' : 'text-grey'"
                            @click="expand()"
                        ></icon>
                    </div>
                    <DoubleChevronUp
                        title="Jump to top of thread"
                        class="mx-2"
                        :class="canScrollUp ? 'cursor-pointer' : 'text-grey'"
                        @click.native="scrollToTop"
                    />
                    <DoubleChevronDown
                        title="Jump to end of thread"
                        class="mx-2"
                        :class="canScrollDown ? 'cursor-pointer' : 'text-grey'"
                        @click.native="scrollToBottom"
                    />
                    <div v-show="showRefreshIcon" class="mx-2">
                        <icon
                            title="Refresh"
                            icon="redo"
                            class="cursor-pointer grey-light"
                            @click="refreshTask()"
                        ></icon>
                    </div>
                    <div v-if="isEmailListAdmin" class="mx-2">
                        <icon
                            title="Staff Logs"
                            icon="clipboard"
                            class="cursor-pointer grey-light"
                            @click="isModalOpen = true"
                        ></icon>
                    </div>
                </div>
            </template>

            <!--We want this to load 'behind the scenes' if there is a thread-->
            <div
                v-if="!!this.thread"
                class="flex-col"
                :class="{
                    hidden: loading,
                    flex: !loading,
                    'max-h-screen-80': isTaskManager,
                    'tab-email-height': !isTaskManager,
                }"
            >
                <div class="p-4 bg-grey-muzmatch shadow-md">
                    <div class="text-md font-bold text-white break-words">Subject: {{ threadSubject }}</div>
                </div>
                <div ref="scroll" class="flex-col break-words overflow-y-scroll px-4">
                    <EmailCard
                        v-for="(email, index) in thread.emails"
                        :key="index"
                        ref="emails"
                        :index="index"
                        :thread-id="parseInt(thread.id)"
                        :type="thread.type"
                        :email="email"
                        :thread-email-addresses="threadEmailAddresses"
                        :member-email-addresses="memberEmailAddresses"
                        :attachments="attachments"
                        :parent-refs="$refs"
                        :context-type="contextType"
                        class="my-4"
                        @handleScroll="handleScroll"
                        @checkStatus="checkStatus"
                    >
                        <vue-markdown
                            :breaks="false"
                            class="markdown pt-4 px-4 whitespace-pre-wrap"
                            :source="parse(email.fragments[0])"
                        />
                    </EmailCard>
                </div>
                <div v-if="showReply" class="p-2">
                    <EmailAddressDropdown
                        :thread-email-addresses="threadEmailAddresses"
                        :member-email-addresses="memberEmailAddresses"
                        :email-addresses-selected="replyEmailAddresses"
                        :dropdown-options="dropdownOptions"
                        @email-address-changed="setReplyEmailAddresses"
                        @add-email="addEmailToList"
                    />
                    <textarea
                        v-model="message"
                        type="text"
                        class="shortcut-accessible w-full rounded border border-grey-200 h-24 p-4 focus:outline-none mb-1"
                        :class="[contextType, contextAction, contextDetail]"
                    />

                    <button
                        class="rounded text-white p-4 focus:outline-none w-full cursor-pointer"
                        :class="loading ? 'bg-pink-light' : 'bg-pink-muzmatch'"
                        @click="sendMessage"
                    >
                        Send Email
                    </button>
                </div>
            </div>
        </Panel>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Panel from './Panel';
    import { dashboardAxios } from '../../../axios.config';
    import { mapActions } from 'vuex';
    import EmailCard from '../Cards/EmailCard';
    import DashboardLabel from '../Labels/DashboardLabel';
    import VueMarkdown from 'vue-markdown';
    import EmailAddressDropdown from '../Dropdown/EmailAddressDropdown';
    import StaffLogsModal from '../Modal/StaffLogsModal';
    import { User } from '../../classes/User';

    export default {
        name: 'EmailPanel',

        components: {
            DashboardLabel,
            EmailCard,
            Panel,
            VueMarkdown,
            EmailAddressDropdown,
            StaffLogsModal,
        },

        props: {
            thread: {
                type: Object,
                default: null,
            },
            memberEmailAddresses: {
                type: Array,
                default: null,
            },
            threadEmailAddresses: {
                type: Array,
                default: null,
            },
            flagged: {
                type: Boolean,
                default: false,
            },
            showReply: {
                type: Boolean,
                default: true,
            },
            isTaskManager: {
                type: Boolean,
                default: true,
            },
            isTab: {
                type: Boolean,
                default: false,
            },
            showRefreshIcon: {
                type: Boolean,
                default: true,
            },
            contextType: {
                type: String,
                required: true,
            },
            contextAction: {
                type: String,
                required: true,
            },
            contextDetail: {
                type: String,
                default: '',
            },
        },

        data() {
            return {
                replyEmailAddresses: [],
                defaultMessage: 'Salaam, ',
                message: '',
                loading: true,
                sending: false,
                attachments: [],
                canScrollUp: false,
                canScrollDown: false,
                canExpand: false,
                canCompress: false,
                isModalOpen: false,
            };
        },

        computed: {
            ...mapGetters({
                staff: 'staff/getStaff',
            }),
            isEmailListAdmin() {
                return User.make(this.staff).can('email-list:admin');
            },
            threadSubject() {
                if (!this.loading && !!this.thread) {
                    return this.thread.subject ? this.thread.subject : 'No subject found';
                }

                return '';
            },
            taskType() {
                return this.flagged ? 'email-flagged' : 'email';
            },
            firstEmailAddressInThread() {
                if (!this.threadEmailAddresses) return null;

                return this.threadEmailAddresses[0];
            },
            dropdownOptions() {
                if (!this.threadEmailAddresses) return [];

                return this.threadEmailAddresses.map((emailAddress, index) => ({
                    emailAddress: emailAddress,
                    $isDisabled: false,
                }));
            },
            selectedEmailAddresses() {
                if (!this.replyEmailAddresses) return [];

                return this.replyEmailAddresses.map((item) => item.emailAddress);
            },
        },

        watch: {
            threadEmailAddresses() {
                if (!this.threadEmailAddresses) {
                    return;
                }

                this.replyEmailAddresses = [
                    {
                        emailAddress: this.threadEmailAddresses[0],
                        $isDisabled: true,
                    },
                ];
            },
            thread: {
                immediate: true,
                handler() {
                    this.loading = true;
                    window.setTimeout(this.handleThread, 50);
                },
            },
            message: {
                immediate: false,
                handler() {
                    localStorage.setItem('emailMessage', this.message);
                },
            },
        },

        created() {
            if (this.threadEmailAddresses) {
                this.replyEmailAddresses.push({
                    emailAddress: this.threadEmailAddresses[0],
                    $isDisabled: true,
                });
            }

            let emailMessage = localStorage.getItem('emailMessage');

            if (typeof emailMessage !== 'undefined' && emailMessage !== null && emailMessage !== '') {
                this.message = emailMessage;
                return;
            }

            this.message = this.defaultMessage;
        },

        async mounted() {
            if (!this.$refs.scroll) return;

            this.$refs.scroll.addEventListener('scroll', _.throttle(this.handleScroll, 50));
        },

        beforeDestroy() {
            if (!this.$refs.scroll) return;

            this.$refs.scroll.removeEventListener('scroll', _.throttle(this.handleScroll, 50));
        },

        methods: {
            ...mapActions({
                handleError: 'handleError',
                alert: 'alert',
            }),

            addEmailToList(email) {
                this.threadEmailAddresses.push(email);

                this.$nextTick(() => {
                    this.replyEmailAddresses.push({
                        emailAddress: email,
                        $isDisabled: false,
                    });
                });
            },

            refreshTask() {
                this.$emit('refreshTask');
            },

            async handleThread() {
                if (this.thread) {
                    if (this.$refs.emails) {
                        this.$refs.emails.forEach((email) => {
                            email.collapseAll();
                        });

                        await this.fetchAttachments();

                        window.setTimeout(() => {
                            this.scrollToTailEmail();
                        }, 10);

                        this.loading = false;
                    }
                }
            },

            compress() {
                if (this.canCompress === false) {
                    return;
                }

                this.$refs.emails.forEach((email) => {
                    email.collapseAll();
                });

                window.setTimeout(this.handleScroll, 50);
            },

            expand() {
                if (this.canExpand === false) {
                    return;
                }

                this.$refs.emails.forEach((email) => {
                    email.expandAll();
                });

                window.setTimeout(this.handleScroll, 50);
            },

            checkStatus() {
                this.checkExpandStatus();
                this.checkCompressStatus();
            },

            checkExpandStatus() {
                let expandStatuses = this.$refs.emails.map((email) => email.canExpand);
                if (expandStatuses.some((status) => status === true)) {
                    this.canExpand = true;
                    return;
                }
                this.canExpand = false;
            },

            checkCompressStatus() {
                let compressStatuses = this.$refs.emails.map((email) => email.canCompress);
                if (compressStatuses.some((status) => status === true)) {
                    this.canCompress = true;
                    return;
                }
                this.canCompress = false;
            },

            handleScroll() {
                let scroll = this.$refs.scroll;

                if (!scroll) return;

                if (scroll.scrollTop === 0) {
                    this.canScrollUp = false;
                } else {
                    this.canScrollUp = true;
                }

                if (scroll.scrollHeight - scroll.scrollTop === scroll.clientHeight) {
                    this.canScrollDown = false;
                } else {
                    this.canScrollDown = true;
                }
            },

            isFirstEmailAddressInThread(emailAddress) {
                return emailAddress === this.firstEmailAddressInThread;
            },

            async sendMessage() {
                if (this.loading || this.sending) {
                    return;
                }

                this.sending = true;

                try {
                    if (
                        this.replyEmailAddresses.length > 1 ||
                        !this.isFirstEmailAddressInThread(this.replyEmailAddresses[0].emailAddress)
                    ) {
                        const response = await this.$swal({
                            html: `
                                This will send the email chain to the following email addresses:
                                <br /> <br /> <strong>${this.selectedEmailAddresses.join('<br />')}</strong>
                                <br /> <br /> Are you sure these email addresses belong to the same member?
                            `,
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonText: 'Yes',
                            cancelButtonText: 'No',
                        });

                        if (response.dismiss) {
                            return;
                        }
                    }

                    await dashboardAxios.post(`/v1/tasks/email/thread/${this.thread.id}/reply`, {
                        message: this.message,
                        flagged: this.flagged,
                        emailAddresses: this.selectedEmailAddresses,
                    });

                    this.isTaskManager ? this.refreshTask() : this.refreshCurrentThread();

                    this.message = this.defaultMessage;
                    this.sending = false;
                } catch (e) {
                    this.sending = false;
                    this.handleError({ error: e });
                }
            },

            async refreshCurrentThread() {
                const resp = await dashboardAxios.get(`/v1/members/threads/${this.thread.id}`);
                this.$emit('set-thread', resp.data.result);
            },

            async forwardThread() {
                const response = await this.$swal({
                    html: `
                        <div class="flex flex-row items-center justify-center">
                            <div class="">
                                <input
                                    id="emailAddress"
                                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    type="text"
                                />
                            </div>
                            <div class="ml-2">
                                @muzz.com
                            </div>
                        </div>
                    `,
                    preConfirm: () => {
                        return [document.getElementById('emailAddress').value];
                    },
                    showCancelButton: true,
                    confirmButtonText: 'Forward email chain',
                });

                if (response.dismiss) {
                    return;
                }

                if (response.value[0].includes('@')) {
                    this.alert({
                        message: "Please do not include the '@' symbol in the email address field",
                        type: 'danger',
                    });
                    return;
                }

                let body = {
                    emailAddress: `${response.value[0]}@muzz.com`,
                    attachments: this.attachments,
                };

                await dashboardAxios.post(`/v1/tasks/email/thread/${this.thread.id}/forward`, body);
            },

            async fetchAttachments() {
                try {
                    let response = await dashboardAxios.get('/v1/tasks/email/attachment/' + this.thread.id);
                    this.attachments = response.data.result;
                } catch (e) {
                    this.handleError({ error: e });
                }
            },

            scrollToBottom() {
                if (!this.canScrollDown) {
                    return;
                }
                this.$nextTick(() => {
                    let scroll = this.$refs.scroll;
                    scroll.scrollTop = scroll.scrollHeight;
                });
            },

            scrollToTop() {
                if (!this.canScrollUp) {
                    return;
                }
                let scroll = this.$refs.scroll;
                scroll.scrollTop = 0;
            },

            scrollToTailEmail() {
                let tailEmail = this.$refs.emails[this.thread.emails.length - 1];

                if (!tailEmail) {
                    return;
                }

                tailEmail.threadVisible = true;
                this.$nextTick(() => tailEmail.$el.scrollIntoView());
                this.$nextTick(() => this.handleScroll());
            },

            parse(body) {
                return body.length > 0 ? body : '*No email content found.*';
            },

            setReplyEmailAddresses(emailAddresses) {
                this.replyEmailAddresses = emailAddresses;
            },
        },
    };
</script>

<style scoped>
    .max-h-screen-80 {
        max-height: calc(100vh - 10rem);
    }

    /* .tabs-height {
        height: initial;
    } */

    .markdown >>> p {
        white-space: initial;
    }

    .markdown >>> pre {
        white-space: pre-wrap;
    }

    .h-custom {
        height: calc(100vh - 24rem);
    }

    .h-custom-2 {
        /* 24.375 subject offset in email panel */
        height: calc(100vh - 24.375rem);
    }

    .scroll-margin-top {
        scroll-margin-top: 1rem;
    }

    .thread-forward-icon {
        margin-bottom: -3px;
    }

    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 10px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #2e3b4b;
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
</style>
