import { render, staticRenderFns } from "./ReleaseNoteCard.vue?vue&type=template&id=65d233a2&scoped=true&"
import script from "./ReleaseNoteCard.vue?vue&type=script&lang=js&"
export * from "./ReleaseNoteCard.vue?vue&type=script&lang=js&"
import style0 from "./ReleaseNoteCard.vue?vue&type=style&index=0&id=65d233a2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65d233a2",
  null
  
)

export default component.exports