<template>
    <div class="flex w-full h-screen items-center justify-center">
        <icon icon="spinner" class="h-16 animate-spin" style="width: 4rem !important"></icon>
    </div>
</template>
<script>
    export default {
        name: 'Home',
    };
</script>
