<template>
    <tr class="border hover:bg-grey-200">
        <td class="px-4 py-2 break-words">
            {{ time(issue.timestamp, 'DD/MM/YYYY HH:mm') }}
        </td>

        <td class="px-4 py-2 font-mono font-bold break-words">
            {{ issue.type }}
            <div class="pt-2">
                <span v-if="issue.appVersion" class="rounded-full p-1 px-2 bg-grey-200 text-sm">
                    {{ issue.appVersion }}
                </span>

                <span
                    v-if="issue.deviceOS && issue.deviceVersion"
                    class="rounded-full p-1 px-2 bg-grey-200 text-sm ml-2"
                >
                    {{ issue.deviceOS }} {{ issue.deviceVersion }}
                </span>
            </div>
        </td>

        <td class="px-4 py-2 break-words">
            <AttachmentModal
                v-if="issue.attachment"
                :thumb="url"
                :urls="[url]"
                thumb-classes="h-12 w-12 border"
                custom-style="object-fit: cover;"
            />
            <span v-else class="italic">No Evidence Provided</span>
        </td>

        <td class="px-4 py-2 font-bold break-words">
            {{ issue.message }}
        </td>
    </tr>
</template>

<script>
    import AttachmentModal from '../../Attachments/AttachmentModalV2';

    export default {
        components: {
            AttachmentModal,
        },
        props: {
            issue: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                showAttachmentModal: false,
            };
        },

        computed: {
            url() {
                if (!this.issue.attachment) return null;

                return this.issue.attachment.replace('s3://', 'https://');
            },
        },

        methods: {
            time(time, format = 'DD/MM/YYYY HH:mm') {
                return moment(time).format(format);
            },
        },
    };
</script>

<style lang="scss" scoped></style>
