<template>
    <div class="relative flex min-h-full pr-5 mb-3">
        <div :class="themes[type]" class="mr-2" @click="toggle">
            <icon v-if="!value" icon="square"></icon>
            <icon v-else icon="check-square"></icon>
        </div>
        <label class="relative mb-0 align-top inline-block text-sm text-grey-darker" :class="type">
            {{ capitalize(text) }}
        </label>
        <input v-model="value" type="checkbox" class="invisible" />
    </div>
</template>

<script>
    export default {
        props: {
            type: {
                required: false,
                type: String,
                default: 'default',
            },
            text: {
                required: false,
                type: String,
                default: '',
            },
            defaultValue: {
                required: false,
                type: Boolean,
                default: true,
            },
        },
        data: function () {
            return {
                value: this.defaultValue,
                themes: {
                    success: 'text-green-dark bg-white',
                    default: 'text-pink-dark bg-white ',
                },
            };
        },
        watch: {
            defaultValue: function (value) {
                this.value = value;
            },
            value: function (value) {
                this.$emit('change', value);
            },
        },
        methods: {
            toggle: function () {
                this.value = !this.value;
            },
            capitalize: function (value) {
                return value[0].toUpperCase() + value.substring(1, value.length);
            },
        },
    };
</script>
