<template>
    <div>
        <img
            v-if="isImage"
            class="cursor-pointer w-32 h-32 attachment shadow rounded"
            :src="parseAttachmentUrl(attachment)"
            alt="Minimised Attachment Image"
            @click="$emit('showAttachmentsModal', index)"
        />
        <video
            v-if="isVideo"
            class="cursor-pointer w-32 h-32 attachment shadow rounded"
            :src="parseAttachmentUrl(attachment)"
            @click="$emit('showAttachmentsModal', index)"
        />
    </div>
</template>

<script>
    export default {
        name: 'AttachmentMedia',

        props: {
            attachment: {
                type: Object,
                required: true,
            },
            index: {
                type: Number,
                required: true,
            },
        },

        computed: {
            isVideo() {
                return this.attachment.type.split('/')[0] === 'video';
            },
            isImage() {
                return this.attachment.type.split('/')[0] === 'image';
            },
        },

        methods: {
            parseAttachmentUrl(attachment) {
                let prefix = 's3://';
                let url = _.get(attachment, 'attachmentUrl', '');

                if (url.includes(prefix)) {
                    url = url.replace(prefix, 'https://');
                }

                return url;
            },
        },
    };
</script>

<style scoped>
    .object-contain {
        object-fit: contain;
    }
</style>
