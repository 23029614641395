<template>
    <div class="h-full">
        <div class="fade sm:flex flex-row sm:-mx-2 h-full">
            <div class="mx-2 sm:w-1/4 flex flex-col flex-shrink-0">
                <div>
                    <input
                        v-model="chatFilter"
                        class="rounded p-2 border-grey-300 border w-full"
                        placeholder="Search..."
                    />
                </div>

                <div class="h-full overflow-y-scroll">
                    <div
                        v-for="match in limitedMatches"
                        v-if="matches"
                        :key="match.id"
                        class="hover:bg-grey-100 mt-1 rounded cursor-pointer flex justify-between items-center"
                        :class="activeChat && match.id === activeChat.id ? 'bg-grey-100' : ''"
                        @click="setCurrentChatContent(match)"
                    >
                        <span class="p-2 notranslate">
                            <span v-if="match.is_instant_chat" title="Instant Chat">⚡️</span>
                            {{ getOtherMemberName(match) }}
                        </span>

                        <div class="mr-2">
                            <span
                                v-if="activeChat && match.id === activeChat.id"
                                class="mr-2"
                                @click="emailChat(match)"
                            >
                                <icon icon="envelope" class="hover:text-grey-600 text-black" />
                            </span>

                            <router-link
                                v-if="activeChat && match.id === activeChat.id"
                                :to="'/members/' + getOtherMemberName(match) + '.' + getReceiver(match)"
                                target="_blank"
                            >
                                <icon icon="link" class="hover:text-grey-600 text-black" />
                            </router-link>

                            <span
                                v-if="
                                    !isProdEnvironment &&
                                    !isAdminMatch(match) &&
                                    activeChat &&
                                    match.id === activeChat.id
                                "
                                class="ml-2"
                                @click="deleteMatch(match)"
                            >
                                <icon icon="times" class="hover:text-grey-600 text-red-dark" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mx-2 my-2 sm:my-0 sm:w-3/4 h-full overflow-hidden rounded shadow-md mb-3">
                <ChatPanel
                    class="w-full h-full"
                    :sender="profile.hashMemberID"
                    :receiver="getReceiver(activeChat)"
                    :match-id="activeChat ? activeChat.id : null"
                    :context-type="contextType"
                    context-action="CONTEXT_ACTION_MEMBER_CHAT_PANEL"
                    custom-style="tab-chat-height flex flex-col"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import ChatPanel from '../../Panels/ChatPanel';
    import { mapActions } from 'vuex';
    import { dashboardAxios } from '../../../../axios.config';

    export default {
        name: 'MessagesTab',
        components: {
            ChatPanel,
        },
        props: {
            profile: {
                type: Object,
                required: true,
            },
            matches: {
                type: Array,
                required: true,
            },
            activeChat: {
                type: Object,
                default: null,
            },
            contextType: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                chatFilter: '',
                matchesLoading: false,
            };
        },
        computed: {
            limitedMatches() {
                return [...this.filteredMatches];
            },

            filteredMatches() {
                if (!this.chatFilter) {
                    return this.matches;
                }

                return _.filter(this.matches, this.filterMatch);
            },

            isProdEnvironment() {
                return process.env.VUE_APP_ENV === 'production';
            },
        },
        methods: {
            ...mapActions({
                handleError: 'handleError',
                alert: 'alert',
                confirmDelete: 'confirmDelete',
            }),

            setCurrentChatContent(match) {
                this.$emit('set-current-chat-content', match);
            },

            emailChat(match) {
                let receiver = this.getReceiver(match);

                this.$emit('send-message-history', receiver);
            },

            async deleteMatch(match) {
                const text = 'This will be permanent';
                const body = 'Matches, messages and swipe between members will be deleted';
                if (!(await this.confirmDelete({ vm: this, text: text, body: body }))) return;

                try {
                    const payload = {
                        matchID: match.id,
                        memberID: match.member1.logId,
                        otherMemberID: match.member2.logId,
                    };

                    const response = await dashboardAxios.post(`/v1/match/remove`, payload);
                    await this.alert({ message: response.data.result.message, type: 'success' });
                    this.$emit('reload-matches');
                } catch (e) {
                    await this.handleError({ error: e });
                }
            },

            filterMatch(match) {
                if (!this.profile) {
                    return true;
                }

                let name =
                    match.member1.id === this.profile.hashMemberID ? match.member2.full_name : match.member1.full_name;

                if (!name) {
                    name = '';
                }

                return name.toLowerCase().includes(this.chatFilter.toLowerCase());
            },

            getOtherMemberName(match) {
                if (match.member1.id === this.profile.hashMemberID) {
                    return match.member2.full_name;
                }

                return match.member1.full_name;
            },

            getReceiver(match) {
                if (!match) {
                    return null;
                }

                if (match.member1.id === this.profile.hashMemberID) {
                    return match.member2.id;
                }

                return match.member1.id;
            },

            isAdminMatch(match) {
                return match.member1.logId === '1' || match.member2.logId === '1';
            },
        },
    };
</script>

<style scoped></style>
