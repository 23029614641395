<template>
    <Panel title="Create Permission">
        <div class="p-4">
            <div class="flex flex-row -mx-2">
                <div class="w-1/2 mx-2">
                    <label>Product</label>

                    <input
                        v-model="product"
                        type="text"
                        class="p-2 bg-white border border-grey-400 rounded w-full mt-1"
                    />
                </div>

                <div class="w-1/2 mx-2">
                    <label>Name</label>

                    <input v-model="name" type="text" class="p-2 bg-white border border-grey-400 rounded w-full mt-1" />
                </div>
            </div>

            <div class="flex justify-end mt-4">
                <button
                    class="py-2 px-4 text-grey-800 rounded focus:outline-none bg-grey-100 mr-2"
                    @click="$router.push('/permissions')"
                >
                    Back to listing
                </button>

                <button
                    class="py-2 px-4 text-white rounded focus:outline-none"
                    :class="loading ? 'bg-pink-lighter' : 'bg-pink-muzmatch'"
                    @click="saveRole()"
                >
                    Save
                </button>
            </div>
        </div>
    </Panel>
</template>

<script>
    import Panel from '../../components/Panels/Panel';
    import { dashboardAxios } from '../../../axios.config';
    import { mapActions } from 'vuex';

    export default {
        name: 'Create',

        components: {
            Panel,
        },

        data() {
            return {
                name: '',
                product: '',
                loading: false,
            };
        },

        methods: {
            ...mapActions(['alert', 'handleError']),

            async saveRole() {
                if (this.loading) {
                    return;
                }

                if (!this.product || !this.name) {
                    this.alert({ message: 'Product and name are required.', type: 'danger' });

                    return;
                }

                this.loading = true;

                try {
                    let response = await dashboardAxios.post('/v1/walipermission', {
                        name: this.name,
                        product: this.product,
                    });

                    let role = response.data.result;

                    this.alert({ message: 'Role saved.', type: 'success' });

                    this.loading = false;

                    await this.$router.push('/permissions/' + role.productName + ':' + role.roleName + '.' + role.id);
                } catch (error) {
                    this.handleError({
                        error,
                        callback: () => {
                            this.loading = false;
                        },
                    });
                }
            },
        },
    };
</script>
