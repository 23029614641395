<template>
    <div class="relative flex items-center">
        <select
            v-model="selection"
            :disabled="isDisabled"
            class="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            :class="[
                whiteBackground ? 'bg-white' : 'bg-gray-200',
                isCapitalize ? 'capitalize' : '',
                isDisabled ? 'cursor-not-allowed' : '',
            ]"
            @change="$emit('updated', selection)"
        >
            <option disabled selected :value="null" :label="placeholder">
                {{ placeholder }}
            </option>
            <option v-for="option in options" :key="option.value" class="" :value="option.value">
                {{ option.label }}
            </option>
        </select>
        <div class="pointer-events-none absolute inset-y-0 flex items-center px-2 text-gray-700" style="right: 0">
            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            default: {
                type: [String, Number, Boolean],
                default: null,
            },
            placeholder: {
                type: String,
                required: true,
            },
            options: {
                type: Array,
                required: true,
            },
            isDisabled: {
                type: Boolean,
                default: false,
            },
            whiteBackground: {
                type: Boolean,
                default: false,
            },
            isCapitalize: {
                type: Boolean,
                default: true,
            },
        },
        data() {
            return {
                selection: null,
            };
        },
        watch: {
            // options(value) {
            //     this.selection = null;
            // },
            default(value) {
                this.selection = value;
            },
        },
        mounted() {
            if (this.default !== null) {
                this.selection = this.default;
            }
        },
    };
</script>

<style scoped>
    .dropdown:hover .dropdown-menu {
        display: block;
    }
</style>
