<template>
    <div class="step-elements py-2 px-2" @click="$emit('selected')">
        <p class="float-left truncate w-2/3">
            <span class="step-pill mr-1 flex items-center" :class="stepPillTypeClass">
                <icon :icon="channelIcon" class="mx-1" />
                {{ stepPillTypeName }}
            </span>
            <slot></slot>
        </p>
        <button
            v-if="!isDisabled"
            type="button"
            class="delete-btn float-right ml-2 p-2 pt-1 text-white bg-grey"
            @click="$emit('deleted', index)"
        >
            <span>x</span>
        </button>
        <toggle-button
            :key="refreshToggle"
            class="float-right"
            :is-toggled="active"
            :is-disabled="isDisabled"
            @clicked="$emit('toggled', $event)"
        />
    </div>
</template>

<script>
    import ToggleButton from '../../components/Buttons/ToggleButton';

    export default {
        name: 'CampaignStepElements',

        components: {
            ToggleButton,
        },

        props: {
            index: {
                type: Number,
                required: true,
            },
            stepPillType: {
                type: String,
                required: true,
            },
            active: {
                type: Boolean,
                required: true,
            },
            isDisabled: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                refreshToggle: 0,
            };
        },
        computed: {
            stepPillTypeName() {
                switch (this.stepPillType) {
                    case 'PUSH':
                        return 'Push';
                    case 'IN_APP':
                        return 'In App';
                    case 'EMAIL':
                        return 'Email';
                    case 'IN_APP_NO_PUSH':
                        return 'In App (no push)';
                    case 'NO_MESSAGE':
                        return 'No Message';
                    default:
                        return '';
                }
            },
            stepPillTypeClass() {
                switch (this.stepPillType) {
                    case 'PUSH':
                        return 'bg-red-dark';
                    case 'IN_APP':
                        return 'bg-green-dark';
                    case 'EMAIL':
                        return 'bg-blue-dark';
                    case 'IN_APP_NO_PUSH':
                        return 'bg-green-light in-app-no-push';
                    case 'NO_MESSAGE':
                        return 'bg-red-dark';
                    default:
                        return '';
                }
            },
            channelIcon() {
                return {
                    EMAIL: 'envelope',
                    IN_APP: 'mobile',
                    PUSH: 'bell',
                    IN_APP_NO_PUSH: 'mobile',
                    NO_MESSAGE: 'ban',
                }[this.stepPillType];
            },
        },
        watch: {
            active() {
                this.refreshToggle++;
            },
        },
    };
</script>

<style scoped>
    .step-elements {
        display: inline-block;
        width: 100%;
    }

    .step-elements:hover {
        background-color: #efefef;
        border-radius: 0.2rem;
    }

    .step-pill {
        display: inline-block;
        min-width: 100px;
        border-radius: 0.25rem;
        padding: 5px 7px 5px 7px;
        color: #ffffff;
    }

    .in-app-no-push {
        width: 160px !important;
    }

    .delete-btn {
        border-radius: 100%;
        height: 2rem;
        width: 2rem;
        font-weight: bolder;
    }
</style>
