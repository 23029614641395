<template>
    <div
        class="py-1 px-1 border-b flex-auto text-center cursor-pointer"
        :class="isActive ? 'border-pink-muzmatch' : ''"
        @click="$emit('clicked', name)"
    >
        <span class="text-xs cursor-pointer" :class="isActive ? 'text-pink-muzmatch font-semibold' : ''">
            {{ name }}
        </span>
    </div>
</template>

<script>
    export default {
        props: {
            name: {
                type: String,
                required: true,
            },
            active: {
                type: String,
                required: true,
            },
        },
        computed: {
            isActive() {
                return this.active.toLowerCase() === this.name.toLowerCase();
            },
        },
    };
</script>
