<template>
    <div class="flex items-center">
        <div class="w-11/12 mr-2">
            <div class="flex space-x-8 items-center">
                <div class="w-1/3">
                    <dropdown
                        class="mr-2"
                        placeholder="Type"
                        :default="queryData.type"
                        :options="queryOptions"
                        @updated="setQueryType"
                    />
                </div>
                <div v-if="queryType && !isHidden" class="w-1/3">
                    <dropdown
                        class="mr-2"
                        placeholder="Comparison"
                        :default="defaultComparison"
                        :options="comparisonOptions"
                        :is-disabled="comparisonOptions.length === 0"
                        @updated="handleQueryUpdate('comparison', $event)"
                    />
                </div>
                <div v-if="queryType" class="w-1/3">
                    <input
                        v-if="isTextType"
                        v-model="textValue"
                        :type="queryType.validate"
                        class="block appearance-none w-full border border-gray-200 text-black py-3 capitalize px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 bg-grey-200"
                        placeholder="Value"
                    />
                    <input v-else-if="isHidden" type="text" class="hidden" />
                    <date-picker v-else-if="isDateType" v-model="dateValue" mode="date">
                        <template #default="{ inputValue, inputEvents }">
                            <input
                                class="block appearance-none w-full border border-gray-200 text-black py-3 capitalize px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 bg-grey-200"
                                :value="inputValue"
                                placeholder="Input Date"
                                v-on="inputEvents"
                            />
                        </template>
                    </date-picker>
                    <dropdown
                        v-else-if="isDropdown"
                        class="mr-2"
                        placeholder="Comparison"
                        :default="queryData.value"
                        :options="valueOptions"
                        @updated="handleQueryUpdate('value', $event)"
                    />
                    <div v-else-if="isMultiDropdown">
                        <multiselect
                            v-model="multiValue"
                            :options="valueMultipleOptions"
                            track-by="id"
                            label="name"
                            :multiple="true"
                            class="mt-1 w-full"
                        />

                        <div v-if="queryData.type === 'cities'">
                            <input
                                v-model="plusMiles"
                                class="mt-2 block appearance-none w-full border border-gray-200 text-black py-3 capitalize px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 bg-grey-200"
                                type="number"
                                placeholder="+ Miles"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-1/12">
            <icon
                v-if="index !== 0"
                size="xs"
                icon="plus"
                class="transform rotate-45 cursor-pointer"
                @click="$emit('remove-row', index)"
            ></icon>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import Multiselect from 'vue-multiselect';
    import DatePicker from 'v-calendar/lib/components/date-picker.umd';
    import Dropdown from '../../components/Dropdown/Dropdown.vue';

    export default {
        components: { Dropdown, Multiselect, DatePicker },
        props: {
            options: {
                type: Array,
                required: true,
            },
            index: {
                type: Number,
                required: true,
            },
            row: {
                type: Object,
                default: null,
            },
        },
        data() {
            return {
                queryType: null,
                queryModel: { type: null, comparison: null, value: null },
                queryData: { type: null, comparison: null, value: null },
                multiValue: [],
                textValue: '',
                dateValue: null,
                plusMiles: null,
            };
        },
        computed: {
            defaultComparison() {
                if (this.queryData.comparison !== null) {
                    return this.queryData.comparison;
                }

                // Just default to first option to potentially save some clicks
                if (this.comparisonOptions.length >= 1) {
                    this.handleQueryUpdate('comparison', this.comparisonOptions[0].value);
                    return this.comparisonOptions[0].value;
                }

                return null;
            },
            queryOptions() {
                return this.options.map((a) => ({
                    label: a.type.replaceAll('_', ' '),
                    value: a.type,
                }));
            },
            comparisonOptions() {
                if (!this.queryType) {
                    return [];
                }

                return this.queryType.comparisons.map((i) => ({
                    value: i,
                    label: i,
                }));
            },
            valueOptions() {
                if (!this.isDropdown && !this.isMultiDropdown) {
                    return [];
                }

                return Object.keys(this.queryType.values).map((key) => ({
                    value: key,
                    label: this.queryType.values[key],
                }));
            },
            valueMultipleOptions() {
                return this.valueOptions.map((i) => ({
                    id: i.value,
                    name: i.label,
                }));
            },
            isTextType() {
                if (!this.queryType) return false;
                return this.queryType.values === null;
            },
            isDateType() {
                if (!this.queryType) return false;
                return this.queryType.values === 'date';
            },
            isHidden() {
                if (!this.queryType) return false;
                return this.queryType.values === 'hidden';
            },
            isMultiDropdown() {
                if (!this.queryType) return false;
                return this.queryType.multi && !!this.queryType.values;
            },
            isDropdown() {
                if (!this.queryType) return false;
                return !this.queryType.multi && !!this.queryType.values;
            },
        },
        watch: {
            comparisonOptions(value) {
                if (value.length !== 1) {
                    return;
                }

                this.queryData.comparison = value[0].value;
            },
            multiValue(value) {
                if (!['in', 'not in'].includes(this.queryData.comparison)) return;

                this.handleQueryUpdate('value', value);
            },
            dateValue(value) {
                if (!value) return;

                this.handleQueryUpdate('value', moment.utc(value).format('YYYY-MM-DD'));
            },
            textValue(value) {
                this.handleQueryUpdate('value', value);
            },
            plusMiles(value) {
                if (this.queryData.value.length !== 1 && value > 0) return;

                const miles = value > 3959 ? 3959 : value;
                this.plusMiles = miles;

                this.handleQueryUpdate('value', [{ ...this.queryData.value[0], miles }]);
            },
        },
        created() {
            if (!this.row) return;

            this.queryData = this.row;
            this.setQueryType(this.queryData.type, false);

            if (this.isMultiDropdown) this.multiValue = this.queryData.value;
            if (this.isTextType) this.textValue = this.queryData.value;
            if (this.queryData.value[0] && this.queryData.value[0].miles)
                this.plusMiles = this.queryData.value[0].miles;
        },
        methods: {
            setQueryType(value, shouldReset = true) {
                if (shouldReset) this.reset();
                this.queryType = this.options.find((a) => a.type === value);
                this.handleQueryUpdate('type', value);
            },
            handleQueryUpdate(type, value) {
                this.queryData[type] = value;
            },
            reset() {
                this.multiValue = [];
                this.textValue = '';
                this.queryData = _.clone(this.queryModel);
            },
        },
    };
</script>
