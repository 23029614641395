<template>
    <section class="mb-8">
        <div class="flex mb-12 mt-4">
            <div class="flex items-center">
                <dropdown
                    style="max-width: 15rem"
                    class="my-2 mr-2"
                    placeholder="Sort By"
                    :options="sortTypes"
                    @updated="(type) => (this.sortType = type)"
                />
                <div v-if="!showPendingReleases">
                    <pill class="cursor-pointer" @clicked="showPendingReleases = true">Show Pending Releases</pill>
                </div>
            </div>
            <h2 class="text-3xl flex-1 text-center">App Versions</h2>
            <div class="pt-2 relative mx-auto text-gray-600 flex-1 text-right">
                <input
                    v-model="searchQuery"
                    class="border-2 border-gray-300 bg-white h-10 px-5 max-w-xl rounded-lg text-sm focus:outline-none"
                    type="search"
                    name="search"
                    placeholder="Search"
                />
                <button class="ml-6" title="Add new App Version" role="button" @click="addRelease">
                    <icon size="sm" icon="plus" />
                </button>
            </div>
        </div>
        <div class="md:flex">
            <div class="w-full md:w-1/2 mx-2">
                <div class="text-2xl flex items-center justify-center font-bold">
                    <platform-icon platform="ios" :custom-classes="`h-6 mb-1 text-black`" />
                    iOS
                </div>
                <div v-if="iosMin" class="flex items-center justify-center font-bold mb-4">
                    <a href="https://iosref.com/ios" target="_blank">Minimum DeviceOS Version: {{ iosMin }}</a>
                </div>
                <release-note-card
                    v-for="(release, index) in iosToShow"
                    v-if="showPendingReleases || (!showPendingReleases && release.released_date !== null)"
                    :key="index"
                    :release="release"
                    :index="index"
                    :is-version-with-most-users="versionWithMostUsers.ios === release.version"
                    :is-below-min-version="isBelowMinVersion(release)"
                    :can-set-suggested="false"
                    @releases-updates="(notes) => $emit('updated-release-notes', notes)"
                />
                <p v-show="iosToShow.length === 0" class="text-xl text-center">Nothing to display</p>
                <div class="max-w-xs mx-auto mt-8">
                    <M2Button v-show="iosToShow.length !== ios.length" @clicked="showMoreIOS += 10">Load More</M2Button>
                </div>
            </div>
            <div class="w-full md:w-1/2 mx-2">
                <div class="text-2xl flex items-center justify-center font-bold">
                    <platform-icon platform="android" :custom-classes="`h-6 mb-1 text-black`" />
                    Android
                </div>
                <div v-if="androidMin != ''" class="flex items-center justify-center font-bold mb-4">
                    Minimum DeviceOS Version: {{ androidMin }}
                </div>
                <release-note-card
                    v-for="(release, index) in androidToShow"
                    v-if="showPendingReleases || (!showPendingReleases && release.released_date !== null)"
                    :key="release.version"
                    :release="release"
                    :index="index"
                    :is-version-with-most-users="versionWithMostUsers.android === release.version"
                    :is-below-min-version="isBelowMinVersion(release)"
                    :can-set-suggested="true"
                    @releases-updates="(notes) => $emit('updated-release-notes', notes)"
                />
                <p v-show="androidToShow.length === 0" class="text-xl text-center">Nothing to display</p>
                <div class="max-w-xs mx-auto mt-8">
                    <M2Button v-show="androidToShow.length !== android.length" @clicked="showMoreAndroid += 10">
                        Load More
                    </M2Button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapActions } from 'vuex';
    import compareVersions from 'compare-versions';
    import ReleaseNoteCard from './ReleaseNoteCard';
    import Dropdown from '../../components/Dropdown/Dropdown';
    import M2Button from '../../components/Buttons/M2Button';
    import { dashboardAxios } from '../../../axios.config';
    import Pill from './Pill';

    export default {
        components: {
            ReleaseNoteCard,
            M2Button,
            Dropdown,
            Pill,
            PlatformIcon: () => import('./PlatformIcon'),
        },
        props: {
            releaseNotes: {
                type: Array,
                required: true,
            },
            androidMin: {
                type: String,
                default: '',
            },
            iosMin: {
                type: String,
                default: '',
            },
        },
        data() {
            return {
                sortType: null,
                searchQuery: '',
                showMoreIOS: 0,
                showMoreAndroid: 0,
                sortTypes: [
                    { value: 'dau', label: 'DAU' },
                    { value: 'chron', label: 'Chronologically' },
                ],
                showPendingReleases: false,
            };
        },
        computed: {
            ios() {
                return this.releaseNotes.filter((note) => note.platform === 'ios');
            },
            iosToShow() {
                const items = this.filterOldReleases(this.ios);

                if (this.searchQuery.length !== 0) {
                    return this.filterSearch(items);
                }

                if (this.sortType) {
                    return this.sortReleases(items);
                }

                let minIOSVersion = items.findIndex((note) => note.force_minimum_version === '1');

                return items.slice(0, minIOSVersion + 4 + this.showMoreIOS);
            },
            android() {
                return this.releaseNotes.filter((note) => note.platform === 'android');
            },
            androidToShow() {
                const items = this.filterOldReleases(this.android);

                if (this.searchQuery.length !== 0) {
                    return this.filterSearch(items);
                }

                if (this.sortType) {
                    const data = this.sortReleases(items);
                    if (data) return data;
                }

                let minAndroidVersion = items.findIndex((note) => note.force_minimum_version === '1');

                return items.slice(0, minAndroidVersion + 4 + this.showMoreAndroid);
            },
            versionWithMostUsers() {
                const releases = this.releaseNotes
                    .concat()
                    .filter((note) => note.released_date !== null)
                    .sort((a, b) => parseInt(b.userCount, 10) - parseInt(a.userCount, 10));
                return releases
                    ? {
                          android: releases.filter((note) => note.platform === 'android')[0].version,
                          ios: releases.filter((note) => note.platform === 'ios')[0].version,
                      }
                    : {};
            },
            iosPages() {
                return Math.floor((this.ios.length - this.iosToShow.length) / 6);
            },
            androidPages() {
                return Math.floor((this.android.length - this.androidToShow.length) / 6);
            },
            pages() {
                return Math.floor(this.iosPages, this.androidPages);
            },
        },
        methods: {
            ...mapActions(['alert']),
            isBelowMinVersion(release) {
                const min = this.releaseNotes.find(
                    (re) => re.platform === release.platform && re.force_minimum_version === '1',
                );
                return compareVersions(release.version.replace('a', ''), min.version.replace('a', '')) === -1;
            },
            filterOldReleases(items) {
                return items.filter((release) => {
                    const minVersion = items.find((i) => i.force_minimum_version === '1');
                    return (
                        compareVersions(release.version.replace('a', ''), minVersion.version.replace('a', '')) !== -1 ||
                        release.released_date !== null
                    );
                });
            },
            sortReleases(items) {
                if (this.sortType === 'dau') return [...items].sort((a, b) => b.dau - a.dau);

                if (this.sortType === 'chron')
                    return [...items].sort((a, b) =>
                        compareVersions(b.version.replace('a', ''), a.version.replace('a', '')),
                    );

                return null;
            },
            filterSearch(rows) {
                return rows.filter((row) => {
                    return Object.keys(row).some((key) => {
                        return String(row[key]).toLowerCase().indexOf(this.searchQuery) > -1;
                    });
                });
            },
            async addRelease() {
                const release = await this.$swal({
                    title: 'Add new release',
                    html: `
                    <select id="platform" name="platform" class="swal2-select w-full border-2 rounded" required>
                        <option value="null" disabled selected>Select a Platform</option>
                        <option value="ios">iOS</option>
                        <option value="android">Android</option>
                    </select>

                    <input id="version" name="version" placeholder="Version (6.0.0 or 6.0.0a)" class="swal2-input" required>
                `,
                    confirmButtonText: 'Save',
                    focusConfirm: false,
                    preConfirm: async () => {
                        if (
                            document.getElementById('platform').value === 'null' ||
                            document.getElementById('version').value === ''
                        ) {
                            this.alert({
                                message: `Please fill out all fields`,
                                type: 'danger',
                            });
                            return false;
                        }

                        try {
                            const resp = await dashboardAxios.post(`/v1/release-notes`, {
                                platform: document.getElementById('platform').value,
                                version: document.getElementById('version').value,
                            });

                            this.$emit('updated-release-notes', resp.data.result);
                        } catch {
                            this.alert({
                                message: `Something went wrong, please try again`,
                                type: 'danger',
                            });

                            return false;
                        }
                    },
                });

                if (release.value.platform === 'null' || release.value.version === '') {
                    this.alert({
                        message: 'Please fill out all fields',
                        type: 'danger',
                    });
                }
            },
        },
    };
</script>

<style scoped>
    .page * {
        font-family: 'Spezia';
    }

    .bg-grey-custom {
        background: #f0f4fc;
    }
</style>
