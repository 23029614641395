<template>
    <button
        class="dashboard-button hover:shadow-md focus:outline-none rounded-full text-white py-2 px-4 whitespace-nowrap"
        :class="buttonClass"
        @click="buttonClick()"
    >
        {{ text }}
    </button>
</template>

<script>
    export default {
        name: 'PrimaryButton',

        props: {
            text: {
                type: String,
                required: true,
            },

            handleClick: {
                type: Function,
                default: async () => {},
            },

            handleError: {
                type: Function,
                default: async (error) => {},
            },

            disabled: {
                type: Boolean,
                default: false,
            },

            confirmed: {
                type: Boolean,
                default: false,
            },

            confirmMessage: {
                type: String,
                default: 'This action is dangerous.',
            },
        },

        data() {
            return {
                loading: false,
            };
        },

        computed: {
            buttonClass() {
                let buttonClass = this.loading ? 'bg-pink-lighter' : 'bg-pink-muzmatch';
                if (this.disabled) {
                    buttonClass = 'bg-pink-lighter cursor-not-allowed';
                }

                return buttonClass;
            },
        },

        methods: {
            async buttonClick() {
                if (this.disabled) {
                    return;
                }

                let response = { value: true };

                if (this.confirmed) {
                    response = await this.$swal({
                        title: 'Are you sure?',
                        icon: 'warning',
                        text: this.confirmMessage,
                        showCancelButton: true,
                        confirmButtonText: 'Continue',
                    });
                }

                if (!response.value) {
                    return;
                }

                if (this.loading) {
                    return;
                }

                this.loading = true;

                try {
                    await this.handleClick();
                } catch (error) {
                    await this.handleError(error);
                }

                this.loading = false;
            },
        },
    };
</script>
