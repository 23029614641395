<template>
    <ul>
        <template v-for="(jsonFilterList, groupIndex) in audienceJsonFilter">
            <li
                v-for="(jsonFilter, i) in jsonFilterList"
                :key="groupIndex + '_' + i"
                class=""
                :class="{ 'mt-2': i + 1 !== jsonFilterList.length }"
            >
                {{ jsonFilter.type }}
                <span v-if="jsonFilter.type !== 'muzmatch_email'">{{ jsonFilter.comparison }}</span>
                <template v-if="jsonFilter.comparison !== 'in' && jsonFilter.comparison !== 'not in'">
                    <span v-if="jsonFilter.type === 'active' && jsonFilter.value === null">both</span>
                    <span v-else>{{ jsonFilter.value }}</span>
                </template>
                <template v-else>
                    {{ jsonFilter.value.map((i) => i.name).join(', ') }}
                    <span v-if="jsonFilter.value[0] && jsonFilter.value[0].miles">
                        + {{ jsonFilter.value[0].miles }} miles
                    </span>
                </template>
                <div v-if="i + 1 !== jsonFilterList.length" class="text-sm text-grey-500">or</div>
            </li>
            <li
                v-if="groupIndex !== audienceJsonFilter.length - 1"
                :key="groupIndex"
                class="text-sm text-grey-500 my-2"
            >
                and
            </li>
        </template>
    </ul>
</template>

<script>
    export default {
        name: 'AudienceJsonDescription',
        props: {
            audienceJsonFilter: {
                type: Array,
                required: true,
            },
        },
    };
</script>

<style scoped></style>
