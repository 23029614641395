<template>
    <div class="approval-hist-tab">
        <ApprovalHistoryContentRowsModal v-if="isModalOpen" :content="modalLog" @hide="isModalOpen = false" />
        <div>
            <SecondaryButton text="Reload" class="m-2" :handle-click="reload"></SecondaryButton>
        </div>
        <div v-if="Object.keys(filteredLogs).length > 0 && (!loading || earliestID > 0)">
            <table class="sm:w-full text-left table-fixed overflow-x-scroll">
                <thead>
                    <tr class="bg-grey-100 text-grey-700 uppercase">
                        <th class="px-2 py-2 w-1/6">Created</th>
                        <th v-if="showMemberID" class="px-2 py-2 w-1/6">Member ID</th>
                        <th class="px-2 py-2 w-1/6">Approved</th>
                        <th class="px-2 py-2 w-1/6">Selfie</th>
                        <th class="px-2 py-2 w-1/6">Main Photo</th>
                        <th class="px-2 py-2 w-1/2">Other Photos</th>
                        <th class="px-2 py-2 w-1/6">Status Message</th>
                        <th class="px-2 py-2 w-1/6">About Me</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(log, key) in filteredLogs">
                        <tr :key="key" class="cursor-pointer border-white border bg-grey-300" @click="toggleOpen(key)">
                            <td colspan="8" class="px-2 py-2 break-words font-bold">{{ key }}</td>
                        </tr>

                        <tr
                            v-for="(event, idx) in log"
                            v-show="opened[key]"
                            :key="key + '_' + idx"
                            class="border hover:bg-grey-200"
                        >
                            <td class="px-2 py-2 break-words">
                                {{ time(event.createdAt, 'HH:mm:ss') }}
                            </td>

                            <td v-if="showMemberID" class="px-2 py-2 break-words">
                                <span>{{ event.memberID }}</span>
                                <router-link
                                    :to="getMemberRoute(event)"
                                    class="hover:text-grey-600 text-grey-800 cursor-pointer p-2"
                                    target="_blank"
                                >
                                    <icon icon="link"></icon>
                                </router-link>
                            </td>

                            <td class="px-2 py-2 font-mono font-bold break-words">
                                <span
                                    v-tooltip="event.approvedTitle"
                                    class="p-2 rounded-full w-8 h-8 flex justify-center items-center cursor-pointer"
                                >
                                    <icon v-if="parseInt(event.approved) === 1" icon="check" class="text-green"></icon>
                                    <icon v-else icon="times" class="text-red"></icon>

                                    <span v-if="parseInt(event.changedViaWali)" class="ml-1">(w)</span>
                                </span>
                            </td>

                            <td class="px-2 py-2 font-mono font-bold break-words">
                                <div class="cntr">
                                    <img
                                        class="cursor-pointer"
                                        :class="event.photoVerificationClass"
                                        :src="
                                            event.photoVerificationSignedUrl
                                                ? event.photoVerificationSignedUrl
                                                : resolveVerificationPhotoUrl(
                                                      event.photoVerification,
                                                      event.hashMemberID,
                                                      'portrait',
                                                      '@1x',
                                                  )
                                        "
                                        @click="openModal('photoVerification', event)"
                                    />
                                    <br />
                                    <svg
                                        v-tooltip="event.photoVerificationFaceTitle"
                                        viewBox="0 0 225.000000 225.000000"
                                        preserveAspectRatio="xMidYMid meet"
                                        class="text-gray-600 fill-current"
                                        :class="event.photoVerificationFace"
                                    >
                                        <g
                                            transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)"
                                            stroke="none"
                                        >
                                            <path
                                                d="M990 2050 c-145 -19 -319 -95 -445 -195 -427 -337 -474 -970 -101
                                            -1363 175 -185 347 -270 589 -292 279 -26 523 54 714 234 139 131 216 249 274
                                            421 22 65 24 85 24 275 l-1 205 -33 90 c-38 106 -78 179 -136 255 -53 68 -165
                                            176 -227 219 -74 50 -205 109 -291 130 -89 23 -272 34 -367 21z m-51 -421
                                            c116 -128 284 -234 447 -281 67 -19 103 -23 239 -23 88 0 174 3 192 7 31 7 32
                                            7 43 -45 15 -74 14 -254 -4 -329 -33 -143 -100 -260 -207 -365 -77 -76 -118
                                            -104 -212 -148 -108 -52 -175 -65 -312 -65 -137 0 -204 13 -312 65 -94 44
                                            -135 72 -212 148 -146 142 -213 297 -225 520 l-4 88 29 17 c16 9 57 33 91 52
                                            122 69 260 220 338 368 18 34 36 62 39 62 3 0 35 -32 70 -71z"
                                            />
                                            <path
                                                d="M810 1139 c-14 -6 -35 -19 -47 -31 -66 -62 -16 -188 74 -188 72 0
                                            118 48 117 121 -2 45 -24 75 -71 95 -37 16 -39 16 -73 3z"
                                            />
                                            <path
                                                d="M1363 1136 c-59 -27 -85 -93 -58 -151 33 -69 124 -88 175 -37 53 53
                                            48 131 -10 173 -36 26 -70 31 -107 15z"
                                            />
                                        </g>
                                    </svg>
                                    <span
                                        v-if="
                                            event.photoVerificationInfo !== null &&
                                            event.photoVerificationInfo.mediaResult.liveness
                                        "
                                        v-tooltip="'Liveness ' + event.photoVerificationInfo.mediaResult.liveness"
                                    >
                                        <icon
                                            class="custom-icon"
                                            :class="
                                                event.photoVerificationInfo.mediaResult.liveness === 'REAL'
                                                    ? 'text-green'
                                                    : 'text-red'
                                            "
                                            :icon="
                                                event.photoVerificationInfo.mediaResult.liveness === 'REAL'
                                                    ? 'check-circle'
                                                    : 'times-circle'
                                            "
                                        />
                                    </span>
                                </div>
                            </td>

                            <td class="px-2 py-2 font-mono font-bold break-words">
                                <div class="cntr">
                                    <img
                                        class="cursor-pointer"
                                        :class="event.photo1Class"
                                        :src="
                                            event.photo1SignedUrl
                                                ? event.photo1SignedUrl
                                                : resolvePhotoUrl(event.photo1, event.hashMemberID, 'portrait', '@1x')
                                        "
                                        @click="openModal('photo1', event)"
                                    />
                                    <br />
                                    <svg
                                        v-tooltip="event.photo1FaceTitle"
                                        viewBox="0 0 225.000000 225.000000"
                                        preserveAspectRatio="xMidYMid meet"
                                        class="text-gray-600 fill-current"
                                        :class="event.photo1Face"
                                    >
                                        <g
                                            transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)"
                                            stroke="none"
                                        >
                                            <path
                                                d="M990 2050 c-145 -19 -319 -95 -445 -195 -427 -337 -474 -970 -101
                                            -1363 175 -185 347 -270 589 -292 279 -26 523 54 714 234 139 131 216 249 274
                                            421 22 65 24 85 24 275 l-1 205 -33 90 c-38 106 -78 179 -136 255 -53 68 -165
                                            176 -227 219 -74 50 -205 109 -291 130 -89 23 -272 34 -367 21z m-51 -421
                                            c116 -128 284 -234 447 -281 67 -19 103 -23 239 -23 88 0 174 3 192 7 31 7 32
                                            7 43 -45 15 -74 14 -254 -4 -329 -33 -143 -100 -260 -207 -365 -77 -76 -118
                                            -104 -212 -148 -108 -52 -175 -65 -312 -65 -137 0 -204 13 -312 65 -94 44
                                            -135 72 -212 148 -146 142 -213 297 -225 520 l-4 88 29 17 c16 9 57 33 91 52
                                            122 69 260 220 338 368 18 34 36 62 39 62 3 0 35 -32 70 -71z"
                                            />
                                            <path
                                                d="M810 1139 c-14 -6 -35 -19 -47 -31 -66 -62 -16 -188 74 -188 72 0
                                            118 48 117 121 -2 45 -24 75 -71 95 -37 16 -39 16 -73 3z"
                                            />
                                            <path
                                                d="M1363 1136 c-59 -27 -85 -93 -58 -151 33 -69 124 -88 175 -37 53 53
                                            48 131 -10 173 -36 26 -70 31 -107 15z"
                                            />
                                        </g>
                                    </svg>
                                    <svg
                                        v-tooltip="event.photo1CmpTitle"
                                        viewBox="0 0 225.000000 225.000000"
                                        preserveAspectRatio="xMidYMid meet"
                                        class="text-gray-600 fill-current"
                                        :class="event.photo1Cmp"
                                    >
                                        <g
                                            transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
                                            stroke="none"
                                        >
                                            <path
                                                d="M695 1560 c-3 -6 -9 -57 -12 -115 l-6 -105 -214 0 c-223 0 -262 -6
                                            -277 -45 -3 -9 -6 -61 -6 -116 0 -106 13 -149 49 -169 12 -6 107 -10 235 -10
                                            l216 0 0 -94 c0 -99 12 -127 44 -109 9 5 99 84 201 177 175 159 185 170 185
                                            204 0 32 -9 45 -73 107 -115 110 -296 269 -317 277 -11 4 -22 3 -25 -2z"
                                            />
                                            <path
                                                d="M1083 939 c-213 -192 -213 -192 -213 -234 0 -18 8 -42 18 -52 34 -39
                                            347 -320 368 -331 36 -19 44 2 44 115 l0 103 225 0 226 0 24 25 c24 23 25 30
                                            25 140 0 115 0 117 -29 146 l-29 29 -221 0 -221 0 0 98 c0 91 -8 122 -31 122
                                            -4 0 -88 -72 -186 -161z"
                                            />
                                        </g>
                                    </svg>
                                </div>
                            </td>

                            <td class="px-2 py-2 font-mono font-bold break-words">
                                <span class="cntr">
                                    <img
                                        class="cursor-pointer"
                                        :class="event.photo2Class"
                                        :src="
                                            event.photo2SignedUrl
                                                ? event.photo2SignedUrl
                                                : resolvePhotoUrl(event.photo2, event.hashMemberID, 'portrait', '@1x')
                                        "
                                        @click="openModal('photo2', event)"
                                    />
                                    <br />
                                    <svg
                                        v-tooltip="event.photo2FaceTitle"
                                        viewBox="0 0 225.000000 225.000000"
                                        preserveAspectRatio="xMidYMid meet"
                                        class="text-gray-600 fill-current"
                                        :class="event.photo2Face"
                                    >
                                        <g
                                            transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)"
                                            stroke="none"
                                        >
                                            <path
                                                d="M990 2050 c-145 -19 -319 -95 -445 -195 -427 -337 -474 -970 -101
                                            -1363 175 -185 347 -270 589 -292 279 -26 523 54 714 234 139 131 216 249 274
                                            421 22 65 24 85 24 275 l-1 205 -33 90 c-38 106 -78 179 -136 255 -53 68 -165
                                            176 -227 219 -74 50 -205 109 -291 130 -89 23 -272 34 -367 21z m-51 -421
                                            c116 -128 284 -234 447 -281 67 -19 103 -23 239 -23 88 0 174 3 192 7 31 7 32
                                            7 43 -45 15 -74 14 -254 -4 -329 -33 -143 -100 -260 -207 -365 -77 -76 -118
                                            -104 -212 -148 -108 -52 -175 -65 -312 -65 -137 0 -204 13 -312 65 -94 44
                                            -135 72 -212 148 -146 142 -213 297 -225 520 l-4 88 29 17 c16 9 57 33 91 52
                                            122 69 260 220 338 368 18 34 36 62 39 62 3 0 35 -32 70 -71z"
                                            />
                                            <path
                                                d="M810 1139 c-14 -6 -35 -19 -47 -31 -66 -62 -16 -188 74 -188 72 0
                                            118 48 117 121 -2 45 -24 75 -71 95 -37 16 -39 16 -73 3z"
                                            />
                                            <path
                                                d="M1363 1136 c-59 -27 -85 -93 -58 -151 33 -69 124 -88 175 -37 53 53
                                            48 131 -10 173 -36 26 -70 31 -107 15z"
                                            />
                                        </g>
                                    </svg>
                                    <svg
                                        v-tooltip="event.photo2CmpTitle"
                                        viewBox="0 0 225.000000 225.000000"
                                        preserveAspectRatio="xMidYMid meet"
                                        class="text-gray-600 fill-current"
                                        :class="event.photo2Cmp"
                                    >
                                        <g
                                            transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
                                            stroke="none"
                                        >
                                            <path
                                                d="M695 1560 c-3 -6 -9 -57 -12 -115 l-6 -105 -214 0 c-223 0 -262 -6
                                            -277 -45 -3 -9 -6 -61 -6 -116 0 -106 13 -149 49 -169 12 -6 107 -10 235 -10
                                            l216 0 0 -94 c0 -99 12 -127 44 -109 9 5 99 84 201 177 175 159 185 170 185
                                            204 0 32 -9 45 -73 107 -115 110 -296 269 -317 277 -11 4 -22 3 -25 -2z"
                                            />
                                            <path
                                                d="M1083 939 c-213 -192 -213 -192 -213 -234 0 -18 8 -42 18 -52 34 -39
                                            347 -320 368 -331 36 -19 44 2 44 115 l0 103 225 0 226 0 24 25 c24 23 25 30
                                            25 140 0 115 0 117 -29 146 l-29 29 -221 0 -221 0 0 98 c0 91 -8 122 -31 122
                                            -4 0 -88 -72 -186 -161z"
                                            />
                                        </g>
                                    </svg>
                                </span>
                                <span class="cntr">
                                    <img
                                        class="cursor-pointer"
                                        :class="event.photo3Class"
                                        :src="
                                            event.photo3SignedUrl
                                                ? event.photo3SignedUrl
                                                : resolvePhotoUrl(event.photo3, event.hashMemberID, 'portrait', '@1x')
                                        "
                                        @click="openModal('photo3', event)"
                                    />
                                    <br />
                                    <svg
                                        v-tooltip="event.photo3FaceTitle"
                                        viewBox="0 0 225.000000 225.000000"
                                        preserveAspectRatio="xMidYMid meet"
                                        class="text-gray-600 fill-current"
                                        :class="event.photo3Face"
                                    >
                                        <g
                                            transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)"
                                            stroke="none"
                                        >
                                            <path
                                                d="M990 2050 c-145 -19 -319 -95 -445 -195 -427 -337 -474 -970 -101
                                            -1363 175 -185 347 -270 589 -292 279 -26 523 54 714 234 139 131 216 249 274
                                            421 22 65 24 85 24 275 l-1 205 -33 90 c-38 106 -78 179 -136 255 -53 68 -165
                                            176 -227 219 -74 50 -205 109 -291 130 -89 23 -272 34 -367 21z m-51 -421
                                            c116 -128 284 -234 447 -281 67 -19 103 -23 239 -23 88 0 174 3 192 7 31 7 32
                                            7 43 -45 15 -74 14 -254 -4 -329 -33 -143 -100 -260 -207 -365 -77 -76 -118
                                            -104 -212 -148 -108 -52 -175 -65 -312 -65 -137 0 -204 13 -312 65 -94 44
                                            -135 72 -212 148 -146 142 -213 297 -225 520 l-4 88 29 17 c16 9 57 33 91 52
                                            122 69 260 220 338 368 18 34 36 62 39 62 3 0 35 -32 70 -71z"
                                            />
                                            <path
                                                d="M810 1139 c-14 -6 -35 -19 -47 -31 -66 -62 -16 -188 74 -188 72 0
                                            118 48 117 121 -2 45 -24 75 -71 95 -37 16 -39 16 -73 3z"
                                            />
                                            <path
                                                d="M1363 1136 c-59 -27 -85 -93 -58 -151 33 -69 124 -88 175 -37 53 53
                                            48 131 -10 173 -36 26 -70 31 -107 15z"
                                            />
                                        </g>
                                    </svg>
                                    <svg
                                        v-tooltip="event.photo3CmpTitle"
                                        viewBox="0 0 225.000000 225.000000"
                                        preserveAspectRatio="xMidYMid meet"
                                        class="text-gray-600 fill-current"
                                        :class="event.photo3Cmp"
                                    >
                                        <g
                                            transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
                                            stroke="none"
                                        >
                                            <path
                                                d="M695 1560 c-3 -6 -9 -57 -12 -115 l-6 -105 -214 0 c-223 0 -262 -6
                                            -277 -45 -3 -9 -6 -61 -6 -116 0 -106 13 -149 49 -169 12 -6 107 -10 235 -10
                                            l216 0 0 -94 c0 -99 12 -127 44 -109 9 5 99 84 201 177 175 159 185 170 185
                                            204 0 32 -9 45 -73 107 -115 110 -296 269 -317 277 -11 4 -22 3 -25 -2z"
                                            />
                                            <path
                                                d="M1083 939 c-213 -192 -213 -192 -213 -234 0 -18 8 -42 18 -52 34 -39
                                            347 -320 368 -331 36 -19 44 2 44 115 l0 103 225 0 226 0 24 25 c24 23 25 30
                                            25 140 0 115 0 117 -29 146 l-29 29 -221 0 -221 0 0 98 c0 91 -8 122 -31 122
                                            -4 0 -88 -72 -186 -161z"
                                            />
                                        </g>
                                    </svg>
                                </span>
                                <span class="cntr">
                                    <img
                                        class="cursor-pointer"
                                        :class="event.photo4Class"
                                        :src="
                                            event.photo4SignedUrl
                                                ? event.photo4SignedUrl
                                                : resolvePhotoUrl(event.photo4, event.hashMemberID, 'portrait', '@1x')
                                        "
                                        @click="openModal('photo4', event)"
                                    />
                                    <br />
                                    <svg
                                        v-tooltip="event.photo4FaceTitle"
                                        viewBox="0 0 225.000000 225.000000"
                                        preserveAspectRatio="xMidYMid meet"
                                        class="text-gray-600 fill-current"
                                        :class="event.photo4Face"
                                    >
                                        <g
                                            transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)"
                                            stroke="none"
                                        >
                                            <path
                                                d="M990 2050 c-145 -19 -319 -95 -445 -195 -427 -337 -474 -970 -101
                                            -1363 175 -185 347 -270 589 -292 279 -26 523 54 714 234 139 131 216 249 274
                                            421 22 65 24 85 24 275 l-1 205 -33 90 c-38 106 -78 179 -136 255 -53 68 -165
                                            176 -227 219 -74 50 -205 109 -291 130 -89 23 -272 34 -367 21z m-51 -421
                                            c116 -128 284 -234 447 -281 67 -19 103 -23 239 -23 88 0 174 3 192 7 31 7 32
                                            7 43 -45 15 -74 14 -254 -4 -329 -33 -143 -100 -260 -207 -365 -77 -76 -118
                                            -104 -212 -148 -108 -52 -175 -65 -312 -65 -137 0 -204 13 -312 65 -94 44
                                            -135 72 -212 148 -146 142 -213 297 -225 520 l-4 88 29 17 c16 9 57 33 91 52
                                            122 69 260 220 338 368 18 34 36 62 39 62 3 0 35 -32 70 -71z"
                                            />
                                            <path
                                                d="M810 1139 c-14 -6 -35 -19 -47 -31 -66 -62 -16 -188 74 -188 72 0
                                            118 48 117 121 -2 45 -24 75 -71 95 -37 16 -39 16 -73 3z"
                                            />
                                            <path
                                                d="M1363 1136 c-59 -27 -85 -93 -58 -151 33 -69 124 -88 175 -37 53 53
                                            48 131 -10 173 -36 26 -70 31 -107 15z"
                                            />
                                        </g>
                                    </svg>
                                    <svg
                                        v-tooltip="event.photo4CmpTitle"
                                        viewBox="0 0 225.000000 225.000000"
                                        preserveAspectRatio="xMidYMid meet"
                                        class="text-gray-600 fill-current"
                                        :class="event.photo4Cmp"
                                    >
                                        <g
                                            transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
                                            stroke="none"
                                        >
                                            <path
                                                d="M695 1560 c-3 -6 -9 -57 -12 -115 l-6 -105 -214 0 c-223 0 -262 -6
                                            -277 -45 -3 -9 -6 -61 -6 -116 0 -106 13 -149 49 -169 12 -6 107 -10 235 -10
                                            l216 0 0 -94 c0 -99 12 -127 44 -109 9 5 99 84 201 177 175 159 185 170 185
                                            204 0 32 -9 45 -73 107 -115 110 -296 269 -317 277 -11 4 -22 3 -25 -2z"
                                            />
                                            <path
                                                d="M1083 939 c-213 -192 -213 -192 -213 -234 0 -18 8 -42 18 -52 34 -39
                                            347 -320 368 -331 36 -19 44 2 44 115 l0 103 225 0 226 0 24 25 c24 23 25 30
                                            25 140 0 115 0 117 -29 146 l-29 29 -221 0 -221 0 0 98 c0 91 -8 122 -31 122
                                            -4 0 -88 -72 -186 -161z"
                                            />
                                        </g>
                                    </svg>
                                </span>
                                <span class="cntr">
                                    <img
                                        class="cursor-pointer"
                                        :class="event.photo5Class"
                                        :src="
                                            event.photo5SignedUrl
                                                ? event.photo5SignedUrl
                                                : resolvePhotoUrl(event.photo5, event.hashMemberID, 'portrait', '@1x')
                                        "
                                        @click="openModal('photo5', event)"
                                    />
                                    <br />
                                    <svg
                                        v-tooltip="event.photo5FaceTitle"
                                        viewBox="0 0 225.000000 225.000000"
                                        preserveAspectRatio="xMidYMid meet"
                                        class="text-gray-600 fill-current"
                                        :class="event.photo5Face"
                                    >
                                        <g
                                            transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)"
                                            stroke="none"
                                        >
                                            <path
                                                d="M990 2050 c-145 -19 -319 -95 -445 -195 -427 -337 -474 -970 -101
                                            -1363 175 -185 347 -270 589 -292 279 -26 523 54 714 234 139 131 216 249 274
                                            421 22 65 24 85 24 275 l-1 205 -33 90 c-38 106 -78 179 -136 255 -53 68 -165
                                            176 -227 219 -74 50 -205 109 -291 130 -89 23 -272 34 -367 21z m-51 -421
                                            c116 -128 284 -234 447 -281 67 -19 103 -23 239 -23 88 0 174 3 192 7 31 7 32
                                            7 43 -45 15 -74 14 -254 -4 -329 -33 -143 -100 -260 -207 -365 -77 -76 -118
                                            -104 -212 -148 -108 -52 -175 -65 -312 -65 -137 0 -204 13 -312 65 -94 44
                                            -135 72 -212 148 -146 142 -213 297 -225 520 l-4 88 29 17 c16 9 57 33 91 52
                                            122 69 260 220 338 368 18 34 36 62 39 62 3 0 35 -32 70 -71z"
                                            />
                                            <path
                                                d="M810 1139 c-14 -6 -35 -19 -47 -31 -66 -62 -16 -188 74 -188 72 0
                                            118 48 117 121 -2 45 -24 75 -71 95 -37 16 -39 16 -73 3z"
                                            />
                                            <path
                                                d="M1363 1136 c-59 -27 -85 -93 -58 -151 33 -69 124 -88 175 -37 53 53
                                            48 131 -10 173 -36 26 -70 31 -107 15z"
                                            />
                                        </g>
                                    </svg>
                                    <svg
                                        v-tooltip="event.photo5CmpTitle"
                                        viewBox="0 0 225.000000 225.000000"
                                        preserveAspectRatio="xMidYMid meet"
                                        class="text-gray-600 fill-current"
                                        :class="event.photo5Cmp"
                                    >
                                        <g
                                            transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
                                            stroke="none"
                                        >
                                            <path
                                                d="M695 1560 c-3 -6 -9 -57 -12 -115 l-6 -105 -214 0 c-223 0 -262 -6
                                            -277 -45 -3 -9 -6 -61 -6 -116 0 -106 13 -149 49 -169 12 -6 107 -10 235 -10
                                            l216 0 0 -94 c0 -99 12 -127 44 -109 9 5 99 84 201 177 175 159 185 170 185
                                            204 0 32 -9 45 -73 107 -115 110 -296 269 -317 277 -11 4 -22 3 -25 -2z"
                                            />
                                            <path
                                                d="M1083 939 c-213 -192 -213 -192 -213 -234 0 -18 8 -42 18 -52 34 -39
                                            347 -320 368 -331 36 -19 44 2 44 115 l0 103 225 0 226 0 24 25 c24 23 25 30
                                            25 140 0 115 0 117 -29 146 l-29 29 -221 0 -221 0 0 98 c0 91 -8 122 -31 122
                                            -4 0 -88 -72 -186 -161z"
                                            />
                                        </g>
                                    </svg>
                                </span>
                            </td>

                            <td class="px-2 py-2 font-mono font-bold break-words">
                                <span
                                    v-tooltip="event.statusMessageTitle"
                                    class="cursor-pointer mch"
                                    :class="event.statusMessageClass"
                                    @click="openModal('statusMessage', event)"
                                >
                                    {{ event.statusMessage }}
                                </span>
                            </td>

                            <td class="px-2 py-2 font-mono font-bold break-words">
                                <span
                                    v-tooltip="event.longDescriptionTitle"
                                    class="cursor-pointer mch"
                                    :class="event.longDescriptionClass"
                                    @click="openModal('longDescription', event)"
                                >
                                    {{ event.longDescription }}
                                </span>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>

            <div v-if="data && data.earliestID > 1" class="max-w-xs mx-auto mt-4">
                <M2Button :is-disabled="loading" @clicked="loadLogs">
                    <span v-if="loading">Loading</span>
                    <span v-else>Load More</span>
                </M2Button>
            </div>
        </div>

        <div v-else class="flex justify-center items-center font-bold">
            <div v-if="!loading" class="my-4">No Logs Found</div>

            <div v-if="loading" class="flex flex-col items-center mt-20">
                <icon icon="list" class="loading-animation text-5xl mb-2 text-pink-muzmatch"></icon>
                <span class="text-lg mt-2 font-bold">Loading...</span>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { mapActions } from 'vuex';
    import { dashboardAxios } from '../../../../axios.config';
    import ApprovalHistoryContentRowsModal from './ApprovalHistoryContentRowsModal';
    import M2Button from '../../Buttons/M2Button';
    import { resolvePhotoUrl, resolveVerificationPhotoUrl } from '@/helpers/DashboardUtils';
    import SecondaryButton from '../../Buttons/SecondaryButton';
    import { getMemberRoute } from '../../../helpers/RedirectToMember';
    import VTooltip from 'v-tooltip';

    Vue.use(VTooltip);

    function initialState() {
        return {
            search: '',
            logs: [],
            data: null,
            filteredLogs: [],
            loading: false,
            isModalOpen: false,
            modalLog: null,
            openedAction: false,
            opened: {},
            earliestID: null,
        };
    }

    export default {
        components: {
            ApprovalHistoryContentRowsModal,
            M2Button,
            SecondaryButton,
        },
        props: {
            endpoint: {
                type: String,
                required: true,
            },
            segment: {
                type: String,
                required: false,
                default: 'all',
            },
            showMemberID: {
                type: Boolean,
                required: true,
            },
        },
        data() {
            return initialState();
        },
        watch: {
            segment: function () {
                this.reload();
            },
        },
        mounted() {
            this.loadLogs();
        },
        methods: {
            ...mapActions({
                handleError: 'handleError',
            }),

            resolvePhotoUrl,
            resolveVerificationPhotoUrl,
            getMemberRoute,

            time(time, format = 'DD/MM/YYYY HH:mm') {
                return moment(time).format(format);
            },

            filterForModal(field, log) {
                let data = {},
                    info;

                data['Approval Status'] = log[field + 'ApprovalStatus'];
                info = log[field + 'Info'];

                data['Download'] = {
                    hashMemberID: log.hashMemberID,
                    uuid: info.mediaResult.media_id,
                    channel: info.mediaResult.channel,
                    alternateChannel: info.mediaResult.channel === 'PROFILE' ? 'VERIFICATION' : 'PROFILE',
                    type: info.mediaResult.type,
                    extension: info.mediaResult.extension,
                };

                switch (field) {
                    case 'photoVerification':
                        if (info) {
                            data['Raw Image Info'] = '<pre>' + JSON.stringify(info.mediaResult, null, 4) + '</pre>';
                        }
                        break;

                    case 'photo1':
                    case 'photo2':
                    case 'photo3':
                    case 'photo4':
                    case 'photo5':
                        if (info) {
                            data['Images'] =
                                '<img style="width: 150px" src="' +
                                (log.presignedUrls && log.presignedUrls[info.comparison.mediaID1]
                                    ? log.presignedUrls[info.comparison.mediaID1]
                                    : resolvePhotoUrl(info.comparison.mediaID1, info.hashMemberID, 'portrait', '@1x')) +
                                '"/>&nbsp;<img style="width: 150px" src="' +
                                (log.presignedUrls && log.presignedUrls[info.comparison.mediaID2]
                                    ? log.presignedUrls[info.comparison.mediaID2]
                                    : resolvePhotoUrl(info.comparison.mediaID2, info.hashMemberID, 'portrait', '@1x')) +
                                '"/>';
                            data[
                                'Comparison Results'
                            ] = `${info.comparison.method1}: ${info.comparison.method1Result}: / ${info.comparison.method2}: ${info.comparison.method2Result}`;
                            data['Raw Image Info'] = '<pre>' + JSON.stringify(info.mediaResult, null, 4) + '</pre>';
                        }
                        break;

                    case 'statusMessage':
                    case 'longDescription':
                        data['ID'] = info.id;

                        if (info) {
                            data['Full Text'] = info.value;
                            data['Created'] = info.updated_at;

                            if (parseInt(info.gibberishDetected) === 1) {
                                data['Contains gibberish?'] = 'Yes';
                            } else {
                                data['Contains gibberish?'] = 'No';
                            }

                            data['Gibberish %'] = info.gibberishPercentage + '%';
                            data['Gibberish threshold'] = info.gibberishThreshold + '%';

                            let flaggedWordsList = info.flaggedWordsList || [];
                            let dhsTermsList = info.dhsTermsList || [];

                            data['Flagged Words'] = flaggedWordsList.join(', ') || '-';
                            data['DHS Terms'] = dhsTermsList.join(', ') || '-';
                        }
                        break;
                }

                return data;
            },

            openModal(field, log) {
                if (log[field] === null) {
                    return;
                }

                this.isModalOpen = true;
                this.modalLog = this.filterForModal(field, log);
            },

            filterLogs: async function () {
                if (!this.logs) {
                    this.filteredLogs = [];
                }

                this.filteredLogs = _.groupBy(this.logs, (log) => {
                    log.statusMessage = null;
                    log.longDescription = null;

                    let title;

                    ['statusMessage', 'longDescription'].forEach((x) => {
                        log[x + 'Title'] = '';

                        if (log[x + 'Info']) {
                            // TODO: do this in CSS
                            log[x] = log[x + 'Info'].value.substr(0, 6) + '...';

                            title = [];

                            if (parseInt(log[x + 'Info'].gibberishDetected)) {
                                title.push('contains gibberish');
                            }

                            if ((log[x + 'Info'].flaggedWordsList || []).length > 0) {
                                title.push('flagged words found');
                            }

                            if ((log[x + 'Info'].dhsTermsList || []).length > 0) {
                                title.push('DHS terms found');
                            }

                            if (title.length === 0) {
                                if (log[x + 'ApprovalStatus'] === 'AUTO_APPROVED') {
                                    title.push('Auto-approved');
                                } else {
                                    title.push('Approved via Wali');
                                }
                            }

                            log[x + 'Title'] = title.join(', ').charAt(0).toUpperCase() + title.join(', ').slice(1);
                        }

                        switch (log[x + 'ApprovalStatus']) {
                            case 'UNCHECKED':
                                log[x + 'Class'] = 'text-orange';
                                break;

                            case 'WALI_APPROVED':
                            case 'AUTO_APPROVED':
                                log[x + 'Class'] = 'text-green';
                                break;

                            case 'WALI_REJECTED':
                            case 'AUTO_REJECTED':
                                log[x + 'Class'] = 'text-red';
                                break;
                        }
                    });

                    // Turn the images into URLs where they're not null
                    ['photoVerification', 'photo1', 'photo2', 'photo3', 'photo4', 'photo5'].forEach((x) => {
                        log[x + 'Class'] = '';
                        log[x + 'Face'] = 'text-transparent';
                        log[x + 'Cmp'] = 'text-transparent';

                        log[x + 'FaceTitle'] = '';
                        log[x + 'CmpTitle'] = '';

                        if (log[x] !== null) {
                            let info = log[x + 'Info'];

                            if (info) {
                                log[x + 'Face'] = 'text-red';
                                log[x + 'FaceTitle'] = 'No faces found';

                                if (info.mediaResult.face_count === null) {
                                    log[x + 'Face'] = 'text-orange';
                                    log[x + 'FaceTitle'] = 'No face detection results';
                                } else if (parseInt(info.mediaResult.face_count) >= 1) {
                                    log[x + 'Face'] = 'text-green';
                                    log[x + 'FaceTitle'] = 'At least one face found';
                                }

                                log[x + 'Cmp'] = 'text-green';
                                log[x + 'CmpTitle'] = 'Face matches selfie verification';
                                if (typeof info.comparison.failed === 'undefined') {
                                    log[x + 'Cmp'] = 'text-orange';
                                    log[x + 'CmpTitle'] = 'No faces comparison results';
                                } else if (info.comparison.failed) {
                                    log[x + 'Cmp'] = 'text-red';
                                    log[x + 'CmpTitle'] = 'Face does not match selfie verification';
                                }
                            }

                            switch (log[x + 'ApprovalStatus']) {
                                case 'UNCHECKED':
                                    log[x + 'Class'] = 'border-2 border-orange';
                                    break;

                                case 'WALI_APPROVED':
                                case 'AUTO_APPROVED':
                                    log[x + 'Class'] = 'border-2 border-green';
                                    break;

                                case 'WALI_REJECTED':
                                case 'AUTO_REJECTED':
                                    log[x + 'Class'] = 'border-2 border-red';
                                    break;
                            }
                        }
                    });

                    // Title strings
                    log.approvedTitle = parseInt(log.approved) ? 'Approved' : 'Rejected';
                    if (parseInt(log.changedViaWali)) {
                        log.approvedTitle += ' via Wali';
                    }

                    return this.time(log.createdAt, 'DD/MM/YYYY');
                });
            },

            async loadLogs() {
                if (this.loading) {
                    return;
                }

                this.loading = true;

                try {
                    const response = await dashboardAxios.get(this.endpoint, {
                        params: {
                            segment: this.segment,
                            earliestID: this.earliestID,
                        },
                    });

                    this.data = response.data.result;
                    this.logs.push(...this.data.logs.reverse());

                    this.earliestID = this.data.earliestID;

                    await this.filterLogs();

                    let firstKey = Object.keys(this.filteredLogs)[0] || null;
                    if (firstKey !== null && !this.openedAction) {
                        this.toggleOpen(firstKey);
                    }
                } catch (error) {
                    console.error(error);

                    this.handleError({ error });
                }

                this.loading = false;
            },

            toggleOpen(key) {
                this.openedAction = true;

                if (this.opened[key]) {
                    Vue.set(this.opened, key, false);

                    return;
                }

                Vue.set(this.opened, key, true);
            },

            reload() {
                Object.assign(this.$data, initialState());

                this.loadLogs();
            },
        },
    };
</script>

<style>
    .loading-animation {
        -webkit-animation: flickerAnimation 1s infinite;
        -moz-animation: flickerAnimation 1s infinite;
        -o-animation: flickerAnimation 1s infinite;
        animation: flickerAnimation 1s infinite;
    }

    @keyframes flickerAnimation {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0.2;
        }
        100% {
            opacity: 1;
        }
    }

    @-o-keyframes flickerAnimation {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0.2;
        }
        100% {
            opacity: 1;
        }
    }

    @-moz-keyframes flickerAnimation {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0.2;
        }
        100% {
            opacity: 1;
        }
    }

    @-webkit-keyframes flickerAnimation {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0.2;
        }
        100% {
            opacity: 1;
        }
    }

    .cntr {
        display: inline-block;
    }

    .approval-hist-tab img {
        height: 120px;
        width: 80px;
        object-fit: cover;
    }

    span + span {
        margin-left: 5px;
    }

    span.mch {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .approval-hist-tab svg {
        width: 30px;
    }

    .border-orange {
        border-color: #dd6b20;
    }

    .text-orange {
        color: #dd6b20;
    }

    .tooltip {
        display: block !important;
        z-index: 10000;
        cursor: pointer;
    }
    .tooltip .tooltip-inner {
        background: #fb406c;
        color: #ffffff;
        border-radius: 16px;
        padding: 5px 10px 4px;
    }
    .tooltip .tooltip-arrow {
        width: 0;
        height: 0;
        border-style: solid;
        position: absolute;
        margin: 5px;
        border-color: #fb406c;
        z-index: 1;
    }
    .tooltip[x-placement^='top'] {
        margin-bottom: 5px;
    }
    .tooltip[x-placement^='top'] .tooltip-arrow {
        border-width: 5px 5px 0 5px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        bottom: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
    }
    .tooltip[x-placement^='bottom'] {
        margin-top: 5px;
    }
    .tooltip[x-placement^='bottom'] .tooltip-arrow {
        border-width: 0 5px 5px 5px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-top-color: transparent !important;
        top: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
    }
    .tooltip[x-placement^='right'] {
        margin-left: 5px;
    }
    .tooltip[x-placement^='right'] .tooltip-arrow {
        border-width: 5px 5px 5px 0;
        border-left-color: transparent !important;
        border-top-color: transparent !important;
        border-bottom-color: transparent !important;
        left: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
    }
    .tooltip[x-placement^='left'] {
        margin-right: 5px;
    }
    .tooltip[x-placement^='left'] .tooltip-arrow {
        border-width: 5px 0 5px 5px;
        border-top-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        right: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
    }
    .tooltip[aria-hidden='true'] {
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.15s, visibility 0.15s;
    }
    .tooltip[aria-hidden='false'] {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.15s;
    }

    .custom-icon {
        margin: 2px 1px 1px 2px;
        font-size: 26px;
    }
</style>
