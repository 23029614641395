<template>
    <Modal :click-overlay="toggleModal" size="auto">
        <template #header>
            <div class="hidden md:block">
                <IconButton rounded class="float-right right-0 top-0 absolute mt-2 mr-2" @click="toggleModal">
                    Close
                </IconButton>
            </div>
        </template>

        <template #body>
            <img class="profile-photo rounded-lg m-auto block aspect-ratio mt-8" :src="imageUrl" />
        </template>
    </Modal>
</template>

<script>
    import Modal from './Modal';
    import { dashboardAxios } from '../../../axios.config';
    export default {
        name: 'ImageMediaModal',
        components: { Modal },
        props: {
            imageUrl: {
                required: true,
                type: String,
            },
        },
        methods: {
            toggleModal() {
                this.$emit('toggleModal');
            },
        },
    };
</script>
