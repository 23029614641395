<template>
    <modal size="l" :no-padding="true" :click-overlay="() => $emit('close')">
        <div slot="body">
            <panel :title="panelTitle" :has-margin="false" :custom-classes="['bg-pink-muzmatch', 'text-white']">
                <div v-if="!isLoading" class="p-4">
                    <div class="flex flex-wrap">
                        <div class="w-full">
                            <label for="form-audience-id">ID*</label>
                            <p v-if="audienceDuplicateID" class="text-red">
                                This audience ID is already used by another
                            </p>
                            <Input
                                id="form-audience-id"
                                :default-value="form.id"
                                text="(No spaces allowed) e.g this_is_an_id"
                                @change="handleInputChange('id', $event)"
                            />
                        </div>
                        <div class="w-full">
                            <label for="form-audience-description">Description</label>
                            <Input
                                id="form-audience-description"
                                :default-value="form.description"
                                @change="handleInputChange('description', $event)"
                            />
                        </div>
                    </div>
                    <div>
                        <div class="font-bold mb-4">Only include customers where</div>
                        <div class="mb-4 flex items-center">
                            <div class="font-bold">Only include active Members?</div>
                            <select v-model="activeMembers" class="ml-2 w-16 border-2 border-black rounded">
                                <option :value="true" selected>Yes</option>
                                <option :value="false">No</option>
                                <option :value="null">Both</option>
                            </select>
                        </div>
                        <div :key="rerenderFilters">
                            <div v-for="(box, index) in boxes" :key="index">
                                <div class="flex items-center">
                                    <div class="w-11/12">
                                        <query-box
                                            ref="queryBox"
                                            :box="box"
                                            :options="audienceFields"
                                            @update-audience-amount="updateAudienceAmount(false)"
                                        />
                                    </div>
                                    <div class="w-1/12 px-2">
                                        <icon
                                            v-if="index !== 0"
                                            size="xs"
                                            icon="plus"
                                            class="transform rotate-45 cursor-pointer"
                                            @click="removeBox(index)"
                                        />
                                    </div>
                                </div>
                                <div class="flex">
                                    <div class="w-11/12">
                                        <div v-if="boxes.length !== index + 1" class="w-full text-center my-4">And</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex">
                        <div class="w-11/12">
                            <div class="flex justify-center">
                                <button
                                    class="border border-pink-dark rounded bg-pink-dark text-center text-white text-xs p-2 mt-5"
                                    @click="addBox"
                                >
                                    Add AND constraint
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="text-center">
                    <icon icon="spinner" class="animate-spin text-4xl my-4" />
                </div>
                <div class="p-4 flex items-center">
                    <div class="w-1/2">
                        <div class="text-grey-600 text-sm">Estimated size: {{ formatNumber(audienceAmount) }}</div>
                    </div>
                    <div class="w-1/2">
                        <div class="flex justify-end">
                            <div class="w-1/3 mr-2">
                                <m2-button
                                    :is-disabled="audienceRefreshing"
                                    :custom-classes="['text-xs', 'normal-case', 'rounded']"
                                    @clicked="updateAudienceAmount(true)"
                                >
                                    <span v-if="!audienceRefreshing">Update audience amount</span>
                                    <icon v-else class="animate-spin" icon="spinner" />
                                </m2-button>
                            </div>
                            <div class="w-1/3">
                                <m2-button
                                    :is-disabled="audienceDuplicateID"
                                    :custom-classes="['text-xs', 'normal-case', 'rounded']"
                                    @clicked="save"
                                >
                                    Save
                                </m2-button>
                            </div>
                        </div>
                    </div>
                </div>
            </panel>
        </div>
    </modal>
</template>

<script>
    import { mapActions } from 'vuex';
    import { messengerAxios } from '../../../axios.config';
    import M2Button from '../../components/Buttons/M2Button.vue';
    import Modal from '../../components/Modal/Modal.vue';
    import Panel from '../../components/Panels/Panel.vue';
    import QueryBox from './QueryBox';
    import _ from 'lodash';

    export default {
        components: {
            Modal,
            Panel,
            QueryBox,
            M2Button,
        },
        props: {
            audience: {
                type: Object,
                default: null,
            },
            audienceFields: {
                type: Array,
                required: true,
            },
            updateType: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                form: {
                    id: null,
                    description: null,
                },
                boxes: [],
                isLoading: false,
                audienceDuplicateID: false,
                audienceRefreshing: false,
                audienceAmount: 0,
                activeMembers: true,
                rerenderFilters: 0,
            };
        },
        computed: {
            panelTitle() {
                if (this.updateType === 'NEW') {
                    return 'Create Audience';
                }

                return 'Edit Audience';
            },
        },
        watch: {
            'form.id': _.debounce(function () {
                this.checkAudienceDuplicateID();
            }, 500),
        },
        created() {
            if (!this.audience) {
                this.boxes = [[{ type: null }]];
                return;
            }

            // Active filter is now mandatory, get filter and assign value to mandatory dropdown
            let boxes = JSON.parse(JSON.stringify(this.audience.jsonFilter));
            const activeFilter = boxes.find((box) => box.find((row) => row.type === 'active'));
            if (activeFilter && activeFilter.length > 0) {
                this.activeMembers = activeFilter[0].value;
            }

            // Assign to boxes without the active filter
            this.boxes = boxes.filter((box) => box.find((row) => row.type !== 'active'));
            this.form.description = this.audience.description;

            if (this.updateType === 'EDIT') {
                this.form.id = this.audience.title;
            }
        },
        methods: {
            ...mapActions({
                alert: 'alert',
                handleError: 'handleError',
            }),
            addBox() {
                this.boxes.push([{ type: null }]);
            },
            removeBox(index) {
                let queries = this.buildJsonQuery(true);
                this.boxes = queries.filter((e, i) => i !== index);
                this.rerenderFilters++;
            },
            handleInputChange(type, value) {
                if (type === 'id') {
                    value = value.replace(' ', '_').toLowerCase();
                }
                this.form[type] = value;
            },
            async checkAudienceDuplicateID() {
                this.audienceDuplicateID = false;

                let title = this.form.id.trim();
                if (title === '' || (this.audience !== null && title === this.audience.title)) {
                    return;
                }

                try {
                    const response = await messengerAxios.get(`/audience/duplicate-titles?title=${title}`);
                    if (response.data.result === true) {
                        await this.alert({
                            message: 'This audience ID is already used by another',
                            type: 'danger',
                        });
                        this.audienceDuplicateID = true;
                    }
                } catch (error) {
                    await this.handleError({ error });
                }
            },
            buildJsonQuery(draft = false) {
                try {
                    const query = this.$refs.queryBox
                        .map((box, i) => {
                            return box.$refs.queryRows
                                .filter((row) => {
                                    if (draft) return true;

                                    if (
                                        (!row.queryData.type || !row.queryData.value || !row.queryData.comparison) &&
                                        row.queryData.type !== 'muzmatch_email'
                                    )
                                        return false;

                                    if (!['in', 'not in'].includes(row.queryData.comparison)) return true;

                                    return row.queryData.value.length !== 0;
                                })
                                .map((row) => row.queryData);
                        })
                        .filter((i) => i.length > 0);

                    if (draft) return query;

                    return [...query, [{ comparison: '=', value: this.activeMembers, type: 'active' }]];
                } catch (error) {
                    return [];
                }
            },
            async updateAudienceAmount(manualClick) {
                this.audienceRefreshing = true;
                const queries = this.buildJsonQuery();

                if (!queries) {
                    if (manualClick) {
                        await this.alert({
                            message: 'Cannot find audience amount with an unfinished or empty query',
                            type: 'danger',
                        });
                    }
                    this.audienceRefreshing = false;
                    return;
                }

                try {
                    const response = await messengerAxios.post(`/audience/amounts`, { jsonFilter: queries });
                    this.audienceAmount = response.data.result.amount;
                } catch (error) {
                    await this.handleError({ error });
                }

                this.audienceRefreshing = false;
            },
            async save() {
                if (!this.form.id) {
                    this.alert({
                        message: 'Audience ID is required',
                        type: 'danger',
                    });
                    return;
                }

                const queries = this.buildJsonQuery();

                if (!queries) {
                    this.alert({
                        message: 'Cannot create audience with an unfinished or empty query',
                        type: 'danger',
                    });
                    return;
                }

                const data = {
                    jsonFilter: queries,
                    title: this.form.id,
                    description: this.form.description,
                };

                this.isLoading = true;

                try {
                    let response = null;
                    if (this.audience && this.audience.id) {
                        response = await messengerAxios.put(`/audience/${this.audience.id}`, data);
                        await this.alert({ message: 'The audience was successfully updated', type: 'success' });
                    } else {
                        response = await messengerAxios.post(`/audiences`, data);
                        //await this.alert({ message: 'The audience was successfully created', type: 'success' });
                    }

                    this.$emit('update-audiences', response.data.result);
                    this.$emit('close');
                } catch (error) {
                    await this.handleError({ error });
                }

                this.isLoading = false;
            },
            formatNumber(number) {
                return new Intl.NumberFormat('en-GB').format(number);
            },
        },
    };
</script>
