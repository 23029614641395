<template>
    <div class="rounded p-2" :class="{ 'bg-purple-muzmatch': !isReplyContext }">
        <h2 v-if="!isReplyContext" class="text-grey text-center font-bold uppercase">Icebreaker</h2>
        <p v-if="instantChat.questionValue" class="mt-1 text-white text-center w-3/4 m-auto font-semibold">
            {{ instantChat.questionValue }}
        </p>

        <div
            v-if="'requester' in instantChat && 'contentValue' in instantChat.requester"
            class="mt-3"
            :class="requesterDirection"
        >
            <div class="p-3 inline-block bg-black rounded" :class="[{ 'w-1/3': !isReplyContext }, requesterDirection]">
                <p v-if="instantChat.requester.contentType === 'text'" class="text-white">
                    {{ instantChat.requester.contentValue }}
                </p>
                <template v-else>
                    <audio
                        v-for="(media, mediaIndex) in instantChat.requester.media"
                        :key="mediaIndex"
                        controls
                        class="focus:outline-none max-w-full"
                    >
                        <source :src="media.url" />
                    </audio>
                </template>
            </div>
            <p class="mt-1 text-white text-xs font-semibold" :class="requesterDirection">
                {{ instantChat.requester.contentTimestamp | time }}
            </p>
        </div>

        <div
            v-if="'receiver' in instantChat && 'contentValue' in instantChat.receiver"
            class="mt-3"
            :class="receiverDirection"
        >
            <div class="p-3 inline-block bg-white rounded" :class="[{ 'w-1/3': !isReplyContext }, receiverDirection]">
                <p v-if="instantChat.receiver.contentType === 'text'" class="text-black">
                    {{ instantChat.receiver.contentValue }}
                </p>
                <template v-else>
                    <audio
                        v-for="(media, mediaIndex) in instantChat.receiver.media"
                        :key="mediaIndex"
                        controls
                        class="focus:outline-none max-w-full"
                    >
                        <source :src="media.url" />
                    </audio>
                </template>
            </div>
            <p
                v-if="contentTimestamp in instantChat.receiver"
                class="mt-1 text-white text-xs font-semibold"
                :class="receiverDirection"
            >
                {{ instantChat.receiver.contentTimestamp | time }}
            </p>
        </div>
    </div>
</template>

<script>
    import MediaItem from './MediaItem';

    export default {
        name: 'InstantChatItem',

        components: {
            MediaItem,
        },

        filters: {
            time(timestamp) {
                return moment(timestamp).format('DD/MM/YYYY HH:mm:ss');
            },
        },

        props: {
            sender: {
                required: true,
                type: String,
            },
            instantChat: {
                required: true,
                type: Object,
            },
            member: {
                required: true,
                type: Object,
            },
            isReplyContext: {
                default: false,
                type: Boolean,
            },
        },

        computed: {
            requesterDirection() {
                if (this.member.id === this.sender) {
                    return 'text-right';
                }

                return 'text-left';
            },
            receiverDirection() {
                if (this.member.id === this.sender) {
                    return 'text-left';
                }

                return 'text-right';
            },
        },
    };
</script>

<style scoped></style>
