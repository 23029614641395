<template>
    <div>
        <div v-if="!loading && boosts !== null">
            <table class="w-full text-left table-fixed mt-4">
                <thead>
                    <tr class="bg-grey-200">
                        <th class="px-4 py-2">Active</th>
                        <th class="px-4 py-2">Start</th>
                        <th class="px-4 py-2">End</th>
                        <th class="px-4 py-2">Was Free</th>
                        <th class="px-4 py-2">Views</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="border hover:bg-grey-200">
                        <td colspan="5" class="px-4 py-4">Next free boost: {{ timeTillNextFree }}</td>
                    </tr>
                    <tr v-for="boost in boosts" :key="boost.id" class="border hover:bg-grey-200">
                        <td class="px-4 py-2">
                            <icon v-if="boost.isActive" icon="check-square" class="text-pink-muzmatch h-10" size="lg" />
                            <icon v-else icon="times" class="text-pink-muzmatch h-10 pl-1" size="lg" />
                        </td>
                        <td class="px-4 py-2">
                            <p>{{ boost.startedAt }}</p>
                        </td>
                        <td class="px-4 py-2">
                            <p>{{ boost.endedAt }}</p>
                        </td>
                        <td class="px-4 py-2">
                            <icon v-if="boost.wasFree" icon="check-square" class="text-pink-muzmatch h-10" size="lg" />
                            <icon v-else icon="times" class="text-pink-muzmatch h-10 pl-1" size="lg" />
                        </td>
                        <td class="px-4 py-2">
                            <p>{{ boost.totalViews }}</p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div v-else class="flex justify-center">
            <span v-if="!loading" class="font-bold my-4">No Boosts to show.</span>
            <span v-else class="font-bold my-4">Loading...</span>
        </div>
    </div>
</template>

<script>
    import { dashboardAxios } from '../../../../axios.config';
    import { mapActions } from 'vuex';

    export default {
        props: {
            profile: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                search: '',
                boosts: null,
                nextFree: null,
                loading: false,
            };
        },
        computed: {
            timeTillNextFree() {
                if (!this.profile) {
                    return 'Unknown';
                }

                if (this.profile.premium !== '1') {
                    return 'Member is not gold (no free boosts)';
                }

                if (this.nextFree.cronIssue === true) {
                    return 'Never, there is a cron issue with giving weekly free boost, create FS Muzz Request!';
                }

                return this.nextFree.timeTill === null
                    ? 'available now ✅'
                    : `${this.nextFree.timeTill} boost is available (${this.nextFree.dateTime})`;
            },
        },
        created() {
            this.loadBoosts();
        },
        methods: {
            ...mapActions({
                handleError: 'handleError',
            }),

            async loadBoosts() {
                if (this.loading) {
                    return;
                }

                this.loading = true;

                try {
                    let response = await dashboardAxios.get(`/v1/boosts/${this.profile.memberID}`);

                    this.boosts = response.data.result.boosts;
                    this.nextFree = response.data.result.nextFree;
                } catch (error) {
                    this.handleError({ error });
                }

                this.loading = false;
            },
        },
    };
</script>
