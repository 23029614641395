<template>
    <div>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'Card',
    };
</script>
