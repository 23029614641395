<template>
    <modal size="sm" :click-overlay="closeModal" :no-padding="true">
        <div slot="body">
            <panel title="Select URL Target" :has-margin="false" :custom-classes="['bg-pink-muzmatch', 'text-white']">
                <div class="p-4">
                    <div class="flex flex-wrap">
                        <div class="w-full">
                            <label>URL Target*</label>
                            <dropdown
                                :v-model="selectedUrlTarget"
                                :default="selectedUrlTarget"
                                :is-capitalize="false"
                                placeholder="Select URL Target"
                                :options="urlTargetList"
                                @updated="form.urlTarget = $event"
                            />
                        </div>
                    </div>
                    <div class="flex space-x-12 mt-4">
                        <div class="w-1/2"></div>
                        <div class="w-1/2">
                            <m2-button :custom-classes="['text-xs', 'normal-case', 'rounded']" @clicked="submit">
                                Add URL Target
                            </m2-button>
                        </div>
                    </div>
                </div>
            </panel>
        </div>
    </modal>
</template>

<script>
    import Modal from '../../components/Modal/Modal.vue';
    import Panel from '../../components/Panels/Panel';
    import Dropdown from '../../components/Dropdown/Dropdown';
    import M2Button from '../../components/Buttons/M2Button';
    import { mapActions } from 'vuex';

    export default {
        name: 'PreviewAddUrlTargetModal',
        components: {
            Modal,
            Panel,
            Dropdown,
            M2Button,
        },
        props: {
            selectedUrlTarget: {
                type: Number,
                default: 0,
            },
            urlTargetList: {
                type: Array,
                default: () => [],
            },
        },
        data() {
            return {
                form: {
                    urlTarget: null,
                },
            };
        },
        mounted() {
            this.form.urlTarget = this.selectedUrlTarget;
        },
        methods: {
            ...mapActions(['alert']),

            submit() {
                if (this.form.urlTarget !== null) {
                    this.$emit('submit', this.form);
                    return;
                }
                this.alert({ message: 'Missing required fields', type: 'danger' });
            },

            closeModal() {
                this.$emit('hide');
            },
        },
    };
</script>

<style scoped></style>
