<template>
    <div>
        <RadioButton
            v-for="(button, index) in buttons"
            :key="index"
            class="mr-1 mb-1"
            :text="button.text"
            :clicked="button === selectedButton"
            @click.native="selectButton(button)"
        ></RadioButton>
    </div>
</template>

<script>
    import RadioButton from '@/components/Buttons/RadioButton';

    export default {
        name: 'RadioButtons',

        components: { RadioButton },

        props: {
            buttons: {
                type: Array,
                required: true,
                validator: function (buttons) {
                    return _.every(buttons, (button) => button.text);
                },
            },
        },

        data() {
            return {
                selectedButton: null,
            };
        },

        methods: {
            selectButton(button) {
                this.selectedButton = button;
                this.$emit('selectButton', button);
            },
        },
    };
</script>
