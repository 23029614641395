<template>
    <div>
        <input
            v-model="search"
            type="text"
            placeholder="Search..."
            style="top: 1rem; right: 1rem"
            class="w-1/4 px-3 py-2 absolute border rounded"
        />

        <div v-if="filteredReports && filteredReports.length && !loading">
            <table class="w-full text-left table-fixed mt-12 overflow-scroll block">
                <thead>
                    <tr class="bg-grey-100 text-sm text-grey-700 uppercase">
                        <th class="px-4 py-2 w-1/6">Type</th>
                        <th class="px-4 py-2 w-1/6">Duplicate Account</th>
                        <th class="px-4 py-2 w-1/6">Reporter</th>
                        <th class="px-4 py-2 w-1/12">Source</th>
                        <th class="px-4 py-2 w-1/6">Attachment</th>
                        <th class="px-4 py-2 w-2/6">Value</th>
                        <th class="px-4 py-2 w-1/6">Blocked</th>
                        <th class="px-4 py-2 w-1/6">Actioned</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(report, idx) in filteredReports" :key="idx" class="border hover:bg-grey-200">
                        <td class="px-4 py-2 break-words">
                            <p class="font-mono font-bold">{{ report.issue_type }}</p>
                            <p class="mt-1">{{ time(report.timestamp) }}</p>
                        </td>
                        <td class="px-4 py-2 break-words">
                            <p v-if="report.member.id != profile.hashMemberID" class="font-mono font-bold">
                                {{ report.member.full_name }}
                                <router-link target="_blank" :to="getMemberRoute(report.member)">
                                    <icon icon="link"></icon>
                                </router-link>
                            </p>
                        </td>

                        <td class="flex justify-between items-center">
                            <span class="px-4 py-2 font-bold break-words notranslate">
                                <p class="no-underline text-pink-muzmatch font-bold">
                                    <template v-if="report.source === 'AUTOMATED_REPORT'">Muzz</template>
                                    <router-link v-else target="_blank" :to="getMemberRoute(report.reporting_member)">
                                        {{ report.reporting_member.full_name }}
                                    </router-link>
                                </p>
                            </span>

                            <div>
                                <span
                                    v-tooltip="
                                        report.source === 'AUTOMATED_REPORT' ? report.reporting_member.full_name : ''
                                    "
                                    class="px-2 py-1 text-sm bg-grey-300 hover:bg-grey-100 cursor-pointer rounded"
                                    @click="openChat(report)"
                                >
                                    Chat
                                </span>
                            </div>
                        </td>

                        <td class="px-4 py-2 break-words">
                            <p
                                class="font-mono font-bold ml-2"
                                :class="{ 'text-orange-400': report.source === 'AUTOMATED_REPORT' }"
                            >
                                {{ report.source }}
                            </p>
                        </td>

                        <td class="px-4 py-2 break-words text-center">
                            <AttachmentModal
                                v-if="report.attachment"
                                :thumb="formatAttachmentUrl(report.attachment)"
                                :urls="[formatAttachmentUrl(report.attachment)]"
                                thumb-classes="h-12 w-12 border"
                                custom-style="object-fit: cover;"
                            />
                        </td>

                        <td class="px-4 py-2 break-words">{{ report.admin_message || report.message }}</td>

                        <td class="px-4 py-2 break-words text-center">
                            <icon v-if="report.reporting_member_blocked" icon="check"></icon>
                        </td>

                        <td class="px-4 py-2 break-words text-center">
                            <icon v-if="report.actioned" icon="check"></icon>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div v-else class="flex justify-center items-center font-bold">
            <div class="my-4">
                <span v-if="!loading">No Reports Received</span>
                <span v-else>Loading...</span>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import { dashboardAxios } from '../../../../axios.config';
    import { getMemberRoute } from '../../../helpers/RedirectToMember';
    import EventBus from '../../../helpers/eventBus';
    import AttachmentModal from '../../Attachments/AttachmentModalV2';
    import VTooltip from 'v-tooltip';
    import Vue from 'vue';

    Vue.use(VTooltip);

    export default {
        name: 'ReportsReceivedTab',

        components: {
            AttachmentModal,
        },

        props: {
            profile: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                search: '',
                reports: [],
                loading: false,
            };
        },

        computed: {
            filteredReports() {
                if (!this.search) {
                    return this.reports;
                }

                return _.filter(this.reports, (report) => {
                    return JSON.stringify(report).toLowerCase().includes(this.search.toLowerCase());
                });
            },
        },
        mounted() {
            this.loadReports();

            EventBus.$on('refresh-received-issues', this.loadReports);
        },

        destroyed() {
            EventBus.$off('refresh-received-issues', this.loadReports);
        },
        methods: {
            ...mapActions({
                handleError: 'handleError',
            }),

            time(time, format = 'DD/MM/YYYY HH:mm') {
                return moment(time).format(format);
            },

            formatAttachmentUrl(value) {
                return value.replace('s3', 'https');
            },

            openChat(report) {
                EventBus.$emit('loadChat', report.reporting_member.id);
            },

            async loadReports() {
                if (this.loading) {
                    return;
                }

                this.loading = true;

                try {
                    let response = await dashboardAxios.get(
                        '/v1/members/' + this.profile.hashMemberID + '/reports-received',
                    );

                    this.reports = response.data.result;
                } catch (error) {
                    console.error(error);

                    this.handleError({ error });
                }

                this.loading = false;
            },

            getMemberRoute(member) {
                return getMemberRoute({ nickname: member.full_name, hashMemberID: member.id });
            },
        },
    };
</script>
