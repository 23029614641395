<template>
    <div>
        <div class="mb-4">
            <input v-model="obscenity_limit" type="number" class="p-2 shadow rounded" step="0.01" />
        </div>

        <div v-for="(photos, idx) in chunkedMedia" :key="idx" class="flex -mx-2 mb-4">
            <div v-for="photo in photos" :id="photo.id" :key="photo.id" class="p-2 shadow-md rounded w-1/6 mx-2">
                <div class="flex flex-wrap items-center justify-between">
                    <div class="flex items-center">
                        <div>Member</div>
                        <router-link
                            :to="{ name: 'members', query: { memberId: photo.memberID } }"
                            class="hover:text-grey-600 text-grey-800 cursor-pointer p-2"
                            target="_blank"
                        >
                            <icon icon="link"></icon>
                        </router-link>
                    </div>
                    <div>{{ photo.created_at }}</div>
                </div>
                <div
                    class="py-2 mb-2 font-semibold pl-2 text-black"
                    :class="getClasses(photo)"
                    :title="photo.profileState"
                >
                    {{ getProfileState(photo) }}
                </div>
                <span>
                    <span class="font-mono">{{ photo.obscenity_classifier }}</span>
                    {{ photo.obscenity_score }}
                </span>
                <div class="flex flex-wrap items-center justify-between">
                    <div>
                        <span class="font-mono">Channel:</span>
                        {{ capitalize(photo.channel) }}
                    </div>
                </div>

                <img
                    :src="photo.urls.file"
                    :width="photo.blur_hash_width"
                    :height="photo.blur_hash_height"
                    class="w-full mt-2"
                />

                <obscene-output :obscenity-output="photo.obscenity_output" :obscenity-limit="obscenityLimit" />
            </div>
        </div>
        <div v-if="chunkedMedia.length > 0" class="max-w-xs mx-auto my-8">
            <M2Button v-show="!isLoading" @clicked="loadMore">Load More</M2Button>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import { dashboardAxios } from '../../../axios.config';
    import ObsceneOutput from './ObsceneOutput';
    import M2Button from '../../components/Buttons/M2Button';
    import _ from 'lodash';
    export default {
        name: 'Obscene',
        components: {
            ObsceneOutput,
            M2Button,
        },
        data() {
            return {
                media: [],
                obscenity_limit: 0.5,
                revealedPhotos: [],
                page: 0,
                isLoading: false,
            };
        },

        computed: {
            chunkedMedia() {
                return _.chunk(this.media, 6);
            },

            obscenityLimit() {
                return parseInt(this.obscenity_limit, 10);
            },
        },

        async mounted() {
            let response = await this.$swal.fire({
                title: 'This page contains obscene images. Do you want to proceed?',
                icon: 'warning',
                confirmButtonText: 'Yes',
                allowOutsideClick: false,
                showCancelButton: true,
                cancelButtonText: 'No',
            });

            if (response.dismiss) {
                window.location.href = '/';
            }

            if (response.value) {
                this.getMedia();
            }
        },

        methods: {
            ...mapActions({ handleError: 'handleError' }),

            shouldShow(photo) {
                return !!_.find(this.revealedPhotos, { id: photo.id });
            },

            async getMedia() {
                try {
                    this.isLoading = true;

                    let response = await dashboardAxios.get('/v1/media/obscene-photos', {
                        params: {
                            page: this.page,
                        },
                    });

                    const lastMedia = this.media.length === 0 ? null : this.media[this.media.length - 1];

                    this.media.push(...response.data.result);
                    this.isLoading = false;

                    if (lastMedia) {
                        this.$nextTick(() => {
                            document.getElementById(lastMedia.id).scrollIntoView();
                        });
                    }
                } catch (error) {
                    this.handleError({ error });
                }
            },

            loadMore() {
                this.page += 1;
                this.getMedia();
            },

            getProfileState(photo) {
                return photo?.profileState?.split(':')?.[0]?.trim();
            },

            getClasses(photo) {
                switch (this.getProfileState(photo)) {
                    case 'APPROVED':
                        return ['bg-green'];
                    case 'PERMANENTLY BLOCKED':
                    case 'TEMPORARILY BLOCKED':
                        return ['bg-red'];
                    default:
                        return ['bg-yellow'];
                }
            },

            capitalize(word) {
                return _.capitalize(word);
            },
        },
    };
</script>
