<template>
    <div>
        <div class="md:flex md:flex-row md:-mx-2 -mb-4">
            <div class="md:mx-2 md:w-1/4 w-full">
                <SearchPanel />
            </div>

            <div class="md:mx-2 md:w-3/4 w-full px-2 overflow-y-scroll py-1 list-height">
                <div v-if="!loading" class="h-full">
                    <div v-if="members.length > 0">
                        <MemberSlimCard v-for="member in members" :key="member.memberID" :member="member" />
                    </div>

                    <div v-else class="flex justify-center h-full">
                        <div class="flex flex-col items-center mt-20">
                            <icon icon="search" class="text-5xl mb-2 text-pink-muzmatch"></icon>

                            <span class="text-lg mt-2 font-bold">Search something on the left to show results.</span>

                            <span v-if="canViewEmails && emailsRoute" class="mt-8 text-lg font-bold">
                                If you're searching for an email
                                <router-link :to="emailsRoute">click here</router-link>
                                to search the inbox
                            </span>
                        </div>
                    </div>
                </div>

                <div v-else class="flex justify-center h-full">
                    <div class="flex flex-col items-center mt-20">
                        <icon icon="search" class="loading-animation text-5xl mb-2 text-pink-muzmatch"></icon>

                        <span class="text-lg mt-2 font-bold">Loading...</span>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="meta && meta.total > 25" class="text-right py-4 mt-2">
            <span class="mr-2 text-grey-800">
                {{ meta.total }} results. Page {{ meta.page }} of {{ meta.lastPage }}
            </span>

            <button
                class="p-2 rounded-l hover:bg-grey-300 focus:outline-none"
                :class="loading ? 'bg-grey-100' : 'bg-grey-200'"
                @click="previous()"
            >
                Previous
            </button>

            <button
                class="p-2 rounded-r hover:bg-grey-300 focus:outline-none"
                :class="loading ? 'bg-grey-100' : 'bg-grey-200'"
                @click="next()"
            >
                Next
            </button>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import SearchPanel from './SearchPanel';
    import EventBus from '../../helpers/eventBus';
    import { dashboardAxios } from '../../../axios.config';
    import { getMemberRoute } from '../../helpers/RedirectToMember';
    import MemberSlimCard from '../../components/Cards/MemberSlimCard';
    import { User } from '../../classes/User';

    export default {
        name: 'List',

        components: {
            SearchPanel,
            MemberSlimCard,
        },

        data() {
            return {
                currentPage: 1,

                meta: null,
                members: [],

                loading: false,
            };
        },

        computed: {
            ...mapGetters({
                staff: 'staff/getStaff',
            }),
            canViewEmails() {
                return User.make(this.staff).can('email-list:admin');
            },
            emailsRoute() {
                if (!this.$route.query.emailAddress) return null;

                return {
                    name: 'email-list',
                    query: {
                        query: this.$route.query.emailAddress,
                    },
                };
            },
        },
        watch: {
            '$route.query'(value) {
                this.currentPage = 1;
                this.loadMembers(_.clone(value));
            },

            currentPage() {
                this.loadMembers(_.clone(this.$route.query));
            },
        },

        async mounted() {
            if (!this.canSearch()) {
                let permission = this.staff.roles[0].productName;
                await this.$router.push({ path: `/${permission}` });
            }

            this.loadMembers(_.clone(this.$route.query));

            EventBus.$on('searched', this.handleSearchEvent);
        },

        destroyed() {
            EventBus.$off('searched', this.handleSearchEvent);
        },
        methods: {
            ...mapActions({
                handleError: 'handleError',
            }),

            canSearch() {
                return User.make(this.staff).can('members:admin');
            },

            async searchMembers() {
                if (this.loading) {
                    return;
                }

                this.loading = true;

                setTimeout(() => (this.loading = false), 250);
            },

            async loadMembers(params = {}) {
                if (this.loading) {
                    return;
                }

                params['page'] = this.currentPage;

                if (
                    !params.nickname &&
                    !params.phoneNumber &&
                    !params.memberId &&
                    !params.memberHash &&
                    !params.emailAddress &&
                    !params.age &&
                    !params.profession &&
                    !params.ethnicity
                ) {
                    this.members = [];
                    this.meta = null;

                    return;
                }

                this.loading = true;

                try {
                    let response = await dashboardAxios.get('/v1/members', { params });

                    this.members = response.data.result.data;
                    this.meta = response.data.result.meta;

                    if (this.members.length === 1) {
                        await this.$router.push(getMemberRoute(this.members[0]));
                    }

                    this.loading = false;
                } catch (e) {
                    this.handleError({ error: e }, () => (this.loading = false));
                }
            },

            async previous() {
                if (this.loading) {
                    return;
                }

                if (this.currentPage - 1 < 1) {
                    return;
                }

                this.currentPage = this.currentPage - 1;
            },

            async next() {
                if (this.loading) {
                    return;
                }

                if (this.currentPage + 1 > this.meta.lastPage) {
                    return;
                }

                this.currentPage = this.currentPage + 1;
            },

            handleSearchEvent() {
                this.loadMembers(_.clone(this.$route.query));
            },
        },
    };
</script>

<style scoped>
    .list-height {
        height: calc(100vh - 125px);
    }

    .loading-animation {
        -webkit-animation: flickerAnimation 1s infinite;
        -moz-animation: flickerAnimation 1s infinite;
        -o-animation: flickerAnimation 1s infinite;
        animation: flickerAnimation 1s infinite;
    }

    @keyframes flickerAnimation {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0.2;
        }
        100% {
            opacity: 1;
        }
    }

    @-o-keyframes flickerAnimation {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0.2;
        }
        100% {
            opacity: 1;
        }
    }

    @-moz-keyframes flickerAnimation {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0.2;
        }
        100% {
            opacity: 1;
        }
    }

    @-webkit-keyframes flickerAnimation {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0.2;
        }
        100% {
            opacity: 1;
        }
    }
</style>
