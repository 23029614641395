<template>
    <button
        class="border border-pink-dark rounded-xs bg-pink-dark text-center text-white w-full p-4 uppercase"
        :class="{ 'cursor-not-allowed': isDisabled, 'bg-grey-muzmatch': isDisabled, ...customClassesAsString }"
        :disabled="isDisabled"
        @click="$emit('clicked')"
    >
        <slot></slot>
    </button>
</template>

<script>
    export default {
        props: {
            isDisabled: {
                type: Boolean,
                default: false,
            },
            customClasses: {
                type: Array,
                default: () => [],
            },
        },
        computed: {
            customClassesAsString() {
                return this.customClasses.reduce((a, v) => ({ ...a, [v]: true }), {});
            },
        },
    };
</script>
