<template>
    <div class="mt-4">
        <DashboardLabel>Actions</DashboardLabel>

        <div
            v-if="!['approved', 'resubmit'].includes(member.verifiedStatus)"
            class="mt-2 flex flex-row items-end flex-wrap"
        >
            <div class="flex flex-col mr-2">
                <label class="my-1">
                    Date of Birth
                    <span class="font-bold" :class="calculateAge < 18 ? 'text-red-dark' : 'text-green-dark'">
                        ({{ calculateAge }})
                    </span>
                </label>
                <div class="flex flex-row flex-wrap">
                    <input
                        v-model="updateDOB.day"
                        type="number"
                        class="mr-1 p-2 text-center border border-grey-300 rounded w-12 sm:w-16"
                    />
                    <input
                        v-model="updateDOB.month"
                        type="number"
                        class="mr-1 p-2 text-center border border-grey-300 rounded w-12 sm:w-16"
                    />
                    <input
                        v-model="updateDOB.year"
                        type="number"
                        class="p-2 mr-2 text-center border border-grey-300 rounded w-20"
                    />

                    <SecondaryButton
                        class="mr-1 my-1"
                        :class="{ 'animate-pulse': !memberAgeMatchesDocument }"
                        text="Update DOB"
                        :handle-error="handleButtonError"
                        :handle-click="updateBirthday"
                    />
                </div>
            </div>

            <div class="flex flex-col mr-2">
                <SecondaryButton
                    class="mr-1 my-1"
                    text="Request Resubmit"
                    :handle-error="handleButtonError"
                    :handle-click="requestResubmit"
                />
            </div>

            <div class="flex flex-col">
                <PrimaryButton
                    class="mr-1 my-1"
                    text="Approve ID Verification"
                    :confirmed="!memberAgeMatchesDocument"
                    confirm-message="Date of Birth from documents does not match against member"
                    :handle-error="handleButtonError"
                    :handle-click="approveMember"
                />
            </div>
        </div>
        <p v-if="member.verifiedStatus === 'approved'" class="mt-2 text-green-dark font-bold">
            Member is currently ID Verified
        </p>
        <p v-else-if="member.verifiedStatus === 'resubmit'" class="mt-2 text-orange-500 font-bold">
            Member has been requested to resubmit ID Verification
        </p>
        <template v-else>
            <template v-if="documentDateOfBirth !== ''">
                <p v-if="memberAgeMatchesDocument" class="mt-2 text-green-dark font-bold">
                    Member current Date of Birth matches documentation
                </p>
                <p v-else class="mt-2 text-red-dark font-bold">
                    Member age does not match documents, update and check document suggestion before you approve
                </p>
            </template>
            <p v-else class="mt-2 text-red-dark font-bold">Could not automatically find Date of Birth from documents</p>
        </template>
    </div>
</template>

<script>
    import moment from 'moment';
    import SecondaryButton from '@/components/Buttons/SecondaryButton';
    import { mapActions } from 'vuex';
    import { dashboardAxios } from '../../../../axios.config';
    import PrimaryButton from '@/components/Buttons/PrimaryButton';
    import DropdownButton from '@/components/Buttons/Dropdown/DropdownButton';
    import DropdownItem from '@/components/Buttons/Dropdown/DropdownItem';
    import DashboardLabel from '@/components/Labels/DashboardLabel';

    export default {
        name: 'IdentityVerificationAction',
        components: {
            PrimaryButton,
            SecondaryButton,
            DropdownButton,
            DropdownItem,
            DashboardLabel,
        },
        props: {
            task: {
                type: Object,
                required: true,
            },
            member: {
                type: Object,
                required: true,
            },
            documentDateOfBirth: {
                type: String,
                default: '',
            },
        },
        data() {
            return {
                amendedDOB: false,
                updateDOB: {
                    day: '',
                    month: '',
                    year: '',
                },
                documentDOB: {
                    day: '',
                    month: '',
                    year: '',
                },
            };
        },

        computed: {
            calculateAge() {
                if (!(this.updateDOB.day && this.updateDOB.month && this.updateDOB.year)) {
                    return '';
                }

                let month = this.updateDOB.month;
                if (month.length === 1) {
                    month = '0' + month;
                }

                let day = this.updateDOB.day;
                if (day.length === 1) {
                    day = '0' + day;
                }

                let birthday = moment(this.updateDOB.year + '-' + month + '-' + day);

                return moment().diff(birthday, 'year') || 'Invalid Date';
            },

            memberAgeMatchesDocument() {
                if (this.member.dobDAY !== this.documentDOB.day) {
                    return false;
                }

                if (this.member.dobMONTH !== this.documentDOB.month) {
                    return false;
                }

                return this.member.dobYEAR === this.documentDOB.year;
            },
        },

        mounted() {
            if (this.documentDateOfBirth !== '') {
                let datetime = moment(this.documentDateOfBirth);
                this.documentDOB.day = datetime.format('D');
                this.documentDOB.month = datetime.format('M');
                this.documentDOB.year = datetime.format('YYYY');
            } else {
                this.documentDOB.day = this.member.dobDAY;
                this.documentDOB.month = this.member.dobMONTH;
                this.documentDOB.year = this.member.dobYEAR;
            }

            this.updateDOB.day = this.documentDOB.day;
            this.updateDOB.month = this.documentDOB.month;
            this.updateDOB.year = this.documentDOB.year;
        },

        methods: {
            ...mapActions({
                handleError: 'handleError',
                alert: 'alert',
                confirmDelete: 'confirmDelete',
            }),

            async handleButtonError(error) {
                this.handleError({ error });
            },

            async updateBirthday() {
                try {
                    if (this.calculateAge < 18) {
                        await this.alert({ message: `Member can't be less than 18 years old`, type: 'danger' });
                        return;
                    }

                    const confirmTitle = 'Are you sure you want to update Date of Birth?';
                    const confirmBody = 'This will send an in-app message saying we updated because of ID Verification';
                    if (!(await this.confirmDelete({ vm: this, text: confirmTitle, body: confirmBody }))) return;

                    const payload = {
                        memberID: this.task.memberID,
                        dobDAY: this.updateDOB.day,
                        dobMONTH: this.updateDOB.month,
                        dobYEAR: this.updateDOB.year,
                    };
                    const response = await dashboardAxios.post(
                        `/v1/tasks/identity-verification/${this.task.id}/member-dob-update`,
                        payload,
                    );

                    if (response.data.result.status === 'success') {
                        this.amendedDOB = true;
                        this.$emit('updated-member', response.data.result.member);
                        await this.alert({ message: response.data.result.message, type: 'success' });
                        return;
                    }

                    await this.alert({ message: response.data.result.message, type: 'danger' });
                } catch (e) {
                    await this.handleError({ error: e });
                }
            },

            async requestResubmit() {
                try {
                    const confirmTitle = 'Are you sure you want to request user to resubmit?';
                    const confirmBody = 'This will send an in-app and email message';
                    if (!(await this.confirmDelete({ vm: this, text: confirmTitle, body: confirmBody }))) return;

                    const payload = { memberID: this.task.memberID };
                    const response = await dashboardAxios.post(
                        `/v1/tasks/identity-verification/${this.task.id}/request-resubmit`,
                        payload,
                    );

                    if (response.data.result.status === 'success') {
                        this.$emit('updated-member', response.data.result.member);
                        await this.alert({ message: response.data.result.message, type: 'success' });
                        return;
                    }

                    await this.alert({ message: response.data.result.message, type: 'danger' });
                } catch (e) {
                    await this.handleError({ error: e });
                }
            },

            async approveMember() {
                try {
                    const confirmTitle = 'Are you sure you want to set verified status to approved?';
                    const confirmBody = 'This will send an in-app and email message';
                    if (!(await this.confirmDelete({ vm: this, text: confirmTitle, body: confirmBody }))) return;

                    const payload = {
                        memberID: this.task.memberID,
                        amendedDOB: this.amendedDOB,
                    };
                    const response = await dashboardAxios.post(
                        `/v1/tasks/identity-verification/${this.task.id}/approve`,
                        payload,
                    );

                    if (response.data.result.status === 'success') {
                        this.$emit('updated-member', response.data.result.member);
                        await this.alert({ message: response.data.result.message, type: 'success' });
                        return;
                    }

                    await this.alert({ message: response.data.result.message, type: 'danger' });
                } catch (e) {
                    await this.handleError({ error: e });
                }
            },
        },
    };
</script>

<style scoped></style>
