<template>
    <div>
        <DashboardLabel class="flex my-3">Swipe and Interstitials</DashboardLabel>

        <div class="flex flex-row items-end flex-wrap">
            <div class="flex flex-col mr-2">
                <label class="my-1">Swipe allocation used</label>
                <input
                    v-model="swipeAllocationUsed"
                    type="number"
                    min="0"
                    placeholder="Enter number to update"
                    class="p-2 border border-grey-300 rounded sm:w-64"
                />
            </div>

            <SecondaryButton
                text="Update"
                class="mt-2 mr-2"
                :disabled="swipeAllocationUsed === null"
                :confirmed="true"
                confirm-message="This will override the swipe allocation used for this member"
                :handle-error="handleButtonError"
                :handle-click="
                    updateDebug('/v1/debug-option/swipe-allocation-used-override', {
                        swipeAllocationUsed: this.swipeAllocationUsed,
                    })
                "
            />

            <DropdownButton class="mt-2 mr-2" text="Retention mode update">
                <DropdownItem
                    v-for="retentionMode in ['FRIENDLY', 'NORMAL', 'RETURNING']"
                    :key="retentionMode"
                    :confirmed="true"
                    confirm-message="This will be temporary and could update automatically the next day"
                    :handle-error="handleButtonError"
                    :handle-click="updateDebug('/v1/debug-option/retention-mode', { retentionMode: retentionMode })"
                >
                    {{ retentionMode }}
                </DropdownItem>
            </DropdownButton>

            <SecondaryButton
                text="Reset or unban swiping"
                class="mt-2"
                :confirmed="true"
                confirm-message="This will reset swipe allocation used and unblock member if swipe banned"
                :handle-error="handleButtonError"
                :handle-click="updateDebug('/v1/debug-option/swipe-allocation-reset', null)"
            />
        </div>

        <div class="mt-6 bg-grey-100 p-4 rounded">
            <div class="flex flex-row items-end flex-wrap">
                <div class="flex flex-col mr-2">
                    <label class="mb-1">Swipe limit</label>
                    <input
                        v-model="interstitial.swipeLimit"
                        type="number"
                        min="0"
                        placeholder="Enter number"
                        class="p-2 border border-grey-300 rounded sm:w-80"
                    />
                </div>

                <PrimaryButton
                    text="Override"
                    class="mr-2"
                    :disabled="interstitial.swipeLimit === ''"
                    :handle-error="handleButtonError"
                    :handle-click="updateDebug('/v1/debug-option/swipe-limit', { swipeLimit: interstitial.swipeLimit })"
                />

                <PrimaryButton
                    text="Clear"
                    :handle-error="handleButtonError"
                    :handle-click="updateDebug('/v1/debug-option/clear-cache/swipe-limit', null, 'SWIPE_LIMIT')"
                />
            </div>
            <div class="mt-4 flex flex-row items-end flex-wrap">
                <div class="flex flex-col mr-2">
                    <label class="my-1 tooltip">
                        Interstitial frequency
                        <icon icon="question-circle"></icon>
                        <span class="tooltiptext">
                            When set, Interstitials will also show to Premium, friendly or female members
                        </span>
                    </label>
                    <input
                        v-model="interstitial.frequency"
                        type="number"
                        min="1"
                        placeholder="Enter number"
                        class="p-2 border border-grey-300 rounded sm:w-80"
                    />
                </div>

                <PrimaryButton
                    text="Override"
                    class="mr-2"
                    :disabled="interstitial.frequency === ''"
                    :handle-error="handleButtonError"
                    :handle-click="
                        updateDebug('/v1/debug-option/interstitial-frequency', {
                            interstitialFrequency: interstitial.frequency,
                        })
                    "
                />

                <PrimaryButton
                    text="Clear"
                    :handle-error="handleButtonError"
                    :handle-click="
                        updateDebug('/v1/debug-option/clear-cache/interstitial-frequency', null, 'FREQUENCY')
                    "
                />
            </div>
            <div class="mt-4 flex flex-row items-end flex-wrap">
                <div class="flex flex-col mr-2">
                    <label class="my-1 tooltip">
                        Interstitial upsell
                        <icon icon="question-circle"></icon>
                        <span class="tooltiptext">
                            When set, Interstitials will also show to Premium, friendly or female members
                        </span>
                    </label>
                    <Dropdown
                        :key="resetDropdown"
                        class="sm:w-80"
                        :options="interstitialTypeList"
                        placeholder="Select an interstitial"
                        :white-background="true"
                        :is-capitalize="false"
                        @updated="interstitial.name = $event"
                    />
                </div>

                <PrimaryButton
                    text="Override"
                    class="mr-2"
                    :disabled="interstitial.name === ''"
                    :handle-error="handleButtonError"
                    :handle-click="
                        updateDebug('/v1/debug-option/interstitial-name', { interstitialName: interstitial.name })
                    "
                />

                <PrimaryButton
                    text="Clear"
                    :handle-error="handleButtonError"
                    :handle-click="updateDebug('/v1/debug-option/clear-cache/interstitial-name', null, 'NAME')"
                />

                <p
                    v-if="member.premium === '1' || currentRetentionMode === 'FRIENDLY'"
                    class="text-orange-500 w-full mt-3 font-semibold"
                >
                    Member is in Premium or Friendly mode, this will mean they will never be swipe banned
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import DashboardLabel from '@/components/Labels/DashboardLabel';
    import SecondaryButton from '@/components/Buttons/SecondaryButton';
    import PrimaryButton from '@/components/Buttons/PrimaryButton';
    import Dropdown from '@/components/Dropdown/Dropdown';
    import DropdownButton from '@/components/Buttons/Dropdown/DropdownButton';
    import DropdownItem from '@/components/Buttons/Dropdown/DropdownItem';
    import M2Button from '@/components/Buttons/M2Button';
    import { mapActions } from 'vuex';
    import { dashboardAxios } from '../../../../axios.config';

    export default {
        name: 'DebugOptionSwipeInterstitial',
        components: {
            PrimaryButton,
            DashboardLabel,
            SecondaryButton,
            Dropdown,
            DropdownButton,
            DropdownItem,
            M2Button,
        },

        props: {
            member: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                currentRetentionMode: null,
                interstitialTypeList: [
                    { value: 'boost', label: 'Boost' },
                    { value: 'undo', label: 'Undo' },
                    { value: 'gold', label: 'Gold' },
                    { value: 'gold_weekly', label: 'Gold Weekly' },
                    { value: 'gold_monthly', label: 'Gold Monthly' },
                    { value: 'gold_quarterly', label: 'Gold Quarterly' },
                    { value: 'gold_biannually', label: 'Gold Biannually' },
                    { value: 'gold_annually', label: 'Gold Annually' },
                    { value: 'discount_gold_weekly', label: 'Discount Gold Weekly' },
                    { value: 'discount_gold_monthly', label: 'Discount Gold Monthly' },
                    { value: 'discount_gold_quarterly', label: 'Discount Gold Quarterly' },
                    { value: 'discount_gold_biannually', label: 'Discount Gold Biannually' },
                    { value: 'discount_gold_annually', label: 'Discount Gold Annually' },
                ],
                interstitial: {
                    swipeLimit: '',
                    frequency: '',
                    name: '',
                },
                swipeAllocationUsed: null,
                resetDropdown: 0,
            };
        },

        mounted() {
            this.currentRetentionMode = this.member.retentionMode;
        },

        methods: {
            ...mapActions({
                handleError: 'handleError',
                alert: 'alert',
            }),

            async handleButtonError(error) {
                this.handleError({ error });
            },

            updateDebug(url, formData = null, clear = null) {
                return async () => {
                    let payload = {
                        memberID: this.member.memberID,
                    };

                    if (formData) {
                        payload = { ...payload, ...formData };
                    }

                    try {
                        let response = await dashboardAxios.post(url, payload);
                        const message = response.data.result.message;

                        if ('retentionMode' in payload) {
                            this.currentRetentionMode = formData.retentionMode;
                        }

                        switch (clear) {
                            case 'SWIPE_LIMIT':
                                this.interstitial.swipeLimit = '';
                                break;
                            case 'FREQUENCY':
                                this.interstitial.frequency = '';
                                break;
                            case 'NAME':
                                this.interstitial.name = '';
                                this.resetDropdown++;
                                break;
                        }

                        await this.alert({ message: message, type: 'success' });
                    } catch (error) {
                        await this.handleError({ error });
                    }
                };
            },
        },
    };
</script>

<style scoped>
    .tooltip {
        position: relative;
        display: inline-block;
        z-index: 1;
    }

    .tooltip .tooltiptext {
        margin-left: 10px;
        visibility: hidden;
        width: 150px;
        background-color: black;
        color: #fff;
        text-align: center;
        padding: 8px;
        border-radius: 6px;
        position: absolute;
    }

    .tooltip:hover .tooltiptext {
        visibility: visible;
    }
</style>
