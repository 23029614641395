<template>
    <Panel title="Shortcuts">
        <template #header>
            <div class="flex justify-end w-full">
                <div class="flex flex-row">
                    <div class="flex items-center">
                        <input
                            v-model="filter"
                            type="text"
                            placeholder="Search..."
                            class="p-2 rounded focus:outline-none w-full"
                        />
                    </div>

                    <div class="p-4 cursor-pointer" @click="$router.push('/shortcuts/add')">
                        <icon icon="plus"></icon>
                    </div>
                </div>
            </div>
        </template>

        <table class="w-full text-left">
            <thead>
                <tr class="bg-grey-200">
                    <th class="px-4 py-2">Name</th>
                    <th class="px-4 py-2">Shortcut</th>
                    <th class="px-4 py-2">Content</th>
                    <th class="px-4 py-2">
                        Type
                        <icon
                            :icon="isDescending ? 'chevron-down' : 'chevron-up'"
                            class="ml-1 cursor-pointer"
                            @click="toggleSortOrder"
                        />
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="shortcut in sortedShortcuts"
                    :key="shortcut.id"
                    class="border hover:bg-grey-200 cursor-pointer"
                    @click="$router.push('/shortcuts/' + encodeURIComponent(shortcut.name) + '.' + shortcut.id)"
                >
                    <td class="px-4 py-2">{{ shortcut.name }}</td>
                    <td class="px-4 py-2">{{ shortcut.keys }}</td>
                    <td class="px-4 py-2">{{ shortcut.content | limit(60) }}</td>
                    <td class="px-4 py-2">
                        <span class="font-bold p-2 px-3 rounded-full text-center inline-block bg-blue-lightest">
                            {{ shortcut.type }}
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </Panel>
</template>

<script>
    import Panel from '../../components/Panels/Panel';
    import { dashboardAxios } from '../../../axios.config';
    import { mapActions } from 'vuex';

    export default {
        name: 'List',

        components: {
            Panel,
        },

        filters: {
            limit(value, limit) {
                if (value.length <= limit) {
                    return value;
                }

                return value.slice(0, limit).trim() + '...';
            },
        },

        data() {
            return {
                filter: '',
                shortcuts: [],
                isDescending: true,
            };
        },

        computed: {
            filteredShortcuts() {
                if (!this.filter) {
                    return this.shortcuts;
                }

                return _.filter(this.shortcuts, (shortcut) => {
                    return JSON.stringify(shortcut).toLowerCase().includes(this.filter.toLowerCase());
                });
            },
            sortedShortcuts() {
                if (!this.filteredShortcuts) {
                    return [];
                }

                const filteredShortcuts = _.cloneDeep(this.filteredShortcuts);

                return filteredShortcuts.sort((a, b) => {
                    if (a.type < b.type) {
                        return this.isDescending ? -1 : 1;
                    }
                    if (a.type > b.type) {
                        return this.isDescending ? 1 : -1;
                    }
                    return 0;
                });
            },
        },
        mounted() {
            this.loadShortcuts();
        },
        methods: {
            ...mapActions(['handleError']),

            async loadShortcuts() {
                try {
                    let response = await dashboardAxios.get('/v1/shortcuts');

                    this.shortcuts = response.data.result;
                } catch (e) {
                    this.handleError({ error: e });
                }
            },

            toggleSortOrder() {
                this.isDescending = !this.isDescending;
            },
        },
    };
</script>
