<template>
    <span class="uppercase text-pink-muzmatch font-bold text-sm">
        <slot></slot>
    </span>
</template>

<script>
    export default {
        name: 'DashboardLabel',
    };
</script>
