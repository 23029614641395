export default function () {
    if (window.location.search !== '') {
        let queries = window.location.search.replace('?', '').split('&');

        let uri = queries.filter((query) => {
            return query.includes('redirectTo=');
        })[0];

        if (uri) {
            return uri.split('=')[1];
        }
    }

    return null;
}
