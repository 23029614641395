<template>
    <tr class="border h-16" :class="{ 'bg-orange-200': treeLevel === 1, 'bg-orange-100': treeLevel === 2 }">
        <td class="px-4 py-2" :class="{ italic: isChild }">
            <template v-if="isChild">
                <icon v-for="level in treeLevel" :key="level" icon="arrow-right" class="mr-2"></icon>
            </template>
            <span v-if="remoteConfig.constant">
                {{ remoteConfig.constant | camelCase }}
            </span>
            <span v-else class="text-grey">
                {{ remoteConfig.label }}
            </span>
        </td>
        <td class="px-4 py-2">
            <template v-if="showValues">
                <template v-if="['TEXT', 'NUMBER'].includes(remoteConfig.type)">
                    <input
                        v-model="remoteConfig.value"
                        :type="remoteConfig.type"
                        :disabled="!hasAdminPermission"
                        class="p-2 bg-grey-100 rounded w-full"
                    />
                </template>
                <template v-else>
                    <label class="pr-2" :for="remoteConfig.id + '-radio-false'">False</label>
                    <input
                        :id="remoteConfig.id + '-radio-false'"
                        v-model="remoteConfig.value"
                        :disabled="!hasAdminPermission"
                        type="radio"
                        value="0"
                    />
                    <label class="pl-4 pr-2" :for="remoteConfig.id + '-radio-true'">True</label>
                    <input
                        :id="remoteConfig.id + '-radio-true'"
                        v-model="remoteConfig.value"
                        :disabled="!hasAdminPermission"
                        type="radio"
                        value="1"
                    />
                </template>
            </template>
        </td>
        <td class="px-4 py-2 text-center">
            <input
                v-if="!isChild"
                :id="remoteConfig.id + '-checkbox'"
                v-model="remoteConfig.enabled"
                type="checkbox"
                :disabled="!hasAdminPermission"
            />
        </td>
        <td v-if="isProduction" class="px-4 py-2 text-center">
            <input
                v-if="!isChild"
                :id="remoteConfig.id + '-admin-checkbox'"
                v-model="remoteConfig.adminEnabled"
                :disabled="!hasAdminPermission || remoteConfig.enabled"
                type="checkbox"
            />
        </td>
        <td class="px-4 py-2">
            <p v-if="isChild && parentChildMode === 'CONDITIONAL'" class="italic text-sm">
                <AudienceJsonDescription :audience-json-filter="remoteConfig.jsonFilter" />
            </p>
            <p v-else-if="isChild && parentChildMode === 'MULTI_VARIATE'" class="italic text-grey">
                Multi-variate test
            </p>
            <p v-else-if="howManyChild" class="italic text-grey">
                <button
                    class="text-xs border rounded text-center text-white p-2 font-bold w-1/4"
                    :class="[
                        remoteConfig.childMode === 'MULTI_VARIATE' ? 'bg-green-dark' : 'bg-purple-muzmatch',
                        isFilterExpanded ? 'bg-orange-500' : '',
                    ]"
                    @click="$emit('toggle-filter', remoteConfig.id, isFilterExpanded)"
                >
                    {{ howManyChild }} {{ conditionOrTestLabel }}
                    <icon :icon="isFilterExpanded ? 'minus' : 'plus'" class="ml-1 cursor-pointer grey-light" />
                </button>
            </p>
            <p v-else class="italic text-grey">Default only</p>
        </td>
        <td class="px-4 py-2">
            <template v-if="hasAdminPermission && isChild && parentChildMode === 'CONDITIONAL'">
                <button
                    class="w-full text-xs rounded bg-purple-muzmatch text-white text-center p-2"
                    @click="$emit('open-modal', index, remoteConfig.jsonFilter)"
                >
                    Edit Filters
                </button>
                <button
                    class="w-full text-xs rounded bg-blue-dark text-white text-center p-2 mt-filter"
                    @click="openAddChildModal(remoteConfig.id, remoteConfig.type, 'MULTI_VARIATE')"
                >
                    Add Test
                </button>
            </template>
        </td>
        <td class="px-4 py-2">
            {{ remoteConfig.updatedAt | date }}
        </td>
        <td v-if="hasAdminPermission" class="px-4 py-2">
            <button
                class="w-1/2 text-xs border rounded bg-pink-dark text-center text-white p-2 float-right"
                :class="{ 'cursor-not-allowed bg-grey-muzmatch': isButtonDisabled }"
                :disabled="isButtonDisabled"
                @click="
                    $emit('update-config', {
                        id: remoteConfig.id,
                        label: remoteConfig.label,
                        value: remoteConfig.value,
                        enabled: remoteConfig.enabled,
                        adminEnabled: remoteConfig.adminEnabled,
                        jsonFilter: remoteConfig.jsonFilter,
                        parentID: parentId,
                        parentChildMode: parentChildMode,
                    })
                "
            >
                Update
            </button>
            <button
                v-if="isChild"
                class="w-1/2 text-xs border rounded bg-red-dark text-center text-white p-2 float-right"
                @click="
                    $emit('delete-config', {
                        id: remoteConfig.id,
                        label: remoteConfig.label,
                        parentID: parentId,
                    })
                "
            >
                Delete
            </button>
            <button
                v-else
                class="w-1/2 text-xs border rounded text-center p-2 float-right"
                :disabled="isAddChildDisabled"
                :class="[
                    !isAddChildDisabled ? 'bg-blue-dark text-white' : 'bg-grey-100 text-grey-300 cursor-not-allowed',
                ]"
                @click="openAddChildModal(remoteConfig.id, remoteConfig.type, remoteConfig.childMode)"
            >
                <template v-if="howManyChild > 0 && remoteConfig.childMode === 'CONDITIONAL'">Add Cond.</template>
                <template v-else-if="howManyChild > 0 && remoteConfig.childMode === 'MULTI_VARIATE'">Add Test</template>
                <template v-else>Add Child</template>
            </button>
        </td>
    </tr>
</template>

<script>
    import _ from 'lodash';
    import moment from 'moment';
    import AudienceJsonDescription from '../Messenger/AudienceJsonDescription';

    export default {
        name: 'TableRow',
        components: {
            AudienceJsonDescription,
        },
        filters: {
            date(date) {
                if (!date) return '-';
                return moment(date).format('DD/MM/YYYY HH:mm');
            },
            camelCase(title) {
                if (!title) return '';
                return _.camelCase(title);
            },
        },

        props: {
            index: {
                type: Number,
                required: true,
            },
            treeLevel: {
                type: Number,
                required: true,
            },
            remoteConfig: {
                type: Object,
                required: true,
            },
            parentId: {
                type: Number,
                default: null,
            },
            parentChildMode: {
                type: String,
                default: null,
            },
            isButtonDisabled: {
                type: Boolean,
                default: true,
            },
            isFilterExpanded: {
                type: Boolean,
                default: false,
            },
            isAddChildDisabled: {
                type: Boolean,
                default: false,
            },
            howManyChild: {
                type: Number,
                default: 0,
            },
            isProduction: {
                type: Boolean,
                required: true,
            },
            hasAdminPermission: {
                type: Boolean,
                required: true,
            },
        },
        computed: {
            isChild() {
                return this.treeLevel >= 1;
            },
            showValues() {
                // If grandchild
                if (this.treeLevel === 2) {
                    return true;
                }

                if (this.remoteConfig.childMode !== 'MULTI_VARIATE') {
                    return true;
                }

                return this.howManyChild === 0;
            },
            conditionOrTestLabel() {
                if (this.remoteConfig.childMode === 'CONDITIONAL') {
                    return this.howManyChild === 1 ? 'cond.' : 'conds.';
                }

                return this.howManyChild === 1 ? 'test' : 'tests';
            },
        },
        watch: {
            'remoteConfig.enabled'(val) {
                if (val === true) {
                    this.remoteConfig.adminEnabled = false;
                }
            },
        },
        methods: {
            openAddChildModal(parentID, parentType, childMode) {
                if (this.howManyChild === 0 && this.treeLevel === 0) {
                    childMode = '';
                }

                this.$emit('open-add-child-modal', parentID, parentType, childMode, this.howManyChild);
            },
        },
    };
</script>

<style scoped>
    .mt-filter {
        margin-top: 0.15rem;
    }
</style>
