<template>
    <div class="flex">
        <div v-if="element.css" class="mt-2">
            <span v-for="c in element.css" :key="c.icon" class="cursor-pointer mx-2">
                <icon
                    :class="{ 'text-pink-muzmatch': c.icon === activeIcon }"
                    :icon="c.icon"
                    @click="setInlineCSS(c.inlineCSS, c.icon)"
                />
            </span>
        </div>

        <div v-if="element.options" class="mt-2">
            <span
                v-for="option in element.options"
                :key="option"
                class="cursor-pointer mx-2"
                @click="updateNodeType(option)"
            >
                {{ option.toUpperCase() }}
            </span>
        </div>

        <div v-if="element.classes" class="mt-2">
            <span
                v-for="className in element.classes"
                :key="className"
                class="cursor-pointer mx-2"
                @click="updateNodeClass(className)"
            >
                {{ className }}
            </span>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';

    export default {
        name: 'CampaignElementMenu',
        props: {
            element: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                activeIcon: null,
            };
        },
        created() {
            if (!this.element.style || !this.element.style['text-align']) {
                return;
            }

            switch (this.element.style['text-align']) {
                case 'left':
                    this.activeIcon = 'align-left';
                    break;

                case 'center':
                    this.activeIcon = 'align-center';
                    break;

                case 'right':
                    this.activeIcon = 'align-right';
                    break;

                default:
                    break;
            }
        },
        methods: {
            setInlineCSS(param, icon) {
                this.activeIcon = icon;
                Vue.set(this.element, 'style', param);
            },

            updateNodeType(type) {
                Vue.set(this.element, 'tagName', type);
            },

            updateNodeClass(className) {
                let classString = this.element.children[0].class;

                if (classString.includes(className)) {
                    classString = classString.replace(className, '');
                } else {
                    classString = `${classString.trim()} ${className}`;
                }

                Vue.set(this.element.children[0], 'class', classString);
            },
        },
    };
</script>
