<template>
    <div class="container mx-auto max-w-sm">
        <Panel title="Marketing Gold">
            <div class="m-2 flex flex-row items-end flex-wrap">
                <form class="w-full" @submit="search">
                    <div class="flex flex-col mb-2 w-full">
                        <input
                            v-model="emailAddress"
                            :disabled="phoneNumber != '' || shareLink != ''"
                            type="text"
                            placeholder="Email Address"
                            class="p-2 border border-grey-300 rounded"
                            spellcheck="false"
                            autocapitalize="off"
                        />
                    </div>
                    <div class="w-full grid justify-items-center">
                        <span>Or</span>
                    </div>
                    <div class="flex flex-col mt-2 mb-4 w-full">
                        <input
                            v-model="phoneNumber"
                            :disabled="emailAddress != '' || shareLink != ''"
                            placeholder="Phone No. (e.g. a UK phone number is 44XXXXXXXXXX)"
                            type="tel"
                            class="p-2 border border-grey-300 rounded"
                            spellcheck="false"
                            autocapitalize="off"
                        />
                    </div>
                    <div class="w-full grid justify-items-center">
                        <span>Or</span>
                    </div>
                    <div class="flex flex-col mt-2 mb-4 w-full">
                        <input
                            v-model="shareLink"
                            :disabled="emailAddress != '' || phoneNumber != ''"
                            placeholder="Share Link (e.g. muzz.com/profile/d/01dbbb9e190d954229df5791dfe9fc00e9f14a421d3e973466094f6c8d0b0f04)"
                            type="tel"
                            class="p-2 border border-grey-300 rounded"
                            spellcheck="false"
                            autocapitalize="off"
                        />
                    </div>
                    <div class="w-full grid justify-items-end mb-4">
                        <PrimaryButton text="Search" :handle-error="handleButtonError" :handle-click="() => search()" />
                    </div>
                </form>
                <div v-if="member !== null" class="flex flex-col w-full">
                    <div class="w-full grid justify-items-center">
                        <img
                            class="profile-photo rounded-lg cursor-pointer h-64"
                            :src="profilephoto"
                            @error="$event.target.src = require('../assets/mm2.png')"
                        />
                    </div>
                    <div class="w-full flex flex-row justify-center">
                        <div :class="member.premium === '1' ? 'pt-2' : 'pt-1'">
                            <img v-if="member.premium === '1'" src="@/assets/logo-gold@3x.png" class="w-8" />
                            <img v-else src="@/assets/logo-mark@3x.png" class="w-8" />
                        </div>

                        <span
                            class="font-bold ml-3 p-2 notranslate"
                            :class="member.approved === '1' ? 'text-green-dark' : 'text-pink-muzmatch'"
                        >
                            {{ member.nickname }} ({{ member.age }}/{{ member.gender }})
                        </span>

                        <router-link
                            :to="getMemberRoute(member)"
                            class="hover:text-grey-600 text-grey-800 cursor-pointer p-2"
                            target="_blank"
                        >
                            <icon icon="link"></icon>
                        </router-link>
                    </div>
                    <div class="flex flex-col w-full gap-y-3">
                        <div class="w-full flex flex-row justify-center gap-x-5">
                            <div class="w-1/2 flex flex-row flex-1">
                                <select v-model="instantMatchCredits" class="p-2 border border-grey-300 rounded w-full">
                                    <option value="">Please select one</option>
                                    <option v-for="ic in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" :key="ic" :value="ic">
                                        {{ ic }}
                                    </option>
                                </select>
                                <icon
                                    icon="times"
                                    class="text-pink-muzmatch h-10 pl-1 cursor-pointer"
                                    size="lg"
                                    @click="instantMatchCredits = ''"
                                />
                            </div>
                            <div class="w-1/3">
                                <SecondaryButton
                                    class="ml-1 mb-1 w-full"
                                    text="Apply IC"
                                    :confirmed="true"
                                    confirm-message="This will give the member instant match credits."
                                    :handle-click="
                                        updateProfile(
                                            '/v1/credits-add',
                                            'post',
                                            {
                                                credits: instantMatchCredits,
                                                type: 'instantMatchCredit',
                                            },
                                            `${instantMatchCredits} Instant Match credit${
                                                instantMatchCredits > 1 ? 's' : ''
                                            } saved.`,
                                        )
                                    "
                                    :disabled="instantMatchCredits == ''"
                                ></SecondaryButton>
                            </div>
                        </div>
                        <div class="w-full flex flex-row justify-center gap-x-5">
                            <div class="w-1/2 flex flew-row flex-1">
                                <select v-model="boostCredits" class="p-2 border border-grey-300 rounded w-full">
                                    <option value="">Please select one</option>
                                    <option
                                        v-for="boost in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                                        :key="boost"
                                        :value="boost"
                                    >
                                        {{ boost }}
                                    </option>
                                </select>
                                <icon
                                    icon="times"
                                    class="text-pink-muzmatch h-10 pl-1 cursor-pointer"
                                    size="lg"
                                    @click="boostCredits = ''"
                                />
                            </div>
                            <div class="w-1/3">
                                <SecondaryButton
                                    class="ml-1 mb-1 w-full"
                                    text="Apply Boost"
                                    :confirmed="true"
                                    confirm-message="This will give the member a free boost credit."
                                    :handle-click="
                                        updateProfile(
                                            '/v1/credits-add',
                                            'post',
                                            {
                                                credits: boostCredits,
                                                type: 'boostCredit',
                                            },
                                            `${boostCredits} Boost credit${boostCredits > 1 ? 's' : ''} saved.`,
                                        )
                                    "
                                    :disabled="boostCredits == 0"
                                ></SecondaryButton>
                            </div>
                        </div>
                        <div class="w-full flex flex-row justify-center mb-2 gap-x-5">
                            <div v-if="member.premium !== '1'" class="w-max flex flex-row flex-1">
                                <select v-model="gold" class="p-2 border border-grey-300 rounded w-full">
                                    <option :value="{ value: null, unit: null }">Please select one</option>
                                    <option :value="{ value: 1, unit: 'day' }">1 Day</option>
                                    <option :value="{ value: 7, unit: 'day' }">7 Days</option>
                                    <option
                                        v-for="month in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
                                        :key="month"
                                        :value="{ value: month, unit: 'month' }"
                                    >
                                        {{ month }} month{{ month > 1 ? 's' : '' }}
                                    </option>
                                </select>
                                <icon
                                    icon="times"
                                    class="text-pink-muzmatch h-10 pl-1 cursor-pointer"
                                    size="lg"
                                    @click="gold = { value: null, unit: null }"
                                />
                            </div>
                            <div v-if="member.premium !== '1'" class="w-1/3">
                                <SecondaryButton
                                    class="ml-1 mb-1 w-full"
                                    text="Apply Gold"
                                    :disabled="gold.value == null && gold.unit == null"
                                    :confirmed="true"
                                    confirm-message="This will give the member free gold."
                                    :handle-click="
                                        updateProfile(
                                            '/v1/apply-gold',
                                            'post',
                                            { duration: gold.value, unit: gold.unit },
                                            `${gold.value} ${gold.unit}${gold.value > 1 ? 's' : ''} of Gold applied!`,
                                        )
                                    "
                                ></SecondaryButton>
                            </div>
                            <span v-if="member.premium === '1'">Member already has gold</span>
                        </div>
                    </div>
                </div>
            </div>
        </Panel>
    </div>
</template>
<script>
    import { mapActions } from 'vuex';
    import Panel from '../components/Panels/Panel';
    import PrimaryButton from '../components/Buttons/PrimaryButton';
    import SecondaryButton from '../components/Buttons/SecondaryButton';
    import { dashboardAxios } from '../../axios.config';
    import { getMemberRoute } from '../helpers/RedirectToMember';
    export default {
        name: 'WaliCredit',
        components: {
            Panel,
            PrimaryButton,
            SecondaryButton,
        },
        data() {
            return {
                phoneNumber: '',
                emailAddress: '',
                shareLink: '',
                member: null,
                instantMatchCredits: 0,
                boostCredits: 0,
                gold: { value: null, unit: null },
            };
        },
        computed: {
            profilephoto() {
                if (typeof this.member.mediaSigned.profile !== 'undefined') {
                    return this.member.mediaSigned.profile[1].url ?? '';
                }
                return '';
            },
        },
        methods: {
            ...mapActions({
                handleError: 'handleError',
                alert: 'alert',
            }),
            async search(e) {
                e.preventDefault();
                this.searchProfile();
            },
            async searchProfile() {
                try {
                    let data = {};

                    if (this.phoneNumber != '') {
                        data.phoneNumber = this.phoneNumber;
                    } else if (this.emailAddress != '') {
                        data.emailAddress = this.emailAddress;
                    } else if (this.shareLink != '') {
                        let urlArray = this.shareLink.split('/');
                        let code = urlArray[urlArray.length - 1];
                        data.code = code;
                    }

                    let response = await dashboardAxios.get('/v1/member-number-email-share-search', { params: data });

                    this.member = response.data.result;

                    this.clear();
                } catch (e) {
                    await this.handleSearchError({ error: e });
                }
            },
            clear() {
                this.instantMatchCredits = '';

                this.boostCredits = '';

                this.gold = { value: null, unit: null };
            },
            getMemberRoute,
            handleButtonError(error) {
                console.log(error);
            },
            handleSearchError(error) {
                this.member = null;
                this.alert({ message: "Can't find member", type: 'danger' });
            },
            updateProfile(url, requestType, formData, message) {
                return async () => {
                    let data = {
                        member_id: this.member.memberID,
                    };

                    if (formData) {
                        data = { ...data, ...formData };
                    }

                    let request =
                        requestType === 'post'
                            ? dashboardAxios.post(url, data)
                            : dashboardAxios.get(url, { params: data });

                    try {
                        await request;

                        this.clear();

                        this.searchProfile();

                        this.alert({ message, type: 'success' });
                    } catch (error) {
                        this.alert({ message: 'Error updating member', type: 'danger' });
                    }
                };
            },
        },
    };
</script>
