<template>
    <div class="flex flex-col w-full items-center h-screen-80 justify-center">
        <number
            v-if="members"
            ref="number1"
            class="text-6xl lg:text-9xl"
            :from="from"
            :to="members"
            :duration="duration"
            :delay="0"
            easing="Power1.easeOut"
        />
    </div>
</template>

<script>
    import { dashboardAxios } from '../../axios.config';
    export default {
        data() {
            return {
                from: 4000000,
                members: null,
                timer: null,
                duration: 2,
                confettiStarted: false,
            };
        },
        watch: {
            members(val) {
                if (val < 6000000) return;
                if (this.confettiStarted) return;

                this.confettiStarted = true;

                setTimeout(() => {
                    this.$confetti.start();
                }, this.duration * 1000);
            },
        },
        created() {
            this.getMemberCount();
        },
        beforeDestroy() {
            this.$confetti.stop();
            clearInterval(this.timer);
        },
        methods: {
            theFormat(number) {
                return number;
            },
            async getMemberCount() {
                this.members = Number(await dashboardAxios.get('/v1/member-count').then((res) => res.data.result));

                if (!this.timer) {
                    // every 15 minutes
                    this.timer = setInterval(() => {
                        this.getMemberCount();
                    }, 60000 * 15);
                }

                this.from = this.members;
            },
        },
    };
</script>
