<template>
    <td class="px-4 py-2 text-center" @click="select">{{ value }}</td>
</template>

<script>
    export default {
        name: 'TableCell',
        props: {
            name: {
                type: String,
                required: true,
            },
            value: {
                type: String,
                required: true,
            },
            dataset: {
                type: String,
                required: true,
            },
        },

        methods: {
            select() {
                this.$emit('select', {
                    version: this.name,
                    dataset: this.dataset,
                });
            },
        },
    };
</script>
