<template>
    <div v-if="member" class="p-2">
        <div class="hover:bg-grey-400 p-4 rounded cursor-pointer flex" :class="{ 'bg-grey-300': selected }">
            <div class="flex-1 flex items-center">
                <img
                    class="w-10 h-10 rounded-full mr-4"
                    :src="member.signedUrl ? member.signedUrl : photoUrl(member.photo, member.id)"
                />

                <span class="px-2 notranslate">{{ member.full_name }}</span>
            </div>

            <div class="w-4 flex items-center justify-center">
                <icon icon="chevron-right"></icon>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MemberCard',

        props: {
            selected: {
                type: Boolean,
                required: true,
            },

            member: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                photoBaseUrl: `https://${process.env.VUE_APP_CDN_DOMAIN}`,
            };
        },

        methods: {
            photoUrl(photoHash, hashMemberID) {
                if (typeof photoHash == 'undefined') {
                    return '';
                }

                return `${this.photoBaseUrl}/media/${hashMemberID}/profile/image/square/${photoHash}@1x.jpg`;
            },
        },
    };
</script>
