import VueCookies from 'vue-cookie';
import { dashboardAxios, messengerAxios, socialModerationAxios } from '../../../axios.config';

export const requestCheckTokenExists = () => {
    let token = VueCookies.get('token');

    return token !== null ? token : null;
};

export const requestSetToken = ({ commit, dispatch }, token) => {
    commit('setToken', token);
    dispatch('requestSetHttpToken', token);
};

export const requestClearAuth = ({ commit }) => {
    commit('setToken', null);

    VueCookies.delete('token');
};

export const requestSetHttpToken = (context, token) => {
    dashboardAxios.defaults.headers.common['Authorization'] = token ? 'Bearer ' + token : null;
    messengerAxios.defaults.headers.common['Authorization'] = token ? 'Bearer ' + token : null;
    socialModerationAxios.defaults.headers.common['Authorization'] = token ? 'Bearer ' + token : null;
};
