<template>
    <div class="email">
        <campaign-viewer
            :class="{ 'right-to-left': rtl }"
            :nodes="renderMarkdown()"
            @html-event="$emit('html-event', $event)"
        />
    </div>
</template>

<script>
    import CampaignViewer from './CampaignViewer.vue';

    export default {
        components: { CampaignViewer },
        props: {
            template: {
                type: Object,
                default: null,
            },
            rtl: {
                type: Boolean,
                default: false,
            },
        },
        methods: {
            renderMarkdown() {
                if (!this.template) return {};

                return {
                    ...this.template,
                    children: this.template.children.map((node) => {
                        let newChild = {
                            ...node,
                            domProps: {
                                innerHTML: node.textNode
                                    .replace(/(\*(.+)\*)/, (word) => `<b> ${word.split('*')[1]} </b>`)
                                    .replace(
                                        /(~(.+)~)/,
                                        (word) => `<span style="color: #fb406c"> ${word.split('~')[1]} </span>`,
                                    )
                                    .replace(
                                        /(\[(.+)\]\((.+)\))/,
                                        (word) =>
                                            `<a href="${word.split(/\((.+)\)/)[1]}" target="_blank"> ${
                                                word.split(/\[(.+)\]/)[1]
                                            } </a>`,
                                    ),
                            },
                        };

                        return newChild;
                    }),
                };
            },
        },
    };
</script>

<style src="./email.css" scoped></style>

<style scoped>
    .right-to-left {
        direction: rtl;
        text-align: right;
    }
</style>
