<template>
    <div class="shadow-lg bg-white p-2 rounded w-64 absolute z-10 profile-dropdown">
        <div class="p-2 hover:bg-grey-200 rounded" @click="logout">
            <button>Sign out</button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'LogoutMenu',

        methods: {
            logout() {
                this.$emit('logout');
            },
        },
    };
</script>

<style scoped>
    .profile-dropdown {
        top: 15px;
        right: 0;
    }
</style>
