<template>
    <div>
        <AllContentRowsModal v-if="isModalOpen" :content="modalContent" @hide="isModalOpen = false" />
        <table class="w-full text-left table-fixed mt-12">
            <thead>
                <tr class="bg-grey-200">
                    <th class="px-4 py-2 w-1/6">Question ID</th>
                    <th class="px-4 py-2 w-1/6 text-center">Answer Type</th>
                    <th class="px-4 py-2">Answer Value</th>
                    <th class="px-4 py-2"></th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(icebreaker, key) in profile.icebreakers"
                    :key="key"
                    class="border hover:bg-grey-200"
                    @click="openModal($event, icebreaker)"
                >
                    <td class="p-4">
                        {{ icebreaker.questionValue }}
                    </td>
                    <td class="p-4 text-center">
                        {{ icebreaker.answerType }}
                    </td>
                    <td v-if="icebreaker.answerType == 'voice'" class="p-4">
                        <audio
                            v-if="icebreaker.answerType == 'voice'"
                            controls
                            class="focus:outline-none max-w-xs"
                            :src="icebreaker.media.variations[0].url"
                        ></audio>
                    </td>
                    <td v-else>{{ icebreaker.answerValue }}</td>
                    <td class="p-4 text-center">
                        <div class="flex justify-end">
                            <div>
                                <M2Button v-if="canDelete()" name="delete" @clicked="handleDelete(key)">
                                    Remove
                                </M2Button>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import { User } from '@/classes/User';
    import M2Button from '../../Buttons/M2Button';
    import AllContentRowsModal from './AllContentRowsModal';
    import { mapGetters, mapActions } from 'vuex';
    import { dashboardAxios } from '@/../axios.config';

    export default {
        name: 'IcebreakersTab',
        components: {
            AllContentRowsModal,
            M2Button,
        },
        props: {
            profile: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                isModalOpen: false,
                modalContent: null,
            };
        },
        computed: {
            ...mapGetters({
                staff: 'staff/getStaff',
            }),
        },
        methods: {
            ...mapActions({
                handleError: 'handleError',
                alert: 'alert',
            }),
            canDelete() {
                return true;
            },
            openModal(event, content) {
                if (event.target.name == 'delete') return;
                this.isModalOpen = true;
                this.modalContent = content;
            },
            async handleDelete(key) {
                const response = await this.$swal({
                    title: 'Are you sure?',
                    icon: 'warning',
                    text: this.confirmMessage,
                    showCancelButton: true,
                    confirmButtonText: 'Continue',
                });

                if (!response?.value) {
                    return;
                }

                let data = {
                    staffID: this.staff.id,
                    memberID: this.profile.memberID,
                    icebreakerQuestionID: key,
                };

                try {
                    let response = await dashboardAxios.post('/v1/icebreakers/delete', data);
                    const message = response.data.result.message;
                    this.alert({ message: message, type: 'success' });
                    this.$emit('refresh');
                } catch (error) {
                    this.handleError({ error });
                }
            },
        },
    };
</script>
