<template>
    <div>
        <div v-if="video && !isLoading" class="cursor-pointer" @click="openVideo">
            <img class="h-48 object-cover w-full" :src="video.images[0]" alt="" />
            <div class="p-3 rounded-bl-lg border shadow-sm">
                <div class="text-xs font-bold mb-2">{{ video.title }}</div>
                <div class="text-xs mb-2">{{ video.description }}</div>
            </div>
        </div>
        <div v-else class="text-center">
            <icon v-if="!error" icon="spinner" class="animate-spin" />
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    export default {
        props: {
            url: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                video: null,
                isLoading: false,
                error: false,
            };
        },
        created() {
            this.getMetaData();
        },
        methods: {
            getMetaData() {
                this.isLoading = true;

                axios
                    .get(`https://jsonlink.io/api/extract?url=${this.url}`, {
                        timeout: 5000,
                    })
                    .then((res) => (this.video = res.data))
                    .catch(() => (this.error = true))
                    .finally(() => (this.isLoading = false));
            },
            openVideo() {
                window.open(this.url, '_blank');
            },
        },
    };
</script>
