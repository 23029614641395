import { dashboardAxios } from '../../../axios.config';

export default {
    namespaced: true,

    state: {
        ethnicities: [],
        professions: [],
        countries: [],
        cities: [],
    },

    mutations: {
        setEthnicities(state, ethnicities) {
            state.ethnicities = ethnicities;
        },
        setProfessions(state, professions) {
            state.professions = professions;
        },
        setCountries(state, countries) {
            state.countries = countries;
        },
        setCities(state, cities) {
            state.cities = cities;
        },
    },

    getters: {
        getEthnicities(state) {
            return state.ethnicities;
        },
        getProfessions(state) {
            return state.professions;
        },
        getCountries(state) {
            return state.countries;
        },
        getCities(state) {
            return state.cities;
        },
    },

    actions: {
        async loadEthnicities({ commit, state, dispatch }) {
            if (state.ethnicities?.length > 0) {
                return;
            }

            try {
                let response = await dashboardAxios.get('/v1/meta/ethnicities');
                commit('setEthnicities', response.data.result ?? []);
            } catch (e) {
                dispatch('handleError', { error: e }, { root: true });
            }
        },
        async loadProfessions({ commit, state, dispatch }) {
            if (state.professions?.length > 0) {
                return;
            }

            try {
                let response = await dashboardAxios.get('/v1/meta/professions');
                commit('setProfessions', response.data.result ?? []);
            } catch (e) {
                dispatch('handleError', { error: e }, { root: true });
            }
        },
        async loadCountries({ commit, state, dispatch }) {
            if (state.countries?.length > 0) {
                return;
            }
            try {
                let response = await dashboardAxios.get('/v1/meta/countries');
                commit('setCountries', response.data.result ?? []);
            } catch (e) {
                dispatch('handleError', { error: e }, { root: true });
            }
        },
        async loadCities({ commit, state, dispatch }) {
            if (state.cities?.length > 0) {
                return;
            }

            try {
                let response = await dashboardAxios.get('/v1/meta/cities');
                commit('setCities', response.data.result ?? []);
            } catch (e) {
                dispatch('handleError', { error: e }, { root: true });
            }
        },
    },
};
