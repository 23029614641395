<template>
    <Panel ref="panel" title="Member">
        <div slot="header" class="ml-auto flex w-full justify-between items-center">
            <div v-if="detachMemberCondition" class="flex justify-center items-center">
                <div class="text-pink-muzmatch relative cursor mx-2">
                    <icon
                        icon="id-badge"
                        class="mx-1"
                        :class="detachMemberCondition ? 'cursor-pointer' : 'cursor-auto'"
                        @click="detachMember"
                    />
                    <icon
                        icon="minus-circle"
                        class="absolute position-custom-2 mx-1 text-custom"
                        :class="detachMemberCondition ? 'cursor-pointer' : 'cursor-auto'"
                        @click="detachMember"
                    />
                </div>
            </div>

            <div class="inline-flex w-full justify-end items-center">
                <div v-if="member" class="inline-flex flex-wrap justify-center py-2">
                    <a href="#summary" class="no-underline text-pink-muzmatch mx-2 font-bold text-sm cursor-pointer">
                        Summary
                    </a>
                    <a href="#profile" class="no-underline text-pink-muzmatch mx-2 font-bold text-sm cursor-pointer">
                        Profile
                    </a>
                    <a href="#actions" class="no-underline text-pink-muzmatch mx-2 font-bold text-sm cursor-pointer">
                        Actions
                    </a>
                    <a href="#reports" class="no-underline text-pink-muzmatch mx-2 font-bold text-sm cursor-pointer">
                        Reports
                    </a>

                    <a
                        href="#reports"
                        class="no-underline text-pink-muzmatch mx-2 font-bold text-sm cursor-pointer mr-4"
                        @click="loadChat()"
                    >
                        Messages
                    </a>
                </div>

                <icon icon="redo" class="cursor-pointer grey-light mr-4" @click="loadMember()" />
            </div>
        </div>

        <div id="dashboard-panel" class="h-screen-80 overflow-y-scroll overflow-x-hidden bg-grey-lightest">
            <DashboardCard
                v-if="member && !memberLoading"
                class="p-4"
                :member="member"
                :hints="hints"
                :member-hash-id="member.hashMemberID"
                :chat-hash-id="chatHashId"
                :even-split="evenSplit"
                :context-type="contextType"
                :context-task-id="parseInt(task.id)"
                :reporter-id="reporterId"
                :identity-verification="identityVerification"
                @refresh="loadMember"
                @rejectSocialAttribute="rejectSocialAttribute"
            ></DashboardCard>

            <div v-else class="flex w-full h-full justify-center items-center flex-col">
                <div
                    v-show="attachMemberCondition"
                    class="text-1xl leading-relaxed text-center mb-4 mx-12 text-pink-muzmatch"
                >
                    No member found for email address {{ firstEmailAddressInThread }}.
                    <br />
                    Click the icon below to associate a member with this email address.
                </div>

                <div class="text-5xl text-pink-muzmatch relative cursor">
                    <icon
                        icon="id-badge"
                        class="mx-1"
                        :class="attachMemberCondition ? 'cursor-pointer' : 'cursor-auto'"
                        @click="toggleMemberModal"
                    />
                    <icon
                        v-if="attachMemberCondition"
                        icon="plus-circle"
                        class="absolute position-custom mx-1 text-sm"
                        :class="attachMemberCondition ? 'cursor-pointer' : 'cursor-auto'"
                        @click="toggleMemberModal"
                    />
                </div>
            </div>
        </div>

        <AttachMemberModal
            v-if="memberModal"
            :id="parseInt(task.id, 10)"
            :email-address="firstEmailAddressInThread"
            @toggleMemberModal="toggleMemberModal"
            @setMember="setMember"
        />
    </Panel>
</template>

<script>
    import { mapActions } from 'vuex';
    import EventBus from '../../helpers/eventBus';
    import { dashboardAxios } from '../../../axios.config';
    import AttachMemberModal from '../Modal/AttachMemberModal';

    export default {
        name: 'DashboardPanel',

        components: {
            AttachMemberModal,
        },

        props: {
            memberHashId: {
                type: String,
                default: null,
            },

            queue: {
                type: Object,
                default: null,
            },

            task: {
                type: Object,
                default: null,
            },

            evenSplit: {
                type: Boolean,
                default: false,
            },

            contextType: {
                type: String,
                required: true,
            },

            isReportTask: {
                type: Boolean,
                required: true,
            },

            member: {
                type: Object,
                default: null,
            },

            hints: {
                type: Array,
                default: () => [],
            },

            memberLoading: {
                type: Boolean,
                required: true,
            },

            reporterId: {
                type: Number,
                default: 0,
            },
        },

        data() {
            return {
                memberModal: false,
                identityVerification: { response: null, session: null },
            };
        },

        computed: {
            chatHashId() {
                if (
                    this.contextType !== 'CONTEXT_TYPE_TASK_INAPPROPRIATE_MESSAGE' &&
                    this.task.source !== 'AUTOMATED_REPORT'
                ) {
                    return null;
                }

                if (!this.isReportTask) {
                    return null;
                }

                if (this.memberHashId === this.task.first_member.id) {
                    return this.task.second_member.id;
                }

                return this.task.first_member.id;
            },

            attachMemberCondition() {
                return !this.member && !!this.queue && this.queue.text === 'Email' && !!this.task && !!this.task.id;
            },

            detachMemberCondition() {
                if (!this.member || !this.queue || !this.task) {
                    return false;
                }

                return (
                    this.queue.text === 'Email' &&
                    this.task.memberEmailAddresses.some((emailAddress) => emailAddress.type === 'OTHER')
                );
            },
            firstEmailAddressInThread() {
                if (!this.attachMemberCondition || !this.task.threadEmailAddresses) return;

                return this.task.threadEmailAddresses.length !== 0 ? this.task.threadEmailAddresses[0] : '';
            },
        },

        watch: {
            memberHashId() {
                this.loadMember();
            },
            task() {
                this.identityVerification = { response: null, session: null };
                if ('identityVerification' in this.task) {
                    this.identityVerification = this.task.identityVerification;
                }
            },
        },

        methods: {
            ...mapActions({
                handleError: 'handleError',
            }),

            async loadChat() {
                EventBus.$emit('loadChat');
            },

            async loadMember() {
                if (this.memberLoading) {
                    return;
                }

                EventBus.$emit('loadMember');
            },

            toggleMemberModal() {
                if (this.member || !this.queue || this.queue.text !== 'Email') {
                    return;
                }

                this.memberModal = !this.memberModal;
            },

            async detachMember() {
                let response = await this.$swal({
                    title: 'Are you sure?',
                    icon: 'warning',
                    text: 'This will detach the member from the task.',
                    showCancelButton: true,
                    confirmButtonText: 'Continue',
                });

                if (response.value) {
                    try {
                        await dashboardAxios.delete(
                            `/v1/tasks/email/thread/${this.task.id}/member/${this.member.memberID}`,
                        );
                        this.setMember(null);
                    } catch (e) {
                        this.handleError(e);
                    }
                }
            },

            setMember(member) {
                this.$emit('memberSelected', member);
            },

            rejectSocialAttribute(reason) {
                this.$emit('rejectSocialAttribute', reason);
            },
        },
    };
</script>

<style>
    .position-custom {
        top: 0;
        left: 2.5rem;
        z-index: 10;
    }

    .position-custom-2 {
        top: 0;
        left: 0.9rem;
        z-index: 10;
    }

    .text-custom {
        font-size: 0.5rem;
    }
</style>
