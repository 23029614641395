<template>
    <Panel :title="'Task - ' + queue.text">
        <div v-if="task && !queue.social">
            <MemberCard
                v-if="task.second_member && queue.text !== 'Email'"
                :member="task.second_member"
                :selected="selected && selected.id === task.second_member.id"
                @click.native="selectMember(task.second_member, task)"
            />

            <div v-if="task.second_member" class="px-4">
                <p class="font-bold">Reported</p>
            </div>

            <MemberCard
                v-if="task.first_member && queue.text !== 'Email'"
                :member="task.first_member"
                :selected="selected && selected.id === task.first_member.id"
                @click.native="selectMember(task.first_member, task)"
            />

            <div v-if="task.thread && !taskTagPage" class="break-words p-4">
                <span class="font-bold">Thread</span>
                <p class="mt-2 font-mono">{{ task.thread.type ? task.thread.type : 'TEST_EMAIL' }}</p>
            </div>

            <div v-if="task.thread && taskTagPage" class="break-words p-4 flex" @click="selectMember(null, task)">
                <div class="flex-1 items-center">
                    <span class="font-bold">Thread</span>
                    <p class="mt-2 font-mono">{{ task.thread.type ? task.thread.type : 'TEST_EMAIL' }}</p>
                </div>

                <div class="w-4 flex items-center justify-center float-right">
                    <icon icon="chevron-right"></icon>
                </div>
            </div>

            <div v-if="task.app_reviews && taskTagPage" class="break-words p-4 flex" @click="selectMember(null, task)">
                <div class="flex-1 items-center">
                    <span>Open App Review</span>
                </div>

                <div class="w-4 flex items-center justify-center float-right">
                    <icon icon="chevron-right"></icon>
                </div>
            </div>

            <div v-if="task.issue || task.comment" class="border-t border-grey-200 p-4">
                <div v-if="task.issue" class="">
                    <span class="font-bold">Issue</span>
                    <p class="mt-2 font-mono">{{ task.issue }}</p>
                </div>

                <div v-if="task.comment" class="mt-6">
                    <span class="font-bold">Comment</span>
                    <p v-if="task.source === 'AUTOMATED_REPORT'" class="text-orange-400 font-bold uppercase">
                        Automated Report
                    </p>
                    <p class="mt-2">{{ task.comment }}</p>

                    <div class="mt-2">
                        <span
                            class="text-pink-muzmatch no-underline hover:underline cursor-pointer font-bold text-sm"
                            @click="googleTranslate(task.comment)"
                        >
                            Translate
                        </span>
                    </div>
                </div>
            </div>

            <div v-if="task.attachment" class="p-4">
                <img
                    v-if="!task.attachment.includes('.mp4')"
                    class="cursor-pointer w-32 h-32 attachment shadow rounded"
                    :src="parseAttachmentUrl(task.attachment)"
                    @click="() => (showAttachmentModal = true)"
                />

                <video v-if="task.attachment.includes('.mp4')" controls>
                    <source :src="parseAttachmentUrl(task.attachment)" type="video/mp4" />
                </video>

                <vue-easy-lightbox
                    move-disabled
                    :visible="showAttachmentModal && !task.attachment.includes('.mp4')"
                    :imgs="parseAttachmentUrl(task.attachment)"
                    :index="0"
                    @hide="showAttachmentModal = false"
                />
            </div>

            <div class="flex flex-row p-4">
                <div class="w-full text-sm text-grey-600">{{ createdAt }}</div>
                <div class="w-full text-right text-sm text-grey-400">{{ taskReference }}</div>
            </div>
        </div>

        <div v-else-if="task && queue.social && task.case.memberReports && socialReporter" class="p-2">
            <MemberCard
                :member="{
                    signedUrl: socialReporter.signedUrl + '=w192',
                    full_name: socialReporter.full_name,
                }"
                :selected="false"
                @click.native="selectSocialMember(socialReporter.accountUid)"
            />

            <div class="px-4">
                <p class="font-bold">Reported</p>
            </div>

            <MemberCard
                :member="{ signedUrl: task.socialProfile.avatarUrl + '=w192', full_name: task.socialProfile.firstName }"
                :selected="false"
            />

            <div class="m-2 p-2 border rounded-sm">
                <span>{{ task.case.memberReports.reports[0].reason }}</span>
            </div>

            <div class="flex flex-row p-4">
                <div class="w-full text-sm text-grey-600">{{ createdAt }}</div>
            </div>
        </div>

        <div v-else class="p-4 flex justify-center items-center h-32">There is no task for this queue.</div>
        <div v-if="task && queue.social && socialNotes.length > 0">
            <div class="pl-4 pr-4">
                <span class="text-xs font-bold">Notes:</span>
            </div>
            <table class="w-full">
                <tr class="border-b text-xs" style="border-color: #f2f0f1">
                    <th class="p-4 text-left"><span>User</span></th>
                    <th class="p-4 text-left"><span>Comment</span></th>
                    <th class="p-4 text-left"><span>Created At</span></th>
                </tr>
                <tr
                    v-for="(note, index) in socialNotes"
                    :key="index"
                    class="border-b text-xs"
                    style="border-color: #f2f0f1"
                >
                    <td class="p-4">{{ note.user }}</td>
                    <td class="p-4">
                        <span>{{ note.comment }}</span>
                    </td>
                    <td class="p-4">
                        <span>{{ time(note.createdAt) }}</span>
                    </td>
                </tr>
            </table>
        </div>
        <div v-if="task && queue.social">
            <input
                v-model="newNote"
                name="note"
                placeholder="Add notes"
                class="w-full border p-2 rounded focus:outline-none focus:shadow-inner"
            />
            <button
                v-if="task && queue.social"
                class="w-full text-white rounded-b p-4 focus:outline-none bg-grey-700"
                @click="saveNote"
            >
                Save
            </button>
        </div>
    </Panel>
</template>

<script>
    import VueEasyLightbox from 'vue-easy-lightbox';
    import Panel from '../../components/Panels/Panel';
    import MemberCard from './MemberCard';
    import { mapActions } from 'vuex';
    import { socialModerationAxios } from '../../../axios.config';

    export default {
        name: 'CurrentTask',

        components: {
            MemberCard,
            Panel,
            VueEasyLightbox,
        },

        props: {
            queue: {
                type: Object,
                required: true,
            },

            task: {
                type: Object,
                default: null,
            },

            selected: {
                type: Object,
                default: null,
            },

            taskTagPage: {
                type: Boolean,
                default: false,
                required: false,
            },
        },

        data() {
            return {
                googleTranslateUrl: 'https://translate.google.com/#auto/en/',
                showAttachmentModal: false,
                member: {},
                category: {
                    CATEGORY_UNSPECIFIED: 'UNSPECIFIED',
                    CATEGORY_EXPLICIT_NUDITY: 'EXPLICIT_NUDITY',
                    CATEGORY_SUGGESTIVE: 'SUGGESTIVE',
                    CATEGORY_VIOLENCE: 'VIOLENCE',
                    CATEGORY_VISUALLY_DISTURBING: 'VISUALLY_DISTURBING',
                    CATEGORY_RUDE_GESTURES: 'RUDE_GESTURES',
                    CATEGORY_DRUGS: 'DRUGS',
                    CATEGORY_TOBACCO: 'TOBACCO',
                    CATEGORY_ALCOHOL: 'ALCOHOL',
                    CATEGORY_GAMBLING: 'GAMBLING',
                    CATEGORY_HATE_SYMBOLS: 'HATE_SYMBOLS',
                },
                label: {
                    LABEL_UNSPECIFIED: 'UNSPECIFIED',
                    LABEL_NUDITY: 'NUDITY',
                    LABEL_GRAPHIC_MALE_NUDITY: 'GRAPHIC_MALE_NUDITY',
                    LABEL_GRAPHIC_FEMALE_NUDITY: 'GRAPHIC_FEMALE_NUDITY',
                    LABEL_SEXUAL_ACTIVITY: 'SEXUAL_ACTIVITY',
                    LABEL_ILLUSTRATED_EXPLICIT_NUDITY: 'ILLUSTRATED_EXPLICIT_NUDITY',
                    LABEL_ADULT_TOYS: 'ADULT_TOYS',
                    LABEL_FEMALE_SWIMWEAR_OR_UNDERWEAR: 'FEMALE_SWIMWEAR_OR_UNDERWEAR',
                    LABEL_MALE_SWIMWEAR_OR_UNDERWEAR: 'MALE_SWIMWEAR_OR_UNDERWEAR',
                    LABEL_PARTIAL_NUDITY: 'PARTIAL_NUDITY',
                    LABEL_BARECHESTED_MALE: 'BARECHESTED_MALE',
                    LABEL_REVEALING_CLOTHES: 'REVEALING_CLOTHES',
                    LABEL_SEXUAL_SITUATIONS: 'SEXUAL_SITUATIONS',
                    LABEL_GRAPHIC_VIOLENCE_OR_GORE: 'GRAPHIC_VIOLENCE_OR_GORE',
                    LABEL_PHYSICAL_VIOLENCE: 'PHYSICAL_VIOLENCE',
                    LABEL_WEAPON_VIOLENCE: 'WEAPON_VIOLENCE',
                    LABEL_WEAPONS: 'WEAPONS',
                    LABEL_SELF_INJURY: 'SELF_INJURY',
                    LABEL_EMACIATED_BODIES: 'EMACIATED_BODIES',
                    LABEL_CORPSES: 'CORPSES',
                    LABEL_HANGING: 'HANGING',
                    LABEL_AIR_CRASH: 'AIR_CRASH',
                    LABEL_EXPLOSIONS_AND_BLASTS: 'EXPLOSIONS_AND_BLASTS',
                    LABEL_MIDDLE_FINGER: 'MIDDLE_FINGER',
                    LABEL_DRUG_PRODUCTS: 'DRUG_PRODUCTS',
                    LABEL_DRUG_USE: 'DRUG_USE',
                    LABEL_PILLS: 'PILLS',
                    LABEL_DRUG_PARAPHERNALIA: 'DRUG_PARAPHERNALIA',
                    LABEL_TOBACCO_PRODUCTS: 'TOBACCO_PRODUCTS',
                    LABEL_SMOKING: 'SMOKING',
                    LABEL_DRINKING: 'DRINKING',
                    LABEL_ALCOHOLIC_BEVERAGES: 'ALCOHOLIC_BEVERAGES',
                    LABEL_GAMBLING: 'GAMBLING',
                    LABEL_NAZI_PARTY: 'NAZI_PARTY',
                    LABEL_WHITE_SUPREMACY: 'WHITE_SUPREMACY',
                    LABEL_EXTREMIST: 'EXTREMIST',
                },
                newNote: '',
            };
        },

        computed: {
            taskReference() {
                let str = this.task.id + '.';

                str = str + (this.task.first_member ? this.task.first_member.id.substring(0, 6) : '0') + '.';
                str = str + (this.task.second_member ? this.task.second_member.id.substring(0, 6) : '0');

                return str;
            },

            createdAt() {
                if (this.queue.social) {
                    if (this.task.case?.memberReports?.reports[0]) {
                        return moment(this.task.case?.memberReports?.reports[0].createdAt).fromNow();
                    }
                }

                if (!this.task.created_at) {
                    return '';
                }

                return moment(this.task.created_at).fromNow();
            },

            socialReporter() {
                if (!this.queue.social) {
                    return null;
                }

                if (this.task.case.memberReports.reports[0].reportingProfile) {
                    return {
                        signedUrl: this.task.case.memberReports.reports[0].reportingProfile.avatarUrl,
                        full_name:
                            this.task.case.memberReports.reports[0].reportingProfile.firstName +
                            ' ' +
                            this.task.case.memberReports.reports[0].reportingProfile.lastName,
                        username: this.task.case.memberReports.reports[0].reportingProfile.username,
                        accountUid: this.task.case.memberReports.reports[0].reportingAccountUid,
                    };
                }

                return null;
            },

            socialNotes() {
                if (!this.queue.social) {
                    return [];
                }

                return this.task.case.notes;
            },
        },

        methods: {
            ...mapActions(['handleError', 'alert']),

            async selectMember(member, task) {
                this.$emit('selected', member, task);
            },

            googleTranslate(message) {
                window.open(this.googleTranslateUrl + message);
            },

            parseAttachmentUrl(attachment) {
                let prefix = 's3://';

                if (attachment && attachment.includes(prefix)) {
                    attachment = attachment.replace(prefix, 'https://');
                }

                return attachment;
            },

            async saveNote() {
                if (this.newNote == '') {
                    this.alert({
                        message: 'Enter a note',
                        type: 'danger',
                    });
                    return;
                }
                try {
                    await socialModerationAxios.post('/internal/community/reports/create/create-case-note', {
                        case_id: this.task.case.caseId,
                        comment: this.newNote,
                    });
                    this.$emit('refreshTask');
                    this.newNote = '';
                } catch (reason) {
                    console.log(reason);
                }
            },

            time(time, format = 'DD/MM/YYYY HH:mm') {
                return moment(time).format(format);
            },
        },
    };
</script>

<style scoped>
    .attachment {
        object-fit: cover;
    }
</style>
