<template>
    <div class="md:flex sm:flex-wrap">
        <div
            class="py-4 px-3 border-b flex-auto text-center cursor-pointer"
            :class="isActive('member-details') ? 'border-pink-muzmatch' : ''"
            @click="$emit('setActive', 'member-details')"
        >
            <DashboardLabel class="cursor-pointer">Details</DashboardLabel>
        </div>

        <div
            class="py-4 px-3 border-b flex-auto text-center cursor-pointer"
            :class="isActive('photo-profile-history') ? 'border-pink-muzmatch' : ''"
            @click="$emit('setActive', 'photo-profile-history')"
        >
            <DashboardLabel class="cursor-pointer">Profile Content</DashboardLabel>
        </div>

        <div
            class="py-4 px-3 border-b flex-auto text-center cursor-pointer"
            :class="isActive('search-filters') ? 'border-pink-muzmatch' : ''"
            @click="$emit('setActive', 'search-filters')"
        >
            <DashboardLabel class="cursor-pointer">Filters</DashboardLabel>
        </div>

        <div
            class="py-4 px-3 border-b flex-auto text-center cursor-pointer"
            :class="isActive('reports-received') ? 'border-pink-muzmatch' : ''"
            @click="$emit('setActive', 'reports-received')"
        >
            <DashboardLabel class="cursor-pointer">Reports Received</DashboardLabel>
        </div>

        <div
            class="py-4 px-3 border-b flex-auto text-center cursor-pointer"
            :class="isActive('reports-made') ? 'border-pink-muzmatch' : ''"
            @click="$emit('setActive', 'reports-made')"
        >
            <DashboardLabel class="cursor-pointer">Reports Made</DashboardLabel>
        </div>

        <div
            class="py-4 px-3 border-b flex-auto text-center cursor-pointer"
            :class="isActive('issues') ? 'border-pink-muzmatch' : ''"
            @click="$emit('setActive', 'issues')"
        >
            <DashboardLabel class="cursor-pointer">Issues</DashboardLabel>
        </div>

        <div
            class="py-4 px-3 border-b flex-auto text-center cursor-pointer"
            :class="isActive('message-history') ? 'border-pink-muzmatch' : ''"
            @click="$emit('setActive', 'message-history')"
        >
            <DashboardLabel class="cursor-pointer">Marriage Chats</DashboardLabel>
        </div>

        <div
            class="py-4 px-3 border-b flex-auto text-center cursor-pointer"
            :class="isActive('social-chats') ? 'border-pink-muzmatch' : ''"
            @click="$emit('setActive', 'social-chats')"
        >
            <DashboardLabel class="cursor-pointer">Social Chats</DashboardLabel>
        </div>

        <div
            class="py-4 px-3 border-b flex-auto text-center cursor-pointer"
            :class="isActive('emails-history') ? 'border-pink-muzmatch' : ''"
            @click="$emit('setActive', 'emails-history')"
        >
            <DashboardLabel class="cursor-pointer">Emails</DashboardLabel>
        </div>

        <div
            class="py-4 px-3 border-b flex-auto text-center cursor-pointer"
            :class="isActive('transactions') ? 'border-pink-muzmatch' : ''"
            @click="$emit('setActive', 'transactions')"
        >
            <DashboardLabel class="cursor-pointer">Transactions</DashboardLabel>
        </div>

        <div
            class="py-4 px-3 border-b flex-auto text-center cursor-pointer"
            :class="isActive('logs') ? 'border-pink-muzmatch' : ''"
            @click="$emit('setActive', 'logs')"
        >
            <DashboardLabel class="cursor-pointer">Logs</DashboardLabel>
        </div>

        <div
            class="py-4 px-3 border-b flex-auto text-center cursor-pointer"
            :class="isActive('staff-logs') ? 'border-pink-muzmatch' : ''"
            @click="$emit('setActive', 'staff-logs')"
        >
            <DashboardLabel class="cursor-pointer">Staff Logs</DashboardLabel>
        </div>

        <div
            class="py-4 px-3 border-b flex-auto text-center cursor-pointer"
            :class="isActive('member-boosts') ? 'border-pink-muzmatch' : ''"
            @click="$emit('setActive', 'member-boosts')"
        >
            <DashboardLabel class="cursor-pointer">Profile Boosts</DashboardLabel>
        </div>

        <div
            class="py-4 px-3 border-b flex-auto text-center cursor-pointer"
            :class="isActive('member-discover') ? 'border-pink-muzmatch' : ''"
            @click="$emit('setActive', 'member-discover')"
        >
            <DashboardLabel class="cursor-pointer">Discover</DashboardLabel>
        </div>

        <div
            class="py-4 px-3 border-b flex-auto text-center cursor-pointer"
            :class="isActive('member-ic') ? 'border-pink-muzmatch' : ''"
            @click="$emit('setActive', 'member-ic')"
        >
            <DashboardLabel class="cursor-pointer">Instant Chats</DashboardLabel>
        </div>

        <div
            class="py-4 px-3 border-b flex-auto text-center cursor-pointer"
            :class="isActive('approval-history') ? 'border-pink-muzmatch' : ''"
            @click="$emit('setActive', 'approval-history')"
        >
            <DashboardLabel class="cursor-pointer">Approval History</DashboardLabel>
        </div>
        <div
            class="py-4 px-3 border-b flex-auto text-center cursor-pointer"
            :class="isActive('icebreakers') ? 'border-pink-muzmatch' : ''"
            @click="$emit('setActive', 'icebreakers')"
        >
            <DashboardLabel class="cursor-pointer">Icebreakers</DashboardLabel>
        </div>
    </div>
</template>

<script>
    import DashboardLabel from '../Labels/DashboardLabel';

    export default {
        name: 'TabsList',

        components: {
            DashboardLabel,
        },

        props: {
            current: {
                type: String,
                required: true,
            },
        },

        methods: {
            isActive(type) {
                return type === this.current;
            },
        },
    };
</script>
