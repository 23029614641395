<template>
    <div>
        <button
            class="text-white rounded px-4 py-2"
            :class="page == 1 ? 'bg-pink-lighter' : 'bg-pink-muzmatch'"
            @click="previousPage()"
        >
            Prev
        </button>
        Page: {{ page }}
        <button class="bg-pink-muzmatch text-white rounded px-4 py-2" @click="nextPage()">Next</button>
        <p v-if="totalResults > 0">
            {{ Math.round((total / totalResults) * 100) }}% ({{ total }} / {{ totalResults }})
        </p>
        <div
            v-for="(photos, idx) in chunkedMedia"
            v-show="chunkedMedia.length"
            :key="idx"
            class="flex w-full bg-red"
            :class="category == 1 ? 'bg-red' : category == 2 ? 'bg-yellow' : category == 3 ? 'bg-green' : ''"
        >
            <div v-for="(photo, indx) in photos" :key="indx" class="w-1/6 mx-1">
                <face-photo :photo="photo" />
            </div>
        </div>
        <div v-if="!chunkedMedia.length" class="flex w-full">
            <div class="w-full mx-1 content-center py-10">
                <p>No images found.</p>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import { dashboardAxios } from '../../../axios.config';
    import FacePhoto from './FacePhoto';

    export default {
        name: 'FacePager',

        components: {
            FacePhoto,
        },

        props: {
            category: {
                required: true,
                type: Number,
            },

            search: {
                required: true,
                type: Object,
            },

            boundaries: {
                required: true,
                type: Object,
            },

            totalResults: {
                required: true,
                type: Number,
            },
        },

        data() {
            return {
                page: 1,
                total: 0,
                media: [],
            };
        },

        computed: {
            chunkedMedia() {
                return _.chunk(this.media, 6);
            },
        },

        methods: {
            nextPage() {
                this.page++;
                this.getMedia();
            },

            previousPage() {
                if (this.page > 0) {
                    if (this.page != 1) {
                        this.page--;
                    }
                    this.getMedia();
                }
            },

            async getMedia() {
                try {
                    let path = this.generateMediaUrl();

                    let parameters = {
                        page: this.page,
                        faceCountRange: [this.boundaries.faceCountRangeMin, this.boundaries.faceCountRangeMax],
                        faceScoreRange: [this.boundaries.faceScoreRangeMin, this.boundaries.faceScoreRangeMax],
                        faceAreaRange: [this.boundaries.faceAreaRangeMin, this.boundaries.faceAreaRangeMax],
                        faceCountSortColumn: this.search.faceCountSortColumn,
                        faceCountSortOrder: this.search.faceCountSortOrder,
                        inclusive: false,
                        approved: this.search.approved,
                        approvedPhotos: this.search.approvedPhotos,
                        interval: this.search.interval,
                        rotated: this.search.rotated,
                        category: this.category,
                    };

                    if (this.category == 1) {
                        parameters.faceCountRange[1] = parseFloat(this.search.borderA.faceCount);
                        parameters.faceScoreRange[1] = parseFloat(this.search.borderA.faceScore);
                        parameters.faceAreaRange[1] = parseFloat(this.search.borderA.faceArea);
                    }

                    if (this.category == 2) {
                        parameters.faceCountRange[0] = parseFloat(this.search.borderA.faceCount);
                        parameters.faceScoreRange[0] = parseFloat(this.search.borderA.faceScore);
                        parameters.faceAreaRange[0] = parseFloat(this.search.borderA.faceArea);
                        parameters.faceCountRange[1] = parseFloat(this.search.borderB.faceCount);
                        parameters.faceScoreRange[1] = parseFloat(this.search.borderB.faceScore);
                        parameters.faceAreaRange[1] = parseFloat(this.search.borderB.faceArea);
                        parameters.inclusive = true;
                    }

                    if (this.category == 3) {
                        parameters.faceCountRange[0] = parseFloat(this.search.borderB.faceCount);
                        parameters.faceScoreRange[0] = parseFloat(this.search.borderB.faceScore);
                        parameters.faceAreaRange[0] = parseFloat(this.search.borderB.faceArea);
                    }

                    let response = await dashboardAxios.post(path, parameters);

                    this.media = response.data.result;

                    let response2 = await dashboardAxios.post(path + '/count', parameters);

                    this.total =
                        response2.data.result && response2.data.result[0] && response2.data.result[0].count
                            ? parseFloat(response2.data.result[0].count)
                            : 0;
                    let total = this.total;

                    this.$emit('updateTotals', {
                        category: 'category' + this.category,
                        total: total,
                    });

                    let response3 = await dashboardAxios.post(path + '/query', parameters);
                    console.log(response3.data.result[0].query);
                } catch (error) {
                    console.log(error);
                }
            },

            generateMediaUrl(url = '/v1/media/face-photos/page') {
                return url;
            },
        },
    };
</script>
