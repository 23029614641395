<template>
    <Panel :title="`Review - ${reviewAuthor}`">
        <div class="px-4 pt-4 pb-2 overflow-y-scroll chat-messages text-sm h-full bg-grey-50">
            <div class="review-info">
                <div>
                    <svg
                        v-for="index in 5"
                        :key="index"
                        class="w-5 h-5 text-pink-muzmatch inline-block"
                        :class="{ 'fill-current': index <= stars }"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                        />
                    </svg>
                </div>
                <div class="text-lg my-4">
                    <span class="font-semibold">{{ review.reviewTitle }}</span>
                    {{ review.reviewContent }}
                </div>
                <div class="flex items-center">
                    <div class="mr-4">
                        <platform-icon custom-classes="h-4 w-4 text-grey-muzmatch" :platform="review.platform" />
                    </div>
                    <div v-if="countryName" class="mr-4">
                        <span class="text-sm text-grey-muzmatch">{{ countryEmoji }} {{ countryName }}</span>
                    </div>
                    <div class="mr-4">
                        <span class="text-sm text-grey-muzmatch">{{ review.reviewTimestamp | parseDate }}</span>
                    </div>
                    <div v-if="review.appVersion">
                        <span class="text-sm text-grey-muzmatch">Ver {{ review.appVersion }}</span>
                    </div>
                    <div class="flex-grow text-right">
                        <span
                            class="text-pink-muzmatch no-underline hover:underline cursor-pointer font-bold text-sm"
                            @click="googleTranslate"
                        >
                            Translate
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-2 bg-grey-50">
            <div class="w-full text-right pr-1 pb-1 text-grey-muzmatch">{{ replyLength }} / {{ limit }}</div>
            <textarea
                v-model="reply"
                :maxlength="limit"
                :placeholder="`Reply to review on the ${appStoreName}`"
                type="text"
                class="shortcut-accessible w-full rounded border h-48 border-grey-200 p-4 mb-1 focus:outline-none"
                :class="[contextType, contextAction, contextDetail]"
            />

            <button
                class="rounded w-full text-white p-4 focus:outline-none cursor-pointer"
                :class="isLoading ? 'bg-pink-light' : 'bg-pink-muzmatch'"
                @click="sendReply"
            >
                <span v-if="!isLoading">Send Reply</span>
                <icon v-else class="animate-spin ml-4" icon="spinner" />
            </button>
        </div>
    </Panel>
</template>

<script>
    import moment from 'moment';
    import Panel from './Panel';
    import countries from '../../assets/json/countries.json';
    import PlatformIcon from '../../pages/AppVersions/PlatformIcon';
    import { dashboardAxios } from '../../../axios.config';
    import { mapActions } from 'vuex';
    import { captureException } from '@sentry/vue';

    export default {
        components: {
            Panel,
            PlatformIcon,
        },
        filters: {
            parseDate(date) {
                return moment(date).format('ddd Do MMM YYYY');
            },
        },
        props: {
            review: {
                type: Object,
                required: true,
            },
            contextType: {
                type: String,
                required: true,
            },
            contextAction: {
                type: String,
                required: true,
            },
            contextDetail: {
                type: String,
                default: '',
            },
        },
        data() {
            return {
                countries,
                isLoading: false,
                reply: 'Salaam, ',
                googleTranslateUrl: 'https://translate.google.com/#auto/en/',
                googlePlayLimit: 350,
                appStoreLimit: 5970,
            };
        },
        computed: {
            stars() {
                return parseInt(this.review.reviewRating, 10);
            },
            countryEmoji() {
                if (!this.review.reviewAuthorCountry) return null;
                return this.review.reviewAuthorCountry
                    .toUpperCase()
                    .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397));
            },
            countryName() {
                if (!this.review.reviewAuthorCountry || this.review.reviewAuthorCountry.length > 2) return null;
                return this.countries.find((country) => country.code === this.review.reviewAuthorCountry.toUpperCase())[
                    'name'
                ];
            },
            reviewAuthor() {
                return this.review.reviewAuthor || '';
            },
            limit() {
                return this.review.platform === 'android' ? this.googlePlayLimit : this.appStoreLimit;
            },
            replyLength() {
                return this.reply.length;
            },
            appStoreName() {
                return this.review.platform === 'ios' ? 'Apple App Store' : 'Google Play Store';
            },
        },
        methods: {
            ...mapActions(['alert']),
            async sendReply() {
                const replyLength = this.reply.length;
                if (replyLength === 0 || replyLength > this.limit) {
                    this.alert({
                        message:
                            replyLength === 0
                                ? 'Please fill out the review field'
                                : `Reply too long. Please make sure the reply is not longer ${this.limit} characters`,
                        type: 'danger',
                    });
                    return;
                }

                this.isLoading = true;

                try {
                    await dashboardAxios.put(`/v1/tasks/app-reviews/${this.review.id}`, {
                        reply: this.reply,
                    });

                    this.alert({
                        message: 'Reply sent successfully',
                        type: 'success',
                    });

                    this.reply = 'Salam, ';
                    this.$emit('refreshTask');
                } catch (err) {
                    this.alert({
                        message: 'Unable to reply to review',
                        type: 'danger',
                    });

                    captureException(err);
                }

                this.isLoading = false;
            },
            googleTranslate() {
                window.open(this.googleTranslateUrl + this.review.reviewContent);
            },
        },
    };
</script>

<style>
    .chat-height {
        height: calc(100% - 170px);
    }

    .bg-pink-muzmatch-lighter {
        background-color: #e33e6a;
    }
</style>
