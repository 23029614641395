<template>
    <div class="absolute alerts-wrapper z-50">
        <div
            v-for="(alert, idx) in alerts"
            :key="idx"
            class="p-5 rounded mt-2 relative shadow-lg"
            :class="alertClass(alert)"
        >
            <div class="message font-bold">
                {{ alert.message }}
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'Alerts',

        computed: {
            ...mapGetters({
                alerts: 'getAlerts',
            }),
        },

        methods: {
            alertClass(alert) {
                if (!alert.type) {
                    return 'bg-grey-200';
                }

                if (alert.type === 'success') {
                    return 'bg-green-lightest text-green-darker';
                }

                if (alert.type === 'danger') {
                    return 'bg-red-lightest text-red-darker';
                }
            },
        },
    };
</script>

<style scoped>
    .alerts-wrapper {
        top: 10px;
        right: 15px;
        position: fixed;
    }

    .message {
        max-width: 15rem;
    }
</style>
