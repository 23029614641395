<script>
    import CampaignListItem from './CampaignListItem';
    import Vue from 'vue';

    export default {
        name: 'ListBuilder',

        components: {
            CampaignListItem,
        },
        props: {
            list: {
                type: Object,
                required: true,
            },
            counter: {
                type: Number,
                required: true,
            },
        },

        render(createElement) {
            let listItem = {
                    label: 'ListItem',
                    tagName: 'li',
                    id: 5,
                    textNode: '',
                },
                options = [
                    {
                        label: 'Ordered list',
                        tag: 'ol',
                    },
                    {
                        label: 'Unordered list',
                        tag: 'ul',
                    },
                ],
                thisVar = this,
                menu = options.map((option) =>
                    createElement(
                        'span',
                        {
                            on: {
                                click() {
                                    Vue.set(thisVar.list, 'tagName', option.tag);
                                },
                            },
                            class: {
                                'mx-3': true,
                                'cursor-pointer': true,
                            },
                        },
                        option.label,
                    ),
                ),
                content = [
                    createElement(
                        'div',
                        {
                            class: {
                                'mb-3': true,
                                flex: true,
                            },
                        },

                        [
                            createElement(
                                'div',
                                {
                                    class: {
                                        'w-1/2': true,
                                    },
                                },

                                [
                                    createElement('icon', {
                                        attrs: {
                                            icon: 'plus',
                                        },
                                        class: {
                                            'mr-3': true,
                                            'cursor-pointer': true,
                                        },
                                        on: {
                                            click: () => {
                                                Vue.set(this.list, 'children', [...this.list.children, listItem]);
                                            },
                                        },
                                    }),
                                    'Add list item ',
                                ],
                            ),

                            createElement(
                                'div',
                                {
                                    class: {
                                        'w-1/2': true,
                                        flex: true,
                                        'justify-end': true,
                                    },
                                },
                                menu,
                            ),
                        ],
                    ),
                ];

            if (this.list.children.length > 0) {
                content = content.concat(
                    this.list.children.map((child, index) => {
                        return createElement('CampaignListItem', {
                            attrs: {
                                index: index,
                                value: child.textNode,
                            },
                            on: {
                                'list-value-updated': (params) => {
                                    Vue.set(this.list.children[params.index], 'textNode', params.value);
                                },
                            },
                        });
                    }),
                );
            }

            return createElement(
                'div',
                {
                    class: {
                        'p-3': true,
                    },
                },
                content,
            );
        },
    };
</script>
