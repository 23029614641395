<template>
    <DashboardSectionCard class="mb-4">
        <a id="debug-options"></a>

        <div class="p-4 border-b cursor-pointer" @click.self="show = !show">
            <DashboardLabel @click.native="show = !show">Developer Debug Options</DashboardLabel>

            <icon class="ml-2" :icon="show ? 'chevron-up' : 'chevron-down'" @click="show = !show"></icon>
        </div>

        <div v-if="show" class="p-4">
            <DebugOptionOther :member="member" @refresh="$emit('refresh')" />
            <DebugOptionCredit class="mt-10" :member="member" @refresh="$emit('refresh')" />
            <DebugOptionSwipeInterstitial class="mt-10" :member="member" />
            <DebugOptionRemoteConfig class="mt-10" :member="member" />
        </div>
    </DashboardSectionCard>
</template>

<script>
    import DashboardSectionCard from '@/components/Cards/DashboardSectionCard';
    import DashboardLabel from '@/components/Labels/DashboardLabel';
    import DebugOptionSwipeInterstitial from '@/components/Cards/DebugOption/DebugOptionSwipeInterstitial';
    import DebugOptionRemoteConfig from '@/components/Cards/DebugOption/DebugOptionRemoteConfig';
    import DebugOptionCredit from '@/components/Cards/DebugOption/DebugOptionCredit';
    import DebugOptionOther from '@/components/Cards/DebugOption/DebugOptionOther';
    import { mapActions } from 'vuex';

    export default {
        name: 'DebugOptionSection',
        components: {
            DashboardSectionCard,
            DashboardLabel,
            DebugOptionSwipeInterstitial,
            DebugOptionRemoteConfig,
            DebugOptionCredit,
            DebugOptionOther,
        },

        props: {
            member: {
                type: Object,
                required: false,
            },
        },

        data() {
            return {
                show: false,
            };
        },

        methods: {
            ...mapActions({
                handleError: 'handleError',
                alert: 'alert',
            }),
        },
    };
</script>

<style scoped></style>
