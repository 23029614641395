<template>
    <div class="contents">
        <td class="px-4 py-2" @click="selectAll(dataset.dataset_name)">{{ dataset.dataset_name }}</td>
        <TableCell
            v-for="(cell, index) in cells"
            :key="changed + index"
            :value="dataset[cell.name]"
            :name="cell.name"
            :class="{ 'bg-pink-dark text-white': selectedDatasets.includes(cell.name) }"
            :dataset="dataset.dataset_name"
            @select="selectDataset"
        />
        <td class="px-4 py-2">
            <SecondaryButton
                class="mr-1"
                text="Increment"
                :confirmed="true"
                :handle-error="handleButtonError"
                confirm-message="Are you sure you want to increase the version?"
                :handle-click="incrementVersion(dataset.dataset_name)"
                :disabled="!(selectedDatasets.length > 0)"
            ></SecondaryButton>
        </td>
    </div>
</template>

<script>
    import { dashboardAxios } from '../../../axios.config';
    import { mapActions } from 'vuex';
    import SecondaryButton from '../../components/Buttons/SecondaryButton';
    import TableCell from './TableCell';
    import EventBus from '../../helpers/eventBus';

    export default {
        name: 'TableRow',
        components: {
            SecondaryButton,
            TableCell,
        },
        props: {
            dataset: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                selectedDatasets: [],
                changed: false,
            };
        },

        computed: {
            cells() {
                return [
                    {
                        name: 'version_en',
                        selected: false,
                    },
                    {
                        name: 'version_engb',
                        selected: false,
                    },
                    {
                        name: 'version_de',
                        selected: false,
                    },
                    {
                        name: 'version_fr',
                        selected: false,
                    },
                    {
                        name: 'version_id',
                        selected: false,
                    },
                    {
                        name: 'version_tr',
                        selected: false,
                    },
                    {
                        name: 'version_nl',
                        selected: false,
                    },
                    {
                        name: 'version_ms',
                        selected: false,
                    },
                    {
                        name: 'version_es',
                        selected: false,
                    },
                    {
                        name: 'version_ar',
                        selected: false,
                    },
                    {
                        name: 'version_hi',
                        selected: false,
                    },
                    {
                        name: 'version_fa',
                        selected: false,
                    },
                    {
                        name: 'version_ur',
                        selected: false,
                    },
                    {
                        name: 'version_bn',
                        selected: false,
                    },
                    {
                        name: 'version_ru',
                        selected: false,
                    },
                    {
                        name: 'version_it',
                        selected: false,
                    },
                ];
            },
        },
        mounted() {
            EventBus.$on('selectedLanguage', (language) => {
                var colName = 'version_' + language.toLowerCase();
                this.selectDataset({ version: colName, dataset: this.dataset.dataset_name });
            });

            EventBus.$on('synchronize-row', (dataset) => {
                if (dataset.name === this.dataset.dataset_name) {
                    this.selectDataset({ version: dataset.version, dataset: dataset, name });
                }
            });
        },
        methods: {
            ...mapActions({ handleError: 'handleError' }),
            async handleButtonError(error) {
                this.handleError({ error });
            },
            incrementVersion(name) {
                return async () => {
                    var locale = this.selectedDatasets;

                    var languages = locale.map((l) => l.replace('version_', '').toUpperCase());

                    var response = await dashboardAxios.post('/v1/datasets/increment-row-version', {
                        dataset_name: [name],
                        locale: languages,
                    });

                    var deletedKeys = response.data.result['deleted-keys'];

                    EventBus.$emit('deletedKeys', deletedKeys);

                    this.$emit('load-datasets');
                    this.deleteDatasets();
                };
            },
            selectDataset(selected) {
                var foundAt;

                this.selectedDatasets.forEach((dataset, index) => {
                    if (dataset === selected.version) {
                        foundAt = index;
                    }
                });

                if (foundAt || foundAt === 0) {
                    EventBus.$emit('delete', {
                        dataset: this.dataset.dataset_name,
                        version: selected.version,
                    });

                    this.$delete(this.selectedDatasets, foundAt);
                } else {
                    EventBus.$emit('select', {
                        dataset: this.dataset.dataset_name,
                        version: selected.version,
                    });

                    this.$set(this.selectedDatasets, this.selectedDatasets.length, selected.version);
                }

                this.changed = !this.changed;
            },
            selectAll(dataset) {
                if (this.selectedDatasets.length > 0) {
                    this.selectedDatasets = [];
                    this.changed = !this.changed;

                    this.cells.forEach((cell) => {
                        EventBus.$emit('delete', {
                            dataset: dataset,
                            version: cell.name,
                        });
                    });

                    return;
                }

                this.cells.forEach((cell) => {
                    this.selectDataset({ version: cell.name, dataset: dataset });
                });
            },
            deleteDatasets() {
                this.selectedDatasets.forEach((dataset, index) => {
                    EventBus.$emit('delete', {
                        dataset: this.dataset.dataset_name,
                        version: dataset,
                    });
                });

                this.selectedDatasets = [];
                this.changed = !this.changed;
            },
        },
    };
</script>

<style scoped>
    .contents {
        display: contents;
    }
</style>
