<template>
    <Modal size="m" :click-overlay="() => $emit('close-modal')" :no-padding="true">
        <div slot="body">
            <Panel
                v-if="!isLoading"
                title="JSON Cache"
                :has-margin="false"
                :custom-classes="['bg-pink-muzmatch', 'text-white']"
            >
                <div slot="header" class="ml-auto p-2">
                    <button
                        v-if="hasAdminPermission"
                        class="w-full rounded bg-white text-center text-xs text-black p-2 font-semibold"
                        @click="rebuildCache"
                    >
                        Rebuild Cache
                    </button>
                </div>
                <pre class="p-3 text-sm">{{ globalJsonFilter | pretty }}</pre>
            </Panel>
            <div v-else class="flex flex-col items-center justify-center py-8">
                <icon icon="list" class="loading-animation text-4xl mb-2 text-pink-muzmatch mr-4"></icon>
                <span class="text-lg mt-2 font-bold">Loading...</span>
            </div>
        </div>
    </Modal>
</template>

<script>
    import Panel from '../../components/Panels/Panel';
    import M2Button from '../../components/Buttons/M2Button';
    import { mapActions } from 'vuex';
    import { dashboardAxios } from '../../../axios.config';

    export default {
        name: 'JsonCacheModal',
        components: {
            Panel,
            M2Button,
        },
        filters: {
            pretty: function (value) {
                return JSON.stringify(value, null, 2);
            },
        },
        props: {
            hasAdminPermission: {
                type: Boolean,
                required: true,
            },
        },
        data() {
            return {
                isLoading: false,
                globalJsonFilter: [],
            };
        },
        created() {
            this.loadCacheConfig();
        },
        methods: {
            ...mapActions(['handleError', 'alert', 'confirmDelete']),

            async loadCacheConfig() {
                this.isLoading = true;

                try {
                    let response = await dashboardAxios.get('/v1/remote-config/cache');
                    this.globalJsonFilter = response.data.result;
                } catch (e) {
                    await this.handleError({ error: e });
                }

                this.isLoading = false;
            },

            async rebuildCache() {
                const text = 'Are you sure you want to rebuild the cache?';
                if (!(await this.confirmDelete({ vm: this, text: text }))) return;

                this.isLoading = true;

                try {
                    let response = await dashboardAxios.post('/v1/remote-config/rebuild-cache');
                    this.globalJsonFilter = response.data.result;
                    await this.alert({ message: 'Successfully rebuilt the cache', type: 'success' });
                } catch (e) {
                    await this.handleError({ error: e });
                }

                this.isLoading = false;
            },
        },
    };
</script>

<style scoped></style>
