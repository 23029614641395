<template>
    <div class="mb-2">
        <div
            :contenteditable="isEditing"
            class="p-3 inline-block w-full cursor-pointer rounded-tr-lg text-black text-left rounded-t-lg rounded-b-sm break-words whitespace-pre-wrap"
            :class="[{ 'right-to-left': rtl }, getMessageTypeClass]"
            @click="edit"
            @input="onInput"
            @blur="blurred"
        >
            <span v-if="isEditing">{{ message }}</span>
            <span v-else v-html="renderMarkdown(message)"></span>
        </div>
        <link-tile v-if="link" :url="link" />
    </div>
</template>

<script>
    import LinkTile from './LinkTile.vue';

    export default {
        components: {
            LinkTile,
        },
        props: {
            message: {
                type: String,
                required: true,
            },
            type: {
                type: String,
                default: 'TEXT',
            },
            isEditing: {
                type: Boolean,
                default: false,
            },
            language: {
                type: String,
                default: 'EN',
            },
            rtl: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                update: null,
                updateType: null,
                link: null,
            };
        },
        computed: {
            getMessageTypeClass() {
                if (this.type === 'ADMIN') {
                    return 'font-semibold text-center bg-white';
                }

                return 'bg-grey-200 pb-5 text-sm';
            },
        },
        watch: {
            message() {
                this.update = this.message;
                this.link = null;
            },
            language() {
                this.link = null;
            },
        },
        mounted() {
            this.update = this.body;
        },
        methods: {
            edit(e) {
                if (e.target.tagName === 'A') return;

                this.$emit('editing');
            },
            renderMarkdown(text) {
                this.linkDetector(text);

                return text
                    .replace(/\*(.*?)\*/gm, '<strong>$1</strong>')
                    .replace(/(?<!https?:\/\/[^\s]+)_(.*?)_/gm, '<em>$1</em>')
                    .replace(/(https?:\/\/[^\s]+)/g, "<a class='underline' href=$1 target='_blank'>$1</a>");
            },
            linkDetector(text) {
                const match = text.match(/(https?:\/\/[^\s]+)/g);

                if (!match) return;

                this.link = match[0];
            },
            onInput(e) {
                this.update = e.target.innerText;
            },
            blurred() {
                if (this.update !== this.body) {
                    this.$emit('blurred');
                }
            },
        },
    };
</script>

<style scoped>
    :empty {
        display: block;
        height: 1em;
    }

    .right-to-left {
        direction: rtl;
        text-align: right;
    }
</style>
