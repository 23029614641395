import moment from 'moment';

const time = (date, format) => {
    return moment(date).format(format);
};

const resolvePhotoUrl = (url, hashMemberID, type, size) => {
    if (!url) {
        return require('../assets/mm2.png');
    }

    if (!size) {
        size = '@2x';
    }

    let prefix = `https://${process.env.VUE_APP_CDN_DOMAIN}/media/${hashMemberID}/profile/image`;

    return `${prefix}/${type ? type : 'portrait'}/${url}${size}.jpg`;
};

const resolveVerificationPhotoUrl = (url, hashMemberID, type, size) => {
    if (!url) {
        return require('../assets/mm2.png');
    }

    if (!size) {
        size = '@2x';
    }

    let prefix = `https://${process.env.VUE_APP_CDN_DOMAIN}/media/${hashMemberID}/verification/image`;

    return `${prefix}/${type ? type : 'portrait'}/${url}${size}.jpg`;
};

const resolveAudioUrl = (url, hashMemberID) => {
    if (!url) {
        return require('../assets/mm2.png');
    }

    let prefix = `https://${process.env.VUE_APP_CDN_DOMAIN}/media/${hashMemberID}/profile/audio`;

    return `${prefix}/${url}.m4a`;
};

const resolveVideoUrl = (url, hashMemberID) => {
    if (!url) {
        return require('../assets/mm2.png');
    }

    let prefix = `https://${process.env.VUE_APP_CDN_DOMAIN}/media/${hashMemberID}/profile/video`;

    return `${prefix}/${url}.mp4`;
};

const formatDuplicateMembers = (members) => {
    if (!members) {
        return;
    }

    return members;
};

const formatTimestamp = (key, value) => {
    if (
        ![
            'timestamp',
            'boughtat',
            'expirydate',
            'lastvalidated',
            'refundedat',
            'createdat',
            'consentedattimestamp',
        ].includes(key.toLowerCase().replace(/[^a-z0-9\s-]/gi, ''))
    ) {
        return value;
    }

    const date = moment(value);

    return date.isValid() ? date.format('DD/MM/YYYY HH:mm:ss') : value;
};

export const validateEmail = (email) => {
    return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export {
    time,
    resolvePhotoUrl,
    resolveVerificationPhotoUrl,
    resolveAudioUrl,
    resolveVideoUrl,
    formatDuplicateMembers,
    formatTimestamp,
};
