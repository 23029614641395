<template>
    <Modal v-if="showModal" :click-overlay="closeModal" @close="closeModal">
        <template #header>
            <IconButton rounded class="float-right right-0 top-0 absolute mt-2 mr-2" @click="closeModal">
                Close
            </IconButton>
        </template>

        <template #body>
            <div class="flex flex-col justify-center items-center w-full h-full">
                <DashboardLabel class="m-5 block">
                    {{
                        currentEmail(email, attachments).length > 1
                            ? 'Attachment ' + adjustedIndex + '/' + currentEmail(email, attachments).length
                            : 'Attachment'
                    }}
                </DashboardLabel>

                <img
                    v-if="isImage(currentEmail(email, attachments)[currentIndex])"
                    class="m-5 object-contain border border-2 border-grey rounded-lg m-auto block"
                    :class="currentEmail(email, attachments).length > 1 ? 'max-h-80' : 'max-h-90'"
                    :src="parseAttachmentUrl(currentEmail(email, attachments)[currentIndex])"
                    alt="Attachment Image"
                />

                <video
                    v-if="isVideo(currentEmail(email, attachments)[currentIndex])"
                    class="m-5 object-contain border border-2 border-grey rounded-lg m-auto block"
                    :class="currentEmail(email, attachments).length > 1 ? 'max-h-80' : 'max-h-90'"
                    :src="parseAttachmentUrl(currentEmail(email, attachments)[currentIndex])"
                    controls
                />

                <div v-if="currentEmail(email, attachments).length > 1" class="flex flex-row w-full my-5">
                    <div class="w-full text-center">
                        <icon
                            icon="chevron-left"
                            :class="isFirstAttachment ? 'text-grey-light cursor-not-allowed' : 'text-pink-muzmatch'"
                            class="cursor-pointer ml-1 text-3xl"
                            @click="getPreviousAttachment"
                        />
                    </div>

                    <div class="w-full text-center">
                        <icon
                            icon="chevron-right"
                            :class="isLastAttachment ? 'text-grey-light cursor-not-allowed' : 'text-pink-muzmatch'"
                            class="cursor-pointer ml-1 text-3xl"
                            @click="getNextAttachment"
                        />
                    </div>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script>
    import DashboardLabel from '../Labels/DashboardLabel';
    import EventBus from '../../helpers/eventBus';

    export default {
        name: 'AttachmentModal',

        components: {
            DashboardLabel,
        },

        props: {
            showModal: {
                type: Boolean,
                required: true,
            },
            attachments: {
                type: Array,
                required: true,
            },
            currentIndex: {
                type: Number,
                required: true,
            },
            email: {
                type: Object,
                required: true,
            },
            isFirstAttachment: {
                type: Boolean,
                required: true,
            },
            isLastAttachment: {
                type: Boolean,
                required: true,
            },
        },

        computed: {
            adjustedIndex() {
                return this.currentIndex + 1;
            },
        },

        methods: {
            currentEmail(email, attachments) {
                return attachments.filter((attachment) => {
                    return attachment.emailId === email.emailId;
                });
            },

            parseAttachmentUrl(attachment) {
                let prefix = 's3://';
                let url = _.get(attachment, 'attachmentUrl', '');

                if (url.includes(prefix)) {
                    url = url.replace(prefix, 'https://');
                }

                return url;
            },

            closeModal() {
                this.$emit('closeModal');
            },

            getPreviousAttachment() {
                this.$emit('getPreviousAttachment');
            },

            getNextAttachment() {
                this.$emit('getNextAttachment');
            },

            isVideo(attachment) {
                return attachment ? attachment.type.split('/')[0] === 'video' : false;
            },
            isImage(attachment) {
                return attachment ? attachment.type.split('/')[0] === 'image' : false;
            },
        },
    };
</script>

<style scoped>
    .object-contain {
        object-fit: contain;
    }

    .max-h-80 {
        max-height: 80%;
    }

    .max-h-90 {
        max-height: 90%;
    }
</style>
