<template>
    <div class="py-5">
        <Panel
            v-if="typeof step !== 'undefined' && step !== null && step.messageType !== 'NO_MESSAGE'"
            :title="translationTitle"
        >
            <div slot="header" :key="headerKeyRefresh" class="ml-auto p-2 max-width-70">
                <dropdown
                    v-if="stepFilter.length"
                    class="float-right text-sm"
                    placeholder="Select a message"
                    :options="stepFilter"
                    :default="stepFilterDefault"
                    :white-background="true"
                    :is-capitalize="false"
                    :is-disabled="parentCanSave"
                    @updated="selectMessage"
                />
            </div>
            <div id="translation-table-wrapper" class="p-4 mr-4">
                <table v-if="step" :key="tableKeyRefresh" class="table">
                    <tr>
                        <th>Element</th>
                        <th v-for="language in previewLanguages" :key="language.name">
                            <span class="float-left flag-language mr-2">
                                <img class="w-4 mt-1" :src="language.path" :alt="language.name" />
                            </span>
                            <span class="float-left mt-1">{{ language.title }}</span>
                            <span v-if="language.name !== 'EN'" class="float-right">
                                <button
                                    v-if="doesKeyExist(language.name)"
                                    type="button"
                                    class="delete-btn float-right ml-2 p-2 pt-1 text-white bg-grey-300 hover:bg-pink-muzmatch"
                                    @click="deleteFlagColumn(language.name)"
                                >
                                    <span>x</span>
                                </button>
                            </span>
                        </th>
                    </tr>
                    <template v-if="step.messageType === 'IN_APP' || step.messageType === 'PUSH'">
                        <tr>
                            <td class="capitalize">Push Title</td>
                            <td v-for="(language, languageIndex) in previewLanguages" :key="languageIndex">
                                <translation-element-box
                                    :body="getPushKey(language.name, 'pushTitle')"
                                    :rtl="language.rtl"
                                    @updated-body="updatedBody('NOTIFICATION', 'Title', language.name, $event)"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td class="capitalize">Push Body</td>
                            <td v-for="(language, languageIndex) in previewLanguages" :key="languageIndex">
                                <translation-element-box
                                    :body="getPushKey(language.name, 'pushBody')"
                                    :rtl="language.rtl"
                                    @updated-body="updatedBody('NOTIFICATION', 'Body', language.name, $event)"
                                />
                            </td>
                        </tr>
                    </template>
                    <template v-if="step.messageType === 'EMAIL'">
                        <tr>
                            <td class="capitalize">Email Subject</td>
                            <td v-for="(language, languageIndex) in previewLanguages" :key="languageIndex">
                                <translation-element-box
                                    :body="getPushKey(language.name, 'subject')"
                                    :rtl="language.rtl"
                                    @updated-body="updatedEmailBody(language.name, $event, 'SUBJECT')"
                                />
                            </td>
                        </tr>
                    </template>
                    <template v-if="step.messageType === 'IN_APP' || step.messageType === 'IN_APP_NO_PUSH'">
                        <tr
                            v-for="(message, messageIndex) in step[largestMessageLanguage].messages"
                            :key="messageIndex"
                            :class="messageIndex === 0 ? 'border-top-bolder' : ''"
                        >
                            <td>Message {{ messageIndex + 1 }}</td>
                            <td v-for="(language, languageIndex) in previewLanguages" :key="languageIndex">
                                <div v-if="getMessageType(language.name, messageIndex)" class="w-full h-8">
                                    <icon-button
                                        class="float-right rounded"
                                        size="xs"
                                        :icon="
                                            getMessageType(language.name, messageIndex) === 'ADMIN'
                                                ? 'user-secret'
                                                : 'user'
                                        "
                                        @click="updateMessageType(messageIndex, language.name)"
                                    />
                                </div>

                                <translation-element-box
                                    :message-type="getMessageType(language.name, messageIndex)"
                                    :body="getMessage(language.name, messageIndex)"
                                    :rtl="language.rtl"
                                    :content-editable="getInAppContentEditable(language.name, messageIndex)"
                                    @updated-body="updatedBody('MESSAGES', messageIndex, language.name, $event)"
                                />
                            </td>
                        </tr>
                    </template>
                    <template v-if="step.messageType === 'EMAIL'">
                        <tr
                            v-for="(item, index) in step[largestEmailLanguage].template.children"
                            :key="index"
                            :class="index === 0 ? 'border-top-bolder' : ''"
                        >
                            <td>Block {{ index + 1 }}</td>
                            <td v-for="(language, languageIndex) in previewLanguages" :key="languageIndex">
                                <template v-if="getEmailComponentType(language.name, index) === 'List'">
                                    <translation-element-box
                                        v-for="(list, listIndex) in step.messageBody_en.template.children[index]
                                            .children"
                                        :key="listIndex"
                                        class="bg-grey-50 mb-1 rounded"
                                        :body="getEmailRow(language.name, index, listIndex)"
                                        :rtl="language.rtl"
                                        :content-editable="getEmailContentEditable(language.name, index)"
                                        @updated-body="updatedEmailBody(language.name, $event, index, listIndex)"
                                    />
                                </template>

                                <translation-element-box
                                    v-else
                                    :body="getEmailRow(language.name, index)"
                                    :rtl="language.rtl"
                                    :content-editable="getEmailContentEditable(language.name, index)"
                                    @updated-body="updatedEmailBody(language.name, $event, index)"
                                />
                            </td>
                        </tr>
                    </template>
                </table>
                <p v-else>
                    Please create or select a step first in the
                    <b>Messages</b>
                    tab or dropdown
                </p>
            </div>
        </Panel>

        <div v-if="step && step.messageType === 'EMAIL'">
            <campaign-viewer
                v-for="lang in previewLanguages"
                v-if="doesKeyExist(lang.name)"
                :key="lang.name"
                class="hidden"
                :nodes="renderMarkdown(lang.name)"
                @html-event="htmlEvent($event, lang.name)"
            />
        </div>

        <save-button
            v-if="!campaign.completedAt"
            :can-save="parentCanSave"
            :is-saving="isSaving"
            :wobble="saveButtonWobble"
            @save="saveCampaign"
        />
        <disabled-button v-else @clicked="$emit('final-disabled-click')"></disabled-button>
    </div>
</template>

<script>
    import Panel from '../../components/Panels/Panel';
    import TranslationElementBox from './TranslationElementBox';
    import _ from 'lodash';
    import Dropdown from '../../components/Dropdown/Dropdown';
    import { messengerAxios } from '../../../axios.config';
    import SaveButton from './SaveButton';
    import DisabledButton from './DisabledButton.vue';
    import { mapActions } from 'vuex';
    import CampaignViewer from '@/components/EmailBuilder/CampaignViewer.vue';
    import IconButton from '@/components/Buttons/IconButton';

    export default {
        name: 'CampaignTranslation',
        components: {
            IconButton,
            Panel,
            TranslationElementBox,
            Dropdown,
            SaveButton,
            CampaignViewer,
            DisabledButton,
        },

        props: {
            campaign: {
                type: Object,
                required: true,
            },
            steps: {
                type: Array,
                required: true,
            },
            step: {
                type: Object,
                default: null,
            },
            currentGroupIndex: {
                type: Number,
                default: null,
            },
            currentStep: {
                type: Number,
                default: null,
            },
            totalSteps: {
                type: Number,
                default: null,
            },
            previewUpdated: {
                type: Number,
                required: true,
            },
            parentCanSave: {
                type: Boolean,
                default: false,
            },
            previewLanguages: {
                type: Array,
                required: true,
            },
            saveButtonWobble: {
                type: Boolean,
                required: true,
            },
        },

        data() {
            return {
                canSave: false,
                isSaving: false,
                panelTitle: 'Translations',
                headerKeyRefresh: 0,
                tableKeyRefresh: 0,
                updatedStep: [],
                stepFilter: [],
                stepFilterDefault: null,
                disabledEmailComponentList: ['App Store Links', 'Divider Bold', 'Divider Thin', 'Photo-Video'],
            };
        },

        computed: {
            translationTitle() {
                if (!this.step) {
                    return 'Translations';
                }

                return (
                    `Editing - Step ${this.currentStep}/${this.totalSteps} - ` +
                    this.step.messageType
                        .toLowerCase()
                        .replace('_', ' ')
                        .replace(/\b\w/g, (l) => l.toUpperCase())
                );
            },
            largestMessageLanguage() {
                let largestLanguage = 'messageBody_en';
                let largestAmount = 0;

                if (this.updatedStep.messageType !== 'IN_APP') {
                    return largestLanguage;
                }

                this.previewLanguages.forEach((language) => {
                    if (
                        this.doesKeyExist(language.name) &&
                        this.updatedStep[this.getKey(language.name)].messages.length > largestAmount
                    ) {
                        largestLanguage = this.getKey(language.name);
                        largestAmount = this.updatedStep[this.getKey(language.name)].messages.length;
                    }
                });

                return largestLanguage;
            },
            largestEmailLanguage() {
                let largestLanguage = 'messageBody_en';
                let largestAmount = 0;

                if (this.updatedStep.messageType !== 'EMAIL') {
                    return largestLanguage;
                }

                this.previewLanguages.forEach((language) => {
                    if (
                        this.doesKeyExist(language.name) &&
                        this.updatedStep[this.getKey(language.name)].template.children.length > largestAmount
                    ) {
                        largestLanguage = this.getKey(language.name);
                        largestAmount = this.updatedStep[this.getKey(language.name)].template.children.length;
                    }
                });

                return largestLanguage;
            },
        },

        watch: {
            steps() {
                this.loadMessageFilter();
            },

            previewUpdated() {
                // If updated from preview screen
                this.updatedStep = JSON.parse(JSON.stringify(this.step));
                this.tableKeyRefresh++;
            },

            currentStep() {
                // If user is switching step from preview screen, then clear edit changes
                if (this.currentGroupIndex !== null && this.currentStep !== null) {
                    this.stepFilterDefault = this.currentGroupIndex + '.' + (this.currentStep - 1);
                    this.headerKeyRefresh++;
                } else {
                    this.stepFilterDefault = null;
                }

                this.updatedStep = JSON.parse(JSON.stringify(this.step));
            },

            canSave() {
                this.$emit('update-parent-can-save', this.canSave);
            },

            parentCanSave() {
                this.canSave = this.parentCanSave;
            },
        },

        created() {
            this.updatedStep = JSON.parse(JSON.stringify(this.step));
            this.loadMessageFilter();
        },

        methods: {
            ...mapActions(['handleError', 'alert', 'confirmDelete']),

            doesKeyExist(lang) {
                if (this.getKey(lang) in this.step === false) {
                    return false;
                }

                return true;
            },

            getKey(lang) {
                return `messageBody_${lang.toLowerCase()}`;
            },

            getMessageType(lang, index) {
                if (!this.doesKeyExist(lang)) {
                    return null;
                }

                if (this.step[this.getKey(lang)].messages && index in this.step[this.getKey(lang)].messages) {
                    return this.step[this.getKey(lang)].messages[index].type;
                }

                return null;
            },

            getMessage(lang, index) {
                if (!this.doesKeyExist(lang)) {
                    return '';
                }

                if (this.step[this.getKey(lang)].messages && index in this.step[this.getKey(lang)].messages) {
                    return this.step[this.getKey(lang)].messages[index]?.text;
                }

                return '';
            },

            getEmailComponentType(lang, index) {
                if (!this.doesKeyExist(lang)) {
                    return null;
                }

                if (
                    'children' in this.step[this.getKey(lang)].template &&
                    index in this.step[this.getKey(lang)].template.children
                ) {
                    return this.step[this.getKey(lang)].template.children[index].label;
                }

                return null;
            },

            getPushKey(lang, type) {
                if (!this.doesKeyExist(lang)) {
                    return '';
                }

                return this.step[this.getKey(lang)][type];
            },

            getEmailRow(lang, index, childIndex) {
                if (!this.doesKeyExist(lang)) {
                    return '';
                }

                if (index in this.step[this.getKey(lang)].template.children) {
                    const emailComponentLabel = this.step[this.getKey(lang)].template.children[index].label;

                    if (this.disabledEmailComponentList.includes(emailComponentLabel)) {
                        return '-';
                    }

                    if (
                        emailComponentLabel === 'List' &&
                        childIndex in this.step[this.getKey(lang)].template.children[index].children
                    ) {
                        return this.step[this.getKey(lang)].template.children[index].children[childIndex].textNode;
                    }

                    if (emailComponentLabel === 'Button') {
                        return this.step[this.getKey(lang)].template.children[index].children[0].textNode;
                    }

                    return this.step[this.getKey(lang)].template.children[index].textNode;
                }

                return '';
            },

            getInAppContentEditable(lang, index) {
                if (!this.doesKeyExist(lang) && index === 0) {
                    return true;
                }

                return this.doesKeyExist(lang) && this.step[this.getKey(lang)].messages.length >= index;
            },

            getEmailContentEditable(lang, index) {
                if (!this.doesKeyExist(lang)) {
                    return false;
                }

                if (index in this.step[this.getKey(lang)].template.children) {
                    const emailComponentLabel = this.step[this.getKey(lang)].template.children[index].label;
                    if (!this.disabledEmailComponentList.includes(emailComponentLabel)) {
                        return true;
                    }
                }

                return false;
            },

            updateMessageType(index, lang) {
                const key = this.getKey(lang);
                const messageType = this.getMessageType(lang, index);
                this.updatedStep[key].messages[index].type = messageType === 'ADMIN' ? 'TEXT' : 'ADMIN';
                this.$nextTick(() => this.updateParent());
            },

            updatedBody(type, index, lang, body) {
                body = body.trim();
                const key = this.getKey(lang);

                if (!(key in this.updatedStep)) {
                    this.updatedStep = {
                        ...this.updatedStep,
                        [key]: {},
                    };
                }

                let localObj = this.updatedStep[this.getKey(lang)];

                switch (type) {
                    case 'NOTIFICATION':
                        if (!('messages' in localObj)) {
                            this.updatedStep[this.getKey(lang)].messages = [];
                        }

                        this.updatedStep[key] = {
                            ...this.updatedStep[key],
                            [`push${index}`]: body,
                        };
                        break;

                    case 'MESSAGES':
                        if (!('messages' in this.updatedStep[key])) {
                            this.updatedStep[key] = {
                                ...this.updatedStep[key],
                                messages: [],
                            };
                        }

                        if (index in this.updatedStep[key].messages) {
                            this.updatedStep[key].messages[index].text = body;
                        } else {
                            this.updatedStep[key].messages[index] = {
                                type: 'TEXT',
                                text: body,
                            };
                        }
                        break;
                }

                this.updatedStep.parentID = null;

                this.$nextTick(() => this.updateParent());
            },

            updatedEmailBody(lang, body, index, childIndex) {
                body = body.trim();
                const key = this.getKey(lang);

                if (!(key in this.updatedStep)) {
                    this.updatedStep = {
                        ...this.updatedStep,
                        [key]: {},
                    };
                }

                let localObj = this.updatedStep[this.getKey(lang)];

                if (!('body' in localObj)) {
                    this.updatedStep[this.getKey(lang)].body = JSON.parse(
                        JSON.stringify(this.updatedStep.messageBody_en.body),
                    );
                }

                if (!('from' in localObj)) {
                    this.updatedStep[this.getKey(lang)].from = this.updatedStep.messageBody_en.from;
                }

                if (!('fromName' in localObj)) {
                    this.updatedStep[this.getKey(lang)].from = this.updatedStep.messageBody_en.fromName;
                }

                if (!('subject' in localObj)) {
                    this.updatedStep[this.getKey(lang)].subject = '';
                }

                if (!('template' in localObj)) {
                    this.updatedStep[this.getKey(lang)].template = JSON.parse(
                        JSON.stringify(this.updatedStep.messageBody_en.template),
                    );
                    this.updatedStep[this.getKey(lang)].template.children.forEach((child) => {
                        child.textNode = '';
                        if ('children' in child) {
                            child.children.forEach((grandChild) => (grandChild.textNode = ''));
                        }
                    });
                }

                if (index === 'SUBJECT') {
                    this.updatedStep[this.getKey(lang)].subject = body;
                } else {
                    if (this.updatedStep[this.getKey(lang)].template.children[index].label === 'List') {
                        this.updatedStep[this.getKey(lang)].template.children[index].children[childIndex].textNode =
                            body;
                    } else if (this.updatedStep[this.getKey(lang)].template.children[index].label === 'Button') {
                        this.updatedStep[this.getKey(lang)].template.children[index].children[0].textNode = body;
                    } else {
                        this.updatedStep[this.getKey(lang)].template.children[index].textNode = body;
                    }
                }

                this.updatedStep.parentID = null;

                this.$nextTick(() => this.updateParent());
            },

            async deleteFlagColumn(flagCode) {
                if (!(await this.confirmDelete({ vm: this }))) return;
                this.$delete(this.updatedStep, `messageBody_${flagCode.toLowerCase()}`);
                this.$emit('deleted-flag-column', flagCode);
                this.updateParent();
            },

            updateParent() {
                this.$emit('updated-translation', this.updatedStep);
                this.canSave = true;
            },

            resolveCountryFlagUrl(flag) {
                return `https://${process.env.VUE_APP_WEB_CDN_DOMAIN}/images/flags/flag_${flag.toLowerCase()}.png`;
            },

            selectMessage(combinedIndex) {
                if (!combinedIndex) return;

                let indexList = combinedIndex.toString().split('.');
                this.$emit('selected-message', {
                    groupIdx: Number(indexList[0]),
                    stepIdx: Number(indexList[1]),
                    variation: null,
                });
            },

            loadMessageFilter() {
                this.stepFilter = this.steps.flatMap((row, grpIdx) => {
                    return row.templates.map((step, stpIdx) => ({
                        label:
                            `${grpIdx + 1}. ` +
                            (step.messageBody_en.subject ||
                                step.messageBody_en.pushTitle ||
                                step.messageBody_en.messages[0]?.text),
                        value: grpIdx + '.' + stpIdx,
                    }));
                });

                if (this.currentGroupIndex !== null && this.currentStep !== null) {
                    this.stepFilterDefault = this.currentGroupIndex + '.' + (this.currentStep - 1);
                    this.headerKeyRefresh++;
                }
            },

            validateForm() {
                let valid = true;

                this.previewLanguages.forEach((language) => {
                    if (this.doesKeyExist(language.name)) {
                        if (this.step.messageType === 'IN_APP') {
                            this.step[this.getKey(language.name)].messages.forEach((message, messageIndex) => {
                                if (message.text.trim() === '') {
                                    this.alert({
                                        message: language.name + ': Missing value on block ' + (messageIndex + 1),
                                        type: 'danger',
                                        time: 4000,
                                    });

                                    valid = false;
                                }
                            });
                        }

                        if (this.step.messageType === 'EMAIL') {
                            this.step[this.getKey(language.name)].template.children.forEach((child, childIndex) => {
                                if (this.disabledEmailComponentList.includes(child.label)) {
                                    return;
                                }

                                if (['Button', 'List'].includes(child.label)) {
                                    child.children.forEach((grandchild) => {
                                        if (grandchild.textNode === '') {
                                            this.alert({
                                                message: language.name + ': Missing value on block ' + (childIndex + 1),
                                                type: 'danger',
                                                time: 4000,
                                            });

                                            valid = false;
                                        }
                                    });
                                }

                                if (!['Button', 'List'].includes(child.label) && child.textNode === '') {
                                    this.alert({
                                        message: language.name + ': Missing value on block ' + (childIndex + 1),
                                        type: 'danger',
                                        time: 4000,
                                    });

                                    valid = false;
                                }
                            });
                        }
                    }
                });

                return valid;
            },

            async saveCampaign() {
                const valid = this.validateForm();
                if (!valid) return;

                this.isSaving = true;

                try {
                    let steps = JSON.parse(JSON.stringify(this.steps));
                    steps[this.currentGroupIndex].templates[this.currentStep - 1] = JSON.parse(
                        JSON.stringify(this.updatedStep),
                    );

                    const response = await messengerAxios.post(`/campaign/${this.campaign.id}/steps`, {
                        steps: steps,
                    });

                    this.canSave = false;
                    await this.alert({ message: 'The messages was successfully saved', type: 'success' });
                    this.$emit('updated-campaign', response.data.result);
                } catch (error) {
                    await this.handleError({ error });
                }

                this.isSaving = false;
            },

            renderMarkdown(lang) {
                return {
                    ...this.updatedStep[this.getKey(lang)].template,
                    children: this.updatedStep[this.getKey(lang)].template.children.map((node) => {
                        let newChild = {
                            ...node,
                            domProps: {
                                innerHTML: node.textNode
                                    .replace(/(\*(.+)\*)/, (word) => `<b> ${word.split('*')[1]} </b>`)
                                    .replace(
                                        /(~(.+)~)/,
                                        (word) => `<span style="color: #fb406c"> ${word.split('~')[1]} </span>`,
                                    )
                                    .replace(
                                        /(\[(.+)\]\((.+)\))/,
                                        (word) =>
                                            `<a href="${word.split(/\((.+)\)/)[1]}" target="_blank"> ${
                                                word.split(/\[(.+)\]/)[1]
                                            } </a>`,
                                    ),
                            },
                        };

                        return newChild;
                    }),
                };
            },

            htmlEvent(html, lang) {
                if (this.getKey(lang) in this.updatedStep) {
                    this.updatedStep[this.getKey(lang)].body = html;
                }
            },
        },
    };
</script>

<style scoped>
    #translation-table-wrapper {
        overflow-x: scroll;
        overflow-y: hidden;
    }

    .flag-language {
        margin-top: 0.3rem;
    }

    table {
        border-collapse: collapse;
    }

    th {
        min-width: 300px;
    }

    th:first-child {
        min-width: 150px;
    }

    td,
    th {
        border: 2px solid #dddddd;
        text-align: left;
        padding: 1rem;
    }

    tr:first-child th {
        border-top: 0;
    }

    tr th:first-child {
        border-left: 0;
    }

    tr th:last-child {
        border-right: 0;
    }

    tr:first-child td {
        border-top: 0;
    }

    tr td:first-child {
        border-left: 0;
    }

    tr:last-child td {
        border-bottom: 0;
    }

    tr td:last-child {
        border-right: 0;
    }

    select {
        background-color: #2f365f;
    }

    .border-top-bolder {
        border-top: 4px solid #b0b0b0;
    }

    .delete-btn {
        border-radius: 100%;
        height: 2rem;
        width: 2rem;
        font-weight: bolder;
    }

    .max-width-70 {
        max-width: 70%;
    }
</style>
