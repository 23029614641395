<template>
    <div v-if="!loading" class="py-5">
        <Panel title="General">
            <div slot="header" class="p-2">
                <icon
                    v-if="!updatedCampaign.completedAt"
                    ref="chevron"
                    icon="chevron-down"
                    class="text-grey-dark cursor-pointer text-2xl mr-2 mt-1"
                    @click="statusChevron = !statusChevron"
                />
                <div v-show="statusChevron" class="shadow-lg bg-white p-2 rounded w-48 mt-2 absolute z-30">
                    <template v-if="updatedCampaign.publishedAt && updatedCampaign.campaignActive === true">
                        <div
                            v-if="updatedCampaign.state === 'PUBLISHED'"
                            class="hover:bg-grey-200 rounded p-2 cursor-pointer"
                            @click="saveCampaign('PAUSE')"
                        >
                            <span>Pause Campaign</span>
                        </div>
                        <div
                            v-else
                            class="hover:bg-grey-200 rounded p-2 cursor-pointer"
                            @click="saveCampaign('RESUME')"
                        >
                            <span>Resume Campaign</span>
                        </div>
                    </template>
                    <div class="hover:bg-red hover:text-white rounded p-2 cursor-pointer" @click="deleteCampaign">
                        <span>Delete Campaign</span>
                    </div>
                    <div
                        v-if="importPermission"
                        class="hover:bg-grey-200 rounded p-2 cursor-pointer"
                        @click="copyCampaign()"
                    >
                        <span>Copy Campaign</span>
                    </div>
                    <div
                        v-if="duplicatePermission"
                        class="hover:bg-grey-200 rounded p-2 cursor-pointer"
                        @click="duplicateCampaign()"
                    >
                        <span>Duplicate Campaign</span>
                    </div>
                </div>
            </div>
            <div class="p-4">
                <div class="p-2">
                    <h2 class="underline-grey pb-4">
                        <span>
                            Campaign Live
                            <span v-if="updatedCampaign.state === 'PAUSED'">(PAUSED)</span>
                        </span>
                        <toggle-button
                            :key="refreshCampaignToggle"
                            class="float-right"
                            :is-toggled="updatedCampaign.campaignActive"
                            :is-disabled="parentCanSave || updatedCampaign.completedAt !== null"
                            :require-confirmation="true"
                            :confirmation-text="
                                updatedCampaign.campaignActive
                                    ? 'Are you sure you want to stop the campaign?'
                                    : 'Are you sure you want to start the campaign?'
                            "
                            @clicked-disabled="$emit('disabled-published-toggle')"
                            @clicked="toggleClick('campaign-active', $event)"
                        />
                    </h2>
                    <p v-if="updatedCampaign.publishedAt" class="mt-4 h-10">
                        Published at {{ updatedCampaign.publishedAt | date }}
                    </p>
                    <p v-else class="mt-4 h-10">Not published yet</p>
                    <h2 class="underline-grey pt-4 pb-4">
                        <span>Campaign Approved</span>
                        <toggle-button
                            :key="refreshCampaignApprovalToggle"
                            class="float-right"
                            :is-disabled="
                                !adminApproval ||
                                updatedCampaign.completedAt !== null ||
                                updatedCampaign.publishedAt === null
                            "
                            :is-toggled="updatedCampaign.approved"
                            :require-confirmation="true"
                            :confirmation-text="
                                updatedCampaign.approved
                                    ? 'Are you sure you want to unapprove the campaign?'
                                    : 'Are you sure you want to approve the campaign?'
                            "
                            @clicked="toggleClick('campaign-approval', $event)"
                        />
                    </h2>
                    <p v-if="updatedCampaign.approvedAt" class="mt-4 h-10">
                        Approved
                        {{
                            typeof updatedCampaign.approvedStaffName !== 'undefined' &&
                            updatedCampaign.approvedStaffName !== null
                                ? 'by ' + updatedCampaign.approvedStaffName
                                : ''
                        }}
                        at {{ updatedCampaign.approvedAt | date }}
                    </p>
                    <p v-else class="mt-4 h-10">Not approved yet</p>
                    <p v-if="updatedCampaign.completedAt" class="mt-4 h-10">
                        Completed at {{ updatedCampaign.completedAt | date }}
                    </p>
                    <p class="mt-4 h-10 flex">
                        <span class="pt-1 mr-4">Marketing campaign</span>
                        <toggle-button
                            :is-toggled="updatedCampaign.marketing"
                            :is-disabled="formDisabled"
                            @clicked="toggleClick('marketing', $event)"
                        />
                    </p>
                    <p class="mt-4 h-10 flex">
                        <span class="pt-2 mr-4">Campaign type</span>
                        <Dropdown
                            placeholder="Campaign type"
                            :options="forms.campaignTypeFilter"
                            :default="updatedCampaign.type"
                            :is-disabled="formDisabled"
                            @updated="
                                updatedCampaign.type = $event;
                                canSave = true;
                            "
                        />
                    </p>
                    <p class="mt-4 h-10 flex">
                        <span class="pt-2 mr-4">Experiment Number</span>
                        <input
                            v-model.number="updatedCampaign.experimentNumber"
                            type="number"
                            class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                            :is-disabled="formDisabled"
                            @input="canSave = true"
                        />
                    </p>
                </div>
                <div v-show="updatedCampaign.type === 'SCHEDULE' || updatedCampaign.type === 'EMAIL'" class="p-2 mt-4">
                    <div class="border-b border-grey-muzmatch pb-2">
                        <h2 class="">
                            <span>Schedule</span>
                        </h2>

                        <div v-show="updatedCampaign.type === 'SCHEDULE'" class="text-xs text-grey-muzmatch">
                            This schedules the campaign to start at the date/time selected below in the member's local
                            timezone
                        </div>
                    </div>

                    <div class="mt-4">
                        <p>
                            <span class="mr-4">Send</span>
                            <date-picker
                                v-if="!formDisabled"
                                v-model="updatedCampaign.scheduleSendAt"
                                mode="dateTime"
                                timezone="UTC"
                                :minute-increment="15"
                                :model-config="scheduleSendAtModelConfig"
                                :min-date="scheduleSendAtMinDate"
                                is24hr
                                @input="canSave = true"
                            >
                                <template #default="{ inputValue, inputEvents }">
                                    <input
                                        class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                                        :value="inputValue"
                                        v-on="inputEvents"
                                    />
                                </template>
                            </date-picker>
                            <span v-else>
                                {{ updatedCampaign.scheduleSendAt | date }}
                            </span>
                        </p>
                        <p
                            v-show="updatedCampaign.type === 'SCHEDULE'"
                            :key="refreshScheduleKey"
                            class="mt-4 h-10 flex"
                        >
                            <toggle-button
                                :is-toggled="updatedCampaign.repeatEveryToggle"
                                :is-disabled="formDisabled"
                                @clicked="toggleClick('repeat-every', $event)"
                            />
                            <span class="pt-2 mx-4">Repeat every</span>
                            <dropdown
                                v-show="updatedCampaign.repeatEveryToggle"
                                class="mr-4"
                                placeholder="0"
                                :default="updatedCampaign.scheduleRepeatValue"
                                :options="forms.intFilter"
                                :is-disabled="formDisabled"
                                @updated="
                                    updatedCampaign.scheduleRepeatValue = $event;
                                    canSave = true;
                                "
                            />
                            <dropdown
                                v-show="updatedCampaign.repeatEveryToggle"
                                placeholder="Select interval"
                                :default="updatedCampaign.scheduleRepeatType"
                                :options="forms.timeFilter"
                                :is-capitalize="false"
                                :is-disabled="formDisabled"
                                @updated="
                                    updatedCampaign.scheduleRepeatType = $event;
                                    canSave = true;
                                "
                            />
                        </p>
                    </div>
                </div>
                <div v-show="updatedCampaign.type === 'API_EVENT'" class="p-2 mt-4">
                    <h2 class="underline-grey pb-4">
                        <span>API Event</span>
                    </h2>
                    <p class="mt-4 h-10 flex">
                        <span class="pt-2 mr-4">Enroll on event</span>
                        <dropdown
                            placeholder="Select Event"
                            :options="apiEventsOptions"
                            :default="updatedCampaign.apiEventID"
                            @updated="
                                updatedCampaign.apiEventID = $event;
                                canSave = true;
                            "
                        />
                    </p>
                    <p class="mt-6 h-10">
                        <span class="pt-2 mr-4 float-left">Send subsequent steps at</span>
                        <date-picker
                            v-model="updatedCampaign.apiEventSendAt"
                            mode="time"
                            timezone="UTC"
                            is24hr
                            @input="canSave = true"
                        >
                            <template #default="{ inputValue, inputEvents }">
                                <input
                                    class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                                    :value="inputValue"
                                    v-on="inputEvents"
                                />
                            </template>
                        </date-picker>
                    </p>
                </div>
                <div v-show="updatedCampaign.type === 'REENGAGEMENT'" class="p-2 mt-4">
                    <h2 class="underline-grey pb-4">Reengagement</h2>
                    <div class="mt-6"></div>
                    <div class="flex items-center gap-x-5">
                        <p>Repeat after</p>
                        <input
                            v-model.number="updatedCampaign.reengagementRepeatDays"
                            type="number"
                            class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                        />
                        <p>Days</p>
                    </div>
                    <div class="pt-4"></div>
                    <div class="text-xs text-purple-muzmatch">
                        If this field is left left empty the member will only get the reengagement once
                    </div>
                    <div class="pt-8"></div>
                    <div class="">Send this reengagement at</div>
                    <div class="pt-2"></div>
                    <div class="flex gap-x-3 reengagement">
                        <date-picker
                            v-model="updatedCampaign.reengagementSendAt"
                            mode="time"
                            timezone="UTC"
                            is24hr
                            @input="canSave = true"
                        />
                        <button
                            v-show="updatedCampaign.reengagementSendAt"
                            title="Clear time"
                            class="rotate-45 transform"
                            @click="updatedCampaign.reengagementSendAt = null"
                        >
                            <icon icon="plus" />
                        </button>
                    </div>
                    <div class="pt-4"></div>
                    <div class="text-xs text-purple-muzmatch">
                        <p>
                            When this time is set the reengagement will go out to the user on or just after above time
                            on the day we match the user to the reengagement.
                        </p>
                        <div class="pt-4"></div>
                        <p>
                            That means, if the above time is set to 4pm, and we detect the user is in the campaign at
                            2am the message will not be sent until 4pm
                        </p>
                        <div class="pt-4"></div>
                        <p>This time will be localised to the users timezone</p>
                    </div>
                </div>
            </div>
        </Panel>

        <Panel title="Audience" class="mt-5">
            <div slot="header">
                <button
                    v-if="!formDisabled"
                    type="button"
                    class="rounded bg-pink-muzmatch float-right text-white px-4 py-1 mt-2 mr-2"
                    @click="showAudienceModal = true"
                >
                    Edit
                </button>
                <p v-else class="text-sm mt-4 mr-4 text-grey-darker">Can’t edit as campaign has started</p>
            </div>
            <div class="p-4">
                <div v-if="currentAudience && updatedCampaign.type !== 'REENGAGEMENT'" class="flex">
                    <p class="w-1/2">{{ currentAudience.title }}</p>
                    <p class="w-1/2 text-right">{{ Number(currentAudience.estimatedAudienceSize).toLocaleString() }}</p>
                </div>
                <p v-else>Select an audience</p>
                <div v-if="selectedAudienceJsonFilter" class="mt-5 italic text-sm">
                    <audience-json-description :audience-json-filter="selectedAudienceJsonFilter" />
                </div>
            </div>
        </Panel>

        <Panel title="Goal" class="mt-5">
            <div class="p-4">
                <div v-for="(goal, index) in updatedCampaign.goalList" :key="index" class="flex">
                    <p class="w-1/2">{{ goalLabel(goal.goalID) }}</p>
                    <div class="w-1/2 justify-end flex items-center">
                        <icon
                            v-if="!formDisabled"
                            class="transform rotate-45 text-xs text-grey-700 ml-2 cursor-pointer"
                            icon="plus"
                            @click="removeGoal(index)"
                        />
                    </div>
                </div>
                <button
                    v-if="updatedCampaign.goalList.length === 0 && !formDisabled"
                    type="button"
                    class="exit-criteria p-2 text-white bg-pink-muzmatch rounded"
                    @click="addGoalModal"
                >
                    Add Exit Criteria
                </button>
                <p v-if="updatedCampaign.goalList.length === 0 && formDisabled">Can’t add as campaign has started</p>
            </div>
        </Panel>

        <campaign-add-goal-modal v-if="showModal" :goals="goals" @hide="showModal = false" @submit-goal="submitGoal" />

        <select-audience-modal
            v-if="showAudienceModal"
            :current-audience="currentAudience"
            :audiences="audiences"
            :form="forms.audienceFilter"
            :default-audience="updatedCampaign.audienceID"
            :audience-fields="audienceOptions"
            @update-audiences="updateAudience"
            @close-modal="showAudienceModal = false"
        />

        <save-button
            v-if="!campaign.completedAt"
            :can-save="parentCanSave"
            :is-saving="isSaving"
            :wobble="saveButtonWobble"
            @save="saveCampaign"
        />
        <disabled-button v-else @clicked="$emit('final-disabled-click')"></disabled-button>
    </div>
</template>

<script>
    import Panel from '../../components/Panels/Panel';
    import ToggleButton from '../../components/Buttons/ToggleButton';
    import DatePicker from 'v-calendar/lib/components/date-picker.umd';
    import Dropdown from '../../components/Dropdown/Dropdown';
    import CampaignAddGoalModal from './CampaignAddGoalModal';
    import SelectAudienceModal from './SelectAudienceModal.vue';
    import SaveButton from './SaveButton.vue';
    import DisabledButton from './DisabledButton.vue';
    import { messengerAxios } from '../../../axios.config';
    import { mapActions } from 'vuex';
    import AudienceJsonDescription from './AudienceJsonDescription';
    import moment from 'moment';
    import { mapGetters } from 'vuex';
    import { User } from '../../classes/User';
    import { requestSubmitApproveAnswers } from '../../store/member/actions';

    export default {
        name: 'CampaignSetting',
        components: {
            Panel,
            ToggleButton,
            DatePicker,
            Dropdown,
            CampaignAddGoalModal,
            SelectAudienceModal,
            SaveButton,
            AudienceJsonDescription,
            DisabledButton,
        },
        filters: {
            date(date) {
                return moment(date).format('DD/MM/YYYY HH:mm:ss');
            },
        },
        props: {
            goals: {
                type: Array,
                required: true,
            },
            currentAudience: {
                type: Object,
                default: null,
            },
            audiences: {
                type: Array,
                required: true,
            },
            audienceOptions: {
                type: Array,
                required: true,
            },
            campaign: {
                type: Object,
                required: true,
            },
            campaignTitle: {
                type: String,
                required: true,
            },
            parentCanSave: {
                type: Boolean,
                default: false,
            },
            apiEvents: {
                type: Array,
                required: true,
            },
            saveButtonWobble: {
                type: Boolean,
                required: true,
            },
            pauseCampaignAmount: {
                type: Number,
                required: true,
            },
            resumeCampaignAmount: {
                type: Number,
                required: true,
            },
        },
        data() {
            return {
                canSave: false,
                isSaving: false,
                loading: true,
                statusChevron: false,
                refreshScheduleKey: 0,
                refreshCampaignToggle: 0,
                refreshCampaignApprovalToggle: 0,
                showAudienceModal: false,
                updatedCampaign: {},
                selectedAudienceJsonFilter: null,
                forms: {
                    campaignTypeFilter: [
                        { value: 'SCHEDULE', label: 'Schedule' },
                        { value: 'API_EVENT', label: 'API Event' },
                        { value: 'REENGAGEMENT', label: 'Reengagement' },
                        { value: 'EMAIL', label: 'Email' },
                    ],
                    timeFilter: [
                        { value: 'DAY', label: 'day' },
                        { value: 'WEEK', label: 'week' },
                        { value: 'MONTH', label: 'month' },
                        { value: 'QUARTER', label: 'quarter' },
                        { value: 'YEAR', label: 'year' },
                    ],
                    delayFilter: [
                        { value: 'IMMEDIATELY', label: 'Immediately' },
                        { value: 'AFTER_DELAY', label: 'After Delay' },
                    ],
                    intFilter: [
                        { value: 1, label: 1 },
                        { value: 2, label: 2 },
                        { value: 3, label: 3 },
                        { value: 4, label: 4 },
                        { value: 5, label: 5 },
                        { value: 6, label: 6 },
                        { value: 7, label: 7 },
                        { value: 8, label: 8 },
                        { value: 9, label: 9 },
                        { value: 10, label: 10 },
                    ],
                    audienceFilter: [],
                    goalFilter: [],
                },
                showModal: false,
                scheduleSendAtMinDate: moment().utc().format(),
                scheduleSendAtModelConfig: {
                    type: 'string',
                    mask: 'iso',
                    timeAdjust: '08:00:00',
                },
            };
        },
        computed: {
            ...mapGetters({
                staff: 'staff/getStaff',
            }),
            adminApproval() {
                return User.make(this.staff).can('messenger:approve');
            },
            importPermission() {
                return User.make(this.staff).can('messenger:import-campaign');
            },
            duplicatePermission() {
                return User.make(this.staff).can('messenger:duplicate-campaign');
            },
            apiEventsOptions() {
                return this.apiEvents.map((a) => ({
                    value: a.id,
                    label: a.title,
                }));
            },
            formDisabled() {
                if (this.campaign.publishedAt && this.campaign.type === 'SCHEDULE') {
                    let currentDatetime = moment().utc();
                    const minuteDifference = currentDatetime.diff(this.campaign.scheduleSendAt, 'minutes');
                    if (minuteDifference >= -60) {
                        return true;
                    }
                }

                return false;
            },
        },
        watch: {
            campaignTitle(title) {
                if (this.updatedCampaign.title !== title) {
                    this.updatedCampaign.title = title;
                    this.canSave = true;
                }
            },
            canSave() {
                this.$emit('update-parent-can-save', this.canSave);
            },
            parentCanSave() {
                this.canSave = this.parentCanSave;
            },
            campaign() {
                this.loadSettings(this.campaign);
            },
            pauseCampaignAmount(amount) {
                this.saveCampaign('PAUSE');
            },
            resumeCampaignAmount(amount) {
                this.saveCampaign('RESUME');
            },
        },
        mounted() {
            this.loadSettings(this.campaign);
            this.loading = false;
        },
        created() {
            window.addEventListener('click', this.documentClick);
        },
        destroyed() {
            document.removeEventListener('click', this.documentClick);
        },
        methods: {
            ...mapActions(['confirmDelete', 'handleError', 'alert']),

            addGoalModal() {
                this.showModal = true;
            },

            submitGoal(form) {
                this.updatedCampaign.goalList.push({
                    goalID: form.goalID,
                });

                this.showModal = false;
                this.canSave = true;
            },

            removeGoal(index) {
                this.$delete(this.updatedCampaign.goalList, index);
                this.canSave = true;
            },

            goalLabel(id) {
                return this.goals.find((g) => g.id === id)['title'];
            },

            updateAudience(selectedAudience) {
                this.canSave = true;
                this.showAudienceModal = false;
                this.updatedCampaign.audienceID = selectedAudience.id;
                this.selectedAudienceJsonFilter = _.clone(selectedAudience.jsonFilter);

                this.$emit('update-current-audience', selectedAudience);
                this.$emit('update-audiences');
            },

            async toggleClick(type, value) {
                switch (type) {
                    case 'campaign-active':
                        this.updatedCampaign.campaignActive = value;
                        if (!(await this.validateForm())) {
                            this.updatedCampaign.campaignActive = !value;
                            this.refreshCampaignToggle++; // Need this to revert toggle back to prev state
                            return false;
                        }
                        if (!value) {
                            this.updatedCampaign.approved = value;
                            this.updatedCampaign.state = 'DRAFT';
                            this.refreshCampaignApprovalToggle++;
                        }
                        await this.saveCampaign();
                        break;
                    case 'marketing':
                        this.updatedCampaign.marketing = value;
                        this.canSave = true;
                        break;
                    case 'repeat-every':
                        this.updatedCampaign.repeatEveryToggle = value;
                        if (value === false) {
                            this.updatedCampaign.scheduleRepeatValue = null;
                            this.updatedCampaign.scheduleRepeatType = null;
                        }
                        this.refreshScheduleKey++;
                        this.canSave = true;
                        break;
                    case 'campaign-approval':
                        this.updatedCampaign.approved = value;
                        if (!(await this.validateForm())) {
                            this.updatedCampaign.approved = !value;
                            this.refreshCampaignApprovalToggle++;
                            return false;
                        }
                        this.updatedCampaign.state = value ? 'PUBLISHED' : 'DRAFT';
                        await this.saveCampaign();
                        break;
                }
            },

            loadSettings(campaign) {
                let approved = this.updatedCampaign.approved;
                this.updatedCampaign = JSON.parse(JSON.stringify(campaign));
                this.updatedCampaign.campaignActive = !!this.updatedCampaign.publishedAt;
                this.updatedCampaign.approved = !!this.updatedCampaign.approvedAt;
                if (approved != this.updatedCampaign.approved) {
                    this.refreshCampaignApprovalToggle++;
                }
                this.updatedCampaign.repeatEveryToggle = !!this.updatedCampaign.scheduleRepeatType;

                if (this.updatedCampaign.apiEventSendAt) {
                    this.updatedCampaign.apiEventSendAt = '2020-01-01 ' + this.updatedCampaign.apiEventSendAt;
                }

                if (this.updatedCampaign.scheduleSendAt) {
                    const currentDatetime = moment().utc();
                    const scheduleDatetime = moment(this.updatedCampaign.scheduleSendAt, 'YYYY-MM-DD').utc();
                    if (scheduleDatetime < currentDatetime) {
                        this.scheduleSendAtMinDate = scheduleDatetime.format();
                    } else {
                        this.scheduleSendAtMinDate = currentDatetime.format();
                    }
                }

                if (this.currentAudience) {
                    this.selectedAudienceJsonFilter = _.clone(this.currentAudience.jsonFilter);
                }
            },

            documentClick(e) {
                const chevron = this.$refs.chevron;
                const target = e.target;

                if (chevron && !chevron.contains(target)) {
                    this.statusChevron = false;
                }
            },

            async deleteCampaign() {
                try {
                    if (!(await this.confirmDelete({ vm: this }))) return;

                    await messengerAxios.delete(`/campaign/${this.updatedCampaign.id}`);

                    await this.alert({ message: 'The campaign was successfully deleted', type: 'success' });
                    return this.$router.push('/messenger');
                } catch (error) {
                    await this.handleError({ error });
                }
            },

            async saveCampaign(stateChange) {
                try {
                    if (!(await this.validateForm())) {
                        return false;
                    }

                    if (stateChange) {
                        if (!(await this.confirmDelete({ vm: this }))) return;
                        if (stateChange === 'PAUSE') {
                            this.updatedCampaign.state = 'PAUSED';
                        } else if (stateChange === 'RESUME') {
                            this.updatedCampaign.state = 'PUBLISHED';
                        }
                        this.statusChevron = false;
                    }

                    this.isSaving = true;

                    const response = await messengerAxios.patch(
                        `/campaign/${this.updatedCampaign.id}/settings`,
                        this.updatedCampaign,
                    );
                    this.canSave = false;
                    this.$emit('update-parent-campaign', response.data.result);
                    this.loadSettings(response.data.result);
                    await this.alert({ message: 'The campaign was successfully updated', type: 'success' });
                } catch (error) {
                    await this.handleError({ error });
                }

                this.isSaving = false;
            },

            async validateForm() {
                let response = true;
                if (this.updatedCampaign.campaignActive) {
                    if (this.updatedCampaign.type === 'SCHEDULE' && !this.updatedCampaign.scheduleSendAt) {
                        response = false;
                        await this.alert({ message: 'Missing required schedule send at', type: 'danger' });
                    }

                    if (this.updatedCampaign.type === 'API_EVENT') {
                        if (!this.updatedCampaign.apiEventID) {
                            response = false;
                            await this.alert({ message: 'Missing required API event type', type: 'danger' });
                        }

                        if (!this.updatedCampaign.apiEventSendAt && this.updatedCampaign.steps.length > 1) {
                            response = false;
                            await this.alert({
                                message: 'Missing required send subsequent steps send at',
                                type: 'danger',
                            });
                        }
                    }

                    if (this.updatedCampaign.steps.length === 0) {
                        response = false;
                        await this.alert({ message: 'Missing required first step', type: 'danger' });
                    }

                    if (!this.updatedCampaign.audienceID && this.updatedCampaign.type !== 'EMAIL') {
                        response = false;
                        await this.alert({ message: 'Missing required audience list', type: 'danger' });
                    }
                }

                if (this.updatedCampaign.experimentNumber == '') {
                    this.updatedCampaign.experimentNumber = null;
                }

                let variations = [
                    ...new Set(
                        this.updatedCampaign.steps.flatMap((step) =>
                            step?.templates.map((template) => template.variation),
                        ),
                    ),
                ];

                if (variations.length > 1 && this.updatedCampaign.experimentNumber == null) {
                    await this.alert({
                        message: 'Missing experiment number',
                        type: 'danger',
                    });
                    response = false;
                }

                return response;
            },

            async copyCampaign() {
                try {
                    let originalCopyCampaign = _.omit(Object.assign({}, this.campaign), [
                        'approvedAt',
                        'approvedByStaffID',
                        'approvedStaffName',
                        'approvedTestSentAt',
                        'audienceSize',
                        'audienceID',
                        'channelList',
                        'createdAt',
                        'deletedAt',
                        'id',
                        'lastProcessed',
                        'processing',
                        'publishedAt',
                        'state',
                        'updatedAt',
                        'staffID',
                        'staffName',
                        'stats',
                    ]);

                    originalCopyCampaign.steps = originalCopyCampaign.steps?.map((step) => {
                        step.templates = step.templates?.map((template) => {
                            return _.omit(template, ['deletedAt', 'templateStepID', 'id', 'createdAt', 'updatedAt']);
                        });
                        return _.omit(step, ['id', 'campaignBlueprintID', 'deletedAt', 'createdAt', 'updatedAt']);
                    });

                    if (this.currentAudience) {
                        originalCopyCampaign.audienceJson = JSON.stringify(this.currentAudience.jsonFilter);
                        originalCopyCampaign.audienceTitle = '';
                        originalCopyCampaign.audienceDescription = '';
                    }

                    await navigator.clipboard.writeText(JSON.stringify(originalCopyCampaign));
                    await this.alert({ message: 'Campaign copied', type: 'success' });
                } catch ($e) {
                    await this.alert({ message: 'Error copying campaign', type: 'danger' });
                }
            },

            async duplicateCampaign() {
                const userResponse = await this.$swal({
                    title: 'Do you want to duplicate the campaign?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Continue',
                });

                if (!userResponse.value) {
                    return;
                }

                this.isSaving = true;
                const response = await messengerAxios.post(`/campaign/${this.updatedCampaign.id}/duplicate`, {});
                this.isSaving = false;
                this.$router.push(`/messenger/campaign.${response.data.result.id}`);
            },
        },
    };
</script>

<style scoped>
    .exit-criteria {
        font-size: 0.8rem;
    }

    h2 {
        font-size: 1.1rem;
        font-weight: bold;
    }

    .underline-grey {
        border-bottom-width: 1px;
        border-bottom-color: rgba(204, 204, 204, 1);
    }
</style>

<style>
    .reengagement .vc-date {
        display: none !important;
    }
</style>
