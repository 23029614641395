<template>
    <div class="container mx-auto">
        <div class="pt-2 mb-4 relative mx-auto text-gray-600 text-right">
            <div class="flex justify-end">
                <div class="text-grey-muzmatch">Search</div>
                <button class="mx-2" @click="doSearch">
                    <svg
                        id="Capa_1"
                        class="text-gray-600 h-4 w-4 fill-current"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        version="1.1"
                        x="0px"
                        y="0px"
                        viewBox="0 0 56.966 56.966"
                        style="enable-background: new 0 0 56.966 56.966"
                        xml:space="preserve"
                        width="512px"
                        height="512px"
                    >
                        <path
                            d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"
                        />
                    </svg>
                </button>
            </div>
            <input
                id="search-block"
                v-model="search"
                class="border-2 border-gray-300 bg-white h-10 px-5 w-2/6 rounded-lg text-sm focus:outline-none"
                type="search"
                name="search-block"
                placeholder="Search"
                @keyup.enter="doSearch"
                @input="onSearchChange"
            />
        </div>
        <div class="flex border-2 rounded h-screen-80">
            <div class="w-2/5 h-full flex flex-col mr-2">
                <panel class="h-full" title="Member Blocks" :has-margin="false">
                    <div class="threads-listing-height overflow-scroll">
                        <table class="w-full">
                            <tbody class="">
                                <div v-if="memberBlockDetails.length == 0" class="text-center mt-4 font-semibold">
                                    No threads found
                                </div>
                                <tr
                                    v-for="memberBlock in memberBlockDetails"
                                    :id="memberBlock.id"
                                    :key="memberBlock.id"
                                    class="transform scale-100 text-xs py-1 h-12 border-b border-grey-600 cursor-pointer bg-blue-500 bg-opacity-25 hover:bg-grey-light"
                                    :class="{ 'bg-grey-light': selected(memberBlock.id) }"
                                    @click="getBlock(memberBlock.id)"
                                >
                                    <td class="px-4 py-2">
                                        <div class="leading-5 text-gray-800">ID: {{ memberBlock.id }}</div>
                                        <div class="leading-5 text-gray-800">Member ID: {{ memberBlock.memberID }}</div>
                                        <div class="leading-5 text-gray-800 break-words">
                                            Source: {{ memberBlock.source }}
                                        </div>
                                    </td>
                                    <td class="whitespace-nowrap text-center pr-2">
                                        <div class="text-grey-muzmatch">Created At: {{ memberBlock.createdAt }}</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div v-show="canLoadMore" class="w-2/3 mx-auto my-4">
                            <M2Button class="mx-auto" @clicked="nextPage">Load More</M2Button>
                        </div>
                    </div>
                </panel>
            </div>
            <div class="w-3/5 h-full">
                <panel class="h-full" :has-margin="false">
                    <template v-if="!isLoading" #header>
                        <div class="flex justify-between w-full">
                            <p class="flex items-center pl-4">
                                <slot name="titleIcon" />
                                <span class="text-sm font-bold">Member Block</span>
                            </p>
                            <div
                                v-if="memberBlockDetail !== null && canDelete"
                                class="flex flex-row items-center w-max pb-1 text-xs thread-forward-icon mx-4 cursor-pointer"
                            >
                                <icon title="Delete" icon="trash" @click="deleteMemberBlock" />
                            </div>
                        </div>
                    </template>
                    <div v-if="memberBlockDetail">
                        <table class="w-full text-left table-fixed">
                            <thead>
                                <tr class="bg-grey-200">
                                    <th class="px-4 py-2 w-1/4">Key</th>
                                    <th class="px-4 py-2">Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(value, detail) in memberBlockDetail"
                                    v-if="detail !== 'nickname' && detail != 'hashMemberID'"
                                    :key="detail"
                                    class="border hover:bg-grey-200"
                                >
                                    <td class="px-4 py-2 font-bold break-words">{{ detail }}</td>
                                    <td class="px-4 py-2 break-words notranslate">
                                        {{ value }}
                                        <a
                                            v-if="detail === 'memberID' && validMember(memberBlockDetail)"
                                            :href="getMemberLink(memberBlockDetail)"
                                            target="_blank"
                                        >
                                            <icon icon="link"></icon>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </panel>
            </div>
        </div>
    </div>
</template>
<script>
    import { dashboardAxios } from '../../../axios.config';
    import M2Button from '../../components/Buttons/M2Button';
    import { mapGetters, mapActions } from 'vuex';
    import { User } from '@/classes/User';
    export default {
        components: {
            M2Button,
        },
        data() {
            return {
                memberBlockDetails: [],
                memberBlockDetail: null,
                id: this.$route.query.query || '',
                isLoading: false,
                page: 1,
                totalPages: null,
                search: '',
            };
        },
        computed: {
            ...mapGetters({
                staff: 'staff/getStaff',
            }),
            canLoadMore() {
                return this.totalPages !== null ? this.page < this.totalPages : false;
            },
            canDelete() {
                return User.make(this.staff).can('members:delete-block-details');
            },
        },
        async created() {
            if (this.$route.query.query) {
                this.search = this.$route.query.query;
            }
            let memberBlockResult = await this.doQuery();
            this.memberBlockDetails = memberBlockResult.items;
            this.totalPages = memberBlockResult.total_pages;
            if (this.memberBlockDetails.length > 0) {
                this.memberBlockDetail = this.memberBlockDetails[0];
            }
        },
        methods: {
            ...mapActions({
                alert: 'alert',
            }),
            async doQuery() {
                this.isLoading = true;

                let params = {
                    page: this.page,
                };

                if (this.search !== '') {
                    params['query'] = this.search;
                }

                let result = await dashboardAxios.get('/v1/memberBlockDetails', {
                    params: params,
                });

                this.isLoading = false;

                return result.data.result;
            },
            async doSearch() {
                this.page = 1;
                if (window.history.replaceState) {
                    var newurl =
                        window.location.protocol +
                        '//' +
                        window.location.host +
                        window.location.pathname +
                        '?query=' +
                        this.search;
                    window.history.replaceState({ path: newurl }, '', newurl);
                }
                let memberBlockResult = await this.doQuery();
                this.memberBlockDetails = memberBlockResult.items;
                this.totalPages = memberBlockResult.total_pages;
                if (this.memberBlockDetails.length > 0) {
                    this.memberBlockDetail = this.memberBlockDetails[0];
                } else {
                    this.memberBlockDetail = null;
                }
            },
            getBlock(id) {
                for (let i = 0; i < this.memberBlockDetails.length; i++) {
                    if (this.memberBlockDetails[i].id == id) {
                        this.memberBlockDetail = this.memberBlockDetails[i];
                        return;
                    }
                }
            },
            async nextPage() {
                this.page += 1;
                let memberBlockResult = await this.doQuery();
                this.memberBlockDetails = this.memberBlockDetails.concat(memberBlockResult.items);
                this.totalPages = memberBlockResult.total_pages;
            },
            selected(id) {
                return this.memberBlockDetail !== null ? this.memberBlockDetail.id == id : false;
            },
            validMember(member) {
                return member.memberIO !== null && member.nickname !== null && member.hashMemberID !== null;
            },
            getMemberLink(member) {
                return `/members/${member.nickname}.${member.hashMemberID}`;
            },
            async deleteMemberBlock() {
                let response = await this.$swal({
                    title: 'Are you sure?',
                    icon: 'warning',
                    text: ``,
                    showCancelButton: true,
                    confirmButtonText: 'Continue',
                });

                if (response.dismiss) {
                    return;
                }

                try {
                    await dashboardAxios.post('/v1/deleteMemberBlockDetails', {
                        member: this.memberBlockDetail,
                    });
                } catch (error) {
                    await this.alert({ message: 'Unauthorized', type: 'danger' });
                    return;
                }

                this.memberBlockDetails = this.memberBlockDetails.filter(
                    (memberBlock) => memberBlock.id != this.memberBlockDetail.id,
                );

                if (this.memberBlockDetails.length > 0) {
                    this.memberBlockDetail = this.memberBlockDetails[0];
                } else {
                    this.memberBlockDetail = null;
                }
            },
            async onSearchChange(event) {
                if (event.target.value.trim().length === 0 && typeof event.inputType === 'undefined') {
                    this.page = 1;
                    let memberBlockResult = await this.doQuery();
                    this.memberBlockDetails = memberBlockResult.items;
                    this.totalPages = memberBlockResult.total_pages;
                    if (window.history.replaceState) {
                        var newurl = window.location.protocol + '//' + window.location.host + window.location.pathname;
                        window.history.replaceState({ path: newurl }, '', newurl);
                    }
                    if (this.memberBlockDetails.length > 0) {
                        this.memberBlockDetail = this.memberBlockDetails[0];
                    }
                }
            },
        },
    };
</script>
<style scoped>
    .threads-listing-height {
        height: calc(100% - 50px);
    }
</style>
