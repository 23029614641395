<template>
    <Panel title="Create Role">
        <form class="p-4" @submit.prevent="saveRole">
            <div class="flex flex-row -mx-2">
                <div class="w-1/2 mx-2">
                    <label>Role Name*</label>

                    <input
                        v-model="label"
                        type="text"
                        class="p-2 bg-white border border-grey-400 rounded w-full mt-1"
                        required="required"
                    />
                </div>

                <div class="w-1/2 mx-2">
                    <label>Description*</label>

                    <input
                        v-model="description"
                        type="text"
                        class="p-2 bg-white border border-grey-400 rounded w-full mt-1"
                        required="required"
                    />
                </div>
            </div>

            <div class="flex justify-end mt-4">
                <button
                    type="button"
                    class="py-2 px-4 text-grey-800 rounded focus:outline-none bg-grey-100 mr-2"
                    @click="$router.push('/roles')"
                >
                    Back to listing
                </button>

                <button
                    type="submit"
                    class="py-2 px-4 text-white rounded focus:outline-none"
                    :class="loading ? 'bg-pink-lighter' : 'bg-pink-muzmatch'"
                >
                    Save
                </button>
            </div>
        </form>
    </Panel>
</template>

<script>
    import Panel from '../../components/Panels/Panel';
    import { dashboardAxios } from '../../../axios.config';
    import { mapActions } from 'vuex';

    export default {
        name: 'Create',

        components: {
            Panel,
        },

        data() {
            return {
                label: '',
                description: '',
                loading: false,
            };
        },

        methods: {
            ...mapActions(['alert', 'handleError']),

            async saveRole() {
                if (this.loading) {
                    return;
                }

                if (!this.label || !this.description) {
                    this.alert({ message: 'Label and description are required.', type: 'danger' });

                    return;
                }

                this.loading = true;

                try {
                    let response = await dashboardAxios.post('/v1/walirole', {
                        label: this.label,
                        description: this.description,
                    });

                    let role = response.data.result;

                    this.alert({ message: 'Role successfully saved.', type: 'success' });

                    this.loading = false;

                    await this.$router.push('/roles/' + role.label + '.' + role.id);
                } catch (error) {
                    this.handleError({
                        error,
                        callback: () => {
                            this.loading = false;
                        },
                    });
                }
            },
        },
    };
</script>
