<template>
    <div>
        <span
            class="border rounded-full border-grey flex items-center cursor-pointer w-12"
            :class="[{ 'bg-green': active }, switchPosition]"
            @click="toggle"
        >
            <span class="rounded-full border w-6 h-6 border-grey shadow-inner bg-white shadow transition"></span>
        </span>
    </div>
</template>

<script>
    export default {
        props: {
            active: {
                type: Boolean,
                required: true,
                default: false,
            },
        },

        computed: {
            switchPosition() {
                return this.active ? 'justify-end' : 'justify-start';
            },
        },

        methods: {
            toggle() {
                this.$emit('toggleButton');
            },
        },
    };
</script>

<style scoped>
    .transition {
        transition: 0.2s;
    }
</style>
