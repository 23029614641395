<template>
    <input v-model="text" type="text" class="p-2 my-2 border border-grey-300 rounded block w-full rounded" />
</template>

<script>
    export default {
        name: 'CampaignListItem',

        props: {
            index: {
                type: Number,
                required: true,
            },
            value: {
                type: String,
                default: '',
            },
        },

        data() {
            return {
                text: '',
            };
        },

        watch: {
            text() {
                this.$emit('list-value-updated', {
                    index: this.index,
                    value: this.text,
                });
            },
        },

        mounted() {
            this.text = this.value;
        },
    };
</script>
