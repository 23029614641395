<template>
    <div class="p-4 pt-2">
        <template v-if="hintList.length">
            <div
                v-for="(hint, index) in hintList"
                :key="'hint_' + index"
                class="mt-2 flex flex-wrap flex-col md:flex-row"
            >
                <span class="mb-1 font-bold" :class="hint.colour">
                    {{ hint.message }}
                </span>
            </div>
        </template>

        <template v-if="canUseIdentityVerification && session !== null">
            <div class="mt-2 flex flex-wrap flex-col md:flex-row">
                <span class="mr-1 mb-1">
                    Session state:
                    <span class="font-bold" :class="session.state === 'EXPIRED' ? 'text-red-dark' : 'text-green-dark'">
                        {{ session.state }}
                    </span>
                </span>
                <span class="mr-1 mb-1 hidden md:block">∙</span>
                <span class="mr-1 mb-1">
                    Session ID:
                    <span class="font-bold text-grey-700">{{ response.sessionID }}</span>
                </span>
            </div>

            <div class="mt-2 flex flex-wrap flex-col md:flex-row">
                <span class="mr-1 mb-1">
                    Verified status:
                    <span
                        class="font-bold"
                        :class="member.verifiedStatus === 'approved' ? 'text-green-dark' : 'text-red-dark'"
                    >
                        {{ member.verifiedStatus | startCase }}
                    </span>
                </span>
                <span class="mr-1 mb-1 hidden md:block">∙</span>
                <span class="mr-1 mb-1">
                    Date of Birth:
                    <span class="font-bold text-grey-700">
                        {{ member.dobDAY | dateOfBirth }}/{{ member.dobMONTH | dateOfBirth }}/{{ member.dobYEAR }}
                    </span>
                </span>
            </div>

            <div class="mt-2 flex flex-wrap flex-col md:flex-row">
                <span class="mr-1 mb-1">
                    Created at:
                    <span class="font-bold text-grey-700">{{ response.createdAt | datetime }}</span>
                </span>
                <span class="mr-1 mb-1 hidden md:block">∙</span>
                <span class="mr-1 mb-1">
                    Resources expiry at:
                    <span class="font-bold text-grey-700">{{ response.expiryAt | datetime }}</span>
                </span>
            </div>

            <div class="mt-2 flex flex-wrap flex-col md:flex-row">
                <span class="mr-1 mb-1">
                    Failure reasons:
                    <span
                        v-for="(failureReason, index) in response.failureReasons"
                        :key="'failure_' + index"
                        class="font-bold text-grey-700"
                    >
                        <template v-if="index !== 0">∙</template>
                        {{ failureReason }}
                    </span>
                </span>
            </div>

            <div v-if="session.checkList.length" class="mt-4">
                <DashboardLabel>Checks</DashboardLabel>
                <div
                    v-for="(check, index) in session.checkList"
                    :key="'check_' + index"
                    class="mt-2 flex flex-wrap flex-col md:flex-row"
                >
                    <span>
                        {{ check.type | normalCase }}
                        <span class="ml-1">
                            <icon
                                :class="check.value === 'APPROVE' ? 'text-green' : 'text-red'"
                                :icon="check.value === 'APPROVE' ? 'check' : 'times'"
                            />
                        </span>

                        <span v-if="check.reason !== null" class="ml-1 italic text-sm">
                            ({{ check.reason | normalCase }})
                        </span>
                    </span>
                </div>
            </div>

            <template v-if="session.livenessList.length">
                <div
                    v-for="(frameList, index) in session.livenessList"
                    :key="'liveness_' + index"
                    class="mt-6 flex flex-wrap flex-col md:flex-row"
                >
                    <DashboardLabel>
                        Liveness Check
                        <template v-if="session.livenessList.length > 1">(Attempt {{ index + 1 }})</template>
                    </DashboardLabel>

                    <IdentityVerificationPhoto type="liveness" :frame-list="frameList" :liveness-index="index" />
                </div>
            </template>
            <template v-else>
                <div class="mt-6 flex flex-wrap flex-col md:flex-row">
                    <DashboardLabel>Liveness Check</DashboardLabel>
                </div>
                <div class="mt-2 w-full">
                    <p class="text-red-dark font-bold">No images were found as they have expired</p>
                </div>
            </template>

            <template v-if="session.documentList.length">
                <div
                    v-for="(document, index) in session.documentList"
                    :key="'document_' + index"
                    class="mt-6 flex flex-wrap flex-col md:flex-row"
                >
                    <DashboardLabel>
                        Document Check
                        <template v-if="session.documentList.length > 1">(Attempt {{ index + 1 }})</template>
                    </DashboardLabel>

                    <IdentityVerificationPhoto
                        type="document"
                        :frame-list="document.pageMediaList"
                        :liveness-index="index"
                    />

                    <template v-if="'documentFieldList' in document">
                        <div class="mt-3 flex flex-wrap flex-col md:flex-row">
                            <span class="mr-1 mb-1">
                                Type:
                                <span class="font-bold text-grey-700">
                                    <template v-if="'document_type' in document.documentFieldList">
                                        {{ document.documentFieldList.document_type | normalCase }}
                                    </template>
                                    <template v-else>N/A</template>
                                </span>
                            </span>
                            <span class="mr-1 mb-1 hidden md:block">∙</span>
                            <span class="mr-1 mb-1">
                                Issuing country:
                                <span class="font-bold text-grey-700">
                                    <template v-if="'issuing_country' in document.documentFieldList">
                                        <span
                                            :class="{
                                                'text-orange-500': document.documentFieldList.issuing_country === 'USA',
                                            }"
                                        >
                                            {{ document.documentFieldList.issuing_country }}
                                        </span>
                                    </template>
                                    <template v-else>N/A</template>
                                </span>
                            </span>
                            <span class="mr-1 mb-1 hidden md:block">∙</span>
                            <span class="mr-1 mb-1">
                                Issuing authority:
                                <span class="font-bold text-grey-700">
                                    <template v-if="'issuing_authority' in document.documentFieldList">
                                        {{ document.documentFieldList.issuing_authority }}
                                    </template>
                                    <template v-else>N/A</template>
                                </span>
                            </span>
                            <span class="mr-1 mb-1 hidden lg:block">∙</span>
                        </div>

                        <div class="mt-3 flex flex-wrap flex-col md:flex-row">
                            <span class="mr-1 mb-1">
                                Date of Birth:
                                <span class="font-bold text-grey-700">
                                    <template v-if="'date_of_birth' in document.documentFieldList">
                                        {{ document.documentFieldList.date_of_birth | date }}
                                    </template>
                                    <template v-else>N/A</template>
                                </span>
                            </span>
                            <span class="mr-1 mb-1 hidden md:block">∙</span>
                            <span class="mr-1 mb-1">
                                Issue date:
                                <span class="font-bold text-grey-700">
                                    <template v-if="'date_of_issue' in document.documentFieldList">
                                        {{ document.documentFieldList.date_of_issue | date }}
                                    </template>
                                    <template v-else>N/A</template>
                                </span>
                            </span>
                            <span class="mr-1 mb-1 hidden md:block">∙</span>
                            <span class="mr-1 mb-1">
                                Expiration date:
                                <span class="font-bold text-grey-700">
                                    <template v-if="'expiration_date' in document.documentFieldList">
                                        {{ document.documentFieldList.expiration_date | date }}
                                    </template>
                                    <template v-else>N/A</template>
                                </span>
                            </span>
                        </div>
                        <div v-if="document.documentFieldList.issuing_country === 'USA'" class="mt-2 w-full">
                            <p class="text-orange-500 font-bold">US document - they use mm/dd/yyyy date format</p>
                        </div>
                    </template>
                    <template v-else>
                        <div class="mt-2 w-full">
                            <p class="text-orange-500 font-bold">
                                Could not automatically extract any document details
                            </p>
                        </div>
                    </template>
                </div>
            </template>
            <template v-else>
                <div class="mt-6 flex flex-wrap flex-col md:flex-row">
                    <DashboardLabel>Document Check</DashboardLabel>
                </div>
                <div class="mt-2 w-full">
                    <p class="text-red-dark font-bold">No document details were found as they have expired</p>
                </div>
            </template>

            <IdentityVerificationAction
                :task="response"
                :member="member"
                :document-date-of-birth="documentDOB"
                @updated-member="$emit('updated-member', $event)"
            />
        </template>
    </div>
</template>

<script>
    import moment from 'moment';
    import IdentityVerificationPhoto from './IdentityVerification/IdentityVerificationPhoto';
    import IdentityVerificationAction from './IdentityVerification/IdentityVerificationAction';
    import DashboardLabel from '@/components/Labels/DashboardLabel';
    import { dashboardAxios } from '../../../axios.config';
    import { User } from '@/classes/User';
    import { mapGetters } from 'vuex';

    export default {
        name: 'IdentityVerificationCard',
        components: {
            IdentityVerificationPhoto,
            IdentityVerificationAction,
            DashboardLabel,
        },
        filters: {
            fromNow(value) {
                if (!value) {
                    return 'N/A';
                }

                return moment(value).fromNow();
            },

            date(timestamp) {
                return moment(timestamp).format('DD/MM/YYYY');
            },

            datetime(timestamp) {
                return moment(timestamp).format('DD/MM/YYYY HH:mm:ss');
            },

            dateOfBirth(dateNumber) {
                if (dateNumber.length === 1) {
                    return '0' + dateNumber;
                }

                return dateNumber;
            },

            startCase(words) {
                return _.startCase(words);
            },

            normalCase(words) {
                return _.startCase(words.toLowerCase()).replace('Id', 'ID');
            },
        },

        props: {
            identityVerification: {
                type: Object,
                required: false,
                default: null,
            },
            member: {
                type: Object,
                required: true,
            },
            isTaskManager: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                isLoading: false,
                response: null,
                session: null,
                documentDOB: '',
            };
        },

        computed: {
            ...mapGetters({
                staff: 'staff/getStaff',
            }),

            canUseIdentityVerification() {
                return User.make(this.staff).can('task-manager:identity-verification');
            },

            hintList() {
                let hintList = [];
                if (!this.canUseIdentityVerification) {
                    hintList.push({
                        colour: 'text-orange-500',
                        message: 'You do not have permission to view Identity Verification',
                    });

                    return hintList;
                }

                if (this.isLoading) {
                    hintList.push({
                        colour: 'text-grey-dark',
                        message: 'Loading details...',
                    });

                    return hintList;
                }

                if (this.response !== null && moment(this.response.expiryAt).isBefore()) {
                    hintList.push({
                        colour: 'text-orange-500',
                        message:
                            'Images uploaded to 3rd Party may have been deleted for privacy and data protection reasons',
                    });
                }

                if (this.isTaskManager !== false) {
                    return hintList;
                }

                if (this.response === null) {
                    let colour = '';
                    let message = '';
                    switch (this.member.verifiedStatus) {
                        case 'notSubmitted':
                            colour = 'text-orange-500';
                            message = 'This member has not started the Identity Verification process';
                            break;
                        case 'resubmit':
                            colour = 'text-orange-500';
                            message = 'This member has been requested to resubmit ID Verification';
                            break;
                        case 'awaitingApproval':
                            colour = 'text-red-dark';
                            message =
                                'This member is awaiting approval but no ID Verification attempt was found. Please manually review.';
                            break;
                        default:
                            colour = 'text-green';
                            message = 'This member automated Identity Verification process was successful';
                    }

                    hintList.push({
                        colour: colour,
                        message: message,
                    });

                    return hintList;
                }

                if (this.response.actioned === null) {
                    hintList.push({
                        colour: 'text-orange-500',
                        message: 'This is currently in a Task Manager queue',
                    });
                } else {
                    hintList.push({
                        colour: 'text-red-dark',
                        message: 'This is already actioned from a Task Manager queue',
                    });

                    if (this.member.verifiedStatus === 'awaitingApproval') {
                        hintList.push({
                            colour: 'text-orange-500',
                            message:
                                'Support member may have requested more details, only use action buttons when received',
                        });
                    }
                }

                return hintList;
            },
        },

        mounted() {
            if (!this.canUseIdentityVerification) {
                return;
            }

            // Could be false if not Identity Verification task manager they're looking at but manually looking at profile
            if (this.isTaskManager === false) {
                if (this.member.verifiedStatus !== 'notSubmitted') {
                    this.getTaskIfExist();
                }
            } else if (this.identityVerification !== null) {
                this.session = this.identityVerification.session;
                this.response = this.identityVerification.response;
                this.getDocumentDateOfBirth();
            }

            if (this.isTaskManager) {
                this.scrollToPanel();
            }
        },

        methods: {
            async getTaskIfExist() {
                // Could return nothing if the member has never failed an ID Verification
                // This is mainly for if support actioned a task by requesting more documents details
                // and they need to come back to this member manually (not via Task Manager) to approve them
                this.isLoading = true;
                const response = await dashboardAxios.get(
                    `/v1/tasks/identity-verification/member/${this.member.memberID}`,
                );

                if (response.data.result.response) {
                    this.response = response.data.result.response;
                    this.session = response.data.result.session;
                }

                this.getDocumentDateOfBirth();

                this.isLoading = false;
            },

            getDocumentDateOfBirth() {
                this.documentDOB = '';

                if (this.session === null) {
                    return;
                }

                let dateOfBirth = null;
                this.session.documentList.forEach((document) => {
                    if ('documentFieldList' in document && 'date_of_birth' in document.documentFieldList) {
                        dateOfBirth = document.documentFieldList.date_of_birth;
                    }
                });

                if (dateOfBirth !== null) {
                    this.documentDOB = dateOfBirth;
                }
            },

            scrollToPanel() {
                let idVerificationElement = document.getElementById('identity-verification');
                if (idVerificationElement) {
                    idVerificationElement.scrollIntoView({ behavior: 'smooth' });
                }
            },
        },
    };
</script>

<style scoped></style>
