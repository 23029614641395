var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"bg-white shadow-md rounded px-8 pt-6 pb-8 mb-8",on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"mb-4"},[_vm._m(0),_c('div',{staticClass:"flex w-full items-center space-x-2"},[_c('div',{staticClass:"w-10/12"},[_c('date-picker',{attrs:{"mode":"date","masks":_vm.masks,"is-range":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
var isDragging = ref.isDragging;
return [_c('div',{staticClass:"flex flex-col sm:flex-row justify-start items-center"},[_c('div',{staticClass:"relative flex-grow"},[_c('svg',{staticClass:"text-grey-muzmatch w-4 h-full mx-2 absolute pointer-events-none",attrs:{"fill":"none","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"d":"M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"}})]),_c('input',_vm._g({staticClass:"flex-grow pl-8 pr-2 py-1 bg-gray-100 border rounded w-full",class:isDragging ? 'text-grey-muzmatch' : 'text-gray-900',domProps:{"value":inputValue.start}},inputEvents.start))]),_c('span',{staticClass:"flex-shrink-0 m-2"},[_c('svg',{staticClass:"w-4 h-4 stroke-current text-grey-muzmatch",attrs:{"viewBox":"0 0 24 24"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M14 5l7 7m0 0l-7 7m7-7H3"}})])]),_c('div',{staticClass:"relative flex-grow"},[_c('svg',{staticClass:"text-grey-muzmatch w-4 h-full mx-2 absolute pointer-events-none",attrs:{"fill":"none","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"d":"M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"}})]),_c('input',_vm._g({staticClass:"flex-grow pl-8 pr-2 py-1 bg-gray-100 border rounded w-full",class:isDragging ? 'text-grey-muzmatch' : 'text-gray-900',domProps:{"value":inputValue.end}},inputEvents.end))])])]}}]),model:{value:(_vm.range),callback:function ($$v) {_vm.range=$$v},expression:"range"}})],1),_c('icon',{staticClass:"cursor-pointer",attrs:{"icon":"window-close"},on:{"click":function () {
                        _vm.range = {
                            start: null,
                            end: null,
                        };
                    }}})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex"},[_c('span',{staticClass:"block text-grey-muzmatch text-sm text-left font-bold mb-2"},[_vm._v("Select Range")])])}]

export { render, staticRenderFns }