<template>
    <div>
        <Modal v-if="showModal" :click-overlay="() => (this.showModal = false)" size="super-sm">
            <template #body>
                <div class="pb-2"><span class="font-bold">Reassigned due to inactivity</span></div>
                <div>This case has been placed back into the queue due to inactivity for 30 minutes.</div>
            </template>
            <template #footer>
                <IconButton :rounded="true" class="float-right mt-2 mr-2" @click="newTask">Open Next Case</IconButton>
            </template>
        </Modal>
        <ShortcutTool
            v-if="canUseShortcuts && task"
            ref="shortcutTool"
            :context-member-id="contextMemberId"
            :context-task-id="contextTaskId"
        />
        <div class="md:flex sm:flex-row">
            <div class="md:w-1/5 md:px-2 w-full overflow-y-scroll fullscreen pt-1 pb-2 px-2">
                <QueuedTasks
                    :can-use-reviews-panel="canUseReviewsPanel"
                    :can-use-identity-verification="canUseIdentityVerification"
                    @selected="queueSelected"
                />

                <CurrentTask
                    v-if="queue"
                    :queue="queue"
                    :task="task"
                    :selected="taskMember"
                    :moderation-state="moderationState"
                    @selected="memberSelected"
                    @refreshTask="refreshTask"
                />
                <ActionedButton
                    v-if="showActionedButton"
                    :queue="queue"
                    :task="task"
                    :context-type="contextType"
                    :reporter-id="reporterId"
                    :reported-id="reportedId"
                    :moderation-state="moderationState"
                    @actioned="newTask"
                />
                <FlagButton v-if="showFlagButton" :queue="queue" :task="task" @actioned="newTask" />
                <RelatedThreads v-if="task && task.relatedThreads" :task="task" />
                <TagButton v-if="task" :task-id="contextTaskId" :queue="queue" :member="member" />
            </div>

            <div
                v-if="!evenSplit && !isReviewTask && !isPendingBlock && ((isSocial && showSocial) || !isSocial)"
                class="md:w-1/5 md:px-2 sm:w-full py-1"
            >
                <MultiChatPanel
                    :member="member"
                    :member-loading="memberLoading"
                    :sender="taskMember ? String(taskMember.id) : ''"
                    :receiver="muzmatchMemberHash"
                    :queue="queue"
                    :context-type="contextType"
                    :is-report-task="isReportTask"
                    :social="queue ? queue.social : false"
                    context-action="CONTEXT_ACTION_TASK_CHAT_PANEL"
                    custom-style="h-screen-80 flex flex-col"
                    :account-uid="socialAccountUid"
                ></MultiChatPanel>
            </div>

            <div v-if="evenSplit" class="md:w-2/5 md:px-2 sm:w-full pb-4 pt-1">
                <EmailPanel
                    :flagged="queue.url.includes('flagged')"
                    :thread="task.thread"
                    :member-email-addresses="task.memberEmailAddresses"
                    :thread-email-addresses="task.threadEmailAddresses"
                    :context-type="contextType"
                    context-action="CONTEXT_ACTION_TASK_EMAIL_PANEL"
                    @refreshTask="refreshTask"
                ></EmailPanel>
            </div>

            <div v-if="isReviewTask" class="md:w-2/5 md:px-2 sm:w-full pb-4 pt-1">
                <ReviewPanel
                    :review="queue.task"
                    :context-type="contextType"
                    context-action="CONTEXT_ACTION_TASK_APP_REVIEWS_PANEL"
                    @refreshTask="refreshTask"
                />
            </div>

            <div v-if="isPendingBlock && task" class="md:w-1/5 md:px-2 sm:w-full py-1">
                <PendingPanel :pending="task.pendingBlock" :blocked-at="task.pendingBlockAt" :task-id="task.id" />
            </div>

            <div v-if="!isReviewTask" class="md:px-2 sm:w-full py-1" :class="evenSplit ? 'md:w-2/5' : 'md:w-3/5'">
                <DashboardPanel
                    :member-hash-id="taskMember ? taskMember.id : ''"
                    :member="member"
                    :hints="hints"
                    :member-loading="memberLoading"
                    :queue="queue"
                    :task="task"
                    :context-type="contextType"
                    :even-split="evenSplit"
                    :is-report-task="isReportTask"
                    :reporter-id="reporterId"
                    @memberSelected="memberSelected"
                ></DashboardPanel>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { User } from '../classes/User';
    import EventBus from '../helpers/eventBus';
    import FlagButton from './TaskManager/FlagButton';
    import TagButton from './TaskManager/TagButton';
    import RelatedThreads from './TaskManager/RelatedThreads';
    import CurrentTask from './TaskManager/CurrentTask';
    import QueuedTasks from './TaskManager/QueuedTasks';
    import ReviewPanel from '../components/Panels/ReviewPanel';
    import DashboardPanel from '../components/Dashboard/DashboardPanel';
    import EmailPanel from '../components/Panels/EmailPanel';
    import ActionedButton from './TaskManager/ActionedButton';
    import { dashboardAxios } from '../../axios.config';
    import ShortcutTool from '../components/Shortcuts/ShortcutTool';
    import MultiChatPanel from '@/components/Panels/MultiChatPanel';
    import Panel from '../components/Panels/Panel.vue';
    import M2Button from '../components/Buttons/M2Button.vue';
    import Dropdown from '../components/Inputs/Dropdown.vue';
    import PendingPanel from '../components/Panels/PendingPanel.vue';

    export default {
        name: 'TaskManager',

        components: {
            MultiChatPanel,
            ReviewPanel,
            DashboardPanel,
            EmailPanel,
            FlagButton,
            TagButton,
            RelatedThreads,
            CurrentTask,
            QueuedTasks,
            ActionedButton,
            ShortcutTool,
            Panel,
            M2Button,
            Dropdown,
            PendingPanel,
        },

        data() {
            return {
                src: null,
                task: null,
                queue: null,
                taskMember: null,
                member: null,
                hints: {},
                memberLoading: false,
                staffRoles: [],
                muzmatchMemberHash: '69d6354927d12aabab64c15843f5e786cfabe1fba511e6190622eb392856dffb',
                refreshed: 0,
                caseId: null,
                socialAccountUid: null,
                moderationState: 'UNSPECIFIED',
                post: null,
                parentComment: null,
                childComment: null,
                showModal: false,
                hashMemberID: null,
            };
        },

        computed: {
            ...mapGetters({
                staff: 'staff/getStaff',
            }),

            isAdmin() {
                return User.make(this.staff).can('task-manager:admin');
            },

            showActionedButton() {
                if (!this.queue) {
                    return false;
                }

                if (!this.task) {
                    return false;
                }

                if (Array.isArray(this.task)) {
                    return false;
                }

                return true;
            },

            showFlagButton() {
                if (!this.queue) {
                    return false;
                }

                if (!this.task) {
                    return false;
                }

                if (this.queue.social && this.queue.escalated) {
                    return false;
                }

                // Easier to code for in dev/local env
                if (process.env.VUE_APP_ENV !== 'production') {
                    return true;
                }

                return !(this.isAdmin && !this.queue.url.includes('flagged'));
            },

            showSocial() {
                if (this.queue.task.account === null) {
                    return false;
                }

                if (this.queue.task.case === null) {
                    return false;
                }

                if (this.queue.task.socialProfile === null) {
                    return false;
                }

                return true;
            },

            showMultiChatPanel() {
                if (!this.queue) {
                    return false;
                }

                if (!this.queue.task) {
                    return false;
                }

                if (this.queue.social) {
                    if (this.queue.task.account === null) {
                        return false;
                    }

                    if (this.queue.task.case === null) {
                        return false;
                    }

                    if (this.queue.task.socialProfile === null) {
                        return false;
                    }

                    return true;
                }

                if (this.queue.url === 'dating-coach' || this.queue.url === 'dating-coach-flagged') {
                    return true;
                }

                if (
                    this.queue.url === 'pending-block-profiles' ||
                    this.queue.url === 'pending-block-profiles-flagged'
                ) {
                    return true;
                }

                return false;
            },

            evenSplit() {
                if (!this.queue) {
                    return false;
                }

                let evenSplit = ['Email'];

                return evenSplit.includes(this.queue.text);
            },

            canUseShortcuts() {
                return User.make(this.staff).can('shortcut-tool:admin');
            },

            canUseReviewsPanel() {
                return User.make(this.staff).can('task-manager:app-reviews');
            },

            canUseIdentityVerification() {
                return User.make(this.staff).can('task-manager:identity-verification');
            },

            contextType() {
                if (!this.queue || !this.queue.text) {
                    return 'CONTEXT_TYPE_TASK_UNKNOWN';
                }

                switch (this.queue.text) {
                    case 'Approve':
                        return 'CONTEXT_TYPE_TASK_APPROVE';
                    case 'Inappropriate Profile':
                        return 'CONTEXT_TYPE_TASK_INAPPROPRIATE_PROFILE';
                    case 'Inappropriate Message':
                        return 'CONTEXT_TYPE_TASK_INAPPROPRIATE_MESSAGE';
                    case 'Spam/Scam':
                        return 'CONTEXT_TYPE_TASK_SPAM_SCAM';
                    case 'Other Report':
                        return 'CONTEXT_TYPE_TASK_OTHER';
                    case 'Issue':
                        return 'CONTEXT_TYPE_TASK_ISSUE';
                    case 'Payment':
                        return 'CONTEXT_TYPE_TASK_ISSUE_PAYMENTS';
                    case 'Email':
                        return 'CONTEXT_TYPE_TASK_EMAIL';
                    case 'App Reviews':
                        return 'CONTEXT_TYPE_TASK_APP_REVIEWS';
                    case 'Identity Verification':
                        return 'CONTEXT_TYPE_TASK_IDENTITY_VERIFICATION';
                }

                return 'CONTEXT_TYPE_TASK_UNKNOWN';
            },

            reporterId() {
                if (!this.queue || !this.queue.text || !this.task) {
                    return 0;
                }

                let logId = '0';

                switch (this.queue.text) {
                    case 'Approve':
                    case 'Issue':
                    case 'Payment':
                    case 'Identity Verification':
                        logId = this.task['first_member']['logId'];
                        break;
                    case 'Inappropriate Profile':
                    case 'Inappropriate Message':
                    case 'Spam/Scam':
                    case 'Other Report':
                        logId = this.task['second_member']['logId'];
                        break;
                    case 'Email':
                        logId = this.task['first_member'] ? this.task['first_member']['logId'] : 0;
                        break;
                    case 'App Reviews':
                        logId = 0;
                        break;
                    case 'Pending Block':
                        logId = this.task['pendingBlock']['reporterID'] ?? 0;
                }

                return parseInt(logId, 10);
            },

            reportedId() {
                if (!this.queue || !this.queue.text || !this.task) {
                    return 0;
                }

                let logId = '0';

                switch (this.queue.text) {
                    case 'Approve':
                    case 'Issue':
                    case 'Payment':
                    case 'Email':
                    case 'Identity Verification':
                        logId = '0';
                        break;
                    case 'Inappropriate Profile':
                    case 'Inappropriate Message':
                    case 'Spam/Scam':
                    case 'Other Report':
                    case 'Pending Block':
                        logId = this.task['first_member']['logId'];
                        break;
                }

                return parseInt(logId, 10);
            },

            isReviewTask() {
                if (!this.queue || !this.queue.url) return false;

                return this.queue.url === 'app-reviews' || this.queue.url === 'app-reviews-flagged';
            },

            isPendingBlock() {
                if (!this.queue || !this.queue.url) return false;

                return this.queue.url.includes('pending-block');
            },

            isReportTask() {
                if (!this.queue || !this.queue.url) return false;

                let reportTasks = ['Inappropriate Profile', 'Inappropriate Message', 'Spam/Scam', 'Other Report'];

                return reportTasks.includes(this.queue.text) || this.queue.social;
            },

            contextMemberId() {
                if (!this.task || !this.task.first_member) return null;

                return parseInt(this.task.first_member.logId, 10);
            },

            contextTaskId() {
                if (!this.task) return null;

                return parseInt(this.task.id);
            },

            isSocial() {
                if (!this.queue || !this.queue.url) return false;

                return this.queue.social;
            },

            socialReports() {
                return this.queue?.task?.case?.memberReports?.reports ?? [];
            },
        },
        mounted() {
            EventBus.$on('loadMember', this.loadMember);
        },
        beforeDestroy() {
            EventBus.$off('loadMember', this.loadMember);
        },
        methods: {
            ...mapActions(['handleError', 'alert']),

            queueSelected(queue) {
                this.queue = queue;

                if (!queue.task) {
                    this.task = null;
                    this.memberSelected(null);

                    return;
                }

                this.task = queue.task;

                this.memberSelected(this.task.first_member);
                this.loadMember();
            },

            memberSelected(member) {
                this.taskMember = member;
            },

            async newTask(task) {
                this.showModal = false;
                EventBus.$emit('refreshCount');

                if (!task || (Array.isArray(task) && task.length == 0)) {
                    this.taskMember = null;
                    if (this.queue.url == 'dating-coach' || this.queue.url == 'dating-coach-flagged') {
                        this.queue.task = null;
                    }
                    if (
                        this.queue.url == 'pending-block-profiles' ||
                        this.queue.url == 'pending-block-profiles-flagged'
                    ) {
                        this.queue.task = null;
                        this.task = null;
                    }
                    return;
                }

                this.task = task;
                this.queue.task = task;

                this.memberSelected(this.task.first_member);
                this.$refs.shortcutTool.getShortcuts();
            },

            async refreshTask() {
                let response = await dashboardAxios.get('/v1/tasks/' + this.queue.url);
                this.task = response.data.result;
                this.queue.task = response.data.result;
            },

            async loadMember(graceful = false) {
                if (!graceful) {
                    this.memberLoading = true;
                }

                if (!this.taskMember || !this.taskMember.id) {
                    this.member = null;
                    this.hints = {};

                    return;
                }

                try {
                    let result = await dashboardAxios.get(`/v1/members/${this.taskMember.id}`);

                    this.member = result.data.result.data;
                    this.hints = result.data.result.hints;
                } catch (e) {
                    this.handleError({ error: e });
                }

                if (!graceful) {
                    this.memberLoading = false;
                }
            },
        },
    };
</script>

<style>
    .h-screen-80 {
        height: calc(100vh - 8.75rem);
    }

    @media (min-width: 768px) {
        .fullscreen {
            height: calc(100vh - 5.6rem);
        }
    }
</style>
