<template>
    <div v-if="staff" class="p-2 pt-3 md:px-2 md:py-1">
        <nav class="py-2 flex flex-row items-center justify-between text-sm md:text-base">
            <span class="text-xl font-bold m-2 hidden md:block">Wali</span>

            <div class="flex w-custom-nav justify-between items-center mx-2">
                <div ref="Breadcrumb">
                    <Breadcrumb
                        v-if="this.staff.roles"
                        :chevron="dropdownNavActivation"
                        @link-click="handleLinkClick($event)"
                        @chevron-click="handleChevronClick()"
                    />

                    <div ref="dropdownMenu">
                        <DropdownMenu v-if="showMenu" :staff="staff" @changedLink="setMenuName" />
                    </div>
                </div>

                <div
                    v-if="canSearch()"
                    class="sm:py-2 sm:px-3 hover:bg-grey-200 rounded cursor-pointer flex items-center font-bold"
                    @click="showSearch = true"
                >
                    <icon icon="search" class="mr-2 text-grey-800"></icon>
                    Search
                </div>

                <div ref="logout" class="flex items-center">
                    <div
                        class="flex flex-row items-center cursor-pointer text-black font-bold"
                        @click="toggleProfileMenu"
                    >
                        <p v-if="staff">{{ staff.name }}</p>

                        <icon icon="chevron-down" class="text-grey ml-1 md:ml-2"></icon>
                    </div>

                    <div class="relative">
                        <LogoutMenu v-if="showLogoutMenu" @logout="logout" />
                    </div>
                </div>
            </div>
        </nav>

        <!-- Search Modal -->
        <SearchModal :show="showSearch" @closed="showSearch = false" />
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import LogoutMenu from '../../../components/Menus/LogoutMenu';
    import DropdownMenu from '../../../components/Menus/DropdownMenu';
    import Breadcrumb from '../../../components/Breadcrumbs/Breadcrumb';
    import Search from '../../../pages/Members/Search';
    import SearchModal from './SearchModal';
    import { User } from '../../../classes/User';

    export default {
        name: 'Nav',

        components: {
            SearchModal,
            Search,
            Breadcrumb,
            LogoutMenu,
            DropdownMenu,
        },

        data() {
            return {
                showMenu: false,
                showSearch: false,
                showLogoutMenu: false,
                profileLinks: [
                    {
                        name: 'logout',
                        path: '/logout',
                    },
                ],
            };
        },

        computed: {
            ...mapGetters({
                staff: 'staff/getStaff',
            }),

            dropdownNavActivation() {
                return this.staff.roles.length > 1;
            },

            containerWidth() {
                return !this.$route.meta.fullWidth ? 'px-4 sm:w-full lg:w-2/3 mx-auto' : 'px-4';
            },
        },
        created() {
            window.addEventListener('click', this.documentClick);
        },
        destroyed() {
            document.removeEventListener('click', this.documentClick);
        },
        methods: {
            handleLinkClick(path) {
                this.$router.push(path);
            },

            canSearch() {
                return User.make(this.staff).can('members:admin');
            },

            handleChevronClick() {
                this.toggleDisplayMenu();
            },

            toggleDisplayMenu() {
                this.showMenu = !this.showMenu;
            },

            toggleProfileMenu() {
                this.showLogoutMenu = !this.showLogoutMenu;
            },

            setMenuName(e) {
                this.menuName = e.path[0].innerHTML.trim();
                this.toggleDisplayMenu();
            },

            logout() {
                this.$router.replace({ name: 'login' });
                this.requestClearAuth();
            },

            documentClick(e) {
                let dropDown = this.$refs.dropdownMenu;
                let logout = this.$refs.logout;
                let Breadcrumb = this.$refs.Breadcrumb;

                let target = e.target;

                if (Breadcrumb && logout && dropDown) {
                    if (dropDown !== target && !Breadcrumb.contains(target) && !logout.contains(target)) {
                        this.showMenu = false;
                        this.showLogoutMenu = false;
                    }
                }
            },

            ...mapActions({
                requestClearAuth: 'auth/requestClearAuth',
            }),
        },
    };
</script>

<style>
    .w-custom-nav {
        width: 100%;
    }

    @media (min-width: 768px) {
        .w-custom-nav {
            width: calc(100vw - 6rem);
        }
    }
</style>
