<template>
    <nav class="w-full">
        <ol class="flex text-grey-dark">
            <li v-for="(param, index) in params" :key="index" class="flex items-center">
                <span class="text-black font-bold no-underline" @click="handleLinkClick(params, index)">
                    <span
                        class="flex"
                        :class="{ 'cursor-pointer hover:text-pink-dark': isNotLastParam(params, index) }"
                    >
                        <template>
                            <p v-if="isNotLastParam(params, index)" class="mr-1">{{ capitalize(param) }}</p>

                            <p v-else class="mr-1 cursor-pointer" @click="handleChevronClick()">
                                {{ capitalize(param) }}
                            </p>
                        </template>

                        <!-- Show badge if it's approve -->
                        <Badge v-if="param === 'approve'" :number="pendingApprovalNumber" type="danger" />
                    </span>
                </span>

                <span v-if="isNotLastParam(params, index)" class="mx-1">/</span>

                <icon
                    v-if="!isNotLastParam(params, index) && chevron"
                    icon="chevron-down"
                    class="text-grey cursor-pointer ml-1 md:ml-2"
                    @click="handleChevronClick()"
                ></icon>
            </li>
        </ol>
    </nav>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'Breadcrumb',

        props: {
            chevron: {
                type: Boolean,
                required: true,
            },
        },

        computed: {
            params() {
                let pieces = this.$route.fullPath.replace('/', '').split('/');

                return _.map(pieces, (piece) => {
                    let segment = piece.split('?')[0].split('#')[0];
                    segment = this.$route?.name?.includes('app-versions') ? segment : segment.split('.')[0];
                    return decodeURIComponent(segment);
                });
            },

            ...mapGetters({
                pendingApprovalNumber: 'member/getPendingApprovalNumber',
            }),
        },

        methods: {
            buildLink(param) {
                return `/${param}`;
            },

            capitalize(param) {
                return param
                    .replaceAll('-', ' ')
                    .split(' ')
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ');
            },

            isNotLastParam(params, index) {
                return index < params.length - 1;
            },

            handleLinkClick(params, index) {
                if (!this.isNotLastParam(params, index)) {
                    return;
                }

                this.$emit('link-click', this.buildLink(params[index]));
            },

            handleChevronClick() {
                this.$emit('chevron-click');
            },
        },
    };
</script>
