import { dashboardAxios } from '../../../axios.config';

export default {
    namespaced: true,

    state: {
        // The currently selected blast.
        blast: null,

        // All the blasts.
        blasts: [],
    },

    mutations: {
        setBlast(state, blast) {
            state.blast = blast;
        },

        setBlasts(state, blasts) {
            state.blasts = blasts;
        },
    },

    getters: {
        getBlast(state) {
            return state.blast;
        },

        getBlasts(state) {
            return state.blasts;
        },
    },

    actions: {
        /**
         * @param commit
         * @param state
         * @param dispatch
         * @param id
         *
         * @returns {Promise<void>}
         */
        async loadBlasts({ commit, state, dispatch }, id = null) {
            try {
                let response = await dashboardAxios.get('/v1/blasts');

                commit('setBlasts', response.data.result);
            } catch (error) {
                dispatch('handleError', { error }, { root: true });
            }

            if (!state.blast) {
                return;
            }

            id = id ? id : state.blast.id;

            commit('setBlast', _.find(state.blasts, { id }));
        },

        /**
         * @param commit
         * @param blast
         *
         * @returns {Promise<void>}
         */
        async setBlast({ commit }, blast) {
            commit('setBlast', blast);
        },
    },
};
