<script>
    export default {
        name: 'HtmlElement',
        props: {
            node: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {};
        },

        render(createElement) {
            if (this.node.label === 'Image') {
                return createElement(
                    'img',
                    {
                        attrs: {
                            src: `https://s3-eu-west-1.amazonaws.com/${process.env.VUE_APP_WEB_CDN_DOMAIN}/images/email/image+block%401x.png`,
                        },
                        class: {
                            'h-16': true,
                        },
                    },
                    this.node.label,
                );
            }

            if (this.node.tagName === 'hr') {
                return createElement('hr', {
                    class: {
                        'divider-bold': this.node.class.indexOf('divider-bold') !== -1,
                        'divider-thin': this.node.class.indexOf('divider-thin') !== -1,
                        'w-full': true,
                        'mx-3': true,
                    },
                });
            }

            if (this.node.label === 'Button') {
                let buttonClasses = this.node.children[0].class,
                    buttonTextMapping = {
                        'button-small': 'CTA-small',
                        'button-medium': 'CTA-medium',
                        'button-large': 'CTA-large',
                    },
                    foundClass = buttonClasses
                        .split(' ')
                        .map((className) => {
                            if (Object.keys(buttonTextMapping).includes(className)) {
                                return className;
                            }
                        })
                        .filter((value) => value);

                return createElement('p', {}, [
                    createElement(
                        'a',
                        {
                            class: {
                                button: true,
                                'button-small': buttonClasses.includes('button-small'),
                                'button-medium': buttonClasses.includes('button-medium'),
                                'button-large': buttonClasses.includes('button-large'),
                                'button-white': buttonClasses.includes('button-white'),
                                'button-solid': buttonClasses.includes('button-solid'),
                                'button-gold': buttonClasses.includes('button-gold'),
                            },
                            // style: {
                            //     'margin-top': 0
                            // }
                        },
                        buttonTextMapping[foundClass[0]],
                    ),
                ]);
            }

            return createElement(this.node.tagName, {}, this.node.label);
        },
    };
</script>

<style src="./email.css" scoped></style>
