<template>
    <div>
        <div class="flex mb-4">
            <div class="w-full">
                <label><h4>Score (e.g 0.5)</h4></label>
                <div class="flex">
                    <input
                        v-model.number="search.faceScore"
                        class="mr-2 p-2 bg-white border border-grey-400 rounded w-2/5 mt-1"
                        type="number"
                        :min="Math.floor(boundaries.faceScoreRangeMin)"
                        :max="Math.ceil(boundaries.faceScoreRangeMax)"
                        step="any"
                        required
                        @edit="updateSearchParams"
                    />
                </div>
            </div>
        </div>
        <div class="flex mb-4">
            <div class="w-full">
                <label><h4>Area (%)</h4></label>
                <div class="flex">
                    <input
                        v-model.number="search.faceArea"
                        class="mr-2 p-2 bg-white border border-grey-400 rounded w-2/5 mt-1"
                        type="number"
                        :min="Math.floor(boundaries.faceAreaRangeMin)"
                        :max="Math.ceil(boundaries.faceAreaRangeMax)"
                        step="1"
                        required
                        @edit="updateSearchParams"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    /* eslint-disable vue/no-mutating-props */

    import { mapActions } from 'vuex';
    import { dashboardAxios } from '../../../axios.config';

    export default {
        name: 'FaceForm',

        props: {
            boundaries: {
                required: true,
                type: Object,
                default: () => {},
            },

            search: {
                required: true,
                type: Object,
                default: () => {},
            },

            name: {
                required: true,
                type: String,
                default: '',
            },
        },

        methods: {
            updateSearchParams() {
                let name = this.props.name;
                let search = this.props.search;
                this.$emit('updateSearchParams', {
                    name: name,
                    search: {
                        search,
                    },
                });
            },
        },
    };
</script>
