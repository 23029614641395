<template>
    <modal :click-overlay="() => this.$emit('hide')" size="m-l" :no-padding="true">
        <div slot="body">
            <panel title="Filter Matches" :has-margin="false">
                <div class="overflow-auto" style="max-height: 42rem">
                    <table class="sm:w-full text-left table-fixed overflow-x-scroll">
                        <thead>
                            <tr class="bg-grey-200">
                                <th class="px-4 py-2">Name</th>
                                <th class="px-4 py-2">Filter Value</th>
                                <th class="px-4 py-2">Other Member</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(value, key) in content" :key="key" class="border hover:bg-grey-200">
                                <td class="bg-grey-100 text-grey-700 font-bold uppercase px-4 py-2 w-1/3">{{ key }}</td>
                                <td class="bg-grey-100 text-grey-700 font-bold uppercase px-4 py-2 w-1/3 break-words">
                                    {{ value.value }}
                                </td>
                                <td class="bg-grey-100 text-grey-700 font-bold uppercase px-4 py-2 w-1/3 break-words">
                                    {{ value.otherMembers }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </panel>
        </div>
    </modal>
</template>

<script>
    import Modal from '../../Modal/Modal';
    import Panel from '../../Panels/Panel';

    export default {
        components: {
            Modal,
            Panel,
        },
        props: {
            content: {
                type: Object,
                required: true,
            },
        },
    };
</script>
