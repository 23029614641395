<template>
    <div v-if="currentEmailAttachments.length > 0" class="flex flex-col attach-section">
        <DashboardLabel class="block border-t-0.25 pl-2 pt-4 mx-4">
            {{ currentEmailAttachments.length > 1 ? 'Attachments:' : 'Attachment:' }}
        </DashboardLabel>
        <div class="flex flex-wrap items-center pl-4">
            <div v-for="(attachment, index) in mediaAttachments" :key="attachment.gAttachmentId" class="mt-4 mx-4">
                <AttachmentMedia
                    :attachment="attachment"
                    :index="index"
                    @showAttachmentsModal="showAttachmentsModal(index)"
                />
            </div>

            <div v-for="attachment in nonMediaAttachments" :key="attachment.gAttachmentId" class="mt-4 mx-4">
                <AttachmentNonMedia :attachment="attachment" :thread-id="threadId" />
            </div>

            <vue-lightbox ref="lightbox" :media="lightboxMedia" :show-light-box="false" />
        </div>
    </div>
</template>

<script>
    import LightBox from 'vue-it-bigger';
    import DashboardLabel from '../Labels/DashboardLabel';
    import AttachmentModal from './AttachmentModal';
    import AttachmentMedia from './AttachmentMedia';
    import AttachmentNonMedia from './AttachmentNonMedia';
    import EventBus from '../../helpers/eventBus';

    export default {
        name: 'AttachmentSection',

        components: {
            AttachmentMedia,
            AttachmentNonMedia,
            DashboardLabel,
            AttachmentModal,
            'vue-lightbox': LightBox,
        },

        props: {
            email: {
                type: Object,
                required: true,
            },
            attachments: {
                type: Array,
                required: true,
            },
            threadId: {
                type: Number,
                required: true,
            },
        },

        computed: {
            mediaAttachments() {
                let mimeTypes = ['image', 'video'];
                return this.currentEmailAttachments.filter((attachment) => {
                    return mimeTypes.some((mimeType) => attachment.type.includes(mimeType));
                });
            },
            nonMediaAttachments() {
                let mimeTypes = ['image', 'video'];
                return this.currentEmailAttachments.filter((attachment) => {
                    return mimeTypes.every((mimeType) => !attachment.type.includes(mimeType));
                });
            },
            lightboxMedia() {
                if (!this.mediaAttachments) return [];

                return this.mediaAttachments.map(function (attachment) {
                    if (attachment.type.split('/')[0] === 'video') {
                        return {
                            sources: [
                                {
                                    src: attachment.attachmentUrl.replace('s3://', 'https://'),
                                    type: attachment.type === 'video/quicktime' ? 'video/mp4' : attachment.type,
                                },
                            ],
                            type: 'video',
                            width: 800, // Required
                            height: 600, // Required
                            autoplay: true,
                            showThumbs: false,
                        };
                    }

                    return {
                        thumb: attachment.attachmentUrl.replace('s3://', 'https://'),
                        src: attachment.attachmentUrl.replace('s3://', 'https://'),
                    };
                });
            },
            currentEmailAttachments() {
                return this.attachments.filter((attachment) => {
                    return attachment.emailId === this.email.emailId;
                });
            },
        },

        methods: {
            showAttachmentsModal(index) {
                this.$refs.lightbox.showImage(index);
            },
        },
    };
</script>

<style src="vue-it-bigger/dist/vue-it-bigger.min.css"></style>
<style>
    .attach-section video {
        height: 80vh !important;
    }
</style>
