<template>
    <Panel title="Roles">
        <template #header>
            <div class="flex justify-end w-full">
                <div class="p-4 cursor-pointer" @click="$router.push('/roles/add')">
                    <icon icon="plus"></icon>
                </div>
            </div>
        </template>

        <table class="w-full text-left">
            <thead>
                <tr class="bg-grey-200">
                    <th class="px-4 py-2">Role name</th>
                    <th class="px-4 py-2">Description</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="role in roleList"
                    :key="role.id"
                    class="border hover:bg-grey-200 cursor-pointer"
                    @click="$router.push('/roles/' + role.label + '.' + role.id)"
                >
                    <td class="px-4 py-2">{{ role.label }}</td>
                    <td class="px-4 py-2">{{ role.description }}</td>
                </tr>
            </tbody>
        </table>
    </Panel>
</template>

<script>
    import { mapActions } from 'vuex';
    import Panel from '../../components/Panels/Panel';
    import { dashboardAxios } from '../../../axios.config';

    export default {
        name: 'List',

        components: {
            Panel,
        },

        data() {
            return {
                roleList: [],
            };
        },
        created() {
            this.loadRoles();
        },
        methods: {
            ...mapActions(['handleError']),

            async loadRoles() {
                try {
                    let response = await dashboardAxios.get('/v1/walirole');

                    this.roleList = response.data.result;
                } catch (e) {
                    this.handleError({ error: e });
                }
            },
        },
    };
</script>
