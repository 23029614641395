<template>
    <div>
        <AllContentRowsModal v-if="isModalOpen" :content="elasticSearch" @hide="isModalOpen = false" />
        <DashboardLabel class="flex my-3">Misc</DashboardLabel>

        <div class="flex flex-row items-end flex-wrap -mb-2">
            <SecondaryButton
                v-for="(button, index) in buttons"
                v-show="shouldButtonShow(button)"
                :key="index"
                class="mr-1 mb-2"
                :confirmed="!button.modalToggle"
                :text="button.text"
                :confirm-message="button.warning || 'Are you sure?'"
                :handle-click="
                    !!button.modalToggle ? button.modalToggle : update(button.action, button.success || 'Success!')
                "
            />
            <SecondaryButton class="mr-1 mb-2" :confirmed="false" text="ES document" :handle-click="getEsDocument" />
            <SecondaryButton
                class="mr-1 mb-2"
                :confirmed="true"
                text="Reactivate profile"
                :handle-click="
                    post(`/v1/reactivate-marriage-profile`, {
                        accountID: member.accountID,
                        member_id: member.memberID,
                    })
                "
            />
            <div class="flex flex-col mr-1">
                <div class="flex">
                    <input
                        v-model="manualMemberID"
                        type="number"
                        min="1"
                        placeholder="MemberID"
                        class="p-2 border border-grey-300 rounded sm:w-32 mr-2"
                    />
                    <SecondaryButton
                        :disabled="!manualMemberID || manualMemberID === '0'"
                        class="mr-1 mt-1 mb-2"
                        text="Generate Manual Current Made For You"
                        confirm-message="Are you sure?"
                        :handle-click="
                            update(`/v1/members/${this.member.memberID}/generate-current-made-for-you`, 'Success!', {
                                manualMemberID: manualMemberID,
                            })
                        "
                    />
                    <SecondaryButton
                        :disabled="!manualMemberID || manualMemberID === '0'"
                        class="mr-1 mt-1 mb-2"
                        text="Generate Manual Next Made For You"
                        confirm-message="Are you sure?"
                        :handle-click="
                            update(`/v1/members/${this.member.memberID}/generate-next-made-for-you`, 'Success!', {
                                manualMemberID: manualMemberID,
                            })
                        "
                    />
                    <DropdownButton class="mt-1 mr-2" text="Account mode update">
                        <DropdownItem
                            v-for="(value, key) in accountModeDropdown"
                            :key="key"
                            class="sm:w-48"
                            :confirmed="true"
                            :handle-error="handleButtonError"
                            :handle-click="
                                update('/v1/debug-option/account-mode', 'Account mode was updated', {
                                    accountMode: key,
                                })
                            "
                        >
                            {{ value }}
                        </DropdownItem>
                    </DropdownButton>
                </div>
            </div>
        </div>

        <FavouritesModal v-if="showFavouritesModal" :this-member="member" @toggle-modal="toggleShowFavouritesModal" />
    </div>
</template>

<script>
    import DashboardLabel from '@/components/Labels/DashboardLabel';
    import SecondaryButton from '@/components/Buttons/SecondaryButton';
    import { mapActions } from 'vuex';
    import { dashboardAxios } from '../../../../axios.config';
    import FavouritesModal from '@/components/Modal/FavouritesModal';
    import EventBus from '@/helpers/eventBus';
    import DropdownButton from '@/components/Buttons/Dropdown/DropdownButton';
    import DropdownItem from '@/components/Buttons/Dropdown/DropdownItem';
    import AllContentRowsModal from '@/components/Dashboard/Tabs/AllContentRowsModal';

    export default {
        name: 'DebugOptionOther',
        components: {
            DashboardLabel,
            SecondaryButton,
            FavouritesModal,
            DropdownItem,
            DropdownButton,
            AllContentRowsModal,
        },

        props: {
            member: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                showFavouritesModal: false,
                isModalOpen: false,
                elasticSearch: null,
                manualMemberID: null,
                accountModeDropdown: {
                    marriage: 'Marriage Only',
                    social: 'Social Only',
                    marriageAndSocial: 'Marriage & Social',
                },
            };
        },

        computed: {
            buttons() {
                return [
                    {
                        modalToggle: this.toggleShowFavouritesModal,
                        text: 'Add favourite member',
                    },
                    {
                        action: '/v1/boost-expire',
                        text: 'Expire Boost in 2 minutes',
                        success: 'Boost will expire in 2 minutes',
                    },
                    {
                        action: '/v1/delete-profile-now',
                        text: 'Delete profile now (DEV only)',
                        success: 'Profile is deleted',
                    },
                    {
                        action: '/v1/boost-expire-now',
                        text: 'Expire Boost now',
                        success: 'Boost is now expired',
                    },
                    {
                        action: `/v1/liked-you/generate/${this.member.memberID}`,
                        text: 'Generate new likes',
                        success: 'Likes was successfully generated',
                    },
                    {
                        action: `/v1/members/${this.member.memberID}/generate-ic-stack`,
                        text: 'Generate IC stack (10 profiles)',
                        success: 'IC was successfully generated',
                    },
                    {
                        action: `/v1/members/${this.member.memberID}/resend-dating-coach-report`,
                        text: 'Resend dating coach report',
                        warning: 'This will send the report as in-app messages and an email!',
                    },
                    {
                        action: `/v1/members/${this.member.memberID}/cancel-subscriptions`,
                        text: 'Cancel Stripe Subscriptions now',
                        warning: 'This will cancel all stripe subscriptions with immediate effect!',
                    },
                ];
            },
        },

        methods: {
            ...mapActions({
                handleError: 'handleError',
                alert: 'alert',
            }),

            shouldButtonShow(button) {
                if (!('showDevOnly' in button)) return true;

                if (button.showDevOnly === true && process.env.VUE_APP_ENV === 'production') {
                    return false;
                }

                return true;
            },

            toggleShowFavouritesModal() {
                this.showFavouritesModal = !this.showFavouritesModal;
            },

            update(url, message, post = {}) {
                return async () => {
                    let data = {
                        member_id: this.member.memberID,
                        memberID: this.member.memberID,
                        accountID: this.member.accountID,
                    };

                    data = {
                        ...data,
                        ...post,
                    };

                    try {
                        await dashboardAxios.post(url, data);
                        await this.alert({ message, type: 'success' });
                    } catch (error) {
                        await this.handleError({ error });
                    }

                    this.$emit('refresh', true);
                    EventBus.$emit('refresh-chat');
                };
            },

            post(url, body) {
                return async () => {
                    let data = body;

                    try {
                        await dashboardAxios.post(url, data);
                        await this.alert({ message: 'Success', type: 'success' });
                    } catch (error) {
                        await this.handleError({ error });
                    }

                    this.$emit('refresh', true);
                    EventBus.$emit('refresh-chat');
                };
            },

            async handleButtonError(error) {
                await this.handleError({ error });
            },

            async getEsDocument() {
                let response = await dashboardAxios.get(`/v1/esdocument/${this.member.memberID}`);
                this.elasticSearch = response.data.result._source;
                this.isModalOpen = true;
            },
        },
    };
</script>

<style scoped></style>
