<template>
    <div class="h-full">
        <div class="fade sm:flex flex-row sm:-mx-2 h-full">
            <div class="mx-2 sm:w-1/4 flex flex-col flex-shrink-0">
                <div>
                    <input
                        v-model="chatFilter"
                        class="rounded p-2 border-grey-300 border w-full"
                        placeholder="Search..."
                    />
                </div>

                <div class="h-full overflow-y-scroll">
                    <div
                        v-for="conversation in limitedConversations"
                        v-if="conversations"
                        :key="conversation.id"
                        class="hover:bg-grey-100 mt-1 rounded cursor-pointer flex justify-between items-center"
                        :class="activeChat && conversation.id === activeChat.id ? 'bg-grey-100' : ''"
                        @click="setCurrentChatContent(conversation)"
                    >
                        <span class="p-2 notranslate">
                            {{ conversation.otherMember.name }}
                        </span>

                        <div class="mr-2">
                            <router-link
                                v-if="
                                    activeChat &&
                                    conversation.id === activeChat.id &&
                                    getOtherMemberMarriageProfileID(conversation)
                                "
                                :to="'/members?memberId=' + getOtherMemberMarriageProfileID(conversation)"
                                target="_blank"
                            >
                                <icon icon="link" class="hover:text-grey-600 text-black" />
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mx-2 my-2 sm:my-0 sm:w-3/4 h-full overflow-hidden rounded shadow-md mb-3">
                <ChatPanel
                    class="w-full h-full"
                    :sender="profile.socialProfileUID"
                    :receiver="getOtherMemberSocialProfileUID(activeChat)"
                    :match-id="activeChat ? activeChat.id : null"
                    :context-type="contextType"
                    context-chat="SOCIAL"
                    context-action="CONTEXT_ACTION_SOCIAL_MEMBER_CHAT_PANEL"
                    custom-style="tab-chat-height flex flex-col"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import ChatPanel from '../../Panels/ChatPanel';
    import { mapActions } from 'vuex';

    export default {
        name: 'SocialChatsTab',
        components: {
            ChatPanel,
        },
        props: {
            profile: {
                type: Object,
                required: true,
            },
            conversations: {
                type: Array,
                required: true,
            },
            activeChat: {
                type: Object,
                default: null,
            },
            contextType: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                chatFilter: '',
                conversationsLoading: false,
            };
        },
        computed: {
            limitedConversations() {
                return [...this.filteredConversations];
            },

            filteredConversations() {
                if (!this.chatFilter) {
                    return this.conversations;
                }

                return _.filter(this.conversations, this.filterConversation);
            },
        },
        methods: {
            ...mapActions({
                handleError: 'handleError',
            }),

            setCurrentChatContent(conversation) {
                this.$emit('set-current-chat-content', conversation);
            },

            filterConversation(conversation) {
                if (!this.profile) {
                    return true;
                }

                return conversation.otherMember.name.toLowerCase().includes(this.chatFilter.toLowerCase());
            },

            getOtherMemberMarriageProfileID(conversation) {
                return conversation.otherMember.marriageProfileID;
            },

            getOtherMemberSocialProfileUID(conversation) {
                if (!conversation) {
                    return null;
                }

                return conversation.otherMember.socialProfileUID;
            },
        },
    };
</script>

<style scoped></style>
