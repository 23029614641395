<template>
    <div class="rounded-t bg-white shadow-md mt-4">
        <div class="pt-4 px-4 font-bold">
            <div class="text-grey-muzmatch">Tags</div>
        </div>
        <div class="p-4">
            <Multiselect
                v-model="taskTags"
                :options="allTags"
                track-by="id"
                label="name"
                :multiple="true"
                class="mt-1 w-full"
                :taggable="true"
                @select="saveTagForTask"
                @remove="removeTagFromTask"
                @tag="saveNewTag"
            />
        </div>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect';
    import { dashboardAxios } from '../../../axios.config';

    export default {
        name: 'TagButton',
        components: {
            Multiselect,
        },
        props: {
            taskId: {
                type: Number,
                required: true,
            },
            queue: {
                type: Object,
                required: true,
            },
            member: {
                type: Object,
                default: () => {},
            },
        },
        data() {
            return {
                taskTags: [],
                allTags: [],
            };
        },
        watch: {
            taskId() {
                this.updateTags();
            },
        },
        async mounted() {
            await this.updateTags();
        },
        methods: {
            async saveTagForTask(task) {
                await dashboardAxios.post(`/v1/issue-tracker/${this.taskId}/${task.id}`, {
                    taskType: this.queue.url,
                    memberID: this.member ? this.member.memberID : null,
                    deviceOS: this.member ? this.member.deviceOS : null,
                    appVersion: this.member ? this.member.appVersion : null,
                });
            },
            async removeTagFromTask(task) {
                await dashboardAxios.delete(`/v1/issue-tracker/${this.taskId}/${task.id}`, {
                    params: { taskType: this.queue.url },
                });
            },
            async saveNewTag(newTag) {
                let response = await this.$swal({
                    text: `Create new tag: ${newTag}?`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'Cancel',
                });

                if (response.dismiss) {
                    return;
                }

                let tag = {
                    name: newTag,
                };

                let result = await dashboardAxios.post(`/v1/issue-tracker`, {
                    tagName: newTag,
                });

                tag.id = result.data.result;

                await this.saveTagForTask(tag);
                this.allTags.push(tag);
                this.taskTags.push(tag);
            },
            async updateTags() {
                let tags = await dashboardAxios.get(`/v1/issue-tracker/${this.taskId}`, {
                    params: {
                        taskType: this.queue.url,
                    },
                });

                this.taskTags = tags.data.result;

                tags = await dashboardAxios.get(`/v1/issue-tracker`);
                this.allTags = tags.data.result;
            },
        },
    };
</script>
