<template>
    <DashboardSectionCard v-if="Object.values(hints).length > 0" class="mb-4 p-4 alert_danger">
        <div v-for="(hint, i) in hints" :key="i">
            <div v-if="i === 'missingFields'">
                <p v-for="(type, id) in hint" :key="id">
                    Missing
                    <b v-if="type === 'selfie'">Selfie Verification</b>
                    <b v-else-if="type === 'main'">Main Photo</b>
                    <b v-else>{{ hint }}</b>
                </p>
            </div>
            <p v-else-if="i === 'niqab'">
                User has specified that they wear
                <b>Niqab</b>
            </p>
            <p v-else-if="i === 'failedAutoAttempts'">
                <b>Auto-approval attempts</b>
                exceeded
            </p>
            <div v-else-if="i === 'photoObscenity'">
                <p v-for="(type, l) in hint" :key="l">
                    <b v-if="type === 'selfie'">Selfie Verification</b>
                    <b v-else-if="type === 'main'">Main Photo</b>
                    <b v-else-if="type === 'video_intro'">Video intro</b>
                    <b v-else>Photo {{ type }}</b>
                    has been flagged as obscene
                </p>
            </div>
            <p v-else-if="i === 'photoVerificationLiveness'">
                <b>Verification Photo</b>
                liveness detection returned
                <b>{{ hint }}</b>
            </p>
            <div v-else-if="i === 'textAnalysis'">
                <div v-for="(h, field) in hint" :key="field">
                    <div v-for="(list, type) in h" :key="type">
                        <p v-if="type === 'gibberish'">
                            <b v-if="field === 'longDescription'">About Me</b>
                            <b v-else-if="field === 'icebreakers'">Icebreakers</b>
                            has been flagged as gibberish
                        </p>
                        <p v-if="type === 'flagged'">
                            <b v-if="field === 'nickname'">Nickname</b>
                            <b v-else-if="field === 'longDescription'">About Me</b>
                            <b v-else-if="field === 'statusMessage'">Status Message</b>
                            <b v-else-if="field === 'jobTitle'">Job Title</b>
                            <b v-else-if="field === 'companyOfEmployment'">Employee</b>
                            <b v-else-if="field === 'icebreakers'">Icebreakers</b>
                            <b v-else-if="field === 'marriageHorizonInfo'">Marriage Intentions</b>
                            contains {{ list.length }} flagged word(s): {{ list.join(', ') }}
                        </p>
                        <p v-if="type === 'dhsTerms'">
                            <b v-if="field === 'nickname'">Nickname</b>
                            <b v-else-if="field === 'longDescription'">About Me</b>
                            <b v-else-if="field === 'statusMessage'">Status Message</b>
                            <b v-else-if="field === 'jobTitle'">Job Title</b>
                            <b v-else-if="field === 'companyOfEmployment'">Employee</b>
                            <b v-else-if="field === 'icebreakers'">Icebreakers</b>
                            <b v-else-if="field === 'marriageHorizonInfo'">Marriage Intentions</b>
                            contains {{ list.length }} DHS term(s): {{ list.join(', ') }}
                        </p>
                    </div>
                </div>
            </div>
            <p v-else-if="i === 'findReports'">
                Member reported
                <b>{{ hint.count }}</b>
                times on
                <b>{{ hint.date }}</b>
                <span v-if="hint.foundCount === 1">(1 previous report))</span>
                <span v-else-if="hint.foundCount >= 1">({{ hint.foundCount }} previous report(s))</span>
            </p>
            <p v-else-if="i === 'suspectCountry'">
                Member is from
                <b>{{ Object.values(hint).join(', ') }}</b>
                and requires manual approval
            </p>
            <p v-else-if="i === 'matureSuspectMember'">
                Member is
                <b>50+</b>
                and at least 2 provided country codes are different:
                <b>{{ Object.values(hint.countries).join(', ') }}</b>
            </p>
            <p v-else-if="i === 'countryIssues'">
                At least 3 provided country codes are different:
                <b>{{ Object.values(hint).join(', ') }}</b>
            </p>
            <div v-else-if="i == 'photoFlagged'">
                <p>
                    <strong>Profile Media</strong>
                    has been flagged as a previously blocked member has used the same media
                </p>
                <div class="mt-2">
                    <button class="underline" @click="showModal = true">Click me to view images</button>
                </div>
                <MediaHintModal v-if="showModal" :content="hint[0].flaggedMedia.paths" @hide="showModal = false" />
            </div>
            <div v-else-if="i === 'faceBanned' && typeof hint === 'string'">
                <p>{{ hint }}</p>
            </div>
            <div v-else-if="i === 'faceBanned'">
                <p>
                    <strong>Selfie</strong>
                    has been flagged as it's similar to a previously blocked member
                </p>
                <div class="mt-2">
                    <button class="underline" @click="showModal = true">Click me to view images</button>
                </div>
                <MediaHintModal v-if="showModal" :content="hint[0].flaggedMedia.paths" @hide="showModal = false" />
            </div>
            <div v-else-if="i === 'pendingProfileImageUploads'">
                <p v-for="(type, id) in hint" :key="id">
                    <b v-if="type === 'selfie'">Selfie Verification</b>
                    <b v-else-if="type === 'main'">Main photo</b>
                    <b v-else>Photo {{ type }}</b>
                    is pending upload
                </p>
            </div>
            <div v-else-if="i === 'profileImageIssues'">
                <p v-for="(type, id) in hint" :key="id">
                    <b v-if="type === 'selfie'">Selfie Verification</b>
                    <b v-else-if="type === 'main'">Main photo</b>
                    <b v-else>Photo {{ type }}</b>
                    is missing face comparison data
                </p>
            </div>
            <p v-else-if="i === 'adminSpoof'">
                <b>Admin spoof active</b>
            </p>
            <p v-else-if="i === 'duplicateAccounts'">
                Found
                <b>{{ hint }}</b>
                duplicate accounts
            </p>
            <p v-else-if="i === 'nickname'">
                Suspicious nickname
                <b>{{ hint }}</b>
            </p>
            <p v-else-if="i === 'ethnicityOriginAndGrouping'">
                Suspicious ethnicity
                <b v-html="hint.label"></b>
            </p>
            <p v-else-if="i === 'pendingBlock'">
                Pending Block Reason:
                <b>{{ hint }}</b>
            </p>
        </div>
    </DashboardSectionCard>
</template>

<script>
    import MediaHintModal from '../Modal/MediaHintModal';
    import DashboardSectionCard from '@/components/Cards/DashboardSectionCard';

    export default {
        name: 'DashboardHints',
        components: { MediaHintModal, DashboardSectionCard },
        props: {
            hints: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                showModal: false,
            };
        },
        methods: {
            isString(item) {
                return typeof item === 'string';
            },

            isFlaggedMedia(item) {
                if (item !== Object(item)) {
                    return false;
                }

                return true;
            },
        },
    };
</script>

<style scoped>
    .alert_danger {
        color: #721c24;
        background-color: #fcebea;
        border: 1px solid #f5c6cb;
    }

    .alert_danger div + div {
        margin-top: 10px;
    }
</style>
