<template>
    <div>
        <Modal v-if="showModal" :click-overlay="() => (this.showModal = false)">
            <template #header>
                <IconButton
                    :rounded="true"
                    class="float-right right-0 top-0 absolute mt-2 mr-2"
                    @click="showModal = false"
                >
                    Close
                </IconButton>
            </template>

            <template #body>
                <table class="w-full text-left table-fixed mb-4">
                    <thead>
                        <tr class="bg-grey-200">
                            <th class="px-4 py-2 w-48">Key</th>
                            <th class="px-4 py-2">Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-if="showModalType === 'ALL'">
                            <tr
                                v-for="t in filteredTransaction"
                                :key="t.id"
                                class="border hover:bg-grey-200 cursor-pointer"
                            >
                                <td class="px-4 py-2 break-words font-bold">{{ t.key }}</td>
                                <td class="px-4 py-2 break-words">{{ formatTimestamp(t.key, t.value) }}</td>
                            </tr>
                        </template>
                        <template v-else>
                            <tr
                                v-for="t in filteredSimpaisaTransaction"
                                :key="t.key"
                                class="border hover:bg-grey-200 cursor-pointer"
                            >
                                <td class="px-4 py-2 break-words font-bold">{{ t.key }}</td>
                                <td class="px-4 py-2 break-words">{{ formatTimestamp(t.key, t.value) }}</td>
                            </tr>
                        </template>
                    </tbody>
                </table>
                <p v-if="showModalType === 'SIMPAISA'">
                    Product has been cancelled and they no longer have access but a manual step is required to action
                    the refund. Please send the above information to the revenue squad to action.
                </p>
            </template>
        </Modal>

        <div v-if="!loading && transactions.length !== 0">
            <div class="overflow-x-scroll">
                <table class="w-full text-left table-fixed block overflow-scroll">
                    <thead>
                        <tr class="bg-grey-200">
                            <th class="px-4 py-2 xl:w-64">Product</th>
                            <th class="px-4 py-2 xl:w-64">Bought At</th>
                            <th class="px-4 py-2 xl:w-48">Gateway</th>
                            <th class="px-4 py-2 xl:w-48">Source</th>
                            <th class="px-4 py-2 xl:w-32">Actioned</th>
                            <th class="px-4 py-2 w-24">Price</th>
                            <th v-if="isAdmin" class="px-4 py-2 w-24">View</th>
                            <th v-if="isAdmin" class="px-4 py-2 w-24">Action</th>
                            <th v-if="isAdmin" class="px-4 py-2 w-24">Action</th>
                            <th v-if="isAdmin" class="px-4 py-2 w-24">Receipt</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="transaction in transactions"
                            :key="transaction.id"
                            class="border hover:bg-grey-200 cursor-pointer"
                            :class="{ 'bg-pink-lighter': profile.memberID !== transaction.memberID }"
                        >
                            <td class="px-4 py-2 break-words" @click="open(transaction)">
                                <p class="font-mono font-bold">{{ transaction.productID }}</p>
                                <p class="mt-1">{{ transaction.timestamp | time }}</p>
                            </td>

                            <td class="px-4 py-2 break-words" @click="open(transaction)">
                                <span class="font-bold">
                                    {{ transaction.boughtAt | time }} - {{ transaction.expiryDate | time }}
                                </span>

                                <div class="mt-1">
                                    <span>{{ transaction.deviceOS }}</span>
                                    -
                                    <span class="ml-1">{{ transaction.appVersion || 'Unknown' }}</span>
                                </div>
                            </td>

                            <td class="px-4 py-2 break-words font-mono font-bold text-sm" @click="open(transaction)">
                                {{ transaction.paymentGateway }}
                            </td>

                            <td class="px-4 py-2 break-words font-mono font-bold text-sm" @click="open(transaction)">
                                {{ transaction.source }}
                            </td>

                            <td class="px-4 py-2 break-words" @click="open(transaction)">
                                <icon v-if="transaction.actioned === '1'" icon="check"></icon>
                            </td>

                            <td class="px-4 py-2 break-words" @click="open(transaction)">{{ price(transaction) }}</td>

                            <td v-if="isAdmin" class="px-4 py-2 break-words">
                                <a
                                    v-if="isStripe(transaction)"
                                    class="text-pink-muzmatch"
                                    :href="getLink(transaction)"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    @click.stop
                                >
                                    <icon icon="link"></icon>
                                </a>
                            </td>
                            <td v-if="isAdmin" class="px-4 py-2 break-words">
                                <div v-if="isStripe(transaction) && isSub(transaction)">
                                    <div v-if="canRefund(transaction)">
                                        <div>
                                            <IconButton
                                                :rounded="true"
                                                :is-disabled="isProcessing"
                                                @click.stop="refund(transaction, true)"
                                            >
                                                <span v-if="isProcessingID === transaction.id">Processing...</span>
                                                <span v-else>Partial Refund</span>
                                            </IconButton>
                                        </div>
                                    </div>
                                    <span v-else>Already refunded</span>
                                </div>
                                <div v-else-if="isSimpaisa(transaction) && isSub(transaction)">
                                    <div v-if="canCancel(transaction)">
                                        <div>
                                            <IconButton
                                                :rounded="true"
                                                :is-disabled="isProcessing"
                                                @click.stop="cancel(transaction)"
                                            >
                                                <span v-if="isProcessingID === transaction.id">Processing...</span>
                                                <span v-else>Cancel</span>
                                            </IconButton>
                                        </div>
                                    </div>
                                    <span v-else>
                                        <template v-if="transaction.cancellationDate !== null">
                                            Already cancelled
                                        </template>
                                        <template v-else>Can't cancel</template>
                                    </span>
                                </div>
                            </td>
                            <td v-if="isAdmin" class="px-4 py-2 break-words">
                                <div v-if="['REFUND_NOW', 'REFUND_REQUEST'].includes(getRefundType(transaction))">
                                    <div v-if="canRefund(transaction)">
                                        <div>
                                            <IconButton
                                                :rounded="true"
                                                :is-disabled="isProcessing"
                                                @click.stop="refund(transaction)"
                                            >
                                                <span v-if="isProcessingID === transaction.id">Processing...</span>
                                                <span v-else>
                                                    <template v-if="getRefundType(transaction) === 'REFUND_NOW'">
                                                        Full Refund
                                                    </template>
                                                    <template v-else>Refund Request</template>
                                                </span>
                                            </IconButton>
                                        </div>
                                    </div>
                                    <span v-else>
                                        <template v-if="getRefundType(transaction) === 'REFUND_NOW'">
                                            Already refunded
                                        </template>
                                        <template v-else-if="getRefundType(transaction) === 'REFUND_REQUEST'">
                                            <div>
                                                <IconButton
                                                    type="success"
                                                    :rounded="true"
                                                    @click.stop="viewSimpaisaRefundRequest(transaction)"
                                                >
                                                    <span>
                                                        <template>View Refund Request</template>
                                                    </span>
                                                </IconButton>
                                            </div>
                                        </template>
                                    </span>
                                </div>
                            </td>
                            <td v-if="isAdmin" class="px-4 py-2 break-words" @click.stop="receipt(transaction)">
                                <div v-if="isStripe(transaction)">
                                    <IconButton
                                        :rounded="true"
                                        :is-disabled="isLoadingReceipt"
                                        @click.stop="receipt(transaction)"
                                    >
                                        <span v-if="isLoadingReceipt === transaction.id">Loading...</span>
                                        <span v-else>View</span>
                                    </IconButton>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div v-else class="flex justify-center items-center font-bold">
            <div class="my-4">
                <span v-if="!loading">No transactions to show.</span>
                <span v-else>Loading...</span>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import { dashboardAxios } from '../../../../axios.config';
    import { User } from '../../../classes/User';
    import { formatTimestamp } from '../../../helpers/DashboardUtils';
    import IconButton from '@/components/Buttons/IconButton.vue';
    import moment from 'moment';

    export default {
        name: 'TransactionsTab',
        components: { IconButton },

        filters: {
            time(value) {
                if (!value) {
                    return 'Unknown';
                }

                return moment(value).format('DD/MM/YYYY');
            },

            trim(value) {
                if (!value) {
                    return '';
                }

                if (value.toString().length < 30) {
                    return value.toString();
                }

                return '...' + value.toString().slice(-30);
            },
        },
        props: {
            profile: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                loading: false,
                isProcessing: false,
                isProcessingID: null,
                isLoadingReceipt: false,
                transactions: [],
                showModal: false,
                showModalType: 'ALL',
                selectedTransaction: null,
            };
        },

        computed: {
            ...mapGetters({
                staff: 'staff/getStaff',
            }),

            filteredTransaction() {
                if (!this.selectedTransaction) {
                    return [];
                }

                return _.map(Object.keys(this.selectedTransaction), (key) => ({
                    key,
                    value: this.selectedTransaction[key],
                }));
            },

            filteredSimpaisaTransaction() {
                if (!this.selectedTransaction) {
                    return [];
                }

                const fieldsToShow = ['transactionID', 'phoneNumber', 'merchant', 'timestamp', 'localPrice'];
                return fieldsToShow
                    .filter((key) => this.selectedTransaction.hasOwnProperty(key))
                    .map((key) => ({ key, value: this.selectedTransaction[key] }));
            },
            isAdmin() {
                return User.make(this.staff).can('task-manager:admin');
            },
        },
        mounted() {
            this.loadTransactions();
        },
        methods: {
            ...mapActions({
                handleError: 'handleError',
                alert: 'alert',
            }),

            formatTimestamp,

            open(transaction, type = 'ALL') {
                this.selectedTransaction = transaction;
                this.showModal = true;
                this.showModalType = type;
            },

            isStripe(transaction) {
                return transaction.paymentGateway === 'STRIPE';
            },

            isSimpaisa(transaction) {
                return transaction.paymentGateway === 'SIMPAISA';
            },

            isGoogle(transaction) {
                return transaction.paymentGateway === 'GOOGLE';
            },

            isSub(transaction) {
                return transaction.productID.includes('premium');
            },

            price(transaction) {
                let price = transaction.price;

                if (!price) {
                    return '£0.00';
                }

                return '£' + Number.parseFloat(price).toFixed(2);
            },

            getLink(transaction) {
                if (transaction.paymentGateway === 'STRIPE') {
                    const test = process.env.VUE_APP_ENV !== 'production' ? '/test/' : '/';
                    return `https://dashboard.stripe.com${test}payments/${transaction.transactionID}`;
                }

                return '#';
            },

            canRefund(transaction) {
                return (
                    transaction.actioned === '1' &&
                    transaction.refundedAt === null &&
                    ['REFUND_NOW', 'REFUND_REQUEST'].includes(this.getRefundType(transaction))
                );
            },

            canCancel(transaction) {
                return (
                    transaction.cancellationDate === null &&
                    transaction.refundedAt === null &&
                    moment(transaction.expiryDate).isAfter(moment()) &&
                    transaction.paymentGateway === 'SIMPAISA'
                );
            },

            getRefundType(transaction) {
                const paymentGateway = transaction.paymentGateway;
                if (['STRIPE', 'PAYMOB', 'GOOGLE'].includes(paymentGateway)) {
                    return 'REFUND_NOW';
                }

                if (paymentGateway === 'SIMPAISA') {
                    return 'REFUND_REQUEST';
                }

                return '';
            },

            async refund(transaction, partial = null) {
                if (!this.canRefund(transaction)) {
                    return;
                }

                this.isProcessing = true;

                let title = 'Are you sure you want to refund this transaction?';
                let text = '';
                if (this.isSimpaisa(transaction)) {
                    title = 'Are you sure you want to set this transaction as refunded?';
                    text =
                        'This will remove either Gold or consumable, but money will not be returned until we send a request to Simpaisa. Please check the output after Refund Request.';
                } else if (this.isGoogle(transaction)) {
                    text =
                        "This will immediately refund this purchase. If it's a consumable, we will make an attempt to deduct the corresponding credits. If it's a subscription, future renewals will proceed unless they choose to cancel. It will remove Gold if we're refunding their last subscription else their Gold will persist.";
                } else {
                    text =
                        'If this is a subscription, it will be canceled with immediate effect. Gold will be disabled too if the product being refunded is a Gold membership (both consumable and sub).';
                }

                const modal = await this.$swal({
                    title: title,
                    text: text,
                    icon: 'warning',
                    allowOutsideClick: false,
                    showCancelButton: true,
                    confirmButtonText: this.getRefundType(transaction) === 'REFUND_NOW' ? 'Refund' : 'Refund Request',
                });

                if (!modal.isConfirmed) {
                    this.isProcessing = false;
                    return;
                }

                this.isProcessingID = transaction.id;

                const { id, memberID, transactionID, merchant, paymentGateway } = transaction;

                let url = `v1/members/${memberID}/transactions/${transactionID}/refund`;
                if (!this.isStripe(transaction)) {
                    url = `v1/members/${memberID}/transactions/${paymentGateway}/${id}/refund`;
                }

                dashboardAxios
                    .post(url, { merchant: merchant }, { params: { partial } })
                    .then((resp) => {
                        this.loadTransactions();

                        if (this.isSimpaisa(transaction)) {
                            this.viewSimpaisaRefundRequest(transaction);
                        }

                        if (resp.data.result && resp.data.result.hasOwnProperty('success')) {
                            const msgType = resp.data.result.success === true ? 'success' : 'danger';
                            this.alert({ message: resp.data.result.message, type: msgType });
                        }
                    })
                    .catch((err) => this.handleError({ err }))
                    .finally(() => {
                        this.isProcessing = false;
                        this.isProcessingID = null;
                    });
            },

            async viewSimpaisaRefundRequest(transaction) {
                const { id } = transaction;

                dashboardAxios
                    .get(`/v1/members/transactions/simpaisa/${id}/view-refund-request`)
                    .then((response) => {
                        this.open(response.data.result, 'SIMPAISA');
                    })
                    .catch((err) => this.handleError({ err }));
            },

            async cancel(transaction) {
                if (!this.canCancel(transaction)) {
                    return;
                }

                this.isProcessing = true;

                const modal = await this.$swal({
                    title: 'Are you sure you want to cancel this subscription?',
                    text: 'Their Gold will not end straight away but will end when it is time for renewal.',
                    icon: 'warning',
                    allowOutsideClick: false,
                    showCancelButton: true,
                    confirmButtonText: 'Cancel Subscription',
                });

                if (!modal.isConfirmed) {
                    this.isProcessing = false;
                    return;
                }

                this.isProcessingID = transaction.id;
                const { id, memberID, merchant } = transaction;

                dashboardAxios
                    .post(`v1/members/${memberID}/transactions/simpaisa/${id}/cancel`, { merchant: merchant })
                    .then(() => {
                        this.loadTransactions();
                    })
                    .catch((err) => this.handleError({ err }))
                    .finally(() => {
                        this.isProcessing = false;
                        this.isProcessingID = null;
                    });
            },

            async receipt(transaction, partial = null) {
                if (!this.isStripe(transaction)) {
                    return;
                }

                this.isLoadingReceipt = true;

                const { transactionID } = transaction;

                dashboardAxios
                    .get(`v1/members/transactions/${transactionID}/receipt`)
                    .then((res) => {
                        if (!res.data.result.url) {
                            this.alert({ message: 'Unable to find receipt.', type: 'danger' });
                        }

                        window.open(res.data.result.url, '_blank');
                    })
                    .catch((err) => this.handleError({ err }))
                    .finally(() => (this.isLoadingReceipt = false));
            },

            async loadTransactions() {
                if (this.loading) {
                    return;
                }

                this.loading = true;

                try {
                    let response = await dashboardAxios.get(
                        '/v1/members/' + this.profile.hashMemberID + '/transactions',
                    );

                    this.transactions = response.data.result;
                } catch (error) {
                    this.handleError({ error });
                }

                this.loading = false;
            },
        },
    };
</script>
