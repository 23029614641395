<template>
    <div>
        <div v-for="(media, mediaIndex) in message.media" :key="mediaIndex">
            <div
                v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, media.mediaID)"
                class="p-3 font-bold inline-block break-words cursor-pointer w-80"
                :class="itemClass"
                @click="selectMessage(message)"
            >
                <reply-to-contents :message="message" :sender="sender"></reply-to-contents>

                <div class="pb-1 flex justify-center relative">
                    <div>{{ media.type }}</div>
                    <div v-if="media.obscenityFlagged" class="pl-2">FLAGGED</div>
                </div>

                <div v-show="!hidden">
                    <img
                        v-if="media.type === 'IMAGE'"
                        alt="Image sent in chat"
                        :src="media.urls.file"
                        class="w-full"
                        :height="media.blur_hash.height"
                        @click="toggleModal(media.urls.file)"
                    />

                    <video v-if="media.type === 'VIDEO'" controls class="focus:outline-none w-full">
                        <source :src="media.urls.file" :height="media.blur_hash.height" />
                    </video>

                    <video v-if="media.type === 'VIDEO_GIF'" loop autoplay class="focus:outline-none w-full">
                        <source :src="media.urls.file" :height="media.blur_hash.height" />
                    </video>
                </div>

                <div v-show="hidden">
                    <div v-if="isVisual(media)" class="relative">
                        <blur-hash-canvas
                            :hash="media.blur_hash.hash"
                            class="w-full"
                            :width="media.blur_hash.width"
                            :height="media.blur_hash.height"
                        />

                        <div class="absolute w-full h-full" style="top: 0; left: 0">
                            <div class="flex justify-center items-center h-full">
                                <span
                                    class="p-2 text-white border border-white rounded hover:bg-white hover:text-black cursor-pointer"
                                    @click="hidden = false"
                                >
                                    Reveal
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <audio v-if="media.type === 'AUDIO'" controls class="focus:outline-none max-w-xs">
                    <source :src="media.urls.file" />
                </audio>

                <div v-if="!['IMAGE', 'AUDIO', 'VIDEO', 'VIDEO_GIF'].includes(media.type)" class="max-w-xs">
                    Unknown media format
                </div>

                <obscene-output v-if="isVisual(media)" :obscenity-output="obscenityOutput" />

                <p v-if="messageSelected" :class="timeClass">
                    {{ message.created_at | time }}
                </p>

                <chat-visibility-disclaimer :message="message"></chat-visibility-disclaimer>
            </div>

            <vue-easy-lightbox
                move-disabled
                :visible="showModal"
                :imgs="[media.urls.file]"
                :index="0"
                @hide="showModal = false"
            />

            <div class="w-full m-1">
                <span
                    v-if="isVisual(media) && !media.obscenityFlagged"
                    class="text-sm font-bold hover:underline cursor-pointer mx-1"
                    @click="flagMedia(media)"
                >
                    FLAG MEDIA
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import ImageMediaModal from '../../Modal/ImageMediaModal';
    import { dashboardAxios } from '../../../../axios.config';
    import ObsceneOutput from '../../../pages/Media/ObsceneOutput';
    import VueEasyLightbox from 'vue-easy-lightbox';
    import ChatVisibilityDisclaimer from '../Contents/ChatVisibilityDisclaimer';
    import ReplyToContents from '../Contents/ReplyToContents';

    export default {
        name: 'MediaItem',

        components: {
            ObsceneOutput,
            ImageMediaModal,
            VueEasyLightbox,
            ChatVisibilityDisclaimer,
            ReplyToContents,
        },

        filters: {
            time(timestamp) {
                return moment(timestamp).format('DD/MM/YYYY HH:mm:ss');
            },
        },

        props: {
            message: {
                required: true,
                type: Object,
            },

            itemClass: {
                default: '',
                type: String,
            },

            timeClass: {
                default: '',
                type: String,
            },

            messageSelected: {
                default: false,
                type: Boolean,
            },

            isAdminChat: {
                type: Boolean,
                required: true,
            },

            sender: {
                required: true,
                type: String,
            },
        },

        data() {
            return {
                showModal: false,
                modalUrl: '',
                obscenityLoaded: {},
                obscenityOutput: {},
                hiddenToggle: true,
            };
        },

        computed: {
            hidden: {
                get() {
                    if (this.isAdminChat) {
                        return false;
                    }

                    return this.hiddenToggle;
                },
                set(value) {
                    this.hiddenToggle = value;
                },
            },
        },

        methods: {
            toggleModal(url = '') {
                this.modalUrl = url;
                this.showModal = !this.showModal;
            },

            visibilityChanged(isVisible, entry, mediaID) {
                if (isVisible && typeof this.obscenityLoaded[mediaID] === 'undefined') {
                    this.refreshObscenityData(mediaID);
                }
            },

            refreshObscenityData(mediaID) {
                dashboardAxios
                    .get(`/v1/media/${mediaID}/obscene-data`)
                    .then((response) => {
                        this.obscenityOutput = response.data.result.obscenity_output;
                    })
                    .finally(() => (this.obscenityLoaded[mediaID] = true));
            },

            selectMessage(message) {
                this.$emit('selectMessage', message);
            },

            isVisual(media) {
                return media.type === 'IMAGE' || media.type === 'VIDEO' || media.type === 'VIDEO_GIF';
            },

            async flagMedia(media) {
                let data = {
                    media_id: media.mediaID,
                    message_id: this.message.id,
                };

                await dashboardAxios.post('/v1/media/flag', data).then(() => {
                    this.refreshObscenityData(media.mediaID);
                });
            },
        },
    };
</script>
