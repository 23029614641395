var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isModalOpen)?_c('AllContentRowsModal',{attrs:{"content":_vm.modalContent},on:{"hide":function($event){_vm.isModalOpen = false}}}):_vm._e(),(_vm.photos && _vm.photos.length && !_vm.loadingPhotos && !_vm.loadingMemberContent)?_c('div',[_c('table',{staticClass:"w-full text-left table-fixed"},[_vm._m(0),_c('tbody',_vm._l((_vm.rows),function(row){return _c('tr',{key:row.id,staticClass:"border hover:bg-grey-200",on:{"click":function($event){return _vm.openModal(row)}}},[_c('td',{staticClass:"px-4 py-2 break-words"},[_vm._v(" "+_vm._s(_vm.time(row.createdAt, 'DD/MM/YYYY HH:mm'))+" ")]),_c('td',{staticClass:"px-4 py-2 font-mono font-bold break-words"},[_vm._v(" "+_vm._s(row.channel && row.type === 'IMAGE' ? row.verification == 1 ? 'VERIFICATION' : 'PHOTO' + row.position : row.type)+" ")]),_c('td',{staticClass:"px-4 py-2 font-mono font-bold break-words"},[_vm._v(" "+_vm._s(row.status || row.field)+" ")]),_c('td',{staticClass:"px-4 py-2 font-bold break-words"},[(row.mediaID && row.type === 'IMAGE')?_c('img',{attrs:{"src":row.signedUrl
                                    ? row.signedUrl
                                    : row.verification == 1
                                    ? _vm.resolveVerificationPhotoUrl(
                                          row.mediaID,
                                          _vm.profile.hashMemberID,
                                          'portrait',
                                          '@1x'
                                      )
                                    : _vm.resolvePhotoUrl(row.mediaID, _vm.profile.hashMemberID, 'portrait', '@1x')}}):_vm._e(),(row.mediaID && row.type === 'VIDEO')?_c('video',{staticClass:"focus:outline-none w-full h-64 cover-fit",attrs:{"id":"profile_video","controls":""},on:{"play":function($event){return _vm.profile_video_play('play')},"ended":function($event){return _vm.profile_video_play('complete')}}},[_c('source',{attrs:{"src":row.signedUrl
                                        ? row.signedUrl
                                        : _vm.resolveVideoUrl(row.mediaID, _vm.profile.hashMemberID)}})]):_vm._e(),(row.mediaID && row.type === 'AUDIO')?_c('audio',{staticClass:"focus:outline-none max-w-xs",attrs:{"controls":"","src":row.signedUrl}}):_c('span',[_vm._v(" "+_vm._s(row.value)+" ")])])])}),0)])]):_c('div',{staticClass:"flex justify-center items-center font-bold"},[_c('div',{staticClass:"my-4"},[(!_vm.loadingPhotos && !_vm.loadingMemberContent)?_c('span',[_vm._v("No Photos Found")]):_c('span',[_vm._v("Loading...")])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',{staticClass:"bg-grey-200"},[_c('th',{staticClass:"px-4 py-2 w-1/4"},[_vm._v("Created")]),_c('th',{staticClass:"px-4 py-2 w-1/4"},[_vm._v("Channel")]),_c('th',{staticClass:"px-4 py-2 w-1/4"},[_vm._v("Status / Field")]),_c('th',{staticClass:"px-4 py-2 w-1/4"},[_vm._v("Photo / Content")])])])}]

export { render, staticRenderFns }