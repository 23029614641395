<template>
    <transition name="modal">
        <div
            class="fixed z-50 top-0 right-0 modal-mask modal-custom-css h-screen w-screen flex justify-center items-center transition duration-500 ease-in-out"
            :class="{ 'modal-custom-css': !noPadding, 'p-6': noPadding }"
            @click.self="clickOverlay"
        >
            <div
                class="bg-white shadow modal-container flex flex-col rounded relative max-h-full overflow-auto"
                :class="`${finalSize} ${noPadding ? '' : 'p-4'}`"
            >
                <div class="w-full">
                    <slot name="header"></slot>
                </div>

                <div class="w-full h-full">
                    <slot name="body"></slot>
                </div>

                <div class="w-full">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: 'Modal',

        props: {
            size: {
                type: String,
                default: '',
            },

            clickOverlay: {
                type: Function,
                default: () => {},
            },

            noPadding: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            finalSize() {
                switch (this.size) {
                    case 'super-sm':
                        return 'w-1/4 max-h-90';
                    case 'sm':
                        return 'w-1/3 max-h-90';
                    case 'm':
                        return 'w-2/5 max-h-90 ';
                    case 'm-l':
                        return 'w-full xl:w-3/5 max-h-90 ';
                    case 'l':
                        return 'w-full xl:w-4/5 max-h-90 ';
                    case 'xl':
                        return 'w-full max-h-90 ';
                    case 'square':
                        return 'w-1/3 max-h-70';
                    case 'responsive':
                        return 'w-full max-w-md md:w-2/3 max-h-full';
                    case 'auto':
                        return 'w-auto';
                    default:
                        return 'flex-1 max-h-90 ';
                }
            },
        },
    };
</script>

<style scoped>
    .modal-mask {
        background-color: rgba(0, 0, 0, 0.6);
        transition: opacity 100ms linear;
    }

    .modal-container {
        transition: opacity 100ms linear;
    }

    .modal-enter {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

    .modal-custom-css {
        padding: 2rem;
    }

    .max-h-90 {
        max-height: 90%;
    }

    .modal-max-width {
        max-width: fit-content;
    }

    .max-h-70 {
        height: 70%;
    }

    @media (min-width: 768px) {
        .modal-custom-css {
            padding: 20px 150px;
        }
    }
</style>
