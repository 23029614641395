import Vue from 'vue';
import App from './App.vue';
import router from './router/index';
import store from './store/index';
import * as components from './components';
import Default from './layouts/default/Default.vue';
import NoNavBar from './layouts/NoNavBar.vue';
import NoLayout from './layouts/NoLayout.vue';
// import '@/assets/styles.css';
import '@/assets/spezia.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import * as icons from './icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { ObserveVisibility } from 'vue-observe-visibility';
import * as Sentry from '@sentry/vue';

// Vue Addons
import VueCookie from 'vue-cookie';
import VueBlurHash from 'vue-blurhash';
import VueHead from 'vue-head';
import VueNumber from 'vue-number-animation';
import VueConfetti from 'vue-confetti';

import moment from 'moment';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import EventBus from './helpers/eventBus';
import './assets/tailwind.css';
import { BrowserTracing } from '@sentry/tracing';

Vue.component('Icon', FontAwesomeIcon);
// Register layouts
Vue.component('DefaultLayout', Default);
Vue.component('NoNavbarLayout', NoNavBar);
Vue.component('NoLayoutLayout', NoLayout);

Vue.directive('observe-visibility', ObserveVisibility);

// Register components
Object.entries(components).forEach(([name, component]) => {
    Vue.component(name, component);
});

// Register FA icons
Object.entries(icons).forEach(([name, component]) => {
    library.add(component);
});

window.moment = moment;

// Cookies
Vue.use(VueCookie);

// BlurHash
Vue.use(VueBlurHash);

Vue.use(VueHead);

Vue.use(VueSweetalert2, {
    customClass: {
        cancelButton: 'rounded bg-grey-100 hover:text-grey-500 py-2 px-4 focus:outline-none text-black mr-2',
        confirmButton: 'rounded bg-pink-muzmatch hover:bg-pink-lighter py-2 px-4 focus:outline-none text-white mr-2',
        actions: 'justify-end',
    },
    buttonsStyling: false,
    reverseButtons: true,
});

Vue.use(VueNumber);
Vue.use(VueConfetti);

Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DNS,
    environment: process.env.VUE_APP_ENV,
    logErrors: process.env.VUE_APP_ENV !== 'production',
    ignoreErrors: ['NavigationDuplicated'],
    trackComponents: true,
    integrations: [],
});

new Vue({
    router,
    store,

    // Add global keyup event listener.
    mounted() {
        window.addEventListener('keyup', (event) => {
            EventBus.$emit('globalKeyup', event);
        });

        window.addEventListener('keydown', (event) => {
            EventBus.$emit('globalKeydown', event);
        });

        window.addEventListener('mouseup', (event) => {
            EventBus.$emit('globalMouseup', event);
        });
    },
    render: (h) => h(App),
}).$mount('#app');
