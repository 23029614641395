<template>
    <div>
        <div v-if="issues && issues.length && !loading">
            <table class="w-full text-left table-fixed">
                <thead>
                    <tr class="bg-grey-200">
                        <th class="px-4 py-2 w-1/6">Created</th>
                        <th class="px-4 py-2 w-1/6">Type</th>
                        <th class="px-4 py-2 w-1/6">Evidence</th>
                        <th class="px-4 py-2 w-1/6">Message</th>
                    </tr>
                </thead>
                <tbody>
                    <IssuesTabRow v-for="issue in issues" :key="issue.id" :issue="issue" />
                </tbody>
            </table>
        </div>

        <div v-else class="flex justify-center items-center font-bold">
            <div class="my-4">
                <span v-if="!loading">No Issues Reported</span>
                <span v-else>Loading...</span>
            </div>
        </div>
    </div>
</template>

<script>
    import IssuesTabRow from './IssuesTabRow';
    import { mapActions } from 'vuex';
    import { dashboardAxios } from '../../../../axios.config';

    export default {
        name: 'IssuesTab',

        components: {
            IssuesTabRow,
        },

        props: {
            profile: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                issues: [],
                loading: false,
            };
        },
        mounted() {
            this.loadIssues();
        },
        methods: {
            ...mapActions({
                handleError: 'handleError',
            }),

            async loadIssues() {
                if (this.loading) {
                    return;
                }

                this.loading = true;

                try {
                    let response = await dashboardAxios.get('/v1/members/' + this.profile.hashMemberID + '/issues');

                    this.issues = response.data.result;
                } catch (error) {
                    console.error(error);

                    this.handleError({ error });
                }

                this.loading = false;
            },
        },
    };
</script>
