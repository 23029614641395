<template>
    <div class="bg-white p-3 flex items-center justify-between border-t border-gray-200">
        <span>Showing {{ filteredCampaignsLength }} of {{ totalItems }}</span>
        <button
            v-if="currentItemsTotal < totalItems && filteredCampaignsLength == currentItemsTotal"
            class="relative inline-flex items-between px-2 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-100"
            :disabled="isLoadingMore"
            @click.prevent="$emit('show-more')"
        >
            <icon icon="chevron-down" class="h-5 w-5" aria-hidden="true" />
            <span class="ml-2">{{ isLoadingMore ? 'Loading...' : 'Show More' }}</span>
        </button>
        <div class="flex gap-x-3">
            <span v-if="filteredCampaignsLength == currentItemsTotal">Show rows:</span>
            <select
                v-if="filteredCampaignsLength == currentItemsTotal"
                v-model="localAmountPerPage"
                class="border rounded-md"
                @change="$emit('amount-per-page-changed', $event)"
            >
                <option>10</option>
                <option>20</option>
                <option>30</option>
                <option>50</option>
                <option>100</option>
            </select>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'ShowMore',
        props: {
            currentItemsTotal: {
                type: Number,
                default: 0,
            },
            totalItems: {
                type: Number,
                default: 0,
            },
            isLoadingMore: {
                type: Boolean,
                default: false,
            },
            filteredCampaignsLength: {
                type: Number,
                default: 0,
            },
            amountPerPage: {
                type: Number,
                default: 20,
            },
        },
        data() {
            return {
                localAmountPerPage: this.amountPerPage,
            };
        },
    };
</script>
