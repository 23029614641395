<template>
    <div class="w-full mt-5">
        <Panel v-if="role" title="Edit Role">
            <form class="p-4" @submit.prevent="saveRole">
                <div class="flex flex-row -mx-2">
                    <div class="w-1/2 mx-2">
                        <label>Role Name*</label>

                        <input
                            v-model="role.label"
                            type="text"
                            class="p-2 bg-white border border-grey-400 rounded w-full mt-1"
                            required="required"
                        />
                    </div>

                    <div class="w-1/2 mx-2">
                        <label>Description*</label>

                        <input
                            v-model="role.description"
                            type="text"
                            class="p-2 bg-white border border-grey-400 rounded w-full mt-1"
                            required="required"
                        />
                    </div>
                </div>

                <div class="flex justify-end mt-4">
                    <button
                        type="button"
                        class="py-2 px-4 text-grey-800 rounded focus:outline-none bg-grey-100 mr-2"
                        @click="$router.push('/roles')"
                    >
                        Back to listing
                    </button>

                    <button
                        type="submit"
                        class="py-2 px-4 text-white rounded focus:outline-none"
                        :class="loading ? 'bg-pink-lighter' : 'bg-pink-muzmatch'"
                    >
                        Save
                    </button>
                </div>
            </form>
        </Panel>

        <Panel title="Permissions">
            <div class="p-4">
                <ul class="list-reset">
                    <li v-for="(product, productIndex) in permissionList" :key="productIndex">
                        <Checkbox
                            :text="product.name"
                            :default-value="product.value"
                            @change="handleProductCheckboxChange(productIndex, $event)"
                        />
                        <ul class="list-reset ml-6">
                            <li v-for="(roleItem, roleIndex) in product.roles" :key="roleIndex">
                                <Checkbox
                                    :text="roleItem.name"
                                    :default-value="roleItem.value"
                                    @change="handleRoleCheckboxChange(productIndex, roleIndex, $event)"
                                />
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </Panel>
    </div>
</template>

<script>
    import Panel from '../../components/Panels/Panel';
    import { dashboardAxios } from '../../../axios.config';
    import { mapActions } from 'vuex';

    export default {
        name: 'Show',

        components: {
            Panel,
        },

        data() {
            return {
                role: null,
                loading: false,
                permissionList: null,
            };
        },
        mounted() {
            let identifier = this.$route.params.id;

            let split = identifier.toString().split('.');

            if (split.length !== 2) {
                this.alert({ message: 'Malformed role identifier.', type: 'danger' });

                return;
            }

            try {
                this.loadRole(parseInt(split[1], 10));
            } catch (e) {
                this.handleError({ error: e });
            }
        },
        methods: {
            ...mapActions(['alert', 'handleError']),

            async loadRole(id) {
                try {
                    let response = await dashboardAxios.get('/v1/walirole/' + id);
                    this.role = response.data.result;

                    await this.loadPermissionList();
                } catch (e) {
                    this.handleError({ error: e });
                }
            },

            async saveRole() {
                if (this.loading) {
                    return;
                }

                if (!this.role.label || !this.role.description) {
                    this.alert({ message: 'Name and description are required', type: 'danger' });

                    return;
                }

                this.loading = true;

                try {
                    //Find all checked permissions to save
                    let permission_id_list = [];
                    this.permissionList.forEach((product) => {
                        product.roles.forEach((role) => {
                            if (role.value === true) {
                                permission_id_list.push(role.id);
                            }
                        });
                    });

                    let response = await dashboardAxios.put('/v1/walirole/' + this.role.id, {
                        label: this.role.label,
                        description: this.role.description,
                        permission_id_list: permission_id_list,
                    });

                    this.role = response.data.result;

                    this.alert({ message: 'Role successfully updated.', type: 'success' });

                    this.loading = false;
                } catch (e) {
                    this.handleError({
                        error: e,
                        callback: () => (this.loading = false),
                    });
                }
            },

            async loadPermissionList() {
                let wali_permission_list = await dashboardAxios.get('/v1/walipermission');
                this.permissionList = this.processPermissionList(wali_permission_list.data.result);

                //Check permission list that is currently active against wali role
                let current_permission_list = [];

                this.role.permission_list.forEach((permission) => {
                    let productName = permission.productName.toLowerCase();
                    let roleName = permission.roleName.toLowerCase();

                    if (!Object.keys(current_permission_list).includes(productName)) {
                        current_permission_list[productName] = [];
                    }
                    current_permission_list[productName].push(roleName);
                });

                this.permissionList.map((product) => {
                    product.roles.map((role, roleIndex) => {
                        let currentRolesOfProduct = current_permission_list[product.name.toLowerCase()];
                        if (currentRolesOfProduct && currentRolesOfProduct.includes(role.name.toLowerCase())) {
                            product.roles[roleIndex].value = true;
                        }
                    });
                });
                this.updateProductCheckbox();
            },

            processPermissionList(roles) {
                roles = _.map(roles, (role) => ({
                    id: role.id,
                    name: role.roleName,
                    value: false,
                    product: role.productName,
                }));

                let rolesByProduct = _.groupBy(roles, (role) => role.product);

                return _.map(rolesByProduct, (roles, name) => ({
                    name: name,
                    value: false,
                    roles: roles,
                }));
            },

            handleProductCheckboxChange(index, value) {
                this.permissionList[index].value = value;
                this.permissionList[index].roles.map((role) => {
                    role.value = value;
                });
            },

            handleRoleCheckboxChange(productIndex, roleIndex, value) {
                this.permissionList[productIndex].roles[roleIndex].value = value;

                // Update the product value if all roles inside are true
                this.updateProductCheckbox();
            },

            updateProductCheckbox() {
                this.permissionList.map((product) => {
                    let productValue = true;
                    product.roles.map((role) => {
                        if (role.value === false) {
                            productValue = false;
                        }
                    });
                    product.value = productValue;
                });
            },
        },
    };
</script>
