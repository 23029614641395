<template>
    <button
        class="flex flex-wrap font-bold shadow-sm items-center justify-center"
        :class="finalStyle"
        :disabled="isDisabled"
        @click="$emit('click', $event)"
    >
        <icon v-if="icon" :icon="icon" class="mx-1"></icon>

        <span>
            <slot />
        </span>
    </button>
</template>

<script>
    export default {
        props: {
            type: {
                required: false,
                type: String,
                default: 'default',
            },

            icon: {
                required: false,
                type: String,
                default: '',
            },

            size: {
                required: false,
                type: String,
                default: 'sm',
            },

            rounded: {
                required: false,
                type: Boolean,
                default: false,
            },

            isDisabled: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                themes: {
                    success: { button: 'bg-green text-white hover:bg-green-light' },
                    light: {
                        button: 'bg-grey-light text-black hover:bg-grey-dark cursor-not-allowed',
                    },
                    danger: { button: 'bg-red text-white hover:bg-red-light' },
                    default: { button: 'bg-pink-dark text-white hover:bg-pink' },
                },
                sizes: {
                    xs: 'p-1',
                    sm: 'py-2 px-4',
                    md: 'py-3 px-5',
                    lg: 'py-4 px-6',
                },
            };
        },

        computed: {
            finalStyle() {
                let style = this.themes[this.type].button + ' ' + this.sizes[this.size];
                if (this.rounded) {
                    style += ' rounded';
                }
                return style;
            },
        },
    };
</script>
