<template>
    <Modal size="l" :click-overlay="() => $emit('close-modal')" :no-padding="true">
        <div slot="body">
            <Panel title="Update filters" :has-margin="false" :custom-classes="['bg-pink-muzmatch', 'text-white']">
                <div class="p-5">
                    <div v-for="(box, index) in boxes" :key="index">
                        <div class="flex items-center">
                            <div class="w-11/12">
                                <QueryBox
                                    ref="queryBox"
                                    :box="box"
                                    :options="audienceFields"
                                    @update-audience-amount="updateAudienceAmount(false)"
                                />
                            </div>
                            <div class="w-1/12 px-2">
                                <icon
                                    v-if="index !== 0"
                                    size="xs"
                                    icon="plus"
                                    class="transform rotate-45 cursor-pointer"
                                    @click="removeBox(index)"
                                />
                            </div>
                        </div>
                        <div class="flex">
                            <div class="w-11/12">
                                <div v-if="boxes.length !== index + 1" class="w-full text-center my-4">And</div>
                            </div>
                        </div>
                    </div>
                    <div class="flex">
                        <div class="w-11/12">
                            <div class="flex justify-center">
                                <button
                                    class="border border-pink-dark rounded bg-pink-dark text-center text-white text-xs p-2 mt-5"
                                    @click="addBox"
                                >
                                    Add AND constraint
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="p-4 flex items-center">
                        <div class="w-1/2">
                            <div class="text-grey-600 text-sm">Estimated size: {{ audienceAmount | formatNumber }}</div>
                        </div>
                        <div class="w-1/2">
                            <div class="flex justify-end">
                                <div class="w-1/3 mr-2">
                                    <M2Button
                                        :is-disabled="audienceRefreshing"
                                        :custom-classes="['text-xs', 'normal-case', 'rounded']"
                                        @clicked="updateAudienceAmount(true)"
                                    >
                                        <span v-if="!audienceRefreshing">Update audience est.</span>
                                        <icon v-else class="animate-spin" icon="spinner" />
                                    </M2Button>
                                </div>
                                <div class="w-1/3">
                                    <M2Button :custom-classes="['text-xs', 'normal-case', 'rounded']" @clicked="update">
                                        Amend
                                    </M2Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Panel>
        </div>
    </Modal>
</template>

<script>
    import Panel from '../../components/Panels/Panel';
    import QueryBox from '../Messenger/QueryBox';
    import M2Button from '../../components/Buttons/M2Button.vue';
    import { messengerAxios } from '../../../axios.config';

    export default {
        name: 'FilterModal',
        components: {
            Panel,
            QueryBox,
            M2Button,
        },
        filters: {
            formatNumber(number) {
                return new Intl.NumberFormat('en-GB').format(number);
            },
        },
        props: {
            index: {
                type: Number,
                required: true,
            },
            jsonFilters: {
                type: Array,
                default: null,
            },
            audienceFields: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                boxes: [],
                audienceAmount: 0,
                audienceRefreshing: false,
            };
        },
        created() {
            if (this.jsonFilters === null) {
                this.boxes = [[{ type: null }]];
                return;
            }

            this.boxes = _.cloneDeep(this.jsonFilters);
        },
        methods: {
            addBox() {
                this.boxes.push([{ type: null }]);
            },

            removeBox(index) {
                let queries = this.buildJsonQuery(true);
                this.boxes = queries.filter((e, i) => i !== index);
            },

            async updateAudienceAmount(manualClick) {
                this.audienceRefreshing = true;
                const queries = this.buildJsonQuery();

                if (!queries) {
                    if (manualClick) {
                        await this.alert({
                            message: 'Cannot find audience amount with an unfinished or empty query',
                            type: 'danger',
                        });
                    }
                    this.audienceRefreshing = false;
                    return;
                }

                try {
                    const response = await messengerAxios.post(`/audience/amounts`, { jsonFilter: queries });
                    this.audienceAmount = response.data.result.amount;
                } catch (error) {
                    await this.handleError({ error });
                }

                this.audienceRefreshing = false;
            },

            buildJsonQuery(draft = false) {
                try {
                    return this.$refs.queryBox
                        .map((box, i) => {
                            return box.$refs.queryRows
                                .filter((row) => {
                                    if (draft) return true;

                                    if (
                                        (!row.queryData.type || !row.queryData.value || !row.queryData.comparison) &&
                                        row.queryData.type !== 'muzmatch_email'
                                    )
                                        return false;

                                    if (!['in', 'not in'].includes(row.queryData.comparison)) return true;

                                    return row.queryData.value.length !== 0;
                                })
                                .map((row) => row.queryData);
                        })
                        .filter((i) => i.length > 0);
                } catch (error) {
                    return [];
                }
            },

            update() {
                const queries = this.buildJsonQuery();
                this.$emit('update-filter', this.index, queries);
                this.$emit('close-modal');
            },
        },
    };
</script>

<style scoped></style>
