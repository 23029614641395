<template>
    <div>
        <div v-if="!loading && boosts !== null">
            <table class="w-full text-left table-fixed mt-4">
                <thead>
                    <tr class="bg-grey-200">
                        <th class="px-4 py-2">Member Boosted</th>
                        <th class="px-4 py-2">Other Member Boosted</th>
                        <th class="px-4 py-2">Other Member</th>
                        <th class="px-4 py-2">Accepted</th>
                        <th class="px-4 py-2">Source</th>
                        <th class="px-4 py-2">Used</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="border hover:bg-grey-200">
                        <td colspan="6" class="px-4 py-4">Next free credit: {{ timeTillNextFree }}</td>
                    </tr>
                    <tr v-for="ic in instantChats" :key="ic.id" class="border hover:bg-grey-200">
                        <td class="px-4 py-2">
                            <icon
                                v-if="ic.memberBoosted"
                                icon="check-square"
                                class="text-pink-muzmatch h-10"
                                size="lg"
                            />
                            <icon v-else icon="times" class="text-pink-muzmatch h-10 pl-1" size="lg" />
                        </td>
                        <td class="px-4 py-2">
                            <icon
                                v-if="ic.otherMemberBoosted"
                                icon="check-square"
                                class="text-pink-muzmatch h-10"
                                size="lg"
                            />
                            <icon v-else icon="times" class="text-pink-muzmatch h-10 pl-1" size="lg" />
                        </td>
                        <td class="px-4 py-2 flex items-center">
                            <router-link
                                :to="{ name: 'members', query: { memberId: ic.otherMemberID } }"
                                class="hover:text-grey-600 text-grey-800 cursor-pointer p-2"
                                target="_blank"
                            >
                                <icon icon="link"></icon>
                            </router-link>
                            <p>{{ ic.otherMemberID }}</p>
                        </td>
                        <td class="px-4 py-2">
                            <icon
                                v-if="ic.acceptedInstantMatchTimeStamp"
                                icon="check-square"
                                class="text-pink-muzmatch h-10"
                                size="lg"
                            />
                            <icon v-else icon="times" class="text-pink-muzmatch h-10 pl-1" size="lg" />
                        </td>
                        <td class="px-4 py-2">
                            <p>{{ ic.source }}</p>
                        </td>
                        <td class="px-4 py-2">
                            <p>{{ ic.timestamp | timestamp }}</p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div v-else class="flex justify-center">
            <span v-if="!loading" class="font-bold my-4">No Boosts to show.</span>
            <span v-else class="font-bold my-4">Loading...</span>
        </div>
    </div>
</template>

<script>
    import { dashboardAxios } from '../../../../axios.config';
    import { mapActions } from 'vuex';
    import moment from 'moment';

    export default {
        name: 'FiltersTab',

        filters: {
            timestamp: function (value) {
                return moment(value).format('DD/MM/YYYY HH:mm:ss');
            },
        },

        props: {
            profile: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                search: '',
                instantChats: null,
                nextFree: null,
                loading: false,
            };
        },
        computed: {
            timeTillNextFree() {
                return this.nextFree.timeTill === null
                    ? 'available now'
                    : `${this.nextFree.timeTill} credit is available (${this.nextFree.dateTime})`;
            },
        },
        created() {
            this.loadICs();
        },
        methods: {
            ...mapActions({
                handleError: 'handleError',
            }),

            async loadICs() {
                if (this.loading) {
                    return;
                }

                this.loading = true;

                try {
                    let response = await dashboardAxios.get(`/v1/ic/${this.profile.memberID}`);

                    this.instantChats = response.data.result.ics;
                    this.nextFree = response.data.result.nextFree;
                } catch (error) {
                    this.handleError({ error });
                }

                this.loading = false;
            },
        },
    };
</script>
