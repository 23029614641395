<template>
    <div>
        <FilterMatchModal v-if="isModalOpen && modalData" :content="modalData" @hide="isModalOpen = false" />
        <div class="flex items-center">
            <div class="w-1/2">
                <div>
                    <strong v-show="discover !== null && discover.newMember">New Member</strong>
                </div>
                <div>
                    <strong v-if="discover !== null">
                        Going Again
                        <icon :icon="discover.goAgain ? 'check-square' : 'times'" class="text-pink-muzmatch" />
                    </strong>
                </div>
            </div>
            <div v-if="discover !== null" class="w-1/2 mr-4 flex justify-end items-center">
                <div class="mr-3 text-grey-muzmatch italic">
                    Page: {{ page }} / {{ totalPages }} (Total: {{ totalItems }})
                </div>
                <div class="py-1 mr-2 w-10 bg-grey-muzmatch flex items-center justify-center">
                    <button :disabled="!hasPrevPage" :class="{ 'cursor-not-allowed': !hasPrevPage }" @click="page--">
                        <icon icon="chevron-left" class="text-white" />
                    </button>
                </div>
                <div class="py-1 w-10 mr-2 bg-grey-muzmatch flex items-center justify-center">
                    <button :disabled="!hasNextPage" :class="{ 'cursor-not-allowed': !hasNextPage }" @click="page++">
                        <icon icon="chevron-right" class="text-white" />
                    </button>
                </div>
                <select id="limit" v-model="pageSize" name="limit" class="w-64 border-2 rounded" :disabled="loading">
                    <option :value="null" disabled>Page Size</option>
                    <option :value="10">10</option>
                    <option :value="20">20</option>
                    <option :value="30">30</option>
                    <option :value="40">40</option>
                    <option :value="50">50</option>
                    <option :value="100">100</option>
                </select>
            </div>
            <icon class="text-pink-muzmatch" icon="sync" @click="loadDiscover" />
        </div>
        <div v-if="!loading && discover !== null">
            <table class="w-full text-left table-fixed mt-4">
                <thead class="discover-header">
                    <tr class="bg-grey-200">
                        <th class="px-4 py-2">Member ID</th>
                        <th class="px-4 py-2">Gold</th>
                        <th class="px-4 py-2">Boosted</th>
                        <th class="px-4 py-2">Liked You</th>
                        <th class="px-4 py-2">
                            Previously Swiped
                            <br />
                            (Going Again)
                        </th>
                        <template v-for="(matchName, idx) in matchKeys">
                            <th v-show="hasSearchFilter(matchName)" :key="idx" class="px-4 py-2">{{ matchName }}</th>
                        </template>
                        <th class="px-4 py-2">Distance</th>
                        <th class="px-4 py-2">Match %</th>
                        <th class="px-4 py-2">Score</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in discoverItemsToShow" :key="item.memberID" class="border hover:bg-grey-200">
                        <td class="px-4 py-2">
                            <div class="flex items-center">
                                <p @click="loadESDocument(item)">{{ item.memberID }}</p>
                                <router-link
                                    :to="{ name: 'members', query: { memberId: item.memberID } }"
                                    class="hover:text-grey-600 text-grey-800 cursor-pointer p-2"
                                    target="_blank"
                                >
                                    <icon icon="link"></icon>
                                </router-link>
                            </div>
                        </td>
                        <td class="px-4 py-2">
                            <icon v-if="item.gold" icon="check-square" class="text-pink-muzmatch h-10" size="lg" />
                            <icon v-else icon="times" class="text-pink-muzmatch h-10 pl-1" size="lg" />
                        </td>
                        <td class="px-4 py-2">
                            <icon v-if="item.boosted" icon="check-square" class="text-pink-muzmatch h-10" size="lg" />
                            <icon v-else icon="times" class="text-pink-muzmatch h-10 pl-1" size="lg" />
                            <br />
                            <span v-if="getESValue(item, 'boosted')">{{ getESValue(item, 'boosted') }}</span>
                            <br />
                            <icon
                                v-if="getESExplain(item, 'boosted')"
                                icon="check-square"
                                class="text-pink-muzmatch h-10"
                                size="lg"
                            />
                            <icon v-else icon="times" class="text-pink-muzmatch h-10 pl-1" size="lg" />
                        </td>
                        <td class="px-4 py-2">
                            <icon v-if="item.likedYou" icon="check-square" class="text-pink-muzmatch h-10" size="lg" />
                            <icon v-else icon="times" class="text-pink-muzmatch h-10 pl-1" size="lg" />
                        </td>
                        <td class="px-4 py-2">
                            <icon
                                v-if="item.previouslySwiped"
                                icon="check-square"
                                class="text-pink-muzmatch h-10"
                                size="lg"
                            />
                            <icon v-else icon="times" class="text-pink-muzmatch h-10 pl-1" size="lg" />
                        </td>
                        <template v-for="(matchName, idxx) in matchKeys">
                            <td v-show="hasSearchFilter(matchName)" :key="idxx">
                                <icon
                                    v-if="
                                        typeof item.scoreDebug !== 'undefined'
                                            ? item.scoreDebug.matches[matchName] === true
                                            : false
                                    "
                                    icon="check-square"
                                    class="text-pink-muzmatch h-10"
                                    size="lg"
                                />
                                <icon v-else icon="times" class="text-pink-muzmatch h-10 pl-1" size="lg" />
                                <br />
                                <span v-if="getESValue(item, matchName)">{{ getESValue(item, matchName) }}</span>
                                <br />
                                <icon
                                    v-if="getESExplain(item, matchName)"
                                    icon="check-square"
                                    class="text-pink-muzmatch h-10"
                                    size="lg"
                                />
                                <icon v-else icon="times" class="text-pink-muzmatch h-10 pl-1" size="lg" />
                            </td>
                        </template>
                        <td class="px-4 py-2">
                            <p>{{ item.locationDistance | formatDistance }}</p>
                        </td>
                        <td class="px-4 py-2 cursor-pointer" @click="openModal(item)">
                            <p>{{ item.percentageMatch }}</p>
                            <p v-if="item.scoreDebug">{{ item.scoreDebug.matched }}/{{ item.scoreDebug.count }}</p>
                        </td>
                        <td class="px-4 py-2">
                            <p>{{ item.score }}</p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div v-else class="flex justify-center">
            <span v-if="!loading" class="font-bold my-4">No discover queue to show.</span>
            <span v-else class="font-bold my-4">Loading...</span>
        </div>
    </div>
</template>

<script>
    import { dashboardAxios } from '../../../../axios.config';
    import { mapActions } from 'vuex';
    import M2Button from '../../Buttons/M2Button';
    import FilterMatchModal from './FilterMatchModal';

    export default {
        name: 'DiscoverTab',

        components: {
            M2Button,
            FilterMatchModal,
        },

        filters: {
            formatDistance(distance) {
                return !distance ? '?' : `${parseFloat(distance).toFixed(2)} mi`;
            },
        },

        props: {
            profile: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                isModalOpen: false,
                pageSize: 50,
                page: 1,
                limit: 200,
                discover: null,
                loading: false,
                modalData: null,
                matchKeys: [
                    'education',
                    'excludeParents',
                    'islamicDress',
                    'languagesSpoken',
                    'maritalStatus',
                    'marriageHorizon',
                    'maxHeight',
                    'minHeight',
                    'practisingLevel',
                    'prayerLevel',
                    'profession',
                    'publicPhotos',
                    'willingToRelocateAbroad',
                ],
                mappedElasticName: {
                    education: 'education',
                    excludeParents: 'has_children',
                    islamicDress: 'islamic_dress',
                    languagesSpoken: 'languages',
                    maritalStatus: 'marital_status',
                    marriageHorizon: 'marriage_horizon',
                    maxHeight: 'height',
                    minHeight: 'height',
                    practisingLevel: 'practising_level',
                    prayerLevel: 'prayer_level',
                    profession: 'profession',
                    publicPhotos: 'has_blurred_photos',
                    willingToRelocateAbroad: 'willing_to_relocate',
                    boosted: 'boosted',
                    miniBoostUntil: 'mini_boost_until',
                },
                mappedSearchName: {
                    education: 'education',
                    excludeParents: 'excludeParents',
                    islamicDress: 'dress',
                    languagesSpoken: 'languagesSpoken',
                    maritalStatus: 'maritalStatus',
                    marriageHorizon: 'marriageHorizon',
                    maxHeight: 'maxHeight',
                    minHeight: 'minHeight',
                    practisingLevel: 'practisingLevel',
                    prayerLevel: 'prayerLevel',
                    profession: 'profession',
                    publicPhotos: 'publicPhotos',
                    willingToRelocateAbroad: 'willingToRelocateAbroad',
                },
            };
        },

        computed: {
            discoverItemsToShow() {
                return this.discover.data.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page);
            },

            totalPages() {
                return Math.ceil(this.discover.data.length / this.pageSize);
            },

            totalItems() {
                return this.discover.data.length;
            },

            hasNextPage() {
                return this.discover.data.length > this.pageSize * this.page;
            },

            hasPrevPage() {
                return this.page > 1;
            },
        },

        watch: {
            pageSize() {
                this.page = 1;
            },
        },
        created() {
            this.loadDiscover();
        },
        methods: {
            ...mapActions({
                handleError: 'handleError',
            }),
            hasSearchFilter(item) {
                let searchFilterName = this.mappedSearchName[item];
                return this.discover.debug.searchFilters[searchFilterName];
            },
            async loadESDocument(item) {
                let response = await dashboardAxios.get(`/v1/esdocument/${item.memberID}`);
                item.es = response.data.result;
                console.log(response);
                item.__ob__.dep.notify();
            },
            async loadDiscover() {
                if (this.loading) {
                    return;
                }

                this.loading = true;

                try {
                    let response = await dashboardAxios.get(`/v1/discover/${this.profile.memberID}`, {
                        params: {
                            limit: this.limit,
                        },
                    });

                    this.discover = response.data.result;
                } catch (error) {
                    this.handleError({ error });
                }

                this.loading = false;
            },
            getESValue(item, name) {
                if (!item.es) return false;
                var esValue = item.es._source[this.mappedElasticName[name]];
                esValue = JSON.stringify(esValue);
                return esValue;
            },
            getESExplain(item, name) {
                var explainationPresence = this.mappedElasticName[name];
                if (name == 'maxHeight' || name == 'minHeight') {
                    var isMinSet = this.getESValue('minHeight');
                    var isMaxSet = this.getESValue('maxHeight');
                    if (isMinSet && isMaxSet) {
                        explainationPresence = 'height';
                    } else if (isMaxSet) {
                        explainationPresence = '-2147483648';
                    } else if (isMinSet) {
                        explainationPresence = '2147483647';
                    }
                }
                return JSON.stringify(item.elasticDebug).includes(explainationPresence);
            },
            openModal(item) {
                this.modalData = item.filterMatch;
                this.isModalOpen = true;
            },
        },
    };
</script>

<style scoped>
    .discover-header th {
        position: sticky;
        top: 0;
    }
    td {
        text-align: center;
    }
    .discover-header {
        height: 200px;
    }
    .discover-header th {
        padding: 10;
        height: 200px;
        overflow: none;
        white-space: nowrap;
        width: 100px;
        border: 1px solid grey;
        transform-origin: 100 0;
        transform-origin: left;
        transform: translate(50%, -49%) rotate(90deg);
        width: 20px;
    }
</style>
