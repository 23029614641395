<template>
    <div>
        <div
            class="w-full flex items-center justify-between text-white rounded z-20 cursor-pointer relative"
            :class="cssClass"
            @click="toggleDropdown"
        >
            <div class="focus:outline-none p-4 w-full text-center">{{ queue.social ? 'Close Case' : 'Actioned' }}</div>
            <div class="absolute h-full p-4 right-0">
                <icon :icon="dropdown ? 'chevron-up' : 'chevron-down'" />
            </div>
        </div>
        <div v-if="dropdown" class="shadow-md">
            <div v-if="actionShortcuts.length > 0 && otherActions">
                <div
                    v-for="(shortcut, index) in actionShortcuts"
                    :key="index"
                    class="bg-white text-sm p-2 -mt-1 cursor-pointer"
                    @click="action(shortcut)"
                >
                    <div class="hover:bg-grey-100 w-full h-full rounded p-2 text-center text-uppercase break-words">
                        {{ shortcut.keys }}
                    </div>
                </div>
            </div>
            <div
                v-if="queue.social"
                class="bg-white text-sm p-2 -mt-1 cursor-pointer"
                @click="action('CLOSURE_ACTION_REVIEWED')"
            >
                <div class="hover:bg-grey-100 w-full h-full rounded p-2 text-center text-uppercase">Reviewed</div>
            </div>
            <div class="bg-white text-sm p-2 -mt-1 cursor-pointer" @click="action()">
                <div class="hover:bg-grey-100 w-full h-full rounded p-2 text-center text-uppercase">No reason</div>
            </div>
            <div
                v-if="actionShortcuts.length === 0 && !queue.social"
                class="text-red text-sm text-center bg-white p-2 -mt-1 cursor-not-allowed"
            >
                Please create a shortcut with type 'ACTION' in the shortcut tool.
            </div>
        </div>
    </div>
</template>

<script>
    import { dashboardAxios, socialModerationAxios } from '../../../axios.config';
    import { mapActions } from 'vuex';
    import { captureException } from '@sentry/minimal';

    export default {
        name: 'ActionedButton',
        props: {
            queue: {
                type: Object,
                required: true,
            },

            task: {
                type: Object,
                required: true,
            },

            reporterId: {
                type: Number,
                required: true,
            },

            reportedId: {
                type: Number,
                required: true,
            },

            contextType: {
                type: String,
                required: true,
            },

            moderationState: {
                type: String,
                default: '',
            },
        },
        data() {
            return {
                loading: false,
                dropdown: false,
                actionShortcuts: [],
            };
        },
        computed: {
            cssClass() {
                let css = '';
                if (!this.queue.social) {
                    css += 'bg-pink-muzmatch ';
                    if (!this.dropdown) {
                        css += 'shadow-md';
                    }
                } else {
                    if (this.moderationState !== 'UNSPECIFIED' || this.queue.task.profileEntity) {
                        css += 'bg-pink-muzmatch ';
                        if (!this.dropdown) {
                            css += 'shadow-md';
                        }
                    } else {
                        css += 'bg-grey-700';
                    }
                }
                return css;
            },
            otherActions() {
                if (
                    this.queue.social ||
                    ['Approve', 'Issue', 'Payment', 'Email', 'App Reviews', 'Identity Verification'].includes(
                        this.queue.text,
                    )
                ) {
                    return false;
                }
                return true;
            },
        },
        watch: {
            cssClass() {
                if (this.moderationState == 'UNSPECIFIED' && this.queue.social && !this.queue.task.profileEntity) {
                    this.dropdown = false;
                }
            },
        },
        created() {
            this.getActionShortcuts();
        },
        methods: {
            ...mapActions({
                handleError: 'handleError',
                alert: 'alert',
            }),

            async action(shortcut) {
                if (this.queue.social) {
                    if (typeof shortcut == 'undefined') {
                        await socialModerationAxios.post('/internal/community/reports/edit/close-case', {
                            case_id: this.task.case.caseId,
                            closure_action: 'CLOSURE_ACTION_NO_REASON',
                            report_status: 'REPORT_STATUS_COMPLETED_UPHELD',
                        });
                        this.$emit('actioned', null);
                    } else {
                        await socialModerationAxios.post('/internal/community/reports/edit/close-case', {
                            case_id: this.task.case.caseId,
                            closure_action: shortcut,
                            report_status: 'REPORT_STATUS_COMPLETED_UPHELD',
                        });
                        this.$emit('actioned', null);
                    }
                    return;
                }
                if (this.loading) {
                    return;
                }

                this.loading = true;

                if (!this.task) {
                    this.loading = false;

                    throw `There is no task on this queue?! This button should be invisible.`;
                }

                if (!!shortcut && this.reporterId !== 0) {
                    await this.sendActionedMessage(shortcut);
                }

                const taskData = {
                    memberID: this.reporterId,
                    taskID: this.task.id,
                };
                await dashboardAxios
                    .post('/v1/log-action-button', {
                        memberID: this.reporterId,
                        taskID: this.task.id,
                        ...(!shortcut && {
                            keys: 'no reason',
                        }),
                        ...shortcut,
                    })
                    .catch((err) => captureException(err));

                try {
                    let response = await dashboardAxios.put('/v1/tasks/' + this.queue.url + '/' + this.task.id);

                    this.$emit('actioned', response.data.result);
                } catch (error) {
                    this.$emit('actioned', null);
                }

                this.loading = false;
            },
            toggleDropdown() {
                if (this.queue.social && (this.moderationState !== 'UNSPECIFIED' || this.queue.task.profileEntity)) {
                    this.dropdown = !this.dropdown;
                    return;
                } else if (this.queue.social) {
                    this.dropdown = false;
                    return;
                }
                this.dropdown = !this.dropdown;
            },
            async sendActionedMessage(shortcut) {
                let data = {
                    member_id: this.reporterId,
                    action_shortcut_id: parseInt(shortcut.id, 10),
                    context_type: this.contextType.split('CONTEXT_TYPE_')[1],
                    context_task_id: this.task.id,
                };

                if (this.reportedId !== 0) {
                    data['reported_id'] = this.reportedId;
                }

                try {
                    await dashboardAxios.post('/v1/message-member-task-actioned', data);

                    this.alert({ message: 'Action message sent', type: 'success' });
                } catch (error) {
                    console.error(error);
                    await this.handleError({ error });
                }
            },
            async getActionShortcuts() {
                try {
                    let response = await dashboardAxios.get('/v1/shortcuts-type/action');
                    this.actionShortcuts = response.data.result;
                } catch (e) {
                    await this.handleError({ error: e });
                }
            },
        },
    };
</script>

<style scoped>
    .text-uppercase {
        text-transform: uppercase;
    }

    .right-0 {
        right: 0;
    }
</style>
