import { render, staticRenderFns } from "./AttachmentModal.vue?vue&type=template&id=b66437b2&scoped=true&"
import script from "./AttachmentModal.vue?vue&type=script&lang=js&"
export * from "./AttachmentModal.vue?vue&type=script&lang=js&"
import style0 from "./AttachmentModal.vue?vue&type=style&index=0&id=b66437b2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b66437b2",
  null
  
)

export default component.exports