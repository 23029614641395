<template>
    <ShortcutModal
        v-if="shortcutModal"
        :shortcuts="shortcuts"
        @toggleModal="toggleShortcutModal"
        @handleShortcutInsertion="handleShortcutInsertion"
    />
</template>

<script>
    import EventBus from '../../helpers/eventBus';
    import { dashboardAxios } from '../../../axios.config';
    import ShortcutModal from './ShortcutModal';
    import { mapGetters } from 'vuex';

    export default {
        components: {
            ShortcutModal,
        },

        props: {
            contextMemberId: {
                type: Number,
                default: null,
            },

            contextTaskId: {
                type: Number,
                default: null,
            },
        },

        data: function () {
            return {
                shortcutModal: false,
                shortcuts: [],
                pressedKeys: [],
                modalTriggerKeys: ['Control', 's'],
                currentInput: null,
                detectedShortcut: null,
                contextType: '',
                contextAction: '',
                contextDetail: '',
            };
        },

        computed: {
            ...mapGetters({
                staff: 'staff/getStaff',
            }),
        },

        created() {
            EventBus.$on('globalKeyup', this.handleKeyup);
            EventBus.$on('globalKeydown', this.handleKeydown);
        },

        mounted() {
            this.getShortcuts();
        },

        destroyed() {
            EventBus.$off('globalKeyup', this.handleKeyup);
            EventBus.$off('globalKeydown', this.handleKeydown);
        },

        methods: {
            async getShortcuts() {
                try {
                    let response = await dashboardAxios.get('/v1/shortcuts');
                    this.shortcuts = response.data.result;
                } catch (e) {
                    this.handleError({ error: e });
                }
            },

            handleShortcutInsertion(shortcut, insertionType) {
                this.insertTextContent(shortcut);
                this.logShortcutInsert(shortcut.id, insertionType);
            },

            insertTextContent(shortcut) {
                let currentValue = this.currentInput.value;
                let valueWithoutKeys = currentValue.replace(shortcut.keys, '');
                let valueBeforeKeys = valueWithoutKeys.slice(0, this.currentInput.selectionEnd);
                let valueAfterKeys = valueWithoutKeys.slice(this.currentInput.selectionEnd, valueWithoutKeys.length);

                let newValue = valueBeforeKeys + shortcut.content + valueAfterKeys;
                newValue = this.formatPlaceholders(newValue);
                let newCursorPosition = this.currentInput.selectionEnd + shortcut.content.length;

                this.currentInput.focus();
                this.currentInput.value = newValue.substring(0, newCursorPosition);
                this.currentInput.scrollTop = this.currentInput.scrollHeight;
                this.currentInput.value = newValue;
                this.currentInput.setSelectionRange(newCursorPosition, newCursorPosition);

                let event = new Event('input', { bubbles: true });
                this.currentInput.dispatchEvent(event);
            },

            async logShortcutInsert(id, insertionType) {
                try {
                    await dashboardAxios.post('/v1/shortcut-logs', {
                        id: id,
                        insertionType: insertionType,
                        contextType: this.contextType,
                        contextAction: this.contextAction,
                        contextDetail: this.contextDetail,
                        contextMemberId: this.contextMemberId,
                        contextTaskId: this.contextTaskId,
                    });
                } catch (e) {
                    this.handleError({ error: e });
                }
            },

            classifyContext(inputClassList) {
                let typeClass = this.getClass(inputClassList, 'CONTEXT_TYPE');
                let actionClass = this.getClass(inputClassList, 'CONTEXT_ACTION');
                let detailClass = this.getClass(inputClassList, 'CONTEXT_DETAIL');

                if (typeClass) {
                    this.contextType = typeClass.split('CONTEXT_TYPE_')[1];
                }

                if (actionClass) {
                    this.contextAction = actionClass.split('CONTEXT_ACTION_')[1];
                }

                if (detailClass) {
                    this.contextDetail = detailClass.split('CONTEXT_DETAIL_')[1];
                    return;
                }

                this.contextDetail = '';
            },

            getClass(classList, label) {
                return classList.filter((className) => className.includes(label)).shift();
            },

            handleKeydown(event) {
                if (event.target.tagName !== 'TEXTAREA' && event.target.tagName !== 'INPUT') {
                    return;
                }

                let inputClassList = Object.values(event.target.classList);

                if (!inputClassList.includes('shortcut-accessible')) {
                    return;
                }

                if (this.shortcutModal === true) {
                    return;
                }

                this.classifyContext(inputClassList);

                // Keyboard shortcut handler
                if (this.detectedShortcut) {
                    if (event.key === 'Enter') {
                        event.preventDefault();
                        this.handleShortcutInsertion(this.detectedShortcut, 'KEYBOARD');
                    }

                    this.clearDetectedShortcut();
                    window.removeEventListener('click', this.handleClick);
                }

                // Shortcut modal handler
                this.currentInput = event.target;

                if (this.pressedKeys.indexOf(event.key) === -1) {
                    this.pressedKeys.push(event.key);
                }

                if (this.areArraysEqual(this.pressedKeys, this.modalTriggerKeys)) {
                    event.preventDefault();
                    this.toggleShortcutModal();
                }
            },

            handleKeyup(event) {
                if (event.target.tagName !== 'TEXTAREA' && event.target.tagName !== 'INPUT') {
                    return;
                }

                if (!Object.values(event.target.classList).includes('shortcut-accessible')) {
                    return;
                }

                if (this.shortcutModal === true) {
                    return;
                }

                // Keyboard shortcut handler
                let value = this.currentInput.value || '';
                let valueUpToCursor = value.substring(0, this.currentInput.selectionEnd);

                let shortcut = this.findShortcutInInput(valueUpToCursor)
                    ? this.findShortcutInInput(valueUpToCursor)
                    : null;

                if (shortcut) {
                    this.detectedShortcut = shortcut;
                    this.currentInput.style.caretColor = '#fb406c';
                    window.addEventListener('click', this.handleClick);
                }

                // Shortcut modal handler
                this.pressedKeys = [];
            },

            handleClick() {
                this.clearDetectedShortcut();
                window.removeEventListener('click', this.handleClick);
            },

            clearDetectedShortcut() {
                this.detectedShortcut = null;
                this.currentInput.style.caretColor = null;
            },

            findShortcutInInput(value) {
                return this.shortcuts.filter((shortcut) => value.endsWith(shortcut.keys))[0];
            },

            toggleShortcutModal() {
                this.shortcutModal = !this.shortcutModal;
                this.pressedKeys = [];
            },

            areArraysEqual(array1, array2) {
                return (
                    array1.length === array2.length &&
                    array1.sort().every((value, index) => value === array2.sort()[index])
                );
            },

            formatPlaceholders(value) {
                const map = {
                    agent_name: () => {
                        if ('alias' in this.staff && this.staff.alias) {
                            return this.staff.alias;
                        }
                        return this.staff.name.split(' ')[0] || '';
                    },
                };

                for (const item in map) {
                    value = value.replaceAll(`{{` + item + `}}`, map[item]());
                }

                return value;
            },
        },
    };
</script>
