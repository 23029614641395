<template>
    <Panel title="Pending Block">
        <div class="bg-white rounded my-2 py-2 px-2 w-full">
            <div v-for="(value, propertyName) in pending" :key="propertyName">
                <p class="w-full break-words font-bold">{{ camelCase(propertyName) }}:</p>
                <p class="w-full break-words">
                    {{ value }}
                </p>
            </div>
            <p class="w-full break-words font-bold">Pending Block Time:</p>
            <p class="w-full break-words">
                {{ blockedAt && date(blockedAt) }}
            </p>
        </div>
    </Panel>
</template>

<script>
    import _ from 'lodash';
    import moment from 'moment';
    export default {
        name: 'PendingPanel',
        props: {
            pending: {
                type: Object,
                default: null,
            },

            blockedAt: {
                type: String,
                default: null,
            },
        },
        methods: {
            camelCase(word) {
                return _.startCase(word);
            },
            date(date) {
                return moment(date).format('DD/MM/YYYY HH:mm:ss');
            },
        },
    };
</script>
