<template>
    <div class="shadow-lg bg-white p-2 rounded w-64 mt-2 absolute z-30">
        <div v-for="item in inactiveLinks" :key="item.path">
            <router-link :to="item.path" class="no-underline text-black">
                <div class="hover:bg-grey-200 rounded p-2">
                    <span>{{ item.name }}</span>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
    import { User } from '../../classes/User';

    export default {
        props: {
            staff: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                links: [
                    { path: '/app-versions', name: 'App Versions', permission: 'app-versions' },
                    { path: '/members', name: 'Member Look up', permission: 'members:admin' },
                    { path: '/roles', name: 'Roles', permission: 'roles' },
                    { path: '/permissions', name: 'Permissions', permission: 'permissions' },
                    { path: '/shortcuts', name: 'Shortcuts', permission: 'shortcut-tool' },
                    { path: '/task-manager', name: 'Task Manager', permission: 'task-manager' },
                    { path: '/team', name: 'Team', permission: 'team' },
                    { path: '/datasets', name: 'Dataset Versions', permission: 'datasets' },
                    { path: '/media/obscene', name: 'Obscene Media', permission: 'media' },
                    { path: '/media/faces', name: 'Faces', permission: 'media' },
                    { path: '/messenger', name: 'Messenger', permission: 'messenger' },
                    { path: '/emails', name: 'Search Emails', permission: 'email-list' },
                    { path: '/issue-tracker', name: 'Issue Tracker', permission: 'task-tags' },
                    { path: '/auto-approvals', name: 'Auto-approvals', permission: 'task-manager' },
                    { path: '/remote-config', name: 'Remote Config', permission: 'remote-config' },
                    { path: '/beta/cms', name: 'CMS', permission: 'cms' },
                    { path: '/marketing-gold', name: 'Marketing Gold', permission: 'members:marketing-gold' },
                    {
                        path: '/member-block-details',
                        name: 'Member Block Details',
                        permission: 'members:block-details',
                    },
                    { path: '/beta/purchase-logger', name: 'Purchase Logger', permission: 'purchase-logger' },
                ],
            };
        },

        computed: {
            availableLinks() {
                return this.links.filter((link) => User.make(this.staff).can(link.permission));
            },

            sortedLinks() {
                return _.sortBy(this.availableLinks, 'name');
            },

            inactiveLinks() {
                return this.sortedLinks.filter(this.filterLinks);
            },
        },

        methods: {
            filterLinks(link) {
                let currentPath = this.$route.path.toLowerCase();
                let linkPath = link.path.toLowerCase();

                return !currentPath.includes(linkPath);
            },
        },
    };
</script>
