<template>
    <div
        :contenteditable="contentEditable"
        class="p-2 break-words"
        :class="[{ 'right-to-left': rtl }, getMessageTypeClass]"
        @input="onInput"
        @blur="blurred"
    >
        <span>
            {{ body }}
        </span>
    </div>
</template>

<script>
    export default {
        props: {
            messageType: {
                type: String,
                default: null,
            },
            body: {
                type: String,
                default: '',
            },
            rtl: {
                type: Boolean,
                default: false,
            },
            contentEditable: {
                type: Boolean,
                default: true,
            },
        },

        data() {
            return {
                updatedBody: null,
            };
        },

        computed: {
            getMessageTypeClass() {
                if (this.messageType === 'ADMIN') {
                    return 'font-semibold text-center';
                }

                return '';
            },
        },

        mounted() {
            this.updatedBody = this.body;
        },

        methods: {
            onInput(e) {
                this.updatedBody = e.target.innerText;
            },
            blurred() {
                if (this.updatedBody !== this.body) {
                    this.$emit('updated-body', this.updatedBody);
                }
            },
        },
    };
</script>

<style scoped>
    .right-to-left {
        direction: rtl;
        text-align: right;
    }
</style>
