<template>
    <Panel title="Remote Config">
        <div slot="header" class="ml-auto flex justify-between items-center">
            <div class="inline-flex w-full justify-end items-center">
                <a
                    class="no-underline text-pink-muzmatch p-2 font-bold text-sm cursor-pointer mr-2"
                    @click="jsonCacheModal.show = true"
                >
                    View JSON Cache
                </a>
                <button
                    v-if="hasAdminPermission"
                    class="text-xs border rounded bg-pink-muzmatch text-white text-center p-2 mr-2"
                    @click="addModal.show = true"
                >
                    Add Parent Config
                </button>
            </div>
        </div>
        <table v-if="!isLoading" class="w-full text-left">
            <thead>
                <tr class="bg-grey-200">
                    <th class="px-4 py-2">Name</th>
                    <th class="px-4 py-2 w-1/6">Value</th>
                    <th class="px-4 py-2">Enabled</th>
                    <th v-if="isProduction" class="px-4 py-2">Admin Enabled</th>
                    <th class="px-4 py-2 w-1/3">Filters</th>
                    <th class="px-4 py-2 w-1/12"></th>
                    <th class="px-4 py-2">Last Updated</th>
                    <th v-if="hasAdminPermission" class="px-4 py-2 w-1/6">Actions</th>
                </tr>
            </thead>
            <tbody :key="rerender">
                <template v-for="groupName in groupNameList">
                    <tr :key="groupName" class="bg-grey-300 border border-grey-300">
                        <td :colspan="colspan" class="px-4 py-2 capitalize font-semibold">
                            {{ groupName | normalcase }}
                        </td>
                    </tr>
                    <template v-for="(remoteConfig, index) in updatedRemoteConfigList">
                        <!-- eslint-disable -->
                        <Fragment>
                            <!-- eslint-enable -->
                            <TableRow
                                v-if="groupName === remoteConfig.groupName && !remoteConfig.parentID"
                                :key="`${groupName}-${remoteConfig.id}`"
                                :index="index"
                                :tree-level="0"
                                :remote-config="remoteConfig"
                                :is-button-disabled="isButtonDisabled(index)"
                                :is-filter-expanded="expandFilterList[remoteConfig.id]"
                                :is-add-child-disabled="!isButtonDisabledMany"
                                :how-many-child="
                                    updatedRemoteConfigList.filter((child) => child.parentID === remoteConfig.id).length
                                "
                                :is-production="isProduction"
                                :has-admin-permission="hasAdminPermission"
                                @toggle-filter="toggleExpandFilter"
                                @update-config="updateConfig"
                                @open-add-child-modal="openAddChildModal"
                            />
                            <template v-if="expandFilterList[remoteConfig.id] && groupName === remoteConfig.groupName">
                                <template v-for="(childRemoteConfig, childIndex) in updatedRemoteConfigList">
                                    <!-- eslint-disable -->
                                    <Fragment>
                                        <!-- eslint-enable -->
                                        <TableRow
                                            v-if="childRemoteConfig.parentID === remoteConfig.id"
                                            :key="`${groupName}-${remoteConfig.id}-${childRemoteConfig.id}`"
                                            :index="childIndex"
                                            :tree-level="1"
                                            :remote-config="childRemoteConfig"
                                            :parent-id="remoteConfig.id"
                                            :parent-child-mode="remoteConfig.childMode"
                                            :is-button-disabled="isButtonDisabled(childIndex)"
                                            :how-many-child="
                                                updatedRemoteConfigList.filter(
                                                    (grandchild) => grandchild.parentID === childRemoteConfig.id,
                                                ).length
                                            "
                                            :is-production="isProduction"
                                            :has-admin-permission="hasAdminPermission"
                                            @open-modal="openFilterModal"
                                            @delete-config="deleteConfig"
                                            @update-config="updateConfig"
                                            @open-add-child-modal="openAddChildModal"
                                        />

                                        <template
                                            v-for="(grandchildRemoteConfig, grandchildIndex) in updatedRemoteConfigList"
                                        >
                                            <TableRow
                                                v-if="
                                                    childRemoteConfig.parentID === remoteConfig.id &&
                                                    grandchildRemoteConfig.parentID === childRemoteConfig.id
                                                "
                                                :key="`${groupName}-${remoteConfig.id}-${childRemoteConfig.id}-${grandchildRemoteConfig.id}`"
                                                :index="grandchildIndex"
                                                :tree-level="2"
                                                :remote-config="grandchildRemoteConfig"
                                                :parent-id="childRemoteConfig.id"
                                                :parent-child-mode="childRemoteConfig.childMode"
                                                :is-button-disabled="isButtonDisabled(grandchildIndex)"
                                                :is-production="isProduction"
                                                :has-admin-permission="hasAdminPermission"
                                                @delete-config="deleteConfig"
                                                @update-config="updateConfig"
                                            />
                                        </template>
                                    </Fragment>
                                </template>
                            </template>
                        </Fragment>
                    </template>
                </template>
            </tbody>
        </table>
        <div v-else class="flex flex-col items-center justify-center py-8">
            <icon icon="list" class="loading-animation text-4xl mb-2 text-pink-muzmatch mr-4"></icon>
            <span class="text-lg mt-2 font-bold">Loading...</span>
        </div>
        <button
            v-if="hasAdminPermission"
            class="my-4 text-xs border rounded bg-pink-dark text-center text-white p-2 w-1/6 float-right"
            :class="{ 'cursor-not-allowed bg-grey-muzmatch': isButtonDisabledMany }"
            :disabled="isButtonDisabledMany"
            @click="updateConfigMany"
        >
            Update All Modified
        </button>
        <button
            v-if="hasAdminPermission"
            class="my-4 mr-2 text-xs border rounded bg-grey-300 text-center p-2 w-1/6 float-right"
            @click="reset"
        >
            Cancel
        </button>
        <FilterModal
            v-if="filterModal.show"
            :index="filterModal.selectedIndex"
            :json-filters="filterModal.selectedJsonFilter"
            :audience-fields="audienceOptions"
            @close-modal="filterModal.show = false"
            @update-filter="updateFilter"
        />
        <AddChildModal
            v-if="addChildModal.show"
            :parent-id="addChildModal.selectedParentID"
            :parent-type="addChildModal.selectedParentType"
            :child-mode="addChildModal.selectedChildMode"
            :how-many-child="addChildModal.howManyChild"
            :audience-fields="audienceOptions"
            @close-modal="addChildModal.show = false"
            @save-child-config="saveChildConfig"
        />
        <JsonCacheModal
            v-if="jsonCacheModal.show"
            :has-admin-permission="hasAdminPermission"
            @close-modal="jsonCacheModal.show = false"
        />
        <AddModal
            v-if="addModal.show"
            :groups="groupNameList"
            @close-modal="addModal.show = false"
            @close-modal-update="saveConfig"
        />
    </Panel>
</template>

<script>
    import Panel from '../components/Panels/Panel';
    import TableRow from './RemoteConfig/TableRow';
    import FilterModal from './RemoteConfig/FilterModal';
    import AddChildModal from './RemoteConfig/AddChildModal';
    import JsonCacheModal from './RemoteConfig/JsonCacheModal';
    import AddModal from './RemoteConfig/AddModal';
    import { Fragment } from 'vue-fragment';
    import { dashboardAxios, messengerAxios } from '../../axios.config';
    import { mapActions, mapGetters } from 'vuex';
    import { User } from '@/classes/User';

    export default {
        name: 'RemoteConfig',
        components: {
            Panel,
            TableRow,
            FilterModal,
            AddChildModal,
            JsonCacheModal,
            AddModal,
            Fragment,
        },
        filters: {
            normalcase(string) {
                return string.toLowerCase().replaceAll('_', ' ');
            },
        },
        data() {
            return {
                isLoading: false,
                groupNameList: [],
                expandFilterList: [],
                originalRemoteConfigList: [],
                updatedRemoteConfigList: [],
                audienceOptions: [],
                rerender: 0,
                filterModal: {
                    show: false,
                    selectedIndex: null,
                    selectedJsonFilter: [],
                },
                addChildModal: {
                    show: false,
                    selectedParentID: null,
                    selectedParentType: null,
                    selectedChildMode: null,
                    howManyChild: 0,
                },
                jsonCacheModal: {
                    show: false,
                },
                addModal: {
                    show: false,
                },
            };
        },
        computed: {
            ...mapGetters({
                staff: 'staff/getStaff',
            }),

            colspan() {
                let colspan = this.isProduction ? 8 : 7;

                if (!this.hasAdminPermission) {
                    colspan--;
                }

                return colspan;
            },

            isButtonDisabledMany() {
                let disabled = true;
                this.updatedRemoteConfigList.forEach((_, index) => {
                    if (this.isButtonDisabled(index) === false) {
                        disabled = false;
                    }
                });

                return disabled;
            },

            isProduction() {
                return process.env.VUE_APP_ENV === 'production';
            },

            hasAdminPermission() {
                return User.make(this.staff).can('remote-config:admin');
            },
        },
        created() {
            this.loadStatsConfig();
            this.loadAudienceOption();
        },
        methods: {
            ...mapActions(['handleError', 'alert', 'confirmDelete']),

            isButtonDisabled(index) {
                return (
                    JSON.stringify(this.originalRemoteConfigList[index]) ===
                    JSON.stringify(this.updatedRemoteConfigList[index])
                );
            },

            toggleExpandFilter(id, currentState) {
                this.expandFilterList[id] = !currentState;
                this.rerender++;
            },

            updateFilter(index, queries) {
                this.updatedRemoteConfigList[index].jsonFilter = queries;
            },

            openFilterModal(index, jsonFilter) {
                this.filterModal.selectedIndex = index;
                this.filterModal.selectedJsonFilter = jsonFilter;
                this.filterModal.show = true;
            },

            openAddChildModal(parentID, parentType, childMode, howManyChild) {
                this.addChildModal.selectedParentID = parentID;
                this.addChildModal.selectedParentType = parentType;
                this.addChildModal.selectedChildMode = childMode;
                this.addChildModal.howManyChild = howManyChild;
                this.addChildModal.show = true;
            },

            reset() {
                this.updatedRemoteConfigList = _.cloneDeep(this.originalRemoteConfigList);
            },

            async loadStatsConfig() {
                this.isLoading = true;

                try {
                    let response = await dashboardAxios.get('/v1/remote-config');

                    // Set original and updated to compare if changes have been made
                    this.originalRemoteConfigList = response.data.result;
                    this.updatedRemoteConfigList = _.cloneDeep(this.originalRemoteConfigList);

                    // Get unique group names
                    this.groupNameList = this.originalRemoteConfigList
                        .reduce(
                            (unique, item) => (unique.includes(item.groupName) ? unique : [...unique, item.groupName]),
                            [],
                        )
                        .sort();
                } catch (e) {
                    await this.handleError({ error: e });
                }

                this.isLoading = false;
            },

            async loadAudienceOption() {
                const audienceOptions = await messengerAxios.get('/audience-options');
                this.audienceOptions = audienceOptions.data.result;
            },

            async deleteConfig(data) {
                // Double check user is happy to delete popup
                const confirmText = `Are you sure you want to delete '${data.label}'?\n\nThis will clear any other unsaved progress.`;
                if (!(await this.confirmDelete({ vm: this, text: confirmText }))) return;

                try {
                    const payload = { parentID: data.parentID };
                    const response = await dashboardAxios.post(`/v1/remote-config/delete/${data.id}`, payload);
                    this.originalRemoteConfigList = response.data.result;
                    this.updatedRemoteConfigList = _.cloneDeep(this.originalRemoteConfigList);

                    await this.alert({ message: 'Successfully deleted from database and cache', type: 'success' });
                } catch (e) {
                    await this.handleError({ error: e });
                }
            },

            saveConfig(data) {
                this.addModal.show = false;
                this.originalRemoteConfigList = data;
                this.updatedRemoteConfigList = _.cloneDeep(this.originalRemoteConfigList);
                this.groupNameList = this.originalRemoteConfigList
                    .reduce(
                        (unique, item) => (unique.includes(item.groupName) ? unique : [...unique, item.groupName]),
                        [],
                    )
                    .sort();
                this.alert({ message: 'Successfully added new config', type: 'success' });
            },

            async saveChildConfig(payload) {
                try {
                    // Save to endpoint
                    const response = await dashboardAxios.post(`/v1/remote-config/save-child`, payload);
                    this.originalRemoteConfigList = response.data.result;
                    this.updatedRemoteConfigList = _.cloneDeep(this.originalRemoteConfigList);
                    this.addChildModal.show = false;
                    this.rerender++;

                    await this.alert({ message: 'Successfully added new config condition', type: 'success' });
                } catch (e) {
                    await this.handleError({ error: e });
                }
            },

            async updateConfig(data) {
                // Validation
                if (data.value === '') {
                    await this.alert({ message: "Value can't be empty", type: 'danger' });
                    return;
                }

                // Double check user is happy popup
                let notificationValue = data.value;
                if (data.type === 'BOOLEAN') {
                    notificationValue = data.value === '1' ? 'true' : 'false';
                }

                const confirmText = `Are you sure you want to update '${data.label}'?\n\nThis will clear any other unsaved progress.`;
                if (!(await this.confirmDelete({ vm: this, text: confirmText }))) return;

                try {
                    // Save to endpoint
                    const payload = {
                        value: data.value,
                        enabled: data.enabled,
                        adminEnabled: data.adminEnabled,
                        jsonFilter: data.jsonFilter,
                        parentID: data.parentID,
                        parentChildMode: data.parentChildMode,
                    };
                    const response = await dashboardAxios.post(`/v1/remote-config/${data.id}`, payload);
                    this.originalRemoteConfigList = response.data.result;
                    this.updatedRemoteConfigList = _.cloneDeep(this.originalRemoteConfigList);

                    await this.alert({ message: 'Successfully updated database and cache', type: 'success' });
                } catch (e) {
                    await this.handleError({ error: e });
                }
            },

            async updateConfigMany() {
                try {
                    // Validate and create payload
                    let counter = 0;
                    let payload = [];
                    this.updatedRemoteConfigList.forEach((remoteConfig, index) => {
                        if (this.isButtonDisabled(index) === false) {
                            if (remoteConfig.value === '') {
                                this.alert({ message: `'${remoteConfig.label}' can't be empty`, type: 'danger' });
                                return;
                            }

                            let parentChildMode = null;
                            if (remoteConfig.parentID) {
                                const parentRemoteConfig = this.updatedRemoteConfigList.find(
                                    (parent) => parent.id === remoteConfig.parentID,
                                );
                                parentChildMode = parentRemoteConfig.childMode;
                            }

                            payload.push({
                                id: remoteConfig.id,
                                value: remoteConfig.value,
                                enabled: remoteConfig.enabled,
                                adminEnabled: remoteConfig.adminEnabled,
                                jsonFilter: remoteConfig.jsonFilter,
                                parentID: remoteConfig.parentID,
                                parentChildMode: parentChildMode,
                            });
                            counter++;
                        }
                    });

                    if (payload.length === 0 || payload.length !== counter) return;

                    // Double check user is happy popup
                    const text = `Are you sure you want to update ${payload.length} config(s)?`;
                    if (!(await this.confirmDelete({ vm: this, text: text }))) return;

                    // Save to endpoint
                    const response = await dashboardAxios.post(`/v1/remote-config/update-many`, { payload: payload });
                    this.originalRemoteConfigList = response.data.result;
                    this.updatedRemoteConfigList = _.cloneDeep(this.originalRemoteConfigList);

                    await this.alert({ message: 'Successfully updated all to database and cache', type: 'success' });
                } catch (e) {
                    await this.handleError({ error: e });
                }
            },
        },
    };
</script>
