<template>
    <div class="mt-2 mb-4">
        <input
            v-model="value"
            :placeholder="placeholder"
            type="text"
            autocomplete="false"
            :autofocus="autofocus"
            class="focus:border-pink rounded-xs border border-grey w-full px-3 py-4 text-base mb-2"
            :class="themes[type].input"
        />
        <p v-if="text" class="text-sm mb-3" :class="themes[type].text">{{ text }}</p>
    </div>
</template>

<script>
    export default {
        props: {
            placeholder: {
                required: false,
                type: String,
                default: '',
            },
            type: {
                required: false,
                type: String,
                default: 'default',
            },
            text: {
                required: false,
                type: String,
                default: '',
            },
            defaultValue: {
                required: false,
                type: String,
                default: '',
            },
            autofocus: {
                required: false,
                type: Boolean,
                default: false,
            },
        },
        data: function () {
            return {
                value: null,
                themes: {
                    success: { input: 'border-green', text: 'text-green' },
                    danger: { input: 'border-red', text: 'text-red' },
                    default: { input: 'grey', text: 'text-grey' },
                },
            };
        },
        watch: {
            defaultValue: function (value) {
                this.value = value;
            },
            value: function (value) {
                this.$emit('change', value);
            },
        },
        created() {
            this.value = this.defaultValue;
        },
    };
</script>
