<template>
    <div>
        <AllContentRowsModal v-if="isModalOpen" :content="modalContent" @hide="isModalOpen = false" />
        <div v-if="photos && photos.length && !loadingPhotos && !loadingMemberContent">
            <table class="w-full text-left table-fixed">
                <thead>
                    <tr class="bg-grey-200">
                        <th class="px-4 py-2 w-1/4">Created</th>
                        <th class="px-4 py-2 w-1/4">Channel</th>
                        <th class="px-4 py-2 w-1/4">Status / Field</th>
                        <th class="px-4 py-2 w-1/4">Photo / Content</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row in rows" :key="row.id" class="border hover:bg-grey-200" @click="openModal(row)">
                        <td class="px-4 py-2 break-words">
                            {{ time(row.createdAt, 'DD/MM/YYYY HH:mm') }}
                        </td>

                        <td class="px-4 py-2 font-mono font-bold break-words">
                            {{
                                row.channel && row.type === 'IMAGE'
                                    ? row.verification == 1
                                        ? 'VERIFICATION'
                                        : 'PHOTO' + row.position
                                    : row.type
                            }}
                        </td>

                        <td class="px-4 py-2 font-mono font-bold break-words">
                            {{ row.status || row.field }}
                        </td>

                        <td class="px-4 py-2 font-bold break-words">
                            <img
                                v-if="row.mediaID && row.type === 'IMAGE'"
                                :src="
                                    row.signedUrl
                                        ? row.signedUrl
                                        : row.verification == 1
                                        ? resolveVerificationPhotoUrl(
                                              row.mediaID,
                                              profile.hashMemberID,
                                              'portrait',
                                              '@1x',
                                          )
                                        : resolvePhotoUrl(row.mediaID, profile.hashMemberID, 'portrait', '@1x')
                                "
                            />
                            <video
                                v-if="row.mediaID && row.type === 'VIDEO'"
                                id="profile_video"
                                controls
                                class="focus:outline-none w-full h-64 cover-fit"
                                @play="profile_video_play('play')"
                                @ended="profile_video_play('complete')"
                            >
                                <source
                                    :src="
                                        row.signedUrl
                                            ? row.signedUrl
                                            : resolveVideoUrl(row.mediaID, profile.hashMemberID)
                                    "
                                />
                            </video>
                            <audio
                                v-if="row.mediaID && row.type === 'AUDIO'"
                                controls
                                class="focus:outline-none max-w-xs"
                                :src="row.signedUrl"
                            ></audio>
                            <span v-else>
                                {{ row.value }}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div v-else class="flex justify-center items-center font-bold">
            <div class="my-4">
                <span v-if="!loadingPhotos && !loadingMemberContent">No Photos Found</span>
                <span v-else>Loading...</span>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import { mapActions } from 'vuex';
    import { dashboardAxios } from '../../../../axios.config';
    import {
        resolvePhotoUrl,
        resolveVerificationPhotoUrl,
        resolveAudioUrl,
        resolveVideoUrl,
    } from '../../../helpers/DashboardUtils';
    import AllContentRowsModal from './AllContentRowsModal';

    export default {
        name: 'ProfileContentTab',

        components: {
            AllContentRowsModal,
        },

        props: {
            profile: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                photos: [],
                memberContent: [],
                loadingPhotos: false,
                loadingMemberContent: false,
                isModalOpen: false,
                modalContent: null,
            };
        },
        computed: {
            rows() {
                const data = _.union(this.memberContent, this.photos);

                return _.orderBy(data, ['createdAt'], ['desc']);
            },
        },
        mounted() {
            this.loadPhotoHistory();
            this.loadMemberContent();
        },
        methods: {
            ...mapActions({
                handleError: 'handleError',
            }),

            time(time, format = 'DD/MM/YYYY HH:mm') {
                return moment(time).format(format);
            },

            openModal(content) {
                this.isModalOpen = true;
                this.modalContent = content;
            },

            resolvePhotoUrl,
            resolveVerificationPhotoUrl,
            resolveAudioUrl,
            resolveVideoUrl,

            async loadPhotoHistory() {
                if (this.loadingPhotos) {
                    return;
                }

                this.loadingPhotos = true;

                try {
                    let response = await dashboardAxios.get(
                        '/v1/members/' + this.profile.hashMemberID + '/photo-history',
                    );

                    this.photos = response.data.result;
                } catch (error) {
                    console.error(error);

                    this.handleError({ error });
                }

                this.loadingPhotos = false;
            },

            async loadMemberContent() {
                if (this.loadingMemberContent) {
                    return;
                }

                this.loadingMemberContent = true;

                try {
                    let response = await dashboardAxios.get(
                        '/v1/members/' + this.profile.hashMemberID + '/member-content-history',
                    );

                    this.memberContent = response.data.result;
                } catch (error) {
                    console.error(error);

                    this.handleError({ error });
                }

                this.loadingMemberContent = false;
            },
        },
    };
</script>
