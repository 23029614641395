<template>
    <div class="container mx-auto">
        <div v-if="campaign" class="flex">
            <div
                :contenteditable="tabActive === 'SETTING' && campaign.completedAt === null"
                class="w-full underline-grey"
                @blur="updateTitle"
            >
                <h1>{{ campaign.title }}</h1>
            </div>
        </div>

        <div class="flex container w-full mt-8">
            <div id="tab-wrapper" class="w-4/6">
                <div class="full-width underline-pink h-10 pt-1">
                    <div class="w-5/6 float-left">
                        <div class="md:flex sm:flex-wrap">
                            <div v-for="(tab, index) in tabList" :key="index">
                                <div
                                    class="campaign-tab flex-auto text-center"
                                    :class="[
                                        tabActive === tab.constant ? 'bg-pink-muzmatch' : '',
                                        !parentCanSave ? 'cursor-pointer' : 'cursor-not-allowed',
                                    ]"
                                    @click="selectTab(tab.constant)"
                                >
                                    <span
                                        class="text-sm font-semibold"
                                        :class="tabActive === tab.constant ? 'text-white' : ''"
                                    >
                                        {{ tab.label }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-1/6 float-right">
                        <div v-if="campaign" class="refresh-campaign cursor-pointer text-right mt-1">
                            <span
                                v-if="campaign.state === 'PAUSED'"
                                class="text-sm font-semibold text-pink-muzmatch mr-2"
                                @click="resumeCampaign"
                            >
                                <icon title="Resume" icon="play" class="cursor-pointer grey-light"></icon>
                            </span>
                            <span
                                v-if="campaign.state === 'PUBLISHED'"
                                class="text-sm font-semibold text-pink-muzmatch mr-2"
                                @click="pauseCampaign"
                            >
                                <icon title="Pause" icon="pause" class="cursor-pointer grey-light"></icon>
                            </span>
                            <span class="text-sm font-semibold text-pink-muzmatch mr-2" @click="reloadCampaign">
                                <icon title="Refresh" icon="redo" class="cursor-pointer grey-light"></icon>
                            </span>
                        </div>
                    </div>
                </div>

                <div v-if="!isLoading">
                    <campaign-setting
                        v-show="tabActive === 'SETTING'"
                        :campaign="campaign"
                        :current-audience="currentAudience"
                        :audiences="audiences"
                        :audience-options="audienceOptions"
                        :goals="goals"
                        :api-events="apiEvents"
                        :campaign-title="campaign.title"
                        :parent-can-save="parentCanSave"
                        :save-button-wobble="saveButtonWobble"
                        :pause-campaign-amount="pauseCampaignAmount"
                        :resume-campaign-amount="resumeCampaignAmount"
                        @update-parent-can-save="updateParentCanSave"
                        @update-audiences="refreshAudiences"
                        @update-current-audience="currentAudience = $event"
                        @update-parent-campaign="campaign = $event"
                        @disabled-published-toggle="disabledPublishedToggle"
                        @final-disabled-click="finalDisabledClick"
                    />

                    <campaign-message
                        v-show="tabActive === 'MESSAGE'"
                        :campaign="campaign"
                        :parent-can-save="parentCanSave"
                        :save-button-wobble="saveButtonWobble"
                        @template-clicked="switchPreview"
                        @update-parent-can-save="updateParentCanSave"
                        @clear-preview="clearPreview"
                        @final-disabled-click="finalDisabledClick"
                        @updated-campaign="
                            campaign = $event;
                            switchPreview({ groupIdx: currentGroupIndex, stepIdx: currentStep - 1 });
                            previewUpdated++;
                        "
                    />

                    <campaign-translation
                        v-show="tabActive === 'TRANSLATION'"
                        :step="previewStep"
                        :total-steps="steps"
                        :current-group-index="currentGroupIndex"
                        :current-step="currentStep"
                        :steps="campaign.steps"
                        :campaign="campaign"
                        :preview-updated="previewUpdated"
                        :preview-languages="previewLanguages"
                        :parent-can-save="parentCanSave"
                        :save-button-wobble="saveButtonWobble"
                        @updated-translation="updatedTranslation"
                        @selected-message="switchPreview"
                        @update-parent-can-save="updateParentCanSave"
                        @deleted-flag-column="deletedFlagColumn"
                        @final-disabled-click="finalDisabledClick"
                        @updated-campaign="
                            campaign = $event;
                            switchPreview({ groupIdx: currentGroupIndex, stepIdx: currentStep - 1, variation: null });
                            previewUpdated++;
                        "
                    />

                    <campaign-analytics
                        v-show="tabActive === 'ANALYTIC'"
                        :type="campaign.type"
                        :steps="campaign.steps"
                        :reengagement-stats="reengagementStats"
                        :stats="campaign.stats"
                        :audience="currentAudience"
                    />
                </div>

                <div v-else class="flex flex-col items-center justify-center my-10">
                    <div><icon icon="list" class="loading-animation text-4xl mb-2 text-pink-muzmatch mr-4"></icon></div>
                    <div><span class="text-lg mt-2 font-bold">Loading...</span></div>
                </div>
            </div>
            <div id="preview" class="w-2/6">
                <div
                    v-if="previewStep && (tabActive === 'SETTING' || tabActive === 'ANALYTIC')"
                    class="w-2/6 h-screen cursor-not-allowed absolute z-10"
                ></div>
                <preview-screen
                    v-if="previewStep"
                    :step="previewStep"
                    :total-steps="steps"
                    :current-step="currentStep"
                    :url-target-list="urlTargetList"
                    :current-group-index="currentGroupIndex"
                    :preview-updated="previewUpdated"
                    :language="selectedLanguage"
                    :preview-languages="previewLanguages"
                    @prev-template="prevTemplate"
                    @next-template="nextTemplate"
                    @add-msg="addMsg"
                    @add-push="addPush"
                    @remove-msg="removeMsg"
                    @remove-push="removePush"
                    @update-msg="updateMsg"
                    @update-notif="updateNotif"
                    @update-email-subject="updateEmailSubject"
                    @update-email-from="updateEmailFrom"
                    @update-email-from-name="updateEmailFromName"
                    @update-url-target="updateUrlTarget"
                    @update-is-email="(bool) => (isEmail = bool)"
                    @update-step="updateEmail"
                    @html-event="saveEmailHTML"
                    @selected-language="($event) => (selectedLanguage = $event)"
                    @update-parent-can-save="updateParentCanSave"
                    @add-language-step="addLanguageStep"
                />
                <div v-else class="px-4 mt-12 text-center text-sm text-gray-600">
                    Click on a template step on the left to view the preview
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CampaignSetting from './CampaignSetting';
    import CampaignMessage from './CampaignMessage.vue';
    import CampaignTranslation from './CampaignTranslation';
    import CampaignAnalytics from './CampaignAnalytic';
    import PreviewScreen from './PreviewScreen';
    import { mapActions } from 'vuex';
    import { messengerAxios } from '../../../axios.config';
    import Vue from 'vue';

    export default {
        name: 'MessengerCampaign',

        components: {
            CampaignSetting,
            CampaignMessage,
            CampaignTranslation,
            CampaignAnalytics,
            PreviewScreen,
        },

        data() {
            return {
                tabActive: 'SETTING',
                tabList: [
                    { label: 'Settings', constant: 'SETTING' },
                    { label: 'Messages', constant: 'MESSAGE' },
                    { label: 'Translations', constant: 'TRANSLATION' },
                    { label: 'Analytics', constant: 'ANALYTIC' },
                ],
                parentCanSave: false,
                campaignID: null,
                campaign: null,
                currentAudience: null,
                audiences: [],
                audienceOptions: [],
                goals: [],
                apiEvents: [],
                urlTargetList: [],
                isLoading: false,
                previewGroup: null,
                previewStep: null,
                previewUpdated: 0,
                currentStep: null,
                isEmail: false,
                currentGroupIndex: null,
                selectedLanguage: 'EN',
                saveButtonWobble: false,
                pauseCampaignAmount: 0,
                resumeCampaignAmount: 0,
                reengagementStats: {},
            };
        },

        computed: {
            steps() {
                if (!this.previewGroup) return 0;
                return this.previewGroup.templates.length;
            },
            messageBodyKey() {
                return `messageBody_${this.selectedLanguage.toLowerCase()}`;
            },
            previewLanguages() {
                return [
                    {
                        name: 'EN',
                        path: this.resolveCountryFlagUrl('GB'),
                        title: 'English',
                        rtl: false,
                    },
                    {
                        name: 'DE',
                        path: this.resolveCountryFlagUrl('DE'),
                        title: 'German',
                    },
                    {
                        name: 'FR',
                        path: this.resolveCountryFlagUrl('FR'),
                        title: 'French',
                        rtl: false,
                    },
                    {
                        name: 'ID',
                        path: this.resolveCountryFlagUrl('ID'),
                        title: 'Indonesian',
                        rtl: false,
                    },
                    {
                        name: 'TR',
                        path: this.resolveCountryFlagUrl('TR'),
                        title: 'Turkish',
                        rtl: false,
                    },
                    {
                        name: 'NL',
                        path: this.resolveCountryFlagUrl('NL'),
                        title: 'Dutch',
                        rtl: false,
                    },
                    {
                        name: 'MS',
                        path: this.resolveCountryFlagUrl('MY'),
                        title: 'Malay',
                        rtl: false,
                    },
                    {
                        name: 'ES',
                        path: this.resolveCountryFlagUrl('ES'),
                        title: 'Spanish',
                        rtl: false,
                    },
                    {
                        name: 'AR',
                        path: this.resolveCountryFlagUrl('AE'),
                        title: 'Arabic',
                        rtl: true,
                    },
                    {
                        name: 'HI',
                        path: this.resolveCountryFlagUrl('IN'),
                        title: 'Hindi',
                        rtl: false,
                    },
                    {
                        name: 'FA',
                        path: this.resolveCountryFlagUrl('AF'),
                        title: 'Persian',
                        rtl: false,
                    },
                    {
                        name: 'UR',
                        path: this.resolveCountryFlagUrl('PK'),
                        title: 'Urdu',
                        rtl: false,
                    },
                    {
                        name: 'BN',
                        path: this.resolveCountryFlagUrl('BD'),
                        title: 'Bengali',
                        rtl: false,
                    },
                    {
                        name: 'RU',
                        path: this.resolveCountryFlagUrl('RU'),
                        title: 'Russian',
                        rtl: false,
                    },
                    {
                        name: 'IT',
                        path: this.resolveCountryFlagUrl('IT'),
                        title: 'Italy',
                        rtl: false,
                    },
                ];
            },
        },

        created() {
            let identifier = this.$route.params.id;
            let split = identifier.toString().split('.');

            if (split.length !== 2) {
                this.alert({ message: 'Malformed role identifier.', type: 'danger' });
                return;
            }

            this.campaignID = Number.parseInt(split[1]);

            if (this.$route.query.tab) {
                this.selectTab(this.$route.query.tab);
            }

            try {
                this.loadData();
            } catch (e) {
                this.handleError({ error: e });
            }
        },

        methods: {
            ...mapActions(['handleError', 'alert']),

            resolveCountryFlagUrl(flag) {
                return `https://${process.env.VUE_APP_WEB_CDN_DOMAIN}/images/flags/flag_${flag.toLowerCase()}.png`;
            },

            async loadData() {
                this.isLoading = true;

                const [campaign, audiences, goals, apiEvents, urlTargets] = await Promise.all([
                    messengerAxios.get('/campaign/' + this.campaignID),
                    messengerAxios.get('/audiences'),
                    messengerAxios.get('/goals'),
                    messengerAxios.get('/api-events'),
                    messengerAxios.get('/url-targets'),
                ]);

                this.campaign = campaign.data.result;
                this.audiences = audiences.data.result;
                this.goals = goals.data.result;
                this.apiEvents = apiEvents.data.result;
                this.urlTargetList = urlTargets.data.result;

                if (this.campaign.audienceID) {
                    const audience = await messengerAxios.get('/audience/' + this.campaign.audienceID);
                    this.currentAudience = audience.data.result;
                }

                // Load first template if exist on first load or when refresh clicked
                if (this.campaign.steps[0] && this.campaign.steps[0].templates[0]) {
                    this.selectedLanguage = 'EN';
                    this.switchPreview({ groupIdx: 0, stepIdx: 0 });
                }

                if (this.campaign.steps) {
                    const muzzEmails = {
                        'noreply@marketing.muzmatch.com': 'noreply@marketing.muzz.com',
                        'met@marketing.muzmatch.com': 'met@muzz.com',
                        'noreply@muzmatch.com': 'noreply@muzz.com',
                        'marketing@muzmatch.com': 'marketing@muzz.com',
                        'hello@muzmatch.com': 'hello@muzz.com',
                        'met@muzmatch.com': 'met@muzz.com',
                        'marketing@marketing.muzmatch.com': 'marketing@marketing.muzz.com',
                        'hello@marketing.muzmatch.com': 'hello@marketing.muzz.com',
                    };
                    this.campaign.steps.map((step) => {
                        step.templates.map((template) => {
                            if (template.messageType == 'EMAIL') {
                                Object.keys(template).forEach((key) => {
                                    if (key.substring(0, 12) == 'messageBody_') {
                                        if (muzzEmails[template[key].from] !== undefined) {
                                            template[key].from = muzzEmails[template[key].from];
                                        }
                                    }
                                });
                            }
                            return template;
                        });
                        return step;
                    });
                }

                if (this.campaign.reengagementStats) {
                    let reengagementKeys = Object.keys(this.campaign.reengagementStats);
                    for (let i = 0; i < reengagementKeys.length; i++) {
                        let keys = reengagementKeys[i].split('.');
                        let foundTemplate = this.findTemplateStats(
                            keys,
                            this.campaign.reengagementStats[reengagementKeys[i]],
                        );

                        if (foundTemplate !== null) {
                            let templateKey = `${foundTemplate.stepId}.${foundTemplate.template.id}.${foundTemplate.variation}`;
                            if (!(templateKey in this.reengagementStats)) {
                                this.reengagementStats[templateKey] = {};
                                this.reengagementStats[templateKey]['template'] = foundTemplate.template;
                                this.reengagementStats[templateKey]['variation'] = foundTemplate.variation;
                            }
                            this.reengagementStats[templateKey][foundTemplate.action] = foundTemplate.value;
                        }
                    }
                }

                this.isLoading = false;

                const [audienceOptions] = await Promise.all([messengerAxios.get('/audience-options')]);
                this.audienceOptions = audienceOptions.data.result;
            },

            findTemplateStats(statKeys, value) {
                for (let step = 0; step < this.campaign.steps.length; step++) {
                    if (this.campaign?.steps[step]?.id == statKeys[0]) {
                        for (let template = 0; template < this.campaign?.steps[step]?.templates.length; template++) {
                            if (this.campaign?.steps[step]?.templates[template].id == statKeys[1]) {
                                return {
                                    stepId: statKeys[0],
                                    template: this.campaign.steps[step].templates[template],
                                    variation: statKeys[2],
                                    action: statKeys[3],
                                    value: value,
                                };
                            }
                        }
                    }
                }
                return null;
            },

            async refreshAudiences() {
                const audiences = await messengerAxios.get('/audiences');
                this.audiences = audiences.data.result;
            },

            updatedTranslation(updatedStep) {
                this.previewStep = JSON.parse(JSON.stringify(updatedStep));
                this.campaign.steps[this.currentGroupIndex].templates[this.currentStep - 1] = JSON.parse(
                    JSON.stringify(updatedStep),
                );
                this.previewUpdated++;
            },

            switchPreview({ groupIdx, stepIdx, variation = 'A' }) {
                if (groupIdx === null || stepIdx === null) return;

                this.previewGroup = this.campaign.steps.find((_, i) => i === groupIdx);

                const templates =
                    variation === null
                        ? this.previewGroup.templates
                        : this.previewGroup.templates.filter((t) => t.variation === variation);

                this.previewStep = templates.find((_, idx) => idx === stepIdx);

                this.currentGroupIndex = groupIdx;
                this.currentStep = stepIdx + 1;

                // Gets buggy if switching to a different step when that language isn't filled in yet
                if (typeof this.previewStep === 'undefined' || !(this.messageBodyKey in this.previewStep)) {
                    this.selectedLanguage = 'EN';
                }
            },

            prevTemplate() {
                this.previewStep = this.previewGroup.templates.find((_, i) => i === this.currentStep - 2);
                this.currentStep--;

                if (!(this.messageBodyKey in this.previewStep)) {
                    this.selectedLanguage = 'EN';
                }
            },

            nextTemplate() {
                this.currentStep++;
                this.previewStep = this.previewGroup.templates.find((_, i) => i === this.currentStep - 1);

                if (!(this.messageBodyKey in this.previewStep)) {
                    this.selectedLanguage = 'EN';
                }
            },

            addMsg() {
                this.previewStep[this.messageBodyKey].messages.push({
                    type: 'TEXT',
                    text: '',
                });
            },

            addPush() {
                this.previewStep[this.messageBodyKey].pushTitle = 'title';
                this.previewStep[this.messageBodyKey].pushBody = 'body';
            },

            removeMsg(index) {
                this.$delete(this.previewStep[this.messageBodyKey].messages, index);
                this.previewStep.parentID = null;
                this.previewUpdated++;
            },

            removePush() {
                this.previewStep[this.messageBodyKey].pushTitle = null;
                this.previewStep[this.messageBodyKey].pushBody = null;
                this.previewStep.parentID = null;
                this.previewUpdated++;
            },

            updateMsg({ index, text, type }) {
                this.previewStep[this.messageBodyKey].messages[index] = {
                    type: type,
                    text: text,
                };
                this.previewStep.parentID = null;
                this.previewUpdated++;
            },

            updateNotif({ item, update }) {
                this.previewStep[this.messageBodyKey][`push${this.uppercaseFirstLetter(item)}`] = update;
                this.previewStep.parentID = null;
                this.previewUpdated++;
            },

            updateEmailSubject({ update }) {
                this.previewStep[this.messageBodyKey].subject = update;
                this.previewStep.parentID = null;
                this.previewUpdated++;
            },

            updateEmailFrom({ email }) {
                this.previewStep[this.messageBodyKey].from = email;
                this.previewStep.parentID = null;
                this.previewUpdated++;
            },

            updateEmailFromName({ name }) {
                this.previewStep[this.messageBodyKey].fromName = name;
                this.previewStep.parentID = null;
                this.previewUpdated++;
            },

            updateTitle(e) {
                if (this.campaign.title === e.target.innerText) return;
                this.campaign.title = e.target.innerText;
                this.parentCanSave = true;
            },

            updateUrlTarget(urlTargetID) {
                this.campaign.steps[this.currentGroupIndex].templates[this.currentStep - 1].urlTargetID = urlTargetID;
                this.previewStep.parentID = null;
            },

            updateEmail({ template }) {
                this.previewStep[this.messageBodyKey].template = template;
                this.previewStep.parentID = null;
                this.previewUpdated++;
            },

            saveEmailHTML({ payload }) {
                this.previewStep[this.messageBodyKey].body = payload;
            },

            uppercaseFirstLetter(string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            },

            updateParentCanSave(boolVal) {
                if (this.campaign.completedAt) return;
                this.parentCanSave = boolVal;
            },

            addLanguageStep(name) {
                Vue.set(
                    this.previewStep,
                    'messageBody_' + name.toLowerCase(),
                    Object.assign({}, this.previewStep['messageBody_en']),
                );
            },

            deletedFlagColumn(flagCode) {
                if (this.selectedLanguage === flagCode) {
                    this.selectedLanguage = 'EN';
                }
            },

            async selectTab(tabConstant) {
                if (!this.parentCanSave) {
                    this.tabActive = tabConstant.toUpperCase();
                    if (tabConstant.toLowerCase() !== this.$route.query.tab) {
                        await this.$router.replace({
                            query: { tab: tabConstant.toLowerCase() },
                        });
                    }
                    return;
                }

                this.saveButtonWobble = true;
                setTimeout(() => (this.saveButtonWobble = false), 3500);
                await this.alert({ message: 'You need to save first before changing tabs', type: 'danger' });
            },

            async disabledPublishedToggle() {
                this.saveButtonWobble = true;
                setTimeout(() => (this.saveButtonWobble = false), 3500);
                await this.alert({
                    message: !this.campaign.completedAt
                        ? 'You need to save first before starting or ending a campaign'
                        : 'This campaign has finished and is no longer editable',
                    type: 'danger',
                });
            },

            async finalDisabledClick() {
                await this.alert({
                    message: 'This campaign has finished and is no longer editable',
                    type: 'danger',
                });
            },

            reloadCampaign() {
                this.previewStep = null;
                this.previewGroup = null;
                this.currentStep = null;
                this.currentGroupIndex = null;
                this.parentCanSave = false;
                this.loadData();
            },

            pauseCampaign() {
                this.pauseCampaignAmount++;
            },

            resumeCampaign() {
                this.resumeCampaignAmount++;
            },

            clearPreview({ type, parentIndex, index }) {
                if (
                    (type === 'GROUP' && this.currentGroupIndex === parentIndex) ||
                    (type === 'SINGLE' && this.currentGroupIndex === parentIndex && this.currentStep - 1 === index)
                ) {
                    this.previewStep = null;
                    this.previewGroup = null;
                    this.currentStep = null;
                    this.currentGroupIndex = null;
                }
            },
        },
    };
</script>

<style scoped>
    .campaign-tab {
        border-radius: 15px 15px 0 0;
        padding: 5px 30px 5px 30px;
    }

    .underline-pink {
        border-bottom-width: 2px;
        border-bottom-color: rgba(251, 64, 108, 1);
    }

    .underline-grey {
        border-bottom-width: 1px;
        border-bottom-color: rgba(204, 204, 204, 1);
    }
</style>
