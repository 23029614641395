<template>
    <div>
        <div class="block mb-2">
            <input
                v-model="element.children[1].children[0].textNode"
                type="text"
                class="p-2 border border-grey-300 rounded block w-full rounded mb-2"
                placeholder="Title"
            />
            <input
                v-model="element.children[1].children[1].textNode"
                type="text"
                class="p-2 border border-grey-300 rounded block w-full rounded"
                placeholder="Text"
            />
        </div>
        <div class="block">
            <input
                v-model="element.children[0].children[0].attrs.src"
                type="text"
                class="p-2 border border-grey-300 rounded block w-full rounded"
                placeholder="Image"
            />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ColumnSection',
        props: {
            element: {
                type: Object,
                required: true,
            },
        },
    };
</script>
