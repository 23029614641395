<template>
    <SecondaryButton
        :class="this.clicked ? 'bg-pink-muzmatch text-white' : ''"
        :text="text"
        :disabled="disabled"
        :handle-click="handleClick"
        :handle-error="handleError"
        :confirmed="confirmed"
        :confirm-message="confirmMessage"
    ></SecondaryButton>
</template>

<script>
    import SecondaryButton from '@/components/Buttons/SecondaryButton';

    export default {
        name: 'RadioButton',

        components: { SecondaryButton },

        props: {
            clicked: {
                type: Boolean,
                required: true,
            },
            text: {
                type: String,
                required: true,
            },

            disabled: {
                type: Boolean,
                default: false,
            },

            handleClick: {
                type: Function,
                default: async () => {},
            },

            handleError: {
                type: Function,
                default: async (error) => {},
            },

            confirmed: {
                type: Boolean,
                default: false,
            },

            confirmMessage: {
                type: String,
                default: 'This action is dangerous.',
            },
        },
    };
</script>
