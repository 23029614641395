<template>
    <panel :has-margin="false" title="Template Builder">
        <div id="block-menu" class="relative bg-grey-50 overflow-y-scroll h-screen-80">
            <div class="flex-col text-center">
                <Draggable :list="blocks" :clone="cloneNew" :group="{ name: 'people', pull: 'clone', put: false }">
                    <div
                        v-for="(element, index) in blocks"
                        :key="element.label + index"
                        class="flex items-center justify-center h-16 m-1 rounded border-solid border-0.25 bg-white"
                    >
                        <create-element :node="element" />
                    </div>
                </Draggable>
                <div
                    class="flex flex-col items-center justify-center py-4 m-1 rounded border-solid border-0.25 bg-white"
                >
                    <div class="font-bold text-center">Placeholders</div>
                    <div v-pre>{{ nickname }}</div>
                    <div v-pre>{{ country }}</div>
                    <div v-pre>{{ city }}</div>
                    <div v-pre>{{ newMembersCount }}</div>
                    <div v-pre>{{ oppositeGender }}</div>
                    <div v-pre>{{ totalWeddingCount }}</div>
                    <div v-pre>{{ totalSuccessCount }}</div>
                    <div v-pre>{{ dailySuccessCount }}</div>
                </div>
            </div>
        </div>
    </panel>
</template>

<script>
    import Panel from '../Panels/Panel.vue';
    import CampaignBuilderModel from './CampaignBuilderModel.json';
    import Draggable from 'vuedraggable';
    import CreateElement from './CreateElement.vue';

    export default {
        components: {
            Panel,
            Draggable,
            CreateElement,
        },
        props: {
            nodes: {
                type: Object,
                default: null,
            },
        },
        data() {
            return {
                blocks: CampaignBuilderModel.blocks,
            };
        },
        methods: {
            cloneNew(elem) {
                let newElement = {
                    tagName: elem.tagName,
                    textNode: '',
                    label: elem.label,
                };

                if (elem.attrs) {
                    newElement.attrs = {
                        src: '',
                    };
                }

                if (elem.options) {
                    newElement.options = elem.options;
                }

                if (elem.css) {
                    newElement.css = elem.css;
                }

                if (elem.default) {
                    newElement.default = elem.default;
                }

                if (elem.children) {
                    newElement.children = elem.children;
                }

                if (elem.domProps) {
                    newElement.domProps = elem.domProps;
                }

                if (elem.scopedSlots) {
                    newElement.scopedSlots = elem.scopedSlots;
                }

                if (elem.style) {
                    newElement.style = elem.style;
                }

                if (elem.rows) {
                    newElement.rows = elem.rows;
                }

                if (elem.classes) {
                    newElement.classes = elem.classes;
                }

                if (elem.class) {
                    newElement.class = elem.class;
                }

                return _.cloneDeep(newElement);
            },
        },
    };
</script>
