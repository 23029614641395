<template>
    <th class="px-4 py-2 text-center cursor-pointer" @click="selectLanguage">
        <span class="block text-center">{{ name }}</span>
        <img class="flag mt-1" :src="path" :title="title" />
    </th>
</template>

<script>
    export default {
        name: 'TableHeader',
        props: {
            name: {
                type: String,
                required: true,
            },
            path: {
                type: String,
                required: true,
            },
            title: {
                type: String,
                required: true,
            },
        },
        methods: {
            selectLanguage() {
                this.$emit('select-language', this.name);
            },
        },
    };
</script>

<style scoped>
    .flag {
        width: 33px;
        height: 20px;
    }
</style>
