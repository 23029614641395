import Vue from 'vue';
import Router from 'vue-router';

import MembersList from '../pages/Members/List';
import MembersShow from '../pages/Members/Show';

import Home from '../pages/Home.vue';

import TeamList from '../pages/Team/List.vue';
import TeamProfile from '../pages/Team/Profile.vue';

import RequestPIN from '../pages/RequestPIN.vue';
import ConfirmPIN from '../pages/ConfirmPIN';

import ShortcutList from '../pages/Shortcuts/List';
import ShortcutShow from '../pages/Shortcuts/Show';
import ShortcutCreate from '../pages/Shortcuts/Create';

import AppVersionList from '../pages/AppVersions/List';

import TaskManager from '../pages/TaskManager';
import AutoApprovals from '../pages/AutoApprovals';

import RoleList from '../pages/Roles/List';
import RoleShow from '../pages/Roles/Show';
import RoleCreate from '../pages/Roles/Create';

import PermissionList from '../pages/Permissions/List';
import PermissionShow from '../pages/Permissions/Show';
import PermissionCreate from '../pages/Permissions/Create';

import DatasetVersions from '../pages/DatasetVersions.vue';

import IssueTracker from '../pages/IssueTracker';

import RemoteConfig from '../pages/RemoteConfig';

import WaliCredit from '../pages/WaliCredit';

import MemberBlockList from '../pages/MemberBlock/List.vue';

// Media
import ObsceneMedia from '../pages/Media/Obscene.vue';
import FaceMedia from '../pages/Media/Face.vue';

import EmailsList from '../pages/Emails/List.vue';

import MessengerList from '../pages/Messenger/MessengerList';
import MessengerCampaign from '../pages/Messenger/MessengerCampaign';

import NotFound from '../pages/Errors/404';

import MemberCounter from '../pages/MemberCounter.vue';

Vue.use(Router);

const router = new Router({
    mode: 'history',

    scrollBehavior(to, from) {
        if (from.name == 'Message List' && to.name == 'Message List' && to.query.pageNumber != from.query.pageNumber) {
            return {};
        }
        return { x: 0, y: 0 };
    },

    routes: [
        {
            path: '/',
            name: 'home',
            meta: { layout: 'no-layout', text: 'Home' },
            component: Home,
        },
        {
            path: '/login',
            name: 'login',
            meta: { layout: 'no-navbar', text: 'Login' },
            component: RequestPIN,
        },
        {
            path: '/members',
            name: 'members',
            meta: { fullWidth: true, text: 'Members', permission: 'members:admin', needToken: true },
            component: MembersList,
        },
        {
            path: '/members/:memberHash',
            name: 'members-show',
            meta: { fullWidth: true, text: 'Members', permission: 'members', needToken: true },
            component: MembersShow,
        },
        {
            path: '/members/:memberHash/:matchId',
            name: 'members-show-chat',
            meta: { fullWidth: true, text: 'Members', permission: 'members', needToken: true },
            component: MembersShow,
        },
        {
            path: '/team',
            component: TeamList,
            name: 'team',
            meta: { permission: 'team', needToken: true },
        },
        {
            path: '/team/add',
            component: TeamProfile,
            name: 'teamAdd',
            meta: { permission: 'team', needToken: true },
        },
        {
            path: '/team/:name',
            component: TeamProfile,
            name: 'teamUpdate',
            meta: { permission: 'team', needToken: true },
        },
        {
            path: '/roles',
            component: RoleList,
            name: 'roles',
            meta: { permission: 'roles', needToken: true },
        },
        {
            path: '/roles/add',
            component: RoleCreate,
            name: 'roles.create',
            meta: { permission: 'roles', needToken: true },
        },
        {
            path: '/roles/:id',
            component: RoleShow,
            name: 'roles.show',
            meta: { permission: 'roles', needToken: true },
        },
        {
            path: '/permissions',
            component: PermissionList,
            name: 'permissions',
            meta: { permission: 'permissions', needToken: true },
        },
        {
            path: '/permissions/add',
            component: PermissionCreate,
            name: 'permissions.create',
            meta: { permission: 'permissions', needToken: true },
        },
        {
            path: '/permissions/:id',
            component: PermissionShow,
            name: 'permissions.show',
            meta: { permission: 'permissions', needToken: true },
        },
        {
            path: '/verify-password',
            name: 'verify password',
            meta: { layout: 'no-navbar' },
            component: ConfirmPIN,
        },
        {
            path: '/shortcuts',
            name: 'shortcuts',
            component: ShortcutList,
            meta: { permission: 'shortcut-tool', needToken: true },
        },
        {
            path: '/shortcuts/add',
            name: 'shortcuts.create',
            component: ShortcutCreate,
            meta: { permission: 'shortcut-tool', needToken: true },
        },
        {
            path: '/shortcuts/:id',
            name: 'shortcuts.show',
            component: ShortcutShow,
            meta: { permission: 'shortcut-tool', needToken: true },
        },
        {
            path: '/app-versions',
            name: 'app-versions',
            component: AppVersionList,
            meta: { fullWidth: true, permission: 'app-versions', needToken: true },
        },
        {
            path: '/task-manager',
            name: 'task-manager',
            component: TaskManager,
            meta: { fullWidth: true, permission: 'task-manager', needToken: true },
        },
        {
            path: '/auto-approvals',
            name: 'auto-approvals',
            component: AutoApprovals,
            meta: { fullWidth: true, permission: 'task-manager', needToken: true },
        },
        {
            path: '/datasets',
            name: 'datasets',
            component: DatasetVersions,
            meta: { fullWidth: true, permission: 'datasets', needToken: true },
        },
        {
            path: '/media/obscene',
            name: 'media.obscene',
            component: ObsceneMedia,
            meta: { fullWidth: true, permission: 'media', needToken: true },
        },
        {
            path: '/media/faces',
            name: 'media.faces',
            component: FaceMedia,
            meta: { fullWidth: true, permission: 'media', needToken: true },
        },
        {
            path: '/emails',
            name: 'email-list',
            component: EmailsList,
            meta: { fullWidth: true, permission: 'email-list', needToken: false },
        },
        {
            path: '/emails/:id',
            name: 'email-list-id',
            component: EmailsList,
            meta: { fullWidth: true, permission: 'email-list', needToken: false },
        },
        {
            path: '/issue-tracker',
            name: 'Issue tracker',
            component: IssueTracker,
            meta: { fullWidth: true, permission: 'task-tags', needToken: false },
        },
        {
            path: '/messenger',
            name: 'Message List',
            component: MessengerList,
            meta: { fullWidth: true, permission: 'messenger', needToken: false },
        },
        {
            path: '/messenger/:id',
            component: MessengerCampaign,
            name: 'Messenger Campaign',
            meta: { fullWidth: true, permission: 'messenger', needToken: false },
        },
        {
            path: '/404',
            component: NotFound,
            meta: { layout: 'no-layout' },
        },
        {
            path: '/member-count',
            name: 'Member Counter',
            meta: { text: 'Member Counter', permission: 'dashboard', needToken: true },
            component: MemberCounter,
        },
        {
            path: '/remote-config',
            name: 'Remote Config',
            meta: { fullWidth: true, text: 'Remote Config', permission: 'remote-config', needToken: true },
            component: RemoteConfig,
        },
        {
            path: '/marketing-gold',
            name: 'Marketing Gold',
            meta: {
                fullWidth: true,
                text: 'Marketing Gold',
                permission: 'members:marketing-gold',
                needToken: true,
            },
            component: WaliCredit,
        },
        {
            path: '/beta/cms',
            component: Home,
            beforeEnter(to, from, next) {
                window.location.href = `${process.env.VUE_APP_BETA_URL}/cms/faq/questions`;
            },
        },
        {
            path: '/beta/speed-dating',
            component: Home,
            beforeEnter(to, from, next) {
                window.location.href = `${process.env.VUE_APP_BETA_URL}/speed-dating/manager/dev-tool`;
            },
        },
        {
            path: '/member-block-details',
            name: 'Member Block Details',
            meta: {
                fullWidth: true,
                text: 'Member Block Details',
                permission: 'members:block-details',
                needToken: true,
            },
            component: MemberBlockList,
        },
        {
            path: '/beta/purchase-logger',
            component: Home,
            beforeEnter(to, from, next) {
                window.location.href = `${process.env.VUE_APP_BETA_URL}/purchase-logger/table/view`;
            },
        },

        // { path: '*', redirect: '/404' }
    ],
});

export default router;
