import axios from 'axios';

const dashboardAxios = axios.create({
    baseURL: process.env.VUE_APP_DASHBOARD_URL,
});

const messengerAxios = axios.create({
    baseURL: process.env.VUE_APP_MESSENGER_URL,
});

const apiAxios = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
});

const socialModerationAxios = axios.create({
    baseURL: process.env.VUE_APP_PLATFORM_URL,
});

export { dashboardAxios, messengerAxios, apiAxios, socialModerationAxios };
