import { dashboardAxios } from '../../../axios.config';

export default {
    namespaced: true,

    state: {
        cities: [],
    },

    mutations: {
        setCities(state, cities) {
            state.cities = cities;
        },
    },

    getters: {
        getCities(state) {
            return state.cities;
        },
    },

    actions: {
        async loadCities({ commit, state, dispatch }) {
            if (state.cities.length !== 0) {
                return;
            }

            let config = {
                params: {
                    countries: ['GB', 'US', 'CA'],
                },
            };

            try {
                let response = await dashboardAxios.get('/v1/meta/cities', config);

                commit('setCities', response.data.result);
            } catch (e) {
                dispatch('handleError', { error: e }, { root: true });
            }
        },
    },
};
