<template>
    <modal size="sm" :click-overlay="closeModal" :no-padding="true">
        <div slot="body">
            <panel title="Add Template" :has-margin="false" :custom-classes="['bg-pink-muzmatch', 'text-white']">
                <div class="p-4">
                    <div class="flex flex-wrap">
                        <div class="w-full">
                            <label>Messenger Type*</label>
                            <dropdown
                                class="mt-1"
                                placeholder="Select type filter"
                                :options="messageTypeList"
                                :is-capitalize="false"
                                @updated="form.messageType = $event"
                            />
                        </div>
                    </div>
                    <div class="flex flex-wrap mt-4">
                        <div class="w-full">
                            <label>Pre-existing Templates</label>
                            <dropdown
                                class="mt-1"
                                placeholder="Select a template"
                                :options="templateList"
                                :is-capitalize="false"
                                @updated="form.templateID = $event"
                            />
                            <div class="text-sm italic mt-2 text-grey-darker">
                                <p v-if="!form.messageType && !loadingTemplateList">
                                    Select a message type to load pre-existing templates
                                </p>
                                <p v-if="form.messageType && loadingTemplateList">Loading templates...</p>
                                <p v-if="form.messageType && !loadingTemplateList && !templateList.length">
                                    There are no templates
                                </p>
                                <p v-if="form.messageType && !loadingTemplateList && templateList.length">
                                    Templates are loaded
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="flex space-x-12 mt-4">
                        <div class="w-1/2"></div>
                        <div class="w-1/2">
                            <m2-button
                                :custom-classes="['text-xs', 'normal-case', 'rounded']"
                                @clicked="submitTemplate"
                            >
                                Add template
                            </m2-button>
                        </div>
                    </div>
                </div>
            </panel>
        </div>
    </modal>
</template>

<script>
    import Modal from '../../components/Modal/Modal.vue';
    import Panel from '../../components/Panels/Panel';
    import Dropdown from '../../components/Dropdown/Dropdown';
    import M2Button from '../../components/Buttons/M2Button';
    import { mapActions } from 'vuex';
    import { messengerAxios } from '../../../axios.config';
    import _ from 'lodash';

    export default {
        name: 'CampaignAddTemplateModal',
        components: {
            Modal,
            Panel,
            Dropdown,
            M2Button,
        },

        props: {
            messageGroupIndex: {
                type: Number,
                required: true,
            },
        },

        data() {
            return {
                form: {
                    messageGroupIndex: null,
                    messageType: null,
                    templateID: null,
                    template: null,
                },
                messageTypeList: [
                    { label: 'Email', value: 'EMAIL' },
                    { label: 'No message', value: 'NO_MESSAGE' },
                ],
                templateList: [],
                originalTemplateList: [],
                loadingTemplateList: false,
            };
        },

        watch: {
            'form.messageType'() {
                this.getExistingTemplateList();
            },
        },

        mounted() {
            this.form.messageGroupIndex = this.messageGroupIndex;
        },

        methods: {
            ...mapActions(['alert', 'handleError']),

            async getExistingTemplateList() {
                this.loadingTemplateList = true;
                this.originalTemplateList = [];

                try {
                    let templateList = [];
                    const response = await messengerAxios.get(`/templates?type=${this.form.messageType}`);
                    if (response.data.result.length) {
                        this.originalTemplateList = response.data.result;

                        response.data.result.forEach((step) => {
                            switch (this.form.messageType) {
                                case 'PUSH':
                                case 'IN_APP':
                                    templateList.push({ label: step.messageBody_en.pushTitle, value: step.id });
                                    break;
                                case 'EMAIL':
                                    templateList.push({ label: step.messageBody_en.subject, value: step.id });
                                    break;
                            }
                        });

                        if (templateList.length) {
                            templateList.sort((a, b) => a.label.localeCompare(b.label));
                        }
                    }
                    this.templateList = templateList;
                } catch (error) {
                    await this.handleError({ error });
                }

                this.loadingTemplateList = false;
            },

            submitTemplate() {
                if (this.form.messageType !== null) {
                    this.form.template = null;
                    if (this.form.templateID) {
                        let template = this.originalTemplateList.find(
                            (template) => template.id === this.form.templateID,
                        );

                        template.active = true;
                        template.parentID = template.id;
                        template = _.omit(template, ['id', 'position', 'createdAt', 'updatedAt', 'templateStepID']);
                        this.form.template = template;
                    }

                    this.$emit('submit-template', this.form);
                    return;
                }
                this.alert({ message: 'Missing required fields', type: 'danger' });
            },

            closeModal() {
                this.$emit('hide');
            },
        },
    };
</script>

<style scoped></style>
