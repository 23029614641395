<template>
    <div class="photoWithToolTip relative">
        <a :href="memberUrl" target="_blank">
            <div class="h-full overflow-hidden">
                <img
                    ref="facePhoto"
                    :src="photo.urls.file"
                    class="w-full"
                    :style="photoStyles"
                    @load="updatePhotoDimensions"
                />
            </div>
            <div :style="photoOverlay" />
            <div :style="centerAxisStyles" />
            <span class="tooltiptext">
                <p>Confidence: {{ confidence }}%</p>
                <p>Area: {{ faceArea }}%</p>
                <p>Ypos: {{ centerY }}%</p>
                <p>Rot: {{ rotation }}&deg;</p>
            </span>
        </a>
    </div>
</template>

<script>
    export default {
        name: 'FacePhoto',

        props: {
            photo: {
                type: Object,
                default: () => {},
            },
        },

        data() {
            return {
                leftPercent: 0,
                topPercent: 0,
                rightPercent: 0,
                bottomPercent: 0,
                rectWidth: 0,
                rectHeight: 0,
                photoWidth: 0,
                photoHeight: 0,
                photoOverlay: {},
                photoStyles: {},
                centerAxisStyles: {},
                confidence: 0,
                rotation: 0,
                faceArea: 0,
                centerY: 0,
            };
        },

        computed: {
            memberUrl() {
                return '/members/' + this.photo.hashMemberID;
            },
        },

        methods: {
            calculateBox() {
                if (this.photo.face_count > 0 && this.photo.face_score > 0) {
                    this.leftPercent = 100 * this.photo.face_bounds_left;
                    this.topPercent = 100 * this.photo.face_bounds_top;
                    this.rightPercent = 100 * this.photo.face_bounds_right;
                    this.bottomPercent = 100 * this.photo.face_bounds_bottom;

                    // Store the original dimensions before snipping off any overflow
                    //let rectFullWidth = this.rightPercent - this.leftPercent;
                    let rectFullHeight = this.bottomPercent - this.topPercent;

                    if (this.topPercent < 0) {
                        this.topPercent = 0;
                    }
                    if (this.bottomPercent > 100) {
                        this.bottomPercent = 100;
                    }
                    if (this.leftPercent < 0) {
                        this.leftPercent = 0;
                    }
                    if (this.rightPercent > 100) {
                        this.rightPercent = 100;
                    }

                    let rectWidth = this.rightPercent - this.leftPercent;
                    let rectHeight = this.bottomPercent - this.topPercent;

                    this.rectWidth = rectWidth > this.photoWidth ? this.photoWidth : rectWidth;
                    this.rectHeight = rectHeight > this.photoHeight ? this.photoHeight : rectHeight;
                    this.photoOverlay = this.generateFaceRectangle();

                    this.faceArea = this.round(this.photo.face_area * 100, 2);
                    this.confidence = this.photo.face_score > 0 ? this.round(this.photo.face_score * 100, 2) : 0;

                    this.rotation = this.photo.face_rotation;
                    this.photoStyles = this.generatePhotoStyles(this.photo.face_rotation);

                    this.centerY = this.round(100 - this.photo.face_center_y * 100, 2);
                    this.centerAxisStyles = this.generateCenterAxisStyles(this.centerY, '#f346fe');
                } else {
                    this.photoOverlay = this.generateMissingRectangle();
                    this.centerAxisStyles = { display: 'none' };
                }
            },

            round(value, decimals) {
                return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
            },

            generatePhotoStyles(rotation) {
                return {
                    transform: 'rotate(' + rotation + 'deg)',
                };
            },

            generateCenterAxisStyles(topPercentage, color) {
                return {
                    width: '100%',
                    height: '1px',
                    top: topPercentage + '%',
                    left: '0px',
                    position: 'absolute',
                    border: 'none',
                    backgroundColor: color,
                    opacity: 0.33,
                };
            },

            generateFaceRectangle() {
                return {
                    width: this.rectWidth + '%',
                    height: this.rectHeight + '%',
                    top: this.topPercent + '%',
                    left: this.leftPercent + '%',
                    position: 'absolute',
                    border: '1px solid #24ff00',
                    backgroundColor: '#bbffb0',
                    opacity: 0.33,
                };
            },

            generateMissingRectangle() {
                return {
                    width: '100%',
                    height: '100%',
                    top: '0px',
                    left: '0px',
                    position: 'absolute',
                    border: '2px solid #ff0d00',
                    boxSizing: 'border-box',
                    opacity: 1,
                };
            },

            updatePhotoDimensions(event) {
                this.photoWidth = event.target.clientWidth;
                this.photoHeight = event.target.clientHeight;
                this.calculateBox();
            },
        },
    };
</script>

<style scoped>
    .photoWithToolTip {
        position: relative;
        display: inline-block;
        border-bottom: 1px dotted black;
    }

    .photoWithToolTip .tooltiptext {
        visibility: hidden;
        width: 100%;
        background-color: black;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 2px;
        /* Position the tooltip */
        position: absolute;
        bottom: 100%;
        left: 0;
        z-index: 1;
        font-size: 10px;
    }

    .photoWithToolTip:hover .tooltiptext {
        visibility: visible;
    }
</style>
