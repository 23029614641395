<template>
    <modal :click-overlay="() => $emit('hide')" size="m-l" :no-padding="true">
        <div slot="body">
            <panel :title="title" :has-margin="false">
                <platform-icon slot="titleIcon" :platform="release.platform" custom-classes="h-6 w-6" />
                <div slot="header" class="mr-2">
                    <dropdown
                        class="my-2 mr-2"
                        placeholder="All Issue Types"
                        :options="issueTypes"
                        @updated="(type) => (issueType = type)"
                    />
                </div>

                <div class="overflow-auto" style="max-height: 42rem">
                    <div v-show="isLoading || noIssuesFound" class="text-xl py-4 text-center font-bold">
                        <span v-show="isLoading">Fetching Issues...</span>
                        <span v-show="!isLoading && noIssuesFound">No issues found for this release</span>
                    </div>
                    <issue-single v-for="issue in issues" :id="issue.id" :key="issue.id" :issue="issue" />
                    <div class="max-w-xs mx-auto py-4">
                        <M2Button v-show="nextPageKey" @clicked="getIssues">Fetch more issues</M2Button>
                    </div>
                </div>
            </panel>
        </div>
    </modal>
</template>

<script>
    import Modal from '../../components/Modal/Modal';
    import Panel from '../../components/Panels/Panel';
    import PlatformIcon from './PlatformIcon';
    import IssueSingle from './IssueSingle';
    import Dropdown from '../../components/Dropdown/Dropdown';
    import M2Button from '../../components/Buttons/M2Button';
    import { dashboardAxios } from '../../../axios.config';

    export default {
        components: {
            Modal,
            Panel,
            PlatformIcon,
            Dropdown,
            IssueSingle,
            M2Button,
        },
        props: {
            release: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                isLoading: false,
                issueType: null,
                nextPageKey: null,
                issues: [],
                issueTypes: ['APP_BROKEN'],
            };
        },
        computed: {
            title() {
                return `${this.release.version} Reported Issues`;
            },
            noIssuesFound() {
                return this.issues.length === 0;
            },
        },
        watch: {
            issueType() {
                this.issues = [];
                this.nextPageKey = null;
                this.getIssues();
            },
        },
        created() {
            this.getIssueTypes();
            this.getIssues();
        },
        methods: {
            async getIssueTypes() {
                try {
                    const resp = await dashboardAxios.get(
                        `/v1/release-notes/${this.release.version}/reported-issue-types`,
                    );
                    this.issueTypes = resp.data.result.map((issueType) => ({
                        label: this.toTitleCase(issueType.replace(/_/g, ' ')),
                        value: issueType,
                    }));
                } catch (err) {
                    console.log(err);
                }
            },
            async getIssues() {
                this.isLoading = true;
                try {
                    const type = this.issueType === null ? '' : `/${this.issueType}`;
                    const resp = await dashboardAxios.get(
                        `/v1/release-notes/${this.release.version}/reported-issues${type}?nextPageKey=${this.nextPageKey}`,
                    );
                    const lastIssue = this.issues.length === 0 ? null : this.issues[this.issues.length - 1];
                    this.issues.push(...resp.data.result.issues);
                    this.nextPageKey =
                        parseInt(resp.data.result.total, 10) === this.issues.length
                            ? null
                            : resp.data.result.nextPageKey;
                    if (lastIssue) {
                        this.$nextTick(() => {
                            const id = document.getElementById(lastIssue.id);

                            if (!id) return;

                            id.scrollIntoView();
                        });
                    }
                } catch (err) {
                    console.log(err);
                }

                this.isLoading = false;
            },
            toTitleCase(str) {
                return str.replace(/\w\S*/g, function (txt) {
                    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                });
            },
        },
    };
</script>
