<template>
    <div>
        <img v-if="attachmentUrl" :src="attachmentUrl" class="w-48" @click="showModal = true" />

        <Modal v-if="showModal" :click-overlay="() => (showModal = false)">
            <template #header>
                <IconButton rounded class="float-right right-0 top-0 absolute mt-2 mr-2" @click="showModal = false">
                    Close
                </IconButton>
            </template>
            <template #body>
                <img class="cursor-pointer h-full" :src="attachmentUrl" />
            </template>
        </Modal>
    </div>
</template>

<script>
    export default {
        props: {
            attachmentUrl: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                showModal: false,
            };
        },
    };
</script>
