<template>
    <div class="flex flex-col w-full md:w-1/2 lg:w-1/3 md:p-0 p-4 mx-auto font-avenir mt-20">
        <div class="my-4">
            <input
                v-model="email"
                type="text"
                class="w-full p-4 border border-grey-300 rounded"
                placeholder="Email address"
                autofocus
                @keyup.enter="checkForm"
            />

            <p class="text-red my-1">{{ errors }}</p>
        </div>

        <div @click="checkForm">
            <Button>Sign In</Button>
        </div>
    </div>
</template>

<script>
    import { dashboardAxios } from '../../axios.config';

    export default {
        name: 'RequestPIN',

        // Save the previous route in the data of the component
        beforeRouteEnter(to, from, next) {
            next((vm) => (vm.prevRoute = from));
        },

        data() {
            return {
                email: '',
                errors: '',
                prevRoute: null,
            };
        },

        methods: {
            async checkForm(event) {
                event.preventDefault();

                this.errors = '';

                if (this.email === '') {
                    this.errors = 'Email is required.';

                    return;
                }

                const email = this.email.includes('@') ? this.email : `${this.email}@muzz.com`;

                try {
                    await dashboardAxios.post('/v1/auth/pin/request', {
                        emailAddress: email,
                    });

                    let redirectTo = this.prevRoute ? this.prevRoute.fullPath : null;

                    if (redirectTo !== null && redirectTo !== '/') {
                        return await this.$router.push({
                            path: '/verify-password',
                            query: {
                                redirectTo: decodeURIComponent(redirectTo),
                                email,
                            },
                        });
                    }

                    await this.$router.push({
                        path: '/verify-password',
                        query: { email },
                    });
                } catch (error) {
                    this.errors = 'Invalid email.';
                }
            },
        },
    };
</script>
