<template>
    <div class="border rounded shadow p-3 mb-8">
        <div class="flex items-center pt-1 pb-2">
            <div class="w-1/12">
                <img src="@/assets/logo-mark@3x.png" class="w-4 h-auto" />
            </div>
            <div class="w-4/12 text-xs text-grey-500">MUZZ</div>
            <div class="w-7/12 text-right text-xs text-grey-500">NOW</div>
        </div>
        <div
            class="font-bold text-sm"
            :class="rtl ? 'right-to-left' : ''"
            :contenteditable="isEditing"
            @click="edit"
            @keypress="onKeyPress($event, 'title')"
            @input="onInput($event, 'title')"
            @blur="blurred"
        >
            <span v-if="isEditing">{{ notification.pushTitle }}</span>
            <span v-else v-html="render(notification.pushTitle)"></span>
        </div>
        <div
            class="break-words text-sm"
            :class="rtl ? 'right-to-left' : ''"
            :contenteditable="isEditing"
            @click="edit"
            @keypress="onKeyPress($event, 'body')"
            @input="onInput($event, 'body')"
            @blur="blurred"
        >
            <span v-if="isEditing">{{ notification.pushBody }}</span>
            <span v-else v-html="render(notification.pushBody)"></span>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';

    const CONTENT_LENGTH = {
        title: 30,
        body: 60,
    };

    const contentLengthAlert = (key, length) => ({
        message: `Push notification ${key} is limited to ${length} characters`,
        type: 'danger',
    });

    export default {
        props: {
            isEditing: {
                type: Boolean,
                default: false,
            },
            notification: {
                type: Object,
                required: true,
            },
            language: {
                type: String,
                default: 'EN',
            },
            rtl: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                update: {
                    title: null,
                    body: null,
                },
            };
        },
        watch: {
            notification() {
                this.update.title = this.notification.pushTitle;
                this.update.body = this.notification.pushBody;
            },
        },
        mounted() {
            this.update.title = this.notification.pushTitle;
            this.update.body = this.notification.pushBody;
        },
        methods: {
            ...mapActions({
                alert: 'alert',
            }),
            edit() {
                this.$emit('editing');
            },
            onKeyPress(e, key) {
                let text = e.target.innerText.replaceAll(/\n|\r/g, '');
                const length = CONTENT_LENGTH[key];

                if (text.length === length) this.alert(contentLengthAlert(key, length));
                if (text.length > length) e.preventDefault();
            },
            onInput(e, key) {
                let text = e.target.innerText.replaceAll(/\n|\r/g, '');
                const length = CONTENT_LENGTH[key];

                switch (e.inputType) {
                    case 'insertFromPaste':
                        if (text.length > length) {
                            this.alert(contentLengthAlert(key, length));
                            text = text.slice(0, length);
                            e.target.innerText = text.slice(0, length);
                        }
                        break;
                }

                if (text.length <= length) this.update[key] = text;
            },
            render(text) {
                return text;
            },
            blurred() {
                if (
                    this.update.title !== this.notification.pushTitle ||
                    this.update.body !== this.notification.pushBody
                ) {
                    this.$emit('blurred');
                }
            },
        },
    };
</script>

<style scoped>
    .right-to-left {
        direction: rtl;
        text-align: right;
    }
</style>
