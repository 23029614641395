<template>
    <panel :has-margin="false" title="Template Builder">
        <div class="overflow-y-scroll h-screen-80">
            <Draggable class="h-full" :list="nodes.children" group="people" handle=".handle">
                <div v-for="(element, index) in nodes.children" :key="index" class="list-group-item">
                    <campaign-element :element="element" @remove="removeElement(index)" />
                </div>
            </Draggable>
        </div>
    </panel>
</template>

<script>
    import CampaignElement from './CampaignElement.vue';
    import Panel from '../Panels/Panel.vue';
    import Draggable from 'vuedraggable';

    export default {
        components: {
            Panel,
            CampaignElement,
            Draggable,
        },
        props: {
            nodes: {
                type: Object,
                default: null,
            },
        },
        methods: {
            removeElement(elementIndex) {
                const index = _.findIndex(this.nodes.children, (node, nodeIndex) => nodeIndex === elementIndex);
                this.$delete(this.nodes.children, index);
            },
        },
    };
</script>
