<template>
    <Modal :click-overlay="toggleModal">
        <template #header>
            <div class="hidden md:block">
                <IconButton rounded class="float-right right-0 top-0 absolute mt-4 mr-4" @click="toggleModal">
                    Close
                </IconButton>
            </div>
        </template>

        <template #body>
            <div class="flex flex-col w-full max-h-90">
                <div class="mt-3 text-center">
                    <DashboardLabel class="mb-5 block">Photo {{ currentImgIndex + 1 }}</DashboardLabel>
                </div>

                <div class="flex flex-row w-full my-3">
                    <div class="w-1/12 flex flex-col mr-3">
                        <icon
                            icon="minus-circle"
                            class="cursor-pointer mt-20 text-5xl mx-auto"
                            :class="
                                zoomFactor === minZoom ? 'text-grey-light cursor-not-allowed' : 'text-pink-muzmatch'
                            "
                            @click="updateZoomFactor(--zoomFactor)"
                        />
                        <icon
                            icon="arrow-circle-up"
                            class="cursor-pointer mt-20 text-5xl mx-auto text-pink-muzmatch"
                            @click="rotateBase64Image(-90, (result) => (currentImage = result))"
                        />
                    </div>

                    <div class="w-10/12">
                        <img
                            class="rounded-lg m-auto"
                            :class="documentPhotoSize"
                            :alt="frameList[currentImgIndex].id"
                            :src="currentImage"
                        />
                    </div>

                    <div class="w-1/12 flex flex-col ml-3">
                        <icon
                            icon="plus-circle"
                            class="cursor-pointer mt-20 text-5xl mx-auto"
                            :class="
                                zoomFactor === maxZoom ? 'text-grey-light cursor-not-allowed' : 'text-pink-muzmatch'
                            "
                            @click="updateZoomFactor(++zoomFactor)"
                        />
                        <icon
                            icon="arrow-circle-down"
                            class="cursor-pointer mt-20 text-5xl mx-auto text-pink-muzmatch"
                            @click="rotateBase64Image(90, (result) => (currentImage = result))"
                        />
                    </div>
                </div>

                <div v-if="!isOneFrame" class="flex flex-row text-center my-3">
                    <div class="w-1/2">
                        <icon
                            icon="chevron-left"
                            :class="isFirstImage ? 'text-grey-light cursor-not-allowed' : 'text-pink-muzmatch'"
                            class="cursor-pointer text-3xl"
                            @click="getPreviousImage"
                        />
                    </div>

                    <div class="w-1/2">
                        <icon
                            icon="chevron-right"
                            :class="isLastImage ? 'text-grey-light cursor-not-allowed' : 'text-pink-muzmatch'"
                            class="cursor-pointer text-3xl"
                            @click="getNextImage"
                        />
                    </div>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script>
    import Modal from '@/components/Modal/Modal';
    import { resolvePhotoUrl, resolveVerificationPhotoUrl, resolveVideoUrl } from '@/helpers/DashboardUtils';
    import EventBus from '@/helpers/eventBus';
    import DashboardLabel from '@/components/Labels/DashboardLabel';
    import IconButton from '@/components/Buttons/IconButton';

    export default {
        name: 'IdentityVerificationPhotoModal',

        components: {
            Modal,
            DashboardLabel,
            IconButton,
        },

        props: {
            frameList: {
                type: Array,
                required: true,
            },
            currentImgIndex: {
                type: Number,
                required: true,
            },
            nextImgIndex: {
                type: Number,
                required: true,
            },
        },

        data() {
            return {
                currentImage: '',
                maxZoom: 4,
                minZoom: 1,
                zoomFactor: 2,
            };
        },

        computed: {
            isOneFrame() {
                return this.frameList.length === 1;
            },

            isFirstImage() {
                return this.currentImgIndex === 0;
            },

            isLastImage() {
                return this.nextImgIndex === this.frameList.length;
            },

            documentPhotoSize() {
                if (this.zoomFactor === this.maxZoom) {
                    return 'w-full';
                }

                if (this.maxZoom / this.zoomFactor === 2) {
                    return 'w-1/2';
                }

                return `w-${this.zoomFactor}/${this.maxZoom}`;
            },
        },

        watch: {
            currentImgIndex() {
                this.currentImage = this.frameList[this.currentImgIndex].base64Content;
            },
        },

        mounted() {
            this.currentImage = this.frameList[this.currentImgIndex].base64Content;
            this.findImageOrientation((orientation) => {
                if (orientation === 'portrait') {
                    this.zoomFactor = 1;
                }
            });

            EventBus.$on('globalKeyup', this.handleKeyup);
        },

        destroyed() {
            EventBus.$off('globalKeyup', this.handleKeyup);
        },

        methods: {
            resolvePhotoUrl: resolvePhotoUrl,
            resolveVerificationPhotoUrl: resolveVerificationPhotoUrl,
            resolveVideoUrl: resolveVideoUrl,

            toggleModal() {
                this.$emit('toggle-modal');
            },

            getPreviousImage() {
                if (this.isOneFrame || this.isFirstImage) {
                    return;
                }
                this.$emit('decrement-index');
            },

            getNextImage() {
                if (this.isOneFrame || this.isLastImage) {
                    return;
                }
                this.$emit('increment-index');
            },

            handleKeyup(event) {
                if (event.key === 'Escape') {
                    this.toggleModal();
                }

                if (event.key === 'ArrowRight') {
                    this.getNextImage();

                    return;
                }

                if (event.key === 'ArrowLeft') {
                    this.getPreviousImage();
                }
            },

            updateZoomFactor(factor) {
                if (factor > this.maxZoom) {
                    this.zoomFactor = this.maxZoom;
                    return;
                }

                if (factor < this.minZoom) {
                    this.zoomFactor = this.minZoom;
                    return;
                }

                this.zoomFactor = factor;
            },

            findImageOrientation(callback) {
                const image = new Image();

                image.onload = () => {
                    let orientation = 'landscape';
                    if (image.naturalWidth < image.naturalHeight) {
                        orientation = 'portrait';
                    }

                    callback(orientation);
                };

                image.src = this.currentImage;
            },

            rotateBase64Image(degrees, callback) {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                const image = new Image();

                image.onload = () => {
                    canvas.width = degrees % 180 === 0 ? image.width : image.height;
                    canvas.height = degrees % 180 === 0 ? image.height : image.width;

                    ctx.translate(canvas.width / 2, canvas.height / 2);
                    ctx.rotate((degrees * Math.PI) / 180);
                    ctx.drawImage(image, image.width / -2, image.height / -2);

                    callback(canvas.toDataURL());
                };

                image.src = this.currentImage;
            },
        },
    };
</script>

<style scoped>
    .max-h-90 {
        max-height: 90%;
    }
</style>
