import { render, staticRenderFns } from "./PushNotification.vue?vue&type=template&id=7723a5c5&scoped=true&"
import script from "./PushNotification.vue?vue&type=script&lang=js&"
export * from "./PushNotification.vue?vue&type=script&lang=js&"
import style0 from "./PushNotification.vue?vue&type=style&index=0&id=7723a5c5&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7723a5c5",
  null
  
)

export default component.exports