<template>
    <Modal :click-overlay="toggleModal" size="responsive">
        <template #header>
            <h1 class="text-lg mt-1 mb-2">Select accounts to add favourite</h1>
        </template>
        <template #body>
            <p v-if="!linkedMembers.length">
                Wali account yet not associated with Muzz accounts. You can link an account below.
            </p>

            <table class="sm:w-full text-left table-fixed overflow-x-scroll">
                <thead>
                    <tr class="bg-grey-100 text-grey-700 uppercase">
                        <th class="px-4 py-2 w-2/5">Photo</th>
                        <th class="px-4 py-2 w-2/5">Name</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="member in linkedMembers"
                        v-if="member.memberID !== thisMember.memberID"
                        :key="member.memberID"
                        class="border hover:bg-grey-200"
                        @click="
                            () =>
                                thisMember.gender !== member.gender && selectUser(member.memberID, member.is_favourited)
                        "
                    >
                        <td class="w-2/6 px-4 py-2 break-words">
                            <img
                                class="inline object-cover w-24 h-24 mr-2 rounded-full"
                                :src="
                                    member.photo.signedUrl
                                        ? member.photo.signedUrl
                                        : resolvePhotoUrl(member.photo.key, member.hashMemberID, 'portrait', false)
                                "
                                @error="$event.target.src = require('../../assets/mm2.png')"
                            />
                        </td>
                        <td>
                            {{ member.nickname }}
                        </td>
                        <td>
                            <label :for="member.memberID">
                                <p v-if="thisMember.gender === member.gender">Same Gender</p>
                                <input
                                    v-else
                                    :id="member.memberID"
                                    type="checkbox"
                                    class="favourites-modal-checkbox"
                                    :class="thisMember.gender === member.gender && 'disabled'"
                                    :disabled="thisMember.gender === member.gender || member.is_favourited"
                                    :checked="
                                        member.is_favourited || checkedMembers.includes(parseInt(member.memberID))
                                    "
                                />
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <input
                                id="member-id-to-link"
                                v-model="memberIdToLink"
                                class="p-2 border border-grey-300 rounded"
                                type="number"
                                placeholder="Member ID"
                            />
                        </td>
                        <td />
                        <td>
                            <div class="sm:m-6">
                                <IconButton rounded @click="() => !!memberIdToLink && linkMember(memberIdToLink)">
                                    Link Member
                                </IconButton>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </template>
        <template #footer>
            <div class="sm:m-6">
                <IconButton rounded class="float-left" @click="toggleModal">Close</IconButton>
            </div>
            <div class="sm:m-6">
                <IconButton
                    v-if="checkedMembers.length > 0"
                    class="float-right"
                    rounded
                    @click="makeFavourite(checkedMembers)"
                >
                    Make Favourite
                </IconButton>
            </div>
        </template>
    </Modal>
</template>

<script>
    import Modal from './Modal';
    import { resolvePhotoUrl } from '@/helpers/DashboardUtils';
    import { dashboardAxios } from '../../../axios.config';
    import { mapActions } from 'vuex';
    import { IconButton } from '@/components';

    export default {
        name: 'FavouritesModal',

        components: {
            Modal,
            IconButton,
        },

        props: {
            thisMember: {
                required: true,
                type: Object,
            },
        },

        data() {
            return {
                linkedMembers: [],
                checkedMembers: [],
                memberIdToLink: null,
            };
        },

        watch: {
            memberIdToLink(value) {
                this.memberIdToLink = parseInt(value);
            },
        },

        mounted() {
            this.getLinkedMembers();
        },

        methods: {
            ...mapActions({
                alert: 'alert',
            }),

            resolvePhotoUrl,

            selectUser(memberId, isFavourited) {
                memberId = parseInt(memberId);

                if (this.checkedMembers.includes(memberId)) {
                    this.checkedMembers = this.checkedMembers.filter((item) => item !== memberId);

                    return;
                }

                if (!isFavourited) {
                    this.checkedMembers.push(memberId);
                }
            },

            toggleModal() {
                this.$emit('toggle-modal');
            },

            getLinkedMembers() {
                dashboardAxios
                    .get('/v1/linked-members', {
                        params: {
                            member_id: this.thisMember.memberID,
                        },
                    })
                    .then(({ data }) => {
                        let linkedMembers = data.result.data;

                        linkedMembers.map((member) => {
                            member.mediaState = member.mediaState && JSON.parse(member.mediaState);
                            member.mediaSigned = member.mediaSigned && JSON.parse(member.mediaSigned);

                            member.photo = {
                                key:
                                    member &&
                                    member.mediaState &&
                                    member.mediaState.complete &&
                                    member.mediaState.complete.image.verification &&
                                    member.mediaState.complete.image.verification.visible,
                                signedUrl:
                                    member &&
                                    member.mediaSigned &&
                                    member.mediaSigned &&
                                    member.mediaSigned.verification &&
                                    member.mediaSigned.verification.url,
                            };
                        });

                        this.linkedMembers = linkedMembers;
                    })
                    .catch(() => {
                        this.showFavouritesModal = false;

                        this.alert({
                            message: 'There was an error getting the linked members',
                            type: 'danger',
                        });
                    });
            },

            makeFavourite(memberIdsToFavourite) {
                dashboardAxios
                    .post('/v1/make-favourite', {
                        member_id: this.thisMember.memberID,
                        member_ids_to_favourite: memberIdsToFavourite,
                    })
                    .then(() => {
                        this.alert({
                            message: 'Favourites added successfully',
                            type: 'success',
                        });

                        this.$emit('toggle-modal');
                    })
                    .catch(() => {
                        this.alert({
                            message: 'There was an error making the users favourites',
                            type: 'danger',
                        });
                    });
            },

            linkMember(memberId) {
                dashboardAxios
                    .post('/v1/link-member', {
                        current_member_id: this.thisMember.memberID,
                        member_id: memberId,
                    })
                    .then(({ data }) => {
                        this.getLinkedMembers();

                        this.memberIdToLink = null;

                        this.alert({
                            message: data.result.message || 'Member linked successfully',
                            type: 'success',
                        });
                    })
                    .catch((error) => {
                        const data = error.response.data;

                        this.alert({
                            message: data.error.message || 'There was an error adding the user to linked members',
                            type: 'danger',
                        });
                    });
            },
        },
    };
</script>

<style>
    input[id='manual-member-id']::-webkit-outer-spin-button,
    input[id='manual-member-id']::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    input[id='manual-member-id'] {
        -moz-appearance: textfield; /* Firefox */
    }
</style>
