<template>
    <div>
        <Modal :click-overlay="() => $emit('close-modal')" size="responsive" :no-padding="true">
            <div slot="body">
                <Panel title="Select Audience" :has-margin="false" :custom-classes="['bg-pink-muzmatch', 'text-white']">
                    <div slot="header" class="ml-auto p-2">
                        <button
                            v-if="showTemplates"
                            class="w-full border border-pink-dark rounded bg-white text-center text-xs text-black p-2"
                            @click="newAudience"
                        >
                            Add New Audience
                        </button>
                    </div>
                    <div class="p-4">
                        <div class="w-full">
                            <div v-if="!showTemplates">
                                <label>Current Audience</label>
                                <p class="mt-3 font-semibold">{{ currentAudience.title }}</p>
                            </div>
                            <div v-else>
                                <dropdown
                                    :options="audienceOptions"
                                    :is-capitalize="false"
                                    placeholder="Select optional audience template"
                                    @updated="selectedAudienceID = $event"
                                />
                            </div>
                        </div>
                        <audience-json-description
                            v-if="selectedAudienceJsonFilter"
                            class="w-full italic text-sm my-5"
                            :audience-json-filter="selectedAudienceJsonFilter"
                        />
                        <div class="w-full">
                            <div v-if="!showTemplates" class="flex mt-3 space-x-2">
                                <div class="w-1/2">
                                    <m2-button
                                        :custom-classes="['text-xs', 'normal-case', 'rounded']"
                                        @clicked="editAudience"
                                    >
                                        Edit Audience
                                    </m2-button>
                                </div>
                                <div class="w-1/2">
                                    <m2-button
                                        :custom-classes="['text-xs', 'normal-case', 'rounded']"
                                        @clicked="removeAudience"
                                    >
                                        Remove Audience
                                    </m2-button>
                                </div>
                            </div>
                            <div v-if="showTemplates && selectedAudienceID" class="flex mt-3 space-x-2">
                                <div class="w-1/2">
                                    <m2-button
                                        :custom-classes="['text-xs', 'normal-case', 'rounded']"
                                        @clicked="copyAudience"
                                    >
                                        Edit Audience
                                    </m2-button>
                                </div>
                                <div class="w-1/2">
                                    <m2-button
                                        :is-disabled="duplicatingStatus"
                                        :custom-classes="['text-xs', 'normal-case', 'rounded']"
                                        @clicked="saveAndDuplicateAudience"
                                    >
                                        <span v-if="!duplicatingStatus">Select Audience</span>
                                        <icon v-else class="animate-spin" icon="spinner" />
                                    </m2-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Panel>
            </div>
        </Modal>
        <create-audience-modal
            v-if="showCreateAudienceModal"
            :audience="editableAudience"
            :audience-fields="audienceFields"
            :update-type="updateType"
            @update-audiences="$emit('update-audiences', $event)"
            @close="showCreateAudienceModal = false"
        />
    </div>
</template>

<script>
    import M2Button from '../../components/Buttons/M2Button.vue';
    import Dropdown from '../../components/Dropdown/Dropdown.vue';
    import CreateAudienceModal from './CreateAudienceModal';
    import AudienceJsonDescription from './AudienceJsonDescription';
    import { messengerAxios } from '../../../axios.config';
    import { mapActions } from 'vuex';

    export default {
        components: {
            CreateAudienceModal,
            AudienceJsonDescription,
            M2Button,
            Dropdown,
        },
        props: {
            currentAudience: {
                type: Object,
                default: null,
            },
            audiences: {
                type: Array,
                required: true,
            },
            audienceFields: {
                type: Array,
                required: true,
            },
            step: {
                type: Number,
                default: null,
            },
            defaultAudience: {
                type: Number,
                default: null,
            },
        },
        data() {
            return {
                showCreateAudienceModal: false,
                selectedAudienceID: null,
                selectedAudienceJsonFilter: null,
                editableAudience: null,
                overrideShowTemplate: false,
                updateType: 'EDIT',
                duplicatingStatus: false,
            };
        },
        computed: {
            audienceOptions() {
                return this.audiences.map((a) => ({
                    value: a.id,
                    label: a.title,
                }));
            },
            showTemplates() {
                return this.currentAudience === null || this.overrideShowTemplate;
            },
        },
        watch: {
            selectedAudienceID() {
                this.updateAudienceJsonFilter();
            },
        },
        mounted() {
            if (this.currentAudience) {
                this.selectedAudienceJsonFilter = _.clone(this.currentAudience.jsonFilter);
            }
        },
        methods: {
            ...mapActions({
                alert: 'alert',
                handleError: 'handleError',
            }),
            updateAudienceJsonFilter() {
                this.selectedAudienceJsonFilter = _.clone(this.audiences.find((i) => i.id === this.selectedAudienceID))[
                    'jsonFilter'
                ];
            },
            removeAudience() {
                this.overrideShowTemplate = true;
                this.selectedAudienceJsonFilter = null;
            },
            editAudience() {
                this.updateType = 'EDIT';
                this.editableAudience = _.clone(this.currentAudience);
                this.showCreateAudienceModal = true;
            },
            copyAudience() {
                this.updateType = 'COPY';
                let audience = _.clone(this.audiences.find((i) => i.id === this.selectedAudienceID));
                audience.id = null;
                audience.title = null;
                this.editableAudience = audience;
                this.showCreateAudienceModal = true;
            },
            newAudience() {
                this.updateType = 'NEW';
                this.editableAudience = null;
                this.showCreateAudienceModal = true;
            },
            async saveAndDuplicateAudience() {
                this.duplicatingStatus = true;

                try {
                    const response = await messengerAxios.post(`/audience/duplicate`, { id: this.selectedAudienceID });

                    if (response.data.status === 200) {
                        this.$emit('update-audiences', response.data.result);
                    }
                } catch (error) {
                    await this.handleError({ error });
                }

                this.duplicatingStatus = false;
            },
        },
    };
</script>
