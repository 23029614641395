<template>
    <div class="w-full h-full flex">
        <div class="md:w-1/5 md:px-2 w-full overflow-y-scroll fullscreen pb-2 px-2 h-screen-90">
            <Panel title="Filter Issues" :has-margin="false">
                <div v-if="range.start !== null" class="block mb-3 p-2">
                    <form class="bg-white rounded pb-2" @submit.prevent>
                        <div class="mb-2">
                            <DatePicker v-model="range" mode="date" :masks="masks" is-range>
                                <template #default="{ inputValue, inputEvents, isDragging }">
                                    <div class="flex flex-col sm:flex-row justify-start items-center pr-2">
                                        <div class="relative flex-grow">
                                            <input
                                                class="flex-grow pr-2 py-1 bg-gray-100 border rounded w-full"
                                                :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                                                :value="inputValue.start"
                                                v-on="inputEvents.start"
                                            />
                                        </div>
                                        <span class="flex-shrink-0 m-2"></span>
                                        <div class="relative flex-grow">
                                            <input
                                                class="flex-grow pr-2 py-1 bg-gray-100 border rounded w-full"
                                                :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                                                :value="inputValue.end"
                                                v-on="inputEvents.end"
                                            />
                                        </div>
                                    </div>
                                </template>
                            </DatePicker>
                        </div>
                        <div>
                            <div class="float-left w-1/2 mb-2 pr-1">
                                <Dropdown
                                    class="my-2 mr-2"
                                    placeholder="All Platforms"
                                    :options="filterList.deviceList"
                                    @updated="(device) => (filterList.selectedDevice = device)"
                                />
                            </div>
                            <div class="float-left w-1/2 mb-2 pl-1">
                                <Dropdown
                                    class="my-2 mr-2"
                                    placeholder="All Versions"
                                    :options="filterList.appVersionList"
                                    @updated="(appVersion) => (filterList.selectedAppVersion = appVersion)"
                                />
                            </div>
                        </div>
                        <div class="w-full flex justify-end">
                            <button class="p-2 text-white bg-pink-muzmatch rounded mr-2" @click="getTagList">
                                Apply filter
                            </button>
                        </div>
                    </form>
                </div>
            </Panel>

            <Panel title="Issue Tags" :has-margin="false">
                <div class="pb-3">
                    <div v-if="tagLoading === true" class="pl-4">
                        <p>Loading tag list...</p>
                    </div>
                    <div
                        v-for="(tag, index) in tagList"
                        v-show="tagLoading === false"
                        :key="tag.id"
                        class="p-2 mb-1 hover:bg-grey-400 rounded cursor-pointer"
                        :class="{ 'bg-grey-300': index === selectedTag }"
                        @click="getTaskMembers(tag.id, index)"
                    >
                        <span class="pl-2">{{ tag.name }}</span>
                        <Badge class="float-right" :number="parseInt(tag.totalCount)" type="muzmatch" />
                    </div>
                    <div v-if="tagLoading === false && !tagList.length" class="pl-4">
                        <p>No tags were created for this date range</p>
                    </div>
                </div>
            </Panel>
        </div>

        <div v-if="taskLoading" class="md:w-1/5 md:px-2 sm:w-full py-1 pl-2">
            <p>Loading issue list...</p>
        </div>

        <div v-if="taskList.length && taskLoading === false" class="md:w-1/5 md:px-2 sm:w-full py-1">
            <Panel class="h-full" title="Issue List" :has-margin="false">
                <div class="h-screen-80 overflow-y-scroll">
                    <div v-for="taskItem in taskList" :key="taskItem.id" class="p-2 mb-1 rounded cursor-pointer">
                        <CurrentTask
                            v-if="taskItem"
                            :queue="taskItem.queue"
                            :task="taskItem"
                            :task-tag-page="true"
                            :selected="taskMember"
                            @selected="memberSelected"
                        />
                    </div>
                </div>
            </Panel>
        </div>

        <div
            v-if="queue !== null && contextType === 'CONTEXT_TYPE_TASK_EMAIL'"
            class="md:px-2 sm:w-full py-1 md:w-3/5 h-screen-90"
        >
            <EmailPanel
                :flagged="queue.url.includes('flagged')"
                :thread="task.thread"
                :member-email-addresses="task.memberEmailAddresses"
                :thread-email-addresses="task.threadEmailAddresses"
                :context-type="contextType"
                context-action="CONTEXT_ACTION_TASK_EMAIL_PANEL"
                @refreshTask="refreshTask"
            ></EmailPanel>
        </div>

        <div
            v-if="queue !== null && contextType === 'CONTEXT_TYPE_TASK_APP_REVIEWS'"
            class="md:w-3/5 md:px-2 sm:w-full pb-4 pt-1"
        >
            <ReviewPanel
                :review="task.app_reviews"
                :context-type="contextType"
                context-action="CONTEXT_ACTION_TASK_APP_REVIEWS_PANEL"
                @refreshTask="refreshTask"
            />
        </div>

        <div
            v-if="
                queue !== null &&
                contextType !== 'CONTEXT_TYPE_TASK_EMAIL' &&
                contextType !== 'CONTEXT_TYPE_TASK_APP_REVIEWS'
            "
            class="md:px-2 sm:w-full py-1 md:w-3/5 h-screen-90"
        >
            <DashboardPanel
                :member-hash-id="taskMember ? taskMember.id : ''"
                :member="member"
                :member-loading="memberLoading"
                :queue="queue"
                :task="task"
                :context-type="contextType"
                :even-split="evenSplit"
                :is-report-task="isReportTask"
                @memberSelected="memberSelected"
            ></DashboardPanel>
        </div>
    </div>
</template>

<script>
    import { dashboardAxios } from '../../axios.config';
    import Panel from '../components/Panels/Panel';
    import { resolvePhotoUrl } from '../helpers/DashboardUtils';
    import { getMemberRoute } from '../helpers/RedirectToMember';
    import CurrentTask from './TaskManager/CurrentTask';
    import EmailPanel from '../components/Panels/EmailPanel';
    import ReviewPanel from '../components/Panels/ReviewPanel';
    import Dropdown from '../components/Dropdown/Dropdown';
    import DatePicker from 'v-calendar/lib/components/date-picker.umd';

    export default {
        name: 'TaskTags',

        components: {
            Panel,
            CurrentTask,
            EmailPanel,
            ReviewPanel,
            Dropdown,
            DatePicker,
        },

        data() {
            return {
                selectedTag: '',
                tagList: [],
                taskList: [],
                task: null,
                queue: null,
                taskMember: false,
                member: null,
                tagLoading: false,
                taskLoading: false,
                memberLoading: false,
                filterList: {
                    selectedDevice: null,
                    selectedAppVersion: null,
                    deviceList: [
                        { value: 'android', label: 'Android' },
                        { value: 'iOS', label: 'IOS' },
                    ],
                    appVersionList: [],
                },
                range: {
                    end: new Date(),
                    start: null,
                },
                masks: {
                    input: 'YYYY-MM-DD',
                },
            };
        },

        computed: {
            contextType() {
                if (!this.queue || !this.queue.text) {
                    return 'CONTEXT_TYPE_TASK_UNKNOWN';
                }

                switch (this.queue.text) {
                    case 'Approve':
                        return 'CONTEXT_TYPE_TASK_APPROVE';
                    case 'Inappropriate Profile':
                        return 'CONTEXT_TYPE_TASK_INAPPROPRIATE_PROFILE';
                    case 'Inappropriate Message':
                        return 'CONTEXT_TYPE_TASK_INAPPROPRIATE_MESSAGE';
                    case 'Spam/Scam':
                        return 'CONTEXT_TYPE_TASK_SPAM_SCAM';
                    case 'Other Report':
                        return 'CONTEXT_TYPE_TASK_OTHER';
                    case 'Issue':
                        return 'CONTEXT_TYPE_TASK_ISSUE';
                    case 'Email':
                        return 'CONTEXT_TYPE_TASK_EMAIL';
                    case 'App Reviews':
                        return 'CONTEXT_TYPE_TASK_APP_REVIEWS';
                }

                return 'CONTEXT_TYPE_TASK_UNKNOWN';
            },

            evenSplit() {
                if (!this.queue) {
                    return false;
                }

                let evenSplit = ['Email'];

                return evenSplit.includes(this.queue.text);
            },

            isReportTask() {
                if (!this.queue || !this.queue.url) return false;

                let reportTasks = ['Inappropriate Profile', 'Inappropriate Message', 'Spam/Scam', 'Other Report'];

                return reportTasks.includes(this.queue.text);
            },
        },

        async mounted() {
            //Set start date as one month back
            this.range.start = new Date(
                this.range.end.getFullYear(),
                this.range.end.getMonth() - 1,
                this.range.end.getDate(),
            );

            await this.getTagList();
            await this.getFilterList();
        },

        methods: {
            async getFilterList() {
                let response = await dashboardAxios.get('/v1/issue-tracker/filter-list');

                let app_version_list = _.map(response.data.result.app_version_list, (app_version) => ({
                    label: app_version.label,
                    value: app_version.label,
                }));
                this.filterList.appVersionList = app_version_list;
            },

            async getTagList() {
                this.tagLoading = true;
                this.selectedTag = '';
                this.task = null;
                this.queue = null;
                this.taskList = [];

                let response = await dashboardAxios.get(
                    '/v1/issue-tracker/badges?start=' +
                        this.range.start.toLocaleDateString('en-GB') +
                        '&end=' +
                        this.range.end.toLocaleDateString('en-GB') +
                        '&device_os=' +
                        (this.filterList.selectedDevice ? this.filterList.selectedDevice : '') +
                        '&app_version=' +
                        (this.filterList.selectedAppVersion ? this.filterList.selectedAppVersion : ''),
                );

                this.tagList = response.data.result;
                this.tagLoading = false;
            },

            async getTaskMembers(tagId, index) {
                this.taskLoading = true;
                this.task = null;
                this.queue = null;

                let response = await dashboardAxios.get(
                    `/v1/issue-tracker/tag/${tagId}?start=` +
                        this.range.start.toLocaleDateString('en-GB') +
                        '&end=' +
                        this.range.end.toLocaleDateString('en-GB') +
                        '&device_os=' +
                        (this.filterList.selectedDevice ? this.filterList.selectedDevice : '') +
                        '&app_version=' +
                        (this.filterList.selectedAppVersion ? this.filterList.selectedAppVersion : ''),
                );

                this.taskList = this.processTaskList(response.data.result);
                this.selectedTag = index;

                if (this.taskList[0].second_member) {
                    this.memberSelected(this.taskList[0].second_member, this.taskList[0]);
                } else if (this.taskList[0].first_member) {
                    this.memberSelected(this.taskList[0].first_member, this.taskList[0]);
                } else {
                    this.memberSelected(null, this.taskList[0]);
                }

                this.taskLoading = false;
            },

            processTaskList(task_list) {
                let processed_task_list = _.map(task_list, (task) => ({
                    id: task.id,
                    id_reference: task.id_reference,
                    first_member: task.first_member,
                    second_member: task.second_member,
                    thread: task.thread,
                    memberEmailAddresses: task.memberEmailAddresses,
                    threadEmailAddresses: task.threadEmailAddresses,
                    app_reviews: task.app_reviews,
                    comment: task.comment,
                    attachment: task.attachment,
                    queue: { url: task.task_url, text: task.task_name, count: 1, loading: false },
                    created_at: task.created_at,
                }));

                return processed_task_list;
            },

            memberSelected(member, task) {
                this.taskMember = member;
                this.task = task;
                this.queue = task.queue;
                this.loadMember();
            },

            async loadMember(graceful = false) {
                if (!graceful) {
                    this.memberLoading = true;
                }

                if (!this.taskMember.id) {
                    this.member = null;
                    return;
                }

                try {
                    let result = await dashboardAxios.get(`/v1/members/${this.taskMember.id}`);
                    this.member = result.data.result.data;
                } catch (e) {
                    this.handleError({ error: e });
                }

                if (!graceful) {
                    this.memberLoading = false;
                }
            },

            async refreshTask() {
                let response = await dashboardAxios.get('/v1/tasks/' + this.queue.url);
                this.task = response.data.result;
                this.queue.task = response.data.result;
            },

            resolvePhotoUrl,

            getMemberRoute,
        },
    };
</script>
