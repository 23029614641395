<template>
    <div class="inline-block relative">
        <span
            class="dashboard-button focus:outline-none rounded-full py-2 px-4 whitespace-nowrap cursor-pointer inline-block"
            :class="buttonClass"
            :disabled="disabled"
            @click="buttonClick"
        >
            {{ text }}

            <icon icon="chevron-down" class="ml-1"></icon>
        </span>

        <div v-show="showDropdown" class="absolute p-2 rounded dropdown shadow bg-white z-20">
            <slot />
        </div>
    </div>
</template>

<script>
    import EventBus from '../../../helpers/eventBus';

    export default {
        name: 'DropdownButton',

        props: {
            text: {
                type: String,
                required: true,
            },

            disabled: {
                type: Boolean,
                default: false,
            },

            reverseColour: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                showDropdown: false,
            };
        },

        computed: {
            buttonClass() {
                let buttonClass = !this.reverseColour
                    ? 'bg-grey-100 hover:bg-pink-muzmatch hover:text-white'
                    : 'bg-pink-muzmatch hover:bg-pink-lighter text-white';

                let disabledClass = !this.reverseColour
                    ? 'bg-grey-100 hover:bg-pink-lighter hover:text-white cursor-not-allowed'
                    : 'bg-pink-lighter hover:bg-grey-100 text-white cursor-not-allowed';

                if (this.disabled) {
                    return disabledClass;
                }

                return buttonClass;
            },
        },

        mounted() {
            EventBus.$on('globalMouseup', this.handleMouseup);
        },

        destroyed() {
            EventBus.$off('globalMouseup', this.handleMouseup);
        },

        methods: {
            buttonClick() {
                if (this.disabled) {
                    return;
                }

                this.showDropdown = !this.showDropdown;
            },

            handleMouseup() {
                this.showDropdown = false;
            },
        },
    };
</script>

<style scoped>
    .dropdown {
        right: 0;
        top: 2rem;
    }

    .dropdown-item + .dropdown-item {
        margin-top: 0.25rem;
    }
</style>
