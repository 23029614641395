<template>
    <transition name="modal">
        <div
            class="flex justify-center items-center fixed z-50 top-0 right-0 modal-mask modal-custom-css h-screen w-screen"
            @click.self="$emit('toggleModal')"
        >
            <div
                class="flex flex-col w-custom h-custom justify-around px-5 py-5 bg-white border-grey border shadow modal-transition rounded"
            >
                <input
                    ref="search"
                    v-model="searchTerm"
                    class="border-grey border h-12 my-2 p-2"
                    placeholder=" Search shortcuts"
                />
                <div class="flex flex-row w-auto justify-between">
                    <div v-if="filteredLength" ref="overflow" class="overflow-y-scroll h-64 w-80 border border-grey">
                        <div
                            v-for="(shortcut, idx) in filteredShortcuts"
                            :key="shortcut.id"
                            class="flex justify-between items-center border-black"
                            :class="
                                isCurrentShortcut(shortcut.id, currentShortcut.id)
                                    ? 'bg-blue-lightest cursor-pointer'
                                    : ''
                            "
                            @click="selectShortcut(idx, 'click')"
                        >
                            <div
                                :id="shortcut.id"
                                ref="shortcutsRef"
                                class="flex flex-col h-16 align-start justify-center px-2 w-80"
                            >
                                <h3 class="w-64 h-auto break-words">{{ shortcut.name }}</h3>
                                <div class="flex flex-row justify-between items-end">
                                    <p class="h-auto break-words text-grey-dark">{{ shortcut.keys }}</p>
                                    <p
                                        v-if="isCurrentShortcut(shortcut.id, currentShortcut.id)"
                                        class="text-grey-dark italic text-sm"
                                    >
                                        Press 'Enter' to insert
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="h-64 w-80 text-grey-dark italic">No shortcuts found</div>
                    <div
                        v-if="currentShortcut"
                        class="w-96 h-64 overflow-y-scroll word-break border border-grey px-4 line-height-custom whitespace-pre-line"
                    >
                        {{ currentShortcut.content }}
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import { mapActions } from 'vuex';

    export default {
        props: {
            shortcuts: {
                type: Array,
                required: true,
            },
        },

        data() {
            return {
                searchTerm: '',
                index: 0,
            };
        },

        computed: {
            currentShortcut() {
                if (!this.filteredShortcuts) {
                    return;
                }
                if (this.index < 0) {
                    return;
                }
                return this.filteredShortcuts[this.index];
            },

            filteredShortcuts() {
                return this.shortcuts.filter(
                    (shortcut) =>
                        shortcut.name.toUpperCase().includes(this.searchTerm.toUpperCase()) ||
                        shortcut.keys.toUpperCase().includes(this.searchTerm.toUpperCase()),
                );
            },

            filteredLength() {
                if (this.filteredShortcuts) {
                    return this.filteredShortcuts.length;
                }

                return 0;
            },

            isFirstShortcut() {
                return this.index === 0;
            },

            isLastShortcut() {
                return this.index === this.filteredShortcuts.length - 1;
            },
        },

        watch: {
            searchTerm() {
                if (this.filteredLength) {
                    this.handleShortcutSelection(0);
                }
            },
        },

        created() {
            window.addEventListener('keydown', this.handleModalKeyPress);
        },

        mounted() {
            this.$nextTick(() => this.$refs.search.focus());
        },

        destroyed() {
            window.removeEventListener('keydown', this.handleModalKeyPress);
        },

        methods: {
            ...mapActions({
                handleError: 'handleError',
            }),

            selectShortcut(index, event) {
                if (event === 'click' && this.index === index) {
                    this.handleModalShortcutInsertion(this.currentShortcut);
                }

                this.index = index;
            },

            handleShortcutSelection(index) {
                this.selectShortcut(index);

                if (index === 0 && this.$refs.overflow) {
                    this.scrollToTop();
                    return;
                }

                if (document.getElementById(this.currentShortcut.id)) {
                    this.scrollToSelectedShortcut();
                }
            },

            scrollToSelectedShortcut() {
                let currentShortcutId = document.getElementById(this.currentShortcut.id);

                this.$nextTick(() => {
                    currentShortcutId.scrollIntoView({ behaviour: 'smooth', block: 'end', inline: 'center' });
                });
            },

            scrollToTop() {
                this.$refs.overflow.scrollTop = 0;
            },

            handleModalShortcutInsertion() {
                try {
                    this.$emit('toggleModal');
                    this.$emit('handleShortcutInsertion', this.currentShortcut, 'MODAL');
                } catch (e) {
                    this.handleError({ error: e });
                }
            },

            isCurrentShortcut(shortcutId, currentShortcutId) {
                return shortcutId === currentShortcutId;
            },

            handleModalKeyPress(event) {
                switch (event.key) {
                    case 'Escape':
                        this.handleEscapeKeyPress();
                        break;
                    case 'Enter':
                        this.handleEnterKeyPress();
                        break;
                    case 'ArrowDown':
                        this.handleArrowDownKeyPress(event);
                        break;
                    case 'ArrowUp':
                        this.handleArrowUpKeyPress(event);
                        break;
                }
            },

            handleEscapeKeyPress() {
                this.$emit('toggleModal');
            },

            handleEnterKeyPress() {
                this.handleModalShortcutInsertion();
            },

            handleArrowDownKeyPress(event) {
                event.preventDefault();

                if (this.isLastShortcut) {
                    this.handleShortcutSelection(0);
                    return;
                }

                this.handleShortcutSelection(this.index + 1);
            },

            handleArrowUpKeyPress(event) {
                event.preventDefault();

                if (this.isFirstShortcut) {
                    this.handleShortcutSelection(this.filteredShortcuts.length - 1);
                    return;
                }

                this.handleShortcutSelection(this.index - 1);
            },
        },
    };
</script>

<style scoped>
    .modal-mask {
        background-color: rgba(0, 0, 0, 0.5);
        transition: opacity 0.3s ease;
    }

    .modal-transition {
        transition: all 0.3s ease;
    }

    .modal-enter {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

    .modal-custom-css {
        padding: 20px 150px;
    }

    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 5px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #fb406c;
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }

    .line-height-custom {
        line-height: 1.2rem;
    }

    .word-break {
        word-break: break-word;
    }

    .w-custom {
        width: 48rem;
    }

    .h-custom {
        height: 24rem;
    }
</style>
