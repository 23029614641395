<template>
    <div>
        <div class="flex items-center py-4 px-8">
            <div class="w-11/12">
                <div class="flex items-center">
                    <img class="rounded-full mr-4 w-16 h-16" style="object-fit: cover" :src="profilePicture" alt="" />
                    <div>
                        <div class="text-lg text-grey-600 mb-1 flex items-center">
                            {{ issue.nickname || 'Unknown' }}
                            <router-link
                                class="no-underline text-grey-600 text-sm ml-2"
                                :to="{
                                    name: 'members',
                                    query: { memberId: issue.memberID },
                                }"
                                target="_blank"
                            >
                                <icon icon="link"></icon>
                            </router-link>
                        </div>
                        <div class="text-grey-600">
                            {{ issue.timeStamp | formatTimeStamp }}
                            <span class="ml-4">Issue: {{ issue.type }}</span>
                        </div>
                    </div>
                </div>
                <div class="pl-8 py-8">
                    {{ issue.comment }}
                </div>
                <div>
                    <button class="text-pink-muzmatch font-bold text-base pl-4" @click="translate">Translate</button>
                </div>
            </div>
            <div class="text-right">
                <img class="rounded shadow w-24" :src="attachmentUrl" alt="" @click="isVisible = true" />
                <vue-easy-lightbox
                    move-disabled
                    :visible="isVisible"
                    :imgs="attachmentUrl"
                    :index="0"
                    @hide="isVisible = false"
                />
            </div>
        </div>
        <hr class="border-b-2 max-w-2xl mx-auto" />
    </div>
</template>

<script>
    import moment from 'moment';
    import VueEasyLightbox from 'vue-easy-lightbox';
    import { resolvePhotoUrl } from '../../helpers/DashboardUtils';
    import DashboardImage from '../../components/Dashboard/DashboardImage';

    export default {
        components: {
            DashboardImage,
            VueEasyLightbox,
        },
        filters: {
            formatTimeStamp(timestamp) {
                return moment(timestamp).format('LT DD/MM/YYYY');
            },
        },
        props: {
            issue: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                googleTranslateUrl: 'https://translate.google.com/#auto/en/',
                isVisible: false,
            };
        },
        computed: {
            profilePicture() {
                return this.issue.photo1SignedUrl
                    ? this.issue.photo1SignedUrl
                    : resolvePhotoUrl(this.issue.photo1, this.issue.hashMemberID, 'portrait');
            },
            attachmentUrl() {
                return this.issue.attachmentURL.replace('s3://', 'https://');
            },
        },
        methods: {
            translate() {
                window.open(this.googleTranslateUrl + this.issue.comment);
            },
        },
    };
</script>

<style lang="scss" scoped></style>
