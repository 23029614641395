<template>
    <div class="bg-white h-screen" about="container">
        <Nav />

        <div class="px-4" :class="containerWidth">
            <slot />
        </div>

        <Alerts />
    </div>
</template>

<script>
    import Nav from './_partials/Nav';
    import Alerts from '../../components/Alerts/Alerts';

    export default {
        components: {
            Nav,
            Alerts,
        },

        computed: {
            containerWidth() {
                return this.$route.meta.fullWidth ? '' : 'sm:w-full lg:w-2/3 mx-auto';
            },
        },
    };
</script>
