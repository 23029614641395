<template>
    <div class="container">
        <div class="large-12 medium-12 small-12">
            <label
                class="block my-3 text-center dashboard-button rounded-full p-2 disabled text-white bg-grey-500 bg-pink-muzmatch hover:shadow-md focus:outline-none cursor-pointer"
            >
                Upload file
                <input id="file" ref="file" type="file" @change="onFileChange()" />
            </label>

            <div id="preview">
                <span class="text-red-dark">{{ validationError }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'ImageUpload',
        data() {
            return {
                file: '',
                imageUrl: '',
                validationError: '',
                encodings: {
                    '\+': '%2B',
                    '\!': '%21',
                    '"': '%22',
                    '\#': '%23',
                    '\$': '%24',
                    '\&': '%26',
                    "'": '%27',
                    '\(': '%28',
                    '\)': '%29',
                    '\*': '%2A',
                    '\,': '%2C',
                    '\:': '%3A',
                    '\;': '%3B',
                    '\=': '%3D',
                    '\?': '%3F',
                    '\@': '%40',
                },
            };
        },
        computed: {
            ...mapGetters({
                config: 'storage/getConfig',
            }),

            campaignCode() {
                return this.$route.params.id.split('.')[1] || null;
            },
        },
        watch: {
            imageUrl() {
                this.$emit('image-uploaded', {
                    url: this.imageUrl,
                });
            },
        },
        methods: {
            async onFileChange() {
                this.file = this.$refs.file.files[0];

                const fileSize = this.file.size / 1024 / 1024; // MiB

                let maxFileSize = 3; // MiB

                if (fileSize > maxFileSize) {
                    this.validationError = 'File size is larger than 3MB. Please select another image.';
                    return;
                } else {
                    this.validationError = '';
                }

                if (this.file) {
                    await this.$store.dispatch('storage/uploadFile', {
                        file: this.file,
                        path: this.campaignCode,
                    });
                    await this.updateUrl();
                }
            },

            async updateUrl() {
                let encodedFileName = this.encodeS3URI(this.file.name);

                this.imageUrl = `https://${this.config.Bucket}/email_campaigns/${this.campaignCode}/${encodedFileName}`;
            },

            encodeS3URI(filename) {
                let thisVar = this;

                return encodeURI(filename).replace(/(\+|!|"|#|\$|&|'|\(|\)|\*|\+|,|:|;|=|\?|@)/gim, function (match) {
                    return thisVar.encodings[match];
                });
            },
        },
    };
</script>

<style>
    input[type='file'] {
        display: none;
    }
</style>
