<template>
    <div class="rounded-t bg-white shadow-md mt-4">
        <div v-if="messages.length" class="px-4 pt-2">
            <div
                v-for="message in messages"
                :key="message.message"
                class="p-2 rounded mt-2"
                :class="staff.id === message.staff.id ? 'bg-pink-muzmatch text-white' : 'bg-grey-300'"
            >
                <div class="flex justify-between items-center">
                    <span class="font-bold">{{ message.staff.name }}</span>
                    <span class="ml-1 text-right text-pink-ligtest">{{ message.created_at | fromNow }}</span>
                </div>

                <div class="mt-2">
                    {{ message.message }}
                </div>
            </div>
        </div>

        <div class="p-4">
            <input
                v-model="issue"
                name="flag"
                type="text"
                class="w-full border p-2 rounded focus:outline-none focus:shadow-inner"
                placeholder="Issue..."
            />
        </div>

        <button
            class="w-full text-white rounded-b p-4 focus:outline-none"
            :class="loading ? 'bg-grey-500' : 'bg-grey-700'"
            @click="flag"
        >
            {{ isFlagged ? 'Unflag' : 'Flag' }}
        </button>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import { dashboardAxios, socialModerationAxios } from '../../../axios.config';

    export default {
        name: 'FlagButton',

        filters: {
            fromNow(value) {
                return moment(value).fromNow();
            },
        },

        props: {
            queue: {
                type: Object,
                required: true,
            },

            task: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                issue: '',

                messages: [],

                loading: false,
            };
        },
        computed: {
            ...mapGetters({
                staff: 'staff/getStaff',
            }),

            isFlagged() {
                if (!this.queue) {
                    return false;
                }

                return this.queue.url.includes('flagged');
            },
        },
        watch: {
            task() {
                this.loadMessages();
            },
        },
        mounted() {
            this.loadMessages();
        },
        methods: {
            ...mapActions(['alert', 'handleError']),

            async flag() {
                this.loading = true;

                try {
                    if (this.queue.social) {
                        let response = await socialModerationAxios.post(
                            '/internal/community/reports/edit/escalate-case',
                            {
                                caseId: this.task.case.caseId,
                                escalationReason: this.issue,
                            },
                        );

                        this.$emit('actioned', true);

                        return;
                    }

                    let type = this.isFlagged ? 'unflag' : 'flag';

                    let response = await dashboardAxios.post(
                        '/v1/tasks/' + this.queue.url + '/' + this.task.id + '/' + type,
                        {
                            message: this.issue,
                        },
                    );

                    this.$emit('actioned', response.data.result);
                } catch (e) {
                    if (_.get(e, 'response.status', null) === 404) {
                        this.alert({ message: 'No more tasks.', type: 'danger' });
                    } else {
                        this.handleError({ error: e });
                    }

                    this.$emit('actioned', null);
                }

                this.issue = '';
                this.loading = false;
            },

            /**
             * @returns {Promise<void>}
             */
            async loadMessages() {
                if (!this.queue) {
                    return;
                }

                if (this.queue.social) {
                    return;
                }

                if (!this.task) {
                    return;
                }

                let taskId = this.task.id;
                let taskName = this.getTaskName(this.queue);

                try {
                    let response = await dashboardAxios.get('/v1/flag-messages/' + taskId + '-' + taskName);

                    this.messages = response.data.result;
                } catch (e) {
                    this.handleError({ error: e });
                }
            },

            getTaskName(queue) {
                if (queue.url.includes('approve')) {
                    return 'approve';
                }

                if (queue.url.includes('issue')) {
                    return 'issue';
                }

                if (queue.url.includes('inappropriate-message')) {
                    return 'inappropriate-messages-report';
                }

                if (queue.url.includes('inappropriate-profile')) {
                    return 'inappropriate-profile-report';
                }

                if (queue.url.includes('other')) {
                    return 'other-report';
                }

                if (queue.url.includes('spam-scam')) {
                    return 'spam-or-scam-report';
                }

                if (queue.url.includes('email')) {
                    return 'email';
                }

                if (queue.url.includes('app-reviews')) {
                    return 'app-reviews';
                }

                if (queue.url.includes('dating-coach')) {
                    return 'app-reviews';
                }

                if (queue.url.includes('identity-verification')) {
                    return 'identity-verification';
                }

                if (queue.url.includes('pending-block-profiles')) {
                    return 'pending-block-profiles';
                }

                if (queue.url.includes('pending-block-profiles-flagged')) {
                    return 'pending-block-profile-flagged';
                }

                this.alert({ message: `I have no idea what ${queue.url} is...`, type: 'danger' });
            },
        },
    };
</script>
