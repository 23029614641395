import repositoryFactory from '../../repositoryFactory';
import qs from 'qs';

const MemberRepository = repositoryFactory.get('member');

export const requestApprove = async ({ commit, dispatch }) => {
    try {
        let response = await MemberRepository.getApprove();

        if (response.data === null) {
            return;
        }

        commit('setApprove', response.data.result);
        commit('setPendingApproveNumber', response.data.result.badges.approve);
    } catch (error) {
        dispatch('handleError', { error }, { root: true });
    }
};

export const requestSubmitApproveAnswers = async ({ dispatch, commit }, payload) => {
    let arr = {};

    arr['memberID'] = payload.memberHashId.memberID;
    arr['answers'] = {};

    payload.answers.forEach((answer, index) => {
        arr['answers'][index] = answer;
    });

    let stringifiedPayload = qs.stringify(arr, { arrayFormat: 'brackets' });

    try {
        let response = await MemberRepository.submitApproveAnswers(stringifiedPayload);

        if (response.data === null) {
            return;
        }

        commit('setApprove', response.data.result.next);
        commit('setPendingApproveNumber', response.data.result.next.badges.approve);
    } catch (error) {
        dispatch('handleError', { error }, { root: true });
    }
};

export const requestFlag = async ({ dispatch }, memberHashId) => {
    try {
        await MemberRepository.flag(memberHashId);
    } catch (error) {
        dispatch('handleError', { error }, { root: true });
    }
};
