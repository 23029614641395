<template>
    <component :is="showPanel ? 'panel' : 'div'" :has-margin="false">
        <div v-if="showPanel" slot="header" class="ml-auto flex items-center mr-4">
            <p class="cursor-pointer text-sm" @click="$emit('discard')">Cancel</p>
            <div class="mx-1">|</div>
            <p class="cursor-pointer text-sm" @click="$emit('save')">Save Changes</p>
        </div>
        <div v-if="!template" class="text-center my-4 text-gray-700">No template selected</div>
        <div :class="{ 'p-4': showPanel, 'overflow-y-scroll': showPanel, 'h-screen-80': showPanel }">
            <preview-container :rtl="rtl" :template="template" @html-event="$emit('html-event', $event)" />
        </div>
    </component>
</template>

<script>
    import Panel from '../Panels/Panel.vue';
    import PreviewContainer from './PreviewContainer.vue';

    export default {
        components: {
            Panel,
            PreviewContainer,
        },
        props: {
            template: {
                type: Object,
                default: null,
            },
            showPanel: {
                type: Boolean,
                default: true,
            },
            rtl: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>
