<template>
    <div>
        <Modal :click-overlay="() => $emit('close')" size="responsive" :no-padding="true">
            <div slot="body">
                <Panel title="Select Language" :has-margin="false" :custom-classes="['bg-pink-muzmatch', 'text-white']">
                    <div class="grid grid-cols-4">
                        <div
                            v-for="previewLanguage in previewLanguages"
                            v-if="!enabledLanguages.includes(previewLanguage.name)"
                            :key="previewLanguage.name"
                            class="flex p-4 cursor-pointer hover:bg-gray-100"
                            @click="() => $emit('add-language', previewLanguage.name)"
                        >
                            <img class="w-8 mr-1" :src="previewLanguage.path" />
                            {{ previewLanguage.title }}
                        </div>
                    </div>
                </Panel>
            </div>
        </Modal>
    </div>
</template>
<script>
    import Modal from '../../components/Modal/Modal.vue';
    import Panel from '../../components/Panels/Panel.vue';

    export default {
        components: { Modal, Panel },
        props: {
            step: {
                type: Object,
                required: true,
            },
            previewLanguages: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {};
        },
        computed: {
            enabledLanguages() {
                return Object.keys(this.step)
                    .filter((a) => a.includes('messageBody'))
                    .map((a) => a.replace('messageBody_', '').toUpperCase());
            },
        },
    };
</script>
