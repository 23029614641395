<template>
    <div>
        <modal size="responsive" :no-padding="true" :click-overlay="() => $emit('close')">
            <div slot="body">
                <panel
                    title="Send test template"
                    :has-margin="false"
                    :custom-classes="['bg-pink-muzmatch', 'text-white']"
                >
                    <div class="p-4">
                        <div>
                            <div class="font-bold">Slack & Accounts</div>
                            <div class="pb-8">
                                <multiselect
                                    v-model="selectedSlackAndAccountList"
                                    :options="slackAndAccountList"
                                    track-by="name"
                                    label="name"
                                    :multiple="true"
                                    class="mt-1 w-full"
                                    :taggable="true"
                                    @tag="addNewTag"
                                    @remove="removeTag"
                                />
                            </div>
                        </div>

                        <div class="mt-8">
                            <m2-button @clicked="send">
                                <span v-if="isSending">
                                    <icon icon="spinner" class="animate-spin text-white text-xl" />
                                </span>
                                <span v-else>Send</span>
                            </m2-button>
                        </div>
                    </div>
                </panel>
            </div>
        </modal>
    </div>
</template>

<script>
    import M2Button from '../../components/Buttons/M2Button.vue';
    import Modal from '../../components/Modal/Modal.vue';
    import Panel from '../../components/Panels/Panel.vue';
    import Multiselect from 'vue-multiselect';
    import { mapActions } from 'vuex';
    import { messengerAxios } from '../../../axios.config';

    export default {
        components: { Modal, Panel, M2Button, Multiselect },
        props: {
            selectedStep: {
                type: Object,
                required: true,
            },
            selectedLanguage: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                isSending: false,
                selectedSlackAndAccountList: [],
                slackAndAccountList: [
                    { type: 'SLACK', name: '#marketing' },
                    { type: 'ACCOUNT', name: 'marketing-test-emails@muzmatch.com' },
                ],
            };
        },
        computed: {
            messageBodyKey() {
                return `messageBody_${this.selectedLanguage.toLowerCase()}`;
            },
        },
        created() {
            this.loadCustomEmails();
        },
        methods: {
            ...mapActions(['handleError', 'alert']),
            async send() {
                let customSlackAndAccountList = JSON.parse(localStorage.getItem('customSlackAndAccountList') ?? '[]');
                customSlackAndAccountList = [
                    ...new Map(
                        [...customSlackAndAccountList, ...this.selectedSlackAndAccountList].map((item) => [
                            item['name'],
                            item,
                        ]),
                    ).values(),
                ];

                localStorage.setItem('customSlackAndAccountList', JSON.stringify(customSlackAndAccountList));

                if (!this.selectedSlackAndAccountList.length) {
                    await this.alert({
                        message: 'No Slack or Accounts was selected',
                        type: 'danger',
                    });
                    return;
                }

                this.isSending = true;

                try {
                    await messengerAxios.post(`/campaign/send-test`, {
                        messageType: this.selectedStep.messageType,
                        messageBody: this.selectedStep[this.messageBodyKey],
                        language: this.selectedLanguage,
                        slackAndAccountList: this.selectedSlackAndAccountList,
                        urlTargetID: this.selectedStep.urlTargetID,
                    });
                    await this.alert({
                        message: 'The test was sent successfully',
                        type: 'success',
                    });
                } catch (error) {
                    await this.alert({
                        message: 'There was a problem sending some or all of the tests, make sure that account exists',
                        type: 'danger',
                        time: 5000,
                    });
                }

                this.isSending = false;

                this.$emit('close');
            },
            async addNewTag(tag) {
                // if (tag.includes('#')) {
                //     this.selectedSlackAndAccountList.push({ type:'SLACK', name: tag })
                // } else

                // Only let them add emails for now
                if (tag.includes('@')) {
                    const account = { type: 'ACCOUNT', name: tag };
                    const isDuplicate = this.selectedSlackAndAccountList.some((item) => item?.name === account.name);

                    if (isDuplicate) return;

                    this.selectedSlackAndAccountList.push(account);

                    const customSelectedSlackAndAccountList = JSON.stringify(this.selectedSlackAndAccountList);
                    sessionStorage.setItem('customSelectedSlackAndAccountList', customSelectedSlackAndAccountList);
                } else {
                    await this.alert({
                        message: 'Not a valid email address',
                        type: 'danger',
                    });
                }
            },
            removeTag(tag) {
                const selectedSlackAndAccountList = this.selectedSlackAndAccountList.filter(
                    (item) => item.name !== tag.name,
                );
                const customSelectedSlackAndAccountList = JSON.stringify(selectedSlackAndAccountList);

                sessionStorage.setItem('customSelectedSlackAndAccountList', customSelectedSlackAndAccountList);
            },
            loadCustomEmails() {
                const customSlackAndAccountList = JSON.parse(localStorage.getItem('customSlackAndAccountList') ?? '[]');
                const customSelectedSlackAndAccountList = JSON.parse(
                    sessionStorage.getItem('customSelectedSlackAndAccountList') ?? '[]',
                );

                if (customSlackAndAccountList.length)
                    this.slackAndAccountList = this.slackAndAccountList.concat(customSlackAndAccountList);

                if (customSelectedSlackAndAccountList.length)
                    this.selectedSlackAndAccountList = this.selectedSlackAndAccountList.concat(
                        customSelectedSlackAndAccountList,
                    );
            },
        },
    };
</script>
