import _ from 'lodash';

export class User {
    constructor(staff) {
        this.staff = staff;
        this.roles = staff ? staff.roles : null;
    }

    /**
     * @returns {boolean}
     */
    can(permissions) {
        if (!Array.isArray(permissions)) {
            permissions = [permissions];
        }

        return _.some(permissions, (permission) => this.canSingle(permission));
    }

    /**
     * @param permission
     *
     * @returns {boolean}
     */
    canSingle(permission) {
        if (!this.roles) {
            return false;
        }

        permission = permission.split(':');

        if (permission.length !== 1 && permission.length !== 2) {
            throw `Malformed Permission`;
        }

        let roles = _.filter(this.roles, { productName: permission[0] });

        if (permission.length === 1) {
            return roles.length >= 1;
        }

        return _.some(roles, (role) => {
            return role.roleName === permission[1];
        });
    }

    isFullstack() {
        return this.staff.wali_department_id === 2;
    }

    /**
     * @param user
     *
     * @returns {User}
     */
    static make(user) {
        return new User(user);
    }
}
