<template>
    <Panel v-if="shortcut" title="Create Shortcut">
        <div class="p-4">
            <div class="flex flex-row -mx-2">
                <div class="w-1/2 mx-2">
                    <label>Name</label>

                    <input
                        v-model="shortcut.name"
                        type="text"
                        class="p-2 bg-white border border-grey-400 rounded w-full mt-1"
                    />
                </div>

                <div class="w-1/2 mx-2">
                    <label>Shortcut</label>

                    <input
                        v-model="shortcut.keys"
                        type="text"
                        class="p-2 bg-white border border-grey-400 rounded w-full mt-1"
                    />
                </div>
            </div>

            <div class="mt-4">
                <label>Content</label>

                <textarea
                    v-model="shortcut.content"
                    class="p-2 bg-white border border-grey-400 rounded w-full mt-1 h-32"
                />
            </div>

            <div class="w-1/2 mt-4">
                <label>Type</label>
                <v-select v-model="shortcut.type" class="mt-1 style-chooser" taggable :options="types" />
            </div>

            <div class="flex justify-end mt-4">
                <button
                    class="py-2 px-4 text-grey-800 rounded focus:outline-none bg-grey-100 mr-2"
                    @click="$router.push('/shortcuts')"
                >
                    Cancel
                </button>

                <button
                    class="py-2 px-4 text-white rounded focus:outline-none"
                    :class="loading ? 'bg-pink-lighter' : 'bg-pink-muzmatch'"
                    @click="saveShortcut()"
                >
                    Save
                </button>
            </div>
        </div>
    </Panel>
</template>

<script>
    import Panel from '../../components/Panels/Panel';
    import { dashboardAxios } from '../../../axios.config';
    import { mapActions } from 'vuex';
    import vSelect from 'vue-select';
    import 'vue-select/dist/vue-select.css';

    export default {
        name: 'Show',

        components: {
            Panel,
            vSelect,
        },

        data() {
            return {
                loading: false,

                shortcut: {
                    name: '',
                    keys: '',
                    content: '',
                    type: 'OTHER',
                },

                types: [],
            };
        },
        watch: {
            'shortcut.type'() {
                if (this.shortcut.type) {
                    this.shortcut.type = this.shortcut.type.toUpperCase();
                } else {
                    this.shortcut.type = '';
                }
            },
        },
        created() {
            this.getTypes();
        },
        methods: {
            ...mapActions(['alert', 'handleError']),

            async saveShortcut() {
                if (this.loading) {
                    return;
                }

                if (!(this.shortcut.name || this.shortcut.keys || this.shortcut.content || this.shortcut.type)) {
                    this.alert({ message: 'Name, shortcut, content and type is required.', type: 'danger' });

                    return;
                }

                this.loading = true;

                try {
                    let response = await dashboardAxios.post('/v1/shortcuts', this.shortcut);

                    let shortcut = response.data.result;

                    this.alert({ message: 'Shortcut saved.', type: 'success' });

                    this.loading = false;

                    await this.$router.push('/shortcuts/' + shortcut.name + '.' + shortcut.id);
                } catch (e) {
                    this.handleError({
                        error: e,
                        callback: () => (this.loading = false),
                    });
                }
            },

            async getTypes() {
                try {
                    let response = await dashboardAxios.get('/v1/shortcuts-type');

                    this.types = response.data.result;
                } catch (e) {
                    this.handleError({
                        error: e,
                        callback: () => (this.loading = false),
                    });
                }
            },
        },
    };
</script>

<style>
    .style-chooser .vs__search,
    .style-chooser .vs__dropdown-toggle,
    .style-chooser .vs__dropdown-menu {
        text-transform: uppercase;
    }
</style>
