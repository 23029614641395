<template>
    <div class="inline-flex flex-col justify-center items-center mt-4 mx-4 cursor-pointer" @click="download">
        <span class="text-5xl text-pink-muzmatch">
            <icon :icon="iconType(attachment.type)" class="mx-1"></icon>
        </span>
        <div class="whitespace-pre-wrap text-center">{{ trimContent(attachment.fileName) }}</div>
    </div>
</template>

<script>
    import { dashboardAxios } from '../../../axios.config';

    export default {
        name: 'AttachmentNonMedia',

        props: {
            attachment: {
                type: Object,
                required: true,
            },
            threadId: {
                type: Number,
                required: true,
            },
        },

        data() {
            return {
                iconTypes: {
                    application: 'file-code',
                    audio: 'file-audio',
                    text: 'file-alt',
                },
                dangerousTypes: ['pdf'],
                dangerous: false,
            };
        },

        methods: {
            iconType: function (type) {
                let fileType = Object.keys(this.iconTypes).find((iconType) => type.includes(iconType));
                return fileType ? this.iconTypes[fileType] : 'file';
            },

            download: async function () {
                this.dangerousTypes.forEach((dangerousType) => {
                    if (this.attachment.type.includes(dangerousType)) {
                        this.dangerous = true;
                    }
                });

                if (this.dangerous) {
                    let response = await this.$swal({
                        title: 'Are you sure?',
                        icon: 'warning',
                        text: `The file ${this.attachment.fileName} is potentially dangerous.`,
                        showCancelButton: true,
                        confirmButtonText: 'Continue',
                    });

                    if (!response.value) {
                        return;
                    }
                }

                let body = {
                    gEmailId: this.attachment.gEmailId,
                    gAttachmentId: this.attachment.gAttachmentId,
                    fileName: this.attachment.fileName,
                    type: this.attachment.type,
                };

                await dashboardAxios
                    .post('/v1/tasks/email/attachment/' + this.threadId, body, { responseType: 'blob' })
                    .then((response) => {
                        let blob = new Blob([response.data]);
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', this.attachment.fileName);
                        document.body.appendChild(link);
                        link.click();

                        // Cleanup
                        window.URL.revokeObjectURL(blob);
                        document.body.removeChild(link);
                    });
            },

            trimContent: function (content) {
                return content.length <= 15
                    ? content
                    : content.split('.')[0].split('').splice(0, 14).join('').trim() + '...\n.' + content.split('.')[1];
            },
        },
    };
</script>

<style scoped>
    .object-contain {
        object-fit: contain;
    }
</style>
