<template>
    <modal size="xl" :no-padding="true" :click-overlay="closeModal">
        <div slot="body">
            <master-view
                :campaign="campaign"
                @save="save"
                @html-event="$emit('html-event', $event)"
                @discard="closeModal"
            />
        </div>
    </modal>
</template>

<script>
    import { mapActions } from 'vuex';
    import MasterView from '../../components/EmailBuilder/MasterView.vue';
    import Modal from '../../components/Modal/Modal.vue';

    export default {
        components: {
            Modal,
            MasterView,
        },
        props: {
            campaign: {
                type: Object,
                required: true,
            },
        },
        methods: {
            ...mapActions(['confirmDelete']),
            async closeModal() {
                if (
                    !(await this.confirmDelete({
                        vm: this,
                        text: 'You have unsaved changes, are you sure you want to discard them?',
                    }))
                ) {
                    return;
                }

                this.$emit('close');
            },
            save(campaign) {
                this.$emit('save', campaign);
                this.$emit('close');
            },
        },
    };
</script>
