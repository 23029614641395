<template>
    <div class="p-4">
        <div class="flex flex-wrap flex-col md:flex-row">
            <span v-if="profile.social" class="mr-1 mb-1">
                SocialID:
                <span class="font-bold text-grey-700">{{ profile.social.profileId }}</span>
            </span>
            <span v-if="profile.social" class="mr-1 mb-1 hidden md:block">∙</span>
            <span class="mr-1 mb-1">
                MarriageID:
                <span class="font-bold text-grey-700">{{ profile.memberID }}</span>
            </span>
            <span class="mr-1 mb-1 hidden md:block">∙</span>
            <span class="mr-1 mb-1">
                AccountID:
                <span class="font-bold text-grey-700">{{ profile.accountID }}</span>
            </span>
        </div>
        <div class="mt-2 flex flex-wrap flex-col md:flex-row">
            <span class="mr-1 mb-1">
                Signed up
                <span class="font-bold text-grey-700">{{ profile.timeStampRegistered | fromNow }}</span>
            </span>
            <span class="mr-1 mb-1 hidden md:block">∙</span>
            <span>
                Last active
                <span class="font-bold text-grey-700">{{ profile.lastActiveTimeStamp | fromNow }}</span>
            </span>
        </div>

        <div class="mt-2 flex flex-wrap flex-col md:flex-row">
            <span class="mr-1 mb-1">
                Mode:
                <span class="font-bold text-grey-700">{{ accountMode }}</span>
            </span>
            <span class="mr-1 mb-1 hidden md:block">∙</span>
            <span class="mr-1 mb-1">
                Photos:
                <span class="font-bold text-grey-700">{{ profile.publicPhotos === '1' ? 'public' : 'private' }}</span>
            </span>
            <span class="mr-1 mb-1 hidden md:block">∙</span>
            <span>{{ profile.deviceOS }}(v.{{ profile.appVersion }})</span>
        </div>

        <div class="mt-2 flex flex-wrap flex-col md:flex-row">
            <span class="mr-1 mb-1">
                Email:
                <span class="font-bold text-grey-700">{{ profile.emailAddress }}</span>
            </span>
            <span class="mr-1 mb-1 hidden md:block">∙</span>
            <span class="mr-1 mb-1">
                Phone Number:
                <span class="font-bold text-grey-700 mr-1 mb-1">+{{ profile.phoneNumber }}</span>
                <span
                    class="rounded-full w-5 h-5 text-xs inline-flex flex justify-center items-center"
                    :class="phoneStatusClass"
                >
                    <icon :icon="phoneStatusIcon"></icon>
                </span>
            </span>
        </div>

        <div v-if="showGold" class="mt-2 flex flex-wrap flex-wrap flex-col md:flex-row font-bold">
            <span class="mr-1 mb-1 text-gold">
                Gold:
                <span class="text-grey-700">
                    {{ profile.premiumStarted | date }} → {{ profile.premiumExpires | date }}
                </span>
            </span>
            <span class="mr-1 mb-1 hidden md:block">∙</span>
            <span class="mr-1 mb-1 text-gold">
                Status:
                <span
                    :class="{
                        'text-green': ['ACTIVE', 'CANCELLED'].includes(profile.subscriptionStatus),
                        'text-orange': ['GRACE_PERIOD', 'BILLING_RETRY'].includes(profile.subscriptionStatus),
                        'text-red': ['EXPIRED', 'UNSPECIFIED'].includes(profile.subscriptionStatus),
                        'text-grey-700': !profile.subscriptionStatus,
                    }"
                >
                    {{ profile.subscriptionStatus || 'N/A' }}
                </span>
            </span>
            <span class="mr-1 mb-1 hidden md:block">∙</span>
            <span class="mr-1 mb-1 text-gold">
                Product:
                <span class="text-grey-700">{{ profile.productID || 'N/A' }}</span>
            </span>
            <span class="mr-1 mb-1 hidden md:block">∙</span>
            <span class="mr-1 mb-1 text-gold">
                {{ expiresText }}:
                <span class="text-grey-700">{{ profile.premiumExpires | fromNow }}</span>
            </span>
        </div>

        <div class="mt-2 flex flex-wrap flex-col md:flex-row">
            <span class="mr-1 mb-1">
                Ethnic origin:
                <span class="font-bold text-grey-700">{{ profile.ethnicOriginCountryName }}</span>
            </span>
            <span class="mr-1 mb-1 hidden md:block">∙</span>
            <span class="mr-1 mb-1">
                Ethnic grouping:
                <span class="font-bold text-grey-700">{{ profile.ethnicGroupingName }}</span>
            </span>
        </div>
        <!--
        <div v-if="!isLoadingFilters" class="mt-2 flex flex-wrap flex-col md:flex-row">
            <span class="mr-1 mb-1">
                Filters:
                <span class="font-bold text-grey-700">
                    <span v-if="filtersNoLocation && filters">
                        Currently applied
                        <icon class="text-green" icon="check" />

                        <span v-if="!gpsEnabled" class="ml-2">(Distance disabled - No GPS)</span>
                    </span>
                    <span v-else>
                        Not applied (No Filters Selected)
                        <icon class="text-red" icon="times" />
                    </span>
                </span>
            </span>
        </div>
    -->

        <div class="mt-2 flex flex-wrap flex-col md:flex-row">
            <span class="mr-1 mb-1">
                Language:
                <span class="font-bold text-grey-700">
                    <span>{{ getCountry(profile.locale) }}</span>
                </span>
            </span>
            <span class="mr-1 mb-1 hidden md:block">∙</span>
            <span class="mr-1 mb-1">
                Discoverable:
                <span class="font-bold text-grey-700">
                    <span v-if="profile.profileVisibility === 'PUBLIC'">
                        <icon class="text-green" icon="check" />
                    </span>
                    <span v-else-if="profile.profileVisibility === 'INVISIBLE_MODE'">
                        <icon class="text-purple-muzmatch" icon="eye-slash" />
                    </span>
                    <span v-else>
                        <icon class="text-red" icon="times" />
                    </span>
                </span>
            </span>
        </div>

        <div v-if="profile.verifiedStatus !== 'approved'" class="mt-2 flex flex-wrap flex-col md:flex-row">
            <span class="mr-1 mb-1">
                Identity verification:
                <span class="font-bold text-grey-700">
                    <template v-if="profile.verifiedStatus === 'notSubmitted'">Not submitted</template>
                    <template v-else-if="profile.verifiedStatus === 'resubmit'">Resubmit state</template>
                    <template v-else>Awaiting approval</template>
                </span>
            </span>
        </div>
    </div>
</template>

<script>
    import { compare } from 'compare-versions';
    import moment from 'moment';
    import { dashboardAxios } from '../../../axios.config';

    export default {
        name: 'DashboardSummaryLabels',

        filters: {
            fromNow(value) {
                if (!value) {
                    return 'N/A';
                }

                return moment(value).fromNow();
            },

            date(value) {
                if (!value) {
                    return 'N/A';
                }

                return moment(value).format('DD/MM/YYYY');
            },
        },

        props: {
            profile: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                isLoadingFilters: true,
                filters: false,
            };
        },

        computed: {
            phoneStatusClass() {
                return this.profile.phoneNumberConfirmed === '1'
                    ? 'bg-green-lightest text-green-dark'
                    : 'bg-red-lightest text-red-dark';
            },

            phoneStatusIcon() {
                return this.profile.phoneNumberConfirmed === '1' ? 'check' : 'times';
            },

            showGold() {
                return this.profile.premiumStarted || this.profile.premiumExpires || this.profile.productID;
            },

            expiresText() {
                if (!this.profile.premiumExpires) {
                    return 'Expires';
                }

                let date = moment(this.profile.premiumExpires);

                return date.isBefore(moment()) ? 'Expired' : 'Expires';
            },

            accountMode() {
                if (this.profile.mode === 'marriage') {
                    return 'Marriage Only';
                }

                if (this.profile.mode === 'social') {
                    return 'Social Only';
                }

                return 'Marriage & Social';
            },

            gpsEnabled() {
                return this.profile.GPSLatitude && this.profile.GPSLongitude;
            },

            filtersNoLocation() {
                return this.profile.deviceOS.toLowerCase() === 'android'
                    ? compare('6.53.0', this.profile.appVersion.replace('a', ''), '<')
                    : compare('6.6.13', this.profile.appVersion, '<');
            },
        },

        created() {
            this.getFilters();
        },

        methods: {
            async getFilters() {
                try {
                    let response = await dashboardAxios.get(
                        '/v1/members/' + this.profile.hashMemberID + '/search-filters',
                    );
                    this.filters = response.data.result;
                } catch {
                    this.filters = false;
                }

                this.isLoadingFilters = false;
            },
            getCountry(key) {
                let countries = {
                    ENGB: 'English',
                    EN: 'English',
                    DE: 'German',
                    FR: 'French',
                    ID: 'Indonesian',
                    TR: 'Turkish',
                    NL: 'Dutch',
                    MS: 'Malaysian',
                    ES: 'Spanish',
                    AR: 'Arabic',
                    HI: 'Hindi',
                    FA: 'Persian',
                    UR: 'Urdu',
                    BN: 'Bengali',
                    RU: 'Russian',
                    IT: 'Italian',
                };
                if (!(key in countries)) {
                    return key;
                }
                return countries[key];
            },
        },
    };
</script>

<style scoped>
    .text-gold {
        color: #fb911a;
    }
</style>
