<template>
    <Modal :click-overlay="toggleModal">
        <template #header>
            <div class="hidden md:block">
                <IconButton rounded class="float-right right-0 top-0 absolute mt-2 mr-2" @click="toggleModal">
                    Close
                </IconButton>
            </div>
        </template>

        <template #body>
            <div class="flex w-full max-h-90">
                <div class="w-1/2 m-3 max-h-full text-center">
                    <DashboardLabel class="mb-5 block">{{ photos[currentImgIndex].label }}</DashboardLabel>

                    <img
                        v-if="photos[currentImgIndex].is === 'image'"
                        class="profile-photo rounded-lg m-auto h-full block aspect-ratio"
                        :src="
                            photos[currentImgIndex].signedUrl
                                ? photos[currentImgIndex].signedUrl
                                : photos[currentImgIndex].type === 'photoVerification'
                                ? resolveVerificationPhotoUrl(photos[currentImgIndex].key, hashMemberId, 'portrait')
                                : resolvePhotoUrl(photos[currentImgIndex].key, hashMemberId, 'portrait')
                        "
                        @error="$event.target.src = require('../../assets/mm2.png')"
                    />
                    <video
                        v-else-if="photos[currentImgIndex].key"
                        id="profile_video"
                        controls
                        class="focus:outline-none m-auto h-full block aspect-ratio"
                    >
                        <source
                            :src="
                                photos[currentImgIndex].signedUrl
                                    ? photos[currentImgIndex].signedUrl
                                    : resolveVideoUrl(photos[currentImgIndex].key, hashMemberId)
                            "
                        />
                    </video>
                    <img
                        v-else
                        class="profile-photo rounded-lg m-auto h-full block aspect-ratio"
                        :src="resolvePhotoUrl(null, null, 'portrait', false)"
                        @error="$event.target.src = require('../../assets/mm2.png')"
                    />

                    <div class="w-full text-center my-5">
                        <icon
                            icon="chevron-left"
                            :class="isFirstImage ? 'text-grey-light cursor-not-allowed' : 'text-pink-muzmatch'"
                            class="cursor-pointer ml-1 text-3xl"
                            @click="getPreviousImage"
                        />
                    </div>
                </div>

                <div class="w-1/2 m-3 max-h-full text-center">
                    <DashboardLabel class="mb-5 block">{{ photos[nextImgIndex].label }}</DashboardLabel>

                    <img
                        v-if="photos[nextImgIndex].is === 'image'"
                        class="profile-photo rounded-lg h-full block m-auto aspect-ratio"
                        :src="
                            photos[nextImgIndex].signedUrl
                                ? photos[nextImgIndex].signedUrl
                                : photos[nextImgIndex].type === 'photoVerification'
                                ? resolveVerificationPhotoUrl(photos[nextImgIndex].key, hashMemberId, 'portrait')
                                : resolvePhotoUrl(photos[nextImgIndex].key, hashMemberId, 'portrait')
                        "
                        @error="$event.target.src = require('../../assets/mm2.png')"
                    />
                    <video
                        v-else-if="photos[nextImgIndex].key"
                        controls
                        class="focus:outline-none m-auto h-full block aspect-ratio"
                    >
                        <source
                            :src="
                                photos[nextImgIndex].signedUrl
                                    ? photos[nextImgIndex].signedUrl
                                    : resolveVideoUrl(photos[nextImgIndex].key, hashMemberId)
                            "
                        />
                    </video>
                    <img
                        v-else
                        class="profile-photo rounded-lg m-auto h-full block aspect-ratio"
                        :src="resolvePhotoUrl(null, null, 'portrait', false)"
                        @error="$event.target.src = require('../../assets/mm2.png')"
                    />

                    <div class="w-full text-center my-5">
                        <icon
                            icon="chevron-right"
                            :class="isLastImage ? 'text-grey-light cursor-not-allowed' : 'text-pink-muzmatch'"
                            class="cursor-pointer ml-1 text-3xl"
                            @click="getNextImage"
                        />
                    </div>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script>
    import Modal from '../Modal/Modal';
    import { resolvePhotoUrl, resolveVerificationPhotoUrl, resolveVideoUrl } from '../../helpers/DashboardUtils';
    import EventBus from '../../helpers/eventBus';

    export default {
        name: 'ProfilePhotoModal',

        components: {
            Modal,
        },

        props: {
            photos: {
                type: Array,
                required: true,
            },
            currentImgIndex: {
                type: Number,
                required: true,
            },
            nextImgIndex: {
                type: Number,
                required: true,
            },
            hashMemberId: {
                type: String,
                required: true,
            },
        },

        computed: {
            isFirstImage() {
                return this.currentImgIndex === 0;
            },
            isLastImage() {
                return this.nextImgIndex === this.photos.length - 1;
            },
        },

        mounted() {
            EventBus.$on('globalKeyup', this.handleKeyup);
        },

        destroyed() {
            EventBus.$off('globalKeyup', this.handleKeyup);
        },

        methods: {
            resolvePhotoUrl: resolvePhotoUrl,
            resolveVerificationPhotoUrl: resolveVerificationPhotoUrl,
            resolveVideoUrl: resolveVideoUrl,

            toggleModal() {
                this.$emit('toggleModal');
            },

            getPreviousImage() {
                if (this.isFirstImage) {
                    return;
                }
                this.$emit('decrementIndex');
            },

            getNextImage() {
                if (this.isLastImage) {
                    return;
                }
                this.$emit('incrementIndex');
            },

            handleKeyup(event) {
                if (event.key === 'Escape') {
                    this.toggleModal();
                }

                if (event.key === 'ArrowRight') {
                    this.getNextImage();

                    return;
                }

                if (event.key === 'ArrowLeft') {
                    this.getPreviousImage();
                }
            },
        },
    };
</script>

<style scoped>
    .max-h-90 {
        max-height: 90%;
    }

    .profile-photo {
        object-fit: contain;
    }

    .aspect-ratio {
        width: calc((90vh - 20px) / 2);
        height: calc((90vh - 20px) * 1.33 / 2);
    }
</style>
