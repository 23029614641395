<template>
    <div class="flex flex-col" :class="mShowDetails ? 'w-32' : ''">
        <div class="w-full flex justify-center">
            <div v-if="type === 'Unknown'" class="flag flex justify-center items-center">
                <icon icon="question"></icon>
            </div>

            <img v-else :src="path" class="flag" :title="type + ' ' + name" />
        </div>

        <div v-show="mShowDetails" class="flex justify-center flex-col text-center details mt-1">
            <p class="text-grey-700 text-sm uppercase">{{ name }}</p>
            <p>{{ type }}</p>
            <p v-if="location">({{ location }})</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'DashboardFlag',

        props: {
            path: {
                type: String,
                required: true,
            },

            name: {
                type: String,
                required: true,
            },

            type: {
                type: String,
                required: true,
            },

            showDetails: {
                type: Boolean,
                required: true,
            },

            location: {
                type: String,
                default: '',
            },
        },

        data() {
            return {
                mShowDetails: false,
            };
        },

        watch: {
            showDetails(value) {
                if (!value) {
                    this.mShowDetails = this.showDetails;

                    return;
                }

                setTimeout(() => {
                    this.mShowDetails = this.showDetails;
                }, 100);
            },
        },
    };
</script>

<style scoped>
    .flag {
        width: 38px;
        height: 25px;
    }

    .details {
        transition: visibility;
        transition-duration: 100ms;
    }
</style>
