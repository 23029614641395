<template>
    <div>
        <p class="pl-1 pb-1 font-bold">Reply to:</p>
        <multiselect
            v-model="replyEmailAddresses"
            class="mb-2 address__dropdown"
            :options="dropdownOptions"
            track-by="emailAddress"
            multiple
            taggable
            @tag="addTag"
        >
            <template slot="tag" slot-scope="{ option }">
                <span
                    class="custom__tag bg-pink-muzmatch rounded inline-block m-1 px-2 py-1"
                    :class="{
                        'bg-pink-muzmatch': isFirstEmailAddressInThread(option.emailAddress),
                        'bg-purple-muzmatch': !isFirstEmailAddressInThread(option.emailAddress),
                    }"
                >
                    <span class="text-white">
                        {{ option.emailAddress }}
                        <icon v-show="isEmailAddressVerified(option.emailAddress)" icon="check-circle" />
                    </span>
                </span>
            </template>
            <template slot="option" slot-scope="props">
                <div class="option__desc">
                    <span class="option__title">
                        {{ props.option.emailAddress }}
                        <icon v-show="isEmailAddressVerified(props.option.emailAddress)" icon="check-circle" />
                    </span>
                </div>
            </template>
        </multiselect>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect';
    import { validateEmail } from '../../helpers/DashboardUtils';

    export default {
        components: {
            Multiselect,
        },
        props: {
            memberEmailAddresses: {
                type: Array,
                default: null,
            },
            threadEmailAddresses: {
                type: Array,
                required: true,
            },
            emailAddressesSelected: {
                type: Array,
                required: true,
            },
            dropdownOptions: {
                type: Array,
                required: true,
            },
        },
        computed: {
            replyEmailAddresses: {
                get() {
                    return this.emailAddressesSelected;
                },
                set(val) {
                    this.$emit('email-address-changed', val);
                },
            },
            messageColour() {
                if (this.isEmailVerified) {
                    return 'bg-pink-muzmatch text-white';
                }

                return 'bg-purple-muzmatch text-white';
            },
            firstEmailAddressInThread() {
                return this.threadEmailAddresses[0];
            },
            isSingleReplyEmailAddress() {
                return this.dropdownOptions.length === 1;
            },
        },
        methods: {
            isEmailAddressVerified(emailAddress) {
                if (!this.memberEmailAddresses) return false;

                return this.memberEmailAddresses.some(
                    (memberEmailAddress) => memberEmailAddress.email_address === emailAddress,
                );
            },
            isFirstEmailAddressInThread(emailAddress) {
                return emailAddress === this.firstEmailAddressInThread;
            },
            addTag(email) {
                if (!validateEmail(email)) {
                    this.$swal.fire({
                        icon: 'error',
                        toast: true,
                        showConfirmButton: false,
                        text: 'Email invalid',
                        timer: 3000,
                    });

                    return;
                }

                this.$emit('add-email', email);
            },
        },
    };
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
    .address__dropdown > .multiselect__tags {
        padding: 8px 40px 8px 8px;
    }

    .address__dropdown > .multiselect__input {
        padding-top: 8px;
    }
    /* .multiselect__content .multiselect__option--highlight:first-child::after  */
    .address__dropdown > .multiselect__element:first-child .multiselect__option {
        color: #fb406c;
    }

    .address__dropdown > .multiselect__element .multiselect__option {
        color: #65355f;
    }

    .address__dropdown > .multiselect__element:first-child .multiselect__option--highlight,
    .address__dropdown > .multiselect__element:first-child .multiselect__option--highlight::after {
        background: #fb406c;
        color: #fff;
    }

    .address__dropdown > .multiselect__element .multiselect__option--highlight,
    .address__dropdown > .multiselect__element .multiselect__option--highlight::after {
        background: #65355f;
        color: #fff;
    }

    /* .multiselect__option--selected.multiselect__option--highlight:first-child,
.multiselect__option--selected.multiselect__option--highlight::after:first-child {
    background: #fb406c;
} */
</style>
