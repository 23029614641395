<template>
    <Panel title="Related Threads" class="mt-3 pb-1">
        <Modal v-if="isModal" :click-overlay="() => (isModal = false)" size="responsive">
            <template #body>
                <EmailPanel
                    v-if="thread"
                    class="w-full h-full"
                    :thread="thread"
                    context-type="CONTEXT_TYPE_TASK_EMAIL"
                    context-action="CONTEXT_ACTION_RELATED_EMAIL_PANEL"
                    :context-detail="'CONTEXT_DETAIL_THREAD_' + thread.id"
                    :show-reply="false"
                    :show-refresh-icon="false"
                ></EmailPanel>
            </template>
        </Modal>
        <div class="mx-2">
            <EmailAddressSelector
                v-for="emailAddress in task.relatedThreads"
                :key="emailAddress.emailAddress"
                :email-address="emailAddress"
                :selected-email-address="selectedEmailAddress"
                @thread-selected="setCurrentThread"
                @email-address-selected="setSelectedEmailAddress"
            />
        </div>
    </Panel>
</template>

<script>
    import Modal from '../../components/Modal/Modal';
    import Panel from '../../components/Panels/Panel';
    import EmailPanel from '../../components/Panels/EmailPanel';
    import EmailAddressSelector from '../../components/Dashboard/Tabs/EmailTab/EmailAddressSelector';

    export default {
        components: {
            Modal,
            Panel,
            EmailPanel,
            EmailAddressSelector,
        },
        props: {
            task: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                selectedEmailAddress: null,
                isModal: false,
                thread: null,
                attachments: [],
            };
        },
        computed: {
            isFirst() {
                return this.data;
            },
        },
        methods: {
            setCurrentThread(thread) {
                this.thread = thread;
                this.isModal = true;
            },
            setSelectedEmailAddress(emailAddress) {
                this.selectedEmailAddress = emailAddress;
            },
            parse(body) {
                return body.length > 0 ? body : '*No email content found.*';
            },
        },
    };
</script>
