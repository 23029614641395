<template>
    <modal :click-overlay="() => $emit('hide')" size="xl" :no-padding="true">
        <div slot="body">
            <panel title="Staff Logs" :has-margin="false">
                <div class="overflow-auto" style="max-height: 42rem">
                    <table v-if="content" class="sm:w-full text-left table-fixed overflow-x-scroll">
                        <thead>
                            <tr class="bg-grey-100 text-grey-700 uppercase">
                                <th class="px-4 py-2 w-1/6">Created</th>
                                <th class="px-4 py-2 w-4/6">Code</th>
                                <th class="px-4 py-2 w-1/6">Staff</th>
                                <th class="px-4 py-2 w-1/2">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="row in content" :key="row.id" class="border hover:bg-grey-200">
                                <td class="w-2/6 px-4 py-2 break-words">
                                    {{ row.timeStamp | time }}
                                </td>
                                <td class="px-4 py-2 break-words">
                                    {{ row.eventCode }}
                                </td>
                                <td class="px-4 py-2 break-words">{{ row.name }} ({{ row.staffID }})</td>
                                <td class="px-4 py-2 break-words">
                                    {{ row.action }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div v-else-if="!content && isLoading" class="flex items-center justify-center my-4">
                        <icon icon="list" class="loading-animation text-4xl mb-2 text-pink-muzmatch mr-4"></icon>
                        <span class="text-lg mt-2 font-bold">Loading...</span>
                    </div>
                    <div v-else class="flex items-center justify-center my-4">No logs found</div>
                </div>
            </panel>
        </div>
    </modal>
</template>

<script>
    import { dashboardAxios } from '../../../axios.config';
    import Modal from './Modal';
    import Panel from '../Panels/Panel';

    export default {
        components: {
            Modal,
            Panel,
        },
        filters: {
            time(timestamp) {
                return moment(timestamp).format('DD/MM/YYYY HH:mm:ss');
            },
        },
        props: {
            threadId: {
                type: Number,
                required: true,
            },
        },
        data() {
            return {
                isLoading: false,
                content: null,
            };
        },
        async created() {
            this.isLoading = true;

            try {
                const resp = await dashboardAxios.get(`/v1/members/threads/${this.threadId}/logs`);
                this.content = resp.data.result.length === 0 ? null : resp.data.result;
            } catch (error) {
                this.content = null;
            }

            this.isLoading = false;
        },
    };
</script>
