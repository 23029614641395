<template>
    <label class="switch">
        <input type="checkbox" :class="{ 'slider-on': selected }" @click="toggled" />
        <span class="slider round" :class="isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'"></span>
    </label>
</template>

<script>
    import { mapActions } from 'vuex';

    export default {
        name: 'ToggleButton',
        props: {
            isToggled: {
                type: Boolean,
                required: true,
            },
            requireConfirmation: {
                type: Boolean,
                default: false,
            },
            confirmationText: {
                type: String,
                default: '',
            },
            isDisabled: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                selected: false,
            };
        },

        mounted() {
            this.selected = this.isToggled;
        },

        methods: {
            ...mapActions(['confirmDelete']),

            async toggled() {
                if (this.isDisabled) {
                    this.$emit('clicked-disabled');
                    return;
                }

                if (this.requireConfirmation) {
                    if (!(await this.confirmDelete({ vm: this, text: this.confirmationText }))) return;
                }

                this.selected = !this.selected;
                this.$emit('clicked', this.selected);
            },
        },
    };
</script>

<style scoped>
    /* The switch - the box around the slider */
    .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
    }

    /* Hide default HTML checkbox */
    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* The slider */
    .slider {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    .slider:before {
        position: absolute;
        content: '';
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    .slider-on + .slider {
        background-color: rgba(251, 64, 108, 1);
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #2196f3;
    }

    .slider-on + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
</style>
