<template>
    <div class="mx-auto">
        <p class="my-5 text-grey-darkest font-bold">
            Click on a cell to select an individual locale or click on the dataset name to select the entire row
        </p>
        <Panel title="Datasets">
            <div class="w-full overflow-x-scroll">
                <table class="w-full text-left">
                    <thead>
                        <tr class="bg-grey-200">
                            <th class="px-4 py-2">Name</th>
                            <TableHeader
                                v-for="header in headers"
                                :key="header.name"
                                :name="header.name"
                                :path="header.path"
                                :title="header.title"
                                @select-language="selectLanguage"
                            />
                            <th class="px-4 py-2">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(dataset, idx) in datasets"
                            :key="idx"
                            class="border hover:bg-grey-200 cursor-pointer"
                        >
                            <TableRow :dataset="dataset" @load-datasets="loadDatasets" />
                        </tr>
                        <tr>
                            <td class="px-4 py-2"><b>Action</b></td>
                            <td v-for="(header, index) in headers" :key="changed + index" class="px-4 py-2">
                                <SecondaryButton
                                    text="Incr"
                                    :confirmed="true"
                                    :handle-error="handleButtonError"
                                    confirm-message="Are you sure you want to increase the version?"
                                    :handle-click="incrementVersion(header.name)"
                                    :disabled="!enableButton(header.name)"
                                ></SecondaryButton>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <Modal
                v-if="showModal"
                size="square"
                :click-overlay="
                    () => {
                        showModal = false;
                    }
                "
            >
                <template #header>
                    <IconButton rounded class="float-right right-0 top-0 mt-2 mr-2" @click="showModal = false">
                        Close
                    </IconButton>
                </template>

                <template #body>
                    <span class="block text-3xl text-bold text-center my-5">{{ deletedKeysCount }} keys deleted</span>

                    <div class="block my-5">
                        <IconButton rounded class="m-auto" @click="toggleShowDeletedKeys">
                            <span v-if="showDeletedKeys">Hide keys</span>
                            <span v-else>Show keys</span>
                        </IconButton>
                    </div>

                    <div v-for="key in Object.keys(deletedKeys)" :key="key" class="block text-center">
                        <DeletedKey v-if="showDeletedKeys" :name="key" :value="deletedKeys[key]" />
                    </div>
                </template>
            </Modal>
        </Panel>
    </div>
</template>

<script>
    import { dashboardAxios } from '../../axios.config';
    import { mapActions } from 'vuex';
    import SecondaryButton from '../components/Buttons/SecondaryButton';
    import TableRow from '../components/Datasets/TableRow';
    import TableHeader from '../components/Datasets/TableHeader';
    import DeletedKey from '../components/Datasets/DeletedKey';
    import EventBus from '../helpers/eventBus';

    export default {
        name: 'DatasetVersions',
        components: {
            SecondaryButton,
            TableRow,
            TableHeader,
            DeletedKey,
        },
        data() {
            return {
                datasets: [],
                selectedCol: {},
                changed: false,
                showModal: false,
                deletedKeys: [],
                viewKeys: true,
                showDeletedKeys: false,
            };
        },
        computed: {
            headers() {
                return [
                    {
                        name: 'EN',
                        path: this.resolveCountryFlagUrl('US'),
                        title: 'English (US)',
                    },
                    {
                        name: 'ENGB',
                        path: this.resolveCountryFlagUrl('GB'),
                        title: 'English (GB)',
                    },
                    {
                        name: 'DE',
                        path: this.resolveCountryFlagUrl('DE'),
                        title: 'German',
                    },
                    {
                        name: 'FR',
                        path: this.resolveCountryFlagUrl('FR'),
                        title: 'French',
                    },
                    {
                        name: 'ID',
                        path: this.resolveCountryFlagUrl('ID'),
                        title: 'Indonesian',
                    },
                    {
                        name: 'TR',
                        path: this.resolveCountryFlagUrl('TR'),
                        title: 'Turkish',
                    },
                    {
                        name: 'NL',
                        path: this.resolveCountryFlagUrl('NL'),
                        title: 'Dutch',
                    },
                    {
                        name: 'MS',
                        path: this.resolveCountryFlagUrl('MY'),
                        title: 'Malay',
                    },
                    {
                        name: 'ES',
                        path: this.resolveCountryFlagUrl('ES'),
                        title: 'Spanish',
                    },
                    {
                        name: 'AR',
                        path: this.resolveCountryFlagUrl('AE'),
                        title: 'Arabic',
                    },
                    {
                        name: 'HI',
                        path: this.resolveCountryFlagUrl('IN'),
                        title: 'Hindi',
                    },
                    {
                        name: 'FA',
                        path: this.resolveCountryFlagUrl('AF'),
                        title: 'Persian',
                    },
                    {
                        name: 'UR',
                        path: this.resolveCountryFlagUrl('PK'),
                        title: 'Urdu',
                    },
                    {
                        name: 'BN',
                        path: this.resolveCountryFlagUrl('BD'),
                        title: 'Bengali',
                    },
                    {
                        name: 'RU',
                        path: this.resolveCountryFlagUrl('RU'),
                        title: 'Russian',
                    },
                    {
                        name: 'IT',
                        path: this.resolveCountryFlagUrl('IT'),
                        title: 'Italian',
                    },
                ];
            },
            deletedKeysCount() {
                var count = 0;

                Object.keys(this.deletedKeys).forEach((key) => {
                    if (typeof this.deletedKeys[key] === 'object') {
                        count += this.deletedKeys[key].length;
                    } else {
                        count++;
                    }
                });

                return count;
            },
        },
        mounted() {
            this.loadDatasets();

            EventBus.$on('select', (value) => {
                if (!this.selectedCol[value.dataset]) {
                    this.selectedCol[value.dataset] = [];
                }

                this.selectedCol[value.dataset].push(value.version);
                this.changed = !this.changed;
            });

            EventBus.$on('delete', (value) => {
                this.selectedCol[value.dataset].forEach((version, index) => {
                    if (version === value.version) {
                        this.selectedCol[value.dataset].splice(index, 1);
                        this.changed = !this.changed;
                    }
                });
            });

            EventBus.$on('deletedKeys', (value) => {
                this.deletedKeys = value;
                this.showModal = true;
            });
        },
        methods: {
            ...mapActions({ handleError: 'handleError' }),
            async handleButtonError(error) {
                this.handleError({ error });
            },
            async loadDatasets() {
                try {
                    let response = await dashboardAxios.get('/v1/datasets');

                    this.datasets = response.data.result;
                } catch (e) {
                    this.handleError({ error: e });
                }
            },
            resolveCountryFlagUrl(flag) {
                let lowerCase = flag.toLowerCase();
                return `https://${process.env.VUE_APP_WEB_CDN_DOMAIN}/images/flags/flag_${lowerCase}.png`;
            },
            selectLanguage(language) {
                EventBus.$emit('selectedLanguage', language);
            },
            enableButton(name) {
                var found = false;
                var versionName = 'version_' + name.toLowerCase();

                Object.keys(this.selectedCol).forEach((dataset) => {
                    this.selectedCol[dataset].forEach((version) => {
                        if (version === versionName) {
                            found = true;
                        }
                    });
                });

                return found;
            },
            incrementVersion(locale) {
                return async () => {
                    var datasets = [];
                    var versionLocale = 'version_' + locale.toLowerCase();
                    var emitEvents = [];

                    Object.keys(this.selectedCol).forEach((dataset, key) => {
                        if (this.selectedCol[dataset]) {
                            this.selectedCol[dataset].forEach((language, index) => {
                                if (language === versionLocale) {
                                    datasets.push(dataset);

                                    this.selectedCol[dataset].splice(index, 1);
                                    emitEvents.push({ name: dataset, version: versionLocale });
                                }
                            });
                        }
                    });

                    var response = await dashboardAxios.post('/v1/datasets/increment-col-version', {
                        dataset_name: datasets,
                        locale: [locale],
                    });

                    this.deletedKeys = response.data.result['deleted-keys'];

                    this.showModal = true;

                    this.loadDatasets();

                    emitEvents.forEach((event) => {
                        EventBus.$emit('synchronize-row', { name: event.name, version: event.version });
                    });
                };
            },
            toggleViewKeys() {
                this.viewKeys = !this.viewKeys;
            },
            toggleShowDeletedKeys() {
                this.showDeletedKeys = !this.showDeletedKeys;
            },
        },
    };
</script>

<style scoped>
    .contents {
        display: contents;
    }
</style>
