import { dashboardAxios } from '../../axios.config';
import { RedirectToLogin } from '../helpers/RedirectToLogin';

const resource = '/member';

export default {
    getApprove() {
        return dashboardAxios.get(`/approvals`).catch((e) => {
            if (e.response.data.name === 'TokenExpiredError') {
                RedirectToLogin();
            }
        });
    },
    submitApproveAnswers(approveData) {
        return dashboardAxios.post(`/approvals`, approveData).catch((e) => {
            if (e.response.data.name === 'TokenExpiredError') {
                RedirectToLogin();
            }
        });
    },
    flag(payload) {
        return dashboardAxios.post(`members/${payload.memberID}/flag`).catch((e) => {
            if (e.response.data.name === 'TokenExpiredError') {
                RedirectToLogin();
            }
        });
    },
};
