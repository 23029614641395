<template>
    <Modal size="m" :click-overlay="() => $emit('close-modal')" :no-padding="true">
        <div slot="body">
            <Panel title="Add Remote Config" :has-margin="false" :custom-classes="['bg-pink-muzmatch', 'text-white']">
                <div class="p-4">
                    <div class="flex flex-wrap mb-5">
                        <div class="w-full">
                            <label for="form-name">Label *</label>
                            <input
                                id="form-name"
                                v-model="form.label"
                                placeholder="Can be camelCase, PascalCase, snake_case or whitespace"
                                class="focus:border-pink rounded-xs border rounded border-grey w-full p-3 text-base mb-2"
                            />
                            <label>Preview: {{ preview }}</label>
                        </div>
                    </div>
                    <div class="flex flex-wrap mb-5">
                        <div class="w-full">
                            <label for="group-name">Group Name *</label>
                            <Multiselect
                                v-model="form.groupName"
                                tag-placeholder="Add a group"
                                placeholder="Search or add a group"
                                label="name"
                                track-by="name"
                                :options="options"
                                :multiple="false"
                                :taggable="true"
                                @tag="addTag"
                            />
                        </div>
                    </div>
                    <div class="mb-5">
                        <label class="mr-2">Type *</label>
                        <div v-for="val in typeOptionList" :key="val.value">
                            <input
                                :id="val.value"
                                v-model="form.type"
                                :value="val.value"
                                class="cursor-pointer"
                                type="radio"
                            />
                            <label class="pl-2 cursor-pointer" :for="val.value">
                                {{ val.label }}
                            </label>
                        </div>
                    </div>
                    <div v-if="form.type === 'TEXT'" class="mb-5 flex flex-col">
                        <label class="mr-2" for="text-value">Value *</label>
                        <input
                            id="text-value"
                            v-model="form.value"
                            class="focus:border-pink rounded-xs border rounded border-grey w-full p-3 text-base mb-2"
                            type="text"
                        />
                    </div>
                    <div v-if="form.type === 'NUMBER'" class="mb-5 flex flex-col">
                        <label class="mr-2" for="number-value">Value *</label>
                        <input
                            id="number-value"
                            v-model="form.value"
                            class="focus:border-pink rounded-xs border rounded border-grey w-full p-3 text-base mb-2"
                            type="number"
                        />
                    </div>
                    <div v-if="form.type === 'BOOLEAN'" class="mb-5 flex flex-col">
                        <label class="w-full">Value *</label>
                        <div>
                            <input id="value-true" v-model="form.value" value="1" class="cursor-pointer" type="radio" />
                            <label class="pl-2 cursor-pointer" for="value-true">True</label>
                        </div>
                        <div>
                            <input
                                id="value-false"
                                v-model="form.value"
                                value="0"
                                class="cursor-pointer"
                                type="radio"
                            />
                            <label class="pl-2 cursor-pointer" for="value-false">False</label>
                        </div>
                    </div>
                    <div class="mb-5">
                        <label for="enabled">Enabled</label>
                        <input id="enabled" v-model="form.enabled" class="ml-2" type="checkbox" name="enabled" />
                    </div>
                    <div>
                        <M2Button
                            :is-disabled="isLoading"
                            :custom-classes="['text-xs', 'normal-case', 'rounded']"
                            @clicked="createConfig"
                        >
                            <span v-if="!isLoading">Confirm</span>
                            <icon v-else class="animate-spin" icon="spinner" />
                        </M2Button>
                    </div>
                </div>
            </Panel>
        </div>
    </Modal>
</template>
<script>
    import _ from 'lodash';
    import Panel from '../../components/Panels/Panel';
    import M2Button from '../../components/Buttons/M2Button';
    import { dashboardAxios } from '../../../axios.config';
    import { mapActions } from 'vuex';
    import Multiselect from 'vue-multiselect';

    export default {
        name: 'AddModal',
        components: {
            Panel,
            M2Button,
            Multiselect,
        },
        props: {
            groups: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                form: {
                    label: '',
                    type: 'TEXT',
                    enabled: false,
                    groupName: '',
                    value: '',
                },
                typeOptionList: [
                    { value: 'TEXT', label: 'Text' },
                    { value: 'NUMBER', label: 'Number' },
                    { value: 'BOOLEAN', label: 'Boolean' },
                ],
                options: [],
                isLoading: false,
                preview: '',
            };
        },
        watch: {
            'form.label'(val) {
                this.preview = _.camelCase(val.trim());
            },
        },
        created() {
            if (this.jsonFilters === null) {
                this.boxes = [[{ type: null }]];
                return;
            }

            this.options = this.groups.map((group) => {
                return {
                    name: group,
                };
            });
            const mobileSetting = this.options.find((option) => {
                return option.name === 'MOBILE_SETTING';
            });
            if (typeof mobileSetting !== 'undefined') {
                this.form.groupName = mobileSetting;
            }
        },
        methods: {
            ...mapActions(['alert', 'handleError']),

            handleInputChange(name, value) {
                this.campaign[name] = value;
            },

            addTag(newTag) {
                const tag = {
                    name: newTag.toUpperCase().replaceAll(' ', '_'),
                };
                this.options.push(tag);
            },

            async createConfig() {
                if (this.form.label === '') {
                    return this.alert({
                        message: 'Label is required',
                        type: 'danger',
                    });
                }

                if (this.form.groupName === '') {
                    return this.alert({
                        message: 'Group Name is required',
                        type: 'danger',
                    });
                }

                if (this.form.value === '') {
                    return this.alert({
                        message: 'Value is required',
                        type: 'danger',
                    });
                }

                this.isLoading = true;
                try {
                    const form = { ...this.form };
                    form.groupName = form.groupName.name;
                    const response = await dashboardAxios.post('/v1/remote-config/save-config', form);
                    this.$emit('close-modal-update', response.data.result);
                    this.isLoading = false;
                } catch (error) {
                    this.alert({
                        message:
                            typeof error.response.data.result.message !== 'undefined'
                                ? error.response.data.result.message
                                : 'Request failed',
                        type: 'danger',
                    });
                    this.isLoading = false;
                }
            },
        },
    };
</script>
