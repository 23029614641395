<template>
    <section class="w-full">
        <Modal v-if="isModal && otherMembers" :click-overlay="() => (this.isModal = false)">
            <template #header>
                <IconButton rounded class="float-right right-0 top-0 absolute mt-2 mr-2" @click="isModal = false">
                    Close
                </IconButton>
            </template>
            <template #body>
                <div>
                    <p>This email address is also attached to the following {{ memberNumber }}</p>
                    <router-link
                        v-for="otherMember in emailAddress.otherMembers"
                        :key="otherMember.member_id"
                        :to="{ name: 'members', query: { memberId: otherMember.member_id } }"
                        target="_blank"
                    >
                        Member ID: {{ otherMember.member_id }}
                    </router-link>
                </div>
            </template>
        </Modal>
        <div
            class="p-2 mt-1 rounded cursor-pointer"
            :class="{ 'hover:bg-grey-100': !isThreadsVisible, 'bg-grey-100': isThreadsVisible }"
            @click="setThreadVisibility"
        >
            <p class="mb-1 text-pink-muzmatch">{{ emailAddress.type }}</p>
            <div class="w-full flex flex-row">
                <icon
                    v-show="otherMembers"
                    class="text-pink-muzmatch"
                    icon="exclamation-circle"
                    @click.stop="showOtherMemberAlert"
                ></icon>
                <p style="width: 85%" class="mr-2 break-words">{{ emailAddress.emailAddress }}</p>
                <icon class="float-right self-center" :icon="icon" />
            </div>
        </div>
        <div v-if="isThreadsVisible">
            <ThreadSelector
                v-for="thread in emailAddress.threads"
                :key="thread.id"
                :thread="thread"
                :selected-thread-id="selectedThreadId"
                @fetch-thread="fetchThread"
            />
        </div>
    </section>
</template>

<script>
    import { dashboardAxios } from '../../../../../axios.config';
    import ThreadSelector from './ThreadSelector';
    import Modal from '../../../Modal/Modal';

    export default {
        components: {
            Modal,
            ThreadSelector,
        },
        props: {
            emailAddress: {
                type: Object,
                required: true,
            },
            selectedEmailAddress: {
                type: String,
                default: null,
            },
        },
        data() {
            return {
                isModal: false,
                isThreadsVisible: false,
                selectedThreadId: null,
            };
        },
        computed: {
            icon() {
                return this.isThreadsVisible ? 'chevron-up' : 'chevron-down';
            },
            otherMembers() {
                if (!this.emailAddress.otherMembers) return;

                return this.emailAddress.otherMembers.length !== 0;
            },
            otherMembersList() {
                if (!this.otherMembers) return null;

                return this.emailAddress.otherMembers
                    .map((otherMember) => {
                        return `
                    <ThreadSelector />
                `;
                    })
                    .join(' ');
            },
            memberNumber() {
                if (!this.otherMembers) return;

                return this.emailAddress.otherMembers.length === 1 ? 'member' : 'members';
            },
        },
        watch: {
            selectedEmailAddress(selectedEmailAddress) {
                if (selectedEmailAddress !== this.emailAddress.emailAddress) {
                    this.isThreadsVisible = false;
                }
            },
        },
        methods: {
            setThreadVisibility() {
                this.isThreadsVisible = !this.isThreadsVisible;

                if (this.isThreadsVisible) {
                    this.$emit('email-address-selected', this.emailAddress.emailAddress);
                    return;
                }

                this.selectedThreadId = null;
            },
            async fetchThread(id) {
                try {
                    const response = await dashboardAxios.get(`/v1/members/threads/${id}`);
                    const thread = response.data.result;
                    this.selectedThreadId = thread.id;
                    this.$emit('thread-selected', thread);
                } catch (error) {
                    console.log(error);
                }
            },
            async showOtherMemberAlert(memberId) {
                this.isModal = true;
            },
        },
    };
</script>

<style lang="scss" scoped></style>
