<template>
    <div class="h-full">
        <div
            v-if="loading"
            class="w-full h-full bg-grey-lightest rounded flex justify-center items-center"
            :class="customStyle"
        >
            <p class="text-5xl text-pink-muzmatch">
                <icon icon="comments" class="mx-1"></icon>
            </p>
        </div>

        <div v-if="!loading" :class="customStyle">
            <div
                class="p-4 overflow-y-scroll chat-messages text-sm overflow-y-scroll"
                :class="isAdminChat ? 'chat-height' : 'h-full'"
            >
                <div
                    v-if="this.messages.length > 250 * page || this.currentPage < this.totalPages"
                    class="flex justify-center items-center"
                >
                    <span
                        class="p-2 px-3 bg-pink-muzmatch text-white rounded cursor-pointer font-bold"
                        @click="nextPage()"
                    >
                        Show More
                    </span>
                </div>

                <div v-for="(message, index) in limitedMessages" :key="index" class="break-words chat-message">
                    <div v-if="!hasPrevious(message, index)" class="px-1 notranslate" :class="getSenderClass(message)">
                        <p>{{ message.member.full_name }}</p>
                    </div>

                    <div v-if="message.type === 'M'" :class="getSenderClass(message)">
                        <media-item
                            :item-class="getBorderClass(message, index) + getMessageClass(message, index)"
                            :time-class="getTimeClass(message)"
                            :message-selected="selectedMessage && selectedMessage.id === message.id"
                            :message="message"
                            :is-admin-chat="isAdminChat"
                            :sender="sender"
                            @selectMessage="selectMessage(message)"
                        />
                    </div>

                    <div v-else-if="message.type === 'T'" :class="getSenderClass(message)">
                        <div
                            class="p-3 font-bold inline-block break-words max-w-2/3 cursor-pointer"
                            :class="getBorderClass(message, index) + getMessageClass(message, index)"
                            @click="selectMessage(message)"
                        >
                            <reply-to-contents :sender="sender" :message="message"></reply-to-contents>

                            <p
                                :class="{ 'line-through': message.visibility !== 'VISIBLE' }"
                                v-html="convertMessage(message)"
                            ></p>

                            <p
                                v-if="selectedMessage && selectedMessage.id === message.id"
                                :class="getTimeClass(message)"
                            >
                                {{ message.created_at | time }}
                            </p>
                            <chat-visibility-disclaimer :message="message"></chat-visibility-disclaimer>
                        </div>
                    </div>

                    <div v-else-if="message.type === 'I'">
                        <InstantChatItem
                            :sender="sender"
                            :instant-chat="message.instantChat"
                            :member="message.member"
                        />
                    </div>

                    <div v-else class="py-6">
                        <div class="text-center font-semibold font-serif">
                            <span class="cursor-pointer" @click="selectMessage(message)">{{ message.message }}</span>
                        </div>

                        <div
                            v-if="selectedMessage && selectedMessage.id === message.id && message.type !== 'M'"
                            class="text-right mt-2"
                        >
                            <span class="text-xs">{{ message.created_at | time }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="isAdminChat" class="p-2 mb-1">
                <textarea
                    ref="textAreaMessage"
                    v-model.lazy="message"
                    type="text"
                    class="shortcut-accessible w-full rounded border h-48 border-grey-200 p-4 mb-1 focus:outline-none"
                    :class="[contextType, contextAction]"
                />

                <button
                    class="rounded w-full text-white p-4 focus:outline-none cursor-pointer"
                    :class="messageLoading ? 'bg-pink-light' : 'bg-pink-muzmatch'"
                    @click="sendMessage()"
                >
                    Send Message
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import Panel from '../Panel';
    import { mapActions } from 'vuex';
    import EventBus from '../../../helpers/eventBus';
    import { dashboardAxios } from '../../../../axios.config';
    import MediaItem from '../Items/MediaItem';
    import InstantChatItem from '../Items/InstantChatItem';
    import ChatVisibilityDisclaimer from './ChatVisibilityDisclaimer';
    import ReplyToContents from './ReplyToContents';

    export default {
        name: 'ChatPanelContents',

        components: {
            MediaItem,
            InstantChatItem,
            Panel,
            ChatVisibilityDisclaimer,
            ReplyToContents,
        },

        filters: {
            time(timestamp) {
                return moment(timestamp).format('DD/MM/YYYY HH:mm:ss');
            },
        },

        props: {
            sender: {
                type: String,
                default: null,
            },

            receiver: {
                type: String,
                default: null,
            },

            matchId: {
                type: String,
                default: null,
            },

            customStyle: {
                type: String,
                default: '',
            },

            contextType: {
                type: String,
                required: true,
            },

            contextAction: {
                type: String,
                required: true,
            },

            loading: {
                type: Boolean,
                required: true,
            },

            messages: {
                type: Array,
                required: true,
            },

            currentPage: {
                type: Number,
                default: 1,
            },

            totalPages: {
                type: Number,
                default: 1,
            },
        },

        data() {
            return {
                page: 1,

                selectedMessage: null,

                message: 'Salaam, ',
                messageLoading: false,

                muzmatchMemberHash: '69d6354927d12aabab64c15843f5e786cfabe1fba511e6190622eb392856dffb',
            };
        },

        computed: {
            isAdminChat() {
                return this.muzmatchMemberHash === this.sender || this.muzmatchMemberHash === this.receiver;
            },

            limitedMessages() {
                let reversedMessages = [...this.messages].slice(0, this.page * 250);
                return reversedMessages.reverse();
            },
        },

        watch: {
            sender() {
                this.message = 'Salaam, ';
                this.page = 1;

                this.loadChat();
            },

            receiver() {
                this.message = 'Salaam, ';
                this.page = 1;

                this.loadChat();
            },
        },
        mounted() {
            this.loadChat();

            EventBus.$on('refresh-chat', this.loadChatSilent);
        },

        destroyed() {
            EventBus.$off('refresh-chat', this.loadChatSilent);
        },
        methods: {
            ...mapActions({
                handleError: 'handleError',
            }),

            getBorderClass(message, index) {
                if (this.sender === message.member.id) {
                    return 'rounded-bl-lg rounded-tl-lg ';
                }

                return 'rounded-br-lg rounded-tr-lg ';
            },

            getMessageClass(message, index) {
                let output = '';

                if (this.sender === message.member.id) {
                    output += 'text-white text-right ';
                    output +=
                        this.selectedMessage && this.selectedMessage.id === message.id
                            ? 'bg-pink-muzmatch-lighter '
                            : 'bg-pink-muzmatch ';
                } else {
                    output += 'text-black text-left ';
                    output +=
                        this.selectedMessage && this.selectedMessage.id === message.id
                            ? 'bg-grey-100 '
                            : 'bg-grey-200 ';
                }

                let hasPrevious = this.hasPrevious(message, index);
                let hasNext = this.hasNext(message, index);

                if (hasPrevious && hasNext) {
                    return output + 'mb-1 rounded-sm';
                }

                if (hasPrevious && !hasNext) {
                    return output + 'mb-2 rounded-b-lg rounded-t-sm';
                }

                if (!hasPrevious && hasNext) {
                    return output + 'mb-1 rounded-t-lg rounded-b-sm';
                }

                if (!hasPrevious && !hasNext) {
                    return output + 'mb-2 rounded-lg';
                }
            },

            getSenderClass(message) {
                return message.member.id === this.sender ? 'text-right' : '';
            },

            getTimeClass(message) {
                if (this.sender === message.member.id) {
                    return 'text-pink-lightest text-xs text-left';
                }

                return 'text-grey-800 text-xs text-right';
            },

            hasPrevious(message, index) {
                let previousMessage = this.messages[index - 1];

                if (!previousMessage) {
                    return false;
                }

                if (previousMessage.type !== 'T') {
                    return false;
                }

                return previousMessage.member.id === message.member.id;
            },

            hasNext(message, index) {
                let nextMessage = this.messages[index + 1];

                if (!nextMessage) {
                    return false;
                }

                if (nextMessage.type !== 'T') {
                    return false;
                }

                return nextMessage.member.id === message.member.id;
            },

            async sendMessage() {
                if (this.messageLoading) {
                    return;
                }

                this.messageLoading = true;

                try {
                    await dashboardAxios.post('/v1/messages', {
                        member_id: this.sender,
                        message: this.$refs.textAreaMessage.value,
                    });

                    await this.loadChat(true);

                    this.message = 'Salaam, ';
                    this.messageLoading = false;
                } catch (e) {
                    this.handleError({ error: e });
                }
            },

            async loadChatSilent() {
                this.page = 1;
                await this.loadChat(true, true);
            },

            selectMessage(message) {
                if (!this.selectedMessage) {
                    this.selectedMessage = message;

                    return;
                }

                this.selectedMessage = this.selectedMessage.id === message.id ? null : message;
            },

            convertMessage(message) {
                let messageReturn = message.message;
                if (typeof message.shareProfileLink !== 'undefined') {
                    for (const [key, value] of Object.entries(message.shareProfileLink)) {
                        let regex = new RegExp(`(\\S*)muzz.com\/profile\/d\/${key}`, 'g');
                        let matches = messageReturn.match(regex);
                        if (matches?.length > 0) {
                            messageReturn = messageReturn.replaceAll(
                                regex,
                                `<a class="hover:underline" target="_blank" href="${value}">${matches[0]}</a>`,
                            );
                        }
                    }
                }
                return messageReturn.split('\n').join('<br>');
            },

            loadChat(silent) {
                this.$emit('loadChat', silent, true);
            },

            nextPage() {
                let elements = document.getElementsByClassName('chat-messages');

                let originalScrollHeight = elements[0].scrollHeight;

                if (this.page * 250 == this.messages.length && this.currentPage < this.totalPages) {
                    this.$emit('nextPage');
                }

                this.page++;

                this.$nextTick(() => {
                    let elements = document.getElementsByClassName('chat-messages');

                    elements[0].scrollTop = elements[0].scrollHeight - originalScrollHeight;
                });
            },

            calculateHeight() {
                this.height = this.$refs.messages.scrollHeight;
            },
        },
    };
</script>

<style>
    .chat-height {
        height: calc(100% - 266px);
    }

    .bg-pink-muzmatch-lighter {
        background-color: #e33e6a;
    }

    .max-w-2\/3 {
        max-width: 66.66%;
    }
</style>
