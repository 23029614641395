<template>
    <div>
        <DashboardLabel class="flex my-3">Credits</DashboardLabel>

        <div class="flex flex-row items-end flex-wrap">
            <div v-for="(credit, type) in form" :key="type" class="flex flex-col mr-6">
                <label class="my-1">{{ credit.label }} Credits</label>
                <div class="flex">
                    <input
                        v-model="credit.value"
                        type="number"
                        min="0"
                        :disabled="isLoading"
                        placeholder="Enter number to override"
                        class="p-2 border border-grey-300 rounded sm:w-24 mr-2"
                    />
                    <SecondaryButton
                        text="Update"
                        class="mt-2 mr-1"
                        :disabled="isLoading"
                        :confirmed="true"
                        :confirm-message="`This will override the ${credit.label} credits for this member`"
                        :handle-error="handleButtonError"
                        :handle-click="updateCredit({ credits: credit.value, type: type })"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import DashboardLabel from '@/components/Labels/DashboardLabel';
    import SecondaryButton from '@/components/Buttons/SecondaryButton';
    import { mapActions } from 'vuex';
    import { dashboardAxios } from '../../../../axios.config';

    export default {
        name: 'DebugOptionCredit',
        components: {
            DashboardLabel,
            SecondaryButton,
        },

        props: {
            member: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                isLoading: false,
                form: {
                    instantMatchCredit: { label: 'Instant Match', value: 0 },
                    boostCredit: { label: 'Boost', value: 0 },
                    eventCredit: { label: 'Event', value: 0 },
                },
            };
        },

        mounted() {
            this.loadCredits();
        },

        methods: {
            ...mapActions({
                handleError: 'handleError',
                alert: 'alert',
            }),

            async handleButtonError(error) {
                await this.handleError({ error });
            },

            async loadCredits() {
                this.isLoading = true;

                try {
                    let response = await dashboardAxios.get(`/v1/profile-credits/${this.member.memberID}`);

                    for (const creditType in this.form) {
                        this.form[creditType].value = response.data.result[creditType];
                    }
                } catch (e) {
                    await this.handleError({ error: e });
                }

                this.isLoading = false;
            },

            updateCredit(formData = null) {
                return async () => {
                    let payload = {
                        memberID: this.member.memberID,
                        accountID: this.member.accountID,
                    };

                    if (formData) {
                        payload = { ...payload, ...formData };
                    }

                    try {
                        let response = await dashboardAxios.post('/v1/profile-credits-save', payload);
                        const message = response.data.result.message;

                        await this.alert({ message: message, type: 'success' });
                    } catch (error) {
                        await this.handleError({ error });
                    }

                    this.$emit('refresh');
                };
            },
        },
    };
</script>

<style scoped></style>
