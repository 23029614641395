<template>
    <Panel title="Search">
        <Search class="p-4 search-height overflow-y-scroll" @searched="$emit('searched')"></Search>
    </Panel>
</template>

<script>
    import Search from './Search';
    import Panel from '../../components/Panels/Panel';

    export default {
        name: 'SearchPanel',

        components: {
            Panel,
            Search,
        },
    };
</script>

<style scoped>
    @media screen and (min-width: 768px) {
        .search-height {
            height: calc(100vh - 180px);
        }
    }
</style>
