<template>
    <span
        class="absolute right-0 top-0 p-2 rounded-full w-8 h-8 flex justify-center items-center cursor-pointer"
        :class="loading ? 'bg-pink-lighter' : 'bg-pink-muzmatch'"
        @click="handleButton()"
    >
        <icon icon="times" class="text-white text-sm"></icon>
    </span>
</template>

<script>
    export default {
        name: 'DeleteButton',

        props: {
            handleClick: {
                type: Function,
                default: async () => {},
            },

            handleError: {
                type: Function,
                default: async (error) => {},
            },
        },

        data() {
            return {
                loading: false,
            };
        },

        methods: {
            async handleButton() {
                if (this.loading) {
                    return;
                }

                this.loading = true;

                try {
                    await this.handleClick();
                } catch (error) {
                    await this.handleError(error);
                }

                this.loading = false;
            },
        },
    };
</script>
