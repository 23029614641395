<template>
    <div class="flex flex-col w-full md:w-1/2 lg:w-1/3 md:p-0 p-4 mx-auto font-avenir mt-10">
        <p class="text-xl">We've sent a one-off PIN to your email.</p>

        <div class="w-full mt-5">
            <div class="“mt-5”">
                <p class="text-base">Verification PIN</p>

                <div class="my-4">
                    <input
                        v-model="pin"
                        type="text"
                        class="w-full p-4 border border-grey-300 rounded"
                        placeholder="PIN code"
                        autofocus
                        @keyup.enter="checkPIN"
                    />

                    <p class="text-red my-1">{{ errors }}</p>
                </div>
            </div>

            <div @click="checkPIN">
                <Button>Sign In</Button>
            </div>
        </div>
    </div>
</template>

<script>
    import { dashboardAxios } from '../../axios.config';
    import getRedirectTo from './../helpers/GetRedirectTo';
    import { mapActions, mapGetters, mapMutations } from 'vuex';
    import { captureException } from '@sentry/minimal';

    export default {
        name: 'ConfirmPIN',

        data() {
            return {
                pin: '',
                errors: '',
            };
        },

        computed: {
            ...mapGetters({
                staff: 'staff/getStaff',
            }),
        },

        methods: {
            ...mapActions({
                requestStaff: 'staff/requestStaff',
                requestSetToken: 'auth/requestSetToken',
                requestCheckTokenExists: 'auth/requestCheckTokenExists',
            }),

            ...mapMutations({
                setIsAppReady: 'setIsAppReady',
            }),

            async checkPIN() {
                this.errors = '';

                if (this.pin === '') {
                    this.errors = 'Please enter the PIN to continue.';

                    return;
                }

                try {
                    let emailAddress = await this.getEmailAddress();

                    if (!emailAddress) {
                        return await this.$router.push('/login');
                    }

                    let body = {
                        PIN: this.pin,
                        emailAddress: emailAddress,
                    };

                    let response = await dashboardAxios.post('/v1/auth/pin/confirm', body);

                    let token = response.data.result.session.passwordToken;

                    await this.requestSetToken(token);
                    await this.requestStaff(token);

                    if (!this.staff.id) {
                        this.errors = 'Staff not found.';

                        return;
                    }

                    this.setIsAppReady(true);

                    // Check if it has uri params, if so, redirect there
                    let redirectTo = getRedirectTo();

                    // If redirect to exists then do so and redirect
                    if (redirectTo !== null) {
                        return (window.location.href = decodeURIComponent(redirectTo));
                    }

                    if (this.staff.defaultHome) {
                        return this.$router.push({ path: `/${this.staff.defaultHome}` });
                    }

                    let permission = this.staff.roles[0].productName.toLowerCase();

                    // Else go to first permission page
                    await this.$router.push({ path: `/${permission}` });
                } catch (e) {
                    captureException(e);
                    this.errors = 'Invalid PIN';
                }
            },

            getEmailAddress() {
                let email = this.$route.query.email;

                return email ? email : null;
            },
        },
    };
</script>
