<template>
    <div v-if="profile">
        <DashboardHints :hints="hints" />

        <DashboardSectionCard class="mb-4">
            <a id="summary"></a>

            <DashboardMemberName :profile="profile" />

            <div class="md:flex flex-row">
                <div style="flex-grow: 1">
                    <DashboardSummaryLabels :profile="profile" />
                    <div class="px-4 pb-4">
                        <div class="flex">
                            <div
                                class="flags flex justify-between cursor-pointer flex-wrap flex-col md:flex-row"
                                :class="showFlags ? 'flex-grow' : ''"
                                @click="showFlags = !showFlags"
                            >
                                <div v-for="flag in flags" :key="flag.code" class="flex justify-center m-2">
                                    <DashboardFlag
                                        :show-details="showFlags"
                                        :path="resolveCountryFlagUrl(flag.code)"
                                        :name="flag.name"
                                        :location="profile[flag.location] || ''"
                                        :type="profile[flag.title] || 'Unknown'"
                                    />
                                </div>
                            </div>

                            <div class="flex justify-center items-center">
                                <span
                                    class="hover:bg-grey-100 p-1 rounded cursor-pointer"
                                    @click="showFlags = !showFlags"
                                >
                                    <icon :icon="showFlags ? 'chevron-left' : 'chevron-right'"></icon>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="this.profile['shareable'] == '1'" class="p-4">
                    <qr-code :text="this.shareLink"></qr-code>
                </div>
            </div>
        </DashboardSectionCard>

        <div class="mb-2">
            <span
                class="text-pink-muzmatch font-bold cursor-pointer"
                @click="duplicateAccountCount > 0 ? toggleShowAccounts() : null"
            >
                {{ duplicateAccountCount }} duplicate accounts ({{ activeDupeAccounts }} active &
                {{ inactiveDupeAccounts }} inactive & {{ blockedDupeAccounts }} blocked)
            </span>

            <icon
                v-show="duplicateAccountCount > 0"
                :icon="showDuplicateAccounts ? 'chevron-up' : 'chevron-down'"
                class="cursor-pointer grey-light ml-1"
                @click="toggleShowAccounts"
            ></icon>

            <div v-if="showDuplicateAccounts" class="flex flex-wrap my-3 notranslate">
                <div v-for="account in formatDuplicateMembers(duplicateAccounts)" :key="account.hash">
                    <router-link
                        target="_blank"
                        :to="getMemberRoute({ nickname: account.name, hashMemberID: account.hash })"
                        class="no-underline text-pink-muzmatch mx-1 font-bold"
                    >
                        {{ account.name || 'no nickname' }}
                        <span class="capitalize">({{ getState(account).toLowerCase() }})</span>
                    </router-link>
                </div>
            </div>
        </div>

        <div class="mb-2">
            <span
                class="text-pink-muzmatch font-bold cursor-pointer"
                @click="previousAccountList.length > 0 ? (showPrevAccounts = !showPrevAccounts) : null"
            >
                {{ previousAccountList.length }} previous accounts
            </span>

            <icon
                v-show="previousAccountList.length > 0"
                :icon="showPrevAccounts ? 'chevron-up' : 'chevron-down'"
                class="cursor-pointer grey-light ml-1"
                @click="showPrevAccounts = !showPrevAccounts"
            ></icon>

            <div v-if="showPrevAccounts" class="flex flex-wrap my-3 notranslate">
                <div v-for="account in formatDuplicateMembers(previousAccountList)" :key="account.hashMemberID">
                    <router-link
                        target="_blank"
                        :to="getMemberRoute({ nickname: account.nickname, hashMemberID: account.hashMemberID })"
                        class="no-underline text-pink-muzmatch mx-1 font-bold"
                    >
                        {{ account.nickname || 'no nickname' }}
                    </router-link>
                </div>
            </div>
        </div>

        <div class="mb-2">
            <span
                class="text-pink-muzmatch font-bold cursor-pointer"
                @click="blockedFieldList.length > 0 ? (showBlockedFields = !showBlockedFields) : null"
            >
                {{ blockedFieldList.length }} blocked accounts
            </span>

            <icon
                v-show="blockedFieldList.length > 0"
                :icon="showBlockedFields ? 'chevron-up' : 'chevron-down'"
                class="cursor-pointer grey-light ml-1"
                @click="showBlockedFields = !showBlockedFields"
            ></icon>

            <div v-if="showBlockedFields" class="flex flex-wrap my-3 notranslate">
                <div v-for="account in formatDuplicateMembers(blockedFieldList)" :key="account.hashMemberID">
                    <router-link
                        target="_blank"
                        :to="getMemberRoute({ nickname: account.nickname, hashMemberID: account.hashMemberID })"
                        class="no-underline text-pink-muzmatch mx-1 font-bold"
                    >
                        {{ account.nickname || 'no nickname' }} ({{ account.source }})
                    </router-link>
                </div>
            </div>
        </div>

        <DashboardSectionCard class="mb-4">
            <a id="identity-verification"></a>

            <div class="p-4 border-b cursor-pointer" @click.self="showIdVerification = !showIdVerification">
                <DashboardLabel @click.native="showIdVerification = !showIdVerification">
                    Identity Verification
                </DashboardLabel>

                <icon
                    v-if="!isIdVerificationTaskManager"
                    class="ml-2"
                    :icon="showIdVerification ? 'chevron-up' : 'chevron-down'"
                    @click="showIdVerification = !showIdVerification"
                />
            </div>

            <IdentityVerificationCard
                v-if="showIdVerification || isIdVerificationTaskManager"
                :member="member"
                :identity-verification="identityVerification ? identityVerification : null"
                :is-task-manager="isIdVerificationTaskManager"
                @updated-member="updatedIdentityVerification"
            />
        </DashboardSectionCard>

        <DashboardSectionCard class="mb-4">
            <a name="profile"></a>

            <div class="border-b flex items-center justify-between">
                <span class="p-4">
                    <DashboardLabel>Profile</DashboardLabel>
                </span>

                <div class="flex flex-col mr-4 flex-wrap md:flex-row justify-end items-center text-sm p-2">
                    <SecondaryButton
                        class="mr-1 my-1"
                        text="Rubbish Profile"
                        :confirmed="true"
                        :handle-error="handleButtonError"
                        confirm-message="This will reject their *entire* profile."
                        :handle-click="updateProfile('/v1/rubbish-profile', 'post', null, 'Profile Rejected')"
                    ></SecondaryButton>

                    <PrimaryButton
                        class="mr-1 my-1"
                        text="Approve Profile"
                        :handle-error="handleButtonError"
                        :handle-click="updateProfile('/v1/approve', 'post', null, 'Approved Profile')"
                    ></PrimaryButton>
                </div>
            </div>

            <div class="p-4">
                <div
                    v-for="(photo, index) in photos"
                    :key="photo.key"
                    class="p-1 float-left"
                    :class="evenSplit ? 'w-1/2 xl:w-1/3' : 'w-1/3 xl:w-1/7'"
                >
                    <div class="text-left">
                        <DashboardLabel class="whitespace-nowrap">{{ photo.label }}</DashboardLabel>

                        <div v-if="photo.is === 'image'" class="relative">
                            <img
                                class="profile-photo w-full rounded-lg cursor-pointer"
                                :class="evenSplit ? 'h-80' : 'h-64'"
                                :src="
                                    photo.signedUrl
                                        ? photo.signedUrl
                                        : photo.type === 'photoVerification'
                                        ? resolveVerificationPhotoUrl(photo.key, member.hashMemberID, 'portrait', false)
                                        : resolvePhotoUrl(photo.key, member.hashMemberID, 'portrait', false)
                                "
                                @click="toggleModal(index)"
                                @error="$event.target.src = require('../../assets/mm2.png')"
                            />
                            <DeleteButton
                                v-if="photo.key || photo.signedUrl || true"
                                class="-mr-1 -mt-2"
                                :handle-click="updateProfile(photo.action, 'post', null, 'Deleted Photo')"
                                :handle-error="handleButtonError"
                            ></DeleteButton>
                        </div>
                        <div v-else class="relative">
                            <video
                                v-if="photo.key"
                                id="profile_video"
                                controls
                                class="focus:outline-none w-full h-64 cover-fit"
                                @play="profile_video_play('play')"
                                @ended="profile_video_play('complete')"
                                @click.prevent="toggleModal(index)"
                            >
                                <source
                                    :src="
                                        photo.signedUrl
                                            ? photo.signedUrl
                                            : resolveVideoUrl(photo.key, member.hashMemberID)
                                    "
                                />
                            </video>
                            <img
                                v-else
                                class="profile-photo w-full rounded-lg cursor-pointer"
                                :class="evenSplit ? 'h-80' : 'h-64'"
                                :src="resolvePhotoUrl(null, null, 'portrait', false)"
                                @click="toggleModal(index)"
                                @error="$event.target.src = require('../../assets/mm2.png')"
                            />
                            <DeleteButton
                                v-if="photo.key || true"
                                class="-mr-1 -mt-2"
                                :handle-click="updateProfile(photo.action, 'post', null, 'Deleted Video')"
                                :handle-error="handleButtonError"
                            ></DeleteButton>
                        </div>
                    </div>
                </div>

                <ProfilePhotoModal
                    v-if="showModal"
                    :photos="photos"
                    :current-img-index="currentImgIndex"
                    :next-img-index="nextImgIndex"
                    :hash-member-id="member.hashMemberID"
                    @toggleModal="toggleModal"
                    @decrementIndex="decrementIndex"
                    @incrementIndex="incrementIndex"
                />
            </div>

            <div class="flow-root clear-both"></div>

            <div v-if="audio.key" class="p-4">
                <div class="flex flex-row -mx-2 flex-wrap justify-between">
                    <div class="w-3/4 px-2 break-words">
                        <DashboardLabel>Voice Intro</DashboardLabel>

                        <p class="mt-4">
                            <audio
                                v-if="audio"
                                controls
                                class="focus:outline-none max-w-xs"
                                :src="audio.signedUrl ? audio.signedUrl : resolveAudioUrl(audio, member.hashMemberID)"
                            ></audio>
                        </p>
                    </div>
                    <div>
                        <SecondaryButton
                            text="Reject voice intro"
                            class="m-2"
                            :handle-click="updateProfile('/v1/remove-profile-audio', 'post', null, 'Deleted Audio')"
                            :handle-error="handleButtonError"
                        ></SecondaryButton>
                    </div>
                </div>
            </div>

            <div class="p-4">
                <div class="flex flex-row -mx-2 flex-wrap justify-between">
                    <div class="w-3/4 px-2 break-words">
                        <DashboardLabel>Status Message</DashboardLabel>

                        <p class="mt-4">{{ profile.statusMessage }}</p>

                        <div class="mt-2">
                            <span
                                class="text-pink-muzmatch no-underline hover:underline cursor-pointer font-bold text-sm"
                                @click="googleTranslate(profile.statusMessage)"
                            >
                                Translate
                            </span>
                        </div>
                    </div>
                    <div>
                        <SecondaryButton
                            text="Reject status message"
                            class="m-2"
                            :handle-click="
                                updateProfile(
                                    '/v1/reject-profile',
                                    'post',
                                    { check_status_message: true },
                                    'Rejected status.',
                                )
                            "
                            :handle-error="handleButtonError"
                        />
                    </div>
                </div>
            </div>

            <div class="p-4">
                <div class="flex flex-row -mx-2 flex-wrap justify-between">
                    <div class="w-3/4 px-2 break-words">
                        <DashboardLabel>About Me</DashboardLabel>

                        <p class="mt-4">
                            <span v-if="showMoreAboutMe">
                                {{ profile.longDescription }}
                            </span>

                            <span v-else>
                                {{ profile.longDescription | limit }}
                            </span>

                            <span
                                v-if="profile.longDescription && profile.longDescription.length > 360"
                                class="text-pink-muzmatch cursor-pointer"
                                @click="showMoreAboutMe = !showMoreAboutMe"
                            >
                                {{ showMoreAboutMe ? 'Less' : 'More' }}
                            </span>
                        </p>

                        <div class="mt-2">
                            <span
                                class="text-pink-muzmatch no-underline hover:underline cursor-pointer font-bold text-sm"
                                @click="googleTranslate(profile.longDescription)"
                            >
                                Translate
                            </span>
                        </div>
                    </div>

                    <div>
                        <SecondaryButton
                            text="Reject about me"
                            class="m-2"
                            :handle-click="updateProfile('/v1/reject-about-me', 'post', null, 'Rejected about me.')"
                            :handle-error="handleButtonError"
                        />
                    </div>
                </div>
            </div>

            <div class="p-4">
                <div class="flex flex-row -mx-2 flex-wrap justify-between">
                    <div class="w-3/4 px-2 break-words">
                        <DashboardLabel>Marriage Intentions</DashboardLabel>

                        <p class="mt-4">
                            <span v-if="showMoreMarriageIntentions">
                                {{ profile.marriageHorizonInfo }}
                            </span>
                            <span v-else>
                                {{ profile.marriageHorizonInfo | limit }}
                            </span>

                            <span
                                v-if="profile.marriageHorizonInfo && profile.marriageHorizonInfo.length > 360"
                                class="text-pink-muzmatch cursor-pointer"
                                @click="showMoreMarriageIntentions = !showMoreMarriageIntentions"
                            >
                                {{ showMoreMarriageIntentions ? 'Less' : 'More' }}
                            </span>
                        </p>

                        <div class="mt-2">
                            <span
                                class="text-pink-muzmatch no-underline hover:underline cursor-pointer font-bold text-sm"
                                @click="googleTranslate(profile.marriageHorizonInfo)"
                            >
                                Translate
                            </span>
                        </div>
                    </div>

                    <div>
                        <SecondaryButton
                            text="Reject marriage intentions"
                            class="m-2"
                            :handle-click="
                                updateProfile(
                                    '/v1/reject-marriage-intentions',
                                    'post',
                                    null,
                                    'Rejected marriage intentions.',
                                )
                            "
                            :handle-error="handleButtonError"
                        />
                    </div>
                </div>
            </div>
        </DashboardSectionCard>

        <DashboardSectionCard v-if="profile.social" class="mb-4">
            <a name="profile"></a>

            <div class="border-b flex items-center justify-between">
                <span class="p-4">
                    <DashboardLabel>Social Profile</DashboardLabel>
                </span>
            </div>

            <div class="p-4">
                <DashboardLabel class="flex my-3">Profile Picture</DashboardLabel>
                <img
                    class="profile-photo w-32 rounded-lg cursor-pointer h-32"
                    :src="profile.social.avatarUrl + '=w192'"
                    @error="$event.target.src = require('../../assets/mm2.png')"
                />
            </div>
            <div class="p-4 pt-0">
                <DashboardLabel class="flex my-3">Name</DashboardLabel>
                <span>{{ profile.social.firstName }} {{ profile.social.lastName }}</span>
            </div>
            <div class="p-4 pt-0">
                <DashboardLabel class="flex my-3">Username</DashboardLabel>
                <span>@{{ profile.social.username }}</span>
            </div>
            <div class="p-4 pt-0">
                <DashboardLabel class="flex my-3">Bio</DashboardLabel>
                <span>{{ profile.social.bio }}</span>
            </div>
        </DashboardSectionCard>

        <DashboardSectionCard
            v-if="profile.social && (hasPermissionForSocialEdit || contextType.includes('CONTEXT_TYPE_TASK_SOCIAL'))"
            class="relative mb-4"
        >
            <a id="edit-profile"></a>

            <div
                class="border-b flex flex-wrap justify-between items-center cursor-pointer"
                @click.self="showSocialEditProfile = !showSocialEditProfile"
            >
                <div class="p-4" @click="showSocialEditProfile = !showSocialEditProfile">
                    <DashboardLabel>Edit social profile</DashboardLabel>

                    <icon class="ml-2" :icon="showSocialEditProfile ? 'chevron-up' : 'chevron-down'" />
                </div>
            </div>

            <div v-if="showSocialEditProfile">
                <div class="p-4 flex flex-row flex-wrap justify-start">
                    <div class="m-2 flex flex-row items-end flex-wrap">
                        <div class="flex flex-col mr-4">
                            <label class="my-1">Profile Picture</label>
                            <img
                                class="profile-photo w-32 rounded-lg cursor-pointer h-32"
                                :src="profile.social.avatarUrl + '=w192'"
                                @error="$event.target.src = require('../../assets/mm2.png')"
                            />
                        </div>
                        <SecondaryButton
                            text="Reject"
                            class="mt-2"
                            :handle-error="handleButtonError"
                            :handle-click="() => rejectSocialAttribute('SOCIAL_PROFILE_COMPONENT_PROFILE_PICTURE')"
                        ></SecondaryButton>
                    </div>
                </div>
                <div class="p-4 pt-0 flex flex-row flex-wrap justify-start">
                    <div class="m-2 flex flex-row items-end flex-wrap">
                        <div class="flex flex-col mr-4">
                            <label class="my-1">First Name</label>
                            <input
                                v-model="profile.social.firstName"
                                type="text"
                                class="p-2 border border-grey-300 rounded sm:w-64"
                            />
                        </div>
                        <SecondaryButton
                            text="Reject"
                            class="mt-2"
                            :handle-error="handleButtonError"
                            :handle-click="() => rejectSocialAttribute('SOCIAL_PROFILE_COMPONENT_NAME')"
                        ></SecondaryButton>
                    </div>
                    <div class="m-2 flex flex-row items-end flex-wrap">
                        <div class="flex flex-col mr-4">
                            <label class="my-1">Last Name</label>
                            <input
                                v-model="profile.social.lastName"
                                type="text"
                                class="p-2 border border-grey-300 rounded sm:w-64"
                            />
                        </div>
                        <SecondaryButton
                            text="Reject"
                            class="mt-2"
                            :handle-error="handleButtonError"
                            :handle-click="() => rejectSocialAttribute('SOCIAL_PROFILE_COMPONENT_NAME')"
                        ></SecondaryButton>
                    </div>
                </div>
                <div class="p-4 pt-0 flex flex-row flex-wrap justify-start">
                    <div class="m-2 flex flex-row items-end flex-wrap">
                        <div class="flex flex-col mr-4">
                            <label class="my-1">Username</label>
                            <input
                                v-model="profile.social.username"
                                type="text"
                                class="p-2 border border-grey-300 rounded sm:w-64"
                            />
                        </div>
                        <SecondaryButton
                            text="Reject"
                            class="mt-2"
                            :handle-error="handleButtonError"
                            :handle-click="() => rejectSocialAttribute('SOCIAL_PROFILE_COMPONENT_USERNAME')"
                        ></SecondaryButton>
                    </div>
                </div>
                <div class="p-4 pt-0 flex flex-row flex-wrap justify-start">
                    <div class="m-2 flex flex-row items-end flex-wrap">
                        <div class="flex flex-col mr-4">
                            <label class="my-1">Bio</label>
                            <textarea
                                v-model="profile.social.bio"
                                class="border border-grey-300 rounded w-96 h-96"
                                style="height: 180px"
                            ></textarea>
                        </div>
                        <SecondaryButton
                            text="Reject"
                            class="mt-2"
                            :handle-error="handleButtonError"
                            :handle-click="() => rejectSocialAttribute('SOCIAL_PROFILE_COMPONENT_BIO')"
                        ></SecondaryButton>
                    </div>
                </div>
            </div>
        </DashboardSectionCard>

        <DashboardSectionCard class="mb-4">
            <a id="block-actions"></a>

            <div class="p-4 border-b flex justify-between items-center">
                <DashboardLabel>Block Actions</DashboardLabel>

                <div>
                    <SecondaryButton
                        v-if="profile.pendingBlockJson && isPendingBlockAdmin"
                        class="mr-1 my-1"
                        text="Undo"
                        :handle-error="handleButtonError"
                        :handle-click="
                            updateProfile('/v1/undo-pending-block', 'post', null, 'Profile Pending Block Undone')
                        "
                    />
                    <PrimaryButton
                        v-if="profile.pendingBlockJson && isPendingBlockAdmin"
                        class="mr-1 my-1"
                        text="Confirm"
                        :handle-error="handleButtonError"
                        :handle-click="handleConfirmPending"
                    />
                    <span
                        v-if="blockState"
                        class="font-bold p-2 px-3 rounded-full text-center inline-block"
                        :class="blockButtonColour"
                    >
                        {{ blockState }}

                        <span v-if="isBlocked && blockStateNotes">
                            <icon
                                :icon="showBlockStateNotes ? 'chevron-up' : 'chevron-down'"
                                class="ml-1"
                                @click="toggleBlockStateNotes"
                            ></icon>
                        </span>
                    </span>
                </div>
            </div>

            <div v-if="isBlocked && blockStateNotes && showBlockStateNotes" class="pt-4 px-4">
                <h3>Current Block State</h3>
                <p v-if="blockStateTimestamp" class="my-1">
                    <span class="font-bold">Date:</span>
                    {{ dateFormat(blockStateTimestamp) }} ·
                    {{ timeAgo(blockStateTimestamp) }}
                </p>
                <p v-if="blockStateAction" class="my-1">
                    <span class="font-bold">Action:</span>
                    {{ blockStateAction }}
                </p>
                <p v-if="blockStateType" class="my-1">
                    <span class="font-bold">Type:</span>
                    {{ blockStateType }}
                </p>
                <p v-if="blockStateComment" class="my-1">
                    <span class="font-bold">Comment:</span>
                    {{ blockStateComment }}
                </p>
            </div>

            <div class="p-4">
                <div v-if="blockButtons.length > 0" class="mb-4">
                    <div class="ml-2 mb-4 text-xl text-pink-muzmatch">
                        Block Member
                        <span v-if="isBlocked">(Already Blocked)</span>
                        <icon v-if="isLoading" class="animate-spin ml-4" icon="spinner" />
                    </div>
                    <SecondaryButton
                        v-for="(button, index) in blockButtons"
                        :key="index"
                        class="mr-1 mb-1"
                        :text="button.text"
                        :handle-click="handleAction(button)"
                    ></SecondaryButton>
                </div>

                <div v-if="isBlocked && unblockButtons.length > 0">
                    <div class="ml-2 mb-4 text-xl text-pink-muzmatch">Unblock Member</div>
                    <SecondaryButton
                        v-for="(button, index) in unblockButtons"
                        :key="index"
                        class="mr-1 mb-1"
                        :text="button.text"
                        :handle-click="handleAction(button)"
                    ></SecondaryButton>
                </div>

                <Modal v-if="this.showBlockModal" :click-overlay="toggleBlockModalWrapper()">
                    <template slot="header">
                        <h1 class="text-lg mt-1 mb-2">{{ currentBlockAction }}</h1>
                    </template>
                    <template slot="body">
                        <div v-if="this.currentBlockAction !== 'Unblock account'">
                            <p class="my-2 font-bold text-sm">Block Type</p>
                            <RadioButtons
                                v-if="blockTypeButtons.length > 0"
                                :buttons="blockTypeButtons"
                                @selectButton="selectButton"
                            ></RadioButtons>
                            <p v-else class="text-sm text-red">
                                Error: Please create a shortcut with type 'BLOCK' in the shortcut tool.
                            </p>
                        </div>

                        <div class="mt-4">
                            <label for="block-reason" class="text-pink-muzmatch font-bold text-sm">
                                Additional Notes (internal)
                            </label>
                            <input
                                id="block-reason"
                                v-model="currentBlockNotes"
                                type="text"
                                placeholder="Notes..."
                                class="flex shortcut-accessible w-full my-2 p-2 rounded border border-grey-300 focus:outline-none focus:border-grey-500 CONTEXT_ACTION_MEMBER_BLOCK_REASON"
                                :class="contextType"
                                :maxlength="blockCharacterLimit"
                            />
                            <div>
                                <span>{{ this.currentBlockNotes.length }}/{{ this.blockCharacterLimit }}</span>
                                <span
                                    v-if="blockCharacterLimit - currentBlockNotes.length < 0"
                                    class="text-red text-sm"
                                >
                                    Character limit reached
                                </span>
                            </div>
                        </div>

                        <PrimaryButton
                            text="Confirm"
                            class="mt-2"
                            :handle-click="handleBlockForm"
                            :disabled="
                                this.currentBlockAction !== 'Unblock account' &&
                                (blockCharacterLimit - currentBlockNotes.length < 0 || !currentBlockType)
                            "
                        ></PrimaryButton>

                        <p v-if="showBlockError" class="mt-5 text-sm font-bold">
                            Error: You must select a type of block.
                        </p>
                    </template>
                </Modal>

                <div class="m-2 flex flex-row items-end flex-wrap">
                    <div class="flex flex-col mr-4">
                        <label class="my-1">Behaviour Report</label>
                        <input
                            v-model="messageReport"
                            type="text"
                            placeholder="Reason..."
                            class="p-2 border border-grey-300 rounded sm:w-64"
                        />
                    </div>
                    <SecondaryButton
                        text="Create"
                        class="mt-2"
                        :handle-error="handleButtonError"
                        :handle-click="handleCreateReport"
                    ></SecondaryButton>
                </div>
            </div>
        </DashboardSectionCard>

        <DashboardSectionCard class="mb-4">
            <a id="actions"></a>

            <div class="p-4 border-b cursor-pointer" @click.self="showActions = !showActions">
                <DashboardLabel @click.native="showActions = !showActions">Actions</DashboardLabel>

                <icon
                    class="ml-2"
                    :icon="showActions ? 'chevron-up' : 'chevron-down'"
                    @click="showActions = !showActions"
                ></icon>
            </div>

            <div v-if="showActions" class="p-4">
                <DashboardLabel class="flex my-3">Account</DashboardLabel>

                <SecondaryButton
                    v-for="(button, index) in buttons.account"
                    :key="index"
                    class="mr-1 mb-1"
                    :confirmed="!button.modalToggle"
                    :text="button.text"
                    :confirm-message="button.warning"
                    :handle-click="
                        !!button.modalToggle
                            ? button.modalToggle
                            : updateProfile(button.action, button.requestType, button.data, 'Success!')
                    "
                ></SecondaryButton>

                <div class="mt-6">
                    <DashboardLabel class="flex my-3">Messaging</DashboardLabel>

                    <SecondaryButton
                        v-for="(button, index) in buttons.messaging"
                        :key="index"
                        class="mr-1 mb-1"
                        :confirmed="true"
                        :text="button.text"
                        confirm-message="Are you sure?"
                        :handle-click="updateProfile(button.action, button.requestType, null, 'Success!')"
                    ></SecondaryButton>
                </div>

                <div class="mt-6">
                    <DashboardLabel class="flex my-3">Subscription</DashboardLabel>

                    <SecondaryButton
                        class="mr-1 mb-1"
                        text="Toggle admin spoof"
                        :confirmed="true"
                        confirm-message="This will toggle admin spoofing for the user."
                        :handle-click="updateProfile('/v1/admin-spoof', 'post', null, 'Success!')"
                    ></SecondaryButton>

                    <DropdownButton class="mr-1 mb-1" text="Add 1 credit of">
                        <DropdownItem
                            :confirmed="true"
                            confirm-message="This will give the user a free additional Instant Chat credit."
                            :handle-click="updateProfile('/v1/instant-match-credit', 'post', null, 'Success!')"
                        >
                            Instant Chat
                        </DropdownItem>
                        <DropdownItem
                            :confirmed="true"
                            confirm-message="This will give the member a free additional Boost credit."
                            :handle-click="updateProfile('/v1/boost-credit', 'post', null, 'Success!')"
                        >
                            Boost
                        </DropdownItem>
                        <DropdownItem
                            :confirmed="true"
                            confirm-message="This will give the member a free additional Dating Coach credit."
                            :handle-click="updateProfile('/v1/dating-coach-credit', 'post', null, 'Success!')"
                        >
                            Dating Coach
                        </DropdownItem>
                        <DropdownItem
                            :confirmed="true"
                            confirm-message="This will give the member a free additional Event credit."
                            :handle-click="updateProfile('/v1/event-credit', 'post', null, 'Success!')"
                        >
                            Event
                        </DropdownItem>
                    </DropdownButton>

                    <DropdownButton class="mr-1 mb-1" text="Apply free gold">
                        <DropdownItem :confirmed="true" :handle-click="applyGold(1, 'day')">1 day</DropdownItem>
                        <DropdownItem :confirmed="true" :handle-click="applyGold(7, 'day')">7 days</DropdownItem>

                        <DropdownItem
                            v-for="month in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
                            :key="month"
                            :confirmed="true"
                            :handle-click="applyGold(month, 'month')"
                        >
                            {{ month }} month{{ month > 1 ? 's' : '' }}
                        </DropdownItem>
                    </DropdownButton>

                    <SecondaryButton
                        class="mr-1 mb-1"
                        text="Disable gold"
                        :confirmed="true"
                        confirm-message="This will remove gold from their account, regardless if they paid for it or not."
                        :handle-click="updateProfile('/v1/disable-premium', 'post', null, 'Success!')"
                    ></SecondaryButton>

                    <SecondaryButton
                        class="mr-1 mb-1"
                        text="Email Stripe Manage Sub Link"
                        :confirmed="true"
                        confirm-message="This will send the user an email with a link where they can manage their subscription"
                        :handle-click="
                            updateProfile(
                                '/v1/send-stripe-sub-manage-email',
                                'post',
                                { member_id: profile.memberID },
                                'Success!',
                            )
                        "
                    ></SecondaryButton>

                    <DropdownButton
                        v-if="canUseVerifiedStatusDropdown"
                        class="mr-1 mb-1"
                        text="Update Verification Status"
                    >
                        <DropdownItem
                            v-for="verifiedStatus in verifiedStatusList"
                            :key="verifiedStatus"
                            class="w-40"
                            :confirmed="true"
                            :handle-click="
                                updateProfile(
                                    '/v1/update-verified-status',
                                    'post',
                                    { verifiedStatus: verifiedStatus },
                                    'Verified status was successfully updated.',
                                )
                            "
                        >
                            {{ verifiedStatus | startCase }}
                        </DropdownItem>
                    </DropdownButton>
                </div>

                <div class="mt-6">
                    <DashboardLabel class="flex my-3">Other</DashboardLabel>

                    <SecondaryButton
                        v-for="(button, index) in buttons.other"
                        v-show="shouldButtonShow(button)"
                        :key="index"
                        class="mr-1 mb-1"
                        :confirmed="!button.modalToggle"
                        :text="button.text"
                        confirm-message="Are you sure?"
                        :handle-click="
                            !!button.modalToggle
                                ? button.modalToggle
                                : updateProfile(button.action, button.requestType, null, 'Success!')
                        "
                    />
                </div>
            </div>
        </DashboardSectionCard>

        <DebugOptionSection
            v-if="hasPermissionForDebugOption"
            class="relative mt-4"
            :member="member"
            @refresh="$emit('refresh')"
        />

        <DashboardSectionCard class="mb-4">
            <a id="messages"></a>

            <div class="p-4 border-b cursor-pointer" @click.self="showMessages = !showMessages">
                <DashboardLabel @click.native="showMessages = !showMessages">Messages</DashboardLabel>

                <icon
                    class="ml-2"
                    :icon="showMessages ? 'chevron-up' : 'chevron-down'"
                    @click="showMessages = !showMessages"
                ></icon>
            </div>

            <div v-if="showMessages" class="sm:flex sm:-mx-2 sm:p-4 pt-4">
                <div class="sm:w-1/2 mx-2 sm:my-0 my-2">
                    <textarea
                        v-model="message"
                        class="shortcut-accessible p-2 w-full border border-grey-300 rounded CONTEXT_ACTION_MEMBER_DIRECT_MESSAGE"
                        :class="contextType"
                        rows="4"
                    ></textarea>

                    <SecondaryButton
                        text="Send Message"
                        :handle-error="handleButtonError"
                        :handle-click="
                            updateProfile('/v1/send-message', 'post', { message: this.message }, 'Message sent.')
                        "
                    ></SecondaryButton>
                </div>

                <div class="sm:w-1/2 mx-2 sm:my-0 my-2">
                    <textarea
                        v-model="email"
                        class="shortcut-accessible p-2 w-full border border-grey-300 rounded CONTEXT_ACTION_MEMBER_DIRECT_EMAIL"
                        :class="contextType"
                        rows="4"
                    ></textarea>

                    <SecondaryButton
                        text="Send Email"
                        :handle-error="handleButtonError"
                        :handle-click="
                            updateProfile('/v1/send-email', 'post', { message_email: this.email }, 'Email sent.')
                        "
                    ></SecondaryButton>
                </div>
            </div>
        </DashboardSectionCard>

        <DashboardSectionCard class="relative mb-4">
            <a id="edit-profile"></a>

            <div
                class="border-b flex flex-wrap justify-between items-center cursor-pointer"
                @click.self="showEditProfile = !showEditProfile"
            >
                <div class="p-4" @click="showEditProfile = !showEditProfile">
                    <DashboardLabel>Edit profile</DashboardLabel>

                    <icon class="ml-2" :icon="showEditProfile ? 'chevron-up' : 'chevron-down'" />
                </div>

                <PrimaryButton
                    v-if="showEditProfile"
                    text="Flip Gender"
                    class="m-2"
                    :confirmed="true"
                    :handle-error="handleButtonError"
                    confirm-message="This will change the user's gender!"
                    :handle-click="updateProfile('/v1/flip-gender', 'post', null, 'Gender updated.')"
                ></PrimaryButton>
            </div>

            <div v-if="showEditProfile" class="p-4 flex flex-row flex-wrap justify-start">
                <div class="m-2 flex flex-row items-end flex-wrap">
                    <div class="flex flex-col mr-4">
                        <label class="my-1">Profile Picture</label>
                        <input v-model="nickname" type="text" class="p-2 border border-grey-300 rounded sm:w-64" />
                    </div>
                    <SecondaryButton
                        text="Save"
                        class="mt-2"
                        :handle-error="handleButtonError"
                        :handle-click="
                            updateProfile(
                                '/v1/amend-nickname',
                                'post',
                                { newNickname: this.nickname },
                                'Nickname updated.',
                            )
                        "
                    ></SecondaryButton>
                </div>
                <div class="m-2 flex flex-row items-end flex-wrap">
                    <div class="flex flex-col mr-4">
                        <label class="my-1">Nickname</label>
                        <input v-model="nickname" type="text" class="p-2 border border-grey-300 rounded sm:w-64" />
                    </div>
                    <SecondaryButton
                        text="Save"
                        class="mt-2"
                        :handle-error="handleButtonError"
                        :handle-click="
                            updateProfile(
                                '/v1/amend-nickname',
                                'post',
                                { newNickname: this.nickname },
                                'Nickname updated.',
                            )
                        "
                    ></SecondaryButton>
                </div>

                <div class="m-2 flex flex-row items-end flex-wrap">
                    <div class="flex flex-col mr-4">
                        <label class="my-1">Birthday ({{ currentAge }})</label>
                        <div class="flex flex-row flex-wrap sm:w-64">
                            <input
                                v-model="dob.day"
                                type="number"
                                class="mr-1 p-2 text-center border border-grey-300 rounded w-12 sm:w-16"
                            />
                            <input
                                v-model="dob.month"
                                type="number"
                                class="mr-1 p-2 text-center border border-grey-300 rounded w-12 sm:w-16"
                            />
                            <input
                                v-model="dob.year"
                                type="number"
                                class="p-2 text-center border border-grey-300 rounded w-20"
                            />
                        </div>
                    </div>
                    <SecondaryButton
                        text="Save"
                        class="mt-2"
                        :disabled="dobButtonDisabled"
                        :handle-error="handleButtonError"
                        :handle-click="
                            updateProfile(
                                '/v1/amend-dob',
                                'post',
                                { dobDAY: this.dob.day, dobMONTH: this.dob.month, dobYEAR: this.dob.year },
                                'Birthday updated.',
                            )
                        "
                    />
                    <p v-if="dobButtonDisabled" class="ml-2 pb-2 text-red-dark">
                        Member is ID Verified (need permission)
                    </p>
                </div>

                <div class="m-2 flex flex-row items-end flex-wrap">
                    <div class="flex flex-col mr-4">
                        <label class="my-1">Email</label>
                        <input v-model="emailAddress" type="text" class="p-2 border border-grey-300 rounded sm:w-64" />
                    </div>
                    <SecondaryButton
                        text="Save"
                        class="mt-2"
                        :handle-error="handleButtonError"
                        :handle-click="
                            updateProfile(
                                '/v1/amend-email-address',
                                'post',
                                { newEmailAddress: this.emailAddress },
                                'Email updated.',
                            )
                        "
                    ></SecondaryButton>
                </div>

                <div class="m-2 flex flex-row items-end flex-wrap">
                    <div class="flex flex-col mr-4">
                        <label class="my-1">Phone Number</label>
                        <input v-model="phoneNumber" type="text" class="p-2 border border-grey-300 rounded sm:w-64" />
                    </div>
                    <SecondaryButton
                        text="Save"
                        class="mt-2"
                        :handle-error="handleButtonError"
                        :handle-click="
                            updateProfile(
                                '/v1/amend-phone-number',
                                'post',
                                { newPhoneNumber: this.phoneNumber },
                                'Phone number updated.',
                            )
                        "
                    ></SecondaryButton>
                    <SecondaryButton
                        text="Remove"
                        class="ml-2 mt-2"
                        :handle-error="handleButtonError"
                        :handle-click="updateProfile('/v1/remove-phone-number', 'post', 'Phone number removed.')"
                    ></SecondaryButton>
                </div>

                <div class="m-2 flex flex-row items-end flex-wrap">
                    <div class="flex flex-col mr-4">
                        <label class="my-1">Job Title</label>
                        <input v-model="jobTitle" type="text" class="p-2 border border-grey-300 rounded sm:w-64" />
                    </div>
                    <SecondaryButton
                        text="Save"
                        class="mt-2"
                        :handle-error="handleButtonError"
                        :handle-click="
                            updateProfile(
                                '/v1/amend-job-title',
                                'post',
                                { newJobTitle: this.jobTitle, locale: this.locale },
                                'Job Title updated.',
                            )
                        "
                    ></SecondaryButton>
                </div>

                <div class="m-2 flex flex-row items-end flex-wrap">
                    <div class="flex flex-col mr-4">
                        <label class="my-1">Employer</label>
                        <input
                            v-model="companyOfEmployment"
                            type="text"
                            class="p-2 border border-grey-300 rounded sm:w-64"
                        />
                    </div>
                    <SecondaryButton
                        text="Save"
                        class="mt-2"
                        :handle-error="handleButtonError"
                        :handle-click="
                            updateProfile(
                                '/v1/amend-employer',
                                'post',
                                { newCompanyOfEmployment: this.companyOfEmployment, locale: this.locale },
                                'Employer updated.',
                            )
                        "
                    ></SecondaryButton>
                </div>

                <div class="m-2 flex flex-row items-end flex-wrap">
                    <div class="flex flex-col mr-4">
                        <label class="my-1">Photo visibility ({{ member.photoVisibility }})</label>
                        <DropdownButton class="mt-1 mr-2" text="Photo visibility update">
                            <DropdownItem
                                v-for="(value, key) in photoVisibilityDropdown"
                                :key="key"
                                class="sm:w-48"
                                :confirmed="true"
                                :handle-error="handleButtonError"
                                :handle-click="
                                    updateProfile(
                                        '/v1/amend-photo-visibility',
                                        'post',
                                        { photoVisibility: key },
                                        'Photos updated.',
                                    )
                                "
                            >
                                {{ value }}
                            </DropdownItem>
                        </DropdownButton>
                    </div>
                </div>
            </div>
        </DashboardSectionCard>

        <DashboardSectionCard>
            <a id="reports"></a>

            <DashboardTabs
                v-if="profile"
                :chat-hash-id="chatHashId"
                :profile="profile"
                :context-type="contextType"
                @send-message-history="sendMessageHistory"
                @refresh="$emit('refresh')"
            />
        </DashboardSectionCard>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import EventBus from '../../helpers/eventBus';
    import DeleteButton from '../Member/DeleteButton';
    import { dashboardAxios, socialModerationAxios } from '../../../axios.config';
    import PrimaryButton from '../Buttons/PrimaryButton';
    import SecondaryButton from '../Buttons/SecondaryButton';
    import DropdownButton from '../Buttons/Dropdown/DropdownButton';
    import {
        formatDuplicateMembers,
        resolveAudioUrl,
        resolvePhotoUrl,
        resolveVerificationPhotoUrl,
        resolveVideoUrl,
        time,
    } from '../../helpers/DashboardUtils';
    import DropdownItem from '../Buttons/Dropdown/DropdownItem';
    import { getMemberRoute } from '../../helpers/RedirectToMember';
    import ProfilePhotoModal from '../Modal/ProfilePhotoModal';
    import RadioButton from '../Buttons/RadioButton';
    import RadioButtons from '@/components/Buttons/RadioButtons';
    import DashboardLabel from '@/components/Labels/DashboardLabel';
    import { captureException } from '@sentry/minimal';
    import { User } from '@/classes/User';
    import DashboardMemberName from '@/components/Dashboard/DashboardMemberName';
    import DashboardSectionCard from '@/components/Cards/DashboardSectionCard';
    import DashboardHints from '@/components/Dashboard/DashboardHints';
    import DashboardSummaryLabels from '@/components/Dashboard/DashboardSummaryLabels';
    import DashboardFlag from '@/components/Images/DashboardFlag';
    import IdentityVerificationCard from '@/pages/TaskManager/IdentityVerificationCard';
    import VueQRCodeComponent from 'vue-qrcode-component';
    import DebugOptionSection from '@/components/Cards/DebugOption/DebugOptionSection';
    import Vue from 'vue';

    Vue.component('QrCode', VueQRCodeComponent);

    export default {
        name: 'DashboardCard',

        components: {
            DashboardLabel,
            RadioButtons,
            DropdownItem,
            DeleteButton,
            PrimaryButton,
            DropdownButton,
            SecondaryButton,
            ProfilePhotoModal,
            RadioButton,
            DashboardMemberName,
            DashboardSectionCard,
            DashboardHints,
            DashboardSummaryLabels,
            DashboardFlag,
            IdentityVerificationCard,
            DebugOptionSection,
        },
        filters: {
            limit(value) {
                if (!value) {
                    return value;
                }

                if (value.toString().length < 360) {
                    return value.toString();
                }

                return value.toString().slice(0, 360) + '...';
            },

            startCase(string) {
                return _.startCase(string);
            },
        },
        props: {
            member: {
                type: Object,
                required: false,
            },

            memberHashId: {
                type: String,
                required: false,
            },

            chatHashId: {
                type: String,
                default: null,
            },

            evenSplit: {
                type: Boolean,
                default: false,
            },

            contextType: {
                type: String,
                required: true,
            },

            contextTaskId: {
                type: Number,
                default: null,
            },

            hints: {
                type: Object,
                default: () => {},
            },

            identityVerification: {
                type: Object,
                required: false,
                default: () => {},
            },

            reporterId: {
                type: Number,
                default: 0,
            },
        },

        data() {
            return {
                isLoading: false,
                profile: null,
                showDuplicateAccounts: false,
                showMemberDetails: false,
                showFlags: false,
                showMoreAboutMe: false,
                showMoreMarriageIntentions: false,
                showReason: false,
                message: 'Salaam,',
                email: 'Salaam, ',
                locale: 'EN',
                nickname: '',
                dob: {
                    day: '',
                    month: '',
                    year: '',
                },
                text: '',
                emailAddress: '',
                phoneNumber: '',
                jobTitle: '',
                companyOfEmployment: '',
                messageReport: '',
                swipeAllocationUsed: null,

                blockShortcuts: [],
                showBlockModal: false,
                showBlockError: false,
                currentBlockAction: '',
                currentBlockType: '',
                currentBlockShortcut: 0,
                currentBlockNotes: '',
                showBlockStateNotes: false,
                blockStateNotes: null,

                showModal: false,
                googleTranslateUrl: 'https://translate.google.com/#auto/en/',

                showIdVerification: false,
                showActions: false,
                showMessages: false,
                showEditProfile: false,
                showSocialEditProfile: false,

                currentImgIndex: 0,
                nextImgIndex: 1,

                previousAccounts: [],
                showPrevAccounts: false,

                blockedFields: [],
                showBlockedFields: false,

                verifiedStatusList: [],
                photoVisibilityDropdown: {
                    PUBLIC: 'Public',
                    PUBLIC_ON_LIKE: 'Public on like',
                    PRIVATE: 'Private',
                },
            };
        },

        computed: {
            ...mapGetters({
                staff: 'staff/getStaff',
            }),

            hasPermissionForDebugOption() {
                return User.make(this.staff).can('members:debug-option');
            },

            hasPermissionForSocialEdit() {
                return User.make(this.staff).can('account:social-edit');
            },

            blockState() {
                if (this.profile.permanentlyBlocked === '1') return 'PERMANENTLY BLOCKED';

                if (this.profile.profileState.includes('TEMPORARILY BLOCKED')) return 'TEMPORARILY BLOCKED';

                if (this.profile.pendingBlockJson) {
                    return 'PENDING BLOCK';
                }

                return 'NORMAL ACCESS';
            },

            currentAge() {
                if (!(this.dob.day && this.dob.month && this.dob.year)) {
                    return '';
                }

                let birthday = moment(this.dob.month + '-' + this.dob.day + '-' + this.dob.year);

                return moment().diff(birthday, 'year') || 'Invalid Date';
            },

            duplicateAccountCount() {
                let members = this.duplicateAccounts;

                if (!members) {
                    return 0;
                }

                return members.length;
            },

            activeDupeAccounts() {
                let members = this.duplicateAccounts;

                if (!members) {
                    return 0;
                }

                return members.filter((m) => this.getState(m) === 'ACTIVE').length;
            },

            inactiveDupeAccounts() {
                let members = this.duplicateAccounts;

                if (!members) {
                    return 0;
                }

                return members.filter((m) => this.getState(m) === 'INACTIVE').length;
            },

            blockedDupeAccounts() {
                let members = this.duplicateAccounts;

                if (!members) {
                    return 0;
                }

                return members.filter((m) => this.getState(m) === 'BLOCKED').length;
            },

            duplicateAccounts() {
                const group = [
                    ...this.mapDupes(this.profile.otherMembersWithSameIDFA || [], 'IDFA'),
                    ...this.mapDupes(this.profile.otherMembersWithSameUDID || [], 'UDID'),
                ];

                return group.filter((item, index, self) => index === self.findIndex((i) => i.hash === item.hash));
            },

            previousAccountList() {
                if (this.previousAccounts.length === 0) {
                    return [];
                }

                return this.previousAccounts.filter(
                    (item, index, self) => index === self.findIndex((i) => i.memberID === item.memberID),
                );
            },

            blockedFieldList() {
                if (this.blockedFields.length === 0) {
                    return [];
                }

                return this.blockedFields.filter(
                    (item, index, self) => index === self.findIndex((i) => i.memberID === item.memberID),
                );
            },

            audio() {
                return {
                    key:
                        this.profile &&
                        this.profile.mediaState &&
                        this.profile.mediaState.complete.audio &&
                        this.profile.mediaState.complete.audio.profile &&
                        this.profile.mediaState.complete.audio.profile.visible,
                    signedUrl:
                        this.profile &&
                        this.profile.mediaSigned &&
                        this.profile.mediaSigned.audio &&
                        this.profile.mediaSigned.audio.url,
                };
            },

            photos() {
                return [
                    {
                        type: 'photoVerification',
                        is: 'image',
                        key:
                            this.profile &&
                            this.profile.mediaState.complete &&
                            this.profile.mediaState.complete.image.verification &&
                            this.profile.mediaState.complete.image.verification.visible,
                        signedUrl:
                            this.profile &&
                            this.profile.mediaSigned &&
                            this.profile.mediaSigned.verification &&
                            this.profile.mediaSigned.verification.url,
                        label: 'selfie',
                        btnLabel: 'Reject selfie',
                        model: 'selfie',
                        action: '/v1/reject-selfie',
                    },
                    {
                        type: 'photo1',
                        is: 'image',
                        key:
                            this.profile &&
                            this.profile.mediaState.complete &&
                            this.profile.mediaState.complete.image.profile &&
                            this.profile.mediaState.complete.image.profile[1] &&
                            this.profile.mediaState.complete.image.profile[1].visible,
                        signedUrl:
                            this.profile &&
                            this.profile.mediaSigned &&
                            this.profile.mediaSigned.profile &&
                            this.profile.mediaSigned.profile[1] &&
                            this.profile.mediaSigned.profile[1].url,
                        label: 'main photo',
                        btnLabel: 'Reject main photo',
                        model: 'photo1',
                        action: '/v1/remove-main-photo',
                    },
                    {
                        type: 'video',
                        is: 'video',
                        key:
                            this.profile &&
                            this.profile.mediaState &&
                            this.profile.mediaState.complete &&
                            this.profile.mediaState.complete.video &&
                            this.profile.mediaState.complete.video.profile &&
                            this.profile.mediaState.complete.video.profile.visible,
                        signedUrl:
                            this.profile &&
                            this.profile.mediaSigned &&
                            this.profile.mediaSigned.video &&
                            this.profile.mediaSigned.video.url,
                        label: 'video',
                        btnLabel: 'Reject Video',
                        model: 'video',
                        action: '/v1/remove-profile-video',
                    },
                    {
                        type: 'photo2',
                        is: 'image',
                        key:
                            this.profile &&
                            this.profile.mediaState.complete &&
                            this.profile.mediaState.complete.image.profile &&
                            this.profile.mediaState.complete.image.profile[2] &&
                            this.profile.mediaState.complete.image.profile[2].visible,
                        signedUrl:
                            this.profile &&
                            this.profile.mediaSigned &&
                            this.profile.mediaSigned.profile &&
                            this.profile.mediaSigned.profile[2] &&
                            this.profile.mediaSigned.profile[2].url,
                        label: 'photo 2',
                        btnLabel: 'Remove photo 2',
                        model: 'photo2',
                        action: '/v1/remove-photo/2',
                    },
                    {
                        type: 'photo3',
                        is: 'image',
                        key:
                            this.profile &&
                            this.profile.mediaState.complete &&
                            this.profile.mediaState.complete.image.profile &&
                            this.profile.mediaState.complete.image.profile[3] &&
                            this.profile.mediaState.complete.image.profile[3].visible,
                        signedUrl:
                            this.profile &&
                            this.profile.mediaSigned &&
                            this.profile.mediaSigned.profile &&
                            this.profile.mediaSigned.profile[3] &&
                            this.profile.mediaSigned.profile[3].url,
                        label: 'photo 3',
                        btnLabel: 'Remove photo 3',
                        model: 'photo3',
                        action: '/v1/remove-photo/3',
                    },
                    {
                        type: 'photo4',
                        is: 'image',
                        key:
                            this.profile &&
                            this.profile.mediaState.complete &&
                            this.profile.mediaState.complete.image.profile &&
                            this.profile.mediaState.complete.image.profile[4] &&
                            this.profile.mediaState.complete.image.profile[4].visible,
                        signedUrl:
                            this.profile &&
                            this.profile.mediaSigned &&
                            this.profile.mediaSigned.profile &&
                            this.profile.mediaSigned.profile[4] &&
                            this.profile.mediaSigned.profile[4].url,
                        label: 'photo 4',
                        btnLabel: 'remove photo 4',
                        model: 'photo4',
                        action: '/v1/remove-photo/4',
                    },
                    {
                        type: 'photo5',
                        is: 'image',
                        key:
                            this.profile &&
                            this.profile.mediaState.complete &&
                            this.profile.mediaState.complete.image.profile &&
                            this.profile.mediaState.complete.image.profile[5] &&
                            this.profile.mediaState.complete.image.profile[5].visible,
                        signedUrl:
                            this.profile &&
                            this.profile.mediaSigned &&
                            this.profile.mediaSigned.profile &&
                            this.profile.mediaSigned.profile[5] &&
                            this.profile.mediaSigned.profile[5].url,
                        label: 'photo 5',
                        btnLabel: 'Remove photo 5',
                        model: 'photo5',
                        action: '/v1/remove-photo/5',
                    },
                ];
            },

            buttons() {
                return {
                    account: [
                        {
                            action: '/v1/delete-profile-three-years',
                            text: 'Delete profile after three years',
                            requestType: 'post',
                            data: null,
                            warning:
                                'This is a destructive action! The member will be unable to reactivate this account and will have to create a new',
                        },
                        {
                            action: '/v1/reset-account',
                            text: 'Reset account',
                            requestType: 'post',
                            data: null,
                            warning: 'This is a destructive action!',
                        },
                        {
                            action: '/v1/deactivate-profile',
                            text: 'Deactivate profile',
                            requestType: 'post',
                            data: null,
                            warning: 'This is a destructive action!',
                        },
                        {
                            action: '/v1/members/gdpr-request',
                            text: 'Email User GDPR',
                            requestType: 'post',
                            data: { staff_id: this.staff.id, send_to_founders: 'false' },
                            warning: 'This will send the member ALL of their details!',
                        },
                        {
                            action: '/v1/members/gdpr-request',
                            text: 'Email Founders GDPR',
                            requestType: 'post',
                            data: { staff_id: this.staff.id, send_to_founders: 'true' },
                            warning: 'This will send the founders ALL of the member details!',
                        },
                        {
                            action: `/v1/members/${this.profile.memberID}/cancel-subscriptions`,
                            text: 'Cancel Stripe Subscriptions',
                            requestType: 'post',
                            data: { cancelAtPeriodEnd: true },
                            warning:
                                'This will cancel all Stripe Subscriptions at end of billing cycle, their Gold will be unchanged until then.',
                        },
                        {
                            modalToggle: this.toggleShowBlockOtherMemberModal,
                            text: 'Block other member',
                        },
                    ],
                    messaging: [
                        {
                            action: '/v1/unsubscribe-profile',
                            text: 'Unsubscribe everything ',
                            requestType: 'post',
                        },
                        {
                            action: '/v1/resend-wali',
                            text: 'Resend wali email confirm',
                            requestType: 'post',
                        },
                        {
                            action: '/v1/reset-push',
                            text: 'Reset push token',
                            requestType: 'post',
                        },
                    ],
                    other: [
                        {
                            action: '/v1/reset-idfa',
                            text: 'Reset IDFA',
                            requestType: 'post',
                        },
                        {
                            action: '/v1/clear-caches',
                            text: 'Clear activity caches',
                            requestType: 'post',
                        },
                        {
                            action: '/v1/refresh-es',
                            text: 'Refresh Elasticsearch',
                            requestType: 'post',
                        },
                        {
                            action: '/v1/auto-approve-rerun',
                            text: 'Run member through auto-approvals',
                            requestType: 'post',
                        },
                        {
                            action: '/v1/boost-expire-now',
                            text: 'Expire Boost now',
                            success: 'Boost is now expired',
                            requestType: 'post',
                        },
                        {
                            action: '/v1/reset-sms-blocker',
                            text: 'Reset SMS block',
                            success: 'SMS block has reset',
                            requestType: 'post',
                        },
                    ],
                };
            },

            blockButtons() {
                return [
                    ...(this.isAdmin || !this.profile.pendingBlockJson
                        ? [
                              {
                                  text: 'Permanently Block All Accounts',
                              },
                              {
                                  text: 'Permanently Block just this account',
                              },
                              {
                                  text: 'Temporary block (72h)',
                              },
                              {
                                  text: 'Close Duplicate Accounts',
                              },
                              {
                                  text: 'Send abuse warning message',
                                  action: '/v1/send-abuse-warning-message',
                                  requestType: 'post',
                              },
                          ]
                        : []),
                ];
            },

            unblockButtons() {
                if (!this.isAdmin) {
                    return [];
                }

                return [
                    {
                        text: 'Unblock account',
                    },
                ];
            },

            blockTypeButtons() {
                if (!this.blockShortcuts) {
                    return [];
                }

                return this.blockShortcuts.map((shortcut) => {
                    return { text: shortcut.name, id: shortcut.id };
                });
            },

            flags() {
                const flags = [
                    {
                        code: 'countryCode',
                        name: 'Phone Number',
                        title: 'countryName',
                    },
                    {
                        code: 'ethnicOriginCountryCode',
                        name: 'Ethnic Origin',
                        title: 'ethnicOriginCountryName',
                    },
                    {
                        code: 'GPSderivedCountryCode',
                        name: 'Location',
                        title: 'GPSderivedCountryName',
                        location: 'GPSderivedLocationName',
                    },
                    {
                        code: 'countrySIMCode',
                        name: 'SIM Card',
                        title: 'countrySIMName',
                    },
                    {
                        code: 'ipAddressCountryCode',
                        name: 'IP Address',
                        title: 'ipAddressCountryName',
                        location: 'IPderivedLocationName',
                    },
                ];

                if (this.member.premium === '1' && this.member.travelEnabled === '1')
                    flags.push({
                        code: 'travelCountryCode',
                        name: 'Travel Mode',
                        title: 'travelDerivedCountryName',
                        location: 'travelDerivedLocationName',
                    });

                return flags;
            },
            isFirstImage() {
                return this.currentImgIndex === 0;
            },
            isLastImage() {
                return this.nextImgIndex === this.photos.length - 1;
            },
            isBlocked() {
                if (this.profile.permanentlyBlocked === '1') return true;

                if (this.profile.profileState.includes('TEMPORARILY BLOCKED')) return true;

                return false;
            },
            pendingBlock() {
                if (this.profile.pendingBlockJson) return true;

                return false;
            },
            blockButtonColour() {
                if (this.isBlocked) {
                    return 'bg-red-lightest text-red-dark';
                } else if (this.profile.pendingBlockJson) {
                    return 'bg-orange-100 text-orange-700';
                }
                return 'bg-green-lightest text-green-dark';
            },
            blockStateAction() {
                if (!this.blockStateNotes) {
                    return '';
                }

                return this.blockStateNotes['adminComment'].split('Action: ')[1].split(',')[0];
            },
            blockStateType() {
                if (!this.blockStateNotes) {
                    return '';
                }

                return this.blockStateNotes['adminComment'].split('Type: ')[1].split(',')[0];
            },
            blockStateComment() {
                if (!this.blockStateNotes) {
                    return '';
                }

                return this.blockStateNotes['adminComment'].split('Notes: ')[1].split('.')[0];
            },
            blockStateTimestamp() {
                if (!this.blockStateNotes) {
                    return '';
                }

                return this.blockStateNotes.timeStamp;
            },
            isAdmin() {
                return User.make(this.staff).can('task-manager:admin');
            },
            isPendingBlockAdmin() {
                return User.make(this.staff).can('task-manager:pending-block-admin');
            },
            shareLink() {
                let url = process.env.VUE_APP_ENV === 'production' ? 'https://muzz.com' : 'https://dev.muzz.com';
                return url + '/profile/d/' + this.profile.shareLinkID;
            },

            canUseVerifiedStatusDropdown() {
                if (process.env.VUE_APP_ENV !== 'production') {
                    return true;
                }

                return User.make(this.staff).can('task-manager:identity-verification');
            },

            isIdVerificationTaskManager() {
                return this.contextType === 'CONTEXT_TYPE_TASK_IDENTITY_VERIFICATION';
            },

            dobButtonDisabled() {
                if (this.member.verifiedStatus === 'approved') {
                    return !User.make(this.staff).can('members:id-verified-dob');
                }

                return false;
            },

            blockCharacterLimit() {
                let stringJson = `Action: ${this.currentBlockAction}, Type: ${this.currentBlockType}, Notes: `;
                return 300 - stringJson.length;
            },
        },

        watch: {
            memberHashId() {
                this.showMemberDetails = false;
                this.showDuplicateAccounts = false;
            },

            member: {
                handler() {
                    this.syncProfile();
                },
                deep: true,
            },
        },

        async mounted() {
            this.syncProfile();

            this.verifiedStatusList = ['notSubmitted', 'resubmit', 'awaitingApproval', 'approved'];
            if (process.env.VUE_APP_ENV === 'production') {
                this.verifiedStatusList = ['notSubmitted', 'resubmit'];
            }
        },

        methods: {
            ...mapActions({
                handleError: 'handleError',
                alert: 'alert',
            }),

            async toggleShowBlockOtherMemberModal(hash) {
                const response = await this.$swal({
                    html: `
                        <div class="flex flex-row items-center justify-center">
                            <div class="text-left">
                                <label class="my-1">Member ID</label>
                                <input
                                    id="memberId"
                                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    type="text"
                                />
                            </div>
                        </div>
                    `,
                    preConfirm: () => {
                        return document.getElementById('memberId').value;
                    },
                    showCancelButton: true,
                    confirmButtonText: 'Block member',
                });

                if (!response.value) {
                    return;
                }

                let data = {
                    decider_member_id: this.profile.memberID,
                    blocked_member_id: response.value,
                };

                try {
                    let response = await dashboardAxios.post('/v1/block-member-by-member', data);
                    const message = response.data.result.message;

                    await this.alert({ message: message, type: 'success' });
                } catch (error) {
                    this.handleError({ error });
                }
            },

            handleAction(button) {
                if (button.action) {
                    return this.updateProfile(button.action, button.requestType, button.data, 'Success!');
                }

                if (button.text === 'Close Duplicate Accounts') {
                    return async () => {
                        const shortcut = this.blockShortcuts.find(
                            (shortcut) => shortcut.keys === 'Duplicate Accounts' || shortcut.keys === 'dpa',
                        );

                        this.currentBlockAction = button.text;
                        this.currentBlockType = shortcut.name;
                        this.currentBlockShortcut = shortcut.id;
                        this.handleBlockForm(true);
                    };
                }

                return this.toggleBlockModalWrapper(button.text);
            },

            /**
             * @param url
             * @param requestType
             * @param formData
             * @param message
             *
             * @returns {function(...[*]=)}
             */
            updateProfile(url, requestType, formData, message) {
                return async () => {
                    let data = {
                        member_id: this.profile.memberID,
                        accountID: this.profile.accountID,
                    };

                    if (formData) {
                        data = { ...data, ...formData };
                    }

                    let request =
                        requestType === 'post'
                            ? dashboardAxios.post(url, data)
                            : dashboardAxios.get(url, { params: data });

                    try {
                        await request;

                        this.alert({ message, type: 'success' });
                    } catch (error) {
                        this.handleError({ error });
                    }

                    this.$emit('refresh', true);

                    EventBus.$emit('refresh-chat');

                    this.isLoading = false;
                };
            },

            async rejectSocialAttribute(attribute) {
                if (this.contextType.includes('CONTEXT_TYPE_TASK_SOCIAL')) {
                    this.$emit('rejectSocialAttribute', attribute);
                } else {
                    try {
                        await socialModerationAxios.post(
                            '/internal/community/social/edit/reset-social-profile-details',
                            {
                                profile_uid: this.member.social.profileId,
                                social_profile_component: attribute,
                            },
                        );
                        await this.alert({ message: 'Field removed', type: 'success' });
                        this.$emit('refresh', true);
                    } catch (error) {
                        await this.alert({ message: 'Unable to remove field', type: 'danger' });
                    }
                }
            },

            /**
             * @param {int} duration
             * @param {string} unit
             */
            applyGold(duration, unit) {
                return this.updateProfile('/v1/apply-gold', 'post', { duration, unit }, 'Success!');
            },

            /**
             * @param error
             *
             * @returns {Promise<void>}
             */
            async handleButtonError(error) {
                this.handleError({ error });
            },

            async handleConfirmPending() {
                const pendingJson = JSON.parse(this.profile.pendingBlockJson);
                const data = {
                    block_action: pendingJson.blockAction,
                    block_type: pendingJson.blockType,
                    block_shortcut_id: pendingJson.blockShortcutID,
                    block_notes: pendingJson.blockNotes,
                    context_type: pendingJson.contextType,
                    context_task_id: pendingJson.contextTaskID,
                };
                this.isLoading = true;
                switch (pendingJson.blockAction) {
                    case 'Permanently Block All Accounts':
                        await this.updateProfile(
                            '/v1/permanent-block-all-account-by-member',
                            'post',
                            data,
                            'Success!',
                        )();
                        break;
                    case 'Permanently Block just this account':
                        await this.updateProfile('/v1/permanent-block-member', 'post', data, 'Success!')();
                        break;
                }
                this.isLoading = false;
            },

            /**
             * @returns {Promise<void>}
             */
            async handleCreateReport() {
                await this.updateProfile(
                    '/v1/create-report',
                    'post',
                    { messageReport: this.messageReport },
                    'Behavior report created.',
                )();
                EventBus.$emit('refresh-received-issues');
                this.messageReport = '';
            },

            shouldButtonShow(button) {
                if (!('showDevOnly' in button)) return true;

                if (button.showDevOnly === true && process.env.VUE_APP_ENV === 'production') {
                    return false;
                }

                return true;
            },

            async sendMessageHistory(hash) {
                let response = await this.$swal({
                    title: 'Are you sure?',
                    icon: 'warning',
                    text: "This will email the persons chat. This could take time, please don't press the button again!",
                    showCancelButton: true,
                    confirmButtonText: 'Continue',
                });

                if (!response.value) {
                    return;
                }

                await this.updateProfile('/v1/email-chat', 'post', { chatOtherMemberID: hash }, 'Chat emailed.')();
            },

            async syncProfile() {
                this.profile = this.member;

                this.locale = this.profile.locale;
                this.nickname = this.profile.nickname;
                this.emailAddress = this.profile.emailAddress;
                this.phoneNumber = this.profile.phoneNumber;
                this.jobTitle = this.profile.jobTitle;
                this.companyOfEmployment = this.profile.companyOfEmployment;
                this.dob.day = this.profile.dobDAY;
                this.dob.month = this.profile.dobMONTH;
                this.dob.year = this.profile.dobYEAR;

                try {
                    const [previousAccounts, blockedFields] = await Promise.all([
                        dashboardAxios.get(`/v1/members/${this.profile.memberID}/previous-accounts`),
                        dashboardAxios.get(`/v1/members/${this.profile.memberID}/blocked-fields`),
                    ]);

                    this.previousAccounts = previousAccounts.data.result;
                    this.blockedFields = blockedFields.data.result;
                } catch (error) {
                    captureException(error);
                }

                this.getBlockNotes();
                this.getBlockShortcuts();
            },

            resolveCountryFlagUrl(flagType) {
                let countryCode = this.profile[flagType] ? this.profile[flagType].toLowerCase() : 'world';

                return `https://${process.env.VUE_APP_WEB_CDN_DOMAIN}/images/flags/flag_${countryCode}.png`;
            },

            toggleShowAccounts() {
                this.showDuplicateAccounts = !this.showDuplicateAccounts;
            },

            toggleModal(index) {
                let isLastImage = index === this.photos.length - 1;
                if (index && typeof index === 'number') {
                    if (isLastImage) {
                        this.nextImgIndex = index;
                        this.currentImgIndex = index - 1;
                    } else {
                        this.nextImgIndex = index + 1;
                        this.currentImgIndex = index;
                    }
                } else {
                    this.nextImgIndex = 1;
                    this.currentImgIndex = 0;
                }
                this.showModal = !this.showModal;
            },

            toggleBlockModalWrapper(blockAction) {
                return async () => {
                    this.toggleBlockModal(blockAction);
                };
            },

            toggleBlockStateNotes() {
                this.showBlockStateNotes = !this.showBlockStateNotes;
            },

            toggleBlockModal(blockAction) {
                this.currentBlockAction = blockAction || '';
                this.currentBlockType = '';
                this.currentBlockShortcut = 0;
                this.showBlockError = false;
                this.showBlockModal = !this.showBlockModal;
            },

            selectButton(button) {
                this.currentBlockType = button.text;
                this.currentBlockShortcut = button.id;
            },

            async getBlockShortcuts() {
                try {
                    let response = await dashboardAxios.get('/v1/shortcuts-type/block');
                    this.blockShortcuts = response.data.result;
                } catch (e) {
                    await this.handleError({ error: e });
                }
            },

            async getBlockNotes() {
                try {
                    let response = await dashboardAxios.get('/v1/block-details/' + this.profile.hashMemberID);
                    this.blockStateNotes = response.data.result['blockDetails'];
                } catch (e) {
                    await this.handleError({ error: e });
                }
            },

            async handleBlockForm(hideModal = false) {
                if (!this.currentBlockAction) {
                    return;
                }

                if (!this.currentBlockType && this.currentBlockAction !== 'Unblock account') {
                    this.showBlockError = true;
                    return;
                }

                this.isLoading = true;

                this.showBlockError = false;

                let data = {
                    block_action: this.currentBlockAction,
                    block_type: this.currentBlockType,
                    block_shortcut_id: this.currentBlockShortcut,
                    block_notes: this.currentBlockNotes,
                    context_type: this.contextType.split('CONTEXT_TYPE_')[1],
                    context_task_id: this.contextTaskId,
                    reporter_id: this.reporterId,
                };

                switch (this.currentBlockAction) {
                    case 'Permanently Block All Accounts':
                        await this.updateProfile(
                            '/v1/permanent-block-all-account-by-member',
                            'post',
                            data,
                            'Success!',
                        )();
                        break;
                    case 'Close Duplicate Accounts':
                        await this.updateProfile('/v1/close-all-other-accounts-by-member', 'post', data, 'Success!')();
                        break;
                    case 'Temporary block (72h)':
                        data['block_length'] = '72h';
                        await this.updateProfile('/v1/temporarily-block-member', 'post', data, 'Success!')();
                        break;
                    case 'Unblock account':
                        await this.updateProfile('/v1/unblock-member', 'post', data, 'Success!')();
                        break;
                    case 'Permanently Block just this account':
                        await this.updateProfile('/v1/permanent-block-member', 'post', data, 'Success!')();
                        break;
                }

                if (hideModal) return;

                this.toggleBlockModal();
                this.showBlockStateNotes = false;

                this.isLoading = false;
            },

            decrementIndex() {
                this.currentImgIndex--;
                this.nextImgIndex--;
            },

            incrementIndex() {
                this.currentImgIndex++;
                this.nextImgIndex++;
            },

            googleTranslate(message) {
                window.open(this.googleTranslateUrl + message);
            },

            blockOrUnblock(button) {
                if (button.toggle === false || this.isBlocked === false) return 'block';

                return 'unblock';
            },

            confirmMessage(button) {
                return `This will *${this.blockOrUnblock(button)}* the user from Muzz`;
            },

            successMessage(button) {
                return `Successfully ${this.blockOrUnblock(button)}ed`;
            },

            dateFormat: function (value) {
                return moment.utc(value).local().format('LLLL');
            },

            timeAgo: function (value) {
                return moment.utc(value).local().fromNow();
            },

            mapDupes(values, type) {
                return values
                    .filter((el) => !!el.hash)
                    .map((el) => ({
                        ...el,
                        type: type,
                    }));
            },

            isActive(member) {
                if (member.permanentlyBlocked !== 0) {
                    return false;
                }

                if (member.deleteAfterTimeStamp !== null) {
                    return false;
                }

                if (member.activeProfile === 0) {
                    return false;
                }

                return true;
            },

            getState(member) {
                if (member.permanentlyBlocked === 1) {
                    return 'BLOCKED';
                }

                if (member.activeProfile === 0) {
                    return 'INACTIVE';
                }

                return 'ACTIVE';
            },

            profile_video_play(evt) {
                if (evt === 'play') {
                    document.getElementById('profile_video').classList.remove('cover-fit');
                } else if (evt === 'complete') {
                    document.getElementById('profile_video').classList.add('cover-fit');
                }
            },

            updatedIdentityVerification(event) {
                this.member.verifiedStatus = event.verifiedStatus;
                this.member.dobDAY = event.dobDAY;
                this.member.dobMONTH = event.dobMONTH;
                this.member.dobYEAR = event.dobYEAR;
                this.member.age = event.age;
                EventBus.$emit('refresh-chat');
            },

            formatDuplicateMembers,
            resolvePhotoUrl,
            resolveVerificationPhotoUrl,
            resolveAudioUrl,
            resolveVideoUrl,
            getMemberRoute,
            time,
        },
    };
</script>

<style scoped>
    .profile-photo {
        object-fit: cover;
        object-position: 50%;
    }

    .right-0 {
        right: 0;
    }

    .top-0 {
        top: 0;
    }

    .flags {
        transition: all;
        transition-duration: 100ms;
    }

    .w-20 {
        width: 5rem;
    }

    video.cover-fit {
        object-fit: cover;
        object-position: 50%;
    }
</style>
