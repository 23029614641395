<template>
    <Panel title="Chat">
        <div slot="header" class="ml-auto p-4">
            <span class="text-grey-500 mr-2 text-sm">{{ matchId }}</span>

            <icon icon="redo" class="cursor-pointer grey-light" @click="loadChat(false, true)"></icon>
        </div>

        <ChatPanelContents
            :sender="sender"
            :receiver="receiver"
            :match-id="matchId"
            :custom-style="customStyle"
            :context-type="contextType"
            :context-action="contextAction"
            :messages="messages"
            :loading="loading"
            :current-page="currentPage"
            :total-pages="totalPages"
            @loadChat="loadChat"
            @nextPage="nextPage"
        />
    </Panel>
</template>

<script>
    import Panel from './Panel';
    import ChatPanelContents from './Contents/ChatPanelContents';
    import { dashboardAxios } from '../../../axios.config';
    import { mapActions } from 'vuex';
    import Vue from 'vue';

    export default {
        name: 'ChatPanel',

        components: {
            Panel,
            ChatPanelContents,
        },

        props: {
            sender: {
                type: String,
                default: null,
            },

            receiver: {
                type: String,
                default: null,
            },

            matchId: {
                type: String,
                default: null,
            },

            customStyle: {
                type: String,
                default: '',
            },

            contextType: {
                type: String,
                required: true,
            },

            contextAction: {
                type: String,
                required: true,
            },

            contextChat: {
                type: String,
                default: 'MARRIAGE',
            },
        },

        data() {
            return {
                loading: true,
                messages: [],
                currentPage: 1,
                totalPages: 1,
            };
        },

        methods: {
            ...mapActions({
                handleError: 'handleError',
            }),

            async nextPage() {
                this.currentPage++;
                this.loadChat(true);
            },

            async loadChat(silent = false, newMessages = false) {
                if (newMessages) {
                    this.currentPage = 1;
                    this.totalPages = 1;
                }

                if (!silent) {
                    this.loading = true;
                }

                if (!this.sender || !this.receiver) {
                    return;
                }

                let match = this.sender + '-' + this.receiver;
                if (this.contextChat === 'SOCIAL') {
                    match = this.matchId;
                }

                try {
                    let response = await dashboardAxios.get('/v1/matches/' + match + '/messages/' + this.currentPage);

                    if (this.currentPage == 1) {
                        this.messages = response.data.result.messages;
                    } else {
                        this.messages = this.messages.concat(response.data.result.messages);
                    }

                    this.totalPages = response.data.result.total_pages;

                    this.loading = false;

                    let elements = document.getElementsByClassName('chat-messages');

                    let originalScrollHeight = elements.length > 0 ? elements[0].scrollHeight : 0;

                    Vue.nextTick(() => {
                        if (this.currentPage == 1) {
                            let elements = document.getElementsByClassName('chat-messages');

                            _.each(elements, (element) => {
                                element.scrollTop = element.scrollHeight;
                            });
                        } else {
                            let elements = document.getElementsByClassName('chat-messages');
                            if (elements.length > 0) {
                                elements[0].scrollTop = elements[0].scrollHeight - originalScrollHeight;
                            }
                        }
                    });
                } catch (e) {
                    this.handleError({ error: e });
                }
            },
        },
    };
</script>
