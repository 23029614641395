<template>
    <div>
        <date-picker-range :default-range="range" @range-updated="rangeUpdated" />
        <AllContentRowsModal v-if="isModalOpen" :content="modalLog" @hide="isModalOpen = false" />
        <div v-if="shouldShowLogs">
            <table class="sm:w-full text-left table-fixed overflow-x-scroll">
                <thead>
                    <tr class="bg-grey-100 text-grey-700 uppercase">
                        <th class="px-4 py-2 w-1/6">Created</th>
                        <th class="px-4 py-2 w-1/3">Code</th>
                        <th class="px-4 py-2 w-1/2">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(log, key) in filteredLogs">
                        <tr :key="key" class="cursor-pointer border-white border" @click="toggleOpen(key)">
                            <td class="px-4 py-2 break-words font-bold bg-grey-300">{{ key }}</td>
                            <td class="bg-grey-300"></td>
                            <td class="bg-grey-300"></td>
                        </tr>

                        <tr
                            v-for="(event, idx) in log"
                            v-show="opened[key]"
                            :key="idx"
                            class="border hover:bg-grey-200"
                            @click="openModal(event)"
                        >
                            <td class="px-4 py-2 break-words">
                                {{ time(event.time_stamp) }}
                            </td>

                            <td class="px-4 py-2 font-mono font-bold break-words">
                                {{ event.event_code }}

                                <span
                                    v-if="event.event_code === 'APP_OPENED'"
                                    class="rounded-full p-1 px-2 bg-grey-200 text-sm"
                                >
                                    {{ event.app_version }}
                                </span>

                                <span
                                    v-if="event.event_code === 'APP_OPENED'"
                                    class="rounded-full p-1 px-2 bg-grey-200 text-sm ml-2"
                                >
                                    {{ event.device_os }} {{ event.device_os_version }}
                                </span>
                            </td>

                            <td class="px-4 py-2 break-words">
                                {{ event.action }}
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>

            <div v-if="data && data.hasNextPage" class="max-w-xs mx-auto mt-4">
                <M2Button :is-disabled="loading" @clicked="loadLogs">
                    <span v-if="loading">Loading</span>
                    <span v-else>Load More</span>
                </M2Button>
            </div>
        </div>

        <div v-else class="flex justify-center items-center font-bold">
            <div v-if="!loading" class="my-4">No Logs Found</div>

            <div v-if="loading && last !== null" class="flex flex-col items-center mt-20">
                <icon icon="list" class="loading-animation text-5xl mb-2 text-pink-muzmatch"></icon>
                <span class="text-lg mt-2 font-bold">Loading...</span>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import DatePicker from 'v-calendar/lib/components/date-picker.umd';
    import { mapActions } from 'vuex';
    import { dashboardAxios } from '../../../../axios.config';
    import AllContentRowsModal from './AllContentRowsModal';
    import M2Button from '../../Buttons/M2Button';
    import DatePickerRange from './DatePickerRange.vue';

    export default {
        components: {
            AllContentRowsModal,
            M2Button,
            DatePicker,
            DatePickerRange,
        },
        props: {
            profile: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                search: '',
                logs: [],
                data: null,
                filteredLogs: [],
                loading: false,
                isModalOpen: false,
                modalLog: null,
                opened: {},
                last: null,
                range: {
                    start: null,
                    end: null,
                },
            };
        },
        computed: {
            shouldShowLogs() {
                if (this.loading) return false;

                if (this.data && this.data.hasNextPage) return true;

                return Object.keys(this.filteredLogs).length > 0 && this.last !== null;
            },
        },
        watch: {},
        mounted() {
            this.loadLogs();
        },
        methods: {
            ...mapActions({
                handleError: 'handleError',
            }),

            rangeUpdated({ newRange, oldRange }) {
                if (newRange.start === oldRange.start && newRange.end === oldRange.end) return;

                this.range = newRange;

                this.last = null;
                this.logs = [];
                this.loadLogs();
            },

            time(time, format = 'DD/MM/YYYY HH:mm') {
                return moment(time).format(format);
            },

            openModal(log) {
                this.isModalOpen = true;
                this.modalLog = log;
            },

            async filterLogs() {
                if (!this.logs) {
                    this.filteredLogs = [];
                }

                const logs = _.groupBy(this.logs, (log) => {
                    return this.time(log.time_stamp, 'DD/MM/YYYY');
                });

                const final = {};

                Object.keys(logs)
                    .sort((a, b) => moment(b, 'DD/MM/YYY').diff(moment(a, 'DD/MM/YYY')))
                    .forEach((key) => (final[key] = logs[key]));

                this.filteredLogs = final;
            },

            async loadLogs() {
                if (this.loading) {
                    return;
                }

                this.loading = true;

                try {
                    const response = await dashboardAxios.get('/v1/logs/' + this.profile.hashMemberID, {
                        params: {
                            last: this.last,
                            ...(this.range.start && { from: this.range.start }),
                            ...(this.range.end && { to: this.range.end }),
                        },
                    });
                    this.data = response.data.result;
                    this.logs.push(...this.data.logs);
                    this.last = response.data.result.last;
                    await this.filterLogs();
                } catch (error) {
                    console.error(error);

                    this.handleError({ error });
                }

                this.loading = false;
            },

            toggleOpen(key) {
                if (this.opened[key]) {
                    Vue.set(this.opened, key, false);

                    return;
                }

                Vue.set(this.opened, key, true);
            },
        },
    };
</script>

<style scoped>
    .loading-animation {
        -webkit-animation: flickerAnimation 1s infinite;
        -moz-animation: flickerAnimation 1s infinite;
        -o-animation: flickerAnimation 1s infinite;
        animation: flickerAnimation 1s infinite;
    }

    @keyframes flickerAnimation {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0.2;
        }
        100% {
            opacity: 1;
        }
    }
    @-o-keyframes flickerAnimation {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0.2;
        }
        100% {
            opacity: 1;
        }
    }
    @-moz-keyframes flickerAnimation {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0.2;
        }
        100% {
            opacity: 1;
        }
    }
    @-webkit-keyframes flickerAnimation {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0.2;
        }
        100% {
            opacity: 1;
        }
    }
</style>
