<template>
    <modal :click-overlay="() => $emit('hide')" size="xl" :no-padding="true">
        <div slot="body">
            <vue-easy-lightbox
                move-disabled
                :visible="showLightbox"
                :imgs="imgs"
                :index="index"
                @hide="showLightbox = false"
            />

            <panel title="Media Hints" :has-margin="false">
                <div class="overflow-auto" style="max-height: 42rem">
                    <table class="sm:w-full text-left table-fixed overflow-x-scroll">
                        <thead>
                            <tr class="bg-grey-100 text-grey-700 uppercase">
                                <th class="px-4 py-2 w-2/5">Media</th>
                                <th class="px-4 py-2 w-1/5">Nickname</th>
                                <th class="px-4 py-2 w-1/5">Blocked</th>
                                <th class="px-4 py-2 w-1/5">Other Member</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(hint, i) in content" :key="hint.path" class="border hover:bg-grey-200">
                                <td class="w-2/6 px-4 py-2 break-words">
                                    <img
                                        class="h-64"
                                        :src="hint.url"
                                        alt=""
                                        @click="
                                            () => {
                                                index = i;
                                                showLightbox = true;
                                            }
                                        "
                                    />
                                </td>
                                <td class="px-4 py-2 break-words">
                                    {{ hint.nickname }}
                                </td>
                                <td class="px-4 py-2 break-words">
                                    <icon
                                        v-if="hint.permanentlyBlocked === '1'"
                                        title="Member is Blocked"
                                        icon="check"
                                    ></icon>
                                </td>
                                <td class="px-4 py-2 break-words">
                                    <router-link
                                        :to="{ name: 'members', query: { memberId: hint.memberID } }"
                                        class="text-sm border border-pink-dark rounded-xs bg-pink-dark text-center text-white w-full p-4 uppercase no-underline"
                                        target="_blank"
                                    >
                                        View Member
                                    </router-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </panel>
        </div>
    </modal>
</template>

<script>
    import VueEasyLightbox from 'vue-easy-lightbox';
    import Modal from './Modal';
    import Panel from '../Panels/Panel';
    import { resolvePhotoUrl, resolveVerificationPhotoUrl } from '../../helpers/DashboardUtils';

    export default {
        components: {
            Modal,
            Panel,
            VueEasyLightbox,
        },
        filters: {
            time(timestamp) {
                return moment(timestamp).format('DD/MM/YYYY HH:mm:ss');
            },
        },
        props: {
            content: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                showLightbox: false,
                index: 0,
            };
        },
        computed: {
            imgs() {
                return this.content.map((item) => item.url);
            },
        },
        methods: {
            resolvePhotoUrl,
            resolveVerificationPhotoUrl,
        },
    };
</script>
