<template>
    <div>
        <div>
            <input
                v-model="search"
                type="text"
                placeholder="Search..."
                style="top: 1rem; right: 1rem"
                class="w-1/4 px-3 py-2 absolute border rounded"
            />
        </div>

        <div>
            <table class="w-full text-left table-fixed mt-12">
                <thead>
                    <tr class="bg-grey-200">
                        <th class="px-4 py-2 w-1/4">Key</th>
                        <th class="px-4 py-2">Value</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="key in Object.keys(filteredData)" :key="key" class="border hover:bg-grey-200">
                        <td class="px-4 py-2 font-bold break-words">{{ key }}</td>

                        <td class="px-4 py-2 break-words notranslate">
                            <div
                                v-if="key !== 'otherMembersWithSameIDFA' && key !== 'otherMembersWithSameUDID'"
                                class="break-words"
                            >
                                {{ formatTimestamp(key, filteredData[key]) }}
                            </div>

                            <div v-else>
                                <span
                                    v-for="account in formatDuplicateMembers(profile[key])"
                                    :key="account.id"
                                    class="inline-flex"
                                >
                                    <router-link
                                        target="_blank"
                                        :to="getMemberRoute({ nickname: account.name, hashMemberID: account.hash })"
                                        class="no-underline text-pink-muzmatch mx-1 bold"
                                    >
                                        {{ account.name }}
                                    </router-link>
                                </span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import { formatDuplicateMembers, formatTimestamp } from '../../../helpers/DashboardUtils';
    import { getMemberRoute } from '../../../helpers/RedirectToMember';

    export default {
        name: 'DetailsTab',

        props: {
            profile: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                search: '',
            };
        },

        computed: {
            filteredData() {
                if (!this.search) {
                    return this.profile;
                }

                return Object.keys(this.profile)
                    .filter((key) => key.toLowerCase().includes(this.search.toLowerCase()))
                    .reduce((obj, key) => {
                        obj[key] = this.profile[key];

                        return obj;
                    }, {});
            },
        },

        methods: {
            formatDuplicateMembers,
            getMemberRoute,
            formatTimestamp,
        },
    };
</script>
