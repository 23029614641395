<template>
    <transition name="modal">
        <div class="fixed z-50 top-0 right-0 modal-mask h-screen w-screen flex justify-center items-center">
            <div
                class="flex flex-col max-w-md justify-center items-center mx-auto my-auto px-5 py-5 bg-white border-grey border shadow modal-container rounded"
            >
                <p>
                    Are you sure you want to delete the {{ type }} named
                    <span class="font-bold">{{ name }}</span>
                    ?
                </p>

                <p v-if="description" class="mt-3">{{ description }}</p>

                <div class="flex flex-row w-96 justify-around">
                    <button class="button-shadow rounded-full bg-grey-light mt-4 h-8 p-1 w-40" @click="handleCancel()">
                        Cancel
                    </button>
                    <button
                        class="button-shadow-red rounded-full p-1 w-40 mt-4 h-8 text-white bg-red-dark"
                        type="button"
                        @click="handleDelete()"
                    >
                        Delete
                    </button>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        props: {
            id: {
                required: true,
                type: String,
            },
            type: {
                required: true,
                type: String,
            },
            name: {
                required: true,
                type: String,
            },
            description: {
                default: null,
                type: String,
            },
        },
        created() {
            window.addEventListener('keydown', this.handleKeyPress);
        },
        destroyed() {
            window.removeEventListener('keydown', this.handleKeyPress);
        },
        methods: {
            handleKeyPress(event) {
                if (event.key === 'Escape') this.$emit('toggle-delete-modal');
            },
            handleDelete() {
                this.$emit('delete-item', this.id);
                this.$emit('toggle-delete-modal');
            },
            handleCancel() {
                this.$emit('toggle-delete-modal');
            },
        },
    };
</script>

<style scoped>
    .modal-mask {
        background-color: rgba(0, 0, 0, 0.5);
        transition: opacity 0.3s ease;
    }
    .modal-container {
        transition: all 0.3s ease;
        display: flex;
        flex: 1;
        max-height: 90vh;
    }
    .modal-enter {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
    .modal-custom-css {
        padding: 20px 150px;
    }
</style>
