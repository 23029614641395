<template>
    <div>
        <iOS13SegmentedControl v-model="controlValue" :segments="segments" />
        <ApprovalHistoryTab
            ref="aht"
            :endpoint="'/v1/auto-approvals'"
            :segment="controlValue"
            :show-member-i-d="true"
        ></ApprovalHistoryTab>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import ApprovalHistoryTab from '@/components/Dashboard/Tabs/ApprovalHistoryTab.vue';
    import iOS13SegmentedControl from 'vue-ios13-segmented-control';

    export default {
        name: 'AutoApprovals',

        components: {
            ApprovalHistoryTab,
            iOS13SegmentedControl,
        },

        data() {
            return {
                controlValue: 'all',
                segments: [
                    { title: 'All', id: 'all' },
                    { title: 'Successful', id: 'successful' },
                    { title: 'Failed', id: 'failed' },
                    { title: 'Failed Detection', id: 'failedDetection' },
                    { title: 'Failed Comparison', id: 'failedComparison' },
                ],
            };
        },

        computed: {
            ...mapGetters({
                staff: 'staff/getStaff',
            }),
        },

        methods: {
            ...mapActions(['handleError']),
        },
    };
</script>

<style>
    @media (min-width: 768px) {
        .fullscreen {
            height: calc(100vh - 5.6rem);
        }
    }
</style>
