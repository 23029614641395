<template>
    <tr
        class="mx-4 hover:bg-grey-100 cursor-pointer"
        :class="{ 'bg-grey-50': isOdd }"
        @click="$router.push('/messenger/campaign.' + campaign.id)"
    >
        <td class="py-4 pl-4">
            {{ campaign.title }}
            <span v-for="channel in campaign.channelList" :key="channel">
                <icon
                    :title="channel"
                    :icon="getChannelIcon(channel)"
                    :class="getChannelClass(channel)"
                    :alt="channel"
                    class="ml-1"
                />
            </span>
        </td>
        <td class="py-4">{{ capitaliseWord(campaign.state) }}</td>
        <td class="py-4">{{ capitaliseWord(campaign.type) }}</td>
        <td class="py-4">{{ formatNumber(campaign.audienceSize) }}</td>
        <td class="py-4">{{ engagement }} ({{ opened }})</td>
        <td class="py-4">{{ formatDatetime(campaign.scheduleSendAt) }}</td>
        <td class="py-4">{{ campaign.processing }}%</td>
        <td class="py-4">{{ formatDatetime(campaign.lastProcessed) }}</td>
        <td class="py-4">{{ formatDatetime(campaign.updatedAt) }}</td>
        <td class="py-4">{{ formatDatetime(campaign.completedAt) }}</td>
    </tr>
</template>

<script>
    import moment from 'moment';
    export default {
        props: {
            campaign: {
                type: Object,
                required: true,
            },
            index: {
                type: Number,
                required: true,
            },
            goalList: {
                type: Array,
                required: true,
            },
        },
        computed: {
            isOdd() {
                return this.index % 2 === 0;
            },
            engagement() {
                if (this.campaign.type == 'REENGAGEMENT') return '';
                if (this.campaign.stats.length === 0) return '0%';

                const stats = this.campaign.stats[this.campaign.stats.length - 1];

                const eng = Object.keys(stats.items).map((item) => ({
                    templateID: item.split('.')[0],
                    action: item.split('.')[0],
                    percentage: ((Number(stats.items[item] || 0) / Number(stats.size)) * 100).toFixed(2),
                }));

                if (!(eng.length - 1 in eng)) {
                    return '0%';
                }

                return eng[eng.length - 1].percentage + '%';
            },
            opened() {
                if (this.campaign.type == 'REENGAGEMENT') {
                    if (Object.keys(this.campaign.reengagementStats).length !== 0) {
                        let total = 0;
                        for (let key in this.campaign.reengagementStats) {
                            if (key.includes('opened')) {
                                total += this.campaign.reengagementStats[key];
                            }
                        }
                        return total;
                    }
                }

                if (this.campaign.stats.length === 0) return '0';

                const stats = this.campaign.stats[this.campaign.stats.length - 1];

                const eng = Object.keys(stats.items).map((item) => ({
                    opened: Number(stats.items[item]) || 0,
                }));

                if (!(eng.length - 1 in eng)) {
                    return '0';
                }

                return eng[eng.length - 1].opened;
            },
        },
        methods: {
            capitaliseWord(string) {
                return string.replace(/\b\w/g, (l) => l.toUpperCase());
            },
            formatNumber(number) {
                if (number) {
                    return new Intl.NumberFormat('en-GB').format(number);
                }

                return '-';
            },
            formatDatetime(datetime) {
                if (datetime) {
                    return moment(datetime).format('DD/MM/YY HH:mm');
                }

                return '-';
            },
            getChannelIcon(channel) {
                return {
                    EMAIL: 'envelope',
                    IN_APP: 'mobile',
                    PUSH: 'bell',
                    IN_APP_NO_PUSH: 'mobile',
                    NO_MESSAGE: 'ban',
                }[channel];
            },
            getChannelClass(channel) {
                return {
                    EMAIL: 'text-blue-dark',
                    IN_APP: 'text-green-dark',
                    PUSH: 'text-red-dark',
                    IN_APP_NO_PUSH: 'text-green-light',
                    NO_MESSAGE: 'text-red-dark',
                }[channel];
            },
        },
    };
</script>
