<template>
    <div>
        <DashboardLabel class="flex my-3">Remote Config</DashboardLabel>

        <template v-if="isLoading">Loading...</template>
        <template v-else-if="currentOverrides.length >= 1">
            <DebugOptionRemoteConfigRow
                v-for="(override, index) in currentOverrides"
                v-if="mobileSettings !== null"
                :key="override.config + '_' + index"
                :index="index"
                :current-override="override"
                :mobile-settings="mobileSettings"
                :override-count="currentOverrides.length"
                @update-row="updateRow"
                @remove-row="removeRow"
                @save-override="saveOverride"
                @clear-cache="clearCache"
            />
        </template>
        <template v-else>
            <div class="mt-4 flex flex-row items-end flex-wrap bg-grey-100 rounded p-4 py-6">
                <p>No cache overrides</p>
            </div>
        </template>

        <div class="mt-4 flex flex-row-reverse">
            <SecondaryButton text="Add new override" :handle-error="handleButtonError" :handle-click="openModal" />
        </div>

        <DebugOptionRemoteConfigAddModal
            v-if="showModal"
            :config-dropdown="configDropdown"
            :current-overrides="currentOverrides"
            :mobile-settings="mobileSettings"
            @add-row="addRow"
            @close-modal="showModal = false"
        />
    </div>
</template>

<script>
    import DashboardLabel from '@/components/Labels/DashboardLabel';
    import { dashboardAxios } from '../../../../axios.config';
    import PrimaryButton from '@/components/Buttons/PrimaryButton';
    import Dropdown from '@/components/Dropdown/Dropdown';
    import { mapActions } from 'vuex';
    import DebugOptionRemoteConfigRow from '@/components/Cards/DebugOption/DebugOptionRemoteConfigRow';
    import DropdownButton from '@/components/Buttons/Dropdown/DropdownButton';
    import DropdownItem from '@/components/Buttons/Dropdown/DropdownItem';
    import SecondaryButton from '@/components/Buttons/SecondaryButton';
    import DebugOptionRemoteConfigAddModal from '@/components/Cards/DebugOption/DebugOptionRemoteConfigAddModal';
    import _ from 'lodash';

    export default {
        name: 'DebugOptionRemoteConfig',
        components: {
            DashboardLabel,
            PrimaryButton,
            SecondaryButton,
            Dropdown,
            DropdownButton,
            DropdownItem,
            DebugOptionRemoteConfigRow,
            DebugOptionRemoteConfigAddModal,
        },

        props: {
            member: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                showModal: false,
                isLoading: true,
                mobileSettings: null,
                currentOverrides: [],
            };
        },

        computed: {
            configDropdown() {
                if (this.mobileSettings === null) {
                    return [];
                }

                let list = [];
                Object.keys(this.mobileSettings).forEach(function (key) {
                    list.push({ value: key, label: _.camelCase(key.trim()) });
                });

                return list;
            },
        },

        mounted() {
            this.loadRemoteConfigCache();
            this.loadCurrentMemberOverrides();
            this.isLoading = false;
        },

        methods: {
            ...mapActions({
                handleError: 'handleError',
                alert: 'alert',
            }),

            async handleButtonError(error) {
                this.handleError({ error });
            },

            async loadCurrentMemberOverrides() {
                try {
                    let response = await dashboardAxios.get(
                        `/v1/debug-option/remote-config/current-overrides/${this.member.memberID}`,
                    );
                    this.currentOverrides = response.data.result;
                } catch (error) {
                    await this.handleError({ error });
                }
            },

            async loadRemoteConfigCache() {
                try {
                    let response = await dashboardAxios.get('/v1/debug-option/remote-config/list');
                    this.mobileSettings = {
                        ...response.data.result.MOBILE_SETTING,
                        ...response.data.result.RAMADAN_CAMPAIGN,
                    };
                } catch (e) {
                    await this.handleError({ error: e });
                }
            },

            async saveOverride() {
                const payload = {
                    memberID: this.member.memberID,
                    overrideList: this.currentOverrides,
                };

                try {
                    let response = await dashboardAxios.post('/v1/debug-option/remote-config/save-override', payload);
                    const message = response.data.result.message;

                    await this.alert({ message: message, type: 'success' });
                } catch (error) {
                    await this.handleError({ error });
                }
            },

            async clearCache() {
                const payload = {
                    memberID: this.member.memberID,
                };

                try {
                    let response = await dashboardAxios.post('/v1/debug-option/remote-config/clear-cache', payload);
                    const message = response.data.result.message;

                    this.currentOverrides = [];

                    await this.alert({ message: message, type: 'success' });
                } catch (error) {
                    await this.handleError({ error });
                }
            },

            async openModal() {
                this.showModal = true;
            },

            addRow(data) {
                this.currentOverrides.push(data);
            },

            updateRow(data) {
                this.currentOverrides[data.index] = data.settings;
            },

            removeRow(index) {
                this.$delete(this.currentOverrides, index);
            },
        },
    };
</script>

<style scoped></style>
