<template>
    <div v-if="template" class="grid grid-cols-3 gap-3">
        <blocks :nodes="template" />
        <template-builder :nodes="template" />
        <preview
            :template="template"
            @save="$emit('save', data)"
            @html-event="$emit('html-event', $event)"
            @discard="$emit('discard')"
        />
    </div>
</template>

<script>
    import Blocks from './Blocks.vue';
    import Preview from './Preview.vue';
    import TemplateBuilder from './TemplateBuilder.vue';

    export default {
        components: {
            Preview,
            TemplateBuilder,
            Blocks,
        },
        props: {
            campaign: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                data: JSON.parse(JSON.stringify(this.campaign)),
            };
        },
        computed: {
            template() {
                return this.data.template;
            },
        },
    };
</script>

<style src="./email.css" scoped></style>
