<template>
    <button
        :title="title"
        :class="classes"
        :disabled="isDisabled"
        class="p-0 w-16 h-16 bg-pink-muzmatch rounded-full active:shadow-lg hover:bg-opacity-70 mouse shadow transition ease-in duration-200 focus:outline-none text-white text-2xl fixed bottom-10 right-10 z-20"
        @click="$emit('save')"
    >
        <icon v-if="!isSaving" icon="check" />
        <icon v-else class="animate-spin" icon="spinner" />
    </button>
</template>

<script>
    export default {
        props: {
            canSave: {
                type: Boolean,
                default: false,
            },
            isSaving: {
                type: Boolean,
                default: false,
            },
            wobble: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            title() {
                return this.canSave ? 'Save Changes' : 'Nothing to save';
            },
            classes() {
                let classes = !this.isDisabled ? [] : ['cursor-not-allowed', 'bg-grey-muzmatch'];

                if (this.canSave) {
                    classes = classes.concat(['animate-pulse']);
                }

                if (this.wobble) {
                    classes = classes.concat(['animate-bounce']);
                }

                return classes;
            },
            isDisabled() {
                return !this.canSave || this.isSaving;
            },
        },
    };
</script>
