<template>
    <div>
        <div class="mt-4 flex flex-row items-end flex-wrap bg-grey-100 rounded p-4 pt-0">
            <div class="flex flex-row w-full">
                <DashboardLabel class="flex my-3">Override {{ index + 1 }}</DashboardLabel>
            </div>
            <div class="flex flex-col mr-2">
                <label class="my-1">Config Type</label>
                <input
                    class="sm:w-80 block appearance-none border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight bg-white"
                    disabled="disabled"
                    type="text"
                    :value="setting.config | camelcase"
                />
            </div>

            <div class="flex flex-col mr-2">
                <label class="my-1">Enabled</label>
                <Dropdown
                    class="sm:w-24"
                    :options="[
                        { value: true, label: 'True' },
                        { value: false, label: 'False' },
                    ]"
                    :default="setting.enabled"
                    :white-background="true"
                    placeholder="Select enabled"
                    :is-capitalize="false"
                    @updated="setting.enabled = $event"
                />
            </div>

            <div class="flex flex-col mr-2">
                <label class="my-1">Value</label>
                <template v-if="['TEXT', 'NUMBER'].includes(currentOverride.type)">
                    <input
                        v-model="setting.value"
                        class="sm:w-64 block appearance-none border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight bg-white"
                        :type="currentOverride.type"
                    />
                </template>
                <template v-else>
                    <Dropdown
                        class="sm:w-64"
                        :options="[
                            { value: true, label: 'True' },
                            { value: false, label: 'False' },
                        ]"
                        :default="setting.value"
                        :white-background="true"
                        placeholder="Select value"
                        :is-capitalize="false"
                        @updated="setting.value = $event"
                    />
                </template>
            </div>

            <div class="flex mb-1">
                <PrimaryButton class="mr-2" text="Override" :handle-click="overrideAll" />
                <PrimaryButton text="Clear" :handle-click="removeRow" />
            </div>
        </div>
    </div>
</template>

<script>
    import DashboardLabel from '@/components/Labels/DashboardLabel';
    import PrimaryButton from '@/components/Buttons/PrimaryButton';
    import Dropdown from '@/components/Dropdown/Dropdown';
    import _ from 'lodash';

    export default {
        name: 'DebugOptionRemoteConfig',
        components: {
            DashboardLabel,
            PrimaryButton,
            Dropdown,
        },

        filters: {
            camelcase(string) {
                return _.camelCase(string);
            },
        },

        props: {
            index: {
                type: Number,
                required: true,
            },
            currentOverride: {
                type: Object,
                required: true,
            },
            mobileSettings: {
                type: Object,
                required: true,
            },
            overrideCount: {
                type: Number,
                required: true,
            },
        },

        data() {
            return {
                setting: {
                    id: null,
                    config: null,
                    enabled: true,
                    type: null,
                    value: null,
                },
            };
        },

        watch: {
            setting: {
                handler() {
                    this.$emit('update-row', { index: this.index, settings: this.setting });
                },
                deep: true,
            },
        },

        mounted() {
            this.setting.id = this.currentOverride.id;
            this.setting.config = this.currentOverride.config;
            this.setting.enabled = this.currentOverride.enabled;
            this.setting.type = this.currentOverride.type;
            this.setting.value = this.currentOverride.value;
        },

        methods: {
            async overrideAll() {
                this.$emit('save-override');
            },
            async removeRow() {
                this.$emit('remove-row', this.index);

                if (this.overrideCount !== 1) {
                    this.$emit('save-override');
                    return;
                }

                this.$emit('clear-cache');
            },
        },
    };
</script>

<style scoped></style>
