<template>
    <Modal :click-overlay="toggleModal">
        <template #header>
            <div class="hidden md:block">
                <IconButton rounded class="float-right right-0 top-0 absolute mt-4 mr-4" @click="toggleModal">
                    Close
                </IconButton>
            </div>
        </template>

        <template #body>
            <div class="flex w-full max-h-90">
                <div class="w-1/2 m-3 max-h-full text-center" :class="{ 'w-full': isOneFrame }">
                    <DashboardLabel class="mb-5 block">
                        <template v-if="frameList[currentImgIndex].category === 'selfie'">Signup Selfie</template>
                        <template v-else>Frame {{ currentImgIndex }}</template>
                    </DashboardLabel>

                    <img
                        class="profile-photo rounded-lg m-auto h-full block aspect-ratio"
                        :alt="frameList[currentImgIndex].id"
                        :src="frameList[currentImgIndex].base64Content"
                    />

                    <div v-if="!isOneFrame" class="w-full text-center mt-5">
                        <icon
                            icon="chevron-left"
                            :class="isFirstImage ? 'text-grey-light cursor-not-allowed' : 'text-pink-muzmatch'"
                            class="cursor-pointer ml-1 text-3xl"
                            @click="getPreviousImage"
                        />
                    </div>
                </div>

                <div v-if="!isOneFrame" class="w-1/2 m-3 max-h-full text-center">
                    <DashboardLabel class="mb-5 block">
                        <template v-if="frameList[nextImgIndex].category === 'selfie'">Signup Selfie</template>
                        <template v-else>Frame {{ nextImgIndex }}</template>
                    </DashboardLabel>

                    <img
                        class="profile-photo rounded-lg m-auto h-full block aspect-ratio"
                        :alt="frameList[nextImgIndex].id"
                        :src="frameList[nextImgIndex].base64Content"
                    />

                    <div class="w-full text-center mt-5">
                        <icon
                            icon="chevron-right"
                            :class="isLastImage ? 'text-grey-light cursor-not-allowed' : 'text-pink-muzmatch'"
                            class="cursor-pointer ml-1 text-3xl"
                            @click="getNextImage"
                        />
                    </div>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script>
    import Modal from '@/components/Modal/Modal';
    import { resolvePhotoUrl, resolveVerificationPhotoUrl, resolveVideoUrl } from '@/helpers/DashboardUtils';
    import EventBus from '@/helpers/eventBus';
    import DashboardLabel from '@/components/Labels/DashboardLabel';
    import IconButton from '@/components/Buttons/IconButton';

    export default {
        name: 'IdentityVerificationPhotoModal',

        components: {
            Modal,
            DashboardLabel,
            IconButton,
        },

        props: {
            frameList: {
                type: Array,
                required: true,
            },
            currentImgIndex: {
                type: Number,
                required: true,
            },
            nextImgIndex: {
                type: Number,
                required: true,
            },
        },

        computed: {
            isOneFrame() {
                return this.frameList.length === 1;
            },

            isFirstImage() {
                return this.currentImgIndex === 0;
            },

            isLastImage() {
                return this.nextImgIndex === this.frameList.length - 1;
            },
        },

        mounted() {
            EventBus.$on('globalKeyup', this.handleKeyup);
        },

        destroyed() {
            EventBus.$off('globalKeyup', this.handleKeyup);
        },

        methods: {
            resolvePhotoUrl: resolvePhotoUrl,
            resolveVerificationPhotoUrl: resolveVerificationPhotoUrl,
            resolveVideoUrl: resolveVideoUrl,

            toggleModal() {
                this.$emit('toggle-modal');
            },

            getPreviousImage() {
                if (this.isOneFrame || this.isFirstImage) {
                    return;
                }
                this.$emit('decrement-index');
            },

            getNextImage() {
                if (this.isOneFrame || this.isLastImage) {
                    return;
                }
                this.$emit('increment-index');
            },

            handleKeyup(event) {
                if (event.key === 'Escape') {
                    this.toggleModal();
                }

                if (event.key === 'ArrowRight') {
                    this.getNextImage();

                    return;
                }

                if (event.key === 'ArrowLeft') {
                    this.getPreviousImage();
                }
            },
        },
    };
</script>

<style scoped>
    .max-h-90 {
        max-height: 90%;
    }

    .profile-photo {
        object-fit: contain;
    }

    .aspect-ratio {
        width: calc((100vh - 20px) / 2);
        height: calc((100vh - 20px) * 1.33 / 2);
    }
</style>
