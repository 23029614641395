<template>
    <div v-if="message.visibility !== 'VISIBLE'">
        <p v-if="message.visibility === 'DELETED'" class="visibility-disclaimer mt-1">
            Deleted at {{ message.deleted_at | time }}
        </p>
        <p v-else-if="message.visibility === 'DISAPPEARED'" class="visibility-disclaimer mt-1">
            Disappeared at {{ message.disappeared_at | time }}
        </p>
    </div>
</template>

<script>
    export default {
        name: 'ChatVisibilityDisclaimer',

        filters: {
            time(timestamp) {
                return moment(timestamp).format('DD/MM/YYYY HH:mm:ss');
            },
        },

        props: {
            message: {
                required: true,
                type: Object,
            },
        },
    };
</script>

<style scoped>
    .visibility-disclaimer {
        font-style: italic;
        font-size: 0.7rem;
    }
</style>
