<template>
    <div class="rounded bg-white shadow">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'DashboardSectionCard',
    };
</script>
