<template>
    <div>
        <slot />

        <div>
            <span class="badge font-bold">{{ number }}</span>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            number: {
                required: false,
                type: Number,
                default: 0,
            },
        },
    };
</script>

<style scoped>
    .badge {
        background-color: #fb406c;
        border-radius: 10px;
        font-size: 12px;
        color: #fff;
        padding: 0 5px;
    }
</style>
