<template>
    <div v-if="message.replyToID" class="replied-contents mb-2 w-full" :class="getBackgroundClass(message)">
        <p v-if="message.quotedBody.messageType !== 'I'" class="reply-origins">
            {{ message.quotedBody.member.full_name }}
        </p>

        <div v-if="message.quotedBody.messageType === 'T'">
            <p
                :class="{ 'line-through': message.quotedBody.visibility !== 'VISIBLE' }"
                v-html="convertMessage(message.quotedBody.message)"
            ></p>
        </div>

        <div v-else-if="message.quotedBody.messageType === 'M'">
            <div v-if="message.quotedBody.visibility === 'VISIBLE'">
                <div
                    v-for="(media, mediaIndex) in message.quotedBody.media"
                    :key="mediaIndex"
                    class="p-3 font-bold inline-block break-words cursor-pointer"
                >
                    <img
                        v-if="media.type === 'IMAGE'"
                        alt="Image sent in chat"
                        :src="media.urls.file"
                        class="w-64"
                        :height="media.blur_hash.height"
                    />

                    <video v-if="media.type === 'VIDEO'" controls class="focus:outline-none w-64">
                        <source :src="media.urls.file" :height="media.blur_hash.height" />
                    </video>

                    <audio v-if="media.type === 'AUDIO'" controls class="focus:outline-none max-w-xs">
                        <source :src="media.urls.file" />
                    </audio>
                </div>
            </div>
            <p v-else class="line-through" v-html="convertMessage(message.quotedBody.message)"></p>
        </div>

        <chat-visibility-disclaimer :message="message.quotedBody"></chat-visibility-disclaimer>
    </div>
</template>

<script>
    import ChatVisibilityDisclaimer from './ChatVisibilityDisclaimer';

    export default {
        name: 'ReplyToContents',

        components: {
            ChatVisibilityDisclaimer,
        },

        props: {
            sender: {
                required: true,
                type: String,
            },
            message: {
                required: true,
                type: Object,
            },
        },

        methods: {
            convertMessage(message) {
                return message.split('\n').join('<br>');
            },

            getBackgroundClass(message) {
                if (this.sender === message.member.id) {
                    return 'replied-contents-r ';
                }
                return 'replied-contents-l ';
            },
        },
    };
</script>

<style scoped>
    .replied-contents {
        padding: 0.75rem;
        border-radius: 0.5rem;
        font-style: italic;
        font-size: 0.78rem;
    }

    .replied-contents-l {
        background-color: #b0bdca;
    }

    .replied-contents-r {
        background-color: #c83356;
    }

    .reply-origins {
        font-style: normal;
        font-size: 0.7rem;
    }
</style>
