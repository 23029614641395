<template>
    <button
        title="This campaign has finished and is no longer editable"
        class="p-0 w-16 h-16 bg-grey-muzmatch rounded-full active:shadow-lg mouse shadow transition ease-in duration-600 focus:outline-none text-white text-2xl fixed bottom-10 right-10 z-20 cursor-not-allowed"
        @click="$emit('clicked')"
    >
        <icon icon="ban" />
    </button>
</template>

<script>
    export default {
        name: 'DisabledButton',
    };
</script>
