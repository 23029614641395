<template>
    <modal :click-overlay="() => this.$emit('hide')" size="m-l" :no-padding="true">
        <div slot="body">
            <panel title="Content" :has-margin="false">
                <div class="overflow-auto" style="max-height: 42rem">
                    <table class="sm:w-full text-left table-fixed overflow-x-scroll">
                        <tbody>
                            <tr v-for="(value, key) in content" :key="key" class="border hover:bg-grey-200">
                                <td
                                    class="bg-grey-100 text-grey-700 font-bold uppercase px-4 py-2 w-1/3 align-text-top"
                                >
                                    {{ key }}
                                </td>
                                <td v-if="key !== 'Download'" class="px-4 py-2 break-words" v-html="value"></td>
                                <td v-else class="px-4 py-2 break-words">
                                    <button
                                        v-for="label in ['Original', 'Alternative']"
                                        :key="label"
                                        class="font-medium text-blue-darker dark:text-blue-darker underline hover:no-underline mr-5"
                                        @click="downloadOriginal(value, label === 'Alternative')"
                                    >
                                        Save original
                                        <template v-if="label === 'Alternative'">(alternate link)</template>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </panel>
        </div>
    </modal>
</template>

<script>
    import Modal from '../../Modal/Modal';
    import Panel from '../../Panels/Panel';
    import { dashboardAxios } from '../../../../axios.config';
    import { mapActions } from 'vuex';

    export default {
        components: {
            Modal,
            Panel,
        },
        props: {
            content: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                isLoading: false,
            };
        },

        methods: {
            ...mapActions({
                handleError: 'handleError',
                alert: 'alert',
            }),

            async downloadOriginal(value, alternateChannel = false) {
                try {
                    // At one point the original file was saved in the wrong place
                    // Give alternative in case of looking at affected member
                    if (alternateChannel) {
                        value.channel = value.alternateChannel;
                    }

                    let response = await dashboardAxios.get(
                        `/v1/download/media/${value.hashMemberID}?uuid=${value.uuid}&channel=${value.channel}&type=${value.type}&extension=${value.extension}`,
                    );

                    const url = response.data.result;
                    window.open(url, '_blank');
                } catch (error) {
                    await this.handleError({ error });
                }
            },
        },
    };
</script>
