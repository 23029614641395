<template>
    <div class="bg-grey-100">
        <div
            v-if="reportsLoading || memberLoading"
            class="w-full bg-grey-lightest rounded flex justify-center items-center"
            :class="customStyle"
        >
            <p class="text-5xl text-pink-muzmatch">
                <icon icon="address-card" class="mx-1"></icon>
            </p>
        </div>
        <div v-else :class="customStyle">
            <div class="m-2">
                <input v-model="search" type="text" placeholder="Search..." class="w-full px-3 py-2 border rounded" />
            </div>

            <div v-if="filteredReports && filteredReports.length && !reportsLoading" class="overflow-y-scroll">
                <div class="w-full text-left mt-2 block text-sm">
                    <div v-for="(report, idx) in filteredReports" :key="idx" class="bg-white rounded mx-2 my-2">
                        <div class="relative">
                            <div class="flex flex-row justify-between items-center px-2 py-1">
                                <p class="font-mono font-bold break-all">{{ report.issue_type }}</p>
                                <p>{{ time(report.timestamp) }}</p>
                            </div>

                            <div class="flex justify-between items-start px-2 py-1">
                                <div class="flex flex-col">
                                    <div v-if="report.source === 'AUTOMATED_REPORT'" class="flex">
                                        <p class="text-orange-400 font-bold uppercase">Automated Report</p>
                                    </div>
                                    <div class="flex">
                                        <span class="pr-1">Reporter:</span>
                                        <p class="font-bold notranslate no-underline text-pink-muzmatch break-all">
                                            <template v-if="report.source === 'AUTOMATED_REPORT'">Muzz</template>
                                            <router-link
                                                v-else
                                                target="_blank"
                                                :to="getMemberRoute(report.reporting_member)"
                                            >
                                                {{ report.reporting_member.full_name }}
                                            </router-link>
                                        </p>
                                        <p
                                            v-tooltip="
                                                report.source === 'AUTOMATED_REPORT'
                                                    ? report.reporting_member.full_name
                                                    : ''
                                            "
                                            class="text-sm text-pink-muzmatch cursor-pointer"
                                            @click="openChat(report)"
                                        >
                                            <icon icon="comments" class="mx-1"></icon>
                                        </p>
                                    </div>
                                    <div
                                        v-if="report.admin_message || report.message"
                                        class="flex flex-col font-bold justify-start break-all py-1 text-md"
                                    >
                                        {{ report.admin_message || report.message }}
                                    </div>
                                </div>
                                <AttachmentModal
                                    v-if="report.attachment"
                                    :thumb="formatAttachmentUrl(report.attachment)"
                                    :urls="[formatAttachmentUrl(report.attachment)]"
                                    thumb-classes="h-8 w-8 border ml-2"
                                    custom-style="object-fit: cover; min-width: 2rem;"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ReportsReceivedTab from '@/components/Dashboard/Tabs/ReportsReceivedTab';
    import { mapActions } from 'vuex';
    import { getMemberRoute } from '../../../helpers/RedirectToMember';
    import AttachmentModal from '../../Attachments/AttachmentModalV2';
    import EventBus from '@/helpers/eventBus';
    import VTooltip from 'v-tooltip';
    import Vue from 'vue';

    Vue.use(VTooltip);

    export default {
        name: 'ReportPanelContents',

        components: {
            ReportsReceivedTab,
            AttachmentModal,
        },

        props: {
            customStyle: {
                type: String,
                default: '',
            },

            member: {
                type: Object,
                default: null,
            },

            memberLoading: {
                type: Boolean,
                required: true,
            },

            reportsLoading: {
                type: Boolean,
                required: true,
            },

            reports: {
                type: Array,
                required: true,
            },
        },

        data() {
            return {
                search: '',
            };
        },

        computed: {
            filteredReports() {
                let commentedReports = _.filter(this.reports, (report) => {
                    return !!report.admin_message || !!report.message;
                });

                if (!this.search) {
                    return commentedReports;
                }

                return _.filter(commentedReports, (report) => {
                    return JSON.stringify(report).toLowerCase().includes(this.search.toLowerCase());
                });
            },
        },

        methods: {
            loadMember(graceful = false) {
                this.$emit('loadMember', graceful);
            },

            ...mapActions({
                handleError: 'handleError',
            }),

            time(time, format = 'DD/MM/YYYY HH:mm') {
                return moment(time).format(format);
            },

            formatAttachmentUrl(value) {
                return value.replace('s3', 'https');
            },

            openChat(report) {
                EventBus.$emit('loadChat', report.reporting_member.id);
            },

            loadReports() {
                this.$emit('loadReports');
            },

            getMemberRoute(member) {
                return getMemberRoute({ nickname: member.full_name, hashMemberID: member.id });
            },
        },
    };
</script>

<style scoped>
    .break-all {
        word-break: break-all;
    }

    .text-md {
        font-size: 1rem;
    }

    .top-right {
        top: 0;
        right: 0;
    }

    .min-w-8 {
        min-width: 2rem;
    }
</style>
