<template>
    <transition name="modal">
        <div
            class="modal flex justify-center items-center w-full h-full fixed z-50"
            @click.self="$emit('toggleMemberModal')"
        >
            <div class="bg-white p-6 shadow rounded mx-4 w-full md:w-1/2 lg:w-1/3 xl:w-1/4 max-h-90 relative">
                <p class="font-bold text-lg mb-4">Add Member</p>
                <div>
                    <label :class="!!this.selected && this.selected !== 'memberId' ? 'text-grey-400' : ''">
                        Member ID
                    </label>

                    <input
                        v-model="search.memberId"
                        type="text"
                        class="p-2 bg-white border border-grey-400 rounded w-full mt-1"
                        :disabled="!!this.selected && this.selected !== 'memberId'"
                        @keyup.enter="attachMember()"
                    />
                </div>

                <div v-if="isDashboardAdmin" class="mt-4">
                    <div class="mt-4">
                        <label :class="!!this.selected && this.selected !== 'phoneNumber' ? 'text-grey-400' : ''">
                            Phone Number
                        </label>

                        <input
                            v-model="search.phoneNumber"
                            type="text"
                            class="p-2 bg-white border border-grey-400 rounded w-full mt-1"
                            :disabled="!!this.selected && this.selected !== 'phoneNumber'"
                            @keyup.enter="attachMember()"
                        />
                    </div>
                </div>

                <div class="mt-4">
                    <label :class="!!this.selected && this.selected !== 'emailAddress' ? 'text-grey-400' : ''">
                        Email Address
                    </label>

                    <input
                        v-model="search.emailAddress"
                        type="text"
                        class="p-2 bg-white border border-grey-400 rounded w-full mt-1"
                        :disabled="!!this.selected && this.selected !== 'emailAddress'"
                        @keyup.enter="attachMember()"
                    />
                </div>

                <div class="mt-4">
                    <label :class="!!this.selected && this.selected !== 'memberHash' ? 'text-grey-400' : ''">
                        Member Hash
                    </label>

                    <input
                        v-model="search.memberHash"
                        type="text"
                        class="p-2 bg-white border border-grey-400 rounded w-full mt-1"
                        :disabled="!!this.selected && this.selected !== 'memberHash'"
                        @keyup.enter="attachMember()"
                    />
                </div>

                <div class="w-full mt-4 flex flex-row justify-between items-center">
                    <span class="p-1 text-pink-muzmatch">{{ errorMsg }}</span>
                    <button
                        class="py-2 px-4 text-white rounded focus:outline-none hover:shadow-md"
                        :class="loading ? 'bg-pink-lighter' : 'bg-pink-muzmatch'"
                        @mousedown="loading = true"
                        @mouseup="loading = false"
                        @mouseleave="loading = false"
                        @click="attachMember()"
                    >
                        Add Member
                    </button>
                </div>

                <div
                    class="absolute top-0 right-0 -mt-1 -mr-1 flex justify-center items-center bg-pink-muzmatch rounded-full w-8 h-8 cursor-pointer shadow"
                    @click="$emit('toggleMemberModal')"
                >
                    <icon icon="times" class="text-white"></icon>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { User } from '../../classes/User';
    import { dashboardAxios } from '../../../axios.config';
    import EventBus from '../../helpers/eventBus';

    export default {
        name: 'AttachMemberModal',
        props: {
            id: {
                type: Number,
                default: null,
            },
            emailAddress: {
                type: String,
                default: null,
            },
        },
        data() {
            return {
                loading: false,
                search: {
                    memberHash: '',
                    memberId: '',
                    phoneNumber: '',
                    emailAddress: '',
                },
                selected: null,
                errorMsg: null,
            };
        },

        computed: {
            ...mapGetters({
                staff: 'staff/getStaff',
            }),

            isDashboardAdmin() {
                return User.make(this.staff).can('dashboard:admin');
            },
        },

        watch: {
            'search.memberId'(newVal) {
                if (newVal) {
                    this.selected = 'memberId';
                    return;
                }
                this.selected = '';
            },
            'search.phoneNumber'(newVal) {
                if (newVal) {
                    this.selected = 'phoneNumber';
                    return;
                }
                this.selected = '';
            },
            'search.emailAddress'(newVal) {
                if (newVal) {
                    this.selected = 'emailAddress';
                    return;
                }
                this.selected = '';
            },
            'search.memberHash'(newVal) {
                if (newVal) {
                    this.selected = 'memberHash';
                    return;
                }
                this.selected = '';
            },
        },

        async mounted() {
            EventBus.$on('globalKeyup', this.handleKeyup);
        },

        destroyed() {
            EventBus.$off('globalKeyup', this.handleKeyup);
        },

        methods: {
            async attachMember() {
                try {
                    const alert = await this.$swal({
                        icon: 'warning',
                        text: `Are you sure the email address ${this.emailAddress} belongs to this member?`,
                        showCancelButton: true,
                        confirmButtonText: 'Continue',
                    });

                    if (!alert.value) return;

                    let response = await dashboardAxios.post('/v1/tasks/email/thread/' + this.id, {
                        search: this.search,
                    });

                    let member = response.data.result;
                    this.$emit('setMember', member);
                    this.$emit('toggleMemberModal');
                } catch (e) {
                    this.errorMsg = 'Error: ' + this.selected + ' not found';
                }
            },

            handleKeyup(event) {
                if (event.key === 'Escape') {
                    this.$emit('toggleMemberModal');
                }
            },
        },
    };
</script>

<style scoped>
    .modal {
        background-color: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0;
        right: 0;
        transition: opacity 100ms ease-in-out;
    }

    .top-0 {
        top: 0;
    }

    .right-0 {
        right: 0;
    }

    .modal-enter {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

    .max-h-90 {
        max-height: 90%;
    }
</style>
