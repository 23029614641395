<template>
    <div class="rounded shadow-md" :class="{ 'mb-3': hasMargin, 'flex flex-col': email }">
        <div>
            <div class="flex flex-row justify-between bg-grey-100 rounded-t h-custom" :class="customClasses">
                <p v-if="title" class="flex items-center pl-4">
                    <slot name="titleIcon" />
                    <span class="text-sm font-bold">{{ title }}</span>
                </p>

                <slot name="header" />
            </div>
        </div>

        <slot />
    </div>
</template>

<script>
    export default {
        name: 'Panel',

        props: {
            title: {
                type: String,
                default: null,
            },
            hasMargin: {
                type: Boolean,
                default: true,
            },
            customClasses: {
                type: Array,
                default: () => [],
            },
            email: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>

<style scoped>
    .h-custom {
        min-height: 50px;
    }
</style>
