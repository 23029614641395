import MemberRepository from './MemberRepository';
import StaffRepository from './StaffRepository';

const repositories = {
    member: MemberRepository,
    staff: StaffRepository,
};

const RepositoryFactory = {
    get: (name) => repositories[name],
};

export default RepositoryFactory;
