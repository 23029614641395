<template>
    <div slot="default" class="flex flex-col p-2">
        <div v-for="(queue, index) in queues" :key="index">
            <div v-if="index === queues.length / 2 && !collapsed" class="mt-1">
                <hr class="border" />
            </div>

            <div
                v-if="!collapsed || (selected && selected.url === queue.url)"
                class="p-2 w-full flex justify-between items-center cursor-pointer hover:bg-grey-100 rounded mb-1"
                :class="{ 'bg-grey-100': selected && selected.url === queue.url }"
                @click="selectQueue(queue)"
            >
                <span class="capitalize">{{ queue.text }}</span>

                <Badge v-if="!loading && !queue.loading" :number="queue.count" type="muzmatch" />

                <div v-else class="w-4 h-4">
                    <img src="./../../assets/img/loading.gif" />
                </div>
            </div>

            <div
                v-if="collapsed && index === queues.length - 1"
                class="p-2 w-full cursor-pointer hover:bg-grey-100 rounded text-center"
                @click="$emit('open')"
            >
                <span class="font-bold"><icon icon="ellipsis-h"></icon></span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'QueueList',

        props: {
            queues: {
                type: Array,
                default: () => [],
            },

            selected: {
                type: Object,
                default: null,
            },

            loading: {
                type: Boolean,
                default: false,
            },

            collapsed: {
                type: Boolean,
                default: false,
            },
        },

        methods: {
            /**
             * Select a queue.
             *
             * @param queue
             */
            selectQueue(queue) {
                this.$emit('selected', queue);
            },
        },
    };
</script>
