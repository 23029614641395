<template>
    <div class="w-full mt-5">
        <Panel title="Details">
            <div slot="header" class="ml-auto flex w-full justify-between items-center">
                <div class="inline-flex w-full justify-end items-center">
                    <div class="inline-flex flex-wrap justify-center py-2">
                        <a
                            class="no-underline text-pink-muzmatch mx-2 font-bold text-sm cursor-pointer"
                            @click="toggleDeleteModal"
                        >
                            Delete User
                        </a>
                    </div>
                </div>
            </div>

            <div class="p-4">
                <div class="flex flex-wrap">
                    <div class="w-full md:w-1/2 md:pr-2">
                        <label for="name-input">
                            {{ form.name.label }}
                        </label>

                        <Input
                            id="name-input"
                            :default-value="form.name.value"
                            :type="errors.name.type"
                            :text="errors.name.text"
                            @change="handleInputChange('name', $event)"
                        />
                    </div>
                </div>

                <div class="flex flex-wrap">
                    <div class="w-full md:w-1/2 md:pr-2">
                        <label for="email-input">
                            {{ form.email.label }}
                        </label>

                        <Input
                            id="email-input"
                            :default-value="form.email.value"
                            :type="errors.email.type"
                            :text="errors.email.text"
                            @change="handleInputChange('email', $event)"
                        />
                    </div>

                    <div class="w-full md:w-1/2 md:pl-2">
                        <label for="title-input">
                            {{ form.description.label }}
                        </label>

                        <Input
                            id="title-input"
                            :default-value="form.description.value"
                            :type="errors.description.type"
                            :text="errors.description.text"
                            @change="handleInputChange('description', $event)"
                        />
                    </div>
                </div>

                <div class="flex flex-wrap">
                    <div class="w-full md:w-1/2 md:pr-2">
                        <label for="defaultHome-input">
                            {{ form.defaultHome.label }}
                        </label>

                        <Input
                            id="defaultHome-input"
                            :default-value="form.defaultHome.value"
                            :type="errors.defaultHome.type"
                            :text="errors.defaultHome.text"
                            @change="handleInputChange('defaultHome', $event)"
                        />
                    </div>
                    <div class="w-full md:w-1/2 md:pl-2">
                        <label for="alias-input">
                            {{ form.alias.label }}
                        </label>

                        <Input
                            id="alias-input"
                            :default-value="form.alias.value"
                            :type="errors.alias.type"
                            :text="errors.alias.text"
                            @change="handleInputChange('alias', $event)"
                        />
                    </div>
                </div>

                <div class="flex flex-wrap">
                    <div class="w-full md:w-1/2 md:pr-2">
                        <label for="role-input">
                            {{ form.department.label }}
                        </label>

                        <div v-if="waliDepartmentList !== null" class="relative flex items-center">
                            <select
                                v-model="form.department.value"
                                class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                @change="handleDropdownChange('department', form.department.value)"
                            >
                                <option
                                    v-for="department in waliDepartmentList"
                                    :key="department.id"
                                    :value="department.id"
                                >
                                    {{ department.label }}
                                </option>
                            </select>
                            <div
                                class="pointer-events-none absolute inset-y-0 flex items-center px-2 text-gray-700"
                                style="right: 0"
                            >
                                <svg
                                    class="fill-current h-4 w-4"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                >
                                    <path
                                        d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                                    />
                                </svg>
                            </div>
                        </div>
                        <div v-else>
                            <br />
                            Loading department list...
                        </div>
                    </div>

                    <div class="w-full md:w-1/2 md:pl-2">
                        <label for="role-input">
                            {{ form.roleList.label }}
                        </label>

                        <Multiselect
                            id="role-input"
                            v-model="form.roleList.value"
                            :options="waliRoleList"
                            track-by="id"
                            label="label"
                            :multiple="true"
                            :v-if="waliRoleList !== null"
                            class="mt-1 w-full"
                        />
                    </div>
                </div>
            </div>
        </Panel>

        <div class="w-full mt-5 permission-overflow">
            <Panel title="User and role permissions">
                <div class="sm:w-1/6 float-left">
                    <div class="p-4">
                        <h5 class="permission-title text-left">Permissions</h5>
                        <ul class="list-reset mt-5">
                            <li v-for="(product, productIndex) in roles" :key="productIndex">
                                <label
                                    class="relative align-top inline-block text-sm text-grey-darker permission-description"
                                >
                                    {{ capitalize(product.name) }}
                                </label>
                                <ul class="list-reset ml-6">
                                    <li v-for="(role, roleIndex) in product.roles" :key="roleIndex">
                                        <label
                                            class="relative align-top inline-block text-sm text-grey-darker permission-description"
                                        >
                                            {{ capitalize(role.name) }}
                                        </label>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="permission-column float-left">
                    <div class="p-4">
                        <h5 class="permission-title text-center">User level</h5>
                        <ul class="list-reset mt-5">
                            <li v-for="(product, productIndex) in roles" :key="productIndex">
                                <span class="relative align-top inline-block permission-block"></span>
                                <ul class="list-reset">
                                    <li v-for="(role, roleIndex) in product.roles" :key="roleIndex">
                                        <template>
                                            <div class="mb-3 text-pink-dark bg-white checkbox-text-center">
                                                <div
                                                    @click="
                                                        handleRoleCheckboxChange(productIndex, roleIndex, !role.value)
                                                    "
                                                >
                                                    <icon v-if="!role.value" icon="square"></icon>
                                                    <icon v-else icon="check-square"></icon>
                                                </div>
                                                <label
                                                    class="relative mb-0 align-top inline-block text-sm text-grey-darker"
                                                ></label>
                                                <input v-model="role.value" type="checkbox" class="invisible" />
                                            </div>
                                        </template>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>

                <template v-for="waliRole in currentWaliRoleList">
                    <div :key="waliRole.label" class="permission-column float-left">
                        <div class="p-4">
                            <h5 class="permission-title text-center">{{ waliRole.label }}</h5>
                            <ul class="list-reset mt-5">
                                <li v-for="(product, productIndex) in roles" :key="productIndex">
                                    <span class="relative align-top inline-block permission-block"></span>
                                    <ul class="list-reset">
                                        <li v-for="(role, roleIndex) in product.roles" :key="roleIndex">
                                            <template>
                                                <div class="mb-3 checkbox-text-center">
                                                    <div class="text-grey-dark bg-white">
                                                        <icon
                                                            v-if="
                                                                currentWaliRolePermissionsList[waliRole.id] &&
                                                                currentWaliRolePermissionsList[waliRole.id][role.id] ===
                                                                    true
                                                            "
                                                            icon="check-square"
                                                        ></icon>
                                                        <icon v-else icon="square"></icon>
                                                    </div>
                                                </div>
                                            </template>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </template>
            </Panel>
        </div>

        <div class="w-full flex justify-end">
            <DeleteModal
                v-if="deleteModal.visibility"
                :id="editStaff.id"
                :type="deleteModal.type"
                :name="editStaff.name"
                :description="deleteModal.description"
                @toggle-delete-modal="toggleDeleteModal"
                @delete-item="deleteStaff"
            />

            <M2Button :is-disabled="isLoading" @clicked="sendForm">
                {{ sendButtonText }}
            </M2Button>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import Panel from '../../components/Panels/Panel';
    import { dashboardAxios } from '../../../axios.config';
    import Multiselect from 'vue-multiselect';
    import DeleteModal from '../../components/Modal/DeleteModal.vue';
    import M2Button from '../../components/Buttons/M2Button';

    const defaultErrors = {
        name: { type: 'default', text: null },
        email: { type: 'default', text: null },
        description: { type: 'default', text: null },
        defaultHome: { type: 'default', text: null },
        alias: { type: 'default', text: null },
        roles: { text: null },
    };

    export default {
        components: {
            Panel,
            Multiselect,
            DeleteModal,
            M2Button,
        },

        data: function () {
            return {
                check: false,
                form: {
                    name: { value: '', label: 'Name' },
                    email: { value: '', label: 'Email' },
                    description: { value: '', label: 'Title' },
                    department: { value: 1, label: 'Department', required: false },
                    roleList: { value: null, label: 'Permission Role', required: false },
                    defaultHome: { value: '', label: 'Home Page', required: false },
                    alias: { value: '', label: 'Alias', required: false },
                },
                errors: defaultErrors,
                deleteModal: {
                    type: 'staff',
                    description: 'This will also revoke all their logged in sessions',
                    visibility: false,
                },
                roles: null,
                editStaff: null,
                waliDepartmentList: null,
                waliRoleList: [],
                currentWaliRoleList: [],
                currentWaliRolePermissionsList: [],

                isLoading: true,
            };
        },
        computed: {
            ...mapGetters({
                getStaffs: 'staff/getStaffs',
            }),
            isUpdate: function () {
                return this.$route.name === 'teamUpdate' ? true : false;
            },
            sendButtonText: function () {
                return this.isUpdate ? 'Update staff' : 'Create staff';
            },
        },
        async created() {
            try {
                await Promise.all([
                    //Get available department list
                    dashboardAxios.get('/v1/walidepartment').then((response) => {
                        this.waliDepartmentList = this.processGeneralDataList(response.data.result);
                    }),

                    //Get list of available wali role and permissions list from Dashboard and process it
                    dashboardAxios.get('/v1/walirole').then((response) => {
                        this.waliRoleList = this.processGeneralDataList(response.data.result);
                        this.currentWaliRolePermissionsList = this.processWaliRolePermissions(response.data.result);
                    }),

                    dashboardAxios.get('/v1/walipermission').then((response) => {
                        this.roles = this.processRoles(response.data.result);
                    }),
                ]);

                if (!this.isUpdate) {
                    this.isLoading = false;
                    return;
                }

                await this.requestStaffCollection();

                this.editStaff = this.getStaffs.filter((staff) => {
                    return staff.name.toLowerCase() === this.$route.params.name.toLowerCase();
                })[0];

                let currentRoles = [];
                this.editStaff.roles.forEach((role) => {
                    let productName = role.productName.toLowerCase();
                    let roleName = role.roleName.toLowerCase();

                    if (!Object.keys(currentRoles).includes(productName)) {
                        currentRoles[productName] = [];
                    }
                    currentRoles[productName].push(roleName);
                });

                this.roles.map((product) => {
                    product.roles.map((role, roleIndex) => {
                        let currentRolesOfProduct = currentRoles[product.name.toLowerCase()];
                        if (currentRolesOfProduct && currentRolesOfProduct.includes(role.name.toLowerCase())) {
                            product.roles[roleIndex].value = true;
                        }
                    });
                });

                //Process list of saved wali roles against staff
                if (this.editStaff.wali_role_list.length) {
                    let processedStaffWaliRoles = this.processGeneralDataList(this.editStaff.wali_role_list);
                    this.form.roleList.value = processedStaffWaliRoles;
                    this.currentWaliRoleList = processedStaffWaliRoles;
                }

                this.form.name.value = this.editStaff.name;
                this.form.email.value = this.editStaff.email;
                this.form.description.value = this.editStaff.description;
                this.form.department.value = this.editStaff.wali_department_id ? this.editStaff.wali_department_id : 1;
                this.form.defaultHome.value = this.editStaff.defaultHome;
                this.form.alias.value = this.editStaff.alias;

                this.isLoading = false;
            } catch (error) {
                this.handleError({ error });
            }

            this.checkStartUpFormErrors();
        },
        methods: {
            ...mapActions({
                requestRegisterStaff: 'staff/requestRegisterStaff',
                requestStaffCollection: 'staff/requestStaffCollection',
                requestUpdateStaff: 'staff/requestUpdateStaff',
                handleError: 'handleError',
                alert: 'alert',
            }),
            capitalize: function (value) {
                return value[0].toUpperCase() + value.substring(1, value.length);
            },
            checkStartUpFormErrors() {
                let hasErrors = false;
                for (let key in this.form) {
                    if (!this.form[key].required) {
                        return;
                    }

                    if (
                        (this.form.hasOwnProperty(key) && key !== 'description' && this.form[key].value === '') ||
                        this.form[key].value == null
                    ) {
                        this.errors[key].type = 'danger';
                        this.errors[key].text = `${this.form[key].label} is required`;
                        hasErrors = true;
                    }
                }

                return hasErrors;
            },
            clearErrors: function () {
                this.errors = defaultErrors;
            },
            triggerErrors() {
                // clear all errors first
                this.clearErrors();
                return this.checkStartUpFormErrors();
            },
            handleInputChange(name, value) {
                if (name in this.form) {
                    this.form[name].value = value;
                }
            },
            handleDropdownChange(name, value) {
                if (name in this.form) {
                    this.form[name].value = value;
                }
            },
            handleRoleCheckboxChange(productIndex, roleIndex, value) {
                this.roles[productIndex].roles[roleIndex].value = value;
            },
            async sendForm() {
                let hasErrors = this.triggerErrors();
                if (hasErrors === true) {
                    return;
                }

                let roleIds = [];
                this.roles.forEach((product) => {
                    product.roles.forEach((role) => {
                        if (role.value === true) {
                            roleIds.push(role.id);
                        }
                    });
                });

                let payload = {
                    name: this.form.name.value,
                    wali_role_list: this.form.roleList.value,
                    wali_department_id: this.form.department.value,
                    email: this.form.email.value,
                    description: this.form.description.value,
                    defaultHome: this.form.defaultHome.value,
                    alias: this.form.alias.value,
                    roles: roleIds,
                };

                if (this.isUpdate) {
                    payload.id = this.editStaff.id;
                    await this.requestUpdateStaff(payload);
                    await this.alert({ message: 'Successfully updated user profile', type: 'success' });
                    await this.$router.go(); //refresh page
                } else {
                    await this.requestRegisterStaff(payload);
                    await this.alert({ message: 'Successfully added user profile', type: 'success' });
                    await this.$router.push({ path: '/team' });
                }
            },

            toggleDeleteModal() {
                this.deleteModal.visibility = !this.deleteModal.visibility;
            },

            async deleteStaff() {
                let deleteStaffResponse = await dashboardAxios.delete('/v1/staff/' + this.editStaff.id);
                if (deleteStaffResponse.data.result.success == true) {
                    await this.alert({ message: 'Successfully deleted staff', type: 'success' });
                    await this.$router.push({ path: '/team' });
                } else {
                    await this.alert({ message: 'Unfortunately there was a problem deleting staff', type: 'danger' });
                }
            },

            processGeneralDataList(dataList) {
                dataList = _.map(dataList, (data) => ({
                    id: data.id,
                    label: data.label,
                }));

                return dataList;
            },

            processWaliRolePermissions(waliRoleList) {
                let waliRolePermissionsList = [];
                waliRoleList.forEach(function (waliRole) {
                    if (waliRole.permission_list) {
                        //fallback in case there are no permissions attached to roles
                        waliRolePermissionsList[waliRole.id] = [];
                        waliRole.permission_list.forEach(function (permission) {
                            waliRolePermissionsList[waliRole.id][permission.id] = true;
                        });
                    }
                });

                return waliRolePermissionsList;
            },

            processRoles(roles) {
                roles = _.map(roles, (role) => ({
                    id: role.id,
                    name: role.roleName,
                    value: false,
                    product: role.productName,
                }));

                let rolesByProduct = _.groupBy(roles, (role) => role.product);

                return _.map(rolesByProduct, (roles, name) => ({
                    name: name,
                    value: false,
                    roles: roles,
                }));
            },
        },
    };
</script>

<style scoped>
    /*.permission-overflow{
        overflow:scroll;
        position:relative;
        height: 1000px;
    }*/

    .permission-title {
        height: 35px;
    }

    .permission-column {
        width: 9%;
    }

    .permission-description {
        height: 35px;
    }

    .permission-block {
        height: 35px;
    }

    .checkbox-text-center {
        text-align: center;
        margin: 0;
        height: 35px;
    }
</style>
