<template>
    <div class="container mx-auto">
        <ShortcutTool v-if="canUseShortcuts && thread" ref="shortcutTool" :context-task-id="threadID" />
        <div class="pt-2 mb-4 relative mx-auto text-gray-600 text-right">
            <div class="text-grey-muzmatch">Search by email address</div>
            <input
                id="search-emails"
                v-model="search"
                class="border-2 border-gray-300 bg-white h-10 px-5 w-2/6 rounded-lg text-sm focus:outline-none"
                type="search"
                name="search-emails"
                placeholder="Search"
                @keyup.enter="newSearch"
            />
            <button class="absolute right-0 top-0 mt-3 mx-4" @click="newSearch">
                <svg
                    id="Capa_1"
                    class="text-gray-600 h-4 w-4 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    version="1.1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 56.966 56.966"
                    style="enable-background: new 0 0 56.966 56.966"
                    xml:space="preserve"
                    width="512px"
                    height="512px"
                >
                    <path
                        d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"
                    />
                </svg>
            </button>
        </div>
        <div class="flex border-2 rounded h-screen-80">
            <div class="w-2/5 h-full flex flex-col mr-2">
                <panel class="h-full" title="Threads" :has-margin="false">
                    <div class="threads-listing-height overflow-scroll">
                        <table class="w-full">
                            <tbody class="">
                                <div v-if="noThreads" class="text-center mt-4 font-semibold">No threads found</div>
                                <tr
                                    v-for="threadItem in threads"
                                    :id="threadItem.id"
                                    :key="threadItem.id"
                                    class="transform scale-100 text-xs py-1 h-12 border-b border-grey-600 cursor-pointer bg-blue-500 bg-opacity-25 hover:bg-grey-light"
                                    :class="{ 'bg-grey-light': threadIsActive(threadItem.id) }"
                                    @click="getThread(threadItem.id, true)"
                                >
                                    <td class="px-4 py-2">
                                        <div class="leading-5 text-gray-500 font-medium">
                                            {{ threadItem.emailAddress }}
                                        </div>
                                        <div class="leading-5 text-gray-800 break-words">
                                            {{ threadItem.subject }}
                                        </div>
                                    </td>
                                    <td class="whitespace-nowrap text-center pr-2">
                                        <div class="text-grey-muzmatch">{{ threadItem.updated | formatDate }}</div>
                                        <div>{{ threadItem.updated | formatTime }}</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div v-show="canLoadMore" class="w-2/3 mx-auto my-4">
                            <M2Button class="mx-auto" @clicked="nextPage">Load More</M2Button>
                        </div>
                    </div>
                </panel>
            </div>
            <div class="w-4/6">
                <EmailPanel
                    v-if="thread"
                    :is-task-manager="false"
                    :thread="thread"
                    :thread-email-addresses="thread.threadEmailAddresses"
                    :member-email-addresses="thread.memberEmailAddresses"
                    context-type="CONTEXT_TYPE_EMAILS_LIST"
                    context-action="CONTEXT_TYPE_EMAILS_REPLY"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import moment from 'moment';
    import { dashboardAxios } from '../../../axios.config';
    import Panel from '../../components/Panels/Panel';
    import EmailPanel from '../../components/Panels/EmailPanel';
    import M2Button from '../../components/Buttons/M2Button';
    import ShortcutTool from '../../components/Shortcuts/ShortcutTool';
    import { User } from '../../classes/User';

    export default {
        components: {
            Panel,
            EmailPanel,
            M2Button,
            ShortcutTool,
        },
        filters: {
            formatDate(date) {
                return moment(date).format('Do MMM YYYY');
            },
            formatTime(date) {
                return moment(date).format('LT');
            },
        },
        data() {
            return {
                threads: [],
                thread: null,
                search: this.$route.query.query || '',
                isLoading: false,
                page: 0,
                lastLoadHad30Threads: true,
            };
        },
        computed: {
            ...mapGetters({
                staff: 'staff/getStaff',
            }),
            noThreads() {
                return this.threads.length === 0 && !this.isLoading;
            },
            canLoadMore() {
                return this.lastLoadHad30Threads && !this.isLoading;
            },
            canUseShortcuts() {
                return User.make(this.staff).can('shortcut-tool:admin');
            },
            threadID() {
                return this.thread ? parseInt(this.thread.id, 10) : null;
            },
        },
        created() {
            if (this.$route.query.id) {
                this.getThread(this.$route.query.id);
            }
            if (this.$route.params.id) {
                this.getThread(this.$route.params.id);
            } else {
                this.doSearch();
            }
        },
        methods: {
            async doSearch() {
                this.isLoading = true;

                const threads = await dashboardAxios.get('/v1/threads', {
                    params: {
                        emailAddress: this.search,
                        page: this.page,
                    },
                });

                this.threads.push(...threads.data.result);
                this.lastLoadHad30Threads = threads.data.result.length === 30;
                this.isLoading = false;

                if (threads.data.result.length === 0) return;

                this.$nextTick(() => {
                    document.getElementById(threads.data.result[0].id).scrollIntoView();
                });
            },
            async getThread(id, click = false) {
                const thread = await dashboardAxios.get(`/v1/members/threads/${id}`);
                this.thread = thread.data.result;

                if (window.history.replaceState && click) {
                    var newurl =
                        window.location.protocol + '//' + window.location.host + window.location.pathname + '?id=' + id;
                    window.history.replaceState({ path: newurl }, '', newurl);
                }

                this.canUseShortcuts && this.$refs.shortcutTool.getShortcuts();
            },
            threadIsActive(threadID) {
                if (!this.thread) return false;

                return this.thread.id === threadID;
            },
            newSearch() {
                this.threads = [];
                this.page = 0;
                this.doSearch();
            },
            nextPage() {
                this.page += 1;
                this.doSearch();
            },
        },
    };
</script>

<style scoped>
    .threads-listing-height {
        height: calc(100% - 50px);
    }
</style>
