<template>
    <button
        class="dashboard-button hover:shadow-md focus:outline-none rounded-full text-white"
        :class="classObject + ' ' + (loading ? 'bg-pink-lighter' : 'bg-pink-muzmatch')"
        @click="makeBasicAPIRequest()"
        v-html="buttonText"
    >
        {{ buttonText }}
    </button>
</template>

<script>
    import { mapActions } from 'vuex';
    import { dashboardAxios } from '../../../axios.config';

    export default {
        name: 'DashboardButton',

        props: {
            action: {
                type: String,
                required: true,
            },
            text: {
                type: String,
                required: true,
            },
            memberId: {
                type: String,
                required: true,
            },
            requestType: {
                type: String,
                required: false,
            },
            removeImageButton: {
                type: Boolean,
                required: false,
                default: false,
            },
            formData: {
                type: Object,
                required: false,
            },
            cssClass: {
                type: String,
                required: false,
            },
            model: {
                type: String,
                required: false,
            },
            showAlert: {
                type: Boolean,
                required: false,
                default: false,
            },
            showToast: {
                type: Boolean,
                required: false,
                default: false,
            },
        },

        data() {
            return {
                loading: false,
            };
        },
        computed: {
            buttonText() {
                return this.removeImageButton ? '&times;' : this.text;
            },

            classObject() {
                let classes = '';

                classes = classes + ' ' + (this.removeImageButton ? 'remove-image-button' : 'text-button');

                if (this.cssClass) {
                    classes = classes + ' ' + this.cssClass;
                }

                return classes;
            },
        },
        methods: {
            ...mapActions(['handleError', 'alert']),

            async makeBasicAPIRequest() {
                if (this.showAlert) {
                    let response = await this.$swal.fire({
                        text: 'Are you sure?',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'No',
                    });

                    if (response.dismiss) {
                        return;
                    }
                }

                let data = {};

                data['member_id'] = this.memberId;

                if (this.formData) {
                    data = { ...data, ...this.formData };
                }

                let request =
                    this.requestType === 'post'
                        ? dashboardAxios.post(this.action, data)
                        : dashboardAxios.get(this.action, data);

                request
                    .then((res) => {
                        if (this.showToast) {
                            this.$swal.fire({
                                icon: 'success',
                                toast: true,
                                showConfirmButton: false,
                                timer: 1000,
                                text: 'Sent',
                                position: 'top-right',
                            });
                        }
                        this.$emit('refresh', { data: res, model: this.model });
                    })
                    .catch((err) => {
                        this.handleError({ error: err });

                        if (this.showToast) {
                            this.$swal.fire({
                                icon: 'error',
                                toast: true,
                                showConfirmButton: false,
                                text: err,
                                timer: 1000,
                                position: 'top-right',
                            });
                        }

                        // some routes can fail but still manage to change the profile so we refresh the profile even if we get an error
                        this.$emit('refresh');
                    });
            },
        },
    };
</script>

<style scoped>
    .text-button {
        padding: 0.5rem 1rem;
    }

    .remove-image-button {
        padding: 0 0.3rem;
        font-size: 24px;
    }
</style>
