import { render, staticRenderFns } from "./CampaignAddGoalModal.vue?vue&type=template&id=321bb9be&scoped=true&"
import script from "./CampaignAddGoalModal.vue?vue&type=script&lang=js&"
export * from "./CampaignAddGoalModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "321bb9be",
  null
  
)

export default component.exports