<template>
    <div>
        <Modal size="m" :click-overlay="() => $emit('close-modal')" :no-padding="true">
            <div slot="body">
                <Panel :title="modalLabel" :has-margin="false" :custom-classes="['bg-pink-muzmatch', 'text-white']">
                    <div class="p-4">
                        <div class="flex flex-wrap mb-3">
                            <div class="w-full">
                                <label for="form-label">Label*</label>
                                <input
                                    id="form-label"
                                    v-model="form.label"
                                    class="focus:border-pink rounded-xs border rounded border-grey w-full p-3 text-base mb-2"
                                />
                            </div>
                        </div>
                        <div class="mb-5">
                            <label class="mr-2">Mode:</label>
                            <label class="italic pr-2 cursor-pointer" for="form-child-mode-multi-variate">
                                Multi-Variate
                            </label>
                            <input
                                id="form-child-mode-multi-variate"
                                v-model="form.childMode"
                                class="cursor-pointer"
                                :disabled="childMode !== ''"
                                type="radio"
                                value="MULTI_VARIATE"
                            />
                            <label class="italic pl-4 pr-2 cursor-pointer" for="form-child-mode-conditional">
                                Conditional
                            </label>
                            <input
                                id="form-child-mode-conditional"
                                v-model="form.childMode"
                                class="cursor-pointer"
                                :disabled="childMode !== ''"
                                type="radio"
                                value="CONDITIONAL"
                            />
                        </div>
                        <div v-if="form.childMode === 'CONDITIONAL'" class="mb-3">
                            <label>Json Filter</label>
                            <div class="flex">
                                <AudienceJsonDescription
                                    v-if="form.jsonFilter.length"
                                    class="italic text-sm text-grey-dark w-5/6"
                                    :audience-json-filter="form.jsonFilter"
                                />
                                <button
                                    class="amend-filter text-xs border rounded bg-green-dark text-center text-white p-2 w-1/3"
                                    @click="filterModal.show = true"
                                >
                                    Amend Filters
                                </button>
                            </div>
                        </div>
                        <div class="flex flex-wrap mb-4">
                            <div class="w-full">
                                <label for="form-type">Type</label>
                                <input
                                    id="form-type"
                                    v-model="form.type"
                                    readonly="readonly"
                                    class="focus:border-pink rounded-xs border rounded border-grey bg-grey-100 w-full p-3 text-base mb-2"
                                />
                            </div>
                        </div>
                        <div class="flex flex-wrap mb-5">
                            <div class="w-full">
                                <label>Value*</label>
                                <template v-if="['TEXT', 'NUMBER'].includes(form.type)">
                                    <input
                                        v-model="form.value"
                                        :type="form.type"
                                        class="p-2 rounded w-full rounded-xs border border-grey"
                                    />
                                </template>
                                <div v-else class="w-full mt-2 border border-grey p-3 rounded">
                                    <label class="pr-2" :for="form.id + '-radio-false'">False</label>
                                    <input :id="form.id + '-radio-false'" v-model="form.value" type="radio" value="0" />
                                    <label class="pl-4 pr-2" :for="form.id + '-radio-true'">True</label>
                                    <input :id="form.id + '-radio-true'" v-model="form.value" type="radio" value="1" />
                                </div>
                            </div>
                        </div>
                        <div class="flex space-x-12">
                            <div class="w-1/2"></div>
                            <div class="w-1/2">
                                <M2Button
                                    :is-disabled="isButtonDisabled"
                                    :custom-classes="['text-xs', 'normal-case', 'rounded', 'border-white']"
                                    @clicked="addChildConfig"
                                >
                                    <span>Save Config</span>
                                </M2Button>
                            </div>
                        </div>
                    </div>
                </Panel>
            </div>
        </Modal>
        <FilterModal
            v-if="filterModal.show"
            :index="0"
            :json-filters="form.jsonFilter"
            :audience-fields="audienceFields"
            @close-modal="filterModal.show = false"
            @update-filter="updateFilter"
        />
    </div>
</template>

<script>
    import Panel from '../../components/Panels/Panel';
    import M2Button from '../../components/Buttons/M2Button';
    import Dropdown from '../../components/Dropdown/Dropdown';
    import FilterModal from '../RemoteConfig/FilterModal';
    import AudienceJsonDescription from '../Messenger/AudienceJsonDescription';
    import { mapActions } from 'vuex';

    export default {
        name: 'AddChildModal',
        components: {
            Panel,
            M2Button,
            Dropdown,
            FilterModal,
            AudienceJsonDescription,
        },
        props: {
            parentId: {
                type: Number,
                required: true,
            },
            parentType: {
                type: String,
                required: true,
            },
            childMode: {
                type: String,
                default: '',
                required: true,
            },
            howManyChild: {
                type: Number,
                default: 0,
            },
            audienceFields: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                form: {
                    childMode: '',
                    label: '',
                    value: '',
                    type: 'TEXT',
                    jsonFilter: [[{ type: 'muzmatch_email', comparison: '=', value: null }]],
                },
                typeOptionList: [
                    { value: 'TEXT', label: 'Text' },
                    { value: 'NUMBER', label: 'Number' },
                    { value: 'BOOLEAN', label: 'Boolean' },
                ],
                filterModal: {
                    show: false,
                },
            };
        },
        computed: {
            modalLabel() {
                if (this.childMode === 'CONDITIONAL') {
                    return 'Add Conditional';
                }

                if (this.childMode === 'MULTI_VARIATE') {
                    return 'Add Multi-Variate Test';
                }

                return 'Add Child';
            },
            isButtonDisabled() {
                return (
                    this.form.label === '' ||
                    this.form.childMode === '' ||
                    this.form.value === '' ||
                    !this.form.jsonFilter.length
                );
            },
        },
        watch: {
            'form.childMode'() {
                this.createLabel();
            },
        },
        mounted() {
            this.form.type = this.parentType;
            this.form.childMode = this.childMode;
            if (this.childMode === '') {
                this.form.childMode = 'MULTI_VARIATE';
            }

            this.createLabel();
        },
        methods: {
            ...mapActions(['handleError', 'alert', 'confirmDelete']),

            createLabel() {
                switch (this.form.childMode) {
                    case 'CONDITIONAL':
                        this.form.label = 'Condition ' + (this.howManyChild + 1);
                        break;

                    case 'MULTI_VARIATE':
                        this.form.label = 'Test ' + (this.howManyChild + 10).toString(36).toUpperCase();
                        break;

                    default:
                        this.form.label = '';
                }
            },

            async addChildConfig() {
                const text = 'Are you sure you want to save this new config condition?';
                if (!(await this.confirmDelete({ vm: this, text: text }))) return;

                let jsonFilter = null;
                if (this.form.childMode === 'CONDITIONAL') {
                    jsonFilter = this.form.jsonFilter;
                }

                this.$emit('save-child-config', {
                    parentID: this.parentId,
                    label: this.form.label,
                    value: this.form.value,
                    type: this.form.type,
                    childMode: this.form.childMode,
                    jsonFilter: jsonFilter,
                });
            },

            updateFilter(index, queries) {
                this.form.jsonFilter = queries;
            },
        },
    };
</script>

<style scoped>
    .amend-filter {
        max-height: 34px;
    }
</style>
