import repositoryFactory from '../../repositoryFactory';

const StaffRepository = repositoryFactory.get('staff');

export const requestRegisterStaff = async ({ commit, dispatch }, payload) => {
    try {
        await StaffRepository.store(payload);
    } catch (error) {
        dispatch('handleError', { error }, { root: true });
    }
};

export const requestUpdateStaff = async ({ commit, dispatch }, payload) => {
    try {
        await StaffRepository.update(payload);
    } catch (error) {
        dispatch('handleError', { error }, { root: true });
    }
};

export const requestStaffCollection = async ({ commit, dispatch }) => {
    try {
        let result = await StaffRepository.collection();

        await commit('setStaffs', result.data.result);
    } catch (error) {
        dispatch('handleError', { error }, { root: true });
    }
};

export const requestStaff = async ({ commit, dispatch }, token) => {
    try {
        let result = await StaffRepository.search(token);

        await commit('setStaff', result.data.result);
    } catch (error) {
        dispatch('handleError', { error }, { root: true });
    }
};
