<template>
    <div class="relative mt-2 mb-4">
        <select
            id="grid-state"
            ref="inputPicker"
            class="block appearance-none w-full text-gray rounded-xs focus:outline-none focus:bg-white focus:border-gray-500 border border-grey px-2 py-3 text-base"
            @input="updateInput()"
        >
            <option v-for="(item, index) in inputs" :key="index" :value="item.value">
                {{ item.name }}
            </option>
        </select>
        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-grey-darker">
            <icon icon="caret-down"></icon>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            inputs: {
                type: Array,
                default: () => [],
            },
        },
        methods: {
            updateInput() {
                this.$emit('input', this.$refs.inputPicker.value);
            },
        },
    };
</script>
