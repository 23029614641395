<template>
    <div>
        <div v-if="!loading && Object.keys(filters).length >= 1">
            <div class="flex items-center">
                <div class="w-3/4 flex">
                    <div class="flex items-center">
                        <p class="mr-2">Has active filters</p>
                        <icon class="text-green" icon="check" />
                    </div>
                </div>
            </div>
            <table class="w-full text-left table-fixed mt-4">
                <thead>
                    <tr class="bg-grey-200">
                        <th class="px-4 py-2 w-1/3">Key</th>
                        <th class="px-4 py-2 w-1/2">Value</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(value, key) in filters">
                        <tr :key="key" class="border hover:bg-grey-200">
                            <td class="p-1">{{ key }}</td>
                            <td class="p-1">{{ value }}</td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>

        <div v-else class="flex justify-center">
            <span v-if="loading" class="font-bold my-4">Loading...</span>
            <span v-else class="font-bold my-4">Member does not have active filters</span>
        </div>
    </div>
</template>

<script>
    import { dashboardAxios } from '../../../../axios.config';
    import { mapActions } from 'vuex';

    export default {
        name: 'FiltersTab',

        props: {
            profile: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                filters: [],
                loading: false,
                sects: {
                    SU: 'Sunni',
                    SH: 'Shia',
                    AH: 'Ahmadi',
                    IB: 'Ibadi',
                    IS: 'Ismaili',
                    NM: 'Non-Muslim',
                    OT: 'Other',
                },
                maritalStatuses: {
                    A: 'Annulled',
                    D: 'Divorced',
                    S: 'Single',
                    SE: 'Separated',
                    W: 'Widowed',
                },
                dressTypes: {
                    M: 'Modest',
                    H: 'Hijab',
                    J: 'Jilbab',
                    N: 'Niqab',
                    O: 'Other',
                },
            };
        },

        mounted() {
            this.loadFilters();
        },

        methods: {
            ...mapActions({
                handleError: 'handleError',
            }),

            async loadFilters() {
                if (this.loading) {
                    return;
                }

                this.loading = true;

                try {
                    let response = await dashboardAxios.get(
                        '/v1/members/' + this.profile.hashMemberID + '/search-filters',
                    );

                    let filters = response.data.result;

                    if (Object.keys(filters).length === 0) {
                        this.filters = [];
                        this.loading = false;
                        return;
                    }

                    Object.keys(filters).forEach((key) => (filters[key] = this.formatValue(key, filters[key])));
                    this.filters = filters;
                } catch (error) {
                    this.handleError({ error });
                }

                this.loading = false;
            },

            formatValue(key, value) {
                if (!['sect', 'dress', 'maritalStatus'].includes(key) || !value) {
                    return value;
                }

                const data = value.split(',').filter(Boolean);

                if (key === 'sect') {
                    return data.map((sect) => this.sects[sect]).toString();
                }

                if (key === 'dress') {
                    return data.map((dress) => this.dressTypes[dress]).toString();
                }

                if (key === 'maritalStatus') {
                    return data.map((maritalStatus) => this.maritalStatuses[maritalStatus]).toString();
                }
            },
        },
    };
</script>
