<template>
    <main ref="appVersions" class="max-w-4xl mx-auto">
        <release-notes-highlights
            :release-notes="releaseNotes"
            :android-min="appVersions !== null ? appVersions.minAndroidVersion : ''"
            :ios-min="appVersions !== null ? appVersions.minIosVersion : ''"
            @updated-release-notes="updateReleaseNotes"
        />
    </main>
</template>

<script>
    import compareVersions from 'compare-versions';
    import Panel from '../../components/Panels/Panel';
    import PlatformIcon from './PlatformIcon';
    import ReleaseNotesHighlights from './ReleaseNotesHighlights';
    import { dashboardAxios } from '../../../axios.config';
    import { mapActions } from 'vuex';
    import { getPercentageOf } from '../../helpers/GetPercentageOf';

    export default {
        name: 'List',

        components: {
            Panel,
            PlatformIcon,
            ReleaseNotesHighlights,
        },

        data() {
            return {
                filter: '',
                releaseNotes: [],
                appVersions: null,
                notesToShow: 'community_notes',
            };
        },
        computed: {
            activeReleases() {
                if (!this.releaseNotes) return null;

                return [
                    ...this.releaseNotes.filter((release) => release.dau > 1).map((release) => release.version),
                ].sort((a, b) => compareVersions(b.replace('a', ''), a.replace('a', '')));
            },
        },
        created() {
            this.loadReleaseNotesAndAppVersions();
        },
        methods: {
            ...mapActions(['handleError']),

            async loadReleaseNotesAndAppVersions() {
                try {
                    const appVersionsResponse = await dashboardAxios.get('/v1/app-versions', {
                        timeout: 5000,
                    });
                    this.appVersions = appVersionsResponse.data.result;
                    // eslint-disable-next-line no-empty
                } catch (e) {}

                try {
                    const releaseNotesResponse = await dashboardAxios.get('/v1/release-notes');
                    this.releaseNotes = this.formatReleaseNotes(releaseNotesResponse.data.result);
                } catch (e) {
                    this.handleError({ error: e });
                }
            },

            updateReleaseNotes(releaseNotes) {
                this.releaseNotes = this.formatReleaseNotes(releaseNotes);
            },

            formatReleaseNotes(releaseNotes) {
                return releaseNotes.map((release) => ({
                    ...release,
                    userCount: this.getUserCountForAppVersion(release.version),
                    dau: release.released_date
                        ? getPercentageOf(
                              this.getUserDauCountForAppVersion(release.version),
                              parseInt(this.appVersions?.dau ?? 0, 10),
                          )
                        : 0,
                }));
            },

            getUserCountForAppVersion(version) {
                if (!this.appVersions || !this.appVersions.total) return 0;

                const appVersion = this.appVersions.total.find((appVersion) => appVersion.appVersion === version);
                return appVersion ? parseInt(appVersion.members, 10) : 0;
            },

            getUserDauCountForAppVersion(version) {
                if (!this.appVersions || !this.appVersions.total) return 0;

                const appVersion = this.appVersions.totalDau.find((appVersion) => appVersion.appVersion === version);
                return appVersion ? parseInt(appVersion.members, 10) : 0;
            },
        },
    };
</script>
