import { dashboardAxios } from '../../axios.config';

export default async function checkGoogleAuth(vm) {
    const resp = await dashboardAxios.get('/v1/tasks/email/authorization');

    if (resp.data.result.status === 'reauth') {
        await vm.$swal({
            title: 'Please login to Gmail',
            icon: 'info',
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: 'Login',
        });

        window.location.href = resp.data.result.url;
    }
}
